import {
    Component,
    OnInit,
    ViewEncapsulation,
    ElementRef,
    ViewChild,
    AfterViewInit,
    Input,
} from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from '../../services/app-data.service';
import { Constants } from 'src/app/app.constants';
import * as moment from 'moment';
import { AnimationController, Gesture, GestureController } from '@ionic/angular';
import { Haptics, NotificationType } from '@capacitor/haptics';

/**
 * Component that has the video lock screen button incl. animation (overlay).
 */
@Component({
    selector: 'video-player-screen-lock',
    templateUrl: './video-player-screen-lock.component.html',
    styleUrls: ['./video-player-screen-lock.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerScreenLock implements OnInit, AfterViewInit {
    isShown = true;

    public screenLockState:
        | 'unlocked'
        | 'locked'
        | 'locking'
        | 'unlocking'
        | 'trans_to_locked'
        | 'trans_to_unlocked' = 'unlocked';


    @ViewChild('lockGreycircleLine', { read: ElementRef }) public lockGreycircle: ElementRef;
    @ViewChild('buttonLock', { read: ElementRef }) public buttonLock: ElementRef;
    @ViewChild('buttonUnlock', { read: ElementRef }) public buttonUnlock: ElementRef;
    @Input() progressListener: { onScreenLockProgress: (progress: number) => void; };

    private longClickLockGesture: Gesture;
    private longClickUnlockGesture: Gesture;

    private longPressActive = false;
    private longPressStart: moment.Moment;
    public progressPercent = 0; // progress between 0 and 1

    public yellowCircleLineRotation = -180;
    public yellowCircleBgRotation = -180;

    private calculateProgressPercentInterval: ReturnType<typeof setInterval>;

    constructor(
        private logger: NGXLogger,
        public translate: TranslateService,
        public uiUtils: UiUtils,
        public appData: AppData,
        public constants: Constants,
        private animationCtrl: AnimationController,
        private gestureCtrl: GestureController
    ) {
        // empty
        this.logger.debug('VideoPlayerSettingsPreviewAnimation constructor');
    }

    ngOnInit() {
        this.logger.debug('VideoPlayerSettingsPreviewAnimation.ngOnInit()');
    }

    ngAfterViewInit() {
        this.longClickLockGesture = this.gestureCtrl.create(
            {
                el: this.buttonLock.nativeElement,
                gestureName: 'long-click-lock',
                threshold: 0,
                onStart: (ev) => this.onGestureEventStart(ev, true),
                onEnd: (ev) => this.onGestureEventEnd(ev, true),
            },
            true
        );
        this.longClickLockGesture.enable(true);

        this.longClickUnlockGesture = this.gestureCtrl.create(
            {
                el: this.buttonUnlock.nativeElement,
                gestureName: 'long-click-unlock',
                threshold: 0,
                onStart: (ev) => this.onGestureEventStart(ev, false),
                onEnd: (ev) => this.onGestureEventEnd(ev, false),
            },
            true
        );
        this.longClickUnlockGesture.enable(true);
    }

    /**
     * Called when a user swipes on screen
     *
     * @param event Swipe event
     */
    onGestureEventStart(event: any, isLock: boolean) {
        this.logger.debug(`GESTURE event START`, isLock, event);
        this.longPressActive = true;
        this.longPressStart = moment();
        this.calculateProgressPercentInterval = setInterval(() => {
            const progress = this.calculateProgressPercent();
            if (this.progressListener) {
                this.progressListener.onScreenLockProgress(progress);
            }
        }, 50);
        if (isLock) {
            if (this.screenLockState !== 'trans_to_locked') {
                this.screenLockState = 'trans_to_locked';
            }
        } else {
            if (this.screenLockState !== 'trans_to_unlocked') {
                this.screenLockState = 'trans_to_unlocked';
            }
        }
    }

    /**
     * Called when a user swipes on screen
     *
     * @param event Swipe event
     */
    onGestureEventEnd(event: any, isLock: boolean) {
        this.logger.debug(`GESTURE event END`, isLock, event);
        if (this.calculateProgressPercentInterval) {
            clearInterval(this.calculateProgressPercentInterval);
        }

        this.longPressActive = false;
        this.longPressStart = null;
        const progress = this.calculateProgressPercent();
        if (this.progressListener) {
            this.progressListener.onScreenLockProgress(progress);
        }

        if (this.screenLockState === 'trans_to_locked') {
            this.screenLockState = 'unlocked';
        } else if (this.screenLockState === 'trans_to_unlocked') {
            this.screenLockState = 'locked';
        }
    }

    calculateProgressPercent() {
        if (!this.longPressStart || !this.longPressActive) {
            this.progressPercent = 0;
        } else {
            const maxMilliseconds = 1200;
            const diff = moment().diff(this.longPressStart, 'ms');
            this.progressPercent = diff / maxMilliseconds;
            if (this.progressPercent > 1) {
                this.progressPercent = 1;
            }
        }

        if (this.progressPercent === 0) {
            this.yellowCircleLineRotation = -180;
            this.yellowCircleBgRotation = -180;
        } else {
            this.yellowCircleLineRotation = -120 + 120 * this.progressPercent;
            this.yellowCircleBgRotation = -180 + 180 * this.progressPercent;
        }

        if (this.progressPercent >= 1) {
            if (this.screenLockState === 'trans_to_locked') {
                this.lockPlayer();
            } else if (this.screenLockState === 'trans_to_unlocked') {
                this.unlockPlayer();
            }
        }

        return this.progressPercent;
    }

    show() {
        this.isShown = true;
    }

    hide() {
        this.isShown = false;
    }

    /**
     * Lock the player (hide most controls)
     */
    lockPlayer() {
        this.screenLockState = 'locked';
        if (this.calculateProgressPercentInterval) {
            clearInterval(this.calculateProgressPercentInterval);
            this.calculateProgressPercentInterval = null;
        }
        this.longPressActive = false;
        this.longPressStart = null;
        setTimeout(() => {
            this.calculateProgressPercent();
            this.hapticFeedback();
        }, 200);

        // this.longClickLockGesture.enable(false);
        this.translate.get('player_is_locked').subscribe((text) => {
            this.uiUtils.displayToast(text, 'top', 2000);
        });
    }

    /**
     * Unlock the player (show all controls again)
     */
    unlockPlayer() {
        this.screenLockState = 'unlocked';
        if (this.calculateProgressPercentInterval) {
            clearInterval(this.calculateProgressPercentInterval);
            this.calculateProgressPercentInterval = null;
        }
        this.longPressActive = false;
        this.longPressStart = null;
        setTimeout(() => {
            this.calculateProgressPercent();
            this.hapticFeedback();
        }, 200);

        // this.longClickUnlockGesture.enable(false);
        this.translate.get('player_is_unlocked').subscribe((text) => {
            this.uiUtils.displayToast(text, 'top', 1500);
        });
    }

    get isLocked(): boolean {
        return (
            this.screenLockState === 'locked' ||
            this.screenLockState === 'unlocking' ||
            this.screenLockState === 'trans_to_unlocked'
        );
    }

    /**
     * Perform a haptic feedback (vibration) with th Capacitor plugin on
     */
    hapticFeedback() {
        // Haptics.impact({ style: ImpactStyle.Light }).then(
        Haptics.notification({ type: NotificationType.Success }).then(
            // Haptics.vibrate({ duration: 100 }).then(
            () => {
                this.logger.debug('Haptic feedback done');
            },
            (err) => {
                this.logger.warn('Haptic feedback error:', err);
            }
        );
    }
}
