import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/app.constants';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from 'src/app/services/app-data.service';
import { AppManager } from 'src/app/services/app-manager.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AbstractRootMenuPage } from 'src/app/utils/abstract-root-menu-page';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'app-error',
    templateUrl: 'error-page.html',
    styleUrls: ['error-page.scss']
})
export class ErrorPage extends AbstractRootMenuPage implements OnInit {

    public errorMessage: string;

    constructor(public uiUtils: UiUtils,
        public translate: TranslateService,
        public appData: AppData,
        public appManager: AppManager,
        public constants: Constants,
        public navCtrl: NavController,
        private analytics: AnalyticsService,
        private titleService: Title,
        public plt: Platform,
        private router: Router,
        private logger: NGXLogger) {
        super(plt);

        // Passing state doesn't seem to work yet
        const nav = this.router.getCurrentNavigation();
        this.logger.debug('getCurrentNavigation.extras', nav ? nav.extras : '-');
        if (nav && nav.extras && nav.extras.state) {
            this.errorMessage = nav.extras.state.errorMessage;
        }
    }


    ngOnInit() {
        this.titleService.setTitle('Error | uugot.it');
        this.analytics.trackPageView('error');
    }

    ionViewWillEnter() {
        super.ionViewWillEnter();
        this.appData.activePage = 'error';
    }

    ionViewWillLeave() {
        super.ionViewWillLeave();
    }

    goToHomePage() {
        this.navCtrl.navigateRoot('');
    }

    /**
     * Reloads the page and goes to the base URL (home page)
     */
    reloadPage() {
        const baseUrl = window.location.origin;
        this.logger.debug('realoadPage baseUrl', baseUrl);
        window.location.href = baseUrl;
    }
}
