<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-button *ngIf="class_id" (click)="navigateBack()">
                <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
            </ion-button>
            <ion-menu-toggle *ngIf="!class_id">
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
        <ion-title>{{ ("word_catalog" | translate) + (class ? ' (' + class.name + ')' : '') }}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="deleteSelected()" *ngIf="getSelected().length > 0"
                title="{{ 'btn_delete_from_wordcatalogue' | translate }}">
                {{getSelected().length}}
                <ion-icon name="trash"></ion-icon>
            </ion-button>
            <ion-button (click)="deleteAll()" *ngIf="getSelected().length === 0"
                [disabled]="!wordCatalog || !wordCatalog.length"
                title="{{ 'btn_delete_all_wordcatalogue' | translate }}">
                <ion-icon slot="icon-only" name="trash"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-progress-bar type="indeterminate" *ngIf="!wordCatalog"></ion-progress-bar>

<ion-content class="ion-padding">
    <p *ngIf="wordCatalog && wordCatalog.length === 0" class="ion-text-center">{{ "word_catalog_empty_info" | translate }}</p>

    <div *ngIf="wordCatalog && wordCatalog.length > 0">
        <ion-grid fixed>
            <ion-row>
                <ion-col size="1.4"></ion-col>
                <ion-col>{{ "original_word" | translate }}</ion-col>
                <ion-col>{{ "translated_word" | translate }}</ion-col>
                <ion-col *ngIf="plt.is('desktop') || plt.is('tablet')" class="languageHeader" size="1">
                    <ion-icon title="{{ 'language' | translate }}" name="language"></ion-icon>
                </ion-col>
                <ion-col size="1"></ion-col>
            </ion-row>
            <ion-row *ngFor="let word of wordCatalog; let i = index" [attr.data-index]="i">
                <ion-col class="checkBox" size="1.4">
                    <ion-checkbox [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'"
                        [(ngModel)]="word.selected"></ion-checkbox>
                    <!--<input type="checkbox" [(ngModel)]="word.checked">-->
                </ion-col>
                <ion-col class="originalWord" (click)="openWordCatalogCardsPage(word.video_id, i)">
                    <span *ngIf="word.fromToken; else elseBlock">{{word.fromToken.trText || word.fromToken.text}}</span>
                    <ng-template #elseBlock>{{word.fromWord || word.oriWord}}</ng-template>
                </ion-col>
                <ion-col class="translatedWord" (click)="openWordCatalogCardsPage(word.video_id, i)">
                    <span [innerHTML]="splitWordIntoLinesJoin(word.toWord, word.to)"></span>
                </ion-col>
                <ion-col *ngIf="plt.is('desktop') || plt.is('tablet')" class="language" size="1">
                    <span title="{{ 'lang_' + word.to | translate }}">{{word.to.toUpperCase()}}</span>
                </ion-col>
                <ion-col class="wordAction" size="1">
                    <ion-icon name="play" (click)="openWordCatalogCardsPage(word.video_id, i)"></ion-icon>
                </ion-col>
            </ion-row>
        </ion-grid>
        <!--<ion-item *ngFor="let word of wordCatalog; let i = index" [attr.data-index]="i">
        <ion-label>word</ion-label>
        <ion-checkbox></ion-checkbox>
    </ion-item>-->
    </div>
    <ion-infinite-scroll (ionInfinite)="doInfinite($event)">
        <ion-infinite-scroll-content loadingSpinner="circles" loadingText="{{ 'loading_more_words' | translate }}">
        </ion-infinite-scroll-content>
    </ion-infinite-scroll>
</ion-content>
