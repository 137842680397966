import { Component, OnInit, Input } from '@angular/core';
import { IVideo } from '../../interfaces/IVideo';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from '../../services/app-data.service';
import { Constants } from 'src/app/app.constants';
import * as moment from 'moment';

/**
 * Component that shows a "2 days left" badge on a video card.
 */
@Component({
    selector: 'app-video-catalog-only-premium-flap',
    templateUrl: './video-catalog-only-premium-flap.component.html',
    styleUrls: ['./video-catalog-only-premium-flap.component.scss'],
})
export class VideoCatalogOnlyPremiumFlapComponent implements OnInit {
    @Input() video: IVideo; // the video
    @Input() show: boolean;

    @Input() isDisabled: boolean;

    constructor(
        private logger: NGXLogger,
        public translate: TranslateService,
        public uiUtils: UiUtils,
        public appData: AppData,
        public constants: Constants
    ) {
        // empty
        // this.logger.debug('VideoCatalogExpiresFlap constructor');
    }

    ngOnInit() {
        // this.logger.debug('VideoCatalogExpiresFlap.ngOnInit()');
    }

    /**
     * Get remaing time until expiration in hours and days.
     * @param maxDays if remaining days is greater than this value, the flap won't be shown
     * @returns for example: `{minutes: 3087, hours: 51, days: 2}`
     */
    getExpiresInHoursAndDays(maxDays: number): {minutes: number; hours: number; days: number;} {
        if (!this.video || !this.video.expiryDate) {
            return null;
        }
        const momentExpires = moment(this.video.expiryDate);
        const momentNow = moment();
        const minutes = momentExpires.diff(momentNow, 's');
        const hours = momentExpires.diff(momentNow, 'h');
        const days = Math.round(hours / 24.0);
        // const days = momentExpires.diff(momentNow, 'd');
        if (days <= maxDays) {
            return { minutes, hours, days };
        } else {
            return null;
        }

    }
}
