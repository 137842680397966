<ng-container *ngIf="isShown">
    <div class="video-player-screen-lock-yellowcircle-bg" #lockYellowcircleLine
        [style.transform]="'rotate(' + yellowCircleBgRotation + 'deg)'"
        [style.opacity]="progressPercent ? (progressPercent + 0.4 / 1.4) : 0"> <!-- set opacity range from 28% to 100%-->
    </div>
    <div class="video-player-screen-lock-greycircle-line" #lockGreycircleLine
        [class.video-player-screen-lock-greycircle-line-hidden]="screenLockState === 'locked' || screenLockState === 'unlocked'">
    </div>
    <div class="video-player-screen-lock-yellowcircle-line" #lockYellowcircleLine
        [style.transform]="'rotate(' + yellowCircleLineRotation + 'deg)'">
    </div>
    <ng-container [ngSwitch]="screenLockState">
        <div class="video-overlay-button-icon video-overlay-button-unlock" #buttonUnlock
            [hidden]="!(screenLockState === 'locked' || screenLockState === 'unlocking' || screenLockState === 'trans_to_unlocked')">
            <ion-icon name="lock-closed-outline" ioniconRemoveTitle iconTitle="{{ 'btn_unlock_screen' | translate }}">
            </ion-icon>
        </div>
        <div class="video-overlay-button-icon" #buttonLock
            [hidden]="!(screenLockState === 'unlocked' || screenLockState === 'locking' || screenLockState === 'trans_to_locked')">
            <ion-icon name="lock-open-outline" ioniconRemoveTitle iconTitle="{{ 'btn_lock_screen' | translate }}">
            </ion-icon>
        </div>
    </ng-container>
    <!-- <div style="position: absolute; left: -10px; top: -50px;">{{ progressPercent }} {{ screenLockState }}</div> -->
</ng-container>
