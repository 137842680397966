// angular
import { Component } from '@angular/core';

// ionic
import { ToastController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { IAssessment } from '../../interfaces/IAssessment';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from 'src/app/services/app-data.service';
import { IGroup } from 'src/app/interfaces/IGroup';
import { AssessmentsApi } from 'src/app/services/api/assessments-api.service';
import { IGroupVideo } from 'src/app/interfaces/IGroupVideo';
import { NGXLogger } from 'ngx-logger';
import { NavigationExtras, Router } from '@angular/router';
import { IVideo } from '../../interfaces/IVideo';
import { AppComponent } from 'src/app/app.component';
import { SharedUiService } from '../../services/shared-ui.service';

@Component({
    selector: 'app-worksheet-select',
    templateUrl: 'worksheet-select-page.html',
    styleUrls: ['worksheet-select-page.scss']
})
export class WorksheetSelectPage {
    public groupVideo: IGroupVideo;
    public group: IGroup;
    public video: IVideo;
    public videoAssessments: IAssessment[];
    public selectedAssessment: IAssessment;
    public hasParams = false;

    constructor(public navCtrl: NavController,
        public assessmentsApi: AssessmentsApi,
        public uiUtils: UiUtils,
        public translate: TranslateService,
        public toastCtrl: ToastController,
        public appData: AppData,
        public plt: Platform,
        private logger: NGXLogger,
        private router: Router,
        private appComponent: AppComponent,
        private sharedUiService: SharedUiService) {

        const nav = this.router.getCurrentNavigation();
        console.log('getCurrentNavigation', nav);
        if (nav && nav.extras && nav.extras.state) {
            this.group = nav.extras.state.group;
            this.groupVideo = nav.extras.state.groupVideo;
            this.video = nav.extras.state.video;
        }
    }

    ionViewWillEnter() {
        if (this.groupVideo || this.video) {
            console.log('WorksheetSelectPage got videoId and group');

            const videoId = this.groupVideo ? this.groupVideo.video_id._id : this.video._id;
            this.assessmentsApi.getAssessmentsForVideo(videoId, 'level name').subscribe(response => {
                console.log('Success - getting assessments for video', response);
                this.videoAssessments = response.data;
                // this.videoAssessments = response.data.sort((a: IAssessment, b: IAssessment) => {
                //     return a.level < b.level ? -1 : 1;
                // });

                // Preselect assessment if there is one already assigned
                if (this.groupVideo && this.groupVideo.assessment_id) {
                    this.selectedAssessment = this.videoAssessments.find(a => a._id === this.groupVideo.assessment_id._id);
                    this.logger.debug('Got selected assessment for %s:', this.groupVideo.assessment_id, this.selectedAssessment);
                }
            }, err => {
                console.log('Error - getting assessments for video', err);
            });
        } else {
            this.navCtrl.navigateRoot('catalog');
        }
    }

    /**
     * Navigates back to educator details page
     */
    navigateBack() {
        const canGoBack = this.appComponent.routerOutlet.canGoBack();
        if (this.group) {
            this.navCtrl.navigateBack(`educator-class/details/${this.group._id}`);
        } else {
            if (canGoBack) {
                // this.appComponent.routerOutlet.pop();
                this.navCtrl.pop();
            } else {
                this.navCtrl.navigateRoot('/catalog/all');
            }
        }
    }

    /**
     * Assigns selected assessment to video
     */
    addAssessmentToVideo() {
        if (!this.selectedAssessment) {
            this.uiUtils.showErrorAlert(this.translate.instant('please_select_worksheet'));
        } else {
            const groupId = this.group?._id || this.groupVideo.group_id;
            const videoId = typeof this.groupVideo.video_id === 'string' ? this.groupVideo.video_id : this.groupVideo.video_id._id;
            this.assessmentsApi.addAssessmentToVideoInGroup(groupId, this.selectedAssessment._id, videoId)
                .subscribe(res => {
                    if (res.success) {
                        console.log('Success - Adding assessment to video', res);
                        // Add assessment to video catalog videos if video is in catalog
                        if (this.appData.videos) {
                            const index = this.appData.videos.findIndex(video => video._id === videoId);
                            if (index !== -1) {
                                if (this.appData.videos[index].assessment_docs) {
                                    this.appData.videos[index].assessment_docs.push(this.selectedAssessment);
                                } else {
                                    this.appData.videos[index].assessment_docs = [this.selectedAssessment];
                                }
                            }
                        }

                        this.uiUtils.displayToast(this.translate.instant('worksheet_added_to_video'));
                    } else {
                        console.error('Error - Adding assessment to video', res);
                        this.uiUtils.displayToast(res.msg);
                    }
                    this.navigateBack();
                }, err => {
                    console.log('Error - Adding assessment to video', err);
                });
        }
    }

    /**
     * Opens worksheet upload page
     */
    openWorksheetUploadPage() {
        const navigationExtras: NavigationExtras = {
            state: {
                group: this.group,
                groupVideo: this.groupVideo,
            }
        };
        this.navCtrl.navigateForward('worksheet-upload', navigationExtras);
    }

    /**
     * Adds video to group selected by user in the alert
     *
     * @param videoId The video ID of the video to add
     */
    async addVideoToClass(videoId: string) {
        if (!this.selectedAssessment) {
            this.uiUtils.showErrorAlert(this.translate.instant('please_select_worksheet'));
            return;
        }
        await this.sharedUiService.showAddVideoToClassDialog(videoId, (result, addResponse) => {
            switch (result) {
                case 'added':
                case 'already_in_class':
                    this.groupVideo = addResponse.data;
                    this.addAssessmentToVideo();
                    break;

                default:
                    break;
            }
        });
    }
}
