// angular
import { Component, NgZone, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Title, DomSanitizer } from '@angular/platform-browser';

// ionic
import { AlertController, IonContent, LoadingController, ModalController, NavController, Platform, PopoverController, IonModal } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core/components';

import { TranslateService } from '@ngx-translate/core';
import { IGroup } from '../../interfaces/IGroup';
import { IVideo } from '../../interfaces/IVideo';
import { IGroupVideo } from '../../interfaces/IGroupVideo';
import { AppData } from 'src/app/services/app-data.service';
import { GroupsApi } from 'src/app/services/api/groups-api.service';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AssessmentsApi } from 'src/app/services/api/assessments-api.service';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { IUser } from 'src/app/interfaces/IUser';
import { IEducator } from 'src/app/interfaces/IEducator';
import { ActivityApi } from 'src/app/services/api/activity-api.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ClipboardService } from 'ngx-clipboard';
import { environment } from 'src/environments/environment';
import { AbstractRootMenuPage } from 'src/app/utils/abstract-root-menu-page';
import { NGXLogger } from 'ngx-logger';
import { AppManager } from '../../services/app-manager.service';
import { IPopoverActionItem } from 'src/app/utils/action-menu-popover.page';
import { ActionMenuPopoverPage } from '../../utils/action-menu-popover.page';
import {
    GroupResponse,
    ISubjectsListResponse,
    PendingInvitesToGroupsResponse, UpdateInviteResponse
} from '../../models/dtos';
import { Utils } from '../../utils/utils';
import { IClip } from 'src/app/interfaces/IClip';
import { ClipsApi } from 'src/app/services/api/clips.service';
import { ClassLeaderboardPage } from '../class-leaderboard/class-leaderboard.page';
import { isNil } from 'lodash';
import { TutorialGenericPage } from '../tutorial-generic/tutorial-generic-page';
import { SubjectsApi } from '../../services/api/subjects.service';
import { ISubject } from '../../interfaces/ISubject';
import { Validators, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { EducatorsApi } from '../../services/api/educators-api';
import { IInvitesToGroups, InviteActions } from '../../interfaces/IInvitesToGroups';

@Component({
    selector: 'app-class-details-educator',
    templateUrl: 'class-details-educator-page.html',
    styleUrls: ['class-details-educator-page.scss']
})
export class ClassDetailsEducatorPage extends AbstractRootMenuPage implements OnInit {
    @ViewChild(IonModal) modal: IonModal;

    public groupResponse?: GroupResponse; // this is the complete JSON response from the server
    public group: IGroup; // This is groupResponse.group
    public classVideosInfo: IVideo[] = [];
    public classVideos: IGroupVideo[];
    public totalVideosCount = 0;
    public videosVisibleCount = 0;
    public remainingTeacherInvites = 10;
    public showStatistics = false;
    public segment = 'videos';
    public fromDate: any;
    public toDate: any;
    loadingVideos = true;
    public mostClickedWords: any[];
    public subjects: ISubject[];
    public subjectsLang: ISubject[];
    public educatorCreateForm: UntypedFormGroup;
    public educatorEditForm: UntypedFormGroup;
    public invites: IInvitesToGroups[];
    public currentEditingEducator: IEducator;
    public isVideosAvailableForClass = false;
    /**
     * Limit the number of words shown under analytics.
     */
    private showMaxClickedWords = 30;

    @ViewChild(IonContent, { static: false }) ionContent: IonContent;

    scrollTop = 0; // Current scroll top (Y)
    scrollTopSaved = null; // Saved scroll top (Y) when navigating to another page
    loggedUser: IUser;

    constructor(private _route: ActivatedRoute,
        public navCtrl: NavController,
        public translate: TranslateService,
        public appData: AppData,
        public groupsApi: GroupsApi,
        public educatorsApi: EducatorsApi,
        public clipsApi: ClipsApi,
        public assessmentsApi: AssessmentsApi,
        public uiUtils: UiUtils,
        public alertCtrl: AlertController,
        public loadingCtrl: LoadingController,
        private _activityApi: ActivityApi,
        private analytics: AnalyticsService,
        private titleService: Title,
        private clipboardService: ClipboardService,
        public plt: Platform,
        public sanitizer: DomSanitizer,
        private popoverController: PopoverController,
        private logger: NGXLogger,
        private appManager: AppManager,
        private modalCtrl: ModalController,
        private subjectsApi: SubjectsApi,
        private formBuilder: UntypedFormBuilder) {
        super(plt);
    }

    ngOnInit() {
        this.titleService.setTitle('Class - Educator | uugot.it');
        this.analytics.trackPageView('flash-cards');
        this.initForm();
        this.loggedUser = this.appData.authenticatedUser;

    }

    initForm() {
        // https://ionicframework.com/docs/developer-resources/forms/
        // https://www.joshmorony.com/advanced-forms-validation-in-ionic-2/
        this.educatorCreateForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email, this.forbiddenEmailValidator()]],
            name: ['', [Validators.required]],
            lastname: ['', [Validators.required]],
            subjects: ['', [Validators.required]]
        });

        this.educatorEditForm = this.formBuilder.group({
            subjects: ['', [Validators.required]] // only able to modify subjects.
        });
    }

    /** Validator for existing email in invites or team educators */
    forbiddenEmailValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (this.invites) {
                for (const invite of this.invites) {
                    if (invite.email == control.value) {
                        return { forbiddenEmail: { value: control.value } };
                    }
                }
            }
            if (this.group?.team_educators) {
                for (const educator of this.group.team_educators) {
                    if (educator.edId['email'] == control.value) {
                        return { forbiddenEmail: { value: control.value } };
                    }
                }
            }
        };
    }

    getInvites() {
        return this.groupsApi.getInvites(this.group._id).toPromise();
    }

    ionViewWillEnter() {
        super.ionViewWillEnter();
        const class_id = this._route.snapshot.params.class_id;
        this.appData.activePage = '';
        this.appData.activeGroupId = class_id;
        this.subjectsApi.getSubjects().subscribe(data => {
            this.subjects = data.data;
            this.subjects = data.data.filter(subject => subject.availableForEducators);
            this.subjectsLang = this.subjects.map(subject => {
                if (this.appData.getLanguage() === 'en') {
                    subject.lang = subject.name.en;
                } else {
                    subject.lang = subject.name.de;
                }
                return subject;
            });

            this.subjectsLang.sort((a, b) => {
                return a.lang.localeCompare(b.lang);
            });

            const daf = this.subjectsLang.filter(subject =>
                subject.slug === 'daf-daz'
            );

            const english = this.subjectsLang.filter(subject =>
                subject.slug === 'english'
            );

            const otherSubjects = this.subjectsLang.filter(subject =>
                subject.slug !== 'daf-daz' && subject.slug !== 'english'
            );

            this.subjectsLang = daf.concat(english).concat(otherSubjects);
        });

    this._route.snapshot.data.resData.subscribe(async data => {
            this.groupResponse = data.groupResponse;
            this.group = data.groupResponse.data;

            try {
                const invitesResult = await this.getInvites();
                this.invites = invitesResult.data;
            } catch (e) {
                console.log('Error fetching invites', e);
            }


            this.classVideos = data.videos; // IGroupVideo[]

            if (this.groupResponse && !this.groupResponse.success) {
                return;
            }
            this.totalVideosCount = this.classVideos.length;
            this.classVideosInfo = this.classVideos.map(v => v.video_id as IVideo); // IVideo[]
            this.loadingVideos = false;

            // Scroll back to save position (this is also done in ionViewDidEnter() - whatever comes first - so it's faster fo the user)
            if (this.scrollTopSaved !== null) {
                setTimeout(() => {
                    this.logger.debug('scrollToPoint', this.scrollTopSaved);
                    this.ionContent.scrollToPoint(0, this.scrollTopSaved);
                }, 50);
            }

            this.updateVideoCount();
            // Update dates to initially load most clicked words for whole class duration
            this.fromDate = this.group.start;
            this.toDate = this.group.end;
            this.getMostClickedWords();

            // Get remaining teacher invites
            this.remainingTeacherInvites -= this.group.team_educators.length + this.invites?.length;
        });

    }

    isAdminOrMainEducator(): boolean {
        const mainEducatorId = (this.group.educator) as unknown as string;
        if (this.group && this.loggedUser) {
            return (mainEducatorId === this.loggedUser._id) || this.loggedUser.customer_role === 'admin';
        }
        return false;
    }

    ionViewDidEnter() {
        // Scroll back to save position
        if (this.scrollTopSaved !== null) {
            setTimeout(() => {
                this.logger.debug('scrollToPoint', this.scrollTopSaved);
                this.ionContent.scrollToPoint(0, this.scrollTopSaved);
            }, 50);
        }
    }

    ionViewWillLeave() {
        this.saveCurrentScrollPostion();
        super.ionViewWillLeave();
    }

    updateScrollPosition($event): void {
        // this.logger.debug('scrollTop', $event.detail.scrollTop);
        this.scrollTop = $event.detail.scrollTop;
    }

    saveCurrentScrollPostion(): void {
        this.logger.debug('Saving SCROLL TOP:', this.scrollTop);
        this.scrollTopSaved = this.scrollTop;
    }

    /**
     * Opens video player page for specific video
     *
     * @param videoInfo The videoInfo for the video to be opened
     */
    openVideoPlayerPage(videoInfo: IVideo, clip: IClip, groupVideo: IGroupVideo, showLeaderboard = false) {
        const navigationExtras: NavigationExtras = {
            state: {
                showLeaderboard,
                groupVideo
            },
        };
        if (videoInfo) {
            if (clip) {
                this.navCtrl.navigateForward(
                    `video/${videoInfo.websource_id}/${videoInfo._id}/${this.group._id}/clip/${clip._id}`,
                    navigationExtras
                );
            } else {
                this.navCtrl.navigateForward(
                    `video/${videoInfo.websource_id}/${videoInfo._id}/${this.group._id}`,
                    navigationExtras
                );
            }
        } else {
            this.uiUtils.showErrorAlert(this.translate.instant('video_not_found'));
        }
    }

    /**
     * Opens worksheet select page for specific video
     *
     * @param video The video for which a worksheet will be selected
     */
    openWorksheetSelectPage(video: IGroupVideo) {
        const navigationExtras: NavigationExtras = {
            state: {
                group: this.group,
                groupVideo: video,
            }
        };
        this.navCtrl.navigateForward('worksheet-select', navigationExtras);
    }

    /**
     * Gets the translations for a specific video as a string
     *
     * @param videoInfo The videoInfo of the video for which translations are needed
     * @return The translations of a video
     */
    getTranslations(videoInfo: IVideo): string {
        return videoInfo ? this.uiUtils.translationsToText(videoInfo.translations, videoInfo.originalLang) : '';
    }

    /**
     * Passes videos into class create page for cloned class
     */
    cloneClass($ev) {
        if (this.appData.userCustomer.license.type === 'free') {
            $ev.preventDefault();
            return;
        }
        const navigationExtras: NavigationExtras = {
            state: {
                clonedGroup: this.group,
                classVideos: this.classVideos,
            }
        };
        this.navCtrl.navigateForward('educator-class/new', navigationExtras);
    }

    /**
     * Shows/hides statistics for the class
     */
    toggleStatistics() {
        this.showStatistics = !this.showStatistics;
    }

    /**
     * Loads class videos from the database
     */
    loadVideos() {
        this.videosVisibleCount = this.totalVideosCount = 0;

        this.groupsApi.getVideos(this.group._id).subscribe(response => {
            console.log('Success - getting group videos', response);
            this.classVideos = response.data;
            this.classVideosInfo = this.classVideos.map(v => v.video_id as IVideo);
            this.updateVideoCount();
        }, err => {
            console.log('Error - getting group videos', err);
        });
    }

    /**
     * Updates the number of total and visible videos.
     */
    updateVideoCount() {
        this.totalVideosCount = this.classVideos.length;
        // this.videosVisibleCount = this.classVideos.filter(v => v.assessment_id).length;
        this.videosVisibleCount = this.classVideos.filter(v => v.is_visible).length;
    }

    /**
     * Deletes a video from the group
     */
    async deleteVideoFromGroup(videoId: string) {
        const loader = await this.loadingCtrl.create({
            message: this.translate.instant('deleting_video')
        });
        // Show confirmation message
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('confirmation'),
            subHeader: this.translate.instant('confirm_delete_text'),
            buttons: [
                {
                    text: this.translate.instant('btn_cancel'),
                    role: 'cancel'
                },
                {
                    text: this.translate.instant('btn_ok'),
                    handler: () => {
                        loader.present();
                        this.groupsApi.deleteVideo(this.group._id, videoId).subscribe(response => {
                            console.log('Success - delete video from group', response);

                            // Remove deleted video
                            const index = this.classVideosInfo.findIndex(video => video._id === videoId);
                            if (index > -1) {
                                this.classVideosInfo.splice(index, 1);
                                this.classVideos.splice(index, 1);
                                this.updateVideoCount();
                            }

                            loader.dismiss();
                        }, err => {
                            console.log('Error - delete video from group', err);
                            this.uiUtils.showErrorAlert(this.translate.instant('delete_video_error'));
                            loader.dismiss();
                        });
                    }
                }
            ]
        });
        await alert.present();
    }

    /**
     * Shows confirmation dialog and removes student from class on confirmation
     *
     * @param student Student to be deleted
     */
    async removeStudent(student: IUser) {
        // Show confirmation message
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('confirm_delete_student_header'),
            subHeader: this.translate.instant('confirm_delete_student_text', { firstName: student.first_name, lastName: student.last_name }),
            buttons: [
                {
                    text: this.translate.instant('btn_cancel'),
                    role: 'cancel'
                },
                {
                    text: this.translate.instant('btn_ok'),
                    handler: () => {
                        this.groupsApi.removeStudentFromGroup(this.group._id, student._id).subscribe(response => {
                            console.log('Success - remove student from group', response);

                            if (response.success) {
                                // Update students list
                                this.group.students = this.group.students.filter(s => s._id !== student._id);
                            }

                            const key = response.success ? 'student_removed_success' : 'student_removed_error';
                            this.uiUtils.displayToast(this.translate.instant(key));

                        }, err => {
                            console.log('Error - remove student from group', err);
                        });
                    }
                }
            ]
        });
        await alert.present();
    }

    /**
     * Shows confirmation dialog and removes educator from class on confirmation
     *
     * @param educator Educator to be deleted
     */
    async removeEducator(educator: IEducator) {
        const loader = await this.loadingCtrl.create({
            message: 'Removing educator'
        });
        // Show confirmation message
        const alert = await this.alertCtrl.create({
            header: 'Remove educator',
            message: `Are you sure that you want to remove  ${educator.edId.first_name} ${educator.edId.last_name} from the class?`,
            buttons: [
                {
                    text: this.translate.instant('btn_cancel'),
                    role: 'cancel'
                },
                {
                    text: this.translate.instant('btn_ok'),
                    handler: () => {
                        this.groupsApi.removeEducatorFromGroup(this.group._id, educator.edId._id).subscribe(response => {
                            console.log('Removed educator from group!', response);

                            if (response.success) {
                                // Update educators list
                                //@ts-ignore
                                this.group.team_educators = this.group.team_educators.filter(s => s._id !== educator._id);
                            }

                            const key = response.success ? 'The educator was removed from the class.' : 'The educator could not be removed from the class.';
                            this.uiUtils.displayToast(key);

                            this.remainingTeacherInvites += 1;

                            loader.dismiss();
                        }, err => {
                            console.log('Error - remove educator from group', err);
                            loader.dismiss();
                        });
                    }
                }
            ]
        });
        await alert.present();
    }

    /**
     * Shows confirmation dialog and deactivates the class on confirmation
     */
    async deactivateClass() {
        // Show confirmation message
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('confirm_deactivate_class_header'),
            subHeader: this.translate.instant('confirm_deactivate_class_text', { class: this.group?.name || '' }),
            buttons: [
                {
                    text: this.translate.instant('btn_cancel'),
                    role: 'cancel'
                },
                {
                    text: this.translate.instant('btn_ok'),
                    handler: () => {
                        this.groupsApi.deactivateGroup(this.group._id).subscribe(response => {
                            console.log('Success - deactivate class', response);

                            if (response.success) {
                                // Update students list
                                this.appManager.getUserClasses().subscribe(classesResponse => {
                                    this.appData.groupsForUser = classesResponse.data;
                                });
                            }

                            const key = response.success ? 'class_deactivate_success' : 'class_deactivate_error';
                            this.uiUtils.displayToast(this.translate.instant(key));

                            this.navCtrl.navigateRoot('/catalog/all');

                        }, err => {
                            console.log('Error - deactivate class', err);
                        });
                    }
                }
            ]
        });
        await alert.present();
    }

    /**
     * Gets most clicked words
     */
    getMostClickedWords() {
        // Check from date is before to date
        if (this.fromDate > this.toDate) {
            this.uiUtils.showErrorAlert(this.translate.instant('invalid_dates'));
            return;
        }

        this._activityApi.getMostClickedWords(this.group._id, this.fromDate.substr(0, 10), this.toDate.substr(0, 10)).subscribe(response => {
            if (response.success) {
                this.mostClickedWords = response.counter.slice(0, this.showMaxClickedWords);
                // console.log('getMostClickedWords ', this.mostClickedWords);
            }
        }, err => {
            console.log(err);
        });
    }

    /** Updates the `is_visible` property on the server.
    * @param $event event
    * @param groupVideo GroupVideo object
    */
    async toggleVideoVisibility($event: CustomEvent, groupVideo: IGroupVideo, video: IVideo) {
        const customer = JSON.parse(this.appData.getPreferenceString('logged_user_customer'));
        const customerLicense = customer.license;
        const isChecked = $event.detail['checked'];
        console.log('new value for checked:', isChecked);
        const oldVisibility = groupVideo.is_visible;
        if (isChecked === oldVisibility) {
            console.log('Visiblity hasn\'t changed - doing nothing');
            return;
        }
        const newVisibility = !oldVisibility;

        const canEnableOrWatchPremiumVideo = Utils.canEnableOrWatchPremiumVideo(video, false, this.appData.authenticatedUser, this.appData.userLicense);

        if (!canEnableOrWatchPremiumVideo) {
            if (isChecked) {
                const i18n = await this.translate
                    .get([
                        'premium_functionality_title',
                        'premium_functionality_enable_video_in_class',
                        'btn_cancel',
                        'btn_subscribe',
                    ])
                    .toPromise();
                const alert = await this.alertCtrl.create({
                    header: i18n['premium_functionality_title'],
                    subHeader: i18n['premium_functionality_enable_video_in_class'],
                    buttons: [
                        {
                            text: i18n['btn_cancel'],
                            role: 'cancel',
                        },
                        {
                            text: i18n['btn_subscribe'],
                            handler: () => {
                                if (this.plt.is('cordova')) {
                                    window.open('https://uugot.it/sba/', '_system');
                                } else {
                                    window.open('https://uugot.it/sba/', '_blank');
                                }
                            },
                        },
                    ],
                });
                await alert.present();
            }
            if ($event && $event.target) {
                const checkbox = $event.target as HTMLInputElement;
                checkbox.checked = false; // Uncheck the checkbox
            }
            return;
        }

        groupVideo.is_visible = newVisibility;
        this.updateVideoCount();

        try {
            const res = await this.groupsApi.updateVideoVisibility(groupVideo._id, groupVideo.is_visible, 'check_only').toPromise();
            if (res.success) {
                this.logger.debug('Visiblity was updated');
            } else {
                const message = this.translate.instant('video_visiblity_could_not_be_changed');
                this.uiUtils.displayToast(message);
                // eslint-disable-next-line require-atomic-updates
                groupVideo.is_visible = oldVisibility;
                return;
            }

            if (res.newLangs && res.newLangs.length > 0 && groupVideo.is_visible) {
                this.logger.debug(`Translating video into students' preferred languages (${res.newLangs})`);
                const message = this.translate.instant('translating_video_in_group');
                const loader: HTMLIonLoadingElement = await this.loadingCtrl.create({
                    message,
                });
                await loader.present();
                const resTranslate = await this.groupsApi.translateVideo(groupVideo._id, true).toPromise();
                this.logger.debug('Translating group videos response', resTranslate);
                await loader.dismiss();
            }

        } catch (err) {
            this.logger.error('Error - updating video in group by group id', err);
            this.uiUtils.showErrorAlert(this.translate.instant('video_visiblity_could_not_be_changed'));
            // eslint-disable-next-line require-atomic-updates
            groupVideo.is_visible = oldVisibility;
        }
    }

    get groupDescriptionAsHtml() {
        return this.group ? this.group.description.replace(/\n/g, '<br />') : null;
    }

    /**
     * Copies the class code and an instruction to the clipboard.
     */
    async copyClassCodeToClipboard() {
        const code = this.group.access_code;
        const className = this.group.name;
        const loginLink = `${this.appManager.appBaseUrl}/login/${code}`;
        const i18n = await this.translate.get(['toast_code_copied', 'copy_code_text'], {
            code,
            className,
            loginLink
        }).toPromise();

        this.clipboardService.copyFromContent(i18n['copy_code_text']);

        this.uiUtils.displayToast(i18n['toast_code_copied']);
    }

    isModalOpen = false;
    isEditModalOpen = false;
    addSuccess = false;
    addedEducatorName = '';
    addedEducatorEmail = '';
    loadingAction = false;

    /**
     * Opens a modal to add educators in the class
     */
    async openAddEducatorsModal() {
        this.isModalOpen = true;
    }

    /**
     * Opens a modal to edit educator in the class
     */
    async openEditEducatorsModal(educator) {
        this.currentEditingEducator = educator;
        const currentSubjects = [];
        for (const subject of educator.subjects) {
            const index = this.subjectsLang.findIndex(s=> s._id == subject._id);
            currentSubjects.push(this.subjectsLang[index]);
        }
        this.educatorEditForm.patchValue({ subjects: currentSubjects });
        this.isEditModalOpen = true;
        console.log('current editieducator ', this.currentEditingEducator);
    }

    cancel() {
        this.currentEditingEducator = null;
        this.isModalOpen = false;
        this.addSuccess = false;
    }

    closeEditModal() {
        this.isEditModalOpen = false;
        this.educatorEditForm.reset();
        this.currentEditingEducator = null;
    }

    async confirm() {
        // this.modal.dismiss(null, 'confirm');
        const loader = await this.loadingCtrl.create({
            message: 'Adding educator'
        });
        // Show confirmation message
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('confirmation'),
            message: this.translate.instant('invite_confirmation', { addedEducatorName: `${this.educatorCreateForm.controls.name.value} ${this.educatorCreateForm.controls.lastname.value}` }),
            buttons: [
                {
                    text: this.translate.instant('btn_cancel'),
                    role: 'cancel'
                },
                {
                    text: this.translate.instant('btn_ok'),
                    handler: () => {
                        loader.present();
                        const invite: {email: string, name: string, last_name: string, subjects: string []} = {
                            email: this.educatorCreateForm.controls.email.value,
                            name: this.educatorCreateForm.controls.name.value,
                            last_name: this.educatorCreateForm.controls.lastname.value,
                            subjects: this.educatorCreateForm.controls.subjects.value
                        };
                        this.educatorsApi.inviteToGroup(invite,  this.group._id).subscribe(async response => {

                            if (response.success) {
                                // Update educators list
                                const key = 'The educator was invited to the class.';
                                this.uiUtils.displayToast(key);
                                try {
                                    const invitesResult = await this.getInvites();
                                    this.invites = invitesResult.data;
                                    this.remainingTeacherInvites -= 1;
                                    this.addedEducatorName = `${invite.name} ${invite.last_name}`;
                                    this.addedEducatorEmail = invite.email;
                                    this.addSuccess = true;
                                    this.educatorCreateForm.reset();
                                    if (this.remainingTeacherInvites === 0) {
                                        this.isModalOpen = false;
                                    }
                                } catch (e) {
                                    console.log('Error fetching invites', e);
                                }
                            } else {
                                this.uiUtils.showErrorAlert(`${this.translate.instant('error_page_message')}`);
                            }

                            loader.dismiss();
                        }, err => {
                            console.log('Error - add educator to group', err);
                            loader.dismiss();
                        });
                    }
                }
            ]
        });
        await alert.present();
    }

    onChange(event) {
        console.log('CHANGE: ', event);
    }
    async confirmEdit(educator_id: string) {
        // this.modal.dismiss(null, 'confirm');
        const loader = await this.loadingCtrl.create({
            message: 'Editing educator'
        });
        // Show confirmation message
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('confirmation'),
            message: `Are you sure you want to edit educator's information?`,
            buttons: [
                {
                    text: this.translate.instant('btn_cancel'),
                    role: 'cancel'
                },
                {
                    text: this.translate.instant('btn_ok'),
                    handler: () => {
                        loader.present();
                        this.groupsApi.editEducator(this.group._id, educator_id, this.educatorEditForm.controls.subjects.value).subscribe(response => {
                            console.log('Edited educator information!', response);

                            if (response.success) {
                                // Update educators list
                                location.reload();
                            }

                            const key = response.success ? 'The educator was edited.' : 'The educator could not be edited.';
                            this.uiUtils.displayToast(key);

                            loader.dismiss();
                        }, err => {
                            console.log('Error - edit educator data', err);
                            loader.dismiss();
                        });
                    }
                }
            ]
        });
        await alert.present();
    }

    onWillDismiss(event: Event) {
        const ev = event as CustomEvent<OverlayEventDetail<string>>;
        if (ev.detail.role === 'confirm') {
            // this.message = `Hello, ${ev.detail.data}!`;
        }
        this.addSuccess = false;
    }

    onWillDismissEdit(event: Event) {
        const ev = event as CustomEvent<OverlayEventDetail<string>>;
        if (ev.detail.role === 'confirm') {
            // this.message = `Hello, ${ev.detail.data}!`;
        }
    }

    get colorForVideosVisibleText() {
        if (this.totalVideosCount === 0) {
            return 'darkgrey';
        }
        if (this.videosVisibleCount === 0) {
            return 'red';
        }
        if (this.videosVisibleCount === this.totalVideosCount) {
            return 'green';
        }
        return 'yellowgreen';
    }

    async presentActionMenuPopover(ev: any) {
        this.logger.debug('presentMenuPopover', ev);
        const popover = await this.popoverController.create({
            component: ActionMenuPopoverPage,
            //   cssClass: 'my-custom-class',
            event: ev,
            componentProps: {
                title: this.translate.instant('menu_actions'),
                options: [
                    {
                        key: 'deactivate_class',
                        title: this.translate.instant('remove_class')
                    }
                ] as IPopoverActionItem[]
            },
        });

        popover.onDidDismiss().then(details => {
            this.logger.debug('Overlay dismissed', details);
            if (details?.data?.key === 'deactivate_class') {
                this.logger.debug('User wants to deactivate the class');
                this.deactivateClass();
            }
        });
        return popover.present();
    }

    isVideoExpired(video: IVideo): boolean {
        return Utils.isVideoExpired(video);
    }


    openStatisticsPage(clip: IClip): void {
        this.navCtrl.navigateForward(`educator-class/${this.group._id}/clip/${clip._id}/tasks-statistics`);
    }

    async openLeaderboardPage() {
        this.navCtrl.navigateForward(`class/${this.group._id}/leaderboard`);
        // const modal = await this.modalCtrl.create({
        //     component: ClassLeaderboardPage,
        //     showBackdrop: true,
        // });
        // await modal.present();
    }

    async generateLeaderboardForClip(videoInfo: IVideo, clip: IClip, groupVideo: IGroupVideo) {
        const generateAndOpenPlayer = async () => {
            try {
                const leaderboard = await this.clipsApi.generateLeaderboard(clip._id).toPromise();
                if (leaderboard) {
                    // eslint-disable-next-line require-atomic-updates
                    clip.leaderboard = leaderboard.data.leaderboard;
                }
                this.openVideoPlayerPage(videoInfo, clip, groupVideo, true);
            } catch (err) {
                console.log('Error - generating leaderboard', err);
                this.uiUtils.showErrorAlert(`${this.translate.instant('generate_leaderboard_error')} [${err.error?.message}]`);
            }
        };

        // Show confirmation message
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('confirm_generate_leaderboard_header'),
            message: this.translate.instant('confirm_generate_leaderboard_message'),
            buttons: [
                {
                    text: this.translate.instant('btn_cancel'),
                    role: 'cancel'
                },
                {
                    text: this.translate.instant('btn_yes'),
                    handler: async () => {
                        await generateAndOpenPlayer();
                    }
                }
            ]
        });
        await alert.present();

    }

    get maxLimitStudentsReached(): boolean {
        if (!this.group || isNil(this.group.maxStudents)) {
            return false;
        } else {
            return this.group.students?.length >= this.group.maxStudents;
        }
    }

    async refreshContent() {
        const videos = await this.groupsApi.getVideos(this.group._id).toPromise();
        this.classVideos = videos.data; // IGroupVideo[]
        this.totalVideosCount = this.classVideos.length;
        this.classVideosInfo = this.classVideos.map((v) => v.video_id as IVideo); // IVideo[]
        this.updateVideoCount();
        this.translate.get('group_videos_were_reloaded').subscribe((i18n) => {
            this.uiUtils.displayToast(i18n);
        });
    }

    async removeInvite(invite: IInvitesToGroups) {

        const alert = await this.alertCtrl.create({
            header: 'Remove invitation for educator',
            message: `Are you sure that you want to remove the invitation for ${invite.email}`,
            buttons: [
                {
                    text: this.translate.instant('btn_cancel'),
                    role: 'cancel'
                },
                {
                    text: this.translate.instant('btn_ok'),
                    handler: () => {
                        this.loadingAction = true;
                        this.educatorsApi.updateInvite(invite._id, InviteActions.EXPIRE).subscribe(async response => {
                            this.loadingAction = false;

                            if (response.success) {
                                try {
                                    const invitesResult = await this.getInvites();
                                    this.invites = invitesResult.data;
                                } catch (e) {
                                    console.log('Error fetching invites', e);
                                }
                                // Update educators list
                            }

                            const key = response.success ? 'The educator was removed from the class.' : 'The educator could not be removed from the class.';
                            this.uiUtils.displayToast(key);

                            this.remainingTeacherInvites += 1;
                        }, err => {
                            this.loadingAction = false;
                            console.log('Error - remove educator from group', err);
                        });
                    }
                }
            ]
        });
        await alert.present();
    }

    getTitleForPendingInvites(invites: IInvitesToGroups[]) {
        if (invites && invites.length > 0) {
            return 'Pending Invites'
        }
        return 'No Pending Invites'
    }

    getSubjectsNames(subjects: ISubject[]) {
        if (!subjects || subjects.length === 0) {
            return '';
        }

        const names: string[] = subjects.map(subject => subject.name['en']);
        return names.join(', ');
    }

    openVideosAvailableBasket() {
        this.navCtrl.navigateForward(`educator-class/details/${this.group._id}/videos/available`);
    }

    isMobile(): boolean {
        // this.logger.debug('mobile %s, landscape %s', this.plt.is('mobile'), this.plt.isLandscape());
        return this.plt.is('mobile');
    }
}
