import { Component, Input } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

@Component({
    selector: 'app-create-class-complete-onboarding-modal',
    templateUrl: './create-class-complete-onboarding-modal.component.html',
    styleUrls: ['./create-class-complete-onboarding-modal.component.scss'],
})
export class CreateClassCompleteOnboardingModalComponent {
    loadingImage: boolean = true;
    @Input() isModalOpen: boolean;

    constructor(private modalCtrl: ModalController, public plt: Platform) {}

    close() {
        this.modalCtrl.dismiss();
    }

    showSpinner() {
        this.loadingImage = true;
    }

    hideSpinner() {
        this.loadingImage = false;
    }
}
