<ion-header>
    <ion-toolbar color="blue">
        <ion-buttons slot="start">
            <ion-button (click)="navigateBack()">
                <ion-icon slot="icon-only" name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>{{ "suggest_other_translation" | translate }}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="submitTranslation()">
                <ion-icon slot="icon-only" name="checkmark"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" *ngIf="translation">
    <ion-card *ngIf="existingRule" padding margin-bottom>
        <ion-icon slot="start" name="warning"></ion-icon>
        <ion-text size="medium"> {{ "existing_rule" | translate }}</ion-text>
        <br><br>
        <ion-text color="dark">{{ "existing_rule_text" | translate }}</ion-text>
        <br><br>
        <ion-text color="dark">
            {{ translation.oriWord  + ' --> ' + existingRule.toWordFix }}
        </ion-text>
        <br><br>
        <ion-text color="dark">
            {{ "context" | translate }}: {{ translation.oriText }} <!--TODO is this correct?-->
        </ion-text>
    </ion-card>

    <form [formGroup]="correctionForm">
        <ion-label padding>
            {{ "original_word" | translate }}
            ({{ ('lang_' + translation.from) | translate }}): <strong>{{ translation.fromWord }}</strong>
        </ion-label>
        <br><br>
        <ion-label padding>
            {{ "translated_word" | translate }}
            ({{ ('lang_' + translation.to) | translate }}): <strong>{{ translation.toWord }}</strong>
        </ion-label>
        <br><br>
        <ion-label color="dark" *ngIf="translation.fromToken && (translation.fromToken.pos || translation.fromToken.lemma)">
            <span class="mat-caption">{{ "pos" | translate }} / {{ "lemma" | translate }}:</span>&nbsp;
            <strong>{{ translation.fromToken.pos }} / {{ translation.fromToken.lemma }}</strong>
        </ion-label>

        <ion-item class="ion-margin-top">
            <ion-label [class.red-text]="!correctionForm.valid && submitAttempt" position="stacked">
                {{ "suggest_new_translation" | translate }} *
            </ion-label>
            <ion-input autofocus type="text" formControlName="suggestion">
            </ion-input>
        </ion-item>

        <ion-item lines="none">
            <ion-label>{{ "case_sensitive" | translate }}</ion-label>
            <ion-checkbox slot="start" color="blue" formControlName="caseSensitive"></ion-checkbox>
        </ion-item>

        <ion-item>
            <ion-label position="stacked">{{ "comment" | translate }}</ion-label>
            <ion-textarea formControlName="comment"></ion-textarea>
        </ion-item>
    </form>
</ion-content>
