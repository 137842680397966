<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-menu-toggle>
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
        <ion-title>{{'help_section' | translate }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <ion-grid fixed>
        <ion-row class="download-guides-cards">
            <ion-col size="12">
                <h3 style="margin-top: 0px;">So verwendest du uugot.it sCOOLing!</h3>
                <p>
                    <img src="assets/img/help/GDPR-Conforme.png" alt="GDPD conform" title="DSGVO-konform" class="gdpr-stamp"/>
                    Hier findest du Anleitungen, wie du uugot.it sCOOLing im Unterricht einsetzt.
                    uugot.it sCOOLing bietet dir Videos für alle gängigen Unterrichtsfächer mit didaktischen Begleitmaterialien und interaktiven multilingualen Untertiteln, damit auch SchülerInnen mit anderen Erstsprachen den Inhalten leicht folgen können - egal ob im Präsenzunterricht oder Distance Learning.
                </p>
            </ion-col>
            <!-- <ion-col size="3" size-md="2" size-lg="2" size-xl="2">

            </ion-col> -->
        </ion-row>

        <ion-row class="recommended-by">
            <ion-col size="12">

                <small>{{'recommended_by' | translate}}:</small>

                <a href="https://www.bmbwf.gv.at" target="_blank" rel="nofollow">
                    <img class="about-partner-logo" src="assets/img/about/BMBWF_Logo_srgb_440x147_gray.png"
                    alt="Bundesministerium für Bildung, Wissenschaft und Forschung"
                    title="Bundesministerium für Bildung, Wissenschaft und Forschung" />
                </a>
                <a href="https://www.oedaf.at" target="_blank" rel="nofollow">
                    <img class="about-partner-logo" src="assets/img/about/oedaf_logo_gray.png"
                    alt="ÖDaF - Österreichischer Fachverband für Deutsch als Fremdsprache/Zweitsprache"
                    title="ÖDaF - Österreichischer Fachverband für Deutsch als Fremdsprache/Zweitsprache" />
                </a>

            </ion-col>
        </ion-row>


        <ion-row class="download-guides-cards">
            <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="6" >
                <ion-card href="https://static-assets-cdn.uugot.it/documents/sCOOLing/sCOOLing_lass_uns_loslegen.pdf" target="_blank">
                    <img src="assets/img/help/maedchen_lernen_farbe.jpg" />
                    <ion-card-header>
                      <ion-card-subtitle><ion-icon name="document-outline"></ion-icon> PDF</ion-card-subtitle>
                      <ion-card-title>Lass uns loslegen!</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        So funktioniert uugot.it sCOOLing.
                    </ion-card-content>
                </ion-card>
            </ion-col>
            <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="6" >
                <ion-card href="https://static-assets-cdn.uugot.it/documents/sCOOLing/sCOOLing_installation_von_uugotit.pdf" target="_blank">
                    <img src="assets/img/help/pupil_ipad_farbe.jpg" />
                    <ion-card-header>
                      <ion-card-subtitle><ion-icon name="document-outline"></ion-icon> PDF</ion-card-subtitle>
                      <ion-card-title>Installation von uugot.it</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        So verwendest du uugot.it am Laptop/PC, Android- oder iOS-Gerät.
                    </ion-card-content>
                </ion-card>
            </ion-col>
        </ion-row>

        <ng-container *ngIf="videos === null || videos?.length > 0">
            <h2 id="academy-videos" style="margin-top: 24px;">Academy Videos</h2>
            <p>
                {{ 'academy_videos_infotext' | translate }}
            </p>
        </ng-container>


        <div *ngIf="videos === null; else showVideos" class="ion-text-center ion-margin-top ion-margin-bottom">
            <div style="margin-top: 5px; margin-bottom: 5px;">
                <ion-spinner name="circles"></ion-spinner>
            </div>
            <div>{{'loading_please_wait' | translate}}</div>
        </div>

        <ng-template #showVideos>
            <ion-row class="academy-videos">
                <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="4" *ngFor="let video of videos">
                    <app-video-catalog-card [video]="video" [watchedVideos]="watchedVideos">
                    </app-video-catalog-card>
                </ion-col>
            </ion-row>
        </ng-template>

        <div class="ion-margin-top ion-margin-bottom" style="margin-top: 35px; max-width: 800px; margin-left: auto; margin-right: auto;">
            <ion-item lines="none">
                <ion-thumbnail slot="start">
                    <img src="assets/img/help/annemarie_duperron.jpg">
                </ion-thumbnail>
                <ion-label>
                    <h2 class="ion-text-wrap">"Meine Kursteilnehmer*innen haben durch die Einbindung von TV-Sendungen in uugot.it nicht nur einfacher Deutsch gelernt,
                        das Interesse an Lerninhalten wurde dadurch enorm gesteigert. Es war, als hätte man einen Schalter umgelegt. Es hat ihnen richtig Spaß gemacht."</h2>
                    <p class="ion-text-wrap">- Mag. Annemarie Duperron, DaF/DaZ-Lehrende Jugendcollege Wien</p>
                </ion-label>
            </ion-item>
        </div>

        <div class="ion-padding alert alert-dark slack-block" style="margin-top: 35px;">
            <ion-row>
                <ion-col size="12" size-md="8" size-lg="8" size-xl="9">
                    <h2>Community für Lehrende</h2>
                    <p>
                        Du möchtest wissen, wer uugot.it sCOOLing sonst noch nutzt? Wie andere Lehrende uugot.it sCOOLing einsetzen? Dich mit KollegInnen austauschen und immer up to date sein, wenn neue Funktionen oder didaktisches Material verfügbar sind?
                    </p>

                    <p>
                        Dafür haben wir auf der Kommunikationsplattform Slack einen virtuellen Ort geschaffen, an dem du dich mit anderen Lehrkräften vernetzen und austauschen kannst und über Neuerungen und Erweiterungen informiert wirst. Du kannst dort auch mit dem Team von uugot.it in Kontakt treten, wenn du mal eine Frage hast.
                    </p>
                    <p>
                        Lade dir bitte dazu die Slack-Applikation auf dein Smartphone (<a href="https://play.google.com/store/apps/details?id=com.Slack" target="_blank">Android - Play Store</a> / <a href="https://itunes.apple.com/app/slack-team-communication/id618783545" target="_blank">iOS - App Store</a>) oder hol sie dir auf deinen <a href="https://slack.com/intl/de-de/help/categories/360000049043-Erste-Schritte#slack-app-herunterladen" target="_blank">Desktop</a>.
                        Hier klicken, um dem uugot.it-Educators-Channel-beizutreten:
                    </p>
                    <p>
                        <ion-button color="blue" href="https://join.slack.com/t/uugotit-educators/shared_invite/zt-e6r6j8k2-dHet74YDgh9FkP0XkZ30QQ" target="_blank">
                            Beitreten
                        </ion-button>
                    </p>
                </ion-col>
                <ion-col size="12" size-md="4" size-lg="4" size-xl="3">
                    <img src="assets/img/help/ed_chat_channel_slack_smartphone_small_cropped.gif" alt="Slack Channel"/>
                </ion-col>
            </ion-row>

        </div>

        <h2 id="downloads" class="ion-margin-top" style="margin-top: 35px;">Downloads</h2>

        <ion-row class="download-guides-cards">

            <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="6" >
                <ion-card>
                    <ion-card-header>
                      <ion-card-title>Formblatt Didaktisierung</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        Das Formblatt Didaktisierung dient Lehrenden als Vorlage für die Erstellung von eigenen Didaktisierungen.
                        Im Infosheet finden sich ergänzende Erläuterungen zum Formblatt.
                    </ion-card-content>
                    <ion-item href="https://static-assets-cdn.uugot.it/documents/sCOOLing/Formblatt_Didaktisierungen_zum_Ausfuellen.docx" target="_blank">
                        <ion-icon name="document-text-outline" slot="start"></ion-icon>
                        <ion-label>
                            Formblatt
                            <ion-chip>
                                <ion-label>.DOCX</ion-label>
                            </ion-chip>
                        </ion-label>
                    </ion-item>
                    <ion-item href="https://static-assets-cdn.uugot.it/documents/sCOOLing/Infosheet_Formblatt_Didaktisierungen.pdf" target="_blank">
                        <ion-icon name="information-circle-outline" slot="start"></ion-icon>
                        <ion-label>
                            Infosheet
                            <ion-chip>
                                <ion-label>.PDF</ion-label>
                            </ion-chip>
                        </ion-label>
                    </ion-item>
                </ion-card>
            </ion-col>

            <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="6" >
                <ion-card>
                    <ion-card-header>
                      <ion-card-title>Arbeitsblatt beliebiges Video</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        Dieses generische Arbeitsblatt kann bei beliebigen Videos eingesetzt werden. Es eignet sich insbesondere für die induktive Annäherung der Lernenden an die Arbeit mit interaktiven Untertiteln im Sprachunterricht.
                        Der didaktische Kommentar (Infosheet) erläutert Einsatzmöglichkeiten und Aufgabenstellungen.
                    </ion-card-content>
                    <ion-item href="https://static-assets-cdn.uugot.it/documents/sCOOLing/AB_Video_Freie_Wahl.docx" target="_blank">
                        <ion-icon name="document-text-outline" slot="start"></ion-icon>
                        <ion-label>
                            Arbeitsblatt
                            <ion-chip>
                                <ion-label>.DOCX</ion-label>
                            </ion-chip>
                        </ion-label>
                        <!-- <ion-note slot="end">.DOCX</ion-note> -->
                    </ion-item>
                    <ion-item href="https://static-assets-cdn.uugot.it/documents/sCOOLing/L_Video_Freie_Wahl.pdf" target="_blank">
                        <ion-icon name="information-circle-outline" slot="start"></ion-icon>
                        <ion-label>
                            Infosheet
                            <ion-chip>
                                <ion-label>.PDF</ion-label>
                            </ion-chip>
                        </ion-label>
                        <!-- <ion-note slot="end">.PDF</ion-note> -->
                    </ion-item>
                </ion-card>
            </ion-col>

            <!-- <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="6" >
                <ion-card href="https://static-assets-cdn.uugot.it/documents/sCOOLing/Formblatt_Didaktisierungen_zum_Ausfuellen.docx" target="_blank">
                    <ion-card-header>
                      <ion-card-subtitle><ion-icon name="document-outline"></ion-icon> DOCX</ion-card-subtitle>
                      <ion-card-title>Formblatt Didaktisierung</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        Das Formblatt Didaktisierung dient Lehrenden als Vorlage für die Erstellung von eigenen Didaktisierungen.
                    </ion-card-content>
                </ion-card>
            </ion-col>
            <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="6" >
                <ion-card href="https://static-assets-cdn.uugot.it/documents/sCOOLing/Infosheet_Formblatt_Didaktisierungen.pdf" target="_blank">
                    <ion-card-header>
                      <ion-card-subtitle><ion-icon name="document-outline"></ion-icon> PDF</ion-card-subtitle>
                      <ion-card-title>Infosheet "Formblatt Didaktisierung"</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        Im Infosheet finden sich ergänzende Erläuterungen zum "Formblatt Didaktisierung".
                    </ion-card-content>
                </ion-card>
            </ion-col>
            <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="6" >
                <ion-card href="https://static-assets-cdn.uugot.it/documents/sCOOLing/AB_Video_Freie_Wahl.docx" target="_blank">
                    <ion-card-header>
                      <ion-card-subtitle><ion-icon name="document-outline"></ion-icon> DOCX</ion-card-subtitle>
                      <ion-card-title>Arbeitsblatt beliebiges Video</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        Dieses generische Arbeitsblatt kann bei beliebigen Videos eingesetzt werden. Es eignet sich insbesondere für die induktive Annäherung der Lernenden an die Arbeit mit interaktiven Untertiteln im Sprachunterricht.
                    </ion-card-content>
                </ion-card>
            </ion-col>
            <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="6" >
                <ion-card href="https://static-assets-cdn.uugot.it/documents/sCOOLing/L_Video_Freie_Wahl.pdf" target="_blank">
                    <ion-card-header>
                      <ion-card-subtitle><ion-icon name="document-outline"></ion-icon> PDF</ion-card-subtitle>
                      <ion-card-title>Didaktischer Kommentar "Arbeitsblatt beliebiges Video"</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        Der didaktische Kommentar zum "Arbeitsblatt beliebiges Video" erläutert Einsatzmöglichkeiten und Aufgabenstellungen bei die Arbeit mit Videos nach freier Wahl der Lernenden.
                    </ion-card-content>
                </ion-card>
            </ion-col> -->
        </ion-row>


        <h2 class="ion-margin-top" style="margin-top: 35px;">FAQ zu uugot.it sCOOLing</h2>

        <ion-row>
            <ion-col size="12" size-md="6" size-xl="4" *ngFor="let faq of faqs">
                <ion-card>
                    <ion-card-header>
                    <ion-card-title>{{faq.question}}</ion-card-title>
                    </ion-card-header>
                    <ion-card-content [innerHTML]="faq.answer">
                    </ion-card-content>
                </ion-card>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
