import { Injectable } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { IActivityEvent } from '../interfaces/IActivity';
import { AppData } from './app-data.service';
import { Constants } from '../app.constants';
import { ActivityApi } from './api/activity-api.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
    providedIn: 'root'
})
export class ActivityService {
    private _eventsSubscription: Subscription;

    private readonly UPLOAD_INTERVAL_SECONDS = 20;

    constructor(
        private _appData: AppData,
        private _constants: Constants,
        private _activityApi: ActivityApi,
        private _logger: NGXLogger
    ) {
        // Do nothing.
    }

    /**
     * Initialize events on student login
     */
    initializeEventsService() {
        this._logger.debug('ActivityService SUBSCRIBE');
        if (!this._eventsSubscription) {
            this._eventsSubscription = interval(this.UPLOAD_INTERVAL_SECONDS * 1000).subscribe(
                _ => {
                    this.uploadLocallyStoredEvents();
                }
            );
        }
    }

    /**
     * Adds an activity event to the localStorage array of events.
     * @param event The event to be added
     */
    saveEventToStorage(event: IActivityEvent) {
        const storageEvents = JSON.parse(
            this._appData.getPreferenceString(this._constants.pref.EVENTS, '[]')
        );
        storageEvents.push(event);
        this._appData.savePreferenceString(
            this._constants.pref.EVENTS,
            JSON.stringify(storageEvents)
        );
    }

    /**
     * This function is called once every 15 seconds or before user closes the application.
     * It saves the events array in localStorage to the server if it exists.
     */
    async uploadLocallyStoredEvents() {
        if (!this._appData.isStudent()) {
            this._logger.trace(
                'ActivityService.uploadLocallyStoredEvents() - user is not a student - not uploading'
            );
            return;
        }
        this._logger.debug('ActivityService.uploadLocallyStoredEvents() at', new Date());
        const storageEvents = JSON.parse(
            this._appData.getPreferenceString(this._constants.pref.EVENTS, '[]')
        );
        if (storageEvents.length) {
            try {
                const response = await this._activityApi.saveEvents(storageEvents).toPromise();
                this._logger.debug('ActivityService.uploadLocallyStoredEvents() response', response);
                if (response.success) {
                    // Delete events from localStorage on success
                    this._appData.savePreferenceString(this._constants.pref.EVENTS, '[]');
                }
            } catch (err) {
                console.error(err);
            }
        } else {
            this._logger.debug('ActivityService.uploadLocallyStoredEvents() Nothing to do here...');
        }
    }

    /**
     * Stops sending events to server after user logs off
     */
    eventsServiceUnsubscribe() {
        this._logger.debug('ActivityService UNSUBSCRIBE', new Date());
        if (this._eventsSubscription) {
            this._eventsSubscription.unsubscribe();
        }
    }
}
