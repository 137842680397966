<ion-header>
    <ion-toolbar [color]="appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-menu-toggle>
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
        <ion-title>{{'menu_login_student' | translate}}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" >
    <div class="ion-text-center">
        <img src="assets/icon/launcher-icon-3x.png" class="logoWithState" alt="uugot.it - learn languages while watching TV"/>
        <h2>Hi Student!<br>{{ "enter_code" | translate }}</h2>
    </div>
    <form [formGroup]="classOpenForm" (ngSubmit)="openUserSelectPage()" class="classOpenForm">
        <ion-grid fixed>
            <ion-list>
                <!-- <ion-searchbar formControlName="classCode" class="ion-padding-start"
                    [placeholder]="'enter_code_here' | translate" autocapitalize="characters"></ion-searchbar>
                <ion-item lines="none" *ngIf="!classOpenForm.controls.classCode.valid && submitAttempt">
                    <p>{{ "class_code_required" | translate }}</p>
                </ion-item> -->

                <ion-item class="ion-no-padding">
                    <ion-label position="floating">{{ "enter_code_here" | translate }}</ion-label>
                    <ion-input type="text" formControlName="classCode" autocapitalize="characters" #classCodeInput></ion-input>
                </ion-item>
                <ion-label class="validator-errors" color="danger"
                    *ngIf="!classOpenForm.controls.classCode.valid && submitAttempt">
                    <p>{{ "class_code_required" | translate }}</p>
                </ion-label>

                <!--
                <ion-item>
                    <ion-label [class.red-text]="!classOpenForm.controls.language.valid && submitAttempt">
                        {{ "mother_tongue" | translate }}
                    </ion-label>
                    <ion-select formControlName="language">
                        <ion-select-option value="en">{{ "lang_en" | translate }}</ion-select-option>
                        <ion-select-option value="de">{{ "lang_de" | translate }}</ion-select-option>
                        <ion-select-option value="es">{{ "lang_es" | translate }}</ion-select-option>
                        <ion-select-option value="ar">{{ "lang_ar" | translate }}</ion-select-option>
                        <ion-select-option value="ru">{{ "lang_ru" | translate }}</ion-select-option>
                        <ion-select-option value="tr">{{ "lang_tr" | translate }}</ion-select-option>
                        <ion-select-option value="hi">{{ "lang_hi" | translate }}</ion-select-option>
                        <ion-select-option value="fr">{{ "lang_fr" | translate }}</ion-select-option>
                        <ion-select-option value="ro">{{ "lang_ro" | translate }}</ion-select-option>
                    </ion-select>
                    <ion-icon style="display:none;" size="large" name="information-circle" tooltip="{{ 'student_tooltip' | translate }}" slot="end" positionV="bottom" event="hover"></ion-icon>
                </ion-item>
                <div class="motherTongueNote"><small>{{ "student_tooltip" | translate }}</small></div>
                -->

                <div class="ion-text-center">
                    <ion-button color="primary" class="ion-margin-top" type="submit">
                        {{ "open_class" | translate }}
                    </ion-button>
                </div>
            </ion-list>
        </ion-grid>
    </form>

    <!-- <ion-grid fixed *ngIf="appData.groupsForUser" class="ion-text-center">
        <div>
            <ion-label class="ion-margin-top">
                {{ "add_class_message" | translate : { className: appData.groupsForUser.join('\n') } }}
            </ion-label>
        </div>
        <div>
            <ion-button *ngIf="appData.groupsForUser.length === 1" color="light" class="ion-margin-top" (click)="removeAllClasses()">
                {{ "btn_remove_class" | translate }}
            </ion-button>
            <ion-button *ngIf="appData.groupsForUser.length > 1" color="light" class="ion-margin-top" (click)="removeAllClasses()">
                {{ "btn_remove_all_classes" | translate }}
            </ion-button>
        </div>
    </ion-grid> -->
</ion-content>
