import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AppData } from 'src/app/services/app-data.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ModalController } from '@ionic/angular';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { TasksApi } from 'src/app/services/api/tasks.service';
import { ITask, ETaskType } from 'src/app/interfaces/ITask';
import { IVideo } from 'src/app/interfaces/IVideo';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { IClip } from 'src/app/interfaces/IClip';

export interface IVideoPlayerAddQuestionPageResult {
    addedTasks?: ITask[];
}

@Component({
    selector: 'app-video-player-add-question',
    templateUrl: './video-player-add-question.page.html',
    styleUrls: ['./video-player-add-question.page.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerAddQuestionPage implements OnInit {
    @Input() public video: IVideo;
    @Input() public excludeTaskIds: string[];
    @Input() public clip: IClip;
    public tasks: ITask[];
    // Result data needs to be passed in as a reference because we also save
    // data if modal is dismissed via backdrop (in that case dismiss() is not called!)
    @Input() public resultData: IVideoPlayerAddQuestionPageResult;

    taskTypeEnum: typeof ETaskType = ETaskType;

    constructor(
        private analytics: AnalyticsService,
        public appData: AppData,
        private logger: NGXLogger,
        private modalCtrl: ModalController,
        public uiUtils: UiUtils,
        public tasksApi: TasksApi,
        public translate: TranslateService
    ) {
        // Do nothing.
    }

    ngOnInit() {
        document.body.classList.toggle('dark', true);
        if (!this.resultData) {
            this.resultData = {
                addedTasks: [],
            };
        }
        this.loadTasks();
        this.logger.debug(`VideoPlayerAddQuestionPage.ngOnInit()`);
    }

    async loadTasks() {
        const tasksResponse = await this.tasksApi
            .getTasksByVideo(this.video._id, 0, 200)
            .toPromise();
        this.tasks = tasksResponse.data;
        this.tasks = this.tasks.filter((t) => {
            if (this.excludeTaskIds?.includes(t._id)) {
                return false;
            }
            // Check if task is within cropped video
            if (this.clip) {
                if (!_.isNil(this.clip.cropStart) && t.position < this.clip.cropStart) {
                    return false;
                }
                if (!_.isNil(this.clip.cropEnd) && t.position > this.clip.cropEnd) {
                    return false;
                }
            }
            return true;
        });
    }

    dismiss() {
        document.body.classList.toggle('dark', false);
        this.modalCtrl.dismiss(this.resultData);
    }

    async onAddAnswerClicked(task: ITask) {
        this.excludeTaskIds.push(task._id);
        this.resultData.addedTasks.push(task);
        if (this.tasks.length === this.resultData.addedTasks.length) {
            // Close dialog once all tasks have been added
            this.dismiss();
        }
    }

    /**
     * Generates a string for all the answers of a task.
     * @param task the task
     * @param cssClassForCorrect a css class that will be set for correct answers (optional)
     * @returns the answers text, for example "(1/2) Antwort A / Antwort B"
     */
    taskAnswersToString(task: ITask, cssClassForCorrect?: string): string {
        return task.answers
            ?.map((t) => {
                if (cssClassForCorrect && t.isCorrect) {
                    return `<span class="${cssClassForCorrect}">${t.text}</span>`;
                } else {
                    return `${t.text}`;
                }
            })
            .join(' / ');
    }

    numberOfCorrectAnswers(task: ITask): number {
        return task.answers?.filter((t) => t.isCorrect).length;
    }
}
