<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-menu-toggle>
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
        <ion-title>{{ "settings" | translate }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <!-- We use a grid to have a fixed width once the screen gets larger -->
    <ion-grid class="ion-no-padding" fixed>
        <ion-row>
            <ion-col col-12>
                <ion-list>
                    <!-- VIDEO QUALITY: -->
                    <ion-item>
                        <ion-label>{{ "video_quality" | translate }}</ion-label>
                        <ion-select [(ngModel)]="settingsModel[this.constants.pref.VIDEO_QUALITY]" (ionChange)="onChangeValue(this.constants.pref.VIDEO_QUALITY)">
                            <ion-select-option value="low">{{ "quality_1" | translate }}</ion-select-option>
                            <ion-select-option value="medium">{{ "quality_2" | translate }}</ion-select-option>
                            <ion-select-option value="high">{{ "quality_3" | translate }}</ion-select-option>
                            <ion-select-option value="veryHigh">{{ "quality_4" | translate }}</ion-select-option>
                        </ion-select>
                    </ion-item>

                    <!-- LANGUAGE: -->
                    <ion-item>
                        <ion-label>{{ "language_app" | translate }}</ion-label>
                        <ion-select [(ngModel)]="settingsModel[this.constants.pref.LANGUAGE]" (ionChange)="onChangeValue(this.constants.pref.LANGUAGE)">
                            <ion-select-option value="system">
                                {{ "lang_system" | translate:{"language": systemLanguageName} }}
                            </ion-select-option>
                            <ion-select-option value="en">{{ "lang_en" | translate }}</ion-select-option>
                            <ion-select-option value="de">{{ "lang_de" | translate }}</ion-select-option>
                        </ion-select>
                    </ion-item>

                    <!--
                    <ion-item>
                        <ion-label>{{ "translation_type" | translate }}</ion-label>
                        <ion-select [(ngModel)]="settingsModel[this.constants.pref.TRANSLATION_TYPE]" (ionChange)="onChangeValue(this.constants.pref.TRANSLATION_TYPE)">
                            <ion-select-option value="0">{{ "default" | translate }}</ion-select-option>
                            <ion-select-option value="1">{{ "word_by_word" | translate }}</ion-select-option>
                        </ion-select>
                    </ion-item>
                    -->

                    <ion-item *ngIf="languages && languages.length > 0">
                        <ion-label>{{ "translation_language" | translate }}</ion-label>
                        <ion-select [(ngModel)]="settingsModel[this.constants.pref.TRANSLATION_LANG]" (ionChange)="onChangeValue(this.constants.pref.TRANSLATION_LANG)">
                            <ion-select-option *ngFor="let lang of languages" value="{{lang.code}}">
                                <!--{{lang.name_native }} ({{ lang.name }})-->
                                {{lang['displayName']}}
                            </ion-select-option>
                        </ion-select>
                    </ion-item>

<!--                    <ion-item *ngIf="videoSourceValues && videoSourceValues.length > 0">-->
<!--                        <ion-label>{{ "video_source" | translate }}</ion-label>-->
<!--                        <ion-select [(ngModel)]="settingsModel[this.constants.pref.VIDEO_SOURCE]" (ionChange)="onChangeValue(this.constants.pref.VIDEO_SOURCE)">-->
<!--                            <ion-select-option *ngFor="let source of videoSourceValues" value="{{source.id}}">-->
<!--                                {{ source.name }}-->
<!--                            </ion-select-option>-->
<!--                        </ion-select>-->
<!--                    </ion-item>-->
                </ion-list>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
