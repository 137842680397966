<ion-header>
   <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
       <ion-buttons slot="start">
           <ion-button *ngIf="class_id" (click)="navigateBack()">
               <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
           </ion-button>
           <ion-menu-toggle *ngIf="!class_id">
               <ion-button>
                   <ion-icon slot="icon-only" name="menu"></ion-icon>
               </ion-button>
           </ion-menu-toggle>
       </ion-buttons>
       <ion-title>{{ "flash_cards" | translate }}<span *ngIf="wordCatalog"> ({{index+1}} / {{wordCatalog.length}})</span></ion-title>
       <ion-buttons slot="end">
           <ion-button (click)="shuffleButtonClicked()" title="{{ 'shuffle_cards' | translate }}"
               [disabled]="!wordCatalog || wordCatalog.length === 0">
               <ion-icon slot="icon-only" name="shuffle"></ion-icon>
           </ion-button>
       </ion-buttons>
   </ion-toolbar>
</ion-header>

<ion-content>
   <ion-progress-bar type="indeterminate" *ngIf="!wordCatalog"></ion-progress-bar>
   <p class="ion-padding ion-text-center" *ngIf="wordCatalog && wordCatalog.length === 0">{{ "word_catalog_empty_info" | translate }}</p>
   <swiper-container
       [hidden]="!wordCatalog || wordCatalog.length === 0"
       id="swiper"
       (slidechangetransitionend)="slideChanged()">
       <swiper-slide
           *ngFor="let word of wordCatalog; let i = index"
           [attr.data-index]="i" class="flip-container swiper-zoom-container"
           onclick="this.classList.toggle('switched');"
           (click)="cardClicked(word)">
           <div class="slide-zoom">
               <div class="flipper">
                   <ion-card class="word-catalog-card front flash-card">
                       <ion-card-header class="card-original-word">
                           {{getFromWord(word)}}
                       </ion-card-header>
                   </ion-card>
                   <ion-card class="word-catalog-card back flash-card">
                       <ion-card-header class="card-translated-word" [innerHTML]="splitWordIntoLinesJoin(word)">
                       </ion-card-header>
                   </ion-card>
               </div>
               <ion-label class="flash-card-sentence" [style.visibility]="getVisibility(word)">
                   {{ beforeWord[i] }}
                   <span class="word-catalog-card-highlight">{{ wordFromSentence[i] }}</span>
                   {{ afterWord[i] }}
               </ion-label>
           </div>
       </swiper-slide>
   </swiper-container>

   <div *ngIf="!plt.is('mobile') && wordCatalog" class="slide-arrows-container">
       <ion-icon name="arrow-back-circle" class="slide-back-arrow" (click)="slidePrev()" *ngIf="index > 0"></ion-icon>
       <ion-icon name="arrow-forward-circle" class="slide-forward-arrow" (click)="slideNext()"
           *ngIf="index < wordCatalog.length - 1"></ion-icon>
   </div>
</ion-content>
