/* eslint-disable key-spacing */
export const environment = {
    production          : true,
    hmr                 : false,
    spyderBaseUrl       : 'https://spyder3-stage.uugot.it/v3',
    serviceApiBaseUrl   : 'https://service-api-stage.uugot.it/v3',
    appBaseUrl          : 'https://app-stage.uugot.it',
    environmentName     : 'STAGE',

    /**
     * Where the files are uploaded to ('https://uugotitdevstorage.blob.core.windows.net' for example)
     */
    azureStorageAccount: 'uugotitstage',

    // In thise countries, all videos will be accessible for not logged-in users:
    enableAllVideosInCountries: ['AT'],

    // Google Analyitcs Measurement ID
    googleAnalyticsKey: 'G-9RGDD6YW8Y',
    mixpanelProjectToken: '',
};;
