import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from './api.service';
import { ILanguage } from 'src/app/interfaces/ILanguage';
import { map } from 'rxjs/operators';

/**
 * API for /languages/
 * See https://spyder3-dev.uugot.it/api-docs/#/languages
 */
@Injectable({
    providedIn: 'root'
})
export class LanguagesApi {
    constructor(public api: Api) {
        // Do nothing.
    }

    /**
     * Retrieve all available languages
     *
     * @param filterActive if not null it will return only languages that have is_active set to the given value.
     */
    getSubtitleLanguages(filterActive: boolean = null, isLoggedIn = false): Observable<ILanguage[]> {
        const languages = this.api.get(isLoggedIn ? 'languages/me' : 'languages');
        if (filterActive === null || filterActive === undefined) {
            return languages;
        }
        return languages.pipe(
            map<ILanguage[], ILanguage[]>(langs => langs.filter(lang => lang.is_active === filterActive))
        );
    }
}
