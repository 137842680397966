<ion-header>
    <ion-toolbar color="secondary">
        <ion-title>{{ "tell_us_about_yourself" | translate }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-list>
        <ion-item>
            <ion-label slot="floating">{{ "email_address" | translate }}</ion-label>
            <ion-input type="email" [(ngModel)]="email"></ion-input>
        </ion-item>

        <ion-item>
            <ion-label slot="floating">{{ "repeat_email_address" | translate }}</ion-label>
            <ion-input type="email" [(ngModel)]="emailRepeat"></ion-input>
        </ion-item>

        <ion-item>
            <ion-label>{{ "language_level_de" | translate }}</ion-label>
            <ion-select [(ngModel)]="languageLevelDE">
                <ion-select-option value="A1">A1</ion-select-option>
                <ion-select-option value="A2">A2</ion-select-option>
                <ion-select-option value="B1">B1</ion-select-option>
                <ion-select-option value="B2">B2</ion-select-option>
                <ion-select-option value="C1">C1</ion-select-option>
            </ion-select>
        </ion-item>

        <ion-item>
            <ion-label>{{ "language_level_en" | translate }}</ion-label>
            <ion-select [(ngModel)]="languageLevelEN">
                <ion-select-option value="A1">A1</ion-select-option>
                <ion-select-option value="A2">A2</ion-select-option>
                <ion-select-option value="B1">B1</ion-select-option>
                <ion-select-option value="B2">B2</ion-select-option>
                <ion-select-option value="C1">C1</ion-select-option>
            </ion-select>
        </ion-item>
    </ion-list>

    <div padding>
        <ion-button color="secondary" expand="block" (click)="buttonSaveClicked($event)">
            {{ "btn_save_continue" | translate }}
        </ion-button>
    </div>
</ion-content>
