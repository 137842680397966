import { OnInit, Component, Input } from '@angular/core';
import { AnalyticsService } from '../../services/analytics.service';
import { AppData } from '../../services/app-data.service';
import { ModalController } from '@ionic/angular';
import { Constants } from '../../app.constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
    template: `
        <ion-header>
            <ion-toolbar [color]="'secondary'">
                <ion-title>{{ 'terms_of_use_title' | translate }}</ion-title>
                <ion-buttons slot="end">
                    <ion-button (click)="dismiss()" *ngIf="!isAcceptRequired">
                        <ion-icon slot="icon-only" name="close"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content class="ion-padding">
            <div class="ion-margin-bottom" *ngIf="isAcceptRequired">
                {{'terms_of_use_subtitle' | translate}}
            </div>
            <div [innerHTML]="mainText" style="font-size: 1em;"></div>
        </ion-content>

        <ion-footer *ngIf="isAcceptRequired">
            <ion-toolbar>
                <ion-buttons slot="end">
                    <ion-button (click)="dismiss(true)">
                        <ion-icon slot="start" name="checkmark"></ion-icon>
                        {{ 'btn_accept' | translate }}
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-footer>
    `,
})
export class TermsOfUsePage implements OnInit {

    public mainText;
    @Input() public isAcceptRequired: boolean;

    constructor(
        private analytics: AnalyticsService,
        public appData: AppData,
        private modalCtrl: ModalController,
        private constants: Constants,
        public translate: TranslateService,
    ) {
        this.mainText = this.constants.TermsTextDe;
    }

    ngOnInit() {
        this.analytics.trackPageView('terms-of-use');
    }

    dismiss(acceptedTerms?: boolean) {
        this.modalCtrl.dismiss({
            acceptedTerms,
        });
    }
}
