<ion-header>
    <ion-toolbar color="blue">
        <ion-buttons slot="start">
            <ion-button (click)="navigateBack()">
                <ion-icon slot="icon-only" name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>{{ "worksheet_upload" | translate }}</ion-title>
        <ion-buttons slot="end" *ngIf="plt.is('mobile')">
            <ion-button (click)="uploadWorksheet()" [disabled]="!isFormValid">
                <ion-icon slot="icon-only" name="checkmark"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding-end">
    <form [formGroup]="worksheetUploadForm" id="worksheetUploadForm">
        <!-- We use a grid to have a fixed width once the screen gets larger -->
        <ion-grid class="ion-no-padding" fixed>
            <ion-row>
                <ion-col size="12">
                    <ion-list>
                        <!-- WORKSHEET NAME: -->
                        <ion-item>
                            <ion-label
                                [class.red-text]="!worksheetUploadForm.controls.worksheetName.valid && submitAttempt">
                                {{ "worksheet_name" | translate }} <sup>*</sup>
                            </ion-label>
                            <ion-input type="text" formControlName="worksheetName" text-right></ion-input>
                        </ion-item>

                        <!-- WORKSHEET LEVEL: -->
                        <ion-item>
                            <ion-label
                                [class.red-text]="!worksheetUploadForm.controls.worksheetLevel.valid && submitAttempt">
                                {{ "level" | translate }} <sup>*</sup>
                            </ion-label>
                            <ion-select formControlName="worksheetLevel">
                                <ion-select-option value="A1">A1</ion-select-option>
                                <ion-select-option value="A2">A2</ion-select-option>
                                <ion-select-option value="B1">B1</ion-select-option>
                                <ion-select-option value="B2">B2</ion-select-option>
                                <ion-select-option value="C1">C1</ion-select-option>
                            </ion-select>
                        </ion-item>

                        <!-- TEST FILE: -->
                        <ion-item>
                            <ion-label [class.red-text]="!hasTestFile && submitAttempt">{{ "test_file" | translate }} <sup>*</sup>
                            </ion-label>
                            <input slot="end" type="file" accept=".odt,.doc, .docx,.pdf" #testFile />
                        </ion-item>

                        <!-- SOLUTION FILE: -->
                        <ion-item>
                            <ion-label>
                                {{ "solution_file" | translate }}
                            </ion-label>
                            <input slot="end" type="file" accept=".odt,.doc, .docx,.pdf" #solutionFile />
                        </ion-item>
                    </ion-list>
                </ion-col>
            </ion-row>
        </ion-grid>
    </form>
    <p class="ion-text-center" *ngIf="!plt.is('mobile')">
        <ion-button color="blue" (click)="uploadWorksheet()" [disabled]="!isFormValid">
            <ion-icon slot="start" name="checkmark"></ion-icon>
            {{ "btn_save" | translate }}
        </ion-button>
    </p>
</ion-content>
