import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { NavController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/app.constants';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from 'src/app/services/app-data.service';
import { AppManager } from 'src/app/services/app-manager.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
    UntypedFormControl,
    UntypedFormArray,
    AbstractControl,
    ValidatorFn,
} from '@angular/forms';
import { EducatorsApi } from '../../services/api/educators-api';
import { NGXLogger } from 'ngx-logger';
import { UsersApi } from '../../services/api/users-api.service';

/** Validate if the email address is allowed */
export function domainEnabledForInviteValidator(appManager: AppManager): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any; } | null => {
        let forbidden;
        if (!control.value) {
            forbidden = false;
        } else {
            if (Validators.email(control)) {
                // If it's not a valid email address, then this will not return an error
                // console.log('forbiddenEmailValidator not an email', control.value);
                forbidden = false;
            } else {
                forbidden = !appManager.isAustrianTeacherEmailAddress(control.value);
            }
        }
        // console.log('domainEnabledForInviteValidator', control.value, forbidden);
        return forbidden ? { domainEnabledForInvite: { value: control.value } } : null;
    };
}

export function disallowOwnEmailValidator(appManager: AppManager): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any; } | null => {
        let forbidden;
        if (!control.value) {
            forbidden = false;
        } else {
            if (Validators.email(control) || !appManager.appData.isLoggedIn || !appManager.appData.authenticatedUser.email) {
                // If it's not a valid email address, then this will not return an error
                // console.log('forbiddenEmailValidator not an email', control.value);
                forbidden = false;
            } else {
                forbidden = control.value.toLowerCase() === appManager.appData.authenticatedUser.email.toLowerCase();
            }
        }
        // console.log('domainEnabledForInviteValidator', control.value, forbidden);
        return forbidden ? { disallowOwnEmail: { value: control.value } } : null;
    };
}

export type InviteEducatorsPageResult = 'invited' | 'cancel';

@Component({
    selector: 'app-invite-educators',
    templateUrl: 'invite-educators-page.html',
    styleUrls: ['invite-educators-page.scss'],
})
export class InviteEducatorsPage implements OnInit {
    public inviteForm: UntypedFormGroup;

    public dismissPageEnabled = true;

    /**
     * True if the user initiated the dialog via a button click.
     */
    public userInitiated = false;

    public callOnDismiss?: (result: 'invited' | 'cancel') => void;

    public emails: UntypedFormArray;

    public validationMessages = null;

    constructor(
        public uiUtils: UiUtils,
        public translate: TranslateService,
        public appData: AppData,
        public appManager: AppManager,
        public constants: Constants,
        public navCtrl: NavController,
        private analytics: AnalyticsService,
        private titleService: Title,
        private modalCtrl: ModalController,
        private formBuilder: UntypedFormBuilder,
        private educatorsApi: EducatorsApi,
        private logger: NGXLogger,
        private usersApi: UsersApi
    ) {
        // Do nothing.
    }

    ngOnInit() {
        this.titleService.setTitle('Invite Educators | uugot.it');
        this.analytics.trackPageView('invite-educators');

        this.logger.debug(`InviteEducatorsPage init - userInitiated: ${this.userInitiated}`);

        this.initForm();

        this.translate
            .get(['validator_email_required', 'validator_email_not_valid', 'validator_email_disallow_own'])
            .subscribe((i18n) => {
                this.validationMessages = {
                    email: [
                        { type: 'required', message: i18n['validator_email_required'] },
                        { type: 'minlength', message: 'Email must be at least 5 characters long.' },
                        {
                            type: 'maxlength',
                            message: 'E-mail cannot be more than 50 characters long.',
                        },
                        { type: 'email', message: i18n['validator_email_not_valid'] },
                        {
                            type: 'domainEnabledForInvite',
                            message:
                                'Für die Verifizierung können nur bestimmte E-Mail-Adressen akzeptiert werden (siehe Info-Button). Lehrkräfte anderer Bildungsinstitute nehmen bitte unter <a href="mailto:ask_uu@uugot.it">ask_uu@uugot.it</a> Kontakt mit uns auf.',
                        },
                        {
                            type: 'disallowOwnEmail',
                            message: i18n['validator_email_disallow_own']
                        }
                    ],
                };
            });
    }

    initForm() {
        this.emails = new UntypedFormArray([]);

        this.inviteForm = this.formBuilder.group({
            emails: this.emails,
        });

        this.addEmailField(true);
        this.addEmailField(false);
        this.addEmailField(false);
    }

    addEmailField(isRequired = false) {
        const validators = [
            Validators.email,
            Validators.maxLength(50),
            domainEnabledForInviteValidator(this.appManager),
            disallowOwnEmailValidator(this.appManager)
        ];
        if (isRequired) {
            validators.push(Validators.required);
        }
        this.emails.push(new UntypedFormControl('', validators));
    }

    ionViewWillEnter() {
        // this.appData.activePage = 'help';
    }

    async onSubmitForm() {
        const value = this.inviteForm.value;
        this.logger.debug('form value', value);

        const emails = value.emails.filter((e) => e && e.includes('@')) as [string];

        try {
            const response = await this.educatorsApi.invite(emails).toPromise();
            this.logger.debug('Invite response is', response);
            const successCount = response.results.filter(r => r.success).length;
            this.translate.get('invitiations_were_sent', { successCount, totalCount: emails.length }).subscribe(i18n => {
                this.uiUtils.displayToast(i18n);
            });

            // Reset form:
            this.initForm();

            // Save invited educators to user preferences:
            const prefs = await this.usersApi.getPreferences().toPromise() || {};
            let invitedEducators = prefs[this.constants.pref.INVITED_EDUCATORS];
            if (!invitedEducators) {
                invitedEducators = [];
            }
            emails.forEach(e => {
                if (!invitedEducators.includes(e)) {
                    invitedEducators.push(e);
                }
            });
            const saveResponse = await this.usersApi.savePreferences(this.constants.pref.INVITED_EDUCATORS, invitedEducators).toPromise();
            this.logger.debug('Saved preferences', saveResponse);
            this.dismiss('invited');

        } catch (err) {
            this.uiUtils.displayToast(`Error: ${err}`);
        }
    }

    submitButtonDisabled() {
        if (this.inviteForm.pristine || !this.inviteForm.valid) {
            return true;
        }
    }

    dismiss(result: InviteEducatorsPageResult) {
        this.modalCtrl
            .dismiss({
                dismissed: true,
            })
            .then(async (value) => {
                if (!this.userInitiated && result === 'cancel') {
                    this.logger.debug('Dismissed invite educators dialog', value);
                    const prefs = await this.usersApi.getPreferences().toPromise() || {};
                    const inviteEducatorsDeclined: number =
                        prefs[this.constants.pref.INVITE_EDUCATORS_CLOSED] || 0;
                    const res = await this.usersApi
                        .savePreferences(
                            this.constants.pref.INVITE_EDUCATORS_CLOSED,
                            inviteEducatorsDeclined + 1
                        )
                        .toPromise();
                    this.logger.debug('Saved preferences on dismiss', res);
                }
                if (this.callOnDismiss) {
                    this.callOnDismiss(result);
                }
            });
    }

    public isRequiredField(control: AbstractControl) {
        if (!control.validator) {
            return false;
        }
        const validator = control.validator({} as AbstractControl);
        if (validator && validator.required) {
            return true;
        }
    }

    stringify(obj: any): string {
        return JSON.stringify(obj);
    }

    showMoreInfo() {
        const list = this.appManager.getAustrianTeacherEmailAddressesForDisplay().map(e => `<li>${e}</li>`).join('');
        this.uiUtils.showInfoAlert(
            '',
            'Akzeptierte E-Mail-Endungen (Domains):',
            `<ul>
            ${list}
            </ul>
        `,
        );
    }
}
