import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { NavController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/app.constants';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from 'src/app/services/app-data.service';
import { AppManager } from 'src/app/services/app-manager.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
    UntypedFormControl,
    ValidatorFn, AbstractControl, ValidationErrors
} from '@angular/forms';
import { EducatorsApi } from '../../services/api/educators-api';
import { NGXLogger } from 'ngx-logger';
import { IUser } from '../../interfaces/IUser';
import { Keyboard } from '@capacitor/keyboard';
import { StudentsApi } from 'src/app/services/api/students-api';
import { Observable } from 'rxjs';
import { ISingleObjectResponse } from 'src/app/models/dtos';
import { IUpdatePassword} from '../../interfaces/IUpdatePassword';
import { UsersApi } from '../../services/api/users-api.service';


@Component({
    selector: 'app-edit-password-profile-page',
    templateUrl: 'edit-password-profile-page.html',
    styleUrls: ['edit-password-profile-page.scss']
})
export class EditPasswordProfilePage implements OnInit {

    public passwordForm: UntypedFormGroup;
    @Input() public isSchoolRequired;
    @Input() public allowDismissOnlyAfterSave;
    @Input() public callOnDismiss?: () => void;
    public dismissPageEnabled;
    public user: IUser;
    submitting = false;

    constructor(public uiUtils: UiUtils,
        public translate: TranslateService,
        public appData: AppData,
        public appManager: AppManager,
        public constants: Constants,
        public navCtrl: NavController,
        private analytics: AnalyticsService,
        private titleService: Title,
        private modalCtrl: ModalController,
        private formBuilder: UntypedFormBuilder,
        private educatorsApi: EducatorsApi,
        private studentsApi: StudentsApi,
        private usersApi: UsersApi,
        private logger: NGXLogger) {
        // Do nothing.

    }
    passwordMatchingValidator(password: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control || !control.parent) {
                return null;
            }
            return control.parent.get(password).value === control.value ? null : { mismatch: true };
        };
    }

    ngOnInit() {
        this.titleService.setTitle('Edit Profile | uugot.it');
        this.analytics.trackPageView('edit-profile');

        this.user = this.appData.authenticatedUser;
        this.logger.debug('Got user', this.user);

        this.passwordForm = this.formBuilder.group({
            old_password: ['', Validators.required],
            new_password: [
                '', Validators.compose([Validators.required,
                    Validators.minLength(8),
                    Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$')
                ])
            ],
            password_repeat: ['', [Validators.required, this.passwordMatchingValidator('new_password')]]
        });

        if (this.isSchoolRequired) {
            this.passwordForm.get('schoolName').setValidators([Validators.required, Validators.minLength(2), Validators.maxLength(50)]);
            this.passwordForm.get('city').setValidators([Validators.required, Validators.minLength(2), Validators.maxLength(50)]);
        }

        this.dismissPageEnabled = !this.allowDismissOnlyAfterSave;

    }
    async onSubmitForm() {
        const formData = this.passwordForm.value;
        this.submitting =  true;
        try {
            const data: IUpdatePassword = {
                old_password: formData.old_password,
                new_password: formData.new_password,
                password_repeat: formData.password_repeat,
            };

            const res = await this.usersApi.updatePassword(data).toPromise();

            if (res.success) {
                this.uiUtils.displayToast("Your passsword has changed correctly", null, 3000, 'success');
                this.passwordForm.reset();
                this.dismiss();
                this.submitting =  false;
            } else {
                const msg = res.msg;
                if (msg === 'OLD_PASSWORD_INCORRECT') {
                    this.uiUtils.displayToast("Old password is incorrect. Please try again", null, 3000, 'danger');
                } else {
                    this.uiUtils.displayToast("There is an error. Please try later or please request again the link", null, 3000, 'danger');
                    this.passwordForm.reset();
                }
                this.submitting =  false;
            }
        } catch (err) {
            this.uiUtils.displayToast("There is an error. Please try later or please request again the link", null, 3000, 'danger');
            this.passwordForm.reset();
            this.submitting =  false;
        }
    }

    dismiss() {
        this.modalCtrl.dismiss({
            dismissed: true,
        }).then(() => {
            if (this.callOnDismiss) {
                this.callOnDismiss();
            }
        });
    }

    hideKeyboard() {
        this.logger.debug('hideKeyboard');
        Keyboard.hide();
    }

}
