import { Component, OnInit, Input, ViewChild, NgZone, ElementRef } from '@angular/core';
import { IVideo } from '../../interfaces/IVideo';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from '../../services/app-data.service';
import { WatchedVideo } from '../../models/watched-video';
import { Utils } from '../../utils/utils';
import { Constants } from 'src/app/app.constants';
import { SharedUiService } from 'src/app/services/shared-ui.service';
import { environment } from '../../../environments/environment';
import {
    AlertController,
    Animation,
    AnimationController,
    ModalController,
    NavController, Platform
} from '@ionic/angular';
import { NavigationExtras } from '@angular/router';
import { AnalyticsCategory, AnalyticsService } from '../../services/analytics.service';
import * as moment from 'moment';
import {
    CreateClassPaidFeaturePopupComponent
} from '../create-class-paid-feature-popup/create-class-paid-feature-popup.component';

/**
 * Component that represents a card for a single video in the video catalog.
 */
@Component({
    selector: 'app-video-catalog-card',
    templateUrl: './video-catalog-card.component.html',
    styleUrls: ['./video-catalog-card.component.scss'],
})
export class VideoCatalogCardComponent implements OnInit {
    @Input() video: IVideo; // the video
    @Input() watchedVideos: WatchedVideo[]; // array of watched videos
    @Input() tag: string; // tag of the video catalog page
    @Input() subjectSlug: string; // subect slug of the video catalog page
    @Input() showPublishDate: boolean;

    @Input() favorCallback: (video: IVideo, favored: boolean) => {};

    @ViewChild('favoredIconFull', { read: ElementRef }) favoredIconFull: ElementRef;
    @ViewChild('favoredIconOutline', { read: ElementRef }) favoredIconOutline: ElementRef;

    constructor(
        private logger: NGXLogger,
        public translate: TranslateService,
        public uiUtils: UiUtils,
        private platform: Platform,
        public appData: AppData,
        private modalController: ModalController,
        public constants: Constants,
        private sharedUiService: SharedUiService,
        private alertCtrl: AlertController,
        private navCtrl: NavController,
        private analytics: AnalyticsService,
        private animationCtrl: AnimationController,
        private ngZone: NgZone,
    ) {
        // empty
        // this.logger.debug('VideoCatalogCardComponent constructor');
    }

    ngOnInit() {
        // Doing nothing.
        // this.logger.debug('VideoCatalogCardComponent.ngOnInit()');
    }

    /**
     * Returns true if video with videoId is watched, false otherwise
     *
     * @param videoId The video ID of the video
     */
    isAlreadyWatched = (): boolean => {
        const watched = this.watchedVideos.find((w) => w.videoId === this.video._id);
        return !!watched;
    };

    /**
     * Returns 0 if a video has not been watched yet, otherwise something like 26 or 100 to indicate the %.
     * @param video the video
     */
    getProgressForWatchedVideo = (): number => {
        const watched = this.watchedVideos.find((w) => w.videoId === this.video._id);
        // this.logger.debug('progress for ', video.title, watched);
        if (
            !watched ||
            !watched.watchedUntil ||
            watched.watchedUntil < this.constants.VideoWatchedThresholdSeconds
        ) {
            return 0;
        }
        const duration = Utils.getDurationOfVideoInSeconds(this.video);
        const percent = Math.ceil(((watched.watchedUntil || 0) / duration) * 100);
        // this.logger.debug('progress for (%) ', video.title, percent);
        return percent;
    };

    /**
     * Adds video to group selected by user in the alert
     */
    async addVideoToClass() {
        await this.sharedUiService.showAddVideoToClassDialog(this.video._id);
    }

    /**
     * Checks if video is highlighted
     *
     * @returns True if highlighted, false otherwise
     */
    isVideoHighlighted = (): boolean => {
        return this.video['isVideoOfTheDay'] && !this.appData.isLoggedIn();
    };

    /**
     * Checks if video is disabled (see also https://gitlab.com/uugotitTeam/webapp/issues/167#note_164102374)
     *
     * @param video Video to be checked
     * @returns > 0 if disabled (1 = because of geo IP, 2 = other reason, 3= premium feature)
     */
    isVideoDisabled = (): number => {
        const isSubjectsPage = !!this.subjectSlug;
        const canWatchVideo = Utils.canEnableOrWatchPremiumVideo(this.video, isSubjectsPage, this.appData.authenticatedUser, this.appData.userLicense);
        if (canWatchVideo) {
            return 0;
        }
        return 2;
    };

    isVideoExpired = (): boolean => {
        return Utils.isVideoExpired(this.video);
    }
    isModalOpen = false;

    /**
     * Opens video player page
     *
     */
    async openVideoPlayerPage() {
        // console.log('Opening video player page with video', videoInfo);
        const videoDisabled = this.isVideoDisabled();
        if (videoDisabled === 1) {
            const i18n = await this.translate
                .get([
                    'video_not_avail_in_country_title',
                    'video_not_avail_in_country_message',
                    'btn_cancel',
                    'btn_register_country_video_not_avail',
                ])
                .toPromise();
            const alert = await this.alertCtrl.create({
                header: i18n['video_not_avail_in_country_title'],
                subHeader: i18n['video_not_avail_in_country_message'],
                buttons: [
                    {
                        text: i18n['btn_later'],
                        role: 'cancel',
                    },
                    {
                        text: i18n['btn_register_country_video_not_avail'],
                        handler: () => {
                            window.open('http://eepurl.com/gVy_mj', '_system');
                        },
                    },
                ],
            });
            await alert.present();
            return;
        } else if (videoDisabled > 1) {
            this.showPopup();
            return;
        }
        const state: any = {
            tag: this.tag,
            subjectSlug: this.subjectSlug,
        };

        if (this.isAlreadyWatched()) {
            // const watchedVideo = this.appData.getWatchedVideo(videoInfo._id);
            const watchedVideo = this.watchedVideos.find((wv) => wv.videoId === this.video._id);
            const duration = Utils.getDurationOfVideoInSeconds(this.video);
            if (
                duration &&
                watchedVideo &&
                watchedVideo.watchedUntil &&
                watchedVideo.watchedUntil > this.constants.VideoWatchedThresholdSeconds &&
                watchedVideo.watchedUntil < duration - this.constants.VideoWatchedThresholdSeconds
            ) {
                state.initialTimeInVideo = watchedVideo.watchedUntil - this.constants.VideoGoBackAfterResumeSeconds; // Subtract 2 seconds
                state.showToastText = this.translate.instant('resuming_video_at', {
                    time: this.uiUtils.secondsToTimeString(state.initialTimeInVideo),
                });
            }
        }

        const navigationExtras: NavigationExtras = {
            state,
        };

        this.navCtrl.navigateForward(
            `video/${this.video.websource_id}/${this.video._id}`,
            navigationExtras
        );

        this.logger.debug('source: ' + this.video.websource_id);

        this.analytics.trackAnalyticsEvent(
            AnalyticsCategory.VideoCatalog,
            'open',
            this.video.websource_id
        );
    }

    openCategoryPage() {
        this.navCtrl.navigateRoot(
            `category/${this.appData.getVideoCategoryById(this.video.cat_id).slug}`
        );
    }

    async favorVideo() {
        try {
            const animation1: Animation = this.animationCtrl
                .create()
                .addElement(this.favoredIconOutline.nativeElement)
                .duration(150)
                .keyframes([
                    { offset: 0, transform: 'scale(1)', opacity: 1 },
                    { offset: 1, transform: 'scale(0.6)', opacity: 0.5 },
                    { offset: 1, transform: 'scale(1)', opacity: 1 }, // Reset to scale 1
                ])
                .onFinish(() => {
                    this.ngZone.run(() => this.video.favored = true);
                });

            const animation2: Animation = this.animationCtrl
                .create()
                .addElement(this.favoredIconFull.nativeElement)
                .delay(150)
                .duration(150)
                .keyframes([
                    { offset: 0, transform: 'scale(0.6)', opacity: 0.5 },
                    { offset: 1, transform: 'scale(1)', opacity: 1 },
                ])
                .onFinish(() => {
                    if (this.favorCallback) {
                        this.ngZone.run(() => this.favorCallback(this.video, true));
                    }
                });

            animation1.play();
            animation2.play();

            const response = await this.appData.addOrUpdateFavoredVideo(
                this.video._id,
                new Date(this.video.expiryDate)
            );
            this.logger.debug('favorVideo response', response);
            const text = await this.translate.get('video_was_added_to_favorites').toPromise();
            this.uiUtils.displayToast(text);
            const video = this.appData.videos?.find((v) => v._id === this.video._id);
            if (video) {
                video.favored = true;
            }
            this.analytics.trackAnalyticsEvent(
                AnalyticsCategory.FavoredVideos,
                'favor_video',
                this.video.cat_id
            );
        } catch (err) {
            this.logger.error('Error favoring video', err);
        }
    }

    async unfavorVideo() {
        try {
            const animation1: Animation = this.animationCtrl
                .create()
                .addElement(this.favoredIconFull.nativeElement)
                .duration(150)
                .keyframes([
                    { offset: 0, transform: 'scale(1)', opacity: 1 },
                    { offset: 1, transform: 'scale(0.6)', opacity: 0.5 },
                    { offset: 1, transform: 'scale(1)', opacity: 1 }, // Reset to scale 1
                ])
                .onFinish(() => {
                    this.ngZone.run(() => this.video.favored = false);
                });

            const animation2: Animation = this.animationCtrl
                .create()
                .addElement(this.favoredIconOutline.nativeElement)
                .delay(150)
                .duration(150)
                .keyframes([
                    { offset: 0, transform: 'scale(0.6)', opacity: 0.5 },
                    { offset: 1, transform: 'scale(1)', opacity: 1 },
                ])
                .onFinish(() => {
                    if (this.favorCallback) {
                        this.ngZone.run(() => this.favorCallback(this.video, false));
                    }
                });

            animation1.play();
            animation2.play();

            const response = await this.appData.deleteFavoredVideo(this.video._id);
            this.logger.debug('unfavorVideo response', response);
            const text = await this.translate.get('video_was_removed_from_favorites').toPromise();
            this.uiUtils.displayToast(text);
            const video = this.appData.videos?.find((v) => v._id === this.video._id);
            if (video) {
                video.favored = false;
            }
            this.analytics.trackAnalyticsEvent(
                AnalyticsCategory.FavoredVideos,
                'unfavor_video',
                this.video.cat_id
            );
        } catch (err) {
            this.logger.error('Error un-favoring video', err);
        }
    }

    getExpiresInHoursAndDays(maxDays: number): {hours: number; days: number;} {
        const momentExpires = moment(this.video.expiryDate);
        const momentNow = moment();
        const hours = momentExpires.diff(momentNow, 'h');
        const days = Math.round(hours / 24.0);
        // const days = momentExpires.diff(momentNow, 'd');
        if (days <= maxDays) {
            return { hours, days };
        } else {
            return null;
        }

    }

    /**
     * If the program name is longer than maxLength, then it will be cut off with ...
     * at maxLength - 3.
     * @param maxLength the maximum length
     * @returns the ellipsized program name
     */
    getProgramNameEllipsized(maxLength = 32) {
        if (this.video.program?.length > maxLength) {
            return `${this.video.program.substring(0, maxLength - 3)}...`;
        } else {
            return this.video.program;
        }
    }

    /**
     * Removes the program from the title, if the title starts with the program. For example:
     * Title: "WELTjournal+: Belarus - Russlands Vasall", program: "WELTjournal+" ->
     * only "Belarus - Russlands Vasall" is returned.
     * @returns the video title less the program (if applicable)
     */
    getVideoTitleLessProgram() {
        if (!this.video.program || this.video.program === this.video.title) {
            return this.video.title;
        }
        if (this.video.title.toLowerCase().startsWith(`${this.video.program.toLowerCase()}: `)) {
            return this.video.title.substring(this.video.program.length + 2);
        } else {
            return this.video.title;
        }
    }


    private async showPremiumGif() {
        const modal = await this.modalController.create({
            component: CreateClassPaidFeaturePopupComponent,
            // cssClass: 'auto-height'
        });

        await modal.present();

        const { data, role } = await modal.onWillDismiss();

        if (role === 'subscribe') {
            if (this.platform.is('cordova')) {
                window.open('https://uugot.it/sba/', '_system'); // Para dispositivos móviles
            } else {
                window.open('https://uugot.it/sba/', '_blank'); // Para navegadores
            }
        } else if (role === 'productPage') {
            if (this.platform.is('cordova')) {
                window.open('https://www.uugot.it/product-scooling-de.html', '_system'); // Para dispositivos móviles
            } else {
                window.open('https://www.uugot.it/product-scooling-de.html', '_blank'); // Para navegadores
            }
        }
    }
    private async showPopup() {
        this.modalController.create({
            component: CreateClassPaidFeaturePopupComponent,
            cssClass: 'modal-premium-feature-gif',
            componentProps: {
                isModalOpen: true, // Pass the necessary input to the component
            },
        }).then(modal => {
            modal.present();
        });
    }
}
