import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import { Api } from './api.service';
import { IActivityEvent } from 'src/app/interfaces/IActivity';
import { EventsResponse } from 'src/app/models/dtos';

/**
 * API for /activity/
 * See http://spyder3-stage.uugot.it/api-docs/#/activity
 */
@Injectable({
    providedIn: 'root'
})
export class ActivityApi {
    constructor(public api: Api) {
        // Do nothing.
    }

    /**
     * Saves events array to the server
     *
     * @param events The array of events
     */
    saveEvents(events: IActivityEvent[]): Observable<EventsResponse> {
        return this.api.post('activity/events', events);
    }

    /**
     * Gets most clicked words in a class for specified period
     *
     * @param groupId The group ID of the group
     * @param fromDate Starting date
     * @param toDate Endind date
     */
    getMostClickedWords(groupId: string, fromDate: string, toDate: string): Observable<any> {
        const body = {
            group_id: groupId,
            from_date: fromDate,
            to_date: toDate
        };

        return this.api.post('activity/statistics/word_added/pergroup', body);
    }
}
