// angular
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

// ionic
import { LoadingController, ToastController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { IAssessment } from '../../interfaces/IAssessment';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppManager } from 'src/app/services/app-manager.service';
import { AppData } from 'src/app/services/app-data.service';
import { Constants } from '../../app.constants';
import { environment } from '../../../environments/environment';
import { AssessmentsApi } from 'src/app/services/api/assessments-api.service';
import { IGroupVideo } from 'src/app/interfaces/IGroupVideo';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { IGroup } from '../../interfaces/IGroup';

@Component({
    selector: 'app-worksheet-upload',
    templateUrl: 'worksheet-upload-page.html',
    styleUrls: ['worksheet-upload-page.scss']
})
export class WorksheetUploadPage implements OnInit {
    public worksheetUploadForm: UntypedFormGroup;
    public submitAttempt = false;
    public groupVideo: IGroupVideo;
    private groupId: string;

    @ViewChild('testFile', { static: true }) public testFile: ElementRef;
    @ViewChild('solutionFile', { static: true }) public solutionFile: ElementRef;

    constructor(public navCtrl: NavController,
        public assessmentsApi: AssessmentsApi,
        public uiUtils: UiUtils,
        private formBuilder: UntypedFormBuilder,
        public appData: AppData,
        private constants: Constants,
        public appManager: AppManager,
        public loadingCtrl: LoadingController,
        public translate: TranslateService,
        public toastCtrl: ToastController,
        public plt: Platform,
        private logger: NGXLogger,
        private router: Router) {

        // https://ionicframework.com/docs/developer-resources/forms/
        // https://www.joshmorony.com/advanced-forms-validation-in-ionic-2/
        this.worksheetUploadForm = this.formBuilder.group({
            worksheetName: ['', Validators.required],
            worksheetLevel: ['', Validators.required]
        });

        const nav = this.router.getCurrentNavigation();
        console.log('getCurrentNavigation', nav);
        if (nav && nav.extras && nav.extras.state) {
            this.groupVideo = nav.extras.state.groupVideo;
            const group = nav.extras.state.groupVideo as IGroup;
            this.groupId = group._id;
        }
    }

    ngOnInit() {
        // Workaround so that the UI is updated when a file is selected
        this.listenFileInputChange();
    }

    ionViewWillEnter() {
        if (!this.appData.isEducator() || !this.groupVideo || !this.groupId) {
            this.navCtrl.navigateRoot('catalog');
        }
    }

    /**
     * Listener the input file component to get the files to be uploaded.
     */
    private listenFileInputChange() {
        const addListener = (elementRef: ElementRef) => {
            const nativeElement = elementRef.nativeElement as HTMLInputElement;
            nativeElement.addEventListener('change', (evt: any) => {
                this.logger.debug('file element change', evt);
            }, false);
        };
        addListener(this.testFile);
        addListener(this.solutionFile);
    }

    /**
     * Navigates back to worksheet select page
     */
    navigateBack() {
        this.navCtrl.navigateBack('worksheet-select');
    }

    get isFormValid() {
        const isFormValid = this.worksheetUploadForm.valid && this.hasTestFile; //&& this.hasSolutionFile
        // this.logger.debug('isFormValid', isFormValid);
        return isFormValid;
    }

    get hasTestFile() {
        return this.testFile?.nativeElement.files[0];
    }

    get hasSolutionFile() {
        return this.solutionFile?.nativeElement.files[0];
    }


    /**
     * Uploads worksheet to azure storage
     */
    async uploadWorksheet() {
        this.submitAttempt = true;

        if (!this.isFormValid) {
            this.logger.debug('Form is not valid');
            return;
        }

        // Showing loading animation
        const loader = await this.loadingCtrl.create({
            message: this.translate.instant('uploading_worksheet')
        });
        await loader.present();

        this.appManager.getTokenAndUploadFilesToAzureStorage(this.testFile, this.solutionFile, 'assessment', this.groupVideo.video_id._id, this.worksheetUploadForm.value.worksheetLevel).subscribe(result => {
            this.logger.debug('Upload success - file 1:', result[0]);
            this.logger.debug('Upload success - file 2:', result[1]);

            const testFileUrl = `https://${environment.azureStorageAccount}.blob.core.windows.net/${this.constants.AzureBlobContainer}/${result[0].name}`;
            let solutionFileUrl;
            if (result[1]) {
                solutionFileUrl = `https://${environment.azureStorageAccount}.blob.core.windows.net/${this.constants.AzureBlobContainer}/${result[1].name}`;
            }

            // Create new assessment
            const newAssessment: IAssessment = {
                name: this.worksheetUploadForm.value.worksheetName,
                video_id: this.groupVideo.video_id._id,
                level: this.worksheetUploadForm.value.worksheetLevel,
                testFileUrl,
                solutionFileUrl,
                visib: 1 // private for the educator
            };

            // Remove loader
            loader.dismiss();

            // Add assessment
            this.assessmentsApi.addAssessment(newAssessment).subscribe(response => {
                this.logger.debug('Add assessment response: ', response);
                // Remove loader
                loader.dismiss();

                if (response.success) {
                    // Add new assessment to video
                    this.assessmentsApi.addAssessmentToVideoInGroup(this.groupId, response.data._id, this.groupVideo.video_id._id)
                        .subscribe(res => {
                            this.logger.debug('Success - add assessment to video in group', res);
                            // Add assessment to video catalog videos if video is in catalog
                            if (this.appData?.videos) {
                                const index = this.appData.videos.findIndex(video => video._id === this.groupVideo.video_id._id);
                                if (index !== -1) {
                                    if (this.appData.videos[index].assessment_docs) {
                                        this.appData.videos[index].assessment_docs.push(response.data);
                                    } else {
                                        this.appData.videos[index].assessment_docs = [response.data];
                                    }
                                }
                            }

                            this.uiUtils.displayToast(this.translate.instant('worksheet_uploaded'));
                            // Update local video
                            this.groupVideo.assessment_id = response.data._id;

                            this.navigateBack();
                        }, err => {
                            this.logger.warn('Error - add assessment to video in group', err);
                            switch (err.status) {
                                case 400:
                                    this.uiUtils.showErrorAlert(this.translate.instant('invalid_input'));
                                    break;
                                case 404:
                                    this.uiUtils.showErrorAlert(this.translate.instant('video_not_found'));
                                    break;
                            }
                        });

                    this.logger.debug('File names: 1: %s 2: %s', testFileUrl, solutionFileUrl);
                } else {
                    this.uiUtils.showErrorAlert(response.msg);
                }
            }, err => {
                this.logger.error('Error - add assessment to database', err);
            });

        }, error => {
            this.logger.error('Error - uploading files', error);
            this.uiUtils.showErrorAlert(this.translate.instant('file_upload_error'));
            // Remove loader
            loader.dismiss();
        });
    }
}
