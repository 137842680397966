import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from './api.service';
import { TranslationCorrectionResponse, TranslationCorrectionsResponse } from 'src/app/models/dtos';

/**
 * API for /translationcorrections/
 * See https://spyder3-dev.uugot.it/api-docs/#/translationcorrections
 */
@Injectable({
    providedIn: 'root'
})
export class TranslationCorrectionsApi {
    constructor(public api: Api) {
        // Do nothing.
    }

    /**
     * Create and send translation suggestion
     *
     * @param body The body of the post request
     */
    sendTranslationCorrection(body: any): Observable<TranslationCorrectionResponse> {
        return this.api.post('translationcorrections', body);
    }

    /**
     * Lists all translation suggestions
     *
     * @param from The language the words were tranlslated from
     * @param to The language the words were translated to
     * @param status Filter suggestions by status
     * @param fromWord List of suggestions can be limited to a single word
     */
    getTranslationSuggestions(from: string, to: string, status = 'pending', fromWord = ''): Observable<TranslationCorrectionsResponse> {
        const body = {
            fromWord,
            from,
            to,
            rule: 'override',
            rule_file: 'Postwords',
            status
        };

        return this.api.post('translationcorrections/getone', body);
    }
}
