import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { NavController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/app.constants';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from 'src/app/services/app-data.service';
import { AppManager } from 'src/app/services/app-manager.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { EducatorsApi } from '../../services/api/educators-api';
import { NGXLogger } from 'ngx-logger';
import { IUser } from '../../interfaces/IUser';
import { Keyboard } from '@capacitor/keyboard';
import { StudentsApi } from 'src/app/services/api/students-api';
import { Observable } from 'rxjs';
import { ISingleObjectResponse } from 'src/app/models/dtos';


@Component({
    selector: 'app-edit-profile',
    templateUrl: 'edit-profile-page.html',
    styleUrls: ['edit-profile-page.scss']
})
export class EditProfilePage implements OnInit {

    public profileForm: UntypedFormGroup;
    @Input() public isSchoolRequired;
    @Input() public allowDismissOnlyAfterSave;
    @Input() public callOnDismiss?: () => void;
    public dismissPageEnabled;
    public user: IUser;

    constructor(public uiUtils: UiUtils,
        public translate: TranslateService,
        public appData: AppData,
        public appManager: AppManager,
        public constants: Constants,
        public navCtrl: NavController,
        private analytics: AnalyticsService,
        private titleService: Title,
        private modalCtrl: ModalController,
        private formBuilder: UntypedFormBuilder,
        private educatorsApi: EducatorsApi,
        private studentsApi: StudentsApi,
        private logger: NGXLogger) {
        // Do nothing.

    }


    ngOnInit() {
        this.titleService.setTitle('Edit Profile | uugot.it');
        this.analytics.trackPageView('edit-profile');

        this.user = this.appData.authenticatedUser;
        this.logger.debug('Got user', this.user);

        this.profileForm = this.formBuilder.group({
            email: new UntypedFormControl({ value: this.user ? this.user.email : '', disabled: true }, [Validators.required, Validators.email]),
            firstName: [this.user ? this.user.first_name : '', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
            lastName: [this.user ? this.user.last_name : '', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
            schoolName: [this.user ? this.user.schoolName : '', [Validators.maxLength(50)]],
            city: [this.user ? this.user.city : '', [Validators.maxLength(50)]]
        });

        if (this.isSchoolRequired) {
            this.profileForm.get('schoolName').setValidators([Validators.required, Validators.minLength(2), Validators.maxLength(50)]);
            this.profileForm.get('city').setValidators([Validators.required, Validators.minLength(2), Validators.maxLength(50)]);
        }

        this.dismissPageEnabled = !this.allowDismissOnlyAfterSave;

    }

    ionViewWillEnter() {
        // this.appData.activePage = 'help';

    }

    async onSubmitForm() {
        const value = this.profileForm.value;
        let modify: Observable<ISingleObjectResponse<IUser>>;
        if (this.user.role === 'educator') {
            modify = this.educatorsApi.modifyProfile({
                first_name: value.firstName,
                last_name: value.lastName,
                schoolName: value.schoolName,
                city: value.city
            });
        } else {
            modify = this.studentsApi.modifyProfile({
                first_name: value.firstName,
                last_name: value.lastName
            });
        }

        const response = await modify.toPromise();
        this.logger.debug('User profile save response is', response);
        if (response.success) {
            this.appManager.saveUserInfo(response.data);
            this.dismissPageEnabled = true;
            this.uiUtils.displayToast(response.msg);
            this.profileForm.markAsPristine();
            if (this.callOnDismiss) {
                this.dismiss();
            }
        }
    }

    dismiss() {
        this.modalCtrl.dismiss({
            dismissed: true,
        }).then(() => {
            if (this.callOnDismiss) {
                this.callOnDismiss();
            }
        });
    }

    hideKeyboard() {
        this.logger.debug('hideKeyboard');
        Keyboard.hide();
    }

}
