<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-menu-toggle>
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
        <ion-title>{{ "Forgot Password" | translate }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding ion-margin-bottom ion-text-center">
    <div class="page-container">
        <form *ngIf='showwEmailForm' [formGroup]="requestLinkForm"  (ngSubmit)='handleRequestEmail()'>
            <ion-grid fixed>
                <ion-list >
                    <ion-item class="ion-no-padding">
                        <ion-label position="floating">{{ "email_address" | translate }}</ion-label>
                        <ion-input type="email" formControlName="email" #email></ion-input>
                    </ion-item>
                    <ion-label class="validator-errors" color="danger"
                               *ngIf="!requestLinkForm.controls.email.valid && (requestLinkForm.controls['email'].dirty)">
                        <p *ngIf="requestLinkForm.value.email !== ''">{{'validator_email_not_valid' | translate}}</p>
                        <p *ngIf="requestLinkForm.value.email === ''">{{'validator_email_required' | translate}}</p>
                    </ion-label>
                </ion-list>
            </ion-grid>
            <ion-button color="primary" type="submit">
                {{ ("request_new_password_button" | translate) }}
            </ion-button>
        </form>
        <form *ngIf='showPasswordForm' [formGroup]="forgotPasswordForm">
            <ion-grid fixed>
                <ion-list>
                    <ion-item  class="ion-no-padding">
                        <ion-label position="floating">{{ "password" | translate }}</ion-label>
                        <app-show-hide-password>
                            <ion-input name="password" type="password"  formControlName='password' required></ion-input>
                        </app-show-hide-password>
                    </ion-item>
                    <ion-item  class="ion-no-padding">
                        <ion-label position="floating">{{ "repeat_password" | translate }}</ion-label>
                        <app-show-hide-password>
                            <ion-input name="repeat_password" type="password" formControlName='repeat_password' required></ion-input>
                        </app-show-hide-password>
                    </ion-item>
                    <ion-label class="validator-errors" color="danger"
                               *ngIf="forgotPasswordForm.controls.password.invalid && (forgotPasswordForm.controls['password'].dirty)">
                        <p *ngIf="forgotPasswordForm.controls.password.errors?.required">{{'validator_password_required' | translate}}</p>
                        <p *ngIf="forgotPasswordForm.controls.password.errors?.pattern">{{'validator_password_not_valid' | translate}}</p>
                    </ion-label>
                    <ion-label class="validator-errors" color="danger"
                               *ngIf="forgotPasswordForm.controls.repeat_password.invalid && (forgotPasswordForm.controls['repeat_password'].dirty)">
                        <p *ngIf="forgotPasswordForm.controls.repeat_password.errors?.match">{{'validator_password_mismatch' | translate}}</p>
                    </ion-label>
                    <ion-label class="validator-errors" color="danger"
                               *ngIf=" forgotPasswordForm.get('repeat_password').errors?.mismatch">
                        <p *ngIf="forgotPasswordForm.controls.repeat_password.errors.mismatch">{{'validator_password_mismatch' | translate}}</p>
                    </ion-label>
                    <ion-label class="validator-errors" color="danger"
                               *ngIf="serverErrorMessage">
                        <p >{{serverErrorMessage}}</p>
                    </ion-label>
                </ion-list>
            </ion-grid>
            <ion-button color="primary" class='submit-btn' (click)="submitNewPassword()" [disabled]="submitting">
                {{ 'change_my_password_button' | translate }}
                <ion-spinner class='ml-2 submit-spinner' *ngIf="submitting"></ion-spinner>
            </ion-button>
        </form>
    </div>

</ion-content>
