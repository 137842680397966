<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-menu-toggle>
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
        <ion-title *ngIf="currentState !== State.logged_in">
            {{ 'menu_login_scooling' | translate }}
        </ion-title>
        <ion-title *ngIf="currentState === State.logged_in">
            {{ 'menu_account' | translate }}
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding ion-margin-bottom ion-text-center">
    <div class="page-container">
        <div>
            <img class="logoWithState"
                src="assets/icon/{{ currentState === State.home || currentState === State.logged_in ? 'launcher-icon-3x.png' : 'yuyu_yellow_sad2.png' }}" />
            <h2 class="header1">{{ mainText | translate }}</h2>
            <p class="subHeader1 content-infotext">{{ subHeaderText | translate }}</p>
        </div>
        <div *ngIf="currentState === State.logged_in && appData.authenticatedUser">
            <p class="email-text">
                {{ 'logged_in_as' | translate }}
                <strong>
                    {{ appData.isStudent() ? appData.authenticatedUser.first_name + ' ' + appData.authenticatedUser.last_name : appData.authenticatedUser.email }}
                </strong>
            </p>

            <div class="ion-margin-top ion-margin-bottom" *ngIf="appData.isStudent()">
                {{ 'your_classes' | translate }}:
                <div *ngFor="let g of appData.groupsForUser" class="ion-margin-top">
                    <strong>
                        <a routerLink="/student-class/details/{{g._id}}" routerDirection="root">{{g.name}}</a>
                        <span class="class-access-code" title="{{'code' | translate}}">{{g.access_code}}</span>
                    </strong>
                </div>
                <div class="ion-margin-top" style="margin-top: 30px;">
                    <!-- <a routerLink="/student" routerDirection="root">{{ "add_class" | translate }}</a> -->
                    <ion-button color="green" routerLink="/student" routerDirection="root">
                        <ion-icon name="add" slot="start"></ion-icon>
                        {{ 'add_class' | translate }}
                    </ion-button>
                </div>
            </div>

            <p class="ion-margin-top">
                <ion-button [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'primary'"
                    (click)="buttonEditProfilePageClicked()">
                    <ion-icon name="create" slot="start"></ion-icon>
                    {{ 'edit_profile' | translate }}
                </ion-button>

            </p>

            <p class="ion-margin-top">
                <ion-button *ngIf="appData.isEducator()" [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'primary'"
                    (click)="buttonInviteEducatorsClicked()">
                    <ion-icon name="person-add" slot="start"></ion-icon>
                    {{ 'invite_colleagues' | translate }}
                </ion-button>
            </p>

            <p class="ion-margin-top">
                <ion-button [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'blue' : 'blue'" (click)="logout()">
                    <ion-icon name="exit" slot="start"></ion-icon>
                    {{ 'log_out' | translate }}
                </ion-button>
            </p>
        </div>
        <div *ngIf="currentState !== State.logged_in">
            <div class="ion-padding-top ion-padding-bottom">
                <ion-segment [(ngModel)]="segment">
                    <ion-segment-button value="code">
                        <ion-label>{{ "login_with_code" | translate }}</ion-label>
                    </ion-segment-button>
                    <ion-segment-button value="email">
                        <ion-label>{{ "login_request_code" | translate }}</ion-label>
                    </ion-segment-button>
                    <ion-segment-button value="password" *ngIf="showLoginWithPassword">
                        <ion-label>{{ "login_with_password" | translate }}</ion-label>
                    </ion-segment-button>
                </ion-segment>
            </div>

            <div [ngSwitch]="segment">
                <div *ngSwitchCase="'code'">
                    <p class="email-text">{{ "info_login_with_code_1" | translate }} <a href="/login" onclick="event.preventDefault();" (click)="segment = 'email'">{{ "info_login_with_code_2" | translate }}</a></p>
                    <form [formGroup]="loginCodeForm" (ngSubmit)="onSubmitLoginWithCode()">
                        <ion-grid fixed>
                            <ion-list *ngIf="currentState !== State.legal_error">
                                <ion-item class="ion-no-padding">
                                    <ion-label position="floating">{{ "code" | translate }}</ion-label>
                                    <ion-input type="text" formControlName="code" autocapitalize="characters" #code></ion-input>
                                </ion-item>
                                <ion-label class="validator-errors" color="danger"
                                    *ngIf="!loginCodeForm.controls.code.valid && (loginCodeForm.controls['code'].dirty || codeSubmitAttempt)">
                                    <p *ngIf="loginCodeForm.value.code !== ''">{{'validator_code_not_valid' | translate}}</p>
                                    <p *ngIf="loginCodeForm.value.code === ''">{{'validator_code_required' | translate}}</p>
                                </ion-label>
                            </ion-list>
                        </ion-grid>
                        <ion-button color="primary" type="submit">
                            {{ currentState == State.legal_error ? ("terms_and_conditions" | translate) :
                            ("button_log_in_with_code" |
                            translate) }}
                        </ion-button>
                    </form>
                </div> <!-- END *ngSwitchCase="'code'" -->

                <div *ngSwitchCase="'email'">
                    <p class="email-text">
                        {{ askEmailText | translate }}
                    </p>
                    <form [formGroup]="requestLinkForm" (ngSubmit)="requestLoginLink()">
                        <ion-grid fixed>
                            <ion-list *ngIf="currentState !== State.legal_error">
                                <ion-item class="ion-no-padding">
                                    <ion-label position="floating">{{ "email_address" | translate }}</ion-label>
                                    <ion-input type="email" formControlName="email" #email></ion-input>
                                </ion-item>
                                <!--<ion-item class="no-line error" *ngIf="requestLinkForm.controls['email'].errors && requestLinkForm.controls['email'].dirty">-->
                                <!--<ion-item class="no-line error" *ngIf="!requestLinkForm.controls.email.valid  && (requestLinkForm.controls.email.dirty || submitAttempt) && !requestLinkForm.controls.email.pending">-->
                                <ion-label class="validator-errors" color="danger"
                                    *ngIf="!requestLinkForm.controls.email.valid && (requestLinkForm.controls['email'].dirty || preauthSubmitAttempt)">
                                    <p *ngIf="requestLinkForm.value.email !== ''">{{'validator_email_not_valid' | translate}}</p>
                                    <p *ngIf="requestLinkForm.value.email === ''">{{'validator_email_required' | translate}}</p>
                                </ion-label>
                            </ion-list>
                        </ion-grid>
                        <ion-button color="primary" type="submit">
                            {{ currentState == State.legal_error ? ("terms_and_conditions" | translate) :
                            ("request_link" | translate) }}
                        </ion-button>
                    </form>
                </div> <!-- END *ngSwitchCase="'email'" -->

<!--                <div *ngSwitchCase="'password'">-->
<!--                    <p class="email-text">{{ "info_login_with_password" | translate }}</p>-->
<!--                    <form [formGroup]="loginPasswordForm" (ngSubmit)="loginWithPassword()">-->
<!--                        <ion-grid fixed>-->
<!--                            <ion-list *ngIf="currentState != State.legal_error">-->
<!--                                <ion-item class="ion-no-padding">-->
<!--                                    <ion-label position="floating">{{ "email_address" | translate }}</ion-label>-->
<!--                                    <ion-input type="email" formControlName="email"></ion-input>-->
<!--                                </ion-item>-->
<!--                                <ion-item class="ion-no-padding">-->
<!--                                    <ion-label position="floating">{{ "password" | translate }}</ion-label>-->
<!--                                    <ion-input type="password" formControlName="password"></ion-input>-->
<!--                                </ion-item>-->
<!--                                <ion-label class="validator-errors" color="danger"-->
<!--                                    *ngIf="!loginPasswordForm.controls.email.valid && (loginPasswordForm.controls['email'].dirty || passwordSubmitAttempt)">-->
<!--                                    <p *ngIf="loginPasswordForm.value.email !== ''">{{'validator_email_not_valid' | translate}}</p>-->
<!--                                    <p *ngIf="loginPasswordForm.value.email === ''">{{'validator_email_required' | translate}}</p>-->
<!--                                </ion-label>-->
<!--                            </ion-list>-->
<!--                        </ion-grid>-->
<!--                        <ion-button color="primary" type="submit">-->
<!--                            {{ currentState == State.legal_error ? ("terms_and_conditions" | translate) :-->
<!--                            ("button_log_in" | translate) }}-->
<!--                        </ion-button>-->
<!--                    </form>-->
<!--                </div> &lt;!&ndash; END *ngSwitchCase="'password'" &ndash;&gt;-->
            </div> <!-- END div [ngSwitch]="segment" -->

            <!-- <div>
                <h3 class="header2">{{ "ask_code" | translate }}</h3>
                <ion-button class="login-btn" color="white" (click)="openStudentLoginPage()">
                    {{ "login_with_class_code" | translate }}
                </ion-button>
            </div> -->

        </div>
        <div *ngIf="currentState !== State.logged_in" class="info-no-account">
            <p class="content-infotext" [innerHTML]="'info_login_no_account' | translate">
            </p>
            <p class="content-infotext" [innerHTML]="'info_login_private_user' | translate">
            </p>
        </div>
    </div>
</ion-content>
