<div id="video-overlay-settings">
    <div class="video-overlay-settings-container">
        <div class="video-overlay-settings-top-container" (click)="close()">
            <div class="video-overlay-settings-top-closebutton" (click)="close()">
                <ion-icon name="close"></ion-icon>
            </div>
            <div class="video-overlay-settings-top-title">{{ "settings" | translate }}</div>
        </div>
        <div class="video-overlay-settings-content">
            <ion-grid>
                <ion-row>
                    <ion-col size="4" class="settings-column-main-items" (click)="close()">
                        <div class="scrollable-container">

                            <div *ngFor="let setting of mainItems" (click)="selectMainItem(setting.id); $event.stopPropagation()"
                                class="setting-list-item settings-item-main"
                                [class.settings-item-selected]="setting.selected">
                                <span class="settings-item-label">{{setting.title | translate}}</span>
                                <!-- <ion-icon slot="end" name="checkmark" *ngIf="setting.selected">
                                </ion-icon> -->
                            </div>

                        </div>
                    </ion-col>
                    <ion-col size="4" class="settings-column-options">
                        <div class="settings-scroll-arrows-container">
                            <ion-icon class="settings-scroll-up" name="chevron-up"
                                [style.visibility]="isOptionsScrollUpVisible ? 'visible' : 'hidden'"
                                (click)="scrollOptionsUp()"></ion-icon>
                            <ion-icon class="settings-scroll-down" name="chevron-down"
                                [style.visibility]="isOptionsScrollDownVisible ? 'visible' : 'hidden'"
                                (click)="scrollOptionsDown()"></ion-icon>
                        </div>
                        <div class="scrollable-container" id="scrollable-container-right" *ngIf="getSelectedMainItem()" (scroll)="onContainerScroll($event)">
                            <div *ngFor="let option of getSelectedMainItem().options"
                                class="setting-list-item settings-item-option"
                                [class.settings-item-selected]="option.selected"
                                (click)="settingOptionClicked(getSelectedMainItem(), option.id)">
                                <span class="settings-item-label">{{option.title | translate}}</span>
                                <ion-icon class="setting-item-option-checkmark" name="checkmark"> <!-- *ngIf="option.selected" -->
                                </ion-icon>
                            </div>
                        </div>
                    </ion-col>
                    <ion-col size="4" class="settings-column-preview" (click)="close()">
                        <!-- <div class="video-playser-settings-preview-animation">
                            <div id="video-subtitle-settings" class="video-subtitle video-subtitle-fontsize-multiplier-{{subtitleFontSize}}" style="display: block;">
                                <span id="video-subtitle-inner-settings" class="video-subtitle-inner" [innerHTML]="VideoSettingsPreviewAnimation.currentSubtitle"></span>
                            </div>
                            <img id="click-cursor" src="assets/img/video-player/hand-pointer-icon.png" />
                        </div> -->
                        <video-player-settings-preview-animation></video-player-settings-preview-animation>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
    </div>
</div>
