<div id="video-player-educator-overlay">
    <!-- ### uiStateEnum.video ### -->
    <div id="video-overlay-center-container" class="video-overlay-center-container-educator"
        *ngIf="(uiState === uiStateEnum.video || uiState === uiStateEnum.addingToClass) && !isEmbed">
        <div id="video-overlay-center-top">
        </div>
        <div class="video-overlay-center-middle">
            <div class="video-overlay-center-button-teacher video-overlay-center-teacher-leftButton">
                <ion-button [size]="plt.is('desktop') || plt.is('tablet') ? 'large' : 'medium'" color="primary" (click)="changeUiState(uiStateEnum.clipHome, true);">
                    {{'clip' | translate}}
                </ion-button>
            </div>
            <div class="video-overlay-center-button video-overlay-center-centerButton"
                [style.display]="videoJSplayer && videoJSplayer.paused() ? 'inline' : 'none'"
                (click)="listener.playVideo()" title="{{ 'player_play_button' | translate }}">
                <img src="assets/img/video-player/play-circle-outline.svg" alt="play video" />
            </div>
            <div class="video-overlay-center-button video-overlay-center-centerButton"
                [style.display]="videoJSplayer && !videoJSplayer.paused() ? 'inline' : 'none'"
                (click)="listener.pauseVideo()" title="{{ 'player_pause_button' | translate }}">
                <img src="assets/img/video-player/pause-circle-outline.svg" alt="pause video" />
            </div>
            <div class="video-overlay-center-button-teacher video-overlay-center-teacher-rightButton">
                <ion-button [size]="plt.is('desktop') || plt.is('tablet') ? 'large' : 'medium'" color="primary" (click)="changeUiState(uiStateEnum.tasksHome, true)">
                    {{'tasks' | translate}}
                </ion-button>
            </div>

            <!-- <ion-button class="video-overlay-buttons-center-leftButton">left</ion-button>
            <ion-button class="video-overlay-buttons-center-rightButton">right</ion-button> -->

        </div>

        <div id="video-overlay-center-bottom">
            <div class="video-overlay-center-button-teacher" *ngIf="!clip?._id && !class_id">
                <ion-button [size]="plt.is('desktop') || plt.is('tablet') ? 'medium' : 'small'" color="primary" (click)="addClipToClass()">
                    <ion-icon slot="start" name="add-circle"></ion-icon>
                    {{'add_clip_to_group_short' | translate}}
                </ion-button>
            </div>
            <!-- <div class="video-overlay-center-button-teacher" *ngIf="clip">
                <ion-button size="medium" color="primary" (click)="updateClip()">
                    <ion-icon slot="start" name="save"></ion-icon>
                    Save clip
                </ion-button>
            </div> -->
            <div class="video-overlay-center-button-teacher" *ngIf="clip?._id && isClipCreatedByCurrentUser">
                <ion-button [size]="plt.is('desktop') || plt.is('tablet') ? 'medium' : 'small'" color="primary" (click)="renameClip()">
                    <ion-icon slot="start" name="pencil"></ion-icon>
                    {{'rename_clip' | translate}}
                </ion-button>
            </div>
        </div>

    </div>

    <!-- ### uiStateEnum.tasksHome ### -->
    <div class="video-overlay-tasks" *ngIf="uiState === uiStateEnum.tasksHome">
        <div class="video-overlay-tasks-container">
            <div class="video-overlay-settings-top-container">
                <div class="video-overlay-settings-top-closebutton" (click)="changeUiState(uiStateEnum.video)">
                    <ion-icon name="close"></ion-icon>
                </div>
                <div class="video-overlay-settings-top-title">{{ "tasks" | translate }}</div>
            </div>
            <div class="video-overlay-settings-content">
                <ion-grid>
                    <ion-row>
                        <ion-col size="4" class="column-left">
                            <div class="scrollable-container">
                                <div class="tasks-main-left-container">
                                    <ion-button color="primary" (click)="showCreateQuestionPage()"
                                        class="tasks-main-left-button">{{'create_new_task' | translate}}</ion-button>
                                    <ion-button color="primary" (click)="showAddQuestionPage()"
                                        class="tasks-main-left-button" [disabled]="!availableTasks || availableTasks.length === 0">{{'btn_existing_tasks' | translate}}
                                        <ng-container *ngIf="availableTasks && availableTasks.length > 0">
                                            ({{availableTasks.length}})
                                        </ng-container>
                                    </ion-button>

                                    <ng-container *ngIf="assignedTasks && assignedTasks.length > 0">
                                        <div class="video-overlay-educator-play-button"
                                            [style.display]="videoJSplayer && videoJSplayer.paused() ? 'block' : 'none'"
                                            (click)="playVideo(true)" title="{{ 'player_play_button' | translate }}">
                                            <img src="assets/img/video-player/play-circle-outline.svg" alt="play video" />
                                        </div>
                                        <div class="video-overlay-educator-play-text">
                                            {{ 'watch_videos_with_added_tasks' | translate }}
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ion-col>
                        <ion-col size="8" class="column-right">
                            <div class="scrollable-container">
                                <div class="assigned-tasks-header">{{'tasks_in_this_clip' | translate}}</div>
                                <div class="assigned-tasks-infotext" *ngIf="assignedTasks && assignedTasks.length === 0">
                                    {{'no_tasks_added_yet' | translate}}
                                </div>
                                <div *ngIf="assignedTasks" class="assigned-tasks">
                                    <div *ngFor="let task of assignedTasks" class="assigned-task-container"
                                        [class.assigned-task-container-outside]="!isTaskInsideClipBounds(task)">
                                        <div class="assigned-task-top">
                                            <div class="assigned-task-top-question">
                                                {{task.question}}
                                            </div>
                                            <div class="assigned-task-top-button1"
                                                style="flex: 0 0 8%; text-align: right;"
                                                *ngIf="task.createdBy && task.position - (clip?.cropStart || 0) >= 0 && (task.createdBy === appData.authenticatedUser._id || task.createdBy._id === appData.authenticatedUser._id)">
                                                <ion-button fill="clear" size="small" color="light"
                                                    (click)="editTask(task)">
                                                    <ion-icon name="create" slot="icon-only"></ion-icon>
                                                </ion-button>
                                            </div>
                                            <div class="assigned-task-top-button2"
                                                style="flex: 0 0 8%; text-align: right;">
                                                <ion-button fill="clear" size="small" color="light"
                                                    (click)="removeTask(task)">
                                                    <ion-icon name="trash" slot="icon-only"></ion-icon>
                                                </ion-button>
                                            </div>
                                        </div>
                                        <div class="assigned-task-middle" [title]="taskAnswersToString(task)"
                                            [innerHTML]="'(' + numberOfCorrectAnswers(task) + '/' + task.answers.length + ') ' + taskAnswersToString(task, 'correct-answer')" >
                                        </div>
                                        <div class="assigned-task-bottom">
                                            <ng-container [ngSwitch]="task.type">
                                                <ion-icon name="checkmark-circle"></ion-icon>
                                                <span *ngSwitchCase="taskTypeEnum.multiple_choice">
                                                    {{'task_type_1' | translate}}
                                                </span>
                                                <span *ngSwitchCase="taskTypeEnum.survey">
                                                    {{'task_type_2' | translate}}
                                                </span>&nbsp;|
                                            </ng-container>
                                            <span>
                                                <ion-icon name="pin"></ion-icon>
                                                {{uiUtils.secondsToTimeString(task.position - (clip?.cropStart || 0), true)}}
                                            </span>
                                            &nbsp;|&nbsp;
                                            <span *ngIf="task.createdBy">
                                                <ion-icon name="person"></ion-icon>&nbsp;
                                                <ng-container
                                                    *ngIf="task.createdBy === appData.authenticatedUser._id || task.createdBy._id === appData.authenticatedUser._id; else notMyUser">
                                                    {{'you' | translate}}
                                                </ng-container>
                                                <ng-template #notMyUser>
                                                    <ng-container *ngIf="task.createdBy.first_name">
                                                        {{task.createdBy.first_name}}
                                                    </ng-container>
                                                    <ng-container *ngIf="task.createdBy.last_name">
                                                        {{task.createdBy.last_name.substring(0, 1)}}.
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="!task.createdBy.first_name && !task.createdBy.last_name">
                                                        {{task.createdBy.username}}
                                                    </ng-container>
                                                </ng-template>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--
                            <div class="settings-scroll-arrows-container">
                                <ion-icon class="settings-scroll-up" name="chevron-up"
                                    [style.visibility]="isOptionsScrollUpVisible ? 'visible' : 'hidden'"
                                    (click)="scrollOptionsUp()"></ion-icon>
                                <ion-icon class="settings-scroll-down" name="chevron-down"
                                    [style.visibility]="isOptionsScrollDownVisible ? 'visible' : 'hidden'"
                                    (click)="scrollOptionsDown()"></ion-icon>
                            </div>
                            <div class="scrollable-container" id="scrollable-container-right" *ngIf="getSelectedMainItem()" (scroll)="onContainerScroll($event)">
                                <div *ngFor="let option of getSelectedMainItem().options"
                                    class="setting-list-item settings-item-option"
                                    [class.settings-item-selected]="option.selected"
                                    (click)="settingOptionClicked(getSelectedMainItem(), option.id)">
                                    <span class="settings-item-label">{{option.title | translate}}</span>
                                    <ion-icon class="setting-item-option-checkmark" name="checkmark">
                                    </ion-icon>
                                </div>
                            </div>
                            -->
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </div>
        </div>
    </div>

    <!-- ### uiStateEnum.clipHome ### -->
    <div class="video-overlay-clip-home" *ngIf="uiState === uiStateEnum.clipHome">
        <div class="video-overlay-clip-home-container">
            <div class="video-overlay-settings-top-container">
                <div class="video-overlay-settings-top-closebutton" (click)="changeUiState(uiStateEnum.video)">
                    <ion-icon name="close"></ion-icon>
                </div>
                <div class="video-overlay-settings-top-title">{{ "clip" | translate }}</div>
            </div>
            <div class="video-overlay-settings-content">
                <div class="video-overlay-clip-infotext alert alert-info">
                    {{ 'clip_infotext' | translate }}
                </div>
                <div class="video-overlay-clip-buttons-top">
                    <ion-button [size]="plt.is('mobile') && !plt.is('tablet') && plt.isPortrait() ? 'small' : 'medium'" color="primary" (click)="changeUiState(uiStateEnum.clipSelectStart)">
                        <ion-icon name="play-skip-back" slot="start"></ion-icon>
                        {{'select_start' | translate}}
                    </ion-button>
                    <ion-button [size]="plt.is('mobile') && !plt.is('tablet') && plt.isPortrait() ? 'small' : 'medium'" color="primary" (click)="cropClip()"
                        [disabled]="clip.isFull || (clip.start === clip.cropStart && clip.end === clip.cropEnd) ">
                        <ion-icon name="cut" slot="start"></ion-icon>
                        {{'crop' | translate}}
                    </ion-button>
                    <ion-button [size]="plt.is('mobile') && !plt.is('tablet') && plt.isPortrait() ? 'small' : 'medium'" color="primary" (click)="changeUiState(uiStateEnum.clipSelectEnd)">
                        <ion-icon name="play-skip-forward" slot="start"></ion-icon>
                        {{'select_end' | translate}}
                    </ion-button>
                </div>
                <div class="video-overlay-clip-bottom">
                    <!-- <ng-container *ngIf="clip != null; else noClipStart">
                        Start:
                        {{uiUtils.secondsToTimeString(clip.start)}}
                        |
                        Length:
                        {{uiUtils.secondsToTimeString(clip.duration)}}
                        |
                        End:
                        {{uiUtils.secondsToTimeString(clip.end)}}
                    </ng-container>
                    <ng-template #noClipStart>
                        Start:
                        {{uiUtils.secondsToTimeString(0)}}
                        |
                        Length:
                        {{uiUtils.secondsToTimeString(videoJSplayer?.duration())}}
                        |
                        End:
                        {{uiUtils.secondsToTimeString(videoJSplayer?.duration())}}
                    </ng-template> -->
                    <div>
                        <div class="video-overlay-clip-label">{{'start' | translate}}:</div>
                        <div class="video-overlay-clip-time">{{uiUtils.secondsToTimeString(clip.start)}}</div>
                    </div>
                    <div>
                        <div class="video-overlay-clip-label">{{'length' | translate}}:</div>
                        <div class="video-overlay-clip-time">{{uiUtils.secondsToTimeString(clip.duration)}}</div>
                    </div>
                    <div>
                        <div class="video-overlay-clip-label">{{'end' | translate}}:</div>
                        <div class="video-overlay-clip-time">{{uiUtils.secondsToTimeString(clip.end)}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- ### uiStateEnum.clipSelectStart ### -->
    <div class="video-overlay-clip-select"
        *ngIf="uiState === uiStateEnum.clipSelectStart || uiState === uiStateEnum.clipSelectEnd">
        <div class="video-overlay-clip-select-container">

            <div class="video-overlay-settings-content">
                <div class="video-overlay-clip-confirm-buttons-top">
                    <ion-button color="primary" (click)="changeUiState(uiStateEnum.clipHome)">
                        <ion-icon name="close" slot="start"></ion-icon>
                        {{'btn_cancel' | translate}}
                    </ion-button>
                    <span>
                        <!-- {{uiUtils.secondsToTimeString(videoJSplayer?.currentTime())}} -->
                        <ion-input [value]="uiUtils.secondsToTimeString(videoJSplayer?.currentTime())"
                            (ionFocus)="onTimeInputFocus($event)" (ionBlur)="onTimeInputBlur($event)"
                            (ionChange)="onTimeInputChange($event)"
                            [color]="timeInputColorName"></ion-input>
                    </span>
                    <ion-button color="primary"
                        (click)="selectClipBorderAtCurrentPosition(uiState === uiStateEnum.clipSelectStart)">
                        <ion-icon name="checkmark" slot="start"></ion-icon>
                        {{'btn_confirm' | translate}}
                    </ion-button>

                </div>
            </div>
        </div>
    </div>

</div>
