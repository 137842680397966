<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-title>Invite Admin</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="dismiss()" *ngIf="dismissPageEnabled">
                <ion-icon slot="icon-only" name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-card>
        <ion-card-content>
            <form [formGroup]="inviteAdminForm" (ngSubmit)="onSubmitForm()">
                <ion-list>
                    <ion-item>
                        <ion-label position="floating">Email*</ion-label>
                        <ion-input type="email" formControlName="email"></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-label position="floating">First Name*</ion-label>
                        <ion-input type="text" formControlName="firstName"></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-label position="floating">Last Name*</ion-label>
                        <ion-input type="text" formControlName="lastName"></ion-input>
                    </ion-item>
                </ion-list>

                <div class="ion-padding">
                    <ion-button expand="block" type="submit" class="ion-no-margin"
                                color='blue'
                                [disabled]="submitButtonDisabled()">
                        {{'btn_invite_now' | translate}}
                    </ion-button>
                </div>
            </form>
        </ion-card-content>
    </ion-card>
</ion-content>
