<ion-card *ngIf="video" (click)="openVideoPlayerPage()" [class.not-yet-watched]="!isAlreadyWatched()"
    [class.isHighlighted]="isVideoHighlighted()" [class.isDisabled]="isVideoDisabled()">
    <div class="card-image-container">
        <div>
            <div *ngIf="isVideoDisabled()" id="pic"></div>
            <img [src]="video.imageURL" [alt]="video.title" />
        </div>
        <div class="watched-progress-bar" *ngIf="getProgressForWatchedVideo() as percent;"
            [title]="'your_watching_progress' | translate:{percent:percent}">
            <span [style.width]="percent + '%'">&nbsp;</span>
        </div>
        <div class="favored-icon" [hidden]="!video.favored" *ngIf="video.hasSubtitle" (click)="unfavorVideo(); $event.stopPropagation();"
            [title]="'btn_remove_from_favored' | translate">
            <ion-icon class="favored-icon-full" name="heart" #favoredIconFull></ion-icon>
        </div>
        <div class="favored-icon" [hidden]="video.favored" *ngIf="video.hasSubtitle" (click)="favorVideo(); $event.stopPropagation();" [title]="'btn_add_to_favored' | translate">
            <ion-icon class="favored-icon-outline" name="heart-outline" #favoredIconOutline></ion-icon>
        </div>
        <div class="video-title">
            <span *ngIf="video.program && video.program != video.title" class="video-title-span-group">
                {{getProgramNameEllipsized()}}
            </span>
            <br>
            <span class="video-title-span-title">
                {{getVideoTitleLessProgram()}}
            </span>
        </div>
    </div>
    <div class="ion-card-content">
        <div class="video-card-content-top">
            <div class="video-card-content-top-left">
                <span class="not-yet-watched-indicator">
                    <!-- 🔵 -->
                    <ion-icon name="ellipse" ioniconRemoveTitle iconTitle="{{ 'video_not_yet_watched' | translate }}"></ion-icon>
                </span>
                <a class="video-category-chip" *ngIf="video.cat_id"
                    [routerLink]="['/category/', appData.getVideoCategoryById(video.cat_id).slug]"
                    (click)="openCategoryPage(); $event.stopPropagation(); $event.preventDefault();">{{'video_cat_' + video.cat_id | translate}}</a>
                <!-- "Others" category -->
                <span class="video-category-chip" *ngIf="!video.cat_id"
                    (click)="openCategoryPage(); $event.stopPropagation(); $event.preventDefault();">{{'video_cat_' + '5e5cf1543a66040eaa718036' | translate}}</span>
            </div>
            <div class="logo-and-time">
                <div *ngIf="video.websource_id && appData.getLogoPathForSource(video.websource_id, 's')"
                    class="websource-logo-image">
                    <img class="video-source-logo" [src]="appData.getLogoPathForSource(video.websource_id, 's')" />
                </div>
                <div *ngIf="video.websource_id && !appData.getLogoPathForSource(video.websource_id, 's')">
                    <span class="websource-logo-name">{{appData.getNameForSource(video.websource_id)}}</span>
                </div>
                <div class="video-time">
                    <ion-icon name="time-outline"></ion-icon>
                    <span *ngIf="video.offset && video.offset.length === 2">
                        {{uiUtils.secondsToTimeString(video.offset[1] - video.offset[0])}}
                    </span>
                    <span *ngIf="(!video.offset || video.offset.length != 2) && video.duration">
                        {{uiUtils.secondsToTimeString(video.duration)}}
                    </span>
                    <span *ngIf="(!video.offset || video.offset.length != 2) && !video.duration && video.durMinutes">
                        ~ {{video.durMinutes}} Min.
                    </span>
                    <span *ngIf="!video.duration && !video.durMinutes && (!video.offset || video.offset.length < 2)">
                        &nbsp;
                    </span>
                </div>
            </div>
            <div class="free-text" *ngIf='video.isAvailableForFreeUser'>Free</div>

        </div>
    </div>
    <div class='bottom-information'>
        <div class='video-card-content-top'>
            <div class='video-card-content-top-left'>
                <div class="add-circle-button" *ngIf="appData.isEducator() && video.hasSubtitle">
                    <div class="icon-add-circle"
                         (click)="addVideoToClass();$event.stopPropagation();"
                         title="Add to class"
                         [title]="'tooltip_add_video_to_group' | translate">
                        <ion-icon color="blue" name="add-circle"></ion-icon>
                    </div>
                    <ion-note *ngIf="video.teachResURL" title="{{'teaching_resources' |  translate}}">
                        <ion-icon name="document-outline" class="icon-tech-res"></ion-icon>
                    </ion-note>
                    <ion-note *ngIf="video.assessment_docs && video.assessment_docs.length"
                              title="{{ 'catalog_video_worksheets' | translate }}">
                        <ion-icon name="document" class="icon-document"></ion-icon>
                        {{ uiUtils.getWorksheetLevels(video) }}
                    </ion-note>
                    <ion-note *ngIf="video.task_docs && video.task_docs.length"
                              title="{{ 'tasks' | translate }}">
                        <ion-icon name="list-circle" class="icon-document" ioniconRemoveTitle iconTitle="{{ 'tasks' | translate }}"></ion-icon>
                        {{ video.task_docs.length }} {{ video.task_docs.length <= 1 ? 'task' : 'tasks' | translate }}
                    </ion-note>
                </div>
            </div>
            <div class="video-date" *ngIf='showPublishDate'>
                <span> {{ "published" | translate }} : {{video.publishDate | date:'mediumDate'}}</span>
            </div>
        </div>
    </div>

    <!-- <ion-item lines="none">
        <ion-label class="ion-text-wrap">
            <ion-card-title>{{video.title}}</ion-card-title>
            <ion-label>
                <a class="video-category-chip" *ngIf="video.cat_id" [routerLink]="['/category/', appData.getVideoCategoryById(video.cat_id).slug]" (click)="openCategoryPage(); $event.stopPropagation(); $event.preventDefault();">
                    {{'video_cat_' + video.cat_id | translate}}</a>
                <span class="text-content">{{uiUtils.translationsToText(video.translations, video.originalLang)}}</span>
            </ion-label>
        </ion-label>

        <ion-note slot="end" class="video-time-and-websource">
            <div class="video-time">
                <span *ngIf="video.offset && video.offset.length === 2">
                    {{uiUtils.secondsToTimeString(video.offset[1] - video.offset[0])}}
                </span>
                <span *ngIf="(!video.offset || video.offset.length != 2) && video.duration">
                    {{uiUtils.secondsToTimeString(video.duration)}}
                </span>
                <span *ngIf="(!video.offset || video.offset.length != 2) && !video.duration && video.durMinutes">
                    ~ {{video.durMinutes}} Min.
                </span>
                <span *ngIf="!video.duration && !video.durMinutes && (!video.offset || video.offset.length < 2)">
                    &nbsp;
                </span>
            </div>
            <div *ngIf="video.websource_id && appData.getLogoPathForSource(video.websource_id, 's')"
                class="websource-logo-image">
                <img class="video-source-logo" [src]="appData.getLogoPathForSource(video.websource_id, 's')" />
            </div>
            <div *ngIf="video.websource_id && !appData.getLogoPathForSource(video.websource_id, 's')">
                <span class="websource-logo-name">{{appData.getNameForSource(video.websource_id)}}</span>
            </div>
        </ion-note>
    </ion-item> -->
</ion-card>

<!-- Show the "2 days still available" flap: -->
<app-video-catalog-expires-flap [video]="video" [isDisabled]="isVideoDisabled()"></app-video-catalog-expires-flap>

<app-video-catalog-only-premium-flap [show]='isVideoDisabled() === 3'></app-video-catalog-only-premium-flap>



