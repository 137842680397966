import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { LoadingController, NavController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from 'src/app/services/app-data.service';
import { AppManager } from 'src/app/services/app-manager.service';
import { AbstractRootMenuPage } from 'src/app/utils/abstract-root-menu-page';
import { ActivatedRoute } from '@angular/router';
import { UsersApi } from '../../services/api/users-api.service';
import { EducatorsApi } from '../../services/api/educators-api';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup, ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import { IForgotPassword, IUpdatePassword } from '../../interfaces/IUpdatePassword';


@Component({
    selector: 'app-forgot-password-page',
    templateUrl: 'forgot-password-page.html',
    styleUrls: ['forgot-password-page.scss']
})

export class ForgotPasswordPage extends AbstractRootMenuPage implements OnInit {
    public forgotPasswordForm: UntypedFormGroup;
    public requestLinkForm: UntypedFormGroup;
    public serverErrorMessage: string;
    public submitting = false;
    showPasswordForm = false;
    showwEmailForm = false;
    private jwt_token;
    private email: string;

    passwordMatchingValidator(password: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control || !control.parent) {
                return null;
            }
            return control.parent.get(password).value === control.value ? null : { mismatch: true };
        };
    }

    constructor(public uiUtils: UiUtils,
                private _route: ActivatedRoute,
                public translate: TranslateService,
                public appData: AppData,
                private loadingCtrl: LoadingController,
                public appManager: AppManager,
                private formBuilder: UntypedFormBuilder,
                public navCtrl: NavController,
                public plt: Platform,
                private titleService: Title,
                private usersApi: UsersApi,
    ){
        super(plt);

        this.requestLinkForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.email])],
        });

        this.forgotPasswordForm = this.formBuilder.group({
            password: ['', Validators.compose([
                Validators.required,
                Validators.minLength(8),
                Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$')
            ])],
            repeat_password: ['', [Validators.required, this.passwordMatchingValidator('password')]]
        });
    }



    async ngOnInit() {
        this.titleService.setTitle('Forgot Password | uugot.it');
        this.showPasswordForm = false;
        const loading = await this.loadingCtrl.create({});
        await loading.present();
        this._route.queryParams.subscribe(async params => {
            const { t, email } = params;
            if (t && email) {
                this.jwt_token = t;
                this.email = email;
                try {
                    const res = await this.usersApi.checkForgotPasswordToken(t).toPromise();
                    loading.dismiss();
                    if (res.success) {
                        this.showPasswordForm = true;
                        this.uiUtils.displayToast("Please type your new password", null, 3000, 'success');
                    } else {
                        this.uiUtils.displayToast("The link is invalid", null, 3000, 'danger');
                        this.navCtrl.navigateRoot('/');
                    }
                } catch (err) {
                    loading.dismiss();
                    this.navCtrl.navigateRoot('');
                }
            } else {
                loading.dismiss();
                this.showwEmailForm = true;
            }
        });
    }

    ionViewWillEnter() {
        this.appData.activePage = 'confirm';
    }


    async submitNewPassword() {
        this.serverErrorMessage = null;
        if (!this.forgotPasswordForm.valid) {
            return null;
        }
        this.submitting = true;
        const formData = this.forgotPasswordForm.value;

        try {
            const data: IForgotPassword = {
                password: formData.password,
                password_repeat: formData.repeat_password,
                jwt_token: this.jwt_token,
                email: decodeURIComponent(this.email)
            };
            const res = await this.usersApi.forgotPassword(data).toPromise();
            if (res.success) {
                this.uiUtils.displayToast("Your passsword has changed correctly", null, 3000, 'success');
                this.forgotPasswordForm.reset();
                this.navCtrl.navigateRoot('/login');

            } else {
                this.uiUtils.displayToast("There is an error. Please try later or please request again the link", null, 3000, 'danger');
                this.showPasswordForm = false;
                this.navCtrl.navigateRoot('/forgot-password');
            }
        } catch (err) {
            this.uiUtils.displayToast("There is an error. Please try later or please request again the link", null, 3000, 'danger');
            this.showPasswordForm = false;
            this.navCtrl.navigateRoot('/forgot-password');
        }
        // Callback in case of preauth error


    }

    async handleRequestEmail() {
        if (!this.requestLinkForm.valid) {
            return null;
        }
        this.submitting = true;
        const formData = this.requestLinkForm.value;
        try {
            const res = await this.usersApi.requestForgotPasswordEmail(formData.email).toPromise();
            if (res.success) {
                this.uiUtils.displayToast("We have sent you an email", null, 3000, 'success');
                this.requestLinkForm.reset();
            } else {
                this.uiUtils.displayToast("There is an error. Please try later", null, 3000, 'danger');
            }
        } catch (err) {
            this.uiUtils.displayToast(this.translate.instant('error_forgotten_password'), null, 3000, 'danger');
        }
    }
}
