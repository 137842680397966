<ion-header>
    <ion-toolbar color="blue">
        <ion-buttons slot="start">
            <ion-menu-toggle>
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
        <ion-title>
            {{ group ? group.name : '' }}
        </ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="refreshContent()" *ngIf="group && groupResponse && groupResponse.success" title="{{ 'btn_reload_class' | translate }}">
                <ion-icon slot="icon-only" name="refresh"></ion-icon>
            </ion-button>
            <ion-button (click)="presentActionMenuPopover($event)" title="{{'menu_actions' | translate}}">
                <ion-icon slot="icon-only" ios="ellipsis-horizontal" md="ellipsis-vertical"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content [scrollEvents]="true" (ionScroll)="updateScrollPosition($event)">
    <ion-progress-bar *ngIf="(!group && !groupResponse && classVideosInfo.length === 0) || loadingAction" type="indeterminate"></ion-progress-bar>
    <ion-grid fixed *ngIf="groupResponse && !groupResponse.success" class="ion-padding ion-text-center">
        <div class="alert alert-danger" >
            {{groupResponse.msg}}
        </div>
    </ion-grid>
    <ion-grid fixed *ngIf="maxLimitStudentsReached" class="ion-padding ion-text-center">
        <div class="alert alert-danger" >
            {{ 'max_students_in_class_reached' | translate:{maxStudents:group.maxStudents} }}
        </div>
    </ion-grid>
    <ion-grid fixed *ngIf="group && groupResponse && groupResponse.success">
        <ion-row *ngIf="!group.isTemplate" class="ion-margin-start ion-margin-end ion-padding-top">
            <ion-col size="12" size-sm="12" size-md="12" size-lg="8" size-xl="9">
                <div>
                    <div style="float:left;" class="ion-margin-end">
                        <span class="header1">
                            {{ "class_code" | translate }}: <span class="selectable-text">{{ group.access_code }}</span>
                        </span>
                        <br>
                        <b [style.color]="colorForVideosVisibleText">
                            {{ videosVisibleCount + " " + ("of" | translate) + " " + totalVideosCount + " " + ("videos_visible" |
                            translate) }}
                        </b>
                    </div>
                    <ion-button style="float:left;" color="blue" class="ion-margin-end copy-btn" (click)="copyClassCodeToClipboard()" ><!-- *ngIf="!plt.is('hybrid')" -->
                        {{ "copy_code" | translate }}
                    </ion-button>
                    <ion-button style="float:left;" color="blue" class="copy-btn" [disabled]='appData.userCustomer?.license?.type === "free"' (click)="cloneClass($event)" *ngIf="totalVideosCount > 0">
                        {{ "clone_class" | translate }}
                    </ion-button>
                </div>
                <div style="clear: both;">
                    <ion-item lines="none" style="margin-left: -16px;">
                        <ion-icon name="information-circle"></ion-icon>
                        <ion-label class="ion-margin-start ion-text-wrap">
                            {{ "access_class_with_code" | translate }}
                        </ion-label>
                    </ion-item>
                </div>
            </ion-col>
            <ion-col size="12" size-sm="12" size-md="12" size-lg="4" size-xl="3">
                <!-- <ion-item lines="none" class="pointer-cursor" (click)="toggleStatistics()">
                    <ion-label>
                        {{ "Start: " + (group.start | date:'yyyy-MM-dd') + " | " + ("end" | translate) + ": " +
                        (group.end | date:'yyyy-MM-dd') }}
                    </ion-label>
                    <ion-icon *ngIf="!showStatistics" name="caret-down" class="arrows" item-right></ion-icon>
                    <ion-icon *ngIf="showStatistics" name="arrow-dropup" class="arrows" item-right></ion-icon>
                </ion-item>
                <ion-label class="statistics" *ngIf="showStatistics" padding-left>
                    {{ ("base_language" | translate) + ': ' + ('lang_'+group.base_language | translate) }}
                </ion-label> -->

                <table style="width: 100%;" class="class-info-table">
                    <tr><td class="content-infotext">{{ "start" | translate }}:</td><td>{{ (group.start | date:'mediumDate') }}</td></tr>
                    <tr><td class="content-infotext">{{ "end" | translate }}:</td><td>{{ (group.end | date:'mediumDate') }}</td></tr>
                    <tr><td class="content-infotext">{{ "base_language" | translate }}:</td><td>{{'lang_'+group.base_language | translate }}</td></tr>
                    <tr>
                        <td colspan="2">
                            <ion-button class="ion-margin-top" fill="solid" size="small" color="light" (click)="openLeaderboardPage()">
                                <ion-icon name="trophy" slot="start"></ion-icon>{{ "season_leaderboard" | translate }}
                            </ion-button>
                        </td>
                    </tr>
                </table>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="group.isTemplate">
            <ion-col size="12">
                <ion-row class="ion-margin-start">
                    <ion-button class="ion-margin-start ion-margin-top copy-btn-template" [disabled]='appData.userCustomer?.license?.type === "free"' (click)="cloneClass($event)">
                        {{ "clone_class" | translate }}
                    </ion-button>
                    <p class="clone-template-class-infotext ion-margin-top">
                        {{ "clone_template_class_infotext" | translate}}
                    </p>
                </ion-row>
                <ion-row>
                    <ion-item lines="none">
                        <ion-icon name="information-circle"></ion-icon>
                        <ion-label class="ion-margin-start ion-text-wrap" [innerHtml]="groupDescriptionAsHtml"></ion-label>
                    </ion-item>
                </ion-row>
            </ion-col>
        </ion-row>

        <div class="alert alert-danger ion-margin-top ion-margin-start ion-margin-end" *ngIf="totalVideosCount > 0 && videosVisibleCount === 0">
            {{ 'hint_no_video_visible' | translate }}
        </div>

        <div class="ion-padding">
            <ion-segment [(ngModel)]="segment" [scrollable]="isMobile() ? true : false" color="blue">
                <ion-segment-button value="videos">
                    <ion-label>{{ 'videos' | translate }}</ion-label>
                </ion-segment-button>
                <ion-segment-button value="students" *ngIf="!group.isTemplate">
                    <ion-label [color]="maxLimitStudentsReached ? 'danger' : null">{{ ('class_participants' | translate) + ' (' + group.students.length + ')' }}</ion-label>
                </ion-segment-button>
                <ion-segment-button value="educators" *ngIf="!group.isTemplate && isAdminOrMainEducator()">

                    <ion-label>{{ 'Educators' + ' (' + (group.team_educators && group.team_educators.length > 0 ?  group.team_educators.length : 1) + ')' }}</ion-label>
                </ion-segment-button>
                <ion-segment-button value="statistics" *ngIf="!group.isTemplate">
                    <ion-label>{{ 'statistics' | translate }}</ion-label>
                </ion-segment-button>
            </ion-segment>
        </div>

        <div [ngSwitch]="segment">
            <div *ngSwitchCase="'videos'">



                <!-- Loop through videos -->
                <ion-row>
                    <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="6" *ngIf='group.hasVideosAvailableForFreeCustomer'>
                        <ion-card>
                            <div class="card-image-container">
                                <img (click)="openVideosAvailableBasket()" [src]="group.coverVideosAvailableForFreeCustomer" />
                                <div class="card-top-image-overlay-not-visible" >
                                    <div>
                                        {{ 'free_videos_for_in_class_use' | translate }}
                                    </div>
                                </div>
                            </div>
                            <ion-item class="ion-no-padding" (click)="openVideosAvailableBasket()">
                                    <ion-text class='ion-padding'>
                                            {{'free_videos_for_in_class_use_explanation' | translate}}
                                    </ion-text>
                            </ion-item>
                        </ion-card>
                    </ion-col>
                    <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="6"
                        *ngFor="let video of classVideosInfo; let i = index;">
                        <ion-card *ngIf="classVideos[i]; let groupVideo" [class.isDisabled]="isVideoExpired(video) || video.isEnabled === false">
                            <div class="card-image-container">
                                <img [src]="video.imageURL" (click)="openVideoPlayerPage(video, groupVideo.clip_id, groupVideo)" [alt]="video.title"/>
                                <div class="card-top-image-overlay-not-visible" *ngIf="!groupVideo.is_visible">
                                    <div>
                                        {{ 'video_not_released' | translate }}
                                    </div>
                                </div>
                                <div class="card-top-image-overlay" *ngIf="groupVideo.clip_id">
                                    <span *ngIf="!groupVideo.clip_id.isFull">
                                        {{'clip_of_video' | translate}}
                                        "{{video.title}}" ({{uiUtils.secondsToTimeString(groupVideo.clip_id.start)}} - {{uiUtils.secondsToTimeString(groupVideo.clip_id.end)}})
                                    </span>

                                    <span class="card-top-image-overlay-tasks" *ngIf="groupVideo.clip_id.task_ids && groupVideo.clip_id.task_ids.length"
                                        title="{{ 'tasks' | translate }}">
                                        <ion-icon name="list-circle" class="icon-document" ioniconRemoveTitle iconTitle="{{ 'tasks' | translate }}"></ion-icon>
                                        {{ groupVideo.clip_id.task_ids.length }} {{ groupVideo.clip_id.task_ids.length <= 1 ? 'task' : 'tasks' | translate }}
                                        <span translate [translateParams]="{numCompleted: groupVideo.numUsersFinishedTasks, numStudents: group.students?.length}">num_students_completed_all</span>
                                    </span>

                                    <!-- <ion-button size="small" style="float: right;" fill="outline" color="light" (click)="openStatisticsPage(groupVideo.clip_id)" > -->
                                    <ng-container *ngIf="groupVideo.numUsersFinishedTasks > 0">
                                        <ion-button *ngIf="groupVideo.clip_id.leaderboard" size="small" style="float: right;" fill="solid" color="light"
                                            (click)="openVideoPlayerPage(video, groupVideo.clip_id, groupVideo, true); $event.stopPropagation();" title="{{'show_leaderboard' | translate}}">
                                            <ion-icon name="stats-chart" slot="icon-only"></ion-icon>
                                        </ion-button>
                                        <ion-button *ngIf="!groupVideo.clip_id.leaderboard && groupVideo.clip_id.task_ids && groupVideo.clip_id.task_ids.length"
                                            size="small" style="float: right;" fill="outline" color="light"
                                            (click)="generateLeaderboardForClip(video, groupVideo.clip_id, groupVideo); $event.stopPropagation();"
                                            title="{{'generate_leaderboard' | translate}}">
                                            <ion-icon name="stats-chart" slot="icon-only"></ion-icon>
                                        </ion-button>
                                    </ng-container>

                                </div>
                            </div>
                            <ion-item class="ion-no-padding" (click)="openVideoPlayerPage(video, groupVideo.clip_id, groupVideo)">
                                <ion-label class="ion-padding-start ion-text-wrap">
                                    <ion-card-title>
                                        <ng-container *ngIf="groupVideo.clip_id && groupVideo.clip_id.title; else noClipTitle">
                                            {{groupVideo.clip_id.title}}
                                        </ng-container>
                                        <ng-template #noClipTitle>
                                            {{video.title}}
                                        </ng-template>
                                    </ion-card-title>
                                    <ion-label class="translations">
                                        {{getTranslations(video)}}
                                    </ion-label>
                                </ion-label>

                                <ion-note slot="end">
                                    <div class="video-time">
                                        <ion-icon name="time"></ion-icon>
                                        <span *ngIf="groupVideo.clip_id && !groupVideo.clip_id.isFull; else noClipDuration">
                                            {{uiUtils.secondsToTimeString(groupVideo.clip_id.duration)}}
                                        </span>
                                        <ng-template #noClipDuration>
                                            <span *ngIf="video.offset && video.offset.length === 2">
                                                {{uiUtils.secondsToTimeString(video.offset[1] - video.offset[0])}}
                                            </span>
                                            <span *ngIf="(!video.offset || video.offset.length != 2) && video.duration">
                                                {{uiUtils.secondsToTimeString(video.duration)}}
                                            </span>
                                            <span *ngIf="(!video.offset || video.offset.length != 2) && !video.duration && video.durMinutes">
                                            ~ {{video.durMinutes}} Min.
                                            </span>
                                        </ng-template>
                                    </div>
                                    <div *ngIf="video.websource_id && appData.getLogoPathForSource(video.websource_id, 's')">
                                        <img class="video-source-logo"
                                            [src]="appData.getLogoPathForSource(video.websource_id, 's')" />
                                    </div>
                                    <div *ngIf="video.websource_id && !appData.getLogoPathForSource(video.websource_id, 's')"
                                        class="websource-logo-name">
                                        <span>{{appData.getNameForSource(video.websource_id)}}</span>
                                    </div>
                                </ion-note>
                            </ion-item>
                            <ion-item class="pointer-cursor worksheet-selector" *ngIf="!group.isTemplate"
                                (click)="openWorksheetSelectPage(groupVideo);$event.stopPropagation()">
                                <ion-label class="bottom-border ion-padding-bottom" color="dark"
                                    *ngIf="groupVideo.assessment_id">
                                    <span *ngIf="groupVideo.assessment_id">
                                        <ion-icon name="document" class="worksheet-icon-start"></ion-icon>
                                        {{groupVideo.assessment_id.name }}
                                        <span class="assessment-level">
                                            {{ ' ' + groupVideo.assessment_id.level }}
                                        </span>
                                    </span>
                                </ion-label>
                                <ion-label *ngIf="!groupVideo.assessment_id" class="bottom-border red-style ion-padding-bottom">
                                    {{ ("select_worksheet" | translate) }}
                                </ion-label>
                                <ion-icon name="caret-down" class="arrows" item-right></ion-icon>
                            </ion-item>
                            <ion-item class="video-educator-actions" *ngIf="!group.isTemplate">
                                <ion-toggle color="blue" [ngModel]="groupVideo.is_visible"
                                    [checked]="groupVideo.is_visible"
                                    (ionChange)="toggleVideoVisibility($event, groupVideo, video)">
                                </ion-toggle>
                                <ion-label class="label-visibility">
                                    {{ 'video_visible_for_students' | translate}}
                                </ion-label>
                                <ion-button color="dark" fill="clear" (click)="deleteVideoFromGroup(video._id);" title="{{ 'remove' | translate }}">
                                    <ion-icon slot="icon-only" name="trash"></ion-icon>
                                </ion-button>
                            </ion-item>
                            <ion-item *ngIf='groupVideo.addedBy'  lines='full'>
                                <ion-label class='educator-name ion-text-capitalize' >
                                    Added by {{groupVideo.addedBy.first_name}} {{groupVideo.addedBy.last_name}}
                                </ion-label>
                            </ion-item>
                            <ion-item *ngIf="groupVideo.subjects && groupVideo.subjects.length > 0" lines="full">
                                <ion-label class='subjects-list'>
                                    Subjects: {{ getSubjectsNames(groupVideo.subjects) }}
                                </ion-label>
                            </ion-item>
                        </ion-card>

                        <!-- Show the "2 days still available" flap: -->
                        <app-video-catalog-expires-flap [video]="video"></app-video-catalog-expires-flap>
                    </ion-col>
                </ion-row>
                <div>
                    <ion-text class='ion-margin-start ion-margin-end'>
                        <h1 class='ion-margin-start'>
                            {{'helpful_tips' | translate}}
                        </h1>
                    </ion-text>
                </div>
                <div class="alert alert-info ion-margin-start ion-margin-end"
                     >
                    <span [innerHTML]="sanitizer.bypassSecurityTrustHtml(translate.instant('infotext_class_no_videos_1'))"></span><br>
                    <ion-button routerLink="/catalog/all" color="light" class="ion-margin-top">{{ 'video_library' | translate }}</ion-button><br><br>
                </div>

                <div class="alert alert-info ion-margin-top ion-margin-start ion-margin-end"
                     >
                    <span [innerHTML]="sanitizer.bypassSecurityTrustHtml(translate.instant('infotext_class_no_videos_3'))"></span><br>
                    <ion-button routerLink="/catalog/all" color="light" class="ion-margin-top">{{ 'video_library' | translate }}</ion-button><br><br>
                </div>

                <div class="alert alert-info ion-margin-top ion-margin-start ion-margin-end"
                     >
                    {{ 'infotext_class_no_videos_4' | translate }}
                    <a routerLink="/video/academy/61f2788477ea279857b74222">
                        {{'infotext_create_task' | translate}}
                    </a>
                    <br>
                </div>
                <div class='ion-margin-bottom'>
                    <ion-text class='ion-margin-start ion-margin-bottom'>
                        {{"infotext_more_helpful_tips" | translate}}
                        <a routerLink="/help">
                            {{'infotext_here' | translate}}
                        </a>
                    </ion-text>
                </div>
            </div>

            <div *ngSwitchCase="'students'">

                <!-- Info if not students have joined yet -->
                <div>
                    <ion-text class='ion-margin-start ion-margin-end'>
                        <h1 class='ion-margin-start'>
                            {{'helpful_tips' | translate}}
                        </h1>
                    </ion-text>
                </div>
<!--                <div class="alert alert-info ion-margin-start ion-margin-end"-->
<!--                    *ngIf="group.students && group.students.length === 0">-->
                <div class="alert alert-info ion-margin-start ion-margin-end">
                    <span [innerHTML]="sanitizer.bypassSecurityTrustHtml(translate.instant('infotext_class_no_students_1', {classCode: group.access_code} ))"></span><br>
                    <ion-button color="light" (click)="copyClassCodeToClipboard()" class="ion-margin-top">{{ "copy_code" | translate }}</ion-button><br><br>
                </div>

<!--                <div class="alert alert-info ion-margin-top ion-margin-start ion-margin-end"-->
<!--                     *ngIf="group.students && group.students.length === 0">-->
                <div class="alert alert-info ion-margin-top ion-margin-start ion-margin-end">
                    {{ 'infotext_class_no_students_2' | translate }}<br>
                    <ion-button routerLink="/help" color="light" class="ion-margin-top">{{ 'help_section' | translate }}</ion-button>
                </div>

                <div class="alert alert-info ion-margin-top ion-margin-start ion-margin-end">
                    {{ 'infotext_class_no_students_3' | translate }}<br>
                </div>


                <ion-list>
                    <ion-item *ngFor="let student of group.students">
                        <ion-label>
                            <h2 class='ion-text-capitalize'>{{ student.first_name }} {{ student.last_name }}</h2>
                            <p>{{ 'joined' | translate }} {{ student.created_at | date:'medium' }}</p>
                        </ion-label>
                        <ion-icon name="trash" class="remove-student-btn" item-right (click)="removeStudent(student)">
                        </ion-icon>
                    </ion-item>
                </ion-list>
            </div>

            <div *ngSwitchCase="'educators'">
                <!-- Info for educators -->
                <div class="alert alert-info ion-margin-top ion-margin-start ion-margin-end">
                    <span [innerHTML]="sanitizer.bypassSecurityTrustHtml(translate.instant('infotext_class_number_educators', {className: group.name, remainingTeacherInvites: remainingTeacherInvites + '/10'} ))"></span><br>
                </div>

                <!-- Info if not educators have joined yet -->
                <div class="alert alert-info ion-margin-top ion-margin-start ion-margin-end"
                     *ngIf="group.team_educators && group.team_educators.length === 0">

                    <span>You can add multiple educators for this class.</span><br>
                    <ion-button color="light" id="open-modal" (click)="openAddEducatorsModal()" class="ion-margin-top ion-padding-start ion-padding-end ion-button-center">{{'invite_teachers_class' | translate}}</ion-button><br><br>
                    <ion-modal trigger="open-modal" [isOpen]="isModalOpen" (willDismiss)="onWillDismiss($event)">
                        <form [formGroup]="educatorCreateForm">
                            <ng-template>
                                <ion-header>
                                    <ion-toolbar>
                                        <ion-buttons slot="start">
                                            <ion-button (click)="cancel()">{{ 'close' | translate }}</ion-button>
                                        </ion-buttons>
                                        <ion-title>{{'invite_educator_class' | translate}}</ion-title>
                                        <ion-buttons slot="end">
                                            <ion-button (click)="confirm()" [disabled]="!this.educatorCreateForm.valid" [strong]="true">{{ 'invite' | translate }}</ion-button>
                                        </ion-buttons>
                                    </ion-toolbar>
                                </ion-header>
                                <ion-content class="ion-padding">
                                    <ion-item>
                                        <ion-label position="stacked">{{ 'educators_email' | translate }}</ion-label>
                                        <ion-input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" formControlName="email" required placeholder="educator@uugot.it"></ion-input>
                                    </ion-item>
                                    <ion-item>
                                        <ion-label position="stacked">{{ 'educators_name' | translate }}</ion-label>
                                        <ion-input type="text" formControlName="name" required placeholder="John"></ion-input>
                                    </ion-item>
                                    <ion-item>
                                        <ion-label position="stacked">{{ 'educators_lastname' | translate }}</ion-label>
                                        <ion-input type="text" formControlName="lastname" required placeholder="Doe"></ion-input>
                                    </ion-item>
                                    <br><br>
                                    <ion-item>
                                        <ion-label class="ion-educator-label">
                                            <h2>{{ 'educators_subjects' | translate }}</h2>
                                        </ion-label>
                                        <!-- <ion-select placeholder="Select subjects" [multiple]="true" formControlName="subjects" required>
                                            <ion-select-option [value]="subject" *ngFor="let subject of subjects">
                                                {{ subject.slug }}
                                            </ion-select-option>
                                        </ion-select> -->
                                        <ionic-selectable
                                            formControlName="subjects"
                                            [items]="subjectsLang"
                                            itemValueField="_id"
                                            itemTextField="lang"
                                            [canSearch]="true"
                                            [isMultiple]="false"
                                            (onChange)="onChange($event)">
                                        </ionic-selectable>
                                    </ion-item>
                                </ion-content>
                            </ng-template>
                        </form>
                    </ion-modal>
                </div>

                <ion-list>
                    <ion-item *ngFor="let educator of group.team_educators">
                        <ion-label>
                            <h2 class='ion-text-capitalize'>{{ educator.edId.last_name }} {{ educator.edId.first_name }}</h2>
                            <p>{{ educator.edId.email }}</p>
<!--                            <p>{{ 'joined' | translate }} {{ educator.created_at | date:'medium' }}</p>-->
                        </ion-label>
                        <ion-icon name="create" id="open-edit-modal" class="remove-student-btn" item-right (click)="openEditEducatorsModal(educator)">
                        </ion-icon>
                        <ion-icon name="trash" *ngIf='educator.edId._id !== appData.authenticatedUser._id' class="remove-educator-btn" (click)="removeEducator(educator)">
                        </ion-icon>
                        <ion-modal trigger="open-edit-modal" [isOpen]="isEditModalOpen" (willDismiss)="closeEditModal()">

                                <ng-template >
                                    <ion-header>
                                        <ion-toolbar>
                                            <ion-buttons slot="start">
                                                <ion-button (click)="closeEditModal()">{{ 'close' | translate }}</ion-button>
                                            </ion-buttons>
                                            <ion-title>{{ 'edit_educator_info' | translate }}</ion-title>
                                            <ion-buttons slot="end">
                                                <ion-button (click)="confirmEdit(educator.edId._id)" [disabled]="!this.educatorEditForm.valid" [strong]="true">Edit</ion-button>
                                            </ion-buttons>
                                        </ion-toolbar>
                                    </ion-header>
                                    <ion-content class="ion-padding" *ngIf='currentEditingEducator'>
                                        <ion-item>
                                            <ion-label position="stacked">{{ 'educators_email' | translate }}</ion-label>
                                            <ion-input type="email" [value]='currentEditingEducator.edId.email'  disabled placeholder="educator@uugot.it"></ion-input>
                                        </ion-item>
                                        <ion-item>
                                            <ion-label position="stacked">{{ 'educators_name' | translate }}</ion-label>
                                            <ion-input type="text" [value]='currentEditingEducator.edId.first_name' disabled placeholder="John"></ion-input>
                                        </ion-item>
                                        <ion-item>
                                            <ion-label position="stacked">{{ 'educators_lastname' | translate }}</ion-label>
                                            <ion-input type="text"  [value]='currentEditingEducator.edId.last_name' disabled placeholder="Doe"></ion-input>
                                        </ion-item>
                                        <br><br>
                                        <form [formGroup]="educatorEditForm" >
                                            <ion-label class="ion-educator-label">
                                                <h2>{{ 'educators_subjects' | translate }}</h2>
                                            </ion-label>
                                            <ion-item>
                                                <!-- <ion-input (click)="select.open()">
                                                    <app-searchable-select title="Subjects" formControlName="subjects" [data]="subjects" [existingData]="educator.subjects" [multiple]="true"
                                                    itemTextField="slug" #select></app-searchable-select>
                                                </ion-input> -->
                                                <ionic-selectable
                                                    formControlName="subjects"
                                                    [items]="subjectsLang"
                                                    itemValueField="_id"
                                                    itemTextField="lang"
                                                    [canSearch]="true"
                                                    [isMultiple]="false"
                                                    (onChange)="onChange($event)">
                                                </ionic-selectable>
                                            </ion-item>
                                        </form>
                                    </ion-content>
                                </ng-template>
                        </ion-modal>
                    </ion-item>
                </ion-list>
                <ion-button *ngIf="group.team_educators && group.team_educators.length > 0" id="open-modal" color="light" (click)="openAddEducatorsModal()" [disabled]="remainingTeacherInvites === 0" class="ion-margin-top ion-padding-start ion-padding-end ion-button-center">{{'invite_teachers_class' | translate}}</ion-button><br><br>
                <ion-list>
                    <ion-list-header>
                        <ion-label><h2><b>{{getTitleForPendingInvites(invites)}}</b></h2></ion-label>
                    </ion-list-header>
                    <ion-item *ngFor="let invite of invites">
                        <ion-label>
                            <h2>{{ invite.lastName }} {{ invite.name }}</h2>
                            <p>{{ invite.email }}</p>
                            <!--                            <p>{{ 'joined' | translate }} {{ educator.created_at | date:'medium' }}</p>-->
                        </ion-label>
                        <ion-icon item-end name="trash" class="remove-educator-btn" (click)='removeInvite(invite)'></ion-icon>
                    </ion-item>
                </ion-list>
                <ion-modal trigger="open-modal" [isOpen]="isModalOpen" (willDismiss)="onWillDismiss($event)">
                    <span>{{ 'add_educators_explanation' | translate }}.</span><br>
                    <form [formGroup]="educatorCreateForm">
                        <ng-template>
                            <ion-header>
                                <ion-toolbar>
                                    <ion-buttons slot="start">
                                        <ion-button (click)="cancel()">{{ 'close' | translate }}</ion-button>
                                    </ion-buttons>
                                    <ion-title>{{'invite_educator_class' | translate}}</ion-title>
                                    <ion-buttons slot="end">
                                        <ion-button (click)="confirm()" [disabled]="!this.educatorCreateForm.valid" [strong]="true">{{ 'invite' | translate }}</ion-button>
                                    </ion-buttons>
                                </ion-toolbar>
                            </ion-header>
                            <ion-content class="ion-padding">
                                <ion-item>
                                    <ion-label position="stacked">{{ 'educators_email' | translate }}</ion-label>
                                    <ion-input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" formControlName="email" required placeholder="educator@uugot.it"></ion-input>
                                </ion-item>
                                <ion-label *ngIf="educatorCreateForm.controls.email.errors?.['forbiddenEmail']" class="ion-error">
                                    {{'email_already_link' | translate}}
                                </ion-label>
                                <ion-item>
                                    <ion-label position="stacked">{{ 'educators_name' | translate }}</ion-label>
                                    <ion-input type="text" formControlName="name" required placeholder="John"></ion-input>
                                </ion-item>
                                <ion-item>
                                    <ion-label position="stacked">{{ 'educators_lastname' | translate }}</ion-label>
                                    <ion-input type="text" formControlName="lastname" required placeholder="Doe"></ion-input>
                                </ion-item>
                                <br><br>
                                <ion-item>
                                    <ion-label class="ion-educator-label">
                                        <h2>{{ 'educators_subjects' | translate }}</h2>
                                    </ion-label>
                                    <!-- <ion-select placeholder="Select subjects" [multiple]="true" formControlName="subjects" required>
                                        <ion-select-option [value]="subject" *ngFor="let subject of subjects">
                                            {{ subject.slug }}
                                        </ion-select-option>
                                    </ion-select> -->
                                    <ionic-selectable
                                        formControlName="subjects"
                                        [items]="subjectsLang"
                                        itemValueField="_id"
                                        itemTextField="lang"
                                        [canSearch]="true"
                                        [isMultiple]="false"
                                        (onChange)="onChange($event)">
                                    </ionic-selectable>
                                </ion-item>
                                <div *ngIf="addSuccess" color="success" class="ion-msg-div">
                                    <ion-label position="stacked" class="ion-msg-label" [innerHTML]="sanitizer.bypassSecurityTrustHtml(translate.instant('successful_educator_add', {addedEducatorName: addedEducatorName, addedEducatorEmail: addedEducatorEmail, remainingTeacherInvites: remainingTeacherInvites} ))"></ion-label>
                                </div>
                            </ion-content>
                        </ng-template>
                    </form>
                </ion-modal>
            </div>

            <div *ngSwitchCase="'statistics'">
                <ion-row>
                    <ion-col>
                        <ion-item>
                            <ion-label>
                                {{"start_date" | translate }}
                            </ion-label>
                            <ion-datetime-button datetime="start_date"></ion-datetime-button>
                            <ion-modal [keepContentsMounted]="true">
                                <ng-template>
                                    <ion-datetime
                                        [showDefaultButtons]="true"
                                        id="start_date"
                                        (ionChange)="getMostClickedWords()"
                                        [(ngModel)]="fromDate"
                                        presentation='date'>
                                    </ion-datetime>
                                </ng-template>
                            </ion-modal>
                            <ion-icon class="calendar ion-margin-start" item-right name="calendar"></ion-icon>
                        </ion-item>
                    </ion-col>
                    <ion-col>
                        <ion-item>
                            <ion-label>
                                {{"end_date" | translate }}
                            </ion-label>
                            <ion-datetime-button datetime="end_date"></ion-datetime-button>
                            <ion-modal [keepContentsMounted]="true">
                                <ng-template>
                                    <ion-datetime
                                        [showDefaultButtons]="true"
                                        id="end_date"
                                        (ionChange)="getMostClickedWords()"
                                        [(ngModel)]="toDate"
                                        presentation='date'>
                                    </ion-datetime>
                                </ng-template>
                            </ion-modal>
                        </ion-item>
                    </ion-col>
                </ion-row>
                <h4 class="ion-padding-start">{{ 'most_clicked_words' | translate }}</h4>
                <div padding *ngIf="!mostClickedWords || mostClickedWords.length == 0">
                    <div class="alert alert-info ion-margin-start ion-margin-end ion-margin-top" >
                        {{ 'no_words_clicked_in_timeframe' | translate }}
                    </div>
                </div>
                <div class="ion-padding-start">
                    <table class="table-most-clicked-words" *ngIf="mostClickedWords && mostClickedWords.length > 0">
                        <tr *ngFor="let word of mostClickedWords">
                            <td class="td-word">
                                {{ word.word }}
                            </td>
                            <td class="td-count">
                                {{ word.total }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </ion-grid>
</ion-content>
