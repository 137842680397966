<ion-app>
    <ion-split-pane contentId="main">
        <ion-menu contentId="main" *ngIf="appData.showMenu">
            <ion-header>
                <ion-toolbar [color]="isEducator() ? 'blue' : isStudent() ? 'green' : 'secondary'">
                    <ion-title>{{ "menu" | translate }}</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-content>
                <ion-list>
                    <div class="menu-logo-url-container">
                        <!-- *ngIf="platform.is('android') && (platform.is('tablet') || platform.is('phablet'))" -->
                        <img src="assets/img/logo_app_uugotit_url_balken_2019_yellow.png" alt="uugot.it Logo - www.uugot.it"/>
                    </div>

                    <!-- ### START Classes ### -->
                    <ng-container *ngIf="isStudent() && appData.groupsForUser">
                        <ion-menu-toggle auto-hide="false" *ngFor="let g of appData.groupsForUser">
                            <ion-item (click)="openStudentGroup(g)" class="ion-no-padding menu-main"
                                [class.activeMenuHighlight]="g._id === appData.activeGroupId">
                                <ion-label class="menu-text">
                                    {{ ('lang_'+g.base_language | translate) + ' - ' + g.name }}
                                </ion-label>
                                <ion-badge *ngIf="getUnwatchedVideosCount(g) as count" slot="end" color="medium">
                                    {{count}}
                                </ion-badge>
                            </ion-item>
                            <!-- <span *ngIf="g._id === appData.activeGroupId">
                                <ion-item class="ion-no-padding" (click)="openClassWordCatalog(g)"
                                    [class.activeMenuHighlight]="appData.activePage === 'word-catalog/' + g._id">
                                    <ion-label class="sub-text">
                                        {{ 'word_catalog' | translate }}
                                    </ion-label>
                                </ion-item>
                                <ion-item class="ion-no-padding" (click)="openClassFlashCards(g)"
                                    [class.activeMenuHighlight]="appData.activePage === 'flash-cards/' + g._id">
                                    <ion-label class="sub-text">
                                        {{ 'flash_cards' | translate }}
                                    </ion-label>
                                </ion-item>
                            </span> -->
                        </ion-menu-toggle>
                    </ng-container>
                    <!-- ### END Classes ### -->

                    <!-- ### START General Pages ### -->
                    <ion-menu-toggle auto-hide="false" *ngFor="let p of getPages(false)">
                        <ng-container *ngIf="isRoleAllowed(p.roles)">
                            <ion-item  class="ion-no-padding {{'menuItem-' + p.title}}" (click)="openPage(p, $event)" [class.menu-main]="p.isMain"
                                [class.menu-disabled]="p.isDisabled"
                                [class.activeMenuHighlight]="isActivePage(p.segment)"
                                *ngIf="isRoleAllowed(p.roles)">
                                <ion-label [ngClass]="[p.isMain ? 'menu-text' : 'sub-text']">
                                    <span *ngIf="p.title === 'video_catalog_kids'; else notKids">
                                      <span  class="menu-item-kids">
                                        <span class="kids-letter-1">K</span>
                                        <span class="kids-letter-2">i</span>
                                        <span class="kids-letter-3">D</span>
                                        <span class="kids-letter-4">S</span>
                                      </span>
                                        {{p.title | translate}}
                                    </span>
                                    <ng-template #notKids>
                                        {{p.title | translate}}
                                    </ng-template>
                                </ion-label>
                                <ion-badge *ngIf="p.badge" color="primary" slot="end">{{ p.badge | translate }}</ion-badge>
                                <ion-icon *ngIf="p.subItems && p.subItems.length > 0" slot="end"
                                    [name]="'chevron-forward'" (click)="toggleSubMenu(p.title, $event); $event.stopPropagation();"
                                    class="submenuOpenCloseIndicator_{{p.title}}" ioniconRemoveTitle iconTitle="{{'toggle_submenu' | translate}}"></ion-icon>
                            </ion-item>
                            <!-- Sub menu items: -->
                            <div *ngIf="p.subItems && p.subItems.length > 0" class="submenu-container submenuContainer_{{p.title}}">
                                <ng-container *ngFor="let sub of p.subItems">
                                    <ion-item *ngIf="isRoleAllowed(sub.roles)" class="ion-no-padding"
                                        (click)="openPage(sub, $event)"
                                        [class.activeMenuHighlight]="isActivePage(sub.segment)"
                                        id="submenuItem_{{sub.title}}">
                                        <ion-label [ngClass]="['sub-text']">
                                            <span *ngIf="sub.segment.endsWith('category/kids'); else notKids" class="menu-item-kids">
                                                <span class="kids-letter-1">K</span>
                                                <span class="kids-letter-2">i</span>
                                                <span class="kids-letter-3">D</span>
                                                <span class="kids-letter-4">S</span>
                                            </span>
                                            <ng-template #notKids>
                                                {{sub.title | translate}}
                                            </ng-template>
                                        </ion-label>
                                    </ion-item>
                                </ng-container>
                            </div>
                        </ng-container>
                    </ion-menu-toggle>
                    <!-- ### END General Pages ### -->

                    <!-- ### START Speech Bubble ### -->
                    <ng-container *ngIf="!isLoggedIn()">
                        <ion-item class="ion-no-padding menu-main" style="text-align: center;">
                            <ion-label class="menu-text">
                                <a href="https://www.uugot.it/product-scooling-de.html" target="_blank"><img src="assets/img/sprechblase_unterricht_sags_lehrer.png"
                                        alt="Jetzt auch für den Unterricht: uugot.it sCOOLing"
                                        title="Jetzt auch für den Unterricht: uugot.it sCOOLing. Sag's deinem Lehrer!"
                                        style="max-width: 153px; margin-top: 4px;"/></a>
                            </ion-label>
                        </ion-item>

                    </ng-container>
                    <!-- ### END Speech Bubble ### -->

                    <!-- ### START Student - ADD CLASS ### -->
                    <ng-container *ngIf="isStudent()">
                        <ion-menu-toggle auto-hide="false">
                            <ion-item (click)="openStudentLoginPage()" class="ion-no-padding menu-main"
                                [class.activeMenuHighlight]="appData.activePage === 'student'">
                                <ion-label class="menu-text">
                                    <ng-container *ngIf="isLoggedIn(); else studentNotLoggedIn">
                                        <ion-icon slot="start" name="add"></ion-icon>
                                        {{ "add_class" | translate }}
                                    </ng-container>
                                    <ng-template #studentNotLoggedIn>
                                        {{ "menu_login_student" | translate }}
                                    </ng-template>
                                </ion-label>
                            </ion-item>
                        </ion-menu-toggle>
                    </ng-container>
                    <!-- ### END Student Login ### -->

                    <!-- ### START Educator Create Class Page ### -->
                    <ion-menu-toggle auto-hide="false" *ngIf="isEducator()">
                        <ion-item (click)="openClassCreatePage()" class="ion-no-padding menu-main"
                            [class.activeMenuHighlight]="appData.activePage === 'educator-class/new'">
                            <ion-label class="menu-text">
                                <ion-icon slot="start" name="add"></ion-icon>
                                {{ 'new_class' | translate }}
                            </ion-label>
                        </ion-item>
                    </ion-menu-toggle>
                    <!-- ### END Educator Create Class Page ### -->

                    <!-- ### START Educator Groups & Templates ### -->
                    <ng-container *ngIf="isEducator() && appData.groupsForUser">
                        <ion-menu-toggle auto-hide="false" *ngFor="let g of appData.groupsForUser">
                            <ion-item (click)="openEducatorGroup(g)" class="ion-no-padding menu-main"
                                [class.activeMenuHighlight]="g._id === appData.activeGroupId">
                                <ion-label class="menu-text">
                                    {{ g.name }} <span class="menu-base-language">{{ g.base_language }}</span>
                                </ion-label>
                            </ion-item>
                            <!-- <span *ngIf="g._id === appData.activeGroupId">
                                <ion-item class="ion-no-padding" (click)="openClassWordCatalog(g)"
                                    [class.activeMenuHighlight]="appData.activePage === 'word-catalog/' + g._id">
                                    <ion-label class="sub-text">
                                        {{ 'word_catalog' | translate }}
                                    </ion-label>
                                </ion-item>
                            </span> -->
                        </ion-menu-toggle>
                    </ng-container>
                    <ng-container *ngIf="isEducator() && appData.groupTemplatesForUser && appData.groupTemplatesForUser.length > 0">
                        <ion-item class="ion-no-padding menu-main menu-disabled">
                            <ion-label class="menu-text">
                                {{ "sample_curriculum" | translate }}
                            </ion-label>
                        </ion-item>
                        <ion-menu-toggle auto-hide="false" *ngFor="let g of appData.groupTemplatesForUser">
                            <ion-item (click)="openEducatorGroup(g)" class="ion-no-padding sub-text"
                                [class.activeMenuHighlight]="g._id === appData.activeGroupId">
                                <ion-label class="sub-text">
                                    {{ g.name }} <span class="menu-base-language">{{ g.base_language }}</span>
                                </ion-label>
                            </ion-item>
                        </ion-menu-toggle>
                    </ng-container>
                    <!-- END Educator Groups & Templates ### -->

                    <!-- START Bottom Pages (Login & Account) ### -->
                    <ion-menu-toggle auto-hide="false" *ngFor="let p of getPages(true)">
                        <ng-container *ngIf="isRoleAllowed(p.roles)">
                            <ion-item class="ion-no-padding {{'menuItem-' + p.title}}" (click)="openPage(p, $event)" [class.menu-main]="p.isMain"
                                [class.menu-disabled]="p.isDisabled"
                                [class.activeMenuHighlight]="isActivePage(p.segment)"
                                *ngIf="isRoleAllowed(p.roles)">
                                <ion-label [ngClass]="[p.isMain ? 'menu-text' : 'sub-text']">
                                    {{p.title | translate}}
                                </ion-label>
                                <ion-badge *ngIf="p.badge" color="primary" slot="end">{{ p.badge | translate }}</ion-badge>
                                <ion-icon *ngIf="p.subItems && p.subItems.length > 0" slot="end"
                                    [name]="'chevron-forward'" (click)="toggleSubMenu(p.title, $event); $event.stopPropagation();"
                                    class="submenuOpenCloseIndicator_{{p.title}}" ioniconRemoveTitle iconTitle="{{'toggle_submenu' | translate}}"></ion-icon>
                            </ion-item>
                            <!-- Sub menu items: -->
                            <div *ngIf="p.subItems && p.subItems.length > 0" class="submenu-container submenuContainer_{{p.title}}">
                                <ng-container *ngFor="let sub of p.subItems">
                                    <ion-item *ngIf="isRoleAllowed(sub.roles)" class="ion-no-padding"
                                        (click)="openPage(sub, $event)"
                                        [class.activeMenuHighlight]="isActivePage(sub.segment)"
                                        id="submenuItem_{{sub.title}}">
                                        <ion-label [ngClass]="['sub-text']">
                                            {{sub.title | translate}}
                                        </ion-label>
                                    </ion-item>
                                </ng-container>
                            </div>
                        </ng-container>
                    </ion-menu-toggle>
                    <!-- END Bottom Pages (Login & Account) ### -->
                </ion-list>
            </ion-content>
        </ion-menu>
        <ion-router-outlet id="main"></ion-router-outlet>
    </ion-split-pane>

    <!-- "Start with sCOOLing" floating button -->
    <div class="bottomright-floating-button-container" *ngIf='showStartScoolingButton'>
        <ion-button class="bottomright-floating-button" size="default" color="secondary"
            (click)="bottomFloatingButtonClicked()" *ngIf="isBottomFloatingButtonShown()"
            [innerHTML]="bottomFloatingButtonText()"></ion-button><!-- [routerLink]="['/help']" -->

        <!-- We need a background for the "close" icon so the background doesn't shine through -->
        <div class="floating-close-button-background" *ngIf="isBottomFloatingButtonCloseShown()"></div>
        <ion-icon class="floating-close-button" name="close-circle" *ngIf="isBottomFloatingButtonCloseShown()"
            (click)="bottomFloatingButtonCloseClicked()" ioniconRemoveTitle [iconTitle]="'hide_button' | translate"></ion-icon>
    </div>

</ion-app>
