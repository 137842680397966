// angular
import { Injectable } from '@angular/core';

// ionic
// import { GoogleAnalytics } from 'ionic-native';

// libraries
// import 'rxjs/add/operator/map';
import { AppData } from './app-data.service';
import { Utils } from '../utils/utils';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../app.constants';
import { Platform } from '@ionic/angular';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../environments/environment';
import { NGXLogger } from 'ngx-logger';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Device } from '@capacitor/device';
import * as mixpanelBrowser from 'mixpanel-browser';
import { Mixpanel } from '@houseninja/capacitor-mixpanel';

declare const ga: Function; // Google Analytics is integrated in the index.html page
declare const gtag: any; // Google Analytics (Firebase) is integrated in the index.html page

// gtag info: https://stackoverflow.com/a/55222168/769726

/**
 * See also https://devdactic.com/firebase-analytics-ionic/
 */
@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    analyticsEnabled = true;

    constructor(public http: HttpClient,
        public constants: Constants,
        public appData: AppData,
        private platform: Platform,
        private router: Router,
        private logger: NGXLogger) {
        logger.info('Initializing AnalyticsService Provider');
    }

    /**
     * Initialize the "gtag" script for Google Analyics (this is used for the web version)
     * See https://developers.google.com/analytics/devguides/collection/gtagjs/
     */
    public init() {
        this.logger.info('AnalyticsService init');
        this.initFb();
        this.listenForRouteChanges();
        this.initGtagScript();
        this.initMixpanelNative();
        this.initMixpanelBrowser();
    }

    initGtagScript() {
        try {
            // Load the gtag script only if googleAnalyticsKey is set
            if (environment.googleAnalyticsKey) {
                const script1 = document.createElement('script');
                script1.async = true;
                script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsKey;
                document.head.appendChild(script1);

                const script2 = document.createElement('script');
                script2.innerHTML = `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '` + environment.googleAnalyticsKey + `', {'anonymize_ip': true});
                gtag('config', '` + environment.googleAnalyticsKey + `', {'send_page_view': false});
                `;
                document.head.appendChild(script2);
            } else {
                this.logger.debug('AnalyticsService googleAnalyticsKey is not set - not loading gtag script');
            }
        } catch (ex) {
            this.logger.error('Error appending google analytics');
            this.logger.error(ex);
        }
    }

    /**
     * Initialize Firebase analytics
     */
    async initFb() {
        if ((await Device.getInfo()).platform == 'web') {
            this.logger.debug('AnalyticsService - device platform is web');
            // Currently disabled because we use "gtag" for the web
            // FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
        }
    }

    initMixpanelNative() {
        if (!this.useMixpanelNative) {
            return;
        }
        if (environment.mixpanelProjectToken) {
            // this.logger.info('Mixpanel analytics (native) - initializing with token');
            // this.mixpanel
            //     .init(environment.mixpanelProjectToken)
            //     .then((value) => {
            //         this.logger.info(`Mixpanel init success: ${value}`);
            //     })
            //     .catch((reason) => {
            //         this.logger.warn(`Mixpanel init error: ${reason}`);
            //     });
            this.logger.info('Mixpanel analytics (native) - no initializing necessary');
        } else {
            this.logger.info('Mixpanel analytics - not initializing because of missing token');
        }
    }

    initMixpanelBrowser() {
        if (!this.useMixpanelBrowser || environment.environmentName === 'DEV') {
            return;
        }
        if (environment.mixpanelProjectToken) {
            this.logger.info('Mixpanel analytics (browser) - initializing with token');
            mixpanelBrowser.init(environment.mixpanelProjectToken, {
                debug: environment.environmentName === 'DEV',
                api_host: 'https://api-eu.mixpanel.com',
            });
        } else {
            this.logger.info('Mixpanel analytics - not initializing because of missing token');
        }
    }


    get useFirebaseAnalytics(): boolean {
        // return this.platform.is('capacitor');
        // Disabled due to GDPR issues:
        return false;
    }

    get useMixpanelNative(): boolean {
        return this.platform.is('capacitor');
    }

    get useMixpanelBrowser(): boolean {
        return !this.useMixpanelNative;
    }

    /**
     * Listen for route changes to track page views - trackPageView() is now deprecated.
     */
    private async listenForRouteChanges() {
        await new Promise(resolve => setTimeout(resolve, 1300));
        this.router.events.subscribe(event => {
            if (environment.environmentName === 'DEV') {
                return;
            }
            if (event instanceof NavigationEnd) {

                const path = event.urlAfterRedirects;
                const location = window.location ? window.location.href : null;
                // const firstPartOfPath = path.split('/')[0];
                if (this.useMixpanelNative) {
                    Mixpanel.track(
                        {
                            event: 'page_view',
                            properties: {
                                'page_path': path,
                                'page_location': location
                            }
                        })
                        .then((res: any) => console.log('Mixpanel analytics (native) - page_view success:', res))
                        .catch((error: any) => console.error('Mixpanel analytics (native) page_view error:', error));
                }
                if (this.useMixpanelBrowser) {
                    mixpanelBrowser.track('page_view', {
                        'page_path': path,
                        'page_location': location
                    });
                }
                if (this.useFirebaseAnalytics) {
                    // For Ionic Capacitor app, use Firebase:
                    this.logger.info('### TRACKING PAGE VIEW (FIREBASE CAP.)', path, location);
                    FirebaseAnalytics.logEvent({
                        name: 'page_view',
                        params: {
                            'page_path': path,
                            'page_location': location
                        }
                    })
                        .then((res: any) => console.log('Firebase Analytics - page_view success:', res))
                        .catch((error: any) => console.error('Firebase analytics page_view error:', error));
                } else {
                    this.logger.info('Firebase Analytics not available', path /*window.location.pathname*/);
                    // For web, use gtag:
                    // gtag('config', environment.googleAnalyticsKey, {
                    //     'page_path': path,
                    //     'page_location': location
                    // });
                    if (typeof gtag !== 'undefined') {
                        gtag('event', 'page_view', {
                            'page_path': path,
                            'page_location': location
                        });
                        this.logger.info('### TRACKING PAGE VIEW (GOOGLE ANALYTICS)', path, location);
                    } else {
                        this.logger.info('### GTAG NOT AVAILABLE');
                    }

                    // gtag('event', 'screen_view', {
                    //     'screen_name' : screenName
                    // });
                    // console.log('Sending Google Analytics hit for screen', screenName);

                    this.logger.info('Property ID', environment.googleAnalyticsKey);
                }
            }
        });
    }

    async setAnalyticsUserProperties(
        userId: string,
        languageLevelDE?: string,
        languageLevelEN?: string,
        properties?: { role?: string; customer_id?: string; profile_city?: string; school_name?: string; }
    ) {
        await new Promise(resolve => setTimeout(resolve, 1300));
        this.logger.info('Setting userId to ' + userId + ', custom dimension 1 to ' + languageLevelDE + ', dimension 2 to ' + languageLevelEN);

        if (this.useMixpanelNative) {
            Mixpanel.identify({ distinctId: userId })
                .then((res: any) => this.logger.debug('Mixpanel analytics (native) identify - success:', res))
                .catch((error: any) => this.logger.warn('Mixpanel analytics (native) identify - error:', error));
            if (properties) {
                Mixpanel.setProfile({ properties });
            }
        }

        if (environment.mixpanelProjectToken) {
            if (this.useMixpanelBrowser) {
                mixpanelBrowser.identify(userId);
                this.logger.debug('Mixpanel analytics (browser) identify', userId);
                if (properties) {
                    mixpanelBrowser.people.set(properties);
                }
            }
        }
        // For Ionic Capacitor app, use Firebase:
        if (this.useFirebaseAnalytics) {
            FirebaseAnalytics.setUserId({
                userId,
            });
        } else {
            if (typeof gtag !== 'undefined') {
                // For web, use gtag:
                gtag('config', environment.googleAnalyticsKey, {
                    'user_id': userId
                });
            } else {
                this.logger.info('### GTAG NOT AVAILABLE');
            }
        }

        // Deprecated - old Google Analytics code:
        const tracker = this.getGoogleAnalyticsTrackerName();
        if (!tracker) {
            this.logger.info('Not setting user properties because tracker is not defined');
            return;
        }
        if (typeof ga === 'undefined') {
            this.logger.info('Not setting user properties because ga is undefined');
            return;
        }

        this.logger.info('Using Google Analytics web tracking');
        ga(tracker + '.set', 'userId', userId);
        if (languageLevelDE && languageLevelEN) {
            ga(tracker + '.set', {
                'dimension1': languageLevelDE,
                'dimension2': languageLevelEN
            });
        }
    }

    /**
     * See <https://help.mixpanel.com/hc/en-us/articles/115004497803-Identity-Management-Best-Practices#multiple-users-on-single-devices-identify-on-login-reset-on-logout>
     * and https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelreset
     */
    resetAnalyticsUserProperties() {
        this.logger.info('Resetting analytics user properties (logout)');

        if (this.useMixpanelNative) {
            Mixpanel.reset()
                .then((res: any) => this.logger.debug('Mixpanel analytics (native) reset - success:', res))
                .catch((error: any) => this.logger.debug('Mixpanel analytics (native) reset - error:', error));
        }

        if (this.useMixpanelBrowser &&  environment.environmentName !== 'DEV') {
            mixpanelBrowser.reset();
        }
    }

    /**
     * This method is deprecated because it uses toe old google analytics code.
     * See listenForRouteChanges() for the new way to do it.
     */
    async trackPageView(pageName: string) {
        await new Promise(resolve => setTimeout(resolve, 1300));
        // Deprecated - old Google Analytics code:
        const tracker = this.getGoogleAnalyticsTrackerName();
        if (!tracker) {
            this.logger.info(`Not tracking page '${pageName}' because userId is not set`);
            return;
        }
        if (typeof ga === 'undefined') {
            this.logger.info(`Not tracking page '${pageName}' because ga is undefined`);
            return;
        }
        this.logger.info('GA trackPageView ' + pageName);
        // ga('uugotitweb.send', 'pageview', pageName);
        ga(tracker + '.set', 'page', pageName);
        ga(tracker + '.send', 'pageview');
    }

    async trackAnalyticsEvent(category: AnalyticsCategory, action: string, label?: string, value?: number, newSession?: boolean) {
        await new Promise(resolve => setTimeout(resolve, 1300));
        if (environment.environmentName === 'DEV') {
            return;
        }

        // gtag('event', eventName, params);
        const params: any = {};
        if (category) {
            params.event_category = category;
        }
        if (label) {
            params.event_label = label;
        }
        if (value) {
            params.value = value;
        }

        if (this.useMixpanelNative) {
            this.logger.info('### TRACKING EVENT (MIXPANEL NATIVE)', action);
            Mixpanel.track({ event: action, properties: params })
                .then((res: any) => this.logger.debug('Mixpanel analytics (native) - event success:', res))
                .catch((error: any) => this.logger.warn('Mixpanel analytics (native) event error:', error));
        }
        if (this.useMixpanelBrowser) {
            mixpanelBrowser.track(action, params);
        }

        if (this.useFirebaseAnalytics) {
            // For Ionic Cordova app, use Firebase:
            this.logger.info('### TRACKING EVENT (FIREBASE CAP.)', action);
            FirebaseAnalytics.logEvent({
                name: action,
                params,
            })
                .then((res: any) => this.logger.info('Firebase Analytics (Cap) - event success:', res))
                .catch((error: any) => this.logger.warn('Firebase analytics (Cap) event error:', error));
        } else {
            // For web, use gtag:
            if (typeof gtag !== 'undefined') {
                gtag('event', action, params);
                this.logger.info('### TRACKING EVENT (GOOGLE ANALYTICS)', action);
                this.logger.info('Property ID', environment.googleAnalyticsKey);
            } else {
                this.logger.info('### GTAG NOT AVAILABLE');
            }
        }

        // Deprecated - old Google Analytics code:
        const tracker = this.getGoogleAnalyticsTrackerName();
        if (!tracker) {
            this.logger.info('Not tracking ' + category + ' - ' + action + ' because userId is not set');
            return;
        }
        if (typeof ga === 'undefined') {
            this.logger.info('Not tracking ' + category + ' - ' + action + ' because ga is undefined');
            return;
        }

        this.logger.info('GA trackEvent: ' + category + ' - ' + action + (label ? ` - ${label}` : '') + (value ? ` - ${value}` : ''));
        ga(tracker + '.send', 'event', category, action, label, value);

    }

    /**
     * Tracker names are set up in the index.html code
     */
    getGoogleAnalyticsTrackerName(): string {
        const email = this.appData.getPreferenceString(this.constants.pref.USER_EMAIL);
        if (!email) {
            return 'external';
        }
        return Utils.isInternalEmailAdress(email) ? 'internal' : 'external';
    }

    toggleAnalytics() {
        this.analyticsEnabled = !this.analyticsEnabled;
        if (this.useFirebaseAnalytics) {
            FirebaseAnalytics.setCollectionEnabled({
                enabled: this.analyticsEnabled,
            });
        }
    }

}

/** Category for tracking events with Google Analytics */
export enum AnalyticsCategory {
    Video = 'Video',
    VideoCatalog = 'VideoCatalog',
    VideoCatalogTag = 'VideoCatalogTag',
    RecentlyWatchedVideos = 'RecentlyWatchedVideos',
    FavoredVideos = 'FavoredVideos',
    WordCatalog = 'WordCatalog',
    FlashCards = 'FlashCards',
    SubjectsCatalog = 'SubjectsCatalog',
    Class = 'Class',
    Misc = 'Misc',
    HelpSection = 'HelpSection',
}
