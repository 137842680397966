import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { NavController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/app.constants';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from 'src/app/services/app-data.service';
import { AppManager } from 'src/app/services/app-manager.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
    ValidatorFn, AbstractControl, ValidationErrors
} from '@angular/forms';
import { EducatorsApi } from '../../services/api/educators-api';
import { NGXLogger } from 'ngx-logger';
import { IUser } from '../../interfaces/IUser';
import { Keyboard } from '@capacitor/keyboard';
import { IUpdatePassword} from '../../interfaces/IUpdatePassword';
import { UsersApi } from '../../services/api/users-api.service';
import { CustomerApi, IAdminInvite } from '../../services/api/customers-api.service';
import { ISingleObjectResponse } from '../../models/dtos';


@Component({
    selector: 'app-edit-password-profile-page',
    templateUrl: 'invite-admin-page.html',
    styleUrls: ['invite-admin-page.scss']
})
export class InviteAdminPage implements OnInit {

    public inviteAdminForm: UntypedFormGroup;
    @Input() public callOnDismiss?: () => void;
    public dismissPageEnabled;
    public user: IUser;
    submitting = false;

    constructor(public uiUtils: UiUtils,
        public translate: TranslateService,
        public appData: AppData,
        public appManager: AppManager,
        public constants: Constants,
        public navCtrl: NavController,
        private titleService: Title,
        private modalCtrl: ModalController,
        private formBuilder: UntypedFormBuilder,
        private educatorsApi: EducatorsApi,
        private customerApi: CustomerApi,
        private usersApi: UsersApi,
        private logger: NGXLogger) {
        // Do nothing.

    }
    passwordMatchingValidator(password: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control || !control.parent) {
                return null;
            }
            return control.parent.get(password).value === control.value ? null : { mismatch: true };
        };
    }

    ngOnInit() {
        this.titleService.setTitle('Edit Profile | uugot.it');

        this.user = this.appData.authenticatedUser;
        this.logger.debug('Got user', this.user);

        this.inviteAdminForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.email])],
            firstName: [
                '', Validators.compose([Validators.required,
                    Validators.maxLength(10),
                ])
            ],
            lastName: [
                '', Validators.compose([Validators.required,
                    Validators.maxLength(14),
                ])
            ],
        });



    }
    async onSubmitForm() {
        const formData: IAdminInvite = {
            name: this.inviteAdminForm.value.firstName,
            last_name: this.inviteAdminForm.value.lastName,
            email: this.inviteAdminForm.value.email,
        };


        this.submitting = true;
        console.log('entro')
        try {
            const res = await this.customerApi.inviteAdmin(formData).toPromise();

            if (res.success) {
                console.log('respoonse , ', res)
                this.uiUtils.displayToast("User successfully invited via email.", null, 3000, 'success');
                this.inviteAdminForm.reset();
                this.dismiss();
                this.submitting = false;
            } else {
                console.log('respoonse , ', res)
                const msg = res.msg;
                if (msg === 'EDUCATOR_INVITE_EMPTY_EMAIL') {
                    this.uiUtils.displayToast("Empty email address.", null, 3000, 'danger');
                } else if (msg === 'EDUCATOR_INVITE_MISSING_INFO') {
                    this.uiUtils.displayToast("Missing information. Please provide the email, name, and last name of the invitee.", null, 3000, 'danger');
                } else if (msg === 'EDUCATOR_PERMISSION_ERROR') {
                    this.uiUtils.displayToast("User does not have the necessary permissions to add an educator to the group.", null, 3000, 'danger');
                } else if (msg === 'EDUCATOR_INVITE_MAX_REACHED') {
                    this.uiUtils.displayToast("Maximum number of invitations reached.", null, 3000, 'danger');
                } else if (msg === 'EDUCATOR_INVITE_ALREADY_REGISTERED') {
                    this.uiUtils.displayToast("This email address is already invited.", null, 3000, 'danger');
                } else {
                    this.uiUtils.displayToast("There is an error. Please try again later or request the link again.", null, 3000, 'danger');
                }
                this.inviteAdminForm.reset();
                this.submitting = false;
            }
        } catch (err) {
            this.uiUtils.displayToast("There is an error. Please try again later or request the link again.", null, 3000, 'danger');
            this.inviteAdminForm.reset();
            this.submitting = false;
        }
    }



    dismiss() {
        this.modalCtrl.dismiss({
            dismissed: true,
        }).then(() => {
            if (this.callOnDismiss) {
                this.callOnDismiss();
            }
        });
    }

    hideKeyboard() {
        this.logger.debug('hideKeyboard');
        Keyboard.hide();
    }

    submitButtonDisabled() {
        if (this.inviteAdminForm.pristine || !this.inviteAdminForm.valid || this.submitting) {
            return true;
        }
    }
}
