import { Component, OnInit, Input, ViewEncapsulation, ViewChild } from '@angular/core';
import { IVideo } from '../../interfaces/IVideo';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from '../../services/app-data.service';
import { Constants } from 'src/app/app.constants';
import {
    ISettingsListener,
    ISettingsMainItem,
    ISettingsChangeListener,
    ISettingsConfiguration,
} from './video-player-settings.interfaces';
import { VideoPlayerSettingsPreviewAnimation } from '../video-player-settings-preview-animation/video-player-settings-preview-animation.component';

/**
 * Component that shows the video player settings (overlay).
 */
@Component({
    selector: 'video-player-settings',
    templateUrl: './video-player-settings.component.html',
    styleUrls: ['./video-player-settings.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerSettings implements OnInit {
    @Input() video: IVideo; // the video

    @Input() listener: ISettingsListener & ISettingsChangeListener;

    isOptionsScrollUpVisible = false;
    isOptionsScrollDownVisible = false;

    @ViewChild(VideoPlayerSettingsPreviewAnimation)
    previewAnimation: VideoPlayerSettingsPreviewAnimation;

    configuration: ISettingsConfiguration;

    mainItems: ISettingsMainItem[] = [
        {
            id: 'language',
            title: 'language',
            prefKey: this.constants.pref.LANGUAGE,
        },
        {
            id: 'subtitle_font_size',
            title: 'subtitle_font_size',
            prefKey: this.constants.pref.SUBTITLE_FONT_SIZE,
        },
        { id: 'video_quality', title: 'video_quality', prefKey: this.constants.pref.VIDEO_QUALITY },
        {
            id: 'video_click_delay',
            title: 'video_click_delay',
            prefKey: this.constants.pref.VIDEO_CLICK_DELAY,
        },
        {
            id: 'video_overlay_intro',
            title: 'video_overlay_intro',
        },
    ];

    constructor(
        private logger: NGXLogger,
        public translate: TranslateService,
        public uiUtils: UiUtils,
        public appData: AppData,
        public constants: Constants
    ) {
        // empty
        this.logger.debug('VideoPlayerSettings constructor');
    }

    ngOnInit() {
        this.logger.debug('VideoPlayerSettings.ngOnInit()');

        this.getMainItemById('language').options = this.listener
            .availableTranslationLanguages(this.video)
            .map((lang) => {
                return {
                    id: lang.code,
                    title: lang['displayName'],
                };
            });

        this.getMainItemById('video_quality').options = [
            {
                id: 'low',
                title: 'quality_1',
            },
            {
                id: 'medium',
                title: 'quality_2',
            },
            {
                id: 'high',
                title: 'quality_3',
            },
            {
                id: 'veryHigh',
                title: 'quality_4',
            },
        ];

        this.getMainItemById('subtitle_font_size').options = [
            {
                id: 'small',
                title: 'small',
            },
            {
                id: 'medium',
                title: 'medium',
            },
            {
                id: 'large',
                title: 'large',
            },
        ];

        this.getMainItemById('video_click_delay').options = [
            {
                id: '0',
                title: 'no_delay',
            },
            {
                id: '1',
                title: '1_sec',
            },
            {
                id: '2',
                title: '2_sec',
            },
            {
                id: '3',
                title: '3_sec',
            },
            {
                id: '9999',
                title: 'infinite',
            },
        ];
    }

    onShow(selectMainItemId?: ISettingsMainItem['id'], configuration?: ISettingsConfiguration) {
        this.getMainItemById('language').options.forEach((option) => {
            option.selected = option.id === this.listener.getSelectedLanguage();
        });

        this.getMainItemById('video_quality').options.forEach((option) => {
            option.selected =
                option.id ===
                this.appData.getPreferenceString(
                    this.constants.pref.VIDEO_QUALITY,
                    this.constants.DefaultVideoQuality
                );
        });

        this.getMainItemById('subtitle_font_size').options.forEach((option) => {
            option.selected =
                option.id ===
                this.appData.getPreferenceString(
                    this.constants.pref.SUBTITLE_FONT_SIZE,
                    this.constants.DefaultSubtitleFontSize
                );
        });

        this.getMainItemById('video_click_delay').options.forEach((option) => {
            option.selected =
                option.id ===
                this.appData.getPreferenceString(
                    this.constants.pref.VIDEO_CLICK_DELAY,
                    this.constants.DefaultVideoClickDelay
                );
        });

        if (selectMainItemId) {
            this.selectMainItem(selectMainItemId);
        }
        this.configuration = configuration;
        this.updateScrollArrowVisibility();
    }

    close() {
        this.previewAnimation.hide();
        this.listener.onCloseSettingsOverlay(this.configuration);
        this.mainItems.forEach((item) => {
            item.selected = false;
        });
    }

    selectMainItem(id: ISettingsMainItem['id']) {
        const handled = this.listener.onMainMenuItemSelected(id);
        if (handled) {
            return;
        }
        this.mainItems.forEach((item) => {
            item.selected = item.id === id;
        });
        if (this.getSelectedMainItem().options.length <= 4) {
            this.isOptionsScrollUpVisible = false;
            this.isOptionsScrollDownVisible = false;
        }
        if (id === 'language' || id === 'subtitle_font_size') {
            this.previewAnimation.show(true);
        } else {
            this.previewAnimation.hide();
        }
        this.updateScrollArrowVisibility();
    }

    updateScrollArrowVisibility() {
        setTimeout(() => {
            this.isOptionsScrollUpVisible = this.calcOptionsScrollUpVisible();
            this.isOptionsScrollDownVisible = this.calcOptionsScrollDownVisible();
            // this.logger.debug(
            //     `settingChanged isOptionsScrollUpVisible=${this.isOptionsScrollUpVisible}, isOptionsScrollDownVisible=${this.isOptionsScrollDownVisible}`
            // );
        }, 0);
    }

    getSelectedMainItem() {
        return this.mainItems.find((item) => item.selected);
    }

    getMainItemById(id: ISettingsMainItem['id']): ISettingsMainItem {
        return this.mainItems.find((item) => item.id === id);
    }

    settingOptionClicked(mainItem: ISettingsMainItem, value: string) {
        if (mainItem.options.find((option) => option.id === value && option.selected)) {
            // The item is already selected
            return;
        }
        this.logger.debug('settingChanged', mainItem.id, value);
        if (mainItem.id !== 'language') {
            if (mainItem.prefKey) {
                this.appData.savePreferenceString(mainItem.prefKey, value);
            }
        }
        this.listener?.onSettingChanged(mainItem.id, value);
        this.previewAnimation?.onSettingChanged(mainItem.id, value);
        mainItem.options.forEach((option) => {
            option.selected = option.id === value;
        });
    }

    scrollOptionsUp() {
        const containerEl = document.getElementById('scrollable-container-right');
        const scrollAmountPx = containerEl.clientHeight / 3;
        // this.logger.debug('scrollOptionsUp by', scrollAmountPx);
        containerEl.scrollBy({
            top: -scrollAmountPx,
            left: 0,
        });
    }

    scrollOptionsDown() {
        const containerEl = document.getElementById('scrollable-container-right');
        const scrollAmountPx = containerEl.clientHeight / 3;
        // this.logger.debug('scrollOptionsUp by', scrollAmountPx);
        containerEl.scrollBy({
            top: scrollAmountPx,
            left: 0,
        });
    }

    calcOptionsScrollUpVisible(): boolean {
        const containerEl = document.getElementById('scrollable-container-right');
        if (!containerEl) {
            return false;
        }
        const scrollTop = containerEl.scrollTop;
        // this.logger.debug('scrollTop', scrollTop);
        return scrollTop > 0;
    }

    calcOptionsScrollDownVisible(): boolean {
        const containerEl = document.getElementById('scrollable-container-right');
        if (!containerEl) {
            return false;
        }
        const scrollBottom =
            containerEl.scrollHeight - Math.abs(containerEl.scrollTop) - containerEl.clientHeight;
        // this.logger.debug(
        //     `scrollHeight=${containerEl.scrollHeight}, scrollTop=${containerEl.scrollTop}, clientHeight=${containerEl.clientHeight}, scrollLeft=${scrollBottom}`
        // );
        return scrollBottom > 0;
    }

    onContainerScroll($event: any) {
        // this.logger.debug('onContainerScroll()', $event);
        this.updateScrollArrowVisibility();
    }
}
