import { Component, ViewChild } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { ToastController, NavController, Platform, IonInput } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { IGroupVideo } from '../../interfaces/IGroupVideo';
import { IGroup } from '../../interfaces/IGroup';
import { GroupsApi } from 'src/app/services/api/groups-api.service';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from '../../services/app-data.service';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import * as moment from 'moment';
import * as _ from 'lodash';
import { IClip } from '../../interfaces/IClip';

@Component({
    selector: 'app-class-create',
    templateUrl: 'class-create-page.html',
    styleUrls: ['class-create-page.scss']
})
export class ClassCreatePage {
    public classCreateForm: UntypedFormGroup;
    public submitAttempt = false;
    public minDate: Date = new Date();
    public maxDate: Date = new Date();
    public minString: string;
    public maxString: string;
    public classVideos: IGroupVideo[];
    public videoId: string;
    public clonedGroup: IGroup;

    public validationMessages = null;

    public datePickerObjStart: any;
    public datePickerObjEnd: any;
    public showCustomDatePicker = false;

    @ViewChild('inputStartDatePicker', { static: false }) inputStartDatePicker: IonInput;
    @ViewChild('inputEndDatePicker', { static: false }) inputEndDatePicker: IonInput;

    constructor(public navCtrl: NavController,
        public groupsApi: GroupsApi,
        public uiUtils: UiUtils,
        private formBuilder: UntypedFormBuilder,
        public appData: AppData,
        public translate: TranslateService,
        public toastCtrl: ToastController,
        private router: Router,
        public plt: Platform,
        private logger: NGXLogger) {


        const nav = this.router.getCurrentNavigation();
        console.log('getCurrentNavigation', nav);
        if (nav && nav.extras && nav.extras.state) {
            this.clonedGroup = nav.extras.state.clonedGroup;
            this.classVideos = nav.extras.state.classVideos;
        }

        this.showCustomDatePicker = !this.plt.is('mobile');
    }


    ngOnInit() {
        this.initForm();

        this.translate
            .get(['validator_generic_required', 'validator_generic_minLength', 'validator_generic_maxLength'])
            .subscribe((i18n) => {
                this.validationMessages = {
                    className: [
                        { type: 'required', message: i18n['validator_generic_required'] },
                        { type: 'minlength', message: i18n['validator_generic_minLength'].replace('%d', 2) },
                        { type: 'maxlength', message: i18n['validator_generic_maxLength'].replace('%d', 40) },
                    ],
                    description: [
                        { type: 'required', message: i18n['validator_generic_required'] },
                        { type: 'maxlength', message: i18n['validator_generic_maxLength'].replace('%d', 200) },
                    ],
                    language: [{ type: 'required', message: i18n['validator_generic_required'] }],
                    startDate: [{ type: 'required', message: i18n['validator_generic_required'] }],
                    endDate: [{ type: 'required', message: i18n['validator_generic_required'] }]
                };
            });
    }

    initForm() {
        // https://ionicframework.com/docs/developer-resources/forms/
        // https://www.joshmorony.com/advanced-forms-validation-in-ionic-2/
        this.classCreateForm = this.formBuilder.group({
            className: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(40)]],
            description: ['', [Validators.required, Validators.maxLength(200)]],
            language: ['', [Validators.required]],
            startDate: ['', [Validators.required]],
            endDate: ['', [Validators.required]]
        });

        this.classCreateForm.patchValue({
            language: 'de'
        });

        this.minDate.setMonth(this.minDate.getMonth() - 6);
        this.maxDate.setFullYear(this.maxDate.getFullYear() + 3);
        this.minString = this.minDate.toISOString().substr(0, 10);
        this.maxString = this.maxDate.toISOString().substr(0, 10);

        this.initDatePicker();

        this.classCreateForm.get('startDate').valueChanges.subscribe(val => {
            this.logger.debug(`New value for startDate: ${val}.`);
            if (val && !this.classCreateForm.get('endDate').value) {
                // Uncomment to automatically focus end date picker:
                // setTimeout(() => {
                //     this.focusEndDatePicker();
                // }, 500);
            }
        });
    }

    ionViewWillEnter() {
        this.authenticateUser();

        this.appData.activeGroupId = '';
        this.appData.activePage = 'educator-class/new';

        this.videoId = this.appData.newClassVideoId; // From add video to new class
        this.appData.newClassVideoId = null;

        if (this.classVideos) {
            console.log('ClassCreatePage has videos.', this.classVideos);
        }
        if (this.videoId) {
            console.log('ClassCreatePage has videoId', this.videoId);
        }
    }

    /**
     * See https://www.logisticinfotech.com/blog/ionic4-datepicker-component
     */
    initDatePicker() {
        const i18n = this.translate.instant([
            'start_date',
            'end_date',
            'btn_ok',
            'btn_cancel',
            'btn_today',
            'january_short',
            'february_short',
            'march_short',
            'april_short',
            'may_short',
            'june_short',
            'july_short',
            'august_short',
            'september_short',
            'october_short',
            'november_short',
            'december_short',
            'monday_short',
            'tuesday_short',
            'wednesday_short',
            'thursday_short',
            'friday_short',
            'saturday_short',
            'sunday_short'
        ]);

        // Start date picker
        this.datePickerObjStart = {
            inputDate: new Date(),
            fromDate: this.minDate, // default null
            toDate: this.maxDate, // default null
            mondayFirst: true,
            momentLocale: this.appData.getLanguage(), // Default 'en-US'
            titleLabel: i18n['start_date'],
            setLabel: i18n['btn_ok'], // default 'Set'
            todayLabel: i18n['btn_today'], // default 'Today'
            closeLabel: i18n['btn_cancel'], // default 'Close'
            monthsList: [
                i18n['january_short'],
                i18n['february_short'],
                i18n['march_short'],
                i18n['april_short'],
                i18n['may_short'],
                i18n['june_short'],
                i18n['july_short'],
                i18n['august_short'],
                i18n['september_short'],
                i18n['october_short'],
                i18n['november_short'],
                i18n['december_short'],
            ],
            weeksList: [
                i18n['sunday_short'],
                i18n['monday_short'],
                i18n['tuesday_short'],
                i18n['wednesday_short'],
                i18n['thursday_short'],
                i18n['friday_short'],
                i18n['saturday_short']
            ],
            clearButton: false,
            btnCloseSetInReverse: true,
            showTodayButton: false,
            dateFormat: 'DD MMM YYYY',
            isSundayHighlighted: {
                // fontColor: '#555'
            },
        };

        // End date picker
        this.datePickerObjEnd = _.cloneDeep(this.datePickerObjStart);
        this.datePickerObjEnd.titleLabel = i18n['end_date'];
        this.datePickerObjEnd.inputDate = moment().add('month', 1).toDate();
    }

    /**
     * Redirects user to video catalog page if they are not logged in or are not educators
     */
    authenticateUser() {
        if (!this.appData.isEducator()) {
            this.navCtrl.navigateRoot('catalog');
        }
    }

    /**
     * Creates and adds a class for the specific user
     */
    createClass() {
        this.submitAttempt = true;

        this.classCreateForm.markAsDirty();
        this.classCreateForm.markAllAsTouched();


        this.logger.debug(`Start date ${typeof this.classCreateForm.value.startDate}:`, this.classCreateForm.value.startDate);
        this.logger.debug(`End date ${typeof this.classCreateForm.value.endDate}:`, this.classCreateForm.value.endDate);


        if (!this.classCreateForm.valid) {
            console.log('Form is not valid');
            return;
        }

        let end: moment.Moment;
        let start: moment.Moment;
        if (this.showCustomDatePicker) {
            if (this.classCreateForm.value.startDate) {
                start = moment(this.classCreateForm.value.startDate, this.datePickerObjStart.dateFormat);
            }
            if (this.classCreateForm.value.endDate) {
                end = moment(this.classCreateForm.value.endDate, this.datePickerObjEnd.dateFormat);
            }
        } else {
            if (this.classCreateForm.value.startDate) {
                start = moment(this.classCreateForm.value.startDate);
            }
            if (this.classCreateForm.value.endDate) {
                end = moment(this.classCreateForm.value.endDate);
            }
        }

        this.logger.debug(`Start date 2 ${typeof start}:`, start);
        this.logger.debug(`End date 2 ${typeof end}:`, end);

        // Check start date is before end date
        if (!end || !start || end.isBefore(start)) {
            this.uiUtils.showErrorAlert(this.translate.instant('invalid_dates'));
            return;
        }

        // end.setUTCHours(23);
        // end.setUTCMinutes(59);
        // end.setUTCSeconds(59);
        // end.setUTCMilliseconds(999);
        // start.setUTCHours(0);
        // start.setUTCMinutes(0);
        // start.setUTCSeconds(0);
        // start.setUTCMilliseconds(0);
        end.set('h', 23);
        end.set('m', 59);
        end.set('s', 59);
        end.set('ms', 999);
        start.set('h', 0);
        start.set('m', 0);
        start.set('s', 0);
        start.set('ms', 0);

        // Create the new group
        const newClass: IGroup = {
            name: this.classCreateForm.value.className,
            description: this.classCreateForm.value.description,
            base_language: this.classCreateForm.value.language,
            under14_students: false, // @TODO change this way of create class.
            start: start.toDate(),
            end: end.toDate(),
            educator: null,
            // educators test
            team_educators: [
                {
                    edId: this.appData.authenticatedUser._id,
                    subjects: []
                }
            ],
            access_code: this.appData.authenticatedUser.access_code,
            customer_id: undefined
        };

        this.logger.debug('New class object', newClass);

        // Add GroupItem
        this.groupsApi.createGroup(newClass).toPromise().then(async (result) => {
            this.logger.debug('Success - post group by user id', result);

            // Add videos if videos passed from clone class
            if (this.classVideos) {
                for (const video of this.classVideos) {
                    // We also need to "clone" the assigned Clip object
                    let clip: IClip;
                    if (video.clip_id) {
                        clip = video.clip_id as IClip;
                        clip.clonedOf = clip._id;
                        delete clip._id;
                    }
                    try {
                        const res = await this.groupsApi
                            .addVideo(result.data._id, video.video_id._id, null, clip)
                            .toPromise();
                        this.logger.debug('Success - put video in group by group id', res);
                    } catch (err) {
                        this.logger.warn('Error - put video in group by group id', err);
                    }
                }
            }

            // Add video if videoId passed from add video to new class
            if (this.videoId) {
                this.groupsApi.addVideo(result.data._id, this.videoId).subscribe(res => {
                    this.logger.debug('Success - put video in group by group id', res);
                }, err => {
                    this.logger.debug('Error - put video in group by group id', err);
                });
            }

            // Add group to groups array for user
            this.appData.groupsForUser.push(result.data);

            this.uiUtils.displayToast(this.translate.instant('class_created'));

            // Navigate to new class
            this.appData.activePage = '';
            this.appData.activeGroupId = result.data._id;
            this.navCtrl.navigateForward(`/educator-class/details/${result.data._id}`);
        }, err => {
            this.logger.error('Error - post group by user id', err);
            switch (err.status) {
                case 400:
                    this.uiUtils.showErrorAlert(this.translate.instant('invalid_input'));
                    break;
                case 404:
                    this.uiUtils.showErrorAlert(this.translate.instant('user_not_found'));
                    break;
                case 409:
                    this.uiUtils.showErrorAlert(this.translate.instant('class_exists'));
                    break;
            }
        });
    }

    datePickerChanged() {
        this.logger.debug('datePickerChanged');
    }

    focusStartDatePicker() {
        if (this.inputStartDatePicker) {
            this.inputStartDatePicker.setFocus();
        }
    }

    focusEndDatePicker() {
        if (this.inputEndDatePicker) {
            this.inputEndDatePicker.setFocus();
        }
    }
}
