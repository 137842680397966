<div class="wrapper">
    <!-- <img *ngIf="isIOsDevice" src="assets/img/paid_feature_class_create.webp">   For IOS device -->
    <img height="400" width="400" src="assets/img/paid_feature_class_create.png">
    <ion-list lines="none">
        <ion-item class='ion-text-center' (click)='subscribe()'  button="true" detail="false">
            <ion-label>
                <ion-button fill="clear" color='primary'>Subscribe</ion-button>
            </ion-label>
        </ion-item>
        <ion-item class='ion-text-center' (click)='product()'  button="true" detail="false">
            <ion-label>
                <ion-button fill="clear" color='primary'>sCooling</ion-button>
            </ion-label>
        </ion-item>
    </ion-list>
</div>
