import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/app.constants';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from 'src/app/services/app-data.service';
import { AppManager } from 'src/app/services/app-manager.service';
import { AnalyticsCategory, AnalyticsService } from 'src/app/services/analytics.service';
import { AbstractRootMenuPage } from 'src/app/utils/abstract-root-menu-page';
import { IVideo } from '../../interfaces/IVideo';
import * as _ from 'lodash';
import { NavigationExtras } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { WatchedVideo } from '../../models/watched-video';
import { VideosApi } from '../../services/api/videos-api.service';
import { WatchedVideoApi } from '../../services/api/watched-videos-api.service';

interface IFAQ {
    question: string;
    answer: string;
}

@Component({
    selector: 'app-help',
    templateUrl: 'help-page.html',
    styleUrls: ['help-page.scss']
})
export class HelpPage extends AbstractRootMenuPage implements OnInit {

    public videos: IVideo[] = null;
    public watchedVideos: WatchedVideo[] = [];

    public faqs: IFAQ[] = [
        {
            question: 'Ab welchem Sprachniveau kann ich mit uugot.it im Unterricht arbeiten?',
            answer: 'Ab A1-Niveau. Es gibt Didaktisierungen von A1 bis B2, teilweise auch C1. Die Lehrkraft kann auch eigene Didaktisierungen erstellen, die genau ans Niveau der Lernergruppe angepasst sind. Es empfiehlt sich aber, dass Lernende vor der Arbeit mit uugot.it etwa 40 UE hinter sich haben.'
        },
        {
            question: 'Welche Vorteile bietet sCOOLing gegenüber der kostenfreien Basisversion uugot.it TV?',
            answer: 'Videos stehen langfristig zur Verfügung und sind mit Arbeitsblättern und didaktischen Kommentaren angereichert. Lehrende können Klassen anlegen und den Lernfortschritt kontrollieren. Lehrende und Lernende haben auch freien Zugang zu allen ORF-Videos.'
        },
        {
            question: 'Wieviel kostet uugot.it sCOOLing?',
            answer: 'Aufgrund der Corona-Pandemie wird derzeit uugot.it sCOOLing für Schulen in Österreich kostenfrei zur Verfügung gestellt.'
        },
        {
            question: 'Welche technischen Voraussetzungen sind erforderlich, um uugot.it sCOOLing im Unterricht zu benutzen?',
            answer: 'Lernende brauchen nur ein mobiles Endgerät oder Zugang zu einem Computer und WiFi bzw. mobiles Internet. '
        },
        {
            question: 'Kann man mit uugot.it auch im Online-Kontext arbeiten?',
            answer: 'Ja, die Arbeitsblätter sind so angelegt, dass sie autonom in Einzelarbeit gelöst werden können. Zusätzlich gibt es im didaktischen Kommentar Ideen, wie die Interaktion zwischen den Lernenden sowohl im Präsenz- als auch im Online-Unterricht gefördert werden kann.'
        },
        {
            question: 'Entspricht uugot.it sCOOLing den datenschutzrechtlichen Anforderungen?',
            answer: 'Ja. Die Lernplattform uugot.it sCOOLing ist DSGVO-konform und erfüllt die gültigen datenschutzrechtlichen Standards.'
        },
        {
            question: 'Die Muttersprache meiner Lernenden wird nicht von uugot.it abgedeckt. Kann ich die App trotzdem benutzen?',
            answer: 'Ja, Lernende mit anderen Muttersprachen können entweder mit der englischen Übersetzung oder einer anderen bekannten Sprache (Brückensprache) arbeiten. Falls sie keine der Sprachen der interaktiven Untertitel kennen, können sie trotzdem die Videos sehen und die Arbeitsblätter bearbeiten. Die deutschen Untertitel sowie die Auswahl der Abspielgeschwindigkeit helfen beim Verständnis.'
        },
        {
            question: 'Können die interaktiven Untertitel die Arbeit mit dem Wörterbuch ersetzen?',
            answer: `Das kommt drauf an. Wenn es um reines Hör- oder Leseverstehen geht, sind die interaktiven
            Untertitel meistens vollkommen ausreichend.
            Im Lernbereich können die neuen Wörter im Kontext geübt und gefestigt werden. Die Wörter
            werden dabei immer in der Form aufgelistet, in der sie im Video vorkommen (also evtl.
            konjugiert oder dekliniert). Vor allem auf niedrigen Niveaus kann es daher hilfreich sein,
            zusätzlich mit einem Wörterbuch (oder der Lehrkraft) zu arbeiten, um Wortart, Grundform und
            andere grammatikalische Merkmale des Wortes (Genus der Substantive, Konjugation der
            unregelmäßigen Verben) zu finden.`
        },
        {
            question: 'Wie kann ich auf uugot.it sCOOLing ein Video finden, das zu meinem Thema passt?',
            answer: `Die Videos im Schulbereich sind nach Fachgegenständen sortiert. In der Mediathek sind sie
            nach Aktualität gelistet und mit dem Genre der Sendung versehen.`
        },
        {
            question: 'Wie wird die Qualität der Didaktisierungen sichergestellt?',
            answer: `Die Didaktisierungen werden von erfahrenen DaF/DaZ-Dozent*innen erstellt.
            Damit sie einem gleichbleibend hohen Qualitätsstandard entsprechen, werden sie erst nach
            Freigabe durch unser Didactic Team ins System hochgeladen und freigeschalten.`
        },
        {
            question: 'Wie ist eine Didaktisierung aufgebaut? Welche Aufgabenstellungen kommen darin vor?',
            answer: `In jeder Didaktisierung werden die Aufgaben in 3 Sektionen aufgeteilt:
            <ul>
            <li>Vor dem Sehen: Einleitung ins Thema, Aktivierung von Vorwissen,
            Wortschatzvorentlastung...</li>
            <li>Während des Sehens: Hörsehverstehen, Arbeit mit Wortschatz und grammatikalischen
            Formen...</li>
            <li>Nach dem Sehen: weiterführende Aufgaben, Internetrecherchen, Schreibaufgaben, eigene
            Meinung ausdrücken...</li>
            </ul>
            Die Aufgabenformate variieren und sind teils geschlossen (z.B. richtig / falsch), teils offen (freie
            Antwort). Für alle geschlossenen Aufgaben wird die richtige Lösung auf dem Lösungsblatt
            angegeben, für offene Aufgaben gibt es oft eine Musterlösung. `
        },
        {
            question: 'Die Lernenden in meiner Gruppe weisen unterschiedliche Sprachstände in Deutsch auf. Kann ich uugot.it trotzdem einsetzen?',
            answer: `Ja. Weil für die Videos separate Didaktisierungen in unterschiedlichen Sprachniveaus vorhanden
            sind, lässt sich gerade in inhomogenen Gruppen sehr leicht Binnendifferenzierung erzielen. `
        },
        {
            question: 'Für welche Altersgruppen eignet sich uugot.it sCOOLing?',
            answer: `Ab Sekundarstufe I.<br>
            Für die erfolgreiche Nutzung der interaktiven Untertitel ist eine entsprechende Lesefähigkeit
            erforderlich, welche ab etwa zehn Jahren gegeben ist. Zudem müssen Lerner*innen die
            lateinische Schrift lesen können. `
        },
        {
            question: 'Kann ich die Arbeitsblätter abändern und so besser an meine Lerngruppe anpassen?',
            answer: `Ja. Die Didaktisierungen können im Word-Format heruntergeladen und entsprechend
            bearbeitet werden. So können z.B. Aufgaben auf den Arbeitsblättern gelöscht oder hinzugefügt
            werden. `
        },
        {
            question: 'Sind alle Sendungen von ORF? Oder sind auch andere Sender vertreten?',
            answer: `Auf sCOOLing sind zurzeit folgende österreichische Sender vertreten: ORF (ohne
                Didaktisierungen), W24, LT1, Tirol TV.`
        },
        {
            question: 'Können die Arbeitsblätter online ausgefüllt und ausgewertet werden?',
            answer: `Die Arbeitsblätter sind im Word-Format verfügbar. Sie müssen vor dem Bearbeiten
            heruntergeladen und eventuell ausgedruckt werden. Auf Laptops können sie im Word
            bearbeitet werden. `
        },
        {
            question: 'Noch weitere Fragen? ',
            answer: 'Schreib uns unter <a href="mailto:ask_uu@uugot.it">ask_uu@uugot.it!</a>'
        }
    ];

    /*
    public academyVideos: IVideo[] = [
        {
            tags: [],
            isAvailable: true,
            isEnabled: true,
            hasSubtitle: false,
            translations: [],
            _id: '6086e577737da4b193125047',
            websource_id: 'academy',
            title: 'Academy Test Video 1',
            description: 'This is a test video',
            duration: 138,
            pageURL:
                'https://media-cdn.uugot.it/videos/testtv/2020-09-03_Nachrichten-in-einfacher-Sprache_veryhigh.mp4?v=1',
            publishDate: '2021-04-26T00:00:00.000Z',
            expiryDate: '2099-12-31T00:00:00.000Z',
            groupId: '',
            sequence: 0,
            imageURL:
                'https://media-cdn.uugot.it/videos/testtv/2020-09-03_Nachrichten-in-einfacher-Sprache_veryhigh.jpg',
            sources: [
                {
                    resolution: 'low',
                    type: 'video/mp4',
                    link:
                        'https://media-cdn.uugot.it/videos/testtv/2020-09-03_Nachrichten-in-einfacher-Sprache_veryhigh.mp4',
                    protocol: 'https',
                },
                {
                    resolution: 'medium',
                    type: 'video/mp4',
                    link:
                        'https://media-cdn.uugot.it/videos/testtv/2020-09-03_Nachrichten-in-einfacher-Sprache_veryhigh.mp4',
                    protocol: 'https',
                },
                {
                    resolution: 'high',
                    type: 'video/mp4',
                    link:
                        'https://media-cdn.uugot.it/videos/testtv/2020-09-03_Nachrichten-in-einfacher-Sprache_veryhigh.mp4',
                    protocol: 'https',
                },
                {
                    resolution: 'veryHigh',
                    type: 'video/mp4',
                    link:
                        'https://media-cdn.uugot.it/videos/testtv/2020-09-03_Nachrichten-in-einfacher-Sprache_veryhigh.mp4',
                    protocol: 'https',
                },
            ],
            subtitleURL: '',
            disabledDate: '2099-01-01T00:00:00.000Z',
            originalLang: 'de',
            offset: null,
            program: null,
            cat_id: null,
            teachResURL: null,
            favored: false,
        },
    ];
    */

    constructor(public uiUtils: UiUtils,
        public translate: TranslateService,
        public appData: AppData,
        public appManager: AppManager,
        public constants: Constants,
        public navCtrl: NavController,
        private analytics: AnalyticsService,
        private titleService: Title,
        protected plt: Platform,
        private logger: NGXLogger,
        private videosApi: VideosApi,
        private watchedVideosApi: WatchedVideoApi
    ) {
        super(plt);
    }

    ngOnInit() {
        this.titleService.setTitle('Help | uugot.it');
        this.analytics.trackPageView('help');
        this.loadVideoCatalog();
    }

    ionViewWillEnter() {
        super.ionViewWillEnter();
        this.appData.activePage = 'help';

        if (this.videos) {
            this.logger.debug('HelpPage - reloading watched videos on ionViewWillEnter');
            this.loadWatchedVideos();
        }
    }

    ionViewWillLeave() {
        super.ionViewWillLeave();
    }

    /**
     * Loads next videos in the video catalog
     *
     * @param showLoadingAnimation Show loading animation if true
     * @param onComplete Function to run when loading complete
     */
    async loadVideoCatalog() {
        const source = 'academy';
        try {
            const responseCatalogue = await this.videosApi
                .getUserSpecificCatalog(source, 0, 20, null, '', null, null, null, null, true)
                .toPromise();
            console.log(`Reload data got ${responseCatalogue.videosAvailable?.length} videos`);
            this.videos = responseCatalogue.videosAvailable || [];
            this.logger.debug('HelpPage - reloading watched videos after initial videos loading');
            this.loadWatchedVideos();
        } catch (err) {
            this.logger.warn(`Error reloading data: ${err.message}`, err);
            if (err.status !== this.constants.HTTP_STATUS_CODE.UNAUTHORIZED) {
                this.uiUtils.showErrorAlert(err.error.message);
            }
            this.videos = [];
        }
    }

    async loadWatchedVideos(): Promise<void> {
        if (!this.videos) {
            return;
        }
        const videoIds = this.videos.map(v => v._id);
        if (this.appData.isLoggedIn()) {
            const watchedVideos = await this.watchedVideosApi.listByVideos(videoIds).toPromise();
            this.watchedVideos = watchedVideos.data?.map(wv => {
                const w = new WatchedVideo(wv.video_id, wv.watchedUntil, wv.expiryDate, wv.dur, wv.fin);
                return w;
            }) || [];
        } else {
            this.watchedVideos = this.appData.getWatchedVideos().filter(w => videoIds.includes(w.videoId));
        }
    }
}
