// angular
import {
    Component,
    ViewChild,
    OnInit,
    ViewEncapsulation,
    HostListener,
    ElementRef
} from '@angular/core';
import { DomSanitizer, SafeStyle, Title } from '@angular/platform-browser';

// ionic
import { NavController, Platform } from '@ionic/angular';

// models
import { SavedWord } from '../../models/saved-word.model';
import { IWordCatalog } from 'src/app/interfaces/IWordCatalog';
import { IWord } from 'src/app/interfaces/ITranslation';

// services
import { Utils } from '../../utils/utils';
import { AnalyticsService, AnalyticsCategory } from 'src/app/services/analytics.service';
import { AppData } from 'src/app/services/app-data.service';
import { IGroup } from 'src/app/interfaces/IGroup';
import { WordCatalogHelperService } from 'src/app/services/word-catalog-helper.service';

// libraries
import { TranslateService } from '@ngx-translate/core';
import { WordCatalogApi } from 'src/app/services/api/wordcatalog.service';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { ILanguages } from '../../interfaces/ILanguages';
import { WordCatalogCardDetailsResponse } from '../../models/dtos';
import Swiper from 'swiper';

@Component({
    selector: 'app-word-catalog-cards',
    templateUrl: 'word-catalog-cards-page.html',
    styleUrls: ['word-catalog-cards-page.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WordCatalogCardsPage implements OnInit {
    wordCatalog: Array<SavedWord|IWordCatalog>;
    wordCardDetails: Map<string, WordCatalogCardDetailsResponse> = new Map();
    initialIndex: number;
    index = 0;
    class_id: string;
    class: IGroup;
    swiper: Swiper;
    safeBackgroundUrls: Map<string, SafeStyle> = new Map();

    // @ViewChild(IonSlides, { static: false }) slides: IonSlides;

    @ViewChild('swiper')
    swiperRef: ElementRef | undefined;

    slideOptions = {
        initialSlide: 5,
        zoom: {
            maxRatio: 5
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'progressbar',
        }
    };
    public loading = false;

    constructor(private _route: ActivatedRoute,
        public navCtrl: NavController,
        public appData: AppData,
        public translate: TranslateService,
        public analytics: AnalyticsService,
        public plt: Platform,
        public wordCatalogApi: WordCatalogApi,
        public uiUtils: UiUtils,
        private sanitizer: DomSanitizer,
        private router: Router,
        private titleService: Title,
        private logger: NGXLogger,
        private wordCatalogHelperService: WordCatalogHelperService) {
        const nav = this.router.getCurrentNavigation();
        this.logger.debug('getCurrentNavigation.extras', nav ? nav.extras : '-');
        if (nav && nav.extras && nav.extras.state) {
            this.index = nav.extras.state.index;
        }
    }

    ionViewWillEnter() {
        this.logger.debug('WordCatalogPage snapshot.data: ', this._route.snapshot.data);


    }

    ngOnInit() {

        this.titleService.setTitle('Word Catalog Cards | uugot.it');
        this.analytics.trackPageView('word-catalog-cards');
        this.loading = true;
        this._route.snapshot.data.resData.subscribe(data => {
            if (data) {
                const initializeWordcatalog = () => {
                    this.wordCatalog = data.wordCatalog;
                    this.class = data.group;
                    this.class_id = this.class ? this.class._id : null;
                    // this.initialIndex = this._route.snapshot.data.data.index;
                    this.swiperRef.nativeElement.swiper.update(); // needed because the slides are rendered before the words are fetched
                    setTimeout(() => {
                        this.initializeSlides();
                        this.swiperRef.nativeElement.swiper.update(); // needed because the slides are rendered before the words are fetched
                    }, 100);
                };
                // Load first word details
                if (data.wordCatalog?.length > 0) {
                    const word = data.wordCatalog[0];
                    this.wordCatalogApi.getCardDetails(word._id).toPromise().then(cardInfo => {
                        this.wordCardDetails.set(word._id, cardInfo);
                        this.loading = false;
                        this.swiperRef.nativeElement.swiper.update(); // needed because the slides are rendered before the words are fetched

                    }).catch(err => {
                        this.logger.error('Error loading card details 1:', err);
                    }).finally(() => {
                        initializeWordcatalog();
                    });
                } else {
                    initializeWordcatalog();
                }
                // Load second word details
                if (data.wordCatalog?.length > 1) {
                    const word = data.wordCatalog[1];
                    this.wordCatalogApi.getCardDetails(word._id).toPromise().then(cardInfo => {
                        this.wordCardDetails.set(word._id, cardInfo);
                    }).catch(err => {
                        this.logger.error('Error loading card details 2:', err);
                    });
                }
            } else {
                this.logger.debug('WordCatalogPage got no data');
            }
        });
    }

    /**
     * Initializes slides
     */
    initializeSlides() {
        this.appData.activeGroupId = this.class_id;
        // this.appData.activePage = this.class_id ? `word-catalog/${this.class_id}` : 'word-catalog';
        this.appData.activePage = this.class_id ? `word-catalog-groups` : 'word-catalog';
        this.wordCatalog.forEach(savedWord => {
            // We need do sanitize the URL due to Angular security restrictions
            if (!this.safeBackgroundUrls[savedWord.imageURL]) {
                this.safeBackgroundUrls[savedWord.imageURL] = this.sanitizer.bypassSecurityTrustStyle(`url(${savedWord.imageURL})`);
            }
        });

        this.logger.debug(`initialIndex=${this.initialIndex}`);
        this.swiperRef?.nativeElement.swiper.slideTo(this.index, 1);
    }

    navigateBack() {
        const navigationExtras: NavigationExtras = {
            state: {
                wordCatalog: this.wordCatalog,
                group: this.class
            }
        };

        const backPath = this.class_id ? `word-catalog/${this.class_id}` : 'word-catalog';
        this.navCtrl.navigateBack([backPath], navigationExtras);
    }

    /**
     * Opens video player page
     *
     * @param videoId The video ID
     * @param source The source of the video
     * @param timeInVideo The time to start the video
     */
    openVideoPlayerPage(word: SavedWord|IWordCatalog): void {

        const videoId = word.video_id;
        const source = word.websource_id;
        // Backwards compatibility (no sentence_start):
        const timeInVideo = word.sentence_start !== null ? word.sentence_start : word.start;

        // videoId: string, source: string, timeInVideo: number
        this.logger.debug('Opening video player page with id %s and time timeInVideo %s', videoId, timeInVideo);
        this.analytics.trackAnalyticsEvent(AnalyticsCategory.WordCatalog, 'play_clip', videoId);

        // this.appData.timeInVideo = timeInVideo; // This is not needed any more because we have looping and clipping
        let videoPath = `video/${source}/${videoId}`;
        if (this.class_id) {
            videoPath += `/${this.class_id}`;
        }

        const clippingStart = Math.max(timeInVideo - 0.2, 0);
        const clippingEnd = word.sentence_end !== null ? word.sentence_end + 0.5 : timeInVideo + 6;

        const navigationExtras: NavigationExtras = {
            state: {
                maxLoops: 3, // 1 = disable looping
                clippingStart,
                clippingEnd,
                autoStart: true,
                backRoute: this.class_id ? `word-catalog-cards/${this.class_id}` : 'word-catalog-cards',
                toLanguage: word.to
            }
        };

        this.navCtrl.navigateForward(videoPath, navigationExtras);
    }

    /**
     * Opens translation correction page for the current word in the slider
     */
    openTranslationCorrection(): void {
        // this.slides.getActiveIndex().then(index => {
        //     const navigationExtras: NavigationExtras = {
        //         state: {
        //             group: this.class,
        //             word: this.wordCatalog[index]
        //         }
        //     };
        //
        //     this.navCtrl.navigateForward(['translation-correction'], navigationExtras);
        // });
    }

    /**
     * Wraps original word in a <span>
     *
     * @param word The word to be wrapped
     * @returns Returns the sentence with the originalWord wrapped in a <span>
     */
    getSentence(word: SavedWord | IWordCatalog): string {
        const details = this.wordCardDetails.get(word._id);
        let sentence = this.wordCatalogHelperService.getSentence(word, details);
        // Replace color tags with spans and CSS classes
        sentence = sentence.replace(/\[START_TEXT_GREEN_TAG\](.*?)\[END_COLOR_TAG\]/g, '<span class="text-green">$1</span>');
        sentence = sentence.replace(/\[START_TEXT_CYAN_TAG\](.*?)\[END_COLOR_TAG\]/g, '<span class="text-cyan">$1</span>');
        sentence = sentence.replace(/\[START_TEXT_WHITE_TAG\](.*?)\[END_COLOR_TAG\]/g, '<span class="text-cyan">$1</span>');
        sentence = sentence.replace(/\[START_TEXT_YELLOW_TAG\](.*?)\[END_COLOR_TAG\]/g, '<span class="text-cyan">$1</span>');
        // Add more replacements for other color tags if needed

        return sentence;
    }

    getCardDetails(word: SavedWord | IWordCatalog): WordCatalogCardDetailsResponse {
        if (!word._id) {
            return null;
        }
        return this.wordCardDetails.get(word._id);
    }

    getFromWord(word: SavedWord | IWordCatalog): string {
        return this.wordCatalogHelperService.getFromWord(
            word,
            this.wordCardDetails
        );
    }

    /**
     * Called when a slide is changed
     */
    slideChanged() {
        this.analytics.trackAnalyticsEvent(AnalyticsCategory.WordCatalog, 'slide_changed');
        this.index = this.swiper?.activeIndex;
    }

    /**
     * Slides to next slide
     */
    slideNext() {
        this.swiperRef.nativeElement.swiper.slideNext();
        // this.swiperRef.nativeElement.swiper.update(); // needed because the slides are rendered before the words are fetched

    }

    /**
     * Slides to previous slide
     */
    slidePrev() {
        this.swiperRef.nativeElement.swiper.slidePrev();
        // this.swiperRef.nativeElement.swiper.update(); // needed because the slides are rendered before the words are fetched
    }

    splitWordIntoLinesJoin(word: SavedWord | IWordCatalog): string {
        const overrideFromAndToWord = this.wordCatalogHelperService.getOverrideFromAndToWords(word, this.wordCardDetails);
        const toWord = overrideFromAndToWord ? overrideFromAndToWord.toWord : word.toWord;
        const toLanguage: ILanguages = word.to;
        return Utils.splitWordIntoLinesJoin(toWord, toLanguage, '<br>');
    }

    splitWordIntoLines(word: SavedWord | IWordCatalog): string[] {
        const overrideFromAndToWord = this.wordCatalogHelperService.getOverrideFromAndToWords(word, this.wordCardDetails);
        const toWord = overrideFromAndToWord ? overrideFromAndToWord.toWord : word.toWord;
        return Utils.splitWordIntoLines(toWord);
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        console.log(event);

        if (event.key === 'ArrowLeft') {
            this.slidePrev();
        }

        if (event.key === 'ArrowRight') {
            this.slideNext();
        }
    }

    hasLoaded() {
        console.log('termino de cargar ')
    }

    slidesUpdated() {
        console.log('entra hay mas sldies')
    }
}
