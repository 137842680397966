import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from './api.service';
import { IWebsource } from 'src/app/interfaces/IWebsource';

/**
 * API for /websources/
 * See https://spyder3-dev.uugot.it/api-docs/#/websources
 */
@Injectable({
    providedIn: 'root'
})
export class WebsourcesApi {
    constructor(public api: Api) {
        // Do nothing.
    }

    /**
     * Retrieve all available sources
     */
    getVideoSources(): Observable<IWebsource[]> {
        return this.api.get('websources');
    }
}
