import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from './api.service';
import { ISingleObjectResponse, IListResponse } from '../../models/dtos';
import { IFavoredVideo } from '../../interfaces/IFavoredVideo';

/**
 * API for /watched-video/
 * See https://spyder3-dev.uugot.it/api-docs/#/watched-video
 */
@Injectable({
    providedIn: 'root',
})
export class FavoredVideosApi {
    constructor(public api: Api) {
        // Do nothing.
    }

    /**
     * Add new favored video or update existing favored video.
     * (student, educator)
     *
     * @param video_id the video ID
     */
    add(video_id: string): Observable<ISingleObjectResponse<IFavoredVideo>> {
        const params = { video_id };
        // console.log('Favored videos update params', params);
        return this.api.put('favored-videos', params);
    }

    /**
     * Delete a favored video.
     * (student, educator)
     *
     * @param video_id the video ID
     */
    delete(video_id: string): Observable<ISingleObjectResponse<IFavoredVideo>> {
        const body = { video_id };
        // console.log('Favored videos update body', body);
        return this.api.delete('favored-videos', null, body);
    }

    /**
     * Get favored videos for a specific user.
     * (student, educator)
     *
     * @param offset Limit number of listed watched videos. Use it for pagination.
     * @param limit Skip certain number of watched videos. Use it for pagination.
     * @param sort Sort watched videos by field. Add - for descending sort. Default value : -created_at
     */
    list(limit?: number, offset?: number, sort?: string): Observable<IListResponse<IFavoredVideo>> {
        const params: any = {};
        if (limit) {
            params.limit = limit;
        }
        if (offset) {
            params.offset = offset;
        }
        if (sort) {
            params.sort = sort;
        }
        return this.api.get('favored-videos/list', params);
    }

    /**
     * Get favored videos for a specific user by array of defined video ids.
     * (student, educator)
     *
     * @param videoIds the IDs of the videos for which you want the watched videos
     */
    listByVideos(videoIds: string[]): Observable<IListResponse<IFavoredVideo>> {
        return this.api.post('favored-videos/listbyvideos', videoIds);
    }
}
