// angular
import { Component, OnInit } from '@angular/core';

// models
import { IVideo } from '../../interfaces/IVideo';

// libraries
import * as _ from 'lodash';

import { VideoCatalogPage } from './video-catalog-page';
import { AnalyticsCategory } from 'src/app/services/analytics.service';
import { Platform } from '@ionic/angular';
import { WatchedVideo } from '../../models/watched-video';

@Component({
    selector: 'app-video-catalog-my-videos',
    templateUrl: 'video-catalog-my-videos-page.html',
    styleUrls: ['video-catalog-page.scss']
})
export class VideoCatalogMyVideosPage extends VideoCatalogPage implements OnInit {

    protected videos: IVideo[];
    protected myFavVideos: IVideo[];
    private lastReload: Date;
    private allVideosLoaded = false;
    private enableInfiniteScroll = true;
    public showLoadingIndicator = true;
    public group_id;
    public videosForCustomer: IVideo[];
    public segment: 'recently-watched' | 'favored';

    ngOnInit() {
        super.ngOnInit();
        // this.segment = this.appData.isLoggedIn() ? 'favored' : 'recently-watched';
        this.segment = 'favored';
        this.enableInfiniteScroll = true;
        this.pageSize = 20;
    }

    ionViewWillEnter() {
        this.logger.debug('ionViewWillEnter');


        this._route.url.subscribe(async urlSegments => {
            const url = urlSegments.map(segment => segment.path).join('/');
            if (url.includes('videos/available')) {
                this.headerTitle = 'Videos available for the class'
                this.isVideosAvailableForGroupSection = true;
                this.group_id = this._route.snapshot.params.class_id;
                this.segment = 'recently-watched';
                const videosByCustomer = await this.watchedVideosApi.listByCustomer(500, 0, '-updated_at').toPromise();
                this.showLoadingIndicator = false;
                const data = videosByCustomer?.map(wv =>
                    wv.video) || [];
                this.videosForCustomer = data;
                super.creatBackButtonSubscription();
            } else {
                this.appData.activePage = 'my-videos';
                this.translate.get('my_videos').subscribe(i18n => {
                    this.headerTitle = i18n;
                });
                if (this.shouldReload()) {
                    this.offset = 0;
                    this.videos = null;
                    this.loadVideoCatalog(true, () => {
                        // Do nothing.
                    });
                } else {
                    this.loadWatchedVideos();
                }
                // console.log('infiniteScroll:', this.infiniteScroll);
                this.infiniteScroll.disabled = this.appData.allVideosLoaded;

                if (this.isRootPage) {
                    // Exit app subscription
                    super.creatBackButtonSubscription();
                }
            }
        });
    }

    // Workaround for https://gitlab.com/uugotitTeam/webapp/issues/172
    ionViewDidEnter() {
        // Do nothing.
    }

    ionViewWillLeave() {
        if (this.isRootPage) {
            // Exit app subscription
            super.removeBackButtonSubscription();
        }
    }


    /**
     * Loads next videos in the video catalog
     *
     * @param showLoadingAnimation Show loading animation if true
     * @param onComplete Function to run when loading complete
     */
    async loadVideoCatalog(showLoadingAnimation: boolean, onComplete: () => void) {
        // const loader = await this.loadingCtrl.create({
        //     message: this.translate.instant('loading_please_wait'),
        // });

        if (showLoadingAnimation) {
            // await loader.present();
            this.showLoadingIndicator = true;
        }

        let country = this.appData.geoIp ? this.appData.geoIp.country : null;
        if (country === 'ZZ') { // when running on localhost for example
            country = 'AT';
        }


        try {

            // Load watched videos and associated videos
            let videoIds: string[];
            switch (this.segment) {
                case 'recently-watched': {
                    // We have a fixed page size - only load 10 watched videos, sorted by descening by date
                    if (this.appData.isLoggedIn()) {
                        // Make sure watched videos are up-to-date
                        await this.appManager.loadWatchedVideos();
                    }
                    this.watchedVideos = _(this.appData.getWatchedVideos())
                        .orderBy(['updatedAt'], ['desc'])
                        .filter(wv => wv.watchedUntil && wv.watchedUntil > this.constants.VideoWatchedThresholdSeconds)
                        .take(500)
                        .value();
                    videoIds = this.watchedVideos.map(v => v.videoId);
                    break;
                }
                case 'favored': {
                    this.favoredVideos = _(await this.appData.getFavoredVideos())
                        .orderBy(['created_at'], ['desc'])
                        .value();
                    videoIds = this.favoredVideos?.map((v) => v.video_id);
                    break;
                }

                default:
                    break;
            }

            let responseCatalogue;
            if (videoIds && videoIds.length > 0) {
                responseCatalogue = await this.videosApi
                    .getVideos(videoIds, this.offset, this.pageSize)
                    .toPromise();
                responseCatalogue.videosAvailable = _.sortBy(responseCatalogue.videosAvailable, [
                    (video) => {
                        return videoIds.indexOf(video._id);
                    },
                ]);
            } else {
                responseCatalogue = {
                    videosAvailable: [],
                    videosAvailableCount: 0,
                };
            }

            const onSuccess = () => {
                if (this.offset === 0) {
                    // Clear videos, in case of pull-to-refresh
                    this.videos = [];
                }
                this.addVideos(responseCatalogue.videosAvailable);
                this.offset += this.pageSize;
                const category =
                    this.segment === 'favored'
                        ? AnalyticsCategory.FavoredVideos
                        : AnalyticsCategory.RecentlyWatchedVideos;
                this.analytics.trackAnalyticsEvent(category, 'loaded', this.tag);
                this.allVideosLoaded = this.enableInfiniteScroll
                    ? this.offset >= responseCatalogue.videosAvailableCount
                    : true;
                this.logger.debug(
                    `Loaded all available videos (${this.offset} / ${responseCatalogue.videosAvailableCount})?`,
                    this.allVideosLoaded ? 'yes' : 'no'
                );
                this.infiniteScroll.disabled = this.allVideosLoaded;
                this.updateFavoredVideos();
            };

            if (showLoadingAnimation) {
                // loader.dismiss().then(onSuccess);
                this.showLoadingIndicator = false;
                onSuccess();
            } else {
                onSuccess();
            }

            this.logger.debug('Reload data complete');
            onComplete();

        } catch (err) {
            const reloadHandler = () => {
                this.logger.debug('user wants to reload');
                this.loadVideoCatalog(true, () => {
                    // Do nothing.
                });
            };
            const onError = () => {
                this.logger.error('Error reloading data: %s', err.message, err);
                if (err.status !== this.constants.HTTP_STATUS_CODE.UNAUTHORIZED) {
                    this.uiUtils.showErrorAlert(err.message || err.error.message, null, reloadHandler);
                }
            };
            if (showLoadingAnimation) {
                // loader.dismiss().then(onError);
                this.showLoadingIndicator = false;
                onError();
            } else {
                onError();
            }
        }

    }

    segmentChanged(event: any) {
        this.logger.debug('segmentChanged', event);
        this.videos = null;
        this.offset = 0;

        switch (this.segment) {
            case 'recently-watched':
                this.enableInfiniteScroll = false;
                this.pageSize = 10;
                break;
            case 'favored':
                this.enableInfiniteScroll = true;
                this.pageSize = 20;
                break;
        }

        this.loadVideoCatalog(true, () => {
            this.logger.debug('segmentChanged operation has ended');
        });
    }

    /**
     * Callback for video card that is called if a video is favor or unfavored.
     * @param video the video
     * @param favored true of the video was favored, false if the video was unfavored.
     */
    videoFavorCallback = (video: IVideo, favored: boolean) => {
        if (!favored) {
            // We add some delay, so that the video doesn't get removed instantly
            setTimeout(() => {
                _.pull(this.videos, video);
            }, 200);
        }
    };

    /**
     * Refreshes video catalog (ion-refresher "ionRefresh" event)
     *
     * @param event ion-refresher event
     */
    doRefresh(event: any) {
        this.logger.debug('Begin refresh operation', event);

        this.offset = 0;
        // this.infiniteScroll.disabled = false;
        if (this.refreshCancelTimeout) {
            clearTimeout(this.refreshCancelTimeout);
            this.refreshCancelTimeout = null;
        }

        this.loadVideoCatalog(false, () => {
            this.logger.debug('Refresh operation has ended');
            event?.target?.complete();
            setTimeout(() => {
                this.updateRefresherProgress(0);
            }, 300);
        });
        this.updateRefresherProgress();
    }

    /**
     * Checks if user should reload
     *
     * @returns True if user should reload, false otherwise
     */
    shouldReload(): boolean {
        if (!this.lastReload) {
            this.logger.debug('shouldReload _lastReload is null');
            return true;
        }
        const diffSeconds = (new Date().getTime() - this.lastReload.getTime()) / 1000;
        this.logger.debug('shouldReload diff seconds %d', diffSeconds);
        const maxDataAgeSeconds = 3600 * 24; // 1 day
        return diffSeconds > maxDataAgeSeconds;
    }

    /**
     * Force reload videos
     */
    forceReloadVideos() {
        this.lastReload = null;
    }

    /**
     * Append videos to the videos array
     *
     * @param newVideos Array of videos to be added
     */
    addVideos(newVideos: IVideo[]) {
        if (!this.videos || this.videos.length === 0) {
            this.logger.debug(`Setting ${newVideos ? newVideos.length : 0} new videos`);
            this.videos = newVideos;
            this.lastReload = new Date();
        } else {
            this.logger.debug(`Adding ${newVideos.length} new videos`);
            this.videos = this.videos.concat(newVideos);
        }
    }

    /**
     * Override function from super class
     */
    getVideos() {
        if (this.isVideosAvailableForGroupSection) {
            return this.videosForCustomer;
        } else {
            return this.videos;
        }
    }

    /**
     * Override function from super class
     */
    navigateBack() {
        if (this.isVideosAvailableForGroupSection) {
            this.navCtrl.navigateBack(`educator-class/details/${this.group_id}`);
        } else {
            this.navCtrl.navigateBack(this.subjectSlug ? 'subjects' : 'catalog');
        }
    }

    get isShowHotTopic() {
        return false;
    }

    activateSearch() {
        this.showSearch = true;
        if (!this.myFavVideos || this.myFavVideos.length === 0) {
            this.myFavVideos = this.videos;
        }
        setTimeout(() => {
            this.search.setFocus();
        }, 500);
    }

    closeSearchBar() {
        this.showSearch = false;
    }

    cancelSearch() {
        this.searchText = null;
        this.onSearchChange();
    }

    onSearchChange() {
        this.offset = 0;

        console.log(`Search text: ${this.searchText}, offset: ${this.offset}`);

        if (!this.searchText) {
            return this.videos = this.myFavVideos;
        }

        this.videos = this.myFavVideos;

        this.videos = this.videos.filter((video) => {
            const matchTitle = video.title && video.title.toLowerCase().match(this.searchText.toLowerCase());
            const matchDescription = video.description && video.description.toLowerCase().match(this.searchText.toLowerCase());
            const matchProgram = video.program && video.program.toLowerCase().match(this.searchText.toLowerCase());

            return matchTitle ||
                   matchDescription ||
                   matchProgram;
        });
    }
}
