import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';

/**
 * Needed for a workaround for https://gitlab.com/uugotitTeam/uugotit-webapp2/-/issues/131
 *
 * How to use: Inherit from this class and call `super(plt)` in `ionViewWillEnter()` and `ionViewWillLeave()`.
 */
export class AbstractRootMenuPage {

    private backButtonSubscription: Subscription;

    constructor(protected plt: Platform) {
        // Do nothing.
    }

    protected creatBackButtonSubscription() {
        this.backButtonSubscription = this.plt.backButton.subscribe(() => {
            navigator['app'].exitApp();
        });
    }

    protected removeBackButtonSubscription() {
        if (this.backButtonSubscription) {
            this.backButtonSubscription.unsubscribe();
        }
    }

    // === Lifecycle methods:

    ionViewWillEnter() {
        this.creatBackButtonSubscription();
    }

    ionViewWillLeave() {
        this.removeBackButtonSubscription();
    }

}
