<div class="wrapper">
    <ion-header>
        <ion-toolbar>
            <ion-buttons slot="end">
                <ion-button fill="clear" (click)='close()' color='primary'>{{'close' | translate}}</ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <ion-list lines="none">
        <ion-item class='ion-text-center padding-inline-end'>
            <ion-label>{{'create_class_complete_onboarding' | translate}}</ion-label>
        </ion-item>
    </ion-list>
</div>
