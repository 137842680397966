// angular
import { Component } from '@angular/core';

// ionic
import { AlertController, ModalController } from '@ionic/angular';

// services
import { AppData } from 'src/app/services/app-data.service';
import { CryptoUtils } from 'src/app/services/crypto-utils.service';
import { AnalyticsService } from 'src/app/services/analytics.service';

// constants
import { Constants } from '../../app.constants';

// libraries
import * as EmailValidator from 'email-validator';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-user-details-prompt',
    templateUrl: 'user-details-prompt-page.html',
    styleUrls: ['user-details-prompt-page.scss']
})
export class UserDetailsPromptPage {
    email = '';
    emailRepeat = '';
    languageLevelDE = 'A1';
    languageLevelEN = 'A1';

    constructor(public alertCtrl: AlertController,
        public modalCtrl: ModalController,
        public appData: AppData,
        public constants: Constants,
        public cryptoUtils: CryptoUtils,
        public analytics: AnalyticsService,
        public translate: TranslateService) {
    }

    /**
     * Called when the save button is clicked
     */
    async buttonSaveClicked(event: any) {
        const values = this.translate.instant([
            'invalid_email',
            'please_enter_valid_email',
            'btn_ok',
            'emails_dont_match',
            'please_enter_email_twice'
        ]);

        if (!EmailValidator.validate(this.email)) {
            const alert = await this.alertCtrl.create({
                header: values.invalid_email,
                subHeader: values.please_enter_valid_email,
                buttons: [values.btn_ok]
            });
            return alert.present();
        }

        if (this.email !== this.emailRepeat) {
            const alert = await this.alertCtrl.create({
                header: values.emails_dont_match,
                subHeader: values.please_enter_email_twice,
                buttons: [values.btn_ok]
            });
            return alert.present();
        }

        this.appData.savePreferenceString(this.constants.pref.USER_EMAIL, this.email);
        this.appData.savePreferenceString(this.constants.pref.USER_LANGUAGE_LEVEL_DE, this.languageLevelDE);
        this.appData.savePreferenceString(this.constants.pref.USER_LANGUAGE_LEVEL_EN, this.languageLevelEN);

        const encryptedEmail = this.cryptoUtils.encodeEmailAdressForAnalytics(this.email);
        this.appData.savePreferenceString(this.constants.pref.USER_EMAIL_ENCRYPTED, encryptedEmail);


        this.analytics.setAnalyticsUserProperties(encryptedEmail, this.languageLevelDE, this.languageLevelEN);

        this.modalCtrl.dismiss();
    }
}
