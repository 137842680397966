import { Injectable } from '@angular/core';
import { IWordCatalog } from 'src/app/interfaces/IWordCatalog';
import { IWord } from 'src/app/interfaces/ITranslation';
import { SavedWord } from 'src/app/models/saved-word.model';
import { Utils } from 'src/app/utils/utils';
import { NGXLogger } from 'ngx-logger';
import { WordCatalogCardArticle, WordCatalogCardDetailsResponse } from 'src/app/models/dtos';
import * as _ from 'lodash';

/*
  Word catalog service.
*/
@Injectable({
    providedIn: 'root',
})
export class WordCatalogHelperService {
    constructor(private logger: NGXLogger) {
        // Doing nothing.
    }

    /**
     * Wraps original word in a <span>
     *
     * @param word The word to be wrapped
     * @returns Returns the sentence with the originalWord wrapped in a <span>
     */
    getSentence(
        word: SavedWord | IWordCatalog,
        details?: WordCatalogCardDetailsResponse,
        cssClassToAdd = 'word-catalog-card-highlight'
    ): string {
        // Try if we have a sentence (comes from the server)
        if ('oriToken' in word && word.oriToken) {
            // this.logger.debug('getSentence using oriToken');
            // Try if we have NLP tokens
            return this.getSentenceByNLPTokens(word, details, cssClassToAdd);
        } else if (word.sentence) {
            // this.logger.debug('getSentence using sentence');
            let regex = Utils.regexWordBoundaryUnicodeInclWhitespace(word.fromWord, 'g');
            const containsFromWord = word.sentence.match(regex);
            // console.log('"' + word.fromWord + '" is contained in sentence: ' + containsFromWord);
            const wordToReplace = containsFromWord ? word.fromWord : word.oriWord;
            regex = Utils.regexWordBoundaryUnicodeInclWhitespace(wordToReplace, 'g');
            const markup = word.sentence.replace(
                regex,
                `$1<span class="${cssClassToAdd}">${wordToReplace}</span>$3`
            );
            if (markup === word.sentence) {
                // Word is not found, fallback to "old" method that oriWords and not the full sentence.
                return this.getSentenceWordByWord(word, cssClassToAdd);
            } else {
                return markup;
            }
        } else {
            this.logger.debug('getSentence legacy');
            // Legacy code (locally stored word catalog)
            return this.getSentenceWordByWord(word, cssClassToAdd);
        }
    }

    /**
     * Wraps original word in a <span>
     *
     * @param word The word to be wrapped
     * @returns Returns the sentence with the originalWord wrapped in a <span>
     */
    getSentenceWordByWord(word: SavedWord | IWordCatalog, cssClassToAdd: string): string {
        if (!word.oriWords || !word.wordIndex) {
            // Backwards compatibility
            if (!word.oriText) {
                return '';
            }

            let sentence = word.oriText;
            let oriWord = word.oriWord;
            if ('oriToken' in word && word.oriToken) {
                oriWord = word.oriToken.text;
            }

            if (!oriWord) {
                return sentence;
            }
            // Words are split, see https://gitlab.com/uugotitTeam/webapp/issues/181#note_175784748
            for (const w of oriWord.split(' ')) {
                const wordSplit = Utils.extractPunctuationAtEndOfWord(w);
                sentence = sentence.replace(
                    w,
                    '<span class="' + cssClassToAdd + '">' + wordSplit[0] + '</span>' + wordSplit[1]
                );
            }

            return sentence;
        }
        let html = '';

        for (let i = 0; i < word.oriWords.length; i++) {
            const wordOrig = word.oriWords[i];
            const wordOrigSplit = Utils.extractPunctuationAtEndOfWord(wordOrig);

            if (i === word.wordIndex) {
                html +=
                    ' <span class="' +
                    cssClassToAdd +
                    '">' +
                    wordOrigSplit[0] +
                    '</span>' +
                    wordOrigSplit[1];
            } else {
                html += ' ' + wordOrig;
            }
        }
        return html;
    }

    getSentenceByNLPTokens(
        word: IWordCatalog,
        details: WordCatalogCardDetailsResponse,
        cssClassToAdd: string
    ): string {
        let subtitleHtml = '';
        const wordOriToken = word.oriToken;
        const wordFromToken = word.fromToken;
        // if (word.fromWord === 'Klimabonus') {
        //     this.logger.debug('Klimabonus');
        // }
        if (!_.isNil(wordOriToken.index) && word.senTransl) {
            word.senTransl.forEach((transl, i) => {
                if (i > 0) {
                    subtitleHtml += ' ';
                }
                transl.oriTok.forEach((oriToken) => {
                    if (
                        wordFromToken.index === oriToken.index ||
                        // eslint-disable-next-line no-extra-parens
                        (wordFromToken.link && wordFromToken.link.indexes.includes(oriToken.index))
                    ) {
                        subtitleHtml += `<span class="${cssClassToAdd}">${oriToken.text}</span>`;
                        if (['NOUN', 'PROPN'].includes(oriToken.pos)) {
                            const lemma = details?.lemma;
                            if (
                                lemma?.fromWord &&
                                lemma?.toWord &&
                                lemma?.genders &&
                                lemma.fromWord !== oriToken.text &&
                                lemma.toWord !== word.toWord
                            ) {
                                subtitleHtml += ` <span class="word-catalog-card-highlight-secondary">(${word.toWord})</span>`;
                            }
                        }
                    } else {
                        subtitleHtml += oriToken.text;
                    }
                    if (oriToken.whitespace) {
                        subtitleHtml += ' ';
                    }
                });
            });
        } else if (word.oriTok) {
            word.oriTok.forEach((oriToken: IWord) => {
                if (
                    !['PUNCT', 'SPACE'].includes(oriToken.pos) &&
                    oriToken.text === wordOriToken.text &&
                    oriToken.pos === wordOriToken.pos
                ) {
                    subtitleHtml += `<span class="${cssClassToAdd}">${oriToken.text}</span>`;
                } else {
                    subtitleHtml += oriToken.text;
                }
                if (oriToken.whitespace) {
                    subtitleHtml += ' ';
                }
            });
        } else if (word.sentence) {
            // This can happen if the translation has been deleted
            return word.sentence;
        } else {
            this.logger.warn(
                `getSentenceByNLPTokens() word with id ${word._id} (${word.oriWord}) doesn't have senTransl, oriTok or sentence`
            );
            return '';
        }
        return subtitleHtml;
    }

    getFromWord(
        word: SavedWord | IWordCatalog,
        wordCardDetails: Map<string, WordCatalogCardDetailsResponse>
    ): string {
        const overrideFromAndToWord = this.getOverrideFromAndToWords(word, wordCardDetails);
        this.logger.debug(`getFromWord() overrideFromAndToWord: ${JSON.stringify(overrideFromAndToWord)}`);
        if (!_.isNil(overrideFromAndToWord?.fromWord)) {
            return overrideFromAndToWord.fromWord;
        }
        if (word.fromToken) {
            return word.fromToken.trText || word.fromToken.text;
        }
        return word.fromWord || word.oriWord;
    }

    /**
     * Returns an object if the fromWord (and/or toWord) are overridden.
     **/
    getOverrideFromAndToWords(
        word: SavedWord | IWordCatalog,
        wordCardDetails: Map<string, WordCatalogCardDetailsResponse>
    ): { fromWord: string; toWord: string; } {
        const pos = word?.fromToken?.trPos || word?.fromToken?.pos;
        if (pos && word._id && ['NOUN', 'PROPN'].includes(pos)) {
            const details = wordCardDetails.get(word._id);
            const lemma = details?.lemma;
            const lemma_en = details?.lemmaLangs?.en;
            // const morph = word.fromToken.trMorph || word.fromToken.morph;
            let article: string;
            let fromWord = word.fromWord;
            let toWord = word.toWord;
            let genders: WordCatalogCardArticle[];
            // if (morph && morph.Gender) {
            //     // this.logger.debug(`getOverrideFromAndToWords() word: ${word.fromWord}, morph: ${JSON.stringify(morph)}`);
            //     article = `${this.genderToArticle(morph.Gender)} `;
            // }
            if (lemma?.fromWord) {
                genders = lemma.genders;
                fromWord = lemma.fromWord;
                if (lemma.toWord) {
                    toWord = lemma.toWord;
                }
            } else if (lemma_en?.fromWord) {
                // Fallback to English
                genders = lemma_en.genders;
                fromWord = lemma_en.fromWord;
            }
            if (fromWord && genders) {
                article = `${genders
                    .map((gender) => gender.article)
                    .join(' / ')} `;
                let suffix = '';
                if (genders.map(gender => gender.short).includes('pl')) {
                    suffix = ` (${genders
                        .map((gender) => `${gender.short === 'pl' ? 'pl' : 'sg'}.`)
                        .join('/')})`;
                }
                return {
                    fromWord: `${article ? article : ''}${fromWord}${suffix}`,
                    toWord,
                };
            }
        }
        return null;
    }

    genderToArticle(gender: string) {
        if (!gender) {
            return '';
        }
        return gender.split(',').map(genderSplit => {
            switch (genderSplit) {
                case 'Masc':
                    return 'der';
                case 'Fem':
                    return 'die';
                case 'Neut':
                    return 'das';
                default:
                    return '';
            }
        }).join(' / ');
    }
}
