import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';

import { Api } from './api.service';
import { ISingleObjectResponse } from '../../models/dtos';
import { IUser } from '../../interfaces/IUser';
import { InviteActions } from '../../interfaces/IInvitesToGroups';
import { IInviteGroup } from '../../interfaces/IInviteGroup';
import { AppData } from '../app-data.service';

/**
 * API for /educators/
 * See http://spyder3-stage.uugot.it/api-docs/#/educators
 */
@Injectable({
    providedIn: 'root'
})
export class EducatorsApi {
    constructor(public http: HttpClient, public api: Api, public appData: AppData) {
        // Do nothing.
    }

    /**
     * Register a new educator
     */
    register(email: string, firstName?: string, lastName?: string, schoolName?: string, city?: string): Observable<ISingleObjectResponse<any>> {
        const body = {
            email
        } as any;
        if (firstName) {
            body.first_name = firstName;
        }
        if (lastName) {
            body.last_name = lastName;
        }
        if (city) {
            body.city = city;
        }
        if (schoolName) {
            body.schoolName = schoolName;
        }
        return this.api.post('educators/register', body);
    }

    /**
     * Register a new educator
     */
    registerWithPassword(formData, invite: string | null = null): Observable<ISingleObjectResponse<any>> {
        if (invite) {
            return this.api.post('educators/registerWithPassword', formData, { invite, language: this.appData.getLanguage() });
        } else {
            return this.api.post('educators/registerWithPassword', formData, { language: this.appData.getLanguage() });
        }
    }

    /**
     * One educator can invite other educators.
     *
     * @param emails Array of email addresses
     */
    invite(emails: [string]): Observable<{
        results: Array<{
            success: boolean;
            msg: string;
            code: string;
            email: string;
        }>;
    }> {
        const body = {
            emails,
            language: this.appData.getLanguage()
        };
        return this.api.post('educators/invite', body);
    }

    /**
     * One educator can invite other educators to one class/group
     *
     * @param invite
     * @param groupId
     */
    inviteToGroup(invite: { email: string; subjects: string[], name: string, last_name: string }, groupId: string): Observable<{
        success: boolean;
        msg: string;
        code: string;
        email: string;
    }> {
        const body = {
            email: invite.email,
            name: invite.name,
            last_name: invite.last_name,
            subjects: invite.subjects,
            group_id: groupId,
            language: this.appData.getLanguage()
        };
        return this.api.post('educators/invitetogroup', body);
    }

    /**
     * Accept or reject invite
     * @param inviteId
     * @param action
     */
    updateInvite(inviteId: string, action: InviteActions) {
        return this.api.put(`educators/invite/${inviteId}/${action}`, {})
    }

    /**
     * Check whether an invitation is still valid or not.
     * @param inviteId
     */
    isInviteValid(inviteId: string): Observable<{
        success: boolean;
        msg: string;
        invite: IInviteGroup
    }> {
        return this.api.get(`educators/invite/${inviteId}/valid`, {})
    }

    modifyProfile(userProperties: any): Observable<ISingleObjectResponse<IUser>> {
        return this.api.put('educators/profile', userProperties);
    }
}
