import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { GroupsApi } from 'src/app/services/api/groups-api.service';
import { AppData } from 'src/app/services/app-data.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { WordCatalogApi } from 'src/app/services/api/wordcatalog.service';
import { NonBlockingResolver } from 'src/app/utils/non-blocking.resolver';
import { Constants } from 'src/app/app.constants';
import { NGXLogger } from 'ngx-logger';
import { UiUtils } from 'src/app/services/ui-utils.service';

@Injectable({
    providedIn: 'root'
})
export class WordCatalogResolver extends NonBlockingResolver {

    readonly PAGE_SIZE = 50;

    constructor(private _router: Router,
        private _groupsApi: GroupsApi,
        private _appData: AppData,
        private _navCtrl: NavController,
        private _wordCatalogApi: WordCatalogApi,
        private _constants: Constants,
        private _logger: NGXLogger,
        private _uiUtils: UiUtils) {
        super();
    }

    getData(route: ActivatedRouteSnapshot): Observable<any> {
        const extrasState = this._router.getCurrentNavigation().extras.state;
        if (extrasState) {
            // this._logger.debug('WordCatalogResolver got extras state', extrasState);
            return of(extrasState);
        } else {
            this._logger.debug('WordCatalogResolver got NO extras state');
            const class_id = route.params.class_id;

            if (class_id && class_id !== this._constants.GeneralClassId) {
                // Check if class exists for this class_id
                return this._groupsApi.getById(class_id).pipe(catchError(err => {
                    return EMPTY;
                }), mergeMap(res => {
                    if (res.success) {
                        return this._wordCatalogApi.getClassCatalog(class_id, this.PAGE_SIZE, 0).pipe(catchError(err => {
                            this._logger.warn('Error loading word catalog for word catalog', err);
                            return EMPTY;
                        }), mergeMap(response => {
                            return of({ group: res.data, wordCatalog: response.data });
                        }));
                    } else {
                        this._uiUtils.displayToast(res.msg);
                        this._navCtrl.navigateRoot('word-catalog-groups');
                        return EMPTY;
                    }
                }));
            } else {
                if (this._appData.authenticatedUser) {
                    return this._wordCatalogApi.getCatalog(this.PAGE_SIZE, 0).pipe(catchError(err => {
                        this._logger.warn('Error loading word catalog for word catalog', err);
                        return EMPTY;
                    }), mergeMap(response => {
                        return of({ wordCatalog: response.data });
                    }));
                } else {
                    if (this._constants.SaveWordCatalogLocally) {
                        return of({ wordCatalog: this._appData.getWordCatalog() });
                    } else {
                        return this._wordCatalogApi.getCatalog4Public(this._appData.getInstallationId(), this.PAGE_SIZE, 0).pipe(catchError(err => {
                            this._logger.warn('Error loading word catalog for word catalog', err);
                            return EMPTY;
                        }), mergeMap(response => {
                            // this._logger.debug('Got catalog (public)', response);
                            return of({ wordCatalog: response.data });
                        }));
                    }
                }
            }
        }
    }
}
