import { IFavoredVideo } from '../interfaces/IFavoredVideo';
interface IFavoredVideoJSON {
    vid: string; // videoId
    c: Date; // createdAt
    u?: Date; // updatedAat
    e?: Date; // expiryDate
}

export class FavoredVideo implements IFavoredVideo {
    user_id: string;
    video_id: string;
    created_at: Date;
    updated_at?: Date;
    expiryDate: Date; // Used for clearing out watched video information once videos have expired


    constructor(video_id: string, expiryDate?: Date) {
        this.video_id = video_id;
        this.expiryDate = expiryDate;
        this.created_at = new Date();
    }

    isContainedInArray(arr: Array<IFavoredVideo>): IFavoredVideo {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].video_id === this.video_id) {
                return arr[i];
            }
        }
        return null;
    }

    static fromJSONObject(json: IFavoredVideoJSON): IFavoredVideo {
        const favoredVideo = new FavoredVideo(
            json.vid,
            json.e
        );
        favoredVideo.created_at = json.c;
        favoredVideo.updated_at = json.u;
        favoredVideo.expiryDate = json.e;
        return favoredVideo;
    }

    static toJSONObject(f: FavoredVideo): IFavoredVideoJSON {
        return {
            vid: f.video_id,
            c: f.created_at,
            u: f.updated_at,
            e: f.expiryDate
        };
    }
}
