import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { NavController, Platform, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/app.constants';
import { GroupsApi } from 'src/app/services/api/groups-api.service';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from 'src/app/services/app-data.service';
import { AppManager } from 'src/app/services/app-manager.service';
import { AnalyticsService, AnalyticsCategory } from 'src/app/services/analytics.service';
import { ActivityService } from 'src/app/services/activity.service';
import { NGXLogger } from 'ngx-logger';
import { AbstractRootMenuPage } from 'src/app/utils/abstract-root-menu-page';
import { IGroup } from '../../interfaces/IGroup';
import { NavigationExtras, Router } from '@angular/router';

@Component({
    selector: 'app-student-login',
    templateUrl: 'student-login-page.html',
    styleUrls: ['student-login-page.scss']
})
export class StudentLoginPage extends AbstractRootMenuPage {
    public classOpenForm: UntypedFormGroup;
    public submitAttempt = false;
    private classCode = '';

    @ViewChild('classCodeInput') classCodeInput;

    constructor(private formBuilder: UntypedFormBuilder,
        public groupsApi: GroupsApi,
        public uiUtils: UiUtils,
        public translate: TranslateService,
        public appData: AppData,
        public appManager: AppManager,
        public constants: Constants,
        public navCtrl: NavController,
        private analytics: AnalyticsService,
        private activityService: ActivityService,
        private logger: NGXLogger,
        protected plt: Platform,
        private alertCtrl: AlertController,
        private router: Router) {

        super(plt);

        const nav = this.router.getCurrentNavigation();
        this.logger.debug('ClassSelectUserPage getCurrentNavigation.extras', nav ? nav.extras : '-');
        if (nav && nav.extras && nav.extras.state) {
            this.classCode = nav.extras.state.classCode;
        }

        this.classOpenForm = this.formBuilder.group({
            classCode: [this.classCode, [Validators.required]],
            // language: ['', Validators.required]
        });
    }

    ionViewWillEnter() {
        // Educator is not allowed to log in as a student
        if (this.appData.isEducator()) {
            this.navCtrl.navigateRoot('catalog');
        }

        this.appData.activePage = 'student';
        super.ionViewWillEnter();
    }

    ionViewDidEnter() {
        if (this.classCode) {
            // Auto-login
            setTimeout(() => {
                this.openUserSelectPage();
            }, 500);
            this.classCode = '';
        }
    }

    ionViewWillLeave() {
        super.ionViewWillLeave();
    }

    /**
     * Redirects to user select page
     */
    async openUserSelectPage() {
        this.submitAttempt = true;

        if (!this.classOpenForm.valid) {
            this.logger.debug('Form is not valid');
            return;
        }
        const classCode = this.classOpenForm.value.classCode.trim();

        // Load student class (trim white spaces)
        this.groupsApi.getByCode(classCode).subscribe(async response => {
            if (response.success) {
                const group = response.data;
                // Check if class already added
                const groupOpened = (this.appData.groupsForUser || []).find(g => g._id === group._id);
                if (groupOpened) {
                    this.showAlertAlreadyAdded(groupOpened);
                }

                if (this.appData.isLoggedIn()) {

                    // User is already logged in
                    if (group.students.find(u => u._id === this.appData.authenticatedUser._id)) {
                        // User has already joined the class previously
                        this.saveGroupLocallyAndOpenDetails(group);
                        this.translate.get('re_added_class', { class: group.name }).subscribe(i18n => this.uiUtils.displayToast(i18n));
                    } else if (group.customer_id !== this.appData.authenticatedUser.customer_id) {
                        // Class has a different customer than user
                        this.showAlertDifferentCustomer();
                    } else {
                        // Add user to class
                        this.groupsApi
                            .addStudent(classCode)
                            .subscribe(
                                (registerResponse) => {
                                    if (registerResponse.success) {
                                        this.saveGroupLocallyAndOpenDetails(group);
                                        this.translate
                                            .get('you_joined_class', { class: group.name })
                                            .subscribe((i18n) => this.uiUtils.displayToast(i18n));
                                    } else {
                                        this.uiUtils.showErrorAlert(
                                            `Joining the class was not successful (${registerResponse.msg})`
                                        );
                                    }
                                },
                                (err) => {
                                    console.log('addStudent to class error', err);
                                    this.uiUtils.showServerRequestErrorAlert(err);
                                }
                            );
                    }
                } else {
                    // User is not logged in - show "select user" page for the class.
                    // this.appData.studentClass = response.data;
                    // this.appData.classAccessCode = classCode;
                    this.analytics.trackAnalyticsEvent(AnalyticsCategory.FlashCards, 'join', group._id);
                    const navigationExtras: NavigationExtras = {
                        state: {
                            group,
                            classCode,
                        }
                    };
                    this.navCtrl.navigateForward('student-class/select-user', navigationExtras);
                    this.resetForm();
                }
            } else {
                this.uiUtils.showErrorAlert(response.msg);
            }
        }, err => {
            console.warn('Error getting group by code', err);
            this.uiUtils.showServerRequestErrorAlert(err);
        });
    }

    /**
     * Remove all the class that the student is logged in (log out at the user)
     */
    removeAllClasses() {
        this.activityService.uploadLocallyStoredEvents();
        this.appManager.deleteUserInfo();
    }

    saveGroupLocallyAndOpenDetails(group: IGroup) {
        this.appData.groupsForUser.push(group);
        this.appData.addClassId(group._id);

        this.openClassDetails(group);
        this.resetForm();
    }


    /**
     * Opens class details page for student
     *
     * @param group The class to be opened
     */
    openClassDetails(group: IGroup) {
        this.appData.activePage = '';
        this.appData.activeGroupId = group._id;
        this.navCtrl.navigateForward(`student-class/details/${group._id}`);
    }

    resetForm() {
        this.classOpenForm.reset({
            classCode: ''
        });
        this.submitAttempt = false;
    }

    async showAlertAlreadyAdded(groupOpened: IGroup) {
        // Show already added alert
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('already_added_title'),
            subHeader: this.translate.instant('already_added_subtitle'),
            buttons: [
                {
                    text: this.translate.instant('go_to_class'),
                    handler: () => {
                        this.openClassDetails(groupOpened);
                    }
                },
                {
                    text: this.translate.instant('add_another'),
                    role: 'cancel',
                    handler: () => {
                        this.resetForm();
                        setTimeout(() => {
                            this.classCodeInput.setFocus();
                        }, 300);
                    }
                }
            ]
        });
        return alert.present();
    }

    async showAlertDifferentCustomer() {
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('group_different_customer_title'),
            subHeader: this.translate.instant('group_different_customer_subtitle'),
            buttons: [
                {
                    text: this.translate.instant('log_out'),
                    handler: () => {
                        this.appManager.deleteUserInfo();
                        this.openUserSelectPage();
                    }
                },
                {
                    text: this.translate.instant('btn_cancel'),
                    role: 'cancel'
                }
            ]
        });
        return alert.present();
    }
}
