<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-title>Change my password</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="dismiss()" *ngIf="dismissPageEnabled">
                <ion-icon slot="icon-only" name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <form [formGroup]="passwordForm" (ngSubmit)="onSubmitForm()">
        <ion-list lines="full" class="ion-no-margin ion-no-padding">
            <ion-item>
                <ion-label position="floating">Old password <ion-text>*</ion-text>
                </ion-label>
                <app-show-hide-password>
                    <ion-input formControlName="old_password" type="password"  #oldPassword (keyup.enter)="newPassword.setFocus()"></ion-input>
                </app-show-hide-password>
            </ion-item>

            <ion-item>
                <ion-label position="floating">{{'password' | translate}}<ion-text>*</ion-text>
                </ion-label>
                <app-show-hide-password>
                    <ion-input formControlName="new_password" type="password" enterkeyhint="next" inputmode="text" autocapitalize="sentences" maxlength="50" #newPassword (keyup.enter)="repeatPassword.setFocus()"></ion-input>
                </app-show-hide-password>
            </ion-item>

            <ion-item>
                <ion-label position="floating">{{'repeat_password' | translate}}<ion-text>*</ion-text>
                </ion-label>
                <app-show-hide-password>
                    <ion-input formControlName="password_repeat" type="password" enterkeyhint="next" inputmode="text" autocapitalize="sentences" maxlength="50" #repeatPassword></ion-input>
                </app-show-hide-password>
            </ion-item>

        </ion-list>

        <div class="ion-padding">
            <ion-button expand="block" type="submit" class="ion-no-margin"
                [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'"
                [disabled]="passwordForm.pristine || !passwordForm.valid || submitting">
                {{'btn_save' | translate}}
            </ion-button>
        </div>
    </form>
</ion-content>
