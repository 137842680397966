import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, delay, finalize } from 'rxjs/operators';

/**
 * Non blocking resolver service - base class. Inspired by https://ionicthemes.com/tutorials/about/ionic-navigation-and-routing-ultimate-guide
 */
export abstract class NonBlockingResolver implements Resolve<any> {

    // This should be in a separate service
    abstract getData(route: ActivatedRouteSnapshot): Observable<any>;

    resolve(route: ActivatedRouteSnapshot) {
        // Base Observable (where we get data from)
        const dataObservable = this.getData(route);

        // NON-BLOCKING RESOLVERS

        // Resolver using a ReplySubject that emits the base Observable and then completes
        // const subject = new ReplaySubject();
        // subject.next(dataObservable);
        // subject.complete();
        // return subject;

        // Resolver using an Observable that emits the base Observable and then completes
        // const observable = Observable.create((observer) => {
        //   observer.next(dataObservable);
        //   observer.complete();
        // });
        // return observable;

        // Resolver using a Promise that resolves the base Observable
        const observablePromise = new Promise((resolve, reject) => {
            resolve(dataObservable);
        });
        return observablePromise;
    }
}
