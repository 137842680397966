import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { AlertController, NavController, Platform } from '@ionic/angular';
import { Constants } from '../../app.constants';
import { TranslateService } from '@ngx-translate/core';
import { IGroup } from '../../interfaces/IGroup';
import { IVideo } from '../../interfaces/IVideo';
import { IGroupVideo } from '../../interfaces/IGroupVideo';
import { GroupsApi } from 'src/app/services/api/groups-api.service';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from 'src/app/services/app-data.service';
import { AppManager } from 'src/app/services/app-manager.service';
import { AssessmentsApi } from 'src/app/services/api/assessments-api.service';
import { AnalyticsService, AnalyticsCategory } from 'src/app/services/analytics.service';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { StudentListVideoGroupResolver } from './class-details-list-videos-resolver.service';
import { Utils } from 'src/app/utils/utils';
import { AbstractRootMenuPage } from 'src/app/utils/abstract-root-menu-page';
import { NGXLogger } from 'ngx-logger';
import { WatchedVideoApi } from '../../services/api/watched-videos-api.service';
import { WatchedVideo } from 'src/app/models/watched-video';
import { IClip, ILeaderboardEntry } from 'src/app/interfaces/IClip';
import * as _ from 'lodash';
import { ILeaderboard } from '../../interfaces/IClip';
import { ITile } from '../../interfaces/ITile';
import { ISubject } from '../../interfaces/ISubject';

@Component({
    selector: 'app-class-details-student',
    templateUrl: 'class-details-list-videos-student-page.html',
    styleUrls: ['class-details-list-videos-page.scss']
})
export class ClassDetailsListVideosStudentPage extends AbstractRootMenuPage implements OnInit {
    public group: IGroup;
    public classVideosInfo: IVideo[] = [];
    public classVideos: IGroupVideo[] = [];
    public available = true;
    protected watchedVideos: WatchedVideo[] = [];
    public tiles: ITile[] = null;
    public language: string;
    private taskResultDetailsShown: Map<string, boolean> = new Map();
    public studentName: string;
    public subject: ISubject;
    constructor(private _route: ActivatedRoute,
        public navCtrl: NavController,
        public groupsApi: GroupsApi,
        public watchedVideosApi: WatchedVideoApi,
        public uiUtils: UiUtils,
        public appData: AppData,
        public constants: Constants,
        public translate: TranslateService,
        public alertCtrl: AlertController,
        public appManager: AppManager,
        public assessmentsApi: AssessmentsApi,
        private analytics: AnalyticsService,
        private studentGroupResolver: StudentListVideoGroupResolver,
        private titleService: Title,
        private logger: NGXLogger,
        protected plt: Platform) {
        super(plt);
    }

    ngOnInit() {
        this.titleService.setTitle('Class - Student | uugot.it');
        this.analytics.trackPageView('flash-cards');
        this.language = this.appData.getLanguage();
    }

    ionViewWillEnter() {
        super.ionViewWillEnter();
        const class_id = this._route.snapshot.params.class_id;
        this.appData.activePage = '';
        this.appData.activeGroupId = class_id;

        this._route.snapshot.data.resData.subscribe(data => {
            this.group = data.group;
            this.subject = data.subject;
            this.classVideos = data.videos;

            this.classVideosInfo = this.classVideos.map(video => video.video_id);
            this.loadWatchedVideos();
            this.loadTiles();
            this.studentName = this.appData.authenticatedUser.first_name;
        });
    }

    ionViewWillLeave() {
        super.ionViewWillLeave();
    }

    /**
     * Returns the number of unwatched videos in a group.
     * The groups must have an array of IGroupVideos at the `videos` property.
     * @param group the class
     */
    getUnwatchedVideosCount(): number {
        const group = this.appData.groupsForUser.find(group => group._id === this.group._id);
        // this.logger.debug('getUnwatchedVideosCount group', group._id);
        console.log('this.group ', group)
        if (group.videos) {
            const watchedVideos = this.appData.getWatchedVideos();
            console.log('WATCHED VIDEOS ', watchedVideos)
            if (!watchedVideos) {
                return 0;
            }
            const count = group.videos.filter(video => {
                // Return true if video from group is not contained in watched videos
                return !watchedVideos.find(w => {
                    return w.videoId === video.video_id._id && w.watchedUntil > 10;
                });
            }).length;
            // this.logger.debug('getUnwatchedVideosCount count and videos', count, watchedVideos);
            return count;
        } else {
            return 0;
        }
    }

    /**
     * Displays an alert to the user if the class has expired
     */
    async showExpiredAlert() {
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('class_expired_title'),
            subHeader: this.translate.instant('class_expired_subtitle'),
            buttons: [
                {
                    text: this.translate.instant('btn_cancel'),
                    role: 'cancel'
                },
                {
                    text: this.translate.instant('btn_remove_class'),
                    handler: () => {
                        // Redirect to student login
                        this.navCtrl.navigateRoot('student');
                    }
                }
            ]
        });
        await alert.present();
    }

    async loadData() {
        return this.studentGroupResolver.getData(this._route.snapshot).toPromise().then(data => {
            this.group = data.group;
            this.classVideos = data.videos;
            this.classVideosInfo = this.classVideos.map(video => video.video_id);
            // return Promise.resolve();
            return this.loadWatchedVideos();
        });
    }

    async loadWatchedVideos(): Promise<void> {
        const videoIds =  this.classVideos.map(v => v.video_id._id);
        const watchedVideos = await this.watchedVideosApi.listByVideos(videoIds).toPromise();
        this.watchedVideos = watchedVideos.data?.map(wv => {
            const w = new WatchedVideo(wv.video_id, wv.watchedUntil, wv.expiryDate, null, null, wv.clip_id);
            return w;
        }) || [];
    }

    /**
     * Opens video player page for specific video
     *
     * @param videoInfo The videoInfo for the video to be opened
     */
    openVideoPlayerPage(videoInfo: IVideo, clip: IClip, groupVideo: IGroupVideo, showLeaderboard = false) {
        if (videoInfo) {
            const state: any = {
                showLeaderboard
            };

            if (this.isAlreadyWatched(videoInfo._id, clip)) {
                // const watchedVideo = this.appData.getWatchedVideo(videoInfo._id);
                const watchedVideo = this.watchedVideos.find(wv => wv.videoId === videoInfo._id);
                const duration = Utils.getDurationOfVideoInSeconds(videoInfo);
                if (
                    duration &&
                    watchedVideo &&
                    watchedVideo.watchedUntil &&
                    watchedVideo.watchedUntil > this.constants.VideoWatchedThresholdSeconds &&
                    watchedVideo.watchedUntil < duration - this.constants.VideoWatchedThresholdSeconds
                ) {
                    state.initialTimeInVideo = watchedVideo.watchedUntil;
                    state.showToastText = this.translate.instant('resuming_video_at', {
                        time: this.uiUtils.secondsToTimeString(watchedVideo.watchedUntil),
                    });
                }
            }

            const navigationExtras: NavigationExtras = {
                state
            };
            if (clip) {
                this.navCtrl.navigateForward(`video/${videoInfo.websource_id}/${videoInfo._id}/${this.group._id}/clip/${clip._id}`, navigationExtras);
            } else {
                this.navCtrl.navigateForward(`video/${videoInfo.websource_id}/${videoInfo._id}/${this.group._id}`, navigationExtras);
            }
        } else {
            this.uiUtils.showErrorAlert(this.translate.instant('video_not_found'));
        }
    }

    /**
     * Gets the translations for a specific video as a string
     *
     * @param videoInfo The videoInfo of the video for which translations are needed
     * @return The translations of a video
     */
    getTranslations(videoInfo: IVideo): string {
        return videoInfo ? this.uiUtils.translationsToText(videoInfo.translations, videoInfo.originalLang) : '';
    }

    /**
     * Deletes a class from the local storage
     */
    async deleteClass() {
        // Show confirmation message
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('confirm_class_delete_title'),
            subHeader: this.translate.instant('confirm_class_delete_subtitle'),
            buttons: [
                {
                    text: this.translate.instant('btn_cancel'),
                    role: 'cancel'
                },
                {
                    text: this.translate.instant('btn_ok'),
                    handler: () => {
                        this.analytics.trackAnalyticsEvent(AnalyticsCategory.Class, 'remove', this.group._id);

                        if (this.appData.getClassIds().length <= 1) {
                            // Log user out
                            this.appManager.deleteUserInfo();
                        } else {
                            // remove class
                            this.appData.removeClassId(this.group._id);
                            if (this.appData.groupsForUser) {
                                this.appData.groupsForUser = this.appData.groupsForUser.filter(g => g._id !== this.group._id);
                            }
                        }

                        // Redirect to student login
                        this.navCtrl.navigateRoot('student');
                    }
                }
            ]
        });
        await alert.present();
    }

    async refreshContent() {
        this.classVideos = [];
        this.classVideosInfo = [];
        await this.loadData();
        this.translate.get('group_videos_were_reloaded').subscribe(i18n => {
            this.uiUtils.displayToast(i18n);
        });
    }

    /**
     * Returns true if video with videoId is watched, false otherwise
     *
     * @param videoId The video ID of the video
     */
    isAlreadyWatched(videoId: string, clip?: IClip): boolean {
        const watched = this.watchedVideos.find(w => w.videoId === videoId && (w.clipId === clip?._id || _.isNil(w.clipId) && _.isNil(clip?._id)));
        return !!watched;
    }

    /**
     * Returns 0 if a video has not been watched yet, otherwise something like 26 or 100 to indicate the %.
     * @param video the video
     */
    getProgressForWatchedVideo(video: IVideo, clip?: IClip): number {
        // this.logger.debug('progress for ', clip?.title || video.title, clip?._id);
        const watched = this.watchedVideos.find(
            (w) => w.videoId === video._id && (w.clipId === clip?._id || _.isNil(w.clipId) && _.isNil(clip?._id))
        );
        // this.logger.debug('progress for ', clip?.title || video.title, watched);
        if (!watched || !watched.watchedUntil || watched.watchedUntil < this.constants.VideoWatchedThresholdSeconds) {
            return 0;
        }
        const duration = clip ? clip.duration : Utils.getDurationOfVideoInSeconds(video);
        const percent = Math.ceil((watched.watchedUntil || 0) / duration * 100);
        // this.logger.debug('progress for (%) ', video.title, percent);
        return percent;
    }

    isVideoExpired(video: IVideo): boolean {
        return Utils.isVideoExpired(video);
    }

    openLeaderboardPage(): void {
        this.navCtrl.navigateForward(`class/${this.group._id}/leaderboard`);
    }

    getLeaderboardEntryForUser(clip: IClip): ILeaderboardEntry {
        return clip.leaderboard?.entries.find(e => e.user_id === this.appData.authenticatedUser?._id);
    }

    getLeaderboardScoreColorForClip(clip: IClip): string {
        const entry = this.getLeaderboardEntryForUser(clip);
        if (!entry) {
            return 'no_color';
        }
        return this.getLeaderboardScoreColor(entry.score);
    }

    getLeaderboardScoreColor(score: number): string {
        if (score >= 85) {
            return 'green';
        } else if (score >= 65) {
            return 'yellow';
        } else if (score >= 50) {
            return 'orange';
        } else if (score < 50) {
            return 'red';
        } else {
            return '';
        }
    }

    showClipTaskResultDetails(groupVideo: IGroupVideo) {
        this.taskResultDetailsShown.set(groupVideo._id, true);
    }

    hideClipTaskResultDetails(groupVideo: IGroupVideo) {
        this.taskResultDetailsShown.delete(groupVideo._id);
    }

    isClipTaskResultDetailsShown(groupVideo: IGroupVideo): boolean {
        return this.taskResultDetailsShown.get(groupVideo._id);
    }

    /**
     * Returns true of the current page is a root page in terms of navigation hierarchy.
     */
    get isRootPage(): boolean {
        return false;
    }

    navigateBack() {
            this.navCtrl.navigateBack(`student-class/details/${this.group._id}`);
    }

    getClipTaskResultText(groupVideo: IGroupVideo): {image: string; headline: string; text: string;} {
        const clip = groupVideo.clip_id as any as IClip;
        const leaderboard = clip.leaderboard;
        if (!leaderboard) {
            return null;
        }
        const myEntry = this.getLeaderboardEntryForUser(clip);
        if (!myEntry) {
            return null;
        }
        const isWorldclass = myEntry.score === 100;
        const isClassBest = myEntry.pos === 1 && myEntry.score < 100;
        const isWellDone = !isWorldclass && !isClassBest && myEntry.score >= 50;
        const isLessThanHalf = !isWorldclass && !isClassBest && !isWellDone;

        const missingScore =
            groupVideo.userTaskScore?.bestInLeaderboard?.result?.score - myEntry.score;

        if (isWorldclass) {
            return {
                image: 'assets/img/yuyu_yellow_realize.png',
                headline: this.translate.instant('tasks_result_worldclass_header'),
                text: this.translate.instant('tasks_result_worldclass_text'),
            };
        } else if (isClassBest) {
            return {
                image: 'assets/img/yuyu_yellow_realize.png',
                headline: this.translate.instant('tasks_result_classbest_header'),
                text: this.translate.instant('tasks_result_classbest_text'),
            };
        } else if (isWellDone) {
            return {
                image: 'assets/icon/launcher-icon-3x.png',
                headline: this.translate.instant('tasks_result_welldone_header'),
                text: this.translate.instant('tasks_result_welldone_text', { missingScore }),
            };
        } else if (isLessThanHalf) {
            return {
                image: 'assets/img/yuyu_yellow_exclamation_sign.png',
                headline: this.translate.instant('tasks_result_lessthanhalf_header'),
                text: this.translate.instant('tasks_result_lessthanhalf_text', { missingScore }),
            };
        }
    }

    getNameEllipsized(firstName: string, lastName: string, capitalizeLastName = true, maxChars = 25) {
        const useFirstName = firstName || '';
        let useLastName = lastName || '';
        if (capitalizeLastName) {
            useLastName = lastName.toUpperCase();
        }
        let name = `${useFirstName} ${useLastName}`;
        if (name.length > maxChars) {
            name = name.substring(0, maxChars - 3) + '...';
        }
        return name;
    }

    /**
     * Returns true if the user is contained in the leaderboard.
     * @param leaderboard the leaderboard
     * @returns
     */
    isUserInLeaderboard(leaderboard: ILeaderboard): ILeaderboardEntry {
        return leaderboard.entries?.find(entry => entry.user_id === this.appData.authenticatedUser?._id);
    }

    private loadTiles() {
        this.groupsApi.getTilesFromGroupId(this.group._id).subscribe((tiles: ITile[]) => {
            if (tiles && tiles.length > 0) {
                const watchedVideos = this.appData.getWatchedVideos();
                this.tiles = tiles.map((tile: ITile) => {
                    const hasPendingVideos = tile.videoIds.some(videoId => {
                        return !watchedVideos.find(w => w.videoId === videoId && w.watchedUntil > 10);
                    });
                    return { ...tile, hasPendingVideos };
                });
            }
        });
    }

    getPendingTasks() {
    }

    getSubjectName(): string {
        if (this.subject && this.subject[this.language]) {
            return this.subject[this.language];
        }
    }
}
