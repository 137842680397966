<ion-header>
    <ion-toolbar color="blue">
        <ion-buttons slot="start">
            <ion-button (click)="navigateBack()">
                <ion-icon slot="icon-only" name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>{{ (group ? "select_worksheet" : "add_assessment") | translate }}</ion-title>
        <ion-buttons slot="end">
            <ion-button *ngIf="plt.is('mobile') && group" (click)="addAssessmentToVideo()"
                [disabled]="!this.selectedAssessment" title="{{ 'btn_save' | translate }}">
                <ion-icon slot="icon-only" name="checkmark"></ion-icon>
            </ion-button>
            <ion-button *ngIf="plt.is('mobile') && !group" (click)="addVideoToClass(video?._id)"
                [disabled]="!this.selectedAssessment" title="{{ 'tooltip_add_video_to_group' | translate }}">
                <ion-icon slot="icon-only" name="add-circle"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center">
    <ion-progress-bar *ngIf="!videoAssessments" type="indeterminate"></ion-progress-bar>
    <ion-grid fixed>
        <ion-item class="ion-text-center" lines="none" *ngIf="!videoAssessments">
            <ion-label>&nbsp;</ion-label>
        </ion-item>
        <ion-item class="ion-text-center" lines="none" *ngIf="videoAssessments && !videoAssessments.length">
            <ion-label>{{ "no_worksheets" | translate }}</ion-label>
        </ion-item>
        <ion-radio-group [(ngModel)]="selectedAssessment">
            <div *ngFor="let assessment of videoAssessments" class="assessment">
                <ion-row>
                    <ion-col size="8">
                        <ion-item lines="none" >
                            <ion-radio [value]="assessment" slot="start"></ion-radio>
                            <ion-label>
                                <div>
                                    {{ assessment.name }}
                                </div>
                                <div class="assessment-info">
                                    <span class="assessment-level">{{ "level" | translate }}:</span>
                                    {{assessment.level}}
                                    <span *ngIf="assessment.visib === 1" class="assessment-visibility-chip">{{ 'privat' | translate }}</span>
                                </div>
                            </ion-label>
                        </ion-item>
                    </ion-col>
                    <ion-col size="4">
                        <ion-button color="blue" fill="clear" (click)="$event.stopPropagation()">
                            <a [href]="assessment.testFileUrl" target="_blank">
                                <ion-icon color="blue" class="download-center" name="download"></ion-icon>
                                <ion-text color="blue" class="download-center">{{ "task" | translate }}</ion-text>
                            </a>
                        </ion-button>
                        <ion-button color="blue" fill="clear" (click)="$event.stopPropagation()" [style.visibility]="assessment.solutionFileUrl ? 'visible' : 'hidden'">
                            <a [href]="assessment.solutionFileUrl" target="_blank">
                                <ion-icon color="blue" class="download-center" name="download"></ion-icon>
                                <ion-text color="blue" class="download-center">{{ "solution" | translate }}</ion-text>
                            </a>
                        </ion-button>
                    </ion-col>
                </ion-row>
            </div>
        </ion-radio-group>
    </ion-grid>

    <div *ngIf="group; else elseNoGroup">
        <p *ngIf="videoAssessments && videoAssessments.length && !plt.is('mobile')">
            <ion-button color="blue" (click)="addAssessmentToVideo()" [disabled]="!this.selectedAssessment">
                <ion-icon slot="start" name="checkmark"></ion-icon>
                {{ "btn_save" | translate }}
            </ion-button>
        </p>
        <p>
            <ion-button color="light" (click)="openWorksheetUploadPage()">
                <ion-icon slot="start" name="cloud-upload-outline"></ion-icon>
                {{ "worksheet_upload" | translate }}
            </ion-button>
        </p>

        <!-- <div class="download-template-btn ion-margin-top ion-margin-bottom">
            <a href="https://static-assets-cdn.uugot.it/documents/sCOOLing/Formblatt_Didaktisierungen_zum_Ausfuellen.docx" target="_blank">
                <ion-icon color="blue" class="download-center" name="download"></ion-icon>
                <ion-text color="blue" class="download-center"><b>&nbsp;&nbsp;{{ "download_template" | translate }}</b>
                </ion-text>
            </a>
        </div> -->
        <p *ngIf="group">
            {{ 'select_worksheet_infotext_1' | translate }}
            <a [routerLink]="['/help']">{{ 'help_section' | translate }}</a>
            {{ 'select_worksheet_infotext_2' | translate }}
        </p>
    </div>

    <ng-template #elseNoGroup>
        <div class="add-to-class-container ion-text-left"
            *ngIf="video && appData.isEducator() && !plt.is('mobile')">
            <div *ngIf="selectedAssessment; else elseNoAssessmentSelected">
                <div class="add-circle-btn ion-text-left">
                    <ion-icon color="blue" name="add-circle" class="icon-add-circle"
                        (click)="addVideoToClass(video?._id);"></ion-icon>
                </div>
                <div class="add-to-class-text">
                    {{ 'tooltip_add_video_to_group' | translate }}
                </div>
            </div>
            <ng-template #elseNoAssessmentSelected>
                <div class="add-to-class-disabled">
                    <div class="add-circle-btn ion-text-left">
                        <ion-icon name="add-circle" class="icon-add-circle"
                            (click)="addVideoToClass(video?._id);"></ion-icon>
                    </div>
                    <div class="add-to-class-text">
                        {{ 'tooltip_add_video_to_group' | translate }}
                    </div>
                </div>
            </ng-template>

        </div>
    </ng-template>
</ion-content>
