/**
 * A task to solve for a user (multiple choice question for example).
 */
export interface ITask {
    _id?: string;
    __v?: number;
    created_at?: Date;
    updated_at?: Date;

    video_id: string;
    createdBy: string; // ObjectId of the user by which the task was created
    type: ETaskType; // 1 = multiple choice, 2 = survey, 3 = free text
    title: string; // Max. length: 120
    question?: string;
    answers?: ITaskAnswerOption[];
    position: number; // time in the video, in seconds (this is always the time based on the original video, without any cropping)
    timeLimit?: number; // time limit to answer the question, in seconds
    showFeedback: boolean; // true if user should see whether he/she answered correctly
    level: string[]; // language level, “A”, “B”, “C”, or “X” (= independent)
    age?: ETaskAgeRange[]; // Task age range (1 = up to 10 y; 2 = 10-15 y, 3 = 15+ y)
    eduLevel?: ETaskEduLevel[]; // Task level of education (1 = primary, 2 = secoondary 1, 3 = secondary 2, 4 = higher)
    visib?: number; // (null/0 = only in class/clip, 1 = public = visible in public videos)
}

export interface ITaskAnswerOption {
    _id?: string;
    order: number;
    text: string; // Max. length: 60
    isCorrect: boolean;
}

/**
 * Task type (for example: multiple choice, free text, ...)
 */
export enum ETaskType {
    multiple_choice = 1,
    survey = 2,
    free_text = 3,
}

/**
 * Task age range (1 = up to 10 y; 2 = 10-15 y, 3 = 15+ y)
 */
export enum ETaskAgeRange {
    range_up_to_10 = 1,
    range_10_to_15 = 2,
    range_15_and_higher = 3,
}

/**
 * Task level of education (1 = primary, 2 = secoondary 1, 3 = secondary 2, 4 = higher)
 */
export enum ETaskEduLevel {
    primary = 1,
    secondary_1 = 2,
    secondary_2 = 3,
    higher = 4,
}
