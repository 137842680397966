<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-menu-toggle>
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
        <ion-title>{{ "welcome_to_uugot.it_sCOOLing" | translate }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center">
    <swiper-container
        [pagination]='pagination'
        space-between="30"
        class=''
        #swiper >
<!--        step 0-->
        <swiper-slide class="ion-padding">
            <ion-row class='centered-row ion-justify-content-center'>
                <ion-col>
                    <div class='slide-desktop-container'>
                        <ion-img src="assets/img/yuyu_yellow_exclamation_sign.png"></ion-img>
                        <h2>
                            {{ 'salute_unverified' | translate:{first_name:appData.authenticatedUser.first_name} }}
                            <br>
                        </h2>
                    </div>
                </ion-col>
            </ion-row>
        </swiper-slide>
        <!--        step 1-->
        <swiper-slide class="ion-padding">
            <ion-row class='centered-row ion-justify-content-center'>
                <ion-col>
                    <div class='slide-desktop-container'>
                        <ion-img  *ngIf='systemLanguageName ==="de"'  src="assets/img/tutorials/S01_de.jpg"></ion-img>
                        <ion-img *ngIf='systemLanguageName !== "de"' style='height: 420px' src="assets/img/tutorials/S01_en.png"></ion-img>
                        <ion-text>
                            <h2>
                                {{ 'language_barrierfree_title' | translate }}
                            </h2>
                        </ion-text>
                        <ion-text class='ion-padding ion-margin-bottom'>
                            {{ 'language_barrierfree_desc' | translate }}
                        </ion-text>
                    </div>
                </ion-col>
            </ion-row>
        </swiper-slide>
    </swiper-container>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button [disabled]='disablePreviousButton()' (click)='previous()'>{{ 'previous' | translate }}</ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
            <ion-button (click)='next()' [disabled]='disableNext || submitting'>
                <ion-spinner *ngIf="submitting"></ion-spinner>
                <span *ngIf="!submitting">{{ getTextForNextButton() }}</span>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
