import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { AppComponent } from 'src/app/app.component';
import { IGroup } from 'src/app/interfaces/IGroup';
import { AppData } from 'src/app/services/app-data.service';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { ILeaderboard, ILeaderboardEntry } from '../../interfaces/IClip';

@Component({
    selector: 'app-class-leaderboard',
    templateUrl: './class-leaderboard.page.html',
    styleUrls: ['./class-leaderboard.page.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ClassLeaderboardPage implements OnInit {
    public group: IGroup;
    public leaderboard: ILeaderboard;

    momentShow: moment.Moment;

    detectChangesInterval: ReturnType<typeof setInterval>;

    /**
     * Show only the first number of entries.
     */
    public readonly showFirstXEntries = 500;

    /**
     * How amny entries are being animated (max.)
     */
    public readonly animateFirstXEntries = 20;

    private readonly intervalMs = 350;

    constructor(
        private _route: ActivatedRoute,
        private appComponent: AppComponent,
        private navCtrl: NavController,
        public uiUtils: UiUtils,
        public translate: TranslateService,
        private logger: NGXLogger,
        public appData: AppData,
        private cdref: ChangeDetectorRef
    ) {
        // Doing nothing.
    }

    ngOnInit() {
        // Doing nothing.
    }

    ionViewWillEnter() {
        const class_id = this._route.snapshot.params.class_id;

        this._route.snapshot.data.resData.subscribe((data) => {
            this.leaderboard = data.leaderboardResponse.leaderboard;
            this.logger.debug('LEADERBOARD', this.leaderboard);
            this.group = data.group;
            this.startAnimation();
        });
    }

    ngAfterContentChecked() {
        // this.logger.debug('ngAfterContentChecked');
        this.cdref.detectChanges();
    }

    startAnimation() {
        this.momentShow = moment();
        this.detectChangesInterval = setInterval(() => {
            // this.logger.debug('***** INTERVAL');
            this.leaderboard?.entries?.forEach((entry) => {
                // We just set a property on the entry
                const isEntryShown = this.isEntryShown(entry);
                if (!entry['isEntryShown'] && isEntryShown) {
                    this.logger.debug(`*** Show entry with position ${entry.pos}`);
                }
                entry['isEntryShown'] = isEntryShown;
            });
            this.cdref.detectChanges();
            if (
                moment().diff(this.momentShow, 'milliseconds') >
                this.intervalMs * this.maxShownEntries
            ) {
                this.logger.debug('***** CLEAR INTERVAL');
                clearInterval(this.detectChangesInterval);
            }
        }, 100);
    }

    /**
     * Navigates back to the class details page
     */
    navigateBack() {
        const canGoBack = this.appComponent.routerOutlet.canGoBack();

        if (canGoBack) {
            // this.appComponent.routerOutlet.pop();
            this.navCtrl.pop();
        } else if (this.group) {
            this.navCtrl.navigateBack(`educator-class/details/${this.group._id}`);
        } else {
            this.navCtrl.navigateRoot('/catalog/all');
        }
    }

    /**
     * Either maximum of shown entries (this.showFirstXEntries) or number of entries, if less.
     */
    get maxShownEntries(): number {
        return Math.min(this.showFirstXEntries, this.leaderboard?.entries?.length || 0);
    }

    /**
     * @param entry the leaderboard entry
     * @returns true if it's the authenticated users's own rank
     */
    isMyOwnRank(entry: ILeaderboardEntry): boolean {
        return entry.user_id === this.appData.authenticatedUser?._id;
    }

    /**
     * Needed for the animation
     * @param entry
     * @returns
     */
    isEntryShown(entry: ILeaderboardEntry): boolean {
        const delta = moment().diff(this.momentShow, 'milliseconds');
        const maxAnimateEntries = Math.min(this.animateFirstXEntries, this.maxShownEntries);
        const pos = this.leaderboard.entries.indexOf(entry) + 1;
        const showAt = (maxAnimateEntries - pos) * this.intervalMs;
        const show = showAt < delta;
        // this.logger.debug('showAt', showAt, delta, show);
        return show;
    }

    isTableHeaderShown() {
        if (!this.leaderboard) {
            return false;
        }
        const delta = moment().diff(this.momentShow, 'milliseconds');
        const maxAnimateEntries = Math.min(this.animateFirstXEntries, this.maxShownEntries);
        const showAt = maxAnimateEntries * this.intervalMs;
        const show = showAt < delta;
        // this.logger.debug('showAt', showAt, delta, show);
        return show;
    }

    /**
     * Returns the color for an entry based on it's position (see https://app.box.com/file/895152691016).
     * @param entry the leaderboard entry
     * @returns green, yellow, orange, or red
     */
    getColorForEntry(entry: ILeaderboardEntry, allEntries: ILeaderboardEntry[]): string {
        if (entry.pos <= 3) {
            return 'green';
        } else if (entry.pos >= 4 && entry.pos <= 7) {
            return 'yellow';
        } else if (entry.pos >= 8 && entry.pos <= allEntries.length - 1) {
            return 'orange';
        } else {
            // Last position
            return 'red';
        }
    }
}
