import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Constants } from '../app.constants';
import { AppData } from 'src/app/services/app-data.service';

/**
 * Intercept every HTTP request and sets the 'Accept-Language' http header.
 */
@Injectable()
export class LanguageHeaderInterceptor implements HttpInterceptor {
    constructor(public appData: AppData, public constants: Constants) {
        // Doing nothing.
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const newReq = req.clone({
            headers: req.headers.set('Accept-Language', this.appData.getLanguage())
        });
        return next.handle(newReq);
    }
}
