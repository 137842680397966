import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from './api.service';
import { ISubject } from 'src/app/interfaces/ISubject';
import { ISubjectsListResponse, ISingleObjectResponse } from 'src/app/models/dtos';

/**
 * API for /subjects/
 * See http://localhost:3005/api-docs/#/subjects
 */
@Injectable({
    providedIn: 'root'
})
export class SubjectsApi {
    constructor(public api: Api) {
        // Do nothing.
    }

    /**
     * Retrieve all available subjects
     * @param visibility // 0 = will NOT appear in the app, 1 = will appear in the app
     */
    getSubjects(offset?: number, limit?: number, sort = '_id', country?: string, visibility?: 0 | 1): Observable<ISubjectsListResponse> {
        const params = {
            offset,
            limit,
            sort
        };

        if (country !== undefined && country !== '') {
            params['country'] = country;
        }
        if (visibility !== undefined) {
            params['visibility'] = visibility;
        }
        return this.api.get('subjects', params);
    }


    /**
     * Returns a specific subject by id, or an error if not found
     *
     * @param id required id to search for (can be slug as well)
     */
    getById(id: string): Observable<ISingleObjectResponse<ISubject>> {
        return this.api.get(`subjects/${id}`);
    }

    // /**
    //  * Loads the video catalog from the API.
    //  *
    //  * @param sourceId - for example 'orftvthek'
    //  * @param offset - offset for paging
    //  * @param limit - Number of videos to retrieve per call.
    //  * @param translation - language code. For example 'en'
    //  * @deprecated
    //  */
    // getVideoCatalog(sourceId: string, offset = 0, limit = 20, translation: string): Observable<VideosListAvailableResponse> {
    //     const params = {
    //         offset,
    //         limit,
    //         sort: '-publishDate'
    //     };

    //     if (translation !== undefined && translation !== '') {
    //         params['translation'] = translation;
    //     }

    //     return this.api.get(`videos/list/available/${sourceId}`, params);
    // }
}
