<!-- Show the "2 days still available" flap: -->
<div class="flap-expire" *ngIf="video && getExpiresInHoursAndDays(3) as exp"
    [class.isExpired]="exp.minutes < 0 || !video.isEnabled" [class.isDisabled]="isDisabled">
    <ng-container *ngIf="video.isEnabled; else videoDisabledBlock">
        <div class="flap-expire-days" *ngIf="exp.minutes >= 0">
            <ng-container *ngIf="exp.hours <= 12">
                {{exp.hours}}
            </ng-container>
            <ng-container *ngIf="exp.hours > 12 && exp.hours <= 23">
                1
            </ng-container>
            <ng-container *ngIf="exp.hours > 23">
                {{exp.days}}
            </ng-container>
        </div>
        <div class="flap-expire-text" *ngIf="exp.minutes >= 0">
            <span *ngIf="exp.hours === 0">{{ 'hours_short' | translate }}</span>
            <span *ngIf="exp.hours === 1">{{ 'hour_short' | translate }}</span>
            <span *ngIf="exp.hours > 1 && exp.hours <= 12">{{ 'hours_short' | translate }}</span>
            <span *ngIf="exp.hours > 12 && exp.days <= 1">{{ 'day' | translate }}</span>
            <span *ngIf="exp.days > 1">{{ 'days' | translate }}</span>
            {{ 'still_available' | translate }}
            <!-- {{exp.hours}} {{exp.days}} -->
        </div>
        <div class="flap-expire-text-full" *ngIf="exp.minutes < 0">
            {{ 'video_expired_flap' | translate }}
        </div>
    </ng-container>
    <ng-template #videoDisabledBlock>
        <div class="flap-expire-text-full" *ngIf="!video.isEnabled">
            {{ 'video_disabled_flap' | translate }}
        </div>
    </ng-template>
</div>
