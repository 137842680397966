import { ILanguages } from './interfaces/ILanguages';

/**
 * TODO: values from app.constants.ts should be moved here.
 */
export const CONSTANTS = {

    /**
     * Languages that are written right-to-left
     */
    RTL_LANGUAGES: ['ar', 'fa', 'he'] as ILanguages[],

    AVAILABLE_POS: [
        { key: 'ADJ', description: 'adjective' },
        { key: 'ADP', description: 'adposition' },
        { key: 'ADV', description: 'adverb' },
        { key: 'AUX', description: 'auxiliary verb' },
        { key: 'CONJ', description: 'coordinating conjunction' },
        { key: 'CCONJ', description: 'coordinating conjunction' },
        { key: 'DET', description: 'determiner' },
        { key: 'INTJ', description: 'interjection' },
        { key: 'NOUN', description: 'noun' },
        { key: 'NUM', description: 'numeral' },
        { key: 'PART', description: 'particle' },
        { key: 'PRON', description: 'pronoun' },
        { key: 'PROPN', description: 'proper noun' },
        { key: 'PUNCT', description: 'punctuation' },
        { key: 'SCONJ', description: 'subordinating conjunction' },
        { key: 'SYM', description: 'symbol' },
        { key: 'VERB', description: 'verb' },
        { key: 'X', description: 'other' },
    ] as { key: string; description: string; }[],

    PLAY_STORE_URL: 'https://play.google.com/store/apps/details?id=it.uugot',

    MAX_DURATION_VIDEOS_FOR_FREE_IN_MINUTES_REGULAR_CONTENT: 3,
    MAX_DURATION_VIDEOS_FOR_FREE_IN_SECONDS_REGULAR_CONTENT: 180,
    MAX_DURATION_VIDEOS_FOR_FREE_IN_MINUTES_KIDS_CONTENT: 11.30,
    MAX_DURATION_VIDEOS_FOR_FREE_IN_SECONDS_KIDS_CONTENT: 690,
    KIDS_CATEGORIES_IDS: ["5e5cf0ce3a66040eaa718031"]

} as const;
