import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from './api.service';
import { ISubjectsListResponse, ISingleObjectResponse } from 'src/app/models/dtos';
import { map } from 'rxjs/operators';

/**
 * API for /subtitles/
 * See http://localhost:3005/api-docs/#/subtitles
 */
@Injectable({
    providedIn: 'root',
})
export class SubtitlesApi {
    constructor(public api: Api) {
        // Do nothing.
    }

    /**
     * Retrieve all available subjects
     */
    getTranscript(
        videoId: string,
        format: 'docx' = 'docx'
    ): Observable<{ contentType: string; arrayBuffer: ArrayBuffer; }> {
        const params = {
            format,
        };
        const headers = {
            accept: undefined,
        };
        switch (format) {
            case 'docx':
                headers.accept =
                    'application/vnd.openxmlformats-officedocument.documentml.document';
                break;
            default:
                break;
        }
        return this.api.getAsArrayBuffer(`subtitles/transcript/${videoId}`, params, headers).pipe(
            map((arrayBuffer) => {
                return {
                    contentType: headers.accept,
                    arrayBuffer,
                };
            })
        );
    }
}
