import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { LoadingController, NavController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/app.constants';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from 'src/app/services/app-data.service';
import { AppManager } from 'src/app/services/app-manager.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AbstractRootMenuPage } from 'src/app/utils/abstract-root-menu-page';
import { ActivatedRoute } from '@angular/router';
import { UsersApi } from '../../services/api/users-api.service';


@Component({
    selector: 'app-confirm-user',
    templateUrl: 'confirm-user-page.html',
    styleUrls: ['confirm-user-page.scss']
})

export class ConfirmUserPage extends AbstractRootMenuPage implements OnInit {


    constructor(public uiUtils: UiUtils,
                private _route: ActivatedRoute,
                private toastController: ToastController,
                public translate: TranslateService,
                public appData: AppData,
                private loadingCtrl: LoadingController,
                public appManager: AppManager,
                public constants: Constants,
                public navCtrl: NavController,
                public plt: Platform,
                private analytics: AnalyticsService,
                private titleService: Title,
                private usersApi: UsersApi
    ){
        super(plt);
    }


    async ngOnInit() {
        if (this.appData.isLoggedIn()) {
            this.navCtrl.navigateRoot('/');
        } else {
            const loading = await this.loadingCtrl.create({
            });
            await loading.present();
            this.titleService.setTitle('About | uugot.it');
            this.analytics.trackPageView('confirm');
            this._route.queryParams.subscribe(params => {
                const { ac, e, invite } = params;
                this.usersApi.confirmEmail(ac, e, invite).subscribe(async res => {
                    loading.dismiss();
                    await this.presentToast();
                    this.navCtrl.navigateRoot('login_educator');
                }, err => {
                    loading.dismiss();
                    this.navCtrl.navigateRoot('');
                })
            });
        }

    }

    ionViewWillEnter() {
        this.appData.activePage = 'confirm';
    }

    async presentToast() {
        const toast = await this.toastController.create({
            message: this.translate.instant('explore_uugot_after_verify'),
            position: 'top',
            color: 'success',
            duration: 5000,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                    },
                },
            ],
        });
        await toast.present();
    }

}
