import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Constants } from '../app.constants';
import { AppData } from 'src/app/services/app-data.service';

/**
 * Intercept every HTTP request and sets the 'Authorization' HTTP header.
 */
@Injectable()
export class JwtTokenHeaderInterceptor implements HttpInterceptor {
    constructor(public appData: AppData, public constants: Constants) {
        // Doing nothing
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.appData.getPreferenceString(this.constants.pref.AUTH_TOKEN);

        if (token) {
            const newReq = req.clone({
                headers: req.headers.set('Authorization', token)
            });
            return next.handle(newReq);
        } else {
            return next.handle(req);
        }
    }
}
