<ion-header>
    <ion-toolbar color="secondary">
        <ion-title>{{'page_title_tutorial' | translate}}</ion-title>
        <ion-buttons slot="end">
            <!--<ion-button *ngIf="index > 0 && index < 3" (click)="slidePrev()">
                <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
            </ion-button>-->
            <ion-button *ngIf="index < 3" (click)='next()'>
                <ion-icon slot="icon-only" name="arrow-forward"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <swiper-container
        [pagination]='slideOptions.pagination'
        (reachend)='tutorialDone()'
        #swiper >
        <swiper-slide>
            <ion-img src="assets/img/tutorials/A01_welcome_v2.gif"></ion-img>
            <h1>Willkommen bei uugot.it!</h1>
            <ion-text class="ion-text-center">
                Schön, dass du zu uugot.it gefunden hast und von nun an einfach Deutsch lernen wirst. Ich bin übrigens uu!
            </ion-text>
        </swiper-slide>
        <swiper-slide>
            <ion-img src="assets/img/tutorials/A02_Tutorial_Juliane_1_reduced.gif"></ion-img>
            <h1>Besser Sprachen lernen</h1>
            <ion-text class="ion-text-center">
                Bei uugot.it lernst du während des Fernsehens - ganz schnell. Egal ob News, Filme, Dokumentationen... Du suchst dir aus, was dich interessiert. Und jeden Tag gibt es neue Beiträge.
            </ion-text>
        </swiper-slide>
        <swiper-slide>
            <ion-img src="assets/img/tutorials/A03_tutorial_subtitle.gif"></ion-img>
            <h1>Du verstehst etwas nicht? Klick drauf!</h1>
            <ion-text class="ion-text-center">
                uugot.it fügt jeder Sendung interaktive Untertitel hinzu. Einfach auf das Wort klicken, das du nicht verstehst, und schon bekommst du die Übersetzung in der gewünschten Sprache eingeblendet.
            </ion-text>
        </swiper-slide>
        <swiper-slide>
            <ion-img src="assets/img/logo_uugotit_767x298.png"></ion-img>
            <h1>Los geht's!</h1>
            <ion-button fill="clear" color="primary" (click)="closeModal()">
                {{'button_continue' | translate}}
                <ion-icon name="arrow-forward"></ion-icon>
            </ion-button>
        </swiper-slide>
    </swiper-container>


    <div *ngIf="!plt.is('mobile')" class="slide-arrows-container">
        <ion-icon *ngIf="index > 0" name="arrow-back-circle" class="slide-back-arrow" (click)="prev()"></ion-icon>
        <ion-icon *ngIf="index < 3" name="arrow-forward-circle" class="slide-forward-arrow" (click)='next()'>
        </ion-icon>
    </div>
    <div *ngIf="plt.is('mobile') && showArrow" class="slide-arrows-container">
        <ion-icon *ngIf="index === 0" name="arrow-forward-circle" class="slide-forward-arrow"></ion-icon>
    </div>
</ion-content>
