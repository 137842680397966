import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from './api.service';

/**
 * API for /appconfig/
 * See http://localhost:3005/api-docs/#/appconfig
 */
@Injectable({
    providedIn: 'root'
})
export class AppConfigApi {
    constructor(public api: Api) {
        // Do nothing.
    }

    /**
     * Retrieve the remote config.
     */
    getRemoteConfig(): Observable<any> {
        return this.api.get('appconfig/remote-config');
    }

}
