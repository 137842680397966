import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import {
    AlertController,
    IonContent,
    IonInput,
    LoadingController,
    ModalController,
    NavController,
    Platform,
    ToastController
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UsersApi } from 'src/app/services/api/users-api.service';
import { GroupsApi } from 'src/app/services/api/groups-api.service';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from 'src/app/services/app-data.service';
import { AppManager } from 'src/app/services/app-manager.service';
import { ActivityService } from 'src/app/services/activity.service';
import { NGXLogger } from 'ngx-logger';
import { forkJoin } from 'rxjs';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { Constants } from '../../app.constants';
import { EducatorsApi } from '../../services/api/educators-api';
import { SharedUiService } from '../../services/shared-ui.service';
import { AbstractRootMenuPage } from 'src/app/utils/abstract-root-menu-page';
import { IEducatorAdminGroup, IEducatorResponse } from '../../interfaces/IEducatorAdminGroup';
import { IInvitesToGroups, InviteActions } from '../../interfaces/IInvitesToGroups';
import { ISingleObjectResponse, UpdateInviteResponse } from '../../models/dtos';
import { ONBOARDING_TYPES } from '../../types/OnboardingTypes';
import { ICustomer } from '../../interfaces/ICustomer';
import { ISubject } from '../../interfaces/ISubject';

enum State {
    home,
    login_error,
    legal_error,
    logged_in
}

@Component({
    selector: 'app-educator-login',
    templateUrl: 'educator-login-v2-page.html',
    styleUrls: ['educator-login-v2-page.scss']
})
export class EducatorLoginV2Page extends AbstractRootMenuPage implements OnInit {
    @ViewChild(IonContent, { read: IonContent }) myContent: IonContent;
    @ViewChild('classInfo') classInfoElement: ElementRef;

    public registerNewEducatorForm: UntypedFormGroup;
    public loginCodeForm: UntypedFormGroup;
    public loginPasswordForm: UntypedFormGroup;
    public requestLinkForm: UntypedFormGroup;
    public codeSubmitAttempt = false;
    public preauthSubmitAttempt = false;
    public passwordSubmitAttempt = false;
    public segment = 'password'; // this is login with password
    public accountSegment = 'profile';
    public serverErrorMessage: string;


    public currentState = State.home;
    public mainText = 'hello_uu_scooling';
    public subHeaderText = 'login_subheader_infotext';
    public askEmailText = 'ask_email';

    public showLoginWithPassword = true;
    public showLoginWithCode = true;
    public submitting = false;
    public loadingInvites = false;
    private loader;

    State = State; // Needed, so it can be accessed from the template

    private emailInput: IonInput;
    private codeInput: IonInput;
    isMinLengthValid = false;
    isPatternValid = false;
    inviteId = null;
    selectedEducators: number[] = [1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 2, 1];
    showGroupInfo = false;
    selectedClass: IEducatorAdminGroup;
    selectedClassId: number;
    customer: ICustomer;
    loadingGroups = false;
    skeletonData: any[] = [
        { class_name: 'Loading...', main_educator: { first_name: 'Loading...', last_name: 'Loading...' } },
    ];
    totalConsumedLicenses: number;

    constructor(public navCtrl: NavController,
                private _route: ActivatedRoute,
                private usersApi: UsersApi,
                private groupsApi: GroupsApi,
                private loadingCtrl: LoadingController,
                private educatorsApi: EducatorsApi,
                public uiUtils: UiUtils,
                private formBuilder: UntypedFormBuilder,
                public appData: AppData,
                public toastCtrl: ToastController,
                private appManager: AppManager,
                public translate: TranslateService,
                private activityService: ActivityService,
                private analytics: AnalyticsService,
                private logger: NGXLogger,
                private modalCtrl: ModalController,
                private constants: Constants,
                private sharedUiService: SharedUiService,
                protected plt: Platform,
                private alertCtrl: AlertController) {

        super(plt);

        this.requestLinkForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.email])],
        });

        // https://ionicframework.com/docs/developer-resources/forms/
        // https://www.joshmorony.com/advanced-forms-validation-in-ionic-2/
        this.registerNewEducatorForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.email])],
            password: ['', Validators.compose([
                Validators.required,
                Validators.minLength(8),
                Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]*$')
            ])],
            confirm: ['', [Validators.required, this.passwordMatchingValidator('password')]]
        });

        this.registerNewEducatorForm.valueChanges.subscribe((value) => {
            if (this.registerNewEducatorForm.controls.password.errors) {
                if (this.registerNewEducatorForm.controls.password.errors.required) {
                    this.isMinLengthValid = false;
                    this.isPatternValid = false;
                    return;
                }
            }

            this.isMinLengthValid = !this.registerNewEducatorForm.controls.password.hasError('minlength');
            this.isPatternValid = !this.registerNewEducatorForm.controls.password.hasError('pattern');
        });
        this.loginCodeForm = this.formBuilder.group({
            code: ['', Validators.compose([Validators.required])]
        });

        this.loginPasswordForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.email])],
            password: ['', Validators.compose([Validators.required])],
        });

        // Update login page if user refreshes page and is auto-logged in
        this.appManager.checkAuthToken(null).subscribe(isLoggedIn => {
            if (isLoggedIn) {
                this.updateUI(State.logged_in);
            }
        });

        // Get code from the URL and do an automatic login
        const accessCode = this._route.snapshot.params.code;

        // Update login page is user is logged in
        if (accessCode) {
            if (this.appData.isLoggedIn) {
                this.logger.debug('Automatic login: user is still logged in - need to log out');
                this.logout();
            }
            this.loginWithCode(accessCode);
        } else if (this.appData.isEducator()) {
            this.updateUI(State.logged_in);
        }
    }

    /** A hero's name can't match the given regular expression */
    passwordMatchingValidator(password: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            if (!control || !control.parent) {
                return null;
            }

            return control.parent.get(password).value === control.value ? null : { mismatch: true };
        };
    }


    async ngOnInit() {
        this.loader = await this.loadingCtrl.create({});
        this._route.queryParams.subscribe(async params => {
            this.inviteId = params['invite'];
            this.logger.debug('Query params', params);
            if (this.appData.isLoggedIn()) {
                if (this.inviteId) {
                    try {
                        const result = await this.educatorsApi.isInviteValid(this.inviteId).toPromise();
                        if (result.invite && result.invite.email && result.invite.email === this.appData.authenticatedUser.email) {
                            this.acceptInvite(this.inviteId);
                        }
                    } catch (e) {
                        console.log('Invite not longer valid');
                        this.showInviteExpiredDialog();
                        return;
                    }
                }
                if (this.appData.authenticatedUser.customer_role === 'admin') {
                    await this.fetchAdminGroups()
                }
            } else {
                if (this.inviteId) {
                    if (params['segment']) {
                        this.segment = params['segment'];
                    }
                    if (params['s']) {
                        this.segment = params['s'];
                    }
                    try {
                        const result = await this.educatorsApi.isInviteValid(this.inviteId).toPromise();
                        if (result.invite && result.invite.email && this.currentState !== State.logged_in) {
                            this.segment = 'register'
                            this.registerNewEducatorForm.patchValue({ email: result.invite.email });
                        }
                    } catch (e) {
                        console.log('Invite not longer valid');
                        this.showInviteExpiredDialog();
                        return;
                    }

                    // this.acceptInvite(this.inviteId);
                }
            }

        });
    }

    ionViewWillEnter() {
        super.ionViewWillEnter();
        this.appData.activePage = 'account';
        super.ionViewWillEnter();
    }

    ionViewWillLeave() {
        super.ionViewWillLeave();
    }

    /**
     * Updates text depending on page state
     */
    updateUI(state: State) {
        this.currentState = state;
        switch (state) {
            case State.home:
                this.mainText = 'hello_uu_scooling';
                this.subHeaderText = 'login_subheader_infotext';
                this.askEmailText = 'ask_email';
                break;
            case State.login_error:
                this.mainText = 'outdated_link';
                this.subHeaderText = 'login_subheader_infotext';
                this.askEmailText = 'ask_email_after_error';
                break;
            case State.logged_in:
                this.mainText = 'hello_uu';
                this.subHeaderText = '';
                this.askEmailText = 'logged_in_as';
                console.log(this.appData.userCustomer)
                const customer = JSON.parse(this.appData.getPreferenceString(this.constants.pref.LOGGED_USER_CUSTOMER))
                this.customer = customer;
                // this.customerName = this.appData.userCustomer.name;
                break;
            default:
                this.mainText = 'not_accepted_terms';
                this.subHeaderText = 'login_subheader_infotext';
                this.askEmailText = 'have_to_accept_terms';
                break;
        }
    }

    /**
     * Calls userApi.preAuth() to request a login link.
     */
    requestLoginLink() {
        this.preauthSubmitAttempt = true;

        if (!this.requestLinkForm.valid) {
            this.logger.debug('Form is not valid');
            return;
        }

        const email = this.requestLinkForm.value.email;

        // Callback in case of preauth error
        const onPreauthError = (result?: any) => {
            if (result.code === 'USERS_PREAUTH_EMAIL_NOTFOUND_AUTO_REGISTER') {
                this.logger.debug('Could not authorize user, attempt to register', result);
                this.educatorsApi.register(email).subscribe(registerResult => {
                    if (registerResult.success) {
                        this.uiUtils.displayToast(registerResult.msg, undefined, 5000);
                    } else if (registerResult.code === 'EDUCATOR_EMAIL_NOT_WHITELISTED') {
                        this.showNoRegistrationPossibleDialog();
                    } else {
                        this.uiUtils.displayToast(registerResult.msg, undefined, 5000);
                    }
                });
            } else {
                this.logger.debug('Could not authorize user, automatic registration not possible', result);
                // this.uiUtils.displayToast(this.translate.instant('user_not_found'));
                this.showNoRegistrationPossibleDialog();
            }
        };


        this.usersApi.preAuth(email).subscribe(result => {
            if (result.success) {
                this.logger.debug('Success - preAuth response', result);
                this.uiUtils.displayToast(this.translate.instant('auth_link_sent'), undefined, 4000);
                this.loginCodeForm.reset();
                this.codeSubmitAttempt = false;
                this.segment = 'code';
                setTimeout(() => {
                    if (this.codeInput) {
                        this.codeInput.setFocus();
                    }
                }, 500);
            } else {
                this.uiUtils.displayToast(result.msg, undefined, 4000);
            }
        }, err => {
            this.logger.info('Error - preAuth response', err.error);
            if (err.error && (err.error.code === 'USERS_PREAUTH_EMAIL_NOTFOUND' || err.error.code === 'USERS_PREAUTH_EMAIL_NOTFOUND_AUTO_REGISTER')) {
                onPreauthError(err.error);
            } else {
                this.uiUtils.showErrorAlert(this.translate.instant('email_error'));
            }
        });
    }


    registerWithUserAndPassword() {
        this.preauthSubmitAttempt = true;

        if (!this.registerNewEducatorForm.valid) {
            this.logger.debug('Form is not valid');
            return;
        }

        const email = this.registerNewEducatorForm.value.email;


        // Callback in case of preauth error
        const onPreauthError = (result?: any) => {
            if (result.code === 'USERS_PREAUTH_EMAIL_NOTFOUND_AUTO_REGISTER') {
                this.logger.debug('Could not authorize user, attempt to register', result);
                this.educatorsApi.register(email).subscribe(registerResult => {
                    if (registerResult.success) {
                        this.uiUtils.displayToast(registerResult.msg, undefined, 4000);
                    } else if (registerResult.code === 'EDUCATOR_EMAIL_NOT_WHITELISTED') {
                        this.showNoRegistrationPossibleDialog();
                    } else {
                        this.uiUtils.displayToast(registerResult.msg, undefined, 4000);
                    }
                });
            } else {
                this.logger.debug('Could not authorize user, automatic registration not possible', result);
                // this.uiUtils.displayToast(this.translate.instant('user_not_found'));
                this.showNoRegistrationPossibleDialog();
            }
        };


        this.usersApi.preAuth(email).subscribe(result => {
            if (result.success) {
                this.logger.debug('Success - preAuth response', result);
                this.uiUtils.displayToast(this.translate.instant('auth_link_sent'), undefined, 4000);
                this.loginCodeForm.reset();
                this.codeSubmitAttempt = false;
                this.segment = 'code';
                setTimeout(() => {
                    if (this.codeInput) {
                        this.codeInput.setFocus();
                    }
                }, 500);
            } else {
                this.uiUtils.displayToast(result.msg, undefined, 4000);
            }
        }, err => {
            this.logger.info('Error - preAuth response', err.error);
            if (err.error && (err.error.code === 'USERS_PREAUTH_EMAIL_NOTFOUND' || err.error.code === 'USERS_PREAUTH_EMAIL_NOTFOUND_AUTO_REGISTER')) {
                onPreauthError(err.error);
            } else {
                this.uiUtils.showErrorAlert(this.translate.instant('email_error'));
            }
        });
    }

    showNoRegistrationPossibleDialog() {
        const list = this.appManager.getAustrianTeacherEmailAddressesForDisplay().map(e => `<li>${e}</li>`).join('');
        this.uiUtils.showInfoAlert(
            '',
            'Code nicht versendet!',
            `Bitte gib deine Dienstmailadresse für die automatische Registrierung ein. (folgende Domains)<br>
            <ul>
            ${list}
            </ul>
            <div class='footer'>Keine Dienstmailadresse? Wir haben dir eine E-Mail gesendet. Bitte überprüfe auch deinen Spam-Ordner!</div>
        `, null, 'dialog-registration-not-possible'
        );
    }

    showInviteExpiredDialog() {
        this.uiUtils.showInfoAlert(
            this.translate.instant('expired_invitation_dialog_title'),
            '',
            `${this.translate.instant('expired_invitation_dialog_message')}<br>`, null, 'dialog-registration-not-possible'
        );
    }


    /**
     * Logs the user out
     */
    async logout() {

        const doLogout = () => {
            const userRole = this.appData.authenticatedUser.role;
            this.updateUI(State.home); // Assignment needed for UI to update
            this.appManager.deleteUserInfo();
            this.logger.debug('User logged out');

            if (userRole === 'student') {
                window.location.href = '/student';
            } else {
                this.navCtrl.navigateRoot('login_educator');
            }
        };

        // Save events for student before logout
        if (this.appData.isStudent()) {
            // Show confirmation message
            const alert = await this.alertCtrl.create({
                header: this.translate.instant('log_out'),
                subHeader: this.translate.instant('confirm_student_logout_text'),
                buttons: [
                    {
                        text: this.translate.instant('btn_cancel'),
                        role: 'cancel'
                    },
                    {
                        text: this.translate.instant('btn_ok'),
                        handler: async () => {
                            await this.activityService.uploadLocallyStoredEvents();
                            doLogout();
                        }
                    }
                ]
            });
            await alert.present();

        } else {
            doLogout();
        }

    }

    /**
     * Opens student login page
     */
    openStudentLoginPage() {
        this.navCtrl.navigateRoot('student');
    }

    /**
     * Checks if form is valid and calls login function if it is
     */
    onSubmitLoginWithCode() {
        this.codeSubmitAttempt = true;

        if (!this.loginCodeForm.valid) {
            this.logger.debug('Form is not valid');
            return;
        }

        this.loginWithCode(this.loginCodeForm.value.code);
    }

    /**
     * Log user in with code
     */
    loginWithCode(accessCode: string) {
        const code = accessCode.trim();
        this.usersApi.loginCode(code).subscribe(async response => {
            this.logger.debug('Success - login with code', response);

            this.appManager.saveUserInfo(response.loggedUser, response.jwtToken, response.license, response.preferences);
            return forkJoin([
                this.appManager.getUserClasses(),
                this.appManager.getUserClassTemplates()
            ]).map(([groupsResponse, templatesResponse]) => {
                // this._logger.debug('Groups and templates:', resArray);
                this.appData.groupsForUser = groupsResponse?.data;
                this.appData.groupTemplatesForUser = templatesResponse?.data;
                this.appData.forceReloadVideos();
                this.updateUI(State.logged_in);
                this.sharedUiService.checkShowProfilePageRequired(response.loggedUser);
                return true;
            }).subscribe(() => {
                this.logger.debug('Loaded educator groups and templates');
            });
        }, err => {
            this.logger.warn('Error - login with code. Trying class access code', err.error);
            this.groupsApi.getByCode(code).subscribe(response => {
                if (response.success) {
                    // this.appData.studentClass = response.data;
                    // this.appData.classAccessCode = code;
                    // this.analytics.trackAnalyticsEvent(AnalyticsCategory.Class, 'join', this.appData.studentClass._id);
                    // this.navCtrl.navigateForward('student-class/select-user');
                    const navigationExtras: NavigationExtras = {
                        state: {
                            group: response.data,
                            classCode: code
                        }
                    };
                    this.navCtrl.navigateForward('student', navigationExtras);
                } else {
                    // Show original error
                    this.logger.debug('Error code is ', err.error?.code);
                    if (['USERS_ACEXPIRED', 'USERS_ACNOTEXISTS'].includes(err.error?.code)) {
                        this.alertCtrl.create({
                            // header: this.translate.instant('token_expired_header'),
                            subHeader: err.error.message,
                            buttons: [
                                {
                                    text: this.translate.instant('btn_cancel'),
                                    role: 'cancel',
                                    handler: () => {
                                        console.log('Cancel clicked');
                                    }
                                },
                                {
                                    text: this.translate.instant('login_request_code'),
                                    handler: () => {
                                        this.segment = 'email';
                                        setTimeout(() => {
                                            if (this.emailInput) {
                                                this.emailInput.setFocus();
                                            }
                                        }, 500);
                                        return true;
                                    }
                                }
                            ]
                        }).then(alert => {
                            return alert.present();
                        });
                    } else {
                        this.uiUtils.showServerRequestErrorAlert(err);
                    }

                }
            }, err2 => {
                console.warn('Error getting group by code', err2);
                this.uiUtils.showServerRequestErrorAlert(err);
            });
        });
    }

    /**
     * Checks if username and password are valid and logs user in if they are
     */
    loginWithPassword() {
        this.passwordSubmitAttempt = false;

        if (!this.loginPasswordForm.valid) {
            this.logger.debug('Form is not valid');
            return;
        }

        this.usersApi.login(this.loginPasswordForm.value.email, this.loginPasswordForm.value.password).subscribe(async response => {
            this.logger.debug('Success - login with password', response);
            const shouldRedirectToOnboarding = response.redirect_to_onboarding;
            this.appManager.saveUserInfo(response.loggedUser, response.jwtToken, response.license, response.preferences, response.customer);

            const sources = [
                this.appManager.getUserClasses(),
                this.appManager.getUserClassTemplates()
            ];
            this.loadingGroups = true;
            if (this.appData.authenticatedUser.customer_role === 'admin') {
                await this.fetchAdminGroups(true)
            }

            return forkJoin(sources).map(([groupsResponse, templatesResponse]) => {
                // this._logger.debug('Groups and templates:', resArray);
                this.appData.groupsForUser = groupsResponse?.data;
                this.appData.groupTemplatesForUser = templatesResponse?.data;

                this.appData.forceReloadVideos();
                this.updateUI(State.logged_in);
                this.sharedUiService.checkShowProfilePageRequired(response.loggedUser);
                return true;
            }).subscribe(() => {
                // Redirect based on onboarding status
                const onboardingComplete = response.loggedUser?.educator_preferences?.onboarding_complete;
                const redirectGroup = response.loggedUser?.educator_preferences?.onboarding_complete?.redirect_to_group;
                console.log('ONBOARDING COMPLETE: ', onboardingComplete)
                if (onboardingComplete && !onboardingComplete.completed) {
                    const onboardingType = onboardingComplete.type;
                    if (onboardingType === ONBOARDING_TYPES.UNVERIFIED) {
                        if (redirectGroup) {
                            const url = `onboarding-unverified?group_id=${redirectGroup}`;
                            this.appData.savePreferenceString(this.constants.pref.ONBOARDING_REDIRECT_URL, url);
                            this.navCtrl.navigateForward(url);
                        } else {
                            const url = 'onboarding-unverified';
                            this.appData.savePreferenceString(this.constants.pref.ONBOARDING_REDIRECT_URL, url);
                            this.navCtrl.navigateForward(url);
                        }
                    } else if (onboardingType === ONBOARDING_TYPES.EXPIRED_UNVERIFIED) {
                        const url = 'onboarding?e=true';
                        this.appData.savePreferenceString(this.constants.pref.ONBOARDING_REDIRECT_URL, url);
                        this.navCtrl.navigateForward(url);
                    } else if (onboardingType === ONBOARDING_TYPES.REGULAR) {
                        if (redirectGroup) {
                            const url = `onboarding-unverified?group_id=${redirectGroup}`;
                            this.appData.savePreferenceString(this.constants.pref.ONBOARDING_REDIRECT_URL, url);
                            this.navCtrl.navigateForward(url); //@TODO  change name component
                        } else {
                            const url = 'onboarding';
                            this.appData.savePreferenceString(this.constants.pref.ONBOARDING_REDIRECT_URL, url);
                            this.navCtrl.navigateForward(url);
                        }
                    }
                } else if (redirectGroup) {
                    this.appData.savePreferenceString(this.constants.pref.ONBOARDING_EDUCATOR_DONE, '1');
                    this.navCtrl.navigateForward(`educator-class/details/${redirectGroup}`);
                }


                this.logger.debug('Loaded educator groups and templates');

                return true;
            });

            const resClasses = await this.appManager.getUserClasses().toPromise();
            // this.appData.groupsForUser = resClasses?.data;
            // this.appData.forceReloadVideos();
            // this.updateUI(this.currentState = State.logged_in);

        }, err => {
            this.logger.error('Error - login with password', err.error);
            this.uiUtils.showServerRequestErrorAlert(err);
        });
    }

    async buttonEditProfilePageClicked() {
        return this.sharedUiService.showEditProfilePage();
    }

    async buttonEditProfilePasswordClicked() {
        return this.sharedUiService.showEditProfilePassword();
    }

    async onInviteAdminClicked() {
        return this.sharedUiService.showInviteAdmin();
    }

    async buttonInviteEducatorsClicked() {
        return this.sharedUiService.showInviteEducatorsPage(true, true);
    }

    async buttonEducatorTutorialClicked() {
        return this.sharedUiService.showEducatorTutorialPage(true);
    }

    @ViewChild('email', { static: false }) set emailInputPlaceholder(email: IonInput) {
        this.logger.debug('EMAIL', email);
        if (email) { // initially setter gets called with undefined
            this.emailInput = email;
        }
    }

    @ViewChild('code', { static: false }) set codeInputPlaceholder(code: IonInput) {
        this.logger.debug('CODE', code);
        if (code) { // initially setter gets called with undefined
            this.codeInput = code;
        }
    }

    submitRegister() {
        this.serverErrorMessage = null;
        if (!this.registerNewEducatorForm.valid) {
            return null;
        }
        this.submitting = true;
        const formData = this.registerNewEducatorForm.value;
        // Callback in case of preauth error
        this.educatorsApi.registerWithPassword(formData, this.inviteId).subscribe(registerResult => {
            this.submitting = false;
            if (registerResult.success) {
                this.registerNewEducatorForm.reset();
                this.loginPasswordForm.reset();
                this.segment = 'password'
                this.uiUtils.displayToast(registerResult.msg, undefined, 4000);
            } else if (registerResult.code === 'EDUCATOR_EMAIL_NOT_WHITELISTED') {
                this.showNoRegistrationPossibleDialog();
            } else {
                this.uiUtils.displayToast(registerResult.msg, undefined, 4000);
            }
        }, error => {
            if (error.msg) {
                this.serverErrorMessage = error.msg;
            }
            this.submitting = false;
            this.uiUtils.displayToast(this.translate.instant('error_page_message'), undefined, 4000, "danger");
            console.log('error ', error)
        });

    }

    acceptInvite(inviteId: string) {
        this.loader.present();
        this.submitting = true;
        this.educatorsApi.updateInvite(inviteId, InviteActions.ACCEPT).subscribe((resp: UpdateInviteResponse) => {
            this.loader.dismiss();
            this.submitting = false;
            console.log('resp: ', resp)
        }, error => {
            this.submitting = false;
            this.loader.dismiss();
            this.uiUtils.displayToast(this.translate.instant('error_page_message'), undefined, 4000, "danger");
        })
    }

    rejectInvite(invite: IInvitesToGroups) {
        this.loader.present();
        this.submitting = true;
        this.educatorsApi.updateInvite(invite._id, InviteActions.REJECT).subscribe((resp: UpdateInviteResponse) => {
            this.loader.dismiss();
            this.submitting = false;
            invite.state = resp.data.state;
            console.log('resp: ', resp)
        }, error => {
            this.submitting = false;
            this.loader.dismiss();
            this.uiUtils.displayToast(this.translate.instant('error_page_message'), undefined, 4000, "danger");
        })
    }

    ngAfterViewInit() {
        // Verificar si showGroupInfo es verdadero para realizar el scroll inicial
        if (this.showGroupInfo) {
            this.scrollToClassInfo();
        }
    }

    scrollToClassInfo() {
        // Realiza el scroll hasta el div de información de la clase
        const yOffset = this.classInfoElement.nativeElement.offsetTop - 40;
        this.myContent.scrollToPoint(0, yOffset, 1000);
    }

    toggleInfo($event, id: number) {
        console.log('jizo ckicl con id ', id)
        $event.preventDefault();
        if (this.selectedClassId === id) {
            this.selectedClassId = -1;
            this.selectedClass = null;
            this.showGroupInfo = false;
        } else {
            this.selectedClassId = id;
            this.selectedClass = this.appData.educatorAdminGroups[id];
            this.showGroupInfo = true;
            this.scrollToClassInfo();
        }
    }

    getSubjectsStringFromEducator(educatorId: IEducatorResponse): string {
        if (this.selectedClass) {
            const educator = this.selectedClass.team_educators.find(educator => educator.edId._id === educatorId._id);
            if (educator && educator.subjects) {
                const subjectNames: string[] = educator.subjects.map((subject: ISubject) => {
                    return subject.name[this.appData.getLanguage()] || subject.name['en'];
                });

                return subjectNames.join(', ');
            }
        }


    }

    getTeamEducatorsListFromSelectedClass() {
        return this.selectedClass.team_educators.filter(educator => educator.edId._id !== this.selectedClass.main_educator._id);
    }

    async upgradeToMainEducator(group: IEducatorAdminGroup, educator: IEducatorResponse) {
        await this.upgradeTeamEducatorAsMainEducator(group.class_id, group.class_name, educator);
    }

    async upgradeTeamEducatorAsMainEducator(groupId: string, className: string, educator: IEducatorResponse) {
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('promote_teacher_to_main_teacher'),
            subHeader: this.translate.instant('promote_teacher_to_main_teacher_subtitle', {selectedEducatorName: educator.first_name, className: className}),
            buttons: [
                {
                    text: this.translate.instant('btn_cancel'),
                    role: 'cancel'
                },
                {
                    text: this.translate.instant('btn_submit'),
                    handler: () => {
                        this.submitUpgradeTeamEducator(groupId, educator._id);
                    }
                }
            ]
        });
        await alert.present();
    }

    private submitUpgradeTeamEducator(groupId: string, educatorId: string) {
        this.loadingGroups = true;
        this.showGroupInfo = false;
        this.groupsApi.upgradeTeamEducatorAsMainEducator(groupId, educatorId).subscribe(
            async (response: ISingleObjectResponse<any>) => {
                if (response.success) {
                    this.uiUtils.displayToast("Text upgraded succesfully", undefined, 4000);
                    if (this.appData.authenticatedUser.customer_role === 'admin') {
                        await this.fetchAdminGroups(true)
                    }

                } else {
                    const msg = response.msg;
                    this.loadingGroups = false;
                    this.uiUtils.displayToast(msg, undefined, 4000);
                }
            },
            (error) => {
                console.log('Error upgrading educator to admin ', error)
            }
        );
    }

    private async fetchAdminGroups(showLastOpenedGroup = false) {
        this.loadingGroups = true;
        return this.groupsApi.getGroupsFromCustomerAdmin().subscribe((groups: IEducatorAdminGroup[]) => {
            this.appData.educatorAdminGroups = groups;
            this.totalConsumedLicenses = groups.reduce((total, group) => total + group.students_count, 0);

            this.loadingGroups = false;
            if (showLastOpenedGroup) {
                // this.showGroupInfo = true;
            }
        });
    }


    getDisplayName(educator: IEducatorResponse): string {
        if (educator.first_name && educator.last_name) {
            const firstName = educator.first_name.charAt(0).toUpperCase() + educator.first_name.slice(1).toLowerCase();
            const lastName = educator.last_name.charAt(0).toUpperCase() + educator.last_name.slice(1).toLowerCase();
            return `${firstName} ${lastName}`;
        } else if (educator.email) {
            return educator.email;
        }
        return '';
    }

    getDisplayNameForUser() {
        if (this.appData.authenticatedUser.first_name && this.appData.authenticatedUser.last_name) {
            return `${this.appData.authenticatedUser.first_name} ${this.appData.authenticatedUser.last_name} `
        } else if (this.appData.userCustomer && this.appData.userCustomer.name) {
            return this.appData.userCustomer.name;
        }
        return this.appData.authenticatedUser.email;
    }
}
