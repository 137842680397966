/* eslint-disable @typescript-eslint/camelcase */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from './api.service';
import { TranslationResponse, VideoTranslationResponse } from 'src/app/models/dtos';
import { ITranslationOptions } from 'src/app/interfaces/ITranslation';

/**
 * API for /translations/
 * See https://spyder3-dev.uugot.it/api-docs/#/translations
 */
@Injectable({
    providedIn: 'root',
})
export class TranslationsApi {
    constructor(public api: Api) {
        // Do nothing.
    }

    /**
     * Loads subtitles for a certain video
     *
     * @param videoId The video ID
     * @param lang The language of subtitles
     */
    getSubtitlesForVideo(videoId: string, lang: string): Observable<TranslationResponse> {
        return this.api.get(`translations/${videoId}/${lang ? lang : ''}`);
    }

    /**
     * Fetch subtitle document from "subtitles" collection and create translation. Just one video can be translated.
     * If translation process "running" put translation into queue.
     *
     * @param webSource_id The web source ID
     * @param options Translation options
     * @param videoId Video Id of videos to be translated
     * @param force If true will delete existing translation and start new one.
     * This is useful when existing translation has errors.
     */
    translateSingleVideo(
        webSource_id: string,
        options: ITranslationOptions,
        videoId: string,
        force = false,
        wait = false,
        returnTranslation = false
    ): Observable<VideoTranslationResponse> {
        return this.api.post(`translations/single/${webSource_id}?force=${force}&wait=${wait}&returnTranslation=${returnTranslation}`, {
            options,
            video_id: videoId,
        });
    }
}
