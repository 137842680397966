import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';

import { Api } from './api.service';
import { BlobTokenResponse, GeoipResponse, LoginResponse, PreAuthResponse, ISingleObjectResponse } from '../../models/dtos';

export interface IAdminInvite {
    name: string;
    last_name: string;
    email: string;
}

/**
 * API for /customers/
 * See http://spyder3-stage.uugot.it/api-docs/#/users
 */
@Injectable({
    providedIn: 'root'
})
export class CustomerApi {
    constructor(public http: HttpClient, public api: Api) {
        // Do nothing.
    }
    /**
     * Invite admin to customer
     *
     * @param obj
     */
    inviteAdmin(obj: IAdminInvite): Observable<ISingleObjectResponse<any>> {
        return this.api.post('customers/admin/invite', obj);
    }
}
