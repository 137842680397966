import { IEducator } from './IEducator';
import { IGroup } from './IGroup';
import { IUser } from './IUser';

export enum InviteState {
    ACCEPTED = 'accepted',
    PENDING = 'pending',
    EXPIRED = 'expired',
    REJECTED = 'rejected',
}

export enum InviteActions {
    ACCEPT = 'accept',
    REJECT = 'reject',
    EXPIRE = 'expire'
}

export interface IInvitesToGroups {
    _id: string;
    email: string;
    invitedBy: IUser;
    name: string;
    lastName: string;
    group: IGroup;
    state: InviteState;
    createdAt?: Date;
    updatedAt?: Date;
    expiryDate?: Date;
}
