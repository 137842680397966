import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';

import { Api } from './api.service';
import { ISingleObjectResponse } from '../../models/dtos';
import { IUser } from '../../interfaces/IUser';

/**
 * API for /students/
 * See http://spyder3-stage.uugot.it/api-docs/#/students
 */
@Injectable({
    providedIn: 'root'
})
export class StudentsApi {
    constructor(public http: HttpClient, public api: Api) {
        // Do nothing.
    }

    modifyProfile(userProperties: any): Observable<ISingleObjectResponse<IUser>> {
        return this.api.put('students/profile', userProperties);
    }
}
