import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertController, ToastController, LoadingController, Platform, ModalController, NavController } from '@ionic/angular';
import 'rxjs/add/operator/map';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { AppData } from './app-data.service';
import { AppManager } from './app-manager.service';
import { IUser } from '../interfaces/IUser';
import { Constants } from '../app.constants';
import { UsersApi } from './api/users-api.service';
import { ITutorialConfig, TutorialGenericPage } from '../pages/tutorial-generic/tutorial-generic-page';
import { EditProfilePage } from '../pages/edit-profile/edit-profile-page';
import { InviteEducatorsPage, InviteEducatorsPageResult } from '../pages/invite-educators-page/invite-educators-page';
import { UiUtils } from './ui-utils.service';
import { GroupsApi } from './api/groups-api.service';
import { GroupVideoResponse } from '../models/dtos';
import * as moment from 'moment';
import { CONSTANTS } from '../constants';
import { EditPasswordProfilePage } from '../pages/edit-password/edit-password-profile-page';
import { InviteAdminPage } from '../pages/invite-admin/invite-admin-page';

/*
  Shared UI components.
*/
@Injectable({
    providedIn: 'root'
})
export class SharedUiService {

    private readonly tutorialIdEducators = 'educators_scooling_welcome';

    constructor(public http: HttpClient,
        public alertCtrl: AlertController,
        public translate: TranslateService,
        public toastCtrl: ToastController,
        public loadingCtrl: LoadingController,
        private logger: NGXLogger,
        private plt: Platform,
        private appData: AppData,
        private constants: Constants,
        private modalCtrl: ModalController,
        private navCtrl: NavController,
        private usersApi: UsersApi,
        private appManager: AppManager,
        private uiUtils: UiUtils,
        private groupApi: GroupsApi) {
        logger.debug('Hello SharedUiService');
    }

    /**
     * Check if the EditProfilePage needs to be shown for an educator.
     *
     * @param user optional, current user. Otherwise  this.appData.authenticatedUser will be used
     */
    checkShowProfilePageRequired(user?: IUser) {
        this.logger.debug('checkShowProfilePageRequired', user);
        const myUser = user || this.appData.authenticatedUser;
        if (this.isProfileDataRequired(myUser)) {
            this.usersApi.getPreferences().subscribe(prefs => {
                this.logger.debug('got user prefs', prefs);
                const key = `${this.constants.pref.TUTORIAL_DONE}_${this.tutorialIdEducators}`;
                const tutorialDone = prefs[key];
                // const tutorialDone = this.appData.getPreferenceString(key);
                const showEditProfilePage = async () => {
                    this.showEditProfilePage(true, true, async () => {
                        await this.checkShowInviteDialog(myUser, prefs);
                    });
                };
                if (tutorialDone) {
                    showEditProfilePage();
                } else {
                    // Show tutorial page first
                    this.showEducatorTutorialPage(false, async () => {
                        this.logger.debug('called on dismiss');
                        await showEditProfilePage();
                    });
                }
            });

        } else {
            this.logger.debug('Profile data is already available or user is not logged in');
            this.checkShowInviteDialog(myUser);
        }
    }

    isProfileDataRequired(user: IUser): boolean {
        this.logger.debug('isProfileDataRequired', user);
        if (!user) {
            return false;
        }
        if (user.role !== 'educator') {
            return false;
        }
        return this.appManager.isAustrianTeacherEmailAddress(user.email) &&
            (!user.first_name || !user.last_name || !user.schoolName || !user.city);
    }

    /**
     * Checks if the InviteEducatorsPage should be shown for an educator.
     * @param prefs optional user prefs, if null, then this.usersApi.getPreferences() will be called.
     */
    async checkShowInviteDialog(user: IUser, prefs?: any) {
        try {
            if (!user || user.role !== 'educator') {
                return;
            }
            if (!this.appManager.isAustrianTeacherEmailAddress(user.email)) {
                return;
            }
            let prefsToUse = prefs;
            this.logger.debug('checkShowInviteDialog prefs', prefs);
            if (!prefsToUse) {
                if (this.appData.authenticatedUser) {
                    prefsToUse = await this.usersApi.getPreferences().toPromise() || {};
                } else {
                    this.logger.debug('prefs are not available, user is not logged in');
                    return;
                }
            }
            const invitedEducators: string[] = prefsToUse[this.constants.pref.INVITED_EDUCATORS];
            const inviteEducatorsClosed: number = prefsToUse[this.constants.pref.INVITE_EDUCATORS_CLOSED];
            if (inviteEducatorsClosed && inviteEducatorsClosed >= this.constants.AskEducatorInviteMaxTimes) {
                this.logger.debug(`checkShowInviteDialog inviteEducatorsDeclined is ${inviteEducatorsClosed}, returning`,);
                return;
            } else if (invitedEducators && invitedEducators.length >= 3) {
                this.logger.debug(`checkShowInviteDialog invitedEducators is ${invitedEducators.length}, returning`);
                return;
            } else {
                this.logger.debug(`checkShowInviteDialog inviteEducatorsDeclined ${inviteEducatorsClosed}, invitedEducators ${invitedEducators?.length} - showing`);
                return await this.showInviteEducatorsPage();
            }
        } catch (err) {
            this.logger.error('Error loading user preferences', err);
        }
    }

    async showEducatorTutorialPage(backdropDismiss: boolean, callOnDismiss?: () => void) {
        const config: ITutorialConfig = {
            id: this.tutorialIdEducators,
            title: this.translate.instant('welcome_to_uugot.it_sCOOLing'),
            doneButtonText: 'button_done',
            showDoneArrow: true,
            slides: [
                {
                    image: this.appData.getLanguage() === 'en' ? 'assets/img/tutorials/S01_en.png' : 'assets/img/tutorials/S01_de.jpg',
                    header: this.translate.instant('educator_tutorial_slider_first_header'),
                    text: this.translate.instant('educator_tutorial_slider_first_text')
                },
                {
                    image: 'assets/img/tutorials/A03_tutorial_subtitle.gif',
                    header: this.translate.instant('educator_tutorial_slider_second_header'),
                    text: this.translate.instant('educator_tutorial_slider_second_text')
                },
                {
                    image: 'assets/img/tutorials/S03.jpg',
                    header: this.translate.instant('educator_tutorial_slider_third_header'),
                    text: this.translate.instant('educator_tutorial_slider_third_text')
                }
            ]
        };
        const modal = await this.modalCtrl.create({
            component: TutorialGenericPage,
            showBackdrop: true,
            // swipeToClose: true,
            backdropDismiss,
            componentProps: {
                config,
                callOnDismiss
            }
        });
        await modal.present();
    }

    async showEditProfilePage(isSchoolRequired?: boolean, allowDismissOnlyAfterSave?: boolean, callOnDismiss?: () => void) {
        let isSchoolRequiredLocal = isSchoolRequired;
        if (isSchoolRequiredLocal === undefined && this.appData.authenticatedUser) {
            isSchoolRequiredLocal = this.appManager.isAustrianTeacherEmailAddress(this.appData.authenticatedUser.email);
        }
        const modal = await this.modalCtrl.create({
            component: EditProfilePage,
            showBackdrop: true,
            // swipeToClose: true,
            backdropDismiss: !allowDismissOnlyAfterSave,
            componentProps: {
                isSchoolRequired: isSchoolRequiredLocal || false,
                allowDismissOnlyAfterSave: allowDismissOnlyAfterSave || false,
                callOnDismiss
            }
        });
        await modal.present();
    }

    async showEditProfilePassword(isSchoolRequired?: boolean, allowDismissOnlyAfterSave?: boolean, callOnDismiss?: () => void) {
        let isSchoolRequiredLocal = isSchoolRequired;
        if (isSchoolRequiredLocal === undefined && this.appData.authenticatedUser) {
            isSchoolRequiredLocal = this.appManager.isAustrianTeacherEmailAddress(this.appData.authenticatedUser.email);
        }
        const modal = await this.modalCtrl.create({
            component: EditPasswordProfilePage,
            showBackdrop: true,
            // swipeToClose: true,
            backdropDismiss: !allowDismissOnlyAfterSave,
            componentProps: {
                isSchoolRequired: isSchoolRequiredLocal || false,
                allowDismissOnlyAfterSave: allowDismissOnlyAfterSave || false,
                callOnDismiss
            }
        });
        await modal.present();
    }

    async showInviteAdmin(callOnDismiss?: () => void) {
        const modal = await this.modalCtrl.create({
            component: InviteAdminPage,
            showBackdrop: true,
            // swipeToClose: true,
            componentProps: {
                // isSchoolRequired: isSchoolRequiredLocal || false,
                // allowDismissOnlyAfterSave: allowDismissOnlyAfterSave || false,
                callOnDismiss
            }
        });
        await modal.present();
    }

    async showInviteEducatorsPage(backdropDismiss = true, userInitiated?: boolean, callOnDismiss?: (result: InviteEducatorsPageResult) => void) {
        const modal = await this.modalCtrl.create({
            component: InviteEducatorsPage,
            showBackdrop: true,
            // swipeToClose: true,
            backdropDismiss,
            componentProps: {
                callOnDismiss,
                userInitiated: userInitiated || false
            }
        });
        await modal.present();
    }

    /**
     * Adds video to group selected by user in the alert
     *
     * @param videoId The video ID of the video to add
     */
    async showAddVideoToClassDialog(
        videoId: string,
        resultHandler?: (
            result: 'cancel' | 'new_class' | 'added' | 'already_in_class' | 'error',
            addResponse?: GroupVideoResponse
        ) => void
    ) {
        const alertInputs = [];

        // Add create new class option
        alertInputs.push({
            type: 'radio',
            label: this.translate.instant('new_class'),
            value: 'add-new-class',
            checked: true,
        });

        // Add classes to alertInputs
        for (const group of this.appData.groupsForUser) {
            alertInputs.push({
                type: 'radio',
                label: group.name,
                value: group,
            });
        }


        const alert = await this.alertCtrl.create({
            header: this.translate.instant('tooltip_add_video_to_group'),
            inputs: alertInputs,
            buttons: [
                {
                    text: this.translate.instant('btn_cancel'),
                    role: 'cancel',
                    handler: () => {
                        if (resultHandler) {
                            resultHandler('cancel');
                        }
                    },
                },
                {
                    text: this.translate.instant('btn_ok'),
                    handler: async (selectedGroup) => {
                        if (selectedGroup === 'add-new-class') {
                            if (resultHandler) {
                                resultHandler('new_class');
                            }
                            // Save video_id
                            this.appData.newClassVideoId = videoId;
                            this.navCtrl.navigateForward('educator-class/new');
                        } else {
                            const loader = await this.loadingCtrl.create({
                                message: 'Fetching your subjects'
                            });
                            await loader.present();
                            const subjectsObservable = this.groupApi.getSubjectsForEducator(selectedGroup._id);
                            const alertInputsSubjects = [];
                            subjectsObservable.subscribe(async response => {
                                await loader.dismiss();
                                const language = this.appData.getLanguage();
                                const subjectsInputs = response.subjects.regular_subjects;
                                subjectsInputs.sort((subject1, subject2) => {
                                    const name1 = subject1.name[language] || subject1.name['en'];
                                    const name2 = subject2.name[language] || subject2.name['en'];

                                    // Use localeCompare for case-insensitive alphabetical sorting
                                    return name1.localeCompare(name2, language, { sensitivity: 'base' });
                                });


                                const alertInputsSubjects: {}[] = subjectsInputs.map(subject => ({
                                    type: 'radio',
                                    label: subject.name[language] || subject.name['en'],
                                    value: subject._id,
                                }));

                                if (subjectsInputs.length === 1) {
                                    this.groupApi.addVideo(selectedGroup._id, videoId, null, null, subjectsInputs[0]._id).subscribe(
                                        (res) => {
                                            let message;
                                            if (res.success) {
                                                message = this.translate.instant('added_video_to_class', {
                                                    className: selectedGroup.name,
                                                });
                                                if (resultHandler) {
                                                    resultHandler('added', res);
                                                }
                                            } else if (!res.code || res.code === 'GROUPVIDEO_VIDEO_ALREADY_IN_GROUP') {
                                                message = this.translate.instant('video_already_in_class', {
                                                    className: selectedGroup.name,
                                                });
                                                if (resultHandler) {
                                                    resultHandler('already_in_class', res);
                                                }
                                            } else {
                                                message = res.msg || 'Unknown error';
                                                if (resultHandler) {
                                                    resultHandler('error', res);
                                                }
                                            }
                                            this.uiUtils.displayToast(message);
                                        },
                                        (err) => {
                                            this.logger.error(
                                                'Error - put video in group by group id',
                                                err
                                            );
                                            if (resultHandler) {
                                                resultHandler('error');
                                            }
                                            switch (err.status) {
                                                case 400:
                                                    this.uiUtils.showErrorAlert(
                                                        this.translate.instant('invalid_input')
                                                    );
                                                    break;
                                                case 401:
                                                    this.uiUtils.showErrorAlert(
                                                        this.translate.instant(
                                                            'not_same_class_video_language'
                                                        )
                                                    );
                                                    break;
                                                case 404:
                                                    this.uiUtils.showErrorAlert(
                                                        this.translate.instant('class_or_user_not_found')
                                                    );
                                                    break;
                                            }
                                        }
                                    );
                                } else {
                                    const alertSubjects = await this.alertCtrl.create({
                                        header: this.translate.instant('tooltip_select_subject'),
                                        inputs: alertInputsSubjects,
                                        buttons: [
                                            {
                                                text: this.translate.instant('btn_cancel'),
                                                role: 'cancel',
                                                handler: () => {
                                                    if (resultHandler) {
                                                        resultHandler('cancel');
                                                    }
                                                },
                                            },
                                            {
                                                text: this.translate.instant('btn_ok'),
                                                handler: async (selectedSubject) => {
                                                    if (!selectedSubject) {
                                                        return false;
                                                    }
                                                    this.groupApi.addVideo(selectedGroup._id, videoId, null, null, selectedSubject).subscribe(
                                                        (res) => {
                                                            let message;
                                                            if (res.success) {
                                                                message = this.translate.instant('added_video_to_class', {
                                                                    className: selectedGroup.name,
                                                                });
                                                                if (resultHandler) {
                                                                    resultHandler('added', res);
                                                                }
                                                            } else if (!res.code || res.code === 'GROUPVIDEO_VIDEO_ALREADY_IN_GROUP') {
                                                                message = this.translate.instant('video_already_in_class', {
                                                                    className: selectedGroup.name,
                                                                });
                                                                if (resultHandler) {
                                                                    resultHandler('already_in_class', res);
                                                                }
                                                            } else {
                                                                message = res.msg || 'Unknown error';
                                                                if (resultHandler) {
                                                                    resultHandler('error', res);
                                                                }
                                                            }
                                                            this.uiUtils.displayToast(message);
                                                        },
                                                        (err) => {
                                                            this.logger.error(
                                                                'Error - put video in group by group id',
                                                                err
                                                            );
                                                            if (resultHandler) {
                                                                resultHandler('error');
                                                            }
                                                            switch (err.status) {
                                                                case 400:
                                                                    this.uiUtils.showErrorAlert(
                                                                        this.translate.instant('invalid_input')
                                                                    );
                                                                    break;
                                                                case 401:
                                                                    this.uiUtils.showErrorAlert(
                                                                        this.translate.instant(
                                                                            'not_same_class_video_language'
                                                                        )
                                                                    );
                                                                    break;
                                                                case 404:
                                                                    this.uiUtils.showErrorAlert(
                                                                        this.translate.instant('class_or_user_not_found')
                                                                    );
                                                                    break;
                                                            }
                                                        }
                                                    );
                                                }
                                            }
                                        ]
                                    });
                                    await  alertSubjects.present();
                                }
                            });

                        }
                    },
                },
            ],
        });

        await alert.present();
    }

    /**
     * Shows an alert that prompts the user do download the app from the store.
     * Saves the timestamp to avoid showing the dialog too often.
     */
    async checkShowAppStoreRedirect() {
        if (this.plt.is('android') && this.plt.is('mobileweb')) {
            const lastAttempt = this.appData.getPreferenceString(
                this.constants.pref.ASK_APP_INSTALLATION_TIMESTAMP,
                '2022-01-01T00:00:00.000Z',
                true
            );
            const momentLastAttempt = moment(lastAttempt);
            // this.logger.debug('#### Android, mobileweb. momentLastAttempt:', momentLastAttempt);

            // Show again after 7 days
            if (moment().diff(momentLastAttempt, 'days') > 7) {
                const saveLastAttempt = () => {
                    this.appData.savePreferenceString(
                        this.constants.pref.ASK_APP_INSTALLATION_TIMESTAMP,
                        moment().toISOString(),
                        true
                    );
                };

                const i18n = await this.translate.get([
                    'install_app_header',
                    'install_app_message',
                    'install_app_button_no',
                    'install_app_button_play_store',
                ]).toPromise();

                const alert = await this.alertCtrl.create({
                    header: i18n['install_app_header'],
                    message: i18n['install_app_message'],
                    buttons: [
                        {
                            text: i18n['install_app_button_no'],
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                console.log('No, thanks');
                                saveLastAttempt();
                            },
                        },
                        {
                            text: i18n['install_app_button_play_store'],
                            handler: () => {
                                console.log('Confirm Okay');
                                saveLastAttempt();
                                window.open(CONSTANTS.PLAY_STORE_URL);
                            },
                        },
                    ],
                });

                setTimeout(async () => {
                    await alert.present();
                }, 1000);
            }
        }
    }
}
