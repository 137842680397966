import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from './api.service';
import { IVideoCategory } from 'src/app/interfaces/IVideoCategory';

/**
 * API for /videocategories/
 * See http://localhost:3005/api-docs/#/videocategories
 */
@Injectable({
    providedIn: 'root'
})
export class VideoCategoriesApi {
    constructor(public api: Api) {
        // Do nothing.
    }

    /**
     * Retrieve all available video catepries
     */
    getVideoCategories(): Observable<IVideoCategory[]> {
        return this.api.get('videocategories');
    }

}
