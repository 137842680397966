import { Component, OnInit, Input, ViewEncapsulation, ViewChild } from '@angular/core';
import { IVideo } from '../../interfaces/IVideo';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from '../../services/app-data.service';
import { Constants } from 'src/app/app.constants';
import { IVideoPlayerTaskOverlayListener } from './video-player-task-overlay.interfaces';
import { ETaskType, ITask, ITaskAnswerOption } from '../../interfaces/ITask';
import * as _ from 'lodash';
import * as moment from 'moment';
import { TasksApi, ITasksProgressionResultResponse } from '../../services/api/tasks.service';
import { ITaskAnswer } from '../../interfaces/ITaskAnswer';
import { ITasksProgression } from '../../interfaces/ITasksProgression';
import { Platform } from '@ionic/angular';
import { IClip } from 'src/app/interfaces/IClip';
import { ITranslation } from 'src/app/interfaces/ITranslation';

export enum State {
    not_answered = 'not_answered',
    answered = 'answered',
    show_result = 'show_result',
    done = 'done',
}

export enum VjsMarkerClass {
    highlight = 'vjs-marker-highlight',
    disabled = 'vjs-marker-disabled',
}

/**
 * Component that shows tasks functionality (for students) as a video player overlay.
 * For example, it shows multiple choice questions or survey questions, plus the result.
 */
@Component({
    selector: 'video-player-task-overlay',
    templateUrl: './video-player-task-overlay.component.html',
    styleUrls: ['./video-player-task-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerTaskOverlayComponent implements OnInit {
    @Input() video: IVideo; // the video
    @Input() isEmbed = false;

    @Input() listener: IVideoPlayerTaskOverlayListener;

    @Input() task: ITask;
    shuffledAnswers: ITaskAnswerOption[]; // Answers copied from the current task
    @Input() clip: IClip;
    @Input() class_id: string; // ID of the class = group

    @Input() videoJSplayer: any;

    tasks: ITask[] = null;
    followUpTasks: ITask[]; // Tasks that will be shown directly after the current task was shown

    state: State = State.not_answered;

    selectedAnswers: ITaskAnswerOption[] = [];

    public shownTaskIds: string[] = [];

    stateEnum: typeof State = State;
    taskTypeEnum: typeof ETaskType = ETaskType;

    answer: ITaskAnswer;
    tasksProg_id: string;
    progStartAt: moment.Moment;
    tasksProgressionResult: ITasksProgressionResultResponse;

    constructor(
        private logger: NGXLogger,
        public translate: TranslateService,
        public uiUtils: UiUtils,
        public appData: AppData,
        public constants: Constants,
        public tasksApi: TasksApi,
        public plt: Platform
    ) {
        // empty
        this.logger.debug('VideoPlayerTaskOverlayComponent constructor');
    }

    ngOnInit() {
        this.logger.debug('VideoPlayerTaskOverlayComponent.ngOnInit()');
        this.initNewVideo();
    }

    initNewVideo() {
        this.logger.debug('VideoPlayerTaskOverlayComponent.initNewVideo()');
        this.selectedAnswers = [];
        this.tasksProg_id = null;
        this.shownTaskIds = [];
        this.progStartAt = null;
        this.tasksProgressionResult = null;
        this.task = null;
        this.shuffledAnswers = null;
    }

    initNewTask(task: ITask, followUpTasks?: ITask[]) {
        this.logger.debug('VideoPlayerTaskOverlayComponent.initNewTask()');
       console.log('VideoPlayerTaskOverlayComponent.initNewTask() ', task);
        this.task = task;
        // Shuffle (randomize) answers, see https://gitlab.com/uugotitTeam/uugot.it/-/issues/267#note_798465871
        if (this.task.type === ETaskType.multiple_choice) {
            this.shuffledAnswers = _.shuffle(this.task.answers);
        } else {
            this.shuffledAnswers = this.task.answers;
        }
        this.followUpTasks = followUpTasks;
        this.state = State.not_answered;
        this.selectedAnswers = [];
        this.answer = null;
        this.setVjsMarkerClass(VjsMarkerClass.highlight);
    }

    initialVideoPlayStarted() {
        this.logger.debug('VideoPlayerTaskOverlayComponent.initialVideoPlayStarted()');
        this.progStartAt = moment();
    }

    get isShown(): boolean {
        return this.task !== null || this.state === this.stateEnum.show_result;
    }

    selectAnswer(answerOption: ITaskAnswerOption): void {
        if (this.state !== State.not_answered) {
            return;
        }
        if (this.task.type === ETaskType.survey) {
            this.selectedAnswers = [answerOption];
        } else {
            const index = this.selectedAnswers.findIndex((a) => a._id === answerOption._id);
            if (index > -1) {
                this.selectedAnswers.splice(index, 1);
            } else {
                this.selectedAnswers.push(answerOption);
            }
        }
    }

    /**
     * The submit button is disabled if the type is survey and no select
     * @returns true if the submit button is disabled
     */
    isSubmitDisabled(): boolean {
        return (
            this.state === State.not_answered &&
            this.task.type === ETaskType.survey &&
            (!this.selectedAnswers || this.selectedAnswers.length === 0)
        );
    }

    isSelectedAnswer(answerOption: ITaskAnswerOption): boolean {
        return this.selectedAnswers.findIndex((a) => a._id === answerOption._id) !== -1;
    }

    isAnswersCorrect(): boolean {
        return _.isEqual(
            this.task.answers.filter((a) => a.isCorrect).sort(),
            this.selectedAnswers.sort()
        );
    }

    async submitAnswer() {
        if (this.task.type !== ETaskType.survey) {
            // In order to avoid a UI update, update new state at the end of the function if it's a survey
            this.state = State.answered;
        }

        // ### Create a tasks progression first if one doesn't exist yet
        if (!this.tasksProg_id) {
            const tasksProg: ITasksProgression = {
                user_id: this.appData.authenticatedUser?._id,
                group_id: this.class_id,
                installationID: this.appData.getInstallationId(),
                clip_id: this.clip?._id,
                task_ids: this.tasks.map((t) => t._id),
                video_id: this.task.video_id,
                answers: [],
                startAt: this.progStartAt.toDate(),
                msSinceStart: undefined,
                isFinished: false,
            };
            let newTasksProgObj
            if (this.appData.authenticatedUser) {
                newTasksProgObj = await this.tasksApi.addProgression(tasksProg).toPromise();
            } else {
                newTasksProgObj = await this.tasksApi.addProgressionForAnonymousUser(tasksProg).toPromise();
            }
            this.logger.debug('Created new tasks progression', newTasksProgObj);
            this.tasksProg_id = newTasksProgObj.data?._id;
        }

        // ### Save the answer
        const selected = this.selectedAnswers.map((a) => a._id);

        const answer: ITaskAnswer = {
            user_id: this.appData.authenticatedUser?._id,
            installationID: this.appData.getInstallationId(),
            group_id: this.class_id,
            clip_id: this.clip?._id,
            tasksProg_id: this.tasksProg_id || undefined,
            task_id: this.task._id,
            video_id: this.task.video_id,
            type: this.task.type,
            selected,
            msSinceStart: moment().diff(this.progStartAt, 'milliseconds', false),
        };
        const newAnswerObj = await this.tasksApi.addAnswer(answer).toPromise();
        this.logger.debug('Submitted new answer', newAnswerObj);
        if (newAnswerObj && newAnswerObj.data) {
            this.answer = newAnswerObj.data;
            if (!this.tasksProg_id) {
                this.tasksProg_id = newAnswerObj.data.tasksProg_id;
            }
        }

        this.setVjsMarkerClass(
            VjsMarkerClass.disabled.toString(),
            VjsMarkerClass.highlight.toString()
        );

        if (this.task.type === ETaskType.survey) {
            // If it's a survey, continue watching
            this.state = State.answered;
            this.continueWatching();
        }
    }
    continueWatching() {
        this.task = null;
        const allTaskIds = this.tasks.map((task) => task._id).sort();
        const isFinished = _.isEqual(allTaskIds, this.shownTaskIds.sort());
        if (this.followUpTasks && this.followUpTasks.length > 0) {
            const nextTask = this.followUpTasks.shift();
            this.initNewTask(nextTask, this.followUpTasks);
            return;
        }
        if (isFinished) {
            this.showResult();
        } else {
            this.listener.playVideo();
        }
    }

    /**
     * Closes the question and doesn't submit the answer - used for preview of tasks for educators.
     */
    continueWithoutSaving() {
        this.state = State.not_answered;
        this.selectedAnswers = [];
        this.setVjsMarkerClass(
            VjsMarkerClass.disabled.toString(),
            VjsMarkerClass.highlight.toString()
        );
        this.task = null;
        if (this.followUpTasks && this.followUpTasks.length > 0) {
            const nextTask = this.followUpTasks.shift();
            this.initNewTask(nextTask, this.followUpTasks);
            return;
        }
        // const addTime = 0.5; // we need to add some time so that the tasks doesn't show up again instantly
        // this.videoJSplayer.currentTime(this.videoJSplayer.currentTime() as number + addTime);
        this.listener.playVideo();
    }

    async showResult() {
        this.state = State.show_result;
        const result = await this.tasksApi.getResultByProgression(this.tasksProg_id).toPromise();
        this.tasksProgressionResult = result;
        this.logger.debug('Got tasks result', result);
    }

    continueAfterResult() {
        this.state = State.done;
        this.selectedAnswers = [];
        this.task = null;
        this.listener.playVideo();
    }

    rewatchAfterResult() {
        this.state = State.not_answered;
        this.initNewVideo();
        this.progStartAt = moment();
        this.resetVjsMarkerClassForAllTasks();
        this.listener.replayVideoFromStart();
    }

    isDone() {
        return this.state === State.done;
    }

    setVjsMarkerClass(addClass?: string, removeClass?: string) {
        const markerElement = document.getElementsByClassName(`vjs-marker-${this.task._id}`)[0];
        if (markerElement) {
            if (addClass) {
                markerElement.classList.add(addClass);
            }
            if (removeClass) {
                markerElement.classList.remove(removeClass);
            }
        }
    }

    resetVjsMarkerClassForAllTasks() {
        this.tasks.forEach((t) => {
            const markerElement = document.getElementsByClassName(`vjs-marker-${t._id}`)[0];
            markerElement.classList.remove(VjsMarkerClass.disabled);
            markerElement.classList.remove(VjsMarkerClass.highlight);
        });
    }

    get bottomRightButtonSize(): string {
        return this.plt.is('mobile') && !this.plt.is('phablet') && !this.plt.is('ipad')
            ? 'medium'
            : 'medium';
    }

    get isOnlySurveyQuestions(): boolean {
        if (!this.tasks) {
            return false;
        }
        return this.tasks?.filter((t) => t.type !== ETaskType.survey).length === 0;
    }

    /**
     * This moves the position of tasks to the end of subtitle frames in which they are located.
     * Only if the end is less than 10 seconds away.
     * @param tasks the tasks
     * @param translation the translation
     * @param maxTime the duration of the video (position of a task will not be moved if a chunk ends after maxTime)
     */
    moveTasksToEndOfSubtitleFrames(tasks: ITask[], translation: ITranslation, maxTime: number = null) {
        tasks.forEach((task) => {
            const inChunk = translation?.subtitles?.find((sub) => {
                return task.position > sub.start && task.position < sub.end;
            });
            const isAfterEnd = !_.isNil(maxTime) && inChunk && inChunk.end > maxTime;
            if (inChunk && inChunk.end - task.position < 10 && !isAfterEnd) {
                this.logger.info(
                    `moveTasksToEndOfSubtitleFrames moving task from ${task.position} to ${inChunk.end}`
                );
            }
        });
    }
}
