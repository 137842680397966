<ion-header>
    <ion-toolbar color="blue">
        <ion-buttons slot="start">
            <ion-menu-toggle>
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
        <ion-buttons slot="end" *ngIf="plt.is('mobile')">
            <ion-button (click)="createClass()" title="{{ 'btn_create_class' | translate }}"> <!-- [disabled]="!classCreateForm.valid" -->
                <ion-icon slot="icon-only" name="checkmark"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>
            {{ "new_class" | translate }}
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding-end ion-padding-start">
    <form [formGroup]="classCreateForm">
        <!-- We use a grid to have a fixed width once the screen gets larger -->
        <ion-grid class="ion-no-padding" fixed>
            <ion-row>
                <ion-col size="12">
                    <div class="alert alert-info ion-margin-top" *ngIf="clonedGroup && !clonedGroup.isTemplate">
                        {{ "cloning_class" | translate:{className: clonedGroup.name} }}
                    </div>
                    <div class="alert alert-info ion-margin-top" *ngIf="clonedGroup && clonedGroup.isTemplate">
                        {{ "cloning_template_class" | translate:{className: clonedGroup.name} }}
                    </div>
                    <ion-list>
                        <!-- CLASS NAME: -->
                        <ion-item class="ion-no-padding">
                            <ion-label position="floating"
                                [class.red-text]="!classCreateForm.controls.className.valid && submitAttempt">
                                {{ "class_name" | translate }} <sup>*</sup>
                            </ion-label>
                            <ion-input type="text" formControlName="className"></ion-input>
                        </ion-item>

                        <div>
                            <ion-label class="validator-errors" color="danger">
                                <ng-container *ngFor="let validation of validationMessages.className">
                                    <p *ngIf="classCreateForm.get('className').hasError(validation.type) && (classCreateForm.get('className').dirty || classCreateForm.get('className').touched)"
                                        [innerHTML]="validation.message">
                                    </p>
                                </ng-container>
                            </ion-label>
                        </div>

                        <!-- DESCRIPTION: -->
                        <ion-item class="ion-no-padding">
                            <ion-label position="floating"
                                [class.red-text]="!classCreateForm.controls.description.valid && submitAttempt">
                                {{"description" | translate }} <sup>*</sup>
                            </ion-label>
                            <ion-textarea formControlName="description"></ion-textarea>
                        </ion-item>

                        <div>
                            <ion-label class="validator-errors" color="danger">
                                <ng-container *ngFor="let validation of validationMessages.description">
                                    <p *ngIf="classCreateForm.get('description').hasError(validation.type) && (classCreateForm.get('description').dirty || classCreateForm.get('description').touched)"
                                        [innerHTML]="validation.message">
                                    </p>
                                </ng-container>
                            </ion-label>
                        </div>

                        <!-- LANGUAGE TO LEARN: -->
                        <ion-item class="ion-no-padding">
                            <ion-label [class.red-text]="!classCreateForm.controls.language.valid && submitAttempt">
                                {{"language_to_learn" | translate }} <sup>*</sup>
                            </ion-label>
                            <ion-select formControlName="language" [placeholder]="'lang_de' | translate" [interfaceOptions]="{header: translate.instant('language_to_learn')}">
                                <ion-select-option value="de">{{ "lang_de" | translate }}</ion-select-option>
                            </ion-select>
                        </ion-item>

                        <div>
                            <ion-label class="validator-errors" color="danger">
                                <ng-container *ngFor="let validation of validationMessages.language">
                                    <p *ngIf="classCreateForm.get('language').hasError(validation.type) && (classCreateForm.get('language').dirty || classCreateForm.get('language').touched)"
                                        [innerHTML]="validation.message">
                                    </p>
                                </ng-container>
                            </ion-label>
                        </div>

                        <!-- START DATE: -->
                        <ion-item class="ion-no-padding">
                            <ion-label [class.red-text]="!classCreateForm.controls.startDate.valid && submitAttempt">
                                {{"start_date" | translate }} <sup>*</sup>
                            </ion-label>
                            <ion-datetime *ngIf="!showCustomDatePicker" formControlName="startDate" [min]="minString"
                                [max]="maxString"></ion-datetime>
                            <ion-input *ngIf="showCustomDatePicker" class="date-picker-input" readonly formControlName="startDate" #inputStartDatePicker
                                [liIonic4Datepicker]="datePickerObjStart"
                                (ionchange)="datePickerChanged()" clearInput="false">
                            </ion-input>
                            <ion-icon class="calendar ion-margin-start" item-right name="calendar" slot="end" (click)="focusStartDatePicker()"></ion-icon>
                        </ion-item>

                        <div>
                            <ion-label class="validator-errors" color="danger">
                                <ng-container *ngFor="let validation of validationMessages.startDate">
                                    <p *ngIf="classCreateForm.get('startDate').hasError(validation.type) && (classCreateForm.get('startDate').dirty || classCreateForm.get('startDate').touched)"
                                        [innerHTML]="validation.message">
                                    </p>
                                </ng-container>
                            </ion-label>
                        </div>

                        <!-- END DATE: -->
                        <ion-item class="ion-no-padding">
                            <ion-label [class.red-text]="!classCreateForm.controls.endDate.valid && submitAttempt">
                                {{"end_date" | translate }} <sup>*</sup>
                            </ion-label>

                            <ion-datetime *ngIf="!showCustomDatePicker" formControlName="endDate" [min]="minString"
                                [max]="maxString"></ion-datetime>

                            <ion-input *ngIf="showCustomDatePicker" class="date-picker-input" readonly formControlName="endDate" #inputEndDatePicker
                                       [liIonic4Datepicker]="datePickerObjEnd"
                                       (ionchange)="datePickerChanged()" clearInput="false">
                            </ion-input>

                            <ion-icon class="calendar ion-margin-start" item-right name="calendar" slot="end" (click)="focusEndDatePicker()"></ion-icon>
                        </ion-item>

                        <div>
                            <ion-label class="validator-errors" color="danger">
                                <ng-container *ngFor="let validation of validationMessages.endDate">
                                    <p *ngIf="classCreateForm.get('endDate').hasError(validation.type) && (classCreateForm.get('endDate').dirty || classCreateForm.get('endDate').touched)"
                                        [innerHTML]="validation.message">
                                    </p>
                                </ng-container>
                            </ion-label>
                        </div>

                    </ion-list>
                </ion-col>
            </ion-row>
        </ion-grid>

        <p class="ion-text-center" *ngIf="!plt.is('mobile')">
            <ion-button color="blue" (click)="createClass()">  <!--[disabled]="!classCreateForm.valid"-->
                {{ "btn_create_class" | translate }}
            </ion-button>
        </p>
    </form>
</ion-content>
