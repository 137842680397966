import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HideHeaderDirective } from './directives/hide-header.directive';
import { IoniconsRemoveTitleDirective } from './directives/ionicon-remove-title.directive';

@NgModule({
    declarations: [HideHeaderDirective, IoniconsRemoveTitleDirective],
    exports: [HideHeaderDirective, IoniconsRemoveTitleDirective],
    imports: [CommonModule],
})
export class SharedModule {}
