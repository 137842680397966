import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import { Observable, EMPTY, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { GroupsApi } from 'src/app/services/api/groups-api.service';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { NonBlockingResolver } from 'src/app/utils/non-blocking.resolver';
import { NGXLogger } from 'ngx-logger';
import { AppData } from '../../services/app-data.service';
import { TranslateService } from '@ngx-translate/core';
import { AppManager } from '../../services/app-manager.service';

@Injectable({
    providedIn: 'root'
})
export class StudentGroupResolver extends NonBlockingResolver {
    constructor(private _groupsApi: GroupsApi,
        private _uiUtils: UiUtils,
        private _navCtrl: NavController,
        public translate: TranslateService,
        public appManager: AppManager,
        private appData: AppData,
        private _alertCtrl: AlertController,
        private _logger: NGXLogger) {
        super();
    }

    getData(route: ActivatedRouteSnapshot): Observable<any> {
        const class_id = route.params.class_id;

        return this._groupsApi.getById(class_id).pipe(
            catchError(err => {
                return EMPTY;
            }),
            mergeMap(res => {
                if (res.success) {
                    return this._groupsApi.getVideos(class_id, true).pipe(
                        catchError(err => {
                            return EMPTY;
                        }),
                        mergeMap(response => {
                            this._logger.debug('Got groups videos response', response);

                            return this._groupsApi.getLeaderboard(class_id).pipe(
                                catchError(err => {
                                    return EMPTY;
                                }),
                                mergeMap(leaderboardResponse => {
                                    return of({ group: res.data, videos: response.data, leaderboard: leaderboardResponse });
                                })
                            );
                        })
                    );
                } else {
                    if (res.code === 'GROUPS_AFTER_END_DATE') {
                        this.showLeaveClassDialog(res.msg, class_id);
                    } else {
                        this._uiUtils.displayToast(res.msg);
                    }
                    this._navCtrl.navigateRoot('catalog');
                    return EMPTY;
                }
            })
        );
    }

    private async showLeaveClassDialog(message: string, groupId): Promise<void> {
        const alert = await this._alertCtrl.create({
            header: this.translate.instant('class_expired_title'),
            message: this.translate.instant('class_expired_subtitle'),
            buttons: [{
                text: this.translate.instant('btn_remove_class'),
                handler: () => {
                    if (this.appData.getClassIds().length <= 1) {
                        this.appManager.deleteUserInfo();
                    } else {
                        this.appData.removeClassId(groupId);
                        if (this.appData.groupsForUser) {
                            this.appData.groupsForUser = this.appData.groupsForUser.filter(g => g._id !== groupId);
                        }
                    }

                    // Redirect to student login
                    this._navCtrl.navigateRoot('student');
                }
            }],
            backdropDismiss: false
        });

        await alert.present();
    }
}
