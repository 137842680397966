<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-menu-toggle *ngIf="isRootPage">
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
            <ion-button *ngIf="!isRootPage" (click)="navigateBack()">
                <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-buttons slot="secondary" *ngIf='appData.isEducator() && !showSearch'>
            <ion-button (click)='activateSearch()'>
                <ion-icon slot="icon-only" name="search"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-searchbar #search
                       *ngIf="!plt.is('mobile') && showSearch"
                       [(ngModel)]="searchText"
                       showCancelButton="always"
                       (ionBlur)='closeSearchBar()'
                       (ionCancel)='closeSearchBar()'
                       (ionChange)='onSearchChange()'
                       debounce="200"
                       placeholder="Search in My Videos">
        </ion-searchbar>
        <ion-title *ngIf="(!plt.is('mobile') && !showSearch)">
            {{ headerTitle }}
        </ion-title>
        <ion-title *ngIf="plt.is('mobile')">
            {{ headerTitle }}
        </ion-title>

    </ion-toolbar>
    <ion-searchbar #search
                   inputmode='search'
                   *ngIf="plt.is('mobile') && showSearch"
                   [(ngModel)]="searchText"
                   showCancelButton="always"
                   (ionBlur)='closeSearchBar()'
                   (ionCancel)='closeSearchBar()'
                   (ionChange)='onSearchChange()'
                   debounce="600"
                   placeholder="{{ 'search_in_video_library' | translate }}">
    </ion-searchbar>
    <ion-toolbar *ngIf='!showLoadingIndicator && searchText'>
        <ion-title>Search result: {{searchText}}</ion-title>
        <ion-buttons slot="end" *ngIf='!showSearch'>
            <ion-button (click)='cancelSearch()'>
                <ion-icon name="close-outline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<!-- We set a minimum scroll threshold to avoid stange behaviour on iOS with "bouncing" scroll -->
<ion-content scrollEvents="true" appHideHeader [header]="segmentedSwitch" [scrollThreshold]="plt.is('ios') ? 50 : 0">

    <div slot="fixed" class="progressBarFixedTop">
        <ion-progress-bar type="indeterminate" *ngIf="showLoadingIndicator"></ion-progress-bar>
    </div>

    <ion-refresher slot="fixed" #refresher (ionRefresh)="doRefresh($event)" (ionPull)="onRefresherPull($event)">
        <ion-refresher-content pullingText="{{ 'pull_to_refresh' | translate }}"
            refreshingText="{{ 'reloading_videos' | translate }}"></ion-refresher-content>
    </ion-refresher>

    <ion-grid slot="fixed" fixed="true" class="segmentedSwitchWrapperGrid ion-no-padding"  style="padding-left: 10px; padding-right: 10px;" #segmentedSwitch [hidden]="refresherProgress > 0">
        <div class="ion-padding-top ion-padding-bottom" style="background-color: var(--background-color); padding-left: 6px; padding-right: 6px;" *ngIf='!isVideosAvailableForGroupSection'>
            <ion-segment [(ngModel)]="segment" (ionChange)="segmentChanged($event)" style="margin-top: 5px;" mode="ios">
                <ion-segment-button value="favored">
                    <ion-label>{{ 'favored_videos' | translate }}</ion-label>
                </ion-segment-button>
                <ion-segment-button value="recently-watched">
                    <ion-label>{{ 'recently_watched' | translate }}</ion-label>
                </ion-segment-button>
            </ion-segment>
        </div>
    </ion-grid>

    <ion-grid fixed="true" [style.margin-top]="plt.is('ios') ? '59px' : '59px' "><!-- for "md" mode of ion-segment 75px -->

        <div class="ion-padding ion-text-center" [ngSwitch]="segment" *ngIf="getVideos() && getVideos().length === 0">
            <p *ngSwitchCase="'favored'">
                {{ "favored_videos_empty_info" | translate }}
            </p>
            <p *ngSwitchCase="'recently-watched'">
                {{ "recently_watched_empty_info" | translate }}
            </p>
        </div>

        <!-- <ion-searchbar *ngIf="appData.isEducator()" padding-left padding-right placeholder="{{ 'search' | translate }}"></ion-searchbar> -->
        <ion-row *ngIf="getVideos()">
            <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="4" *ngFor="let video of getVideos()">
                <app-video-catalog-card [video]="video" [watchedVideos]="watchedVideos" [tag]="tag" [subjectSlug]="subjectSlug"
                    [favorCallback]="videoFavorCallback">
                </app-video-catalog-card>
            </ion-col>
        </ion-row>
    </ion-grid>

    <ion-infinite-scroll (ionInfinite)="doInfinite($event)">
        <ion-infinite-scroll-content loadingSpinner="circles" loadingText="{{ 'loading_more_broadcasts' | translate }}">
        </ion-infinite-scroll-content>
    </ion-infinite-scroll>
</ion-content>
