import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from './api.service';
import { IListResponse, ISingleObjectResponse } from 'src/app/models/dtos';
import { ITask } from 'src/app/interfaces/ITask';
import { ITaskAnswer } from 'src/app/interfaces/ITaskAnswer';
import { ITasksProgression } from 'src/app/interfaces/ITasksProgression';
import { IClip } from 'src/app/interfaces/IClip';
import { IVideo } from 'src/app/interfaces/IVideo';
import * as _ from 'lodash';

export interface ITasksProgressionResultResponse {
    progression: ITasksProgression;
}

export interface ITasksStatisticsResponse {
    progressions: ITasksProgression[];
    clip: IClip;
    video: IVideo;
}

/**
 * API for /tasks/
 * See http://localhost:3005/api-docs/#/tasks
 */
@Injectable({
    providedIn: 'root',
})
export class TasksApi {
    constructor(public api: Api) {
        // Do nothing.
    }

    /**
     * Creates a new task
     * @param task the Task object
     */
    addTask(task: ITask): Observable<ISingleObjectResponse<ITask>> {
        return this.api.post(`tasks/`, task);
    }

    /**
     * Updates a task
     * @param task the Task object
     */
    updateTask(task: ITask): Observable<ISingleObjectResponse<ITask>> {
        return this.api.put(`tasks/${task._id}`, task);
    }

    /**
     * Retrieve all available subjects
     * @param visib query by visibility (null or undefined = any, 0 = for clip/class, 1 = public)
     */
    getTasksByVideo(
        videoId: string,
        offset?: number,
        limit?: number,
        sort = 'position',
        visib?: null | 0 | 1
    ): Observable<IListResponse<ITask>> {
        const params: any = {
            offset,
            limit,
            sort,
        };
        if (visib !== null && visib !== undefined) {
            params.visib = visib;
        }
        return this.api.get(`tasks/list-by-video/${videoId}`, params);
    }

    /**
     * Creates a new answer for one task (requires clip_id on body)
     * @param answer the TaskAnswer object
     */
    addAnswer(answer: ITaskAnswer): Observable<ISingleObjectResponse<ITaskAnswer>> {
        return this.api.post(`tasks/${answer.task_id}/answer`, answer);
    }

    /**
     * Creates a new task progression (requires clip_id on body)
     * @param answer the TaskAnswer object
     */
    addProgression(
        tasksProgression: ITasksProgression
    ): Observable<ISingleObjectResponse<ITasksProgression>> {
        return this.api.post(`tasks/progression`, tasksProgression);
    }

    /**
     * Creates a new task progression for anonymous user (uugot tv user)(requires clip_id on body)
     * @param answer the TaskAnswer object
     */
    addProgressionForAnonymousUser(
        tasksProgression: ITasksProgression
    ): Observable<ISingleObjectResponse<ITasksProgression>> {
        return this.api.post(`tasks/progression-anonymous`, tasksProgression);
    }


    /**
     * Get all the answers for all the tasks in a progression for the current user.
     */
    getResultByProgression(tasksProg_id: string): Observable<ITasksProgressionResultResponse> {
        return this.api.get(`tasks/progression/${tasksProg_id}/result`);
    }

    /**
     * Get the statistics for tasks assigned to a clip.
     */
    getStatisticsByClip(clipId: string): Observable<ITasksStatisticsResponse> {
        return this.api.get(`tasks/statistics-by-clip/${clipId}`);
    }
}
