import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';

import { Api } from './api.service';
import { IGroup } from '../../interfaces/IGroup';
import { AppData } from '../app-data.service';
import {
    GroupEducatorSubjects,
    GroupResponse,
    GroupsResponse,
    GroupVideoResponse,
    GroupVideosResponse, PendingInvitesToGroupsResponse,
    RegisterStudentResponse,
    RemoveStudentResponse
} from 'src/app/models/dtos';
import { IVideo } from 'src/app/interfaces/IVideo';
import { ISingleObjectResponse, ISingleObjectDeleteResponse, GroupVideosUpdateVisibilityResponse } from '../../models/dtos';
import { Platform } from '@ionic/angular';
import { IGroupVideo } from '../../interfaces/IGroupVideo';
import { IClip, ILeaderboard } from '../../interfaces/IClip';
import { ISubject } from 'src/app/interfaces/ISubject';

/**
 * API for /groups/
 * See http://spyder3-stage.uugot.it/api-docs/#/groups
 */
@Injectable({
    providedIn: 'root'
})
export class GroupsApi {
    constructor(public api: Api, public appData: AppData, private plt: Platform) {
        // do nothing.
    }

    /**
     * Returns a specific group by id, or an error if not found
     *
     * @param id required id to search for
     */
    getById(id: string): Observable<GroupResponse> {
        const params = {
            id
        };
        return this.api.get('groups/groupinfo', params);
    }

    /**
     * Returns a specific group by id for educators
     *
     * @param id The group id
     */
    getByIdForEducator(id: string): Observable<GroupResponse> {
        const params = {
            id
        };
        return this.api.get('groups/educator/groupinfo', params);
    }

    /**
    * Returns group by access code
    *
    * @param accessCode required access_code to search for
    */
    getByCode(accessCode: string): Observable<GroupResponse> {
        return this.api.get(`groups/accesscode/${accessCode}`);
    }

    /**
     * Returns group by access code, to be used by logged in students only
     *
     * @param accessCode The access code of the class
     */
    getByCodeForStudent(accessCode: string): Observable<GroupResponse> {
        return this.api.get(`groups/student/accesscode/${accessCode}`);
    }

    /**
     * Returns all groups of a user (educator)
     *
     * @param userId required userId to search for
     */
    getByUser(userId: string): Observable<GroupsResponse> {
        const params = {
            id: userId
        };
        return this.api.get('groups/educator', params);
    }

    /**
     * Returns all group templates of a user (educator)
     *
     * @param userId required userId to search for
     */
    getTemplatesByUser(userId: string): Observable<GroupsResponse> {
        const params = {
            id: userId
        };
        return this.api.get('groups/educator/templates', params);
    }

    /**
     * Adds a new group to the system
     *
     * @param group Adds a new group to the system
     */
    createGroup(group: IGroup): Observable<GroupResponse> {
        return this.api.post('groups', group);
    }

    /**
     * Adds a video to a group by video & group id
     *
     * @param groupId the group the video should be added to
     * @param videoId the video that should be added to the group
     * @param clipId the ID of the clip to be added to a class (optional)
     * @param clip a clip that will be created - overrides clipId (optional)
     */
    addVideo(groupId: string, videoId: string, clipId?: string, clip?: IClip, selectedSubject?: string): Observable<GroupVideoResponse> {
        const params: any = {
            group_id: groupId,
            video_id: videoId
        };
        if (clipId) {
            params.clip_id = clipId;
        }

        const body: any = {};
        if (clip) {
            body.clip = clip;
        }

        if (selectedSubject) {
            body.subjects = [selectedSubject]
        }

        return this.api.post('groups/videos', body, params);
    }

    updateVideoVisibility(
        groupVideoId: string,
        isVisible: boolean,
        translationType: 'start_and_wait' | 'check_only' = 'check_only'
    ): Observable<GroupVideosUpdateVisibilityResponse> {
        const body = {
            is_visible: isVisible,
        };
        return this.api.put(
            `groups/videos/${groupVideoId}?trans=${isVisible}&translationType=${translationType}`,
            body
        );
    }

    updateVideo(
        groupVideoId: string,
        changes: { clip_id?: string; }
    ): Observable<GroupVideoResponse> {
        const body = changes;
        return this.api.put(`groups/videos/${groupVideoId}`, body);
    }


    translateVideo(groupVideoId: string, wait: boolean) {
        const body = {};
        return this.api.post(`groups/videos/${groupVideoId}/translate?wait=${wait}`, body);
    }


    /**
     * Returns all videos of a group
     *
     * @param groupId required groupId to search for
     */
    getVideos(groupId: string, isVisible?: boolean, assessmentExists?: boolean, subjectId?: string): Observable<GroupVideosResponse> {
        const params = {
            group_id: groupId
        };
        if (assessmentExists !== null && assessmentExists !== undefined) {
            params['assessment_exists'] = assessmentExists;
        }
        if (isVisible !== null && isVisible !== undefined) {
            params['is_visible'] = isVisible;
        }
        if (subjectId) {
            params['subject_id'] = subjectId;
        }
        return this.api.get('groups/videos', params);
    }

    /**
     * Returns all subjects that an educator can teach within a group
     *
     * @param groupId required groupId to search for
     */
    getSubjectsForEducator(groupId: string): Observable<GroupEducatorSubjects> {
        const params = {
            group_id: groupId
        };
        return this.api.get('groups/educator/subjects', params);
    }

    /**
     * Returns video details of a video in a class for students
     *
     * @param classId required class ID to search for
     * @param videoId required video ID to search for
     */
    getUserSpecificClassVideoDetails(classId: string, videoId: string): Observable<IVideo> {
        const params = {
            group_id: classId
        };
        return this.api.get(`groups/videos/details4${this.appData.authenticatedUser.role}/${videoId}`, params);
    }

    /**
     * Returns video details of a video in a class for anonymous user when it is possibly
     *
     * @param classId required class ID to search for
     * @param videoId required video ID to search for
     */
    getPublicSpecificClassVideoDetails(classId: string, videoId: string): Observable<IVideo> {
        const params = {
            group_id: classId
        };
        return this.api.get(`groups/videos/details4public/${videoId}`, params);
    }

    /**
     * Deletes a video from a group
     *
     * @param groupId the group the video should be deleted from
     * @param videoId the video that should be deleted from the group
     */
    deleteVideo(groupId: string, videoId: string): Observable<GroupVideoResponse> {
        const params = {
            group_id: groupId,
            video_id: videoId
        };
        return this.api.delete('groups/videos', params);
    }

    /**
     * Registers a student to a class
     *
     * @param firstName First name of the student
     * @param lastName Last name of the student
     * @param groupAccessCode Access code of the class
     */
    registerStudent(firstName: string, lastName: string, groupAccessCode: string): Observable<RegisterStudentResponse> {
        const body = {
            first_name: firstName,
            last_name: lastName,
            group_access_code: groupAccessCode,
            installation_id: this.appData.getInstallationId(),
            plt: this.plt.platforms()
        };

        return this.api.post('groups/register-student', body);
    }

    /**
     * Adds the currently logged in user as a student to a class
     *
     * @param groupAccessCode Access code of the class
     */
    addStudent(groupAccessCode: string): Observable<RegisterStudentResponse> {
        const body = {
            access_code: groupAccessCode,
            installation_id: this.appData.getInstallationId(),
            plt: this.plt.platforms()
        };

        return this.api.put('groups/student', body);
    }

    /**
     * Gets all classes for a student
     */
    getStudentClasses(): Observable<GroupsResponse> {
        return this.api.get('groups/student');
    }

    /**
     * List all classes for a student
     */
    listStudentClasses(groupIds: string[]): Observable<GroupsResponse> {
        const body = {
        } as any;
        if (groupIds) {
            body._id = {
                $in: groupIds
            };
        }
        return this.api.post('groups/student/list', body);
    }

    /**
     * Gets the video details for a class video
     *
     * @param videoId The ID of the video
     * @param groupId The ID of the group
     */
    getClassVideoDetails(videoId: string, groupId: string): Observable<IVideo> {
        const params = {
            group_id: groupId
        };

        const role = this.appData.authenticatedUser.role;
        return this.api.delete(`groups/videos/details4${role}/${videoId}`, params);
    }

    /**
     * Removes a student from class
     *
     * @param groupId The ID of the group
     * @param studentId The ID of the student
     */
    removeStudentFromGroup(groupId: string, studentId: string): Observable<RemoveStudentResponse> {
        const body = {
            group_id: groupId,
            user_id: studentId
        };

        return this.api.delete(`groups/educator/removestudent`, null, body);
    }

    /**
     * Removes an educator from class
     *
     * @param groupId The ID of the group
     * @param educatorId The ID of the educator
     */
    removeEducatorFromGroup(groupId: string, educatorId: string): Observable<any> {
        const body = {
            group_id: groupId,
            educator_id: educatorId
        };

        return this.api.put(`groups/educator/remove`, body);
    }

    /**
     * Adds an educator to a class
     *
     * @param groupId Group ID of the class
     */
    addEducator(groupId: string, educatorEmail: string, subjectsIds: ISubject[]): Observable<any> {
        const body = {
            group_id: groupId,
            email: educatorEmail,
            subjects: subjectsIds
        };

        return this.api.post('groups/educator/add', body);
    }

    /**
     * Edits an educator data
     *
     * @param groupId Group ID of the class
     */
    editEducator(groupId: string, educatorId: string, subjectsIds: ISubject[]): Observable<any> {
        const body = {
            group_id: groupId,
            educator_id: educatorId,
            subjects: subjectsIds
        };

        return this.api.put('groups/educator/subjects', body);
    }

    /**
     * Deactivates a group (class)
     *
     * @param groupId the group ID
     */
    deactivateGroup(groupId: string): Observable<ISingleObjectDeleteResponse> {
        return this.api.delete(`groups/educator/deactivate/${groupId}`);
    }

    getLeaderboard(groupId: string): Observable<{ leaderboard: ILeaderboard; }> {
        return this.api.get(`groups/leaderboard/${groupId}`);
    }

    getInvites(groupId: string): Observable<PendingInvitesToGroupsResponse> {
        return this.api.get(`groups/educator/${groupId}/invites`);
    }

    getGroupsFromCustomerAdmin(): Observable<any> {
        return this.api.get(`customers/groups`);
    }

    upgradeTeamEducatorAsMainEducator(groupId: string, educatorId: string): Observable<ISingleObjectResponse<any>> {
        return this.api.put(`groups/educator/upgrademaineducator/${groupId}/${educatorId}`, {});
    }

    /**
     * Get tiles from a group_id
     *
     * @param groupId Group ID of the class
     */
    getTilesFromGroupId(groupId: string): Observable<any> {
        const params = {
            group_id: groupId
        };

        return this.api.get('groups/tiles', params);
    }

    /**
     * Get videos from a group_id and subject_id
     *
     * @param groupId Group ID of the class
     * @param subjectId
     */
    getVideosForGroupIdAndTile(groupId: string, subjectId: string): Observable<any> {
        const params = {
            group_id: groupId
        };

        return this.api.get(`groups/tiles/${subjectId}`, params);
    }
}
