<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-button (click)="navigateBack()">
                <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title *ngIf="group">{{group.name}}
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding leaderboard-content">
    <ion-grid fixed>

        <div class="video-overlay-leaderboard-top-container">
            <div class="video-overlay-leaderboard-top-title">
                <img class="leaderboard-title-flag" src="assets/img/leaderboard/finish_flag_grey_stick.png"
                    alt="flag" />
                <span>{{'season_leaderboard' | translate}}</span>
                <img class="leaderboard-title-flag" src="assets/img/leaderboard/finish_flag_grey_stick.png"
                    alt="flag" />
            </div>
        </div>

        <table class="leaderboard-table">
            <tr [class.leaderboard-entry-hidden]="!isTableHeaderShown()">
                <th>
                </th>
                <th>
                </th>
                <th class="ion-text-center" title="{{'leaderboard_points_tooltip' | translate}}">
                    Points
                </th>
                <th colspan="3" class="ion-text-center">
                    Awards
                </th>
                <th class="ion-text-center">
                    <img class="header-image-video" src="assets/img/leaderboard/video-icon_light_grey.png"
                        title="{{'videos_watched' | translate}}" alt="{{'videos_watched' | translate}}">
                </th>
                <th class="ion-text-center">
                    <img class="header-image-abzeichen" src="assets/img/leaderboard/abzeichen.png"
                        title="{{'tasks_completed' | translate}}" alt="{{'tasks_completed' | translate}}">
                </th>
            </tr>
            <ng-container *ngIf="leaderboard">
                <ng-container *ngFor="let entry of leaderboard.entries; let i = index">
                    <tr *ngIf="i < maxShownEntries" [class.leaderboard-own-rank-row]="isMyOwnRank(entry)"
                        [class.leaderboard-entry-hidden]="!entry.isEntryShown"
                        [class.leaderboard-entry-all-shown]="isTableHeaderShown()">
                        <!-- Only show first 10 entries -->
                        <td class="leaderboard-pos-cell">{{entry.pos}}.</td>
                        <td class="leaderboard-name-cell">{{entry.first_name}} {{entry.last_name.toUpperCase()}}</td>
                        <td class="leaderboard-score-cell leaderboard-score-{{getColorForEntry(entry, leaderboard.entries)}}">
                            {{entry.score}}</td>
                        <td class="leaderboard-medal-cell">
<!--                            <ng-container *ngIf="entry.award1">-->
                                {{entry.award1}}x
                            <!--                            </ng-container>-->
                            <img class="leaderboard-medal-image" src="assets/img/leaderboard/medal_1_gold.png"
                                    alt="gold medal" />
                        </td>
                        <td class="leaderboard-medal-cell">
<!--                            <ng-container *ngIf="entry.award2">-->
                                {{entry.award2}}x
                                <img class="leaderboard-medal-image" src="assets/img/leaderboard/medal_2_silver.png"
                                    alt="silver medal" />
<!--                            </ng-container>-->
                        </td>
                        <td class="leaderboard-medal-cell">
<!--                            <ng-container *ngIf="entry.award3">-->
                                {{entry.award3}}x
                                <img class="leaderboard-medal-image" src="assets/img/leaderboard/medal_3_bronze.png"
                                    alt="bronze medal" />
<!--                            </ng-container>-->
                        </td>
                        <td class="leaderboard-numWatched-cell">
                            {{entry.numWatched}}
                        </td>
                        <td class="leaderboard-numTasks-cell">
                            {{entry.numTasks}}
                        </td>
                    </tr>
                </ng-container>
            </ng-container>

        </table>
    </ion-grid>

</ion-content>
