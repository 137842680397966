<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-title>{{ "invite_colleagues" | translate }}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="dismiss('cancel')" *ngIf="dismissPageEnabled">
                <ion-icon slot="icon-only" name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <!--<ion-grid fixed>-->
    <div class="ion-padding-start ion-padding-end">
        <p>
            {{'invite_colleagues_text' | translate}}
        </p>
        <p class="content-infotext" style="font-size: 0.88em; margin-bottom: 0px;">
            uugot.it sCOOLing steht momentan Lehrkräften von Schulen kostenfrei zur Verfügung.
        </p>
        <p style="margin-top: 5px; margin-bottom: 10px;">
            <ion-button size="small" color="light" (click)="showMoreInfo()">
                <ion-icon slot="start" name="information-circle"></ion-icon> {{'btn_more_info' | translate}}
            </ion-button>
        </p>
    </div>
    <form [formGroup]="inviteForm" (ngSubmit)="onSubmitForm()">
        <ion-list lines="full" class="ion-no-margin ion-no-padding">
            <div *ngFor="let control of emails.controls; index as i">
                <ion-item>
                    <ion-label position="floating">{{'email' | translate}} {{i+1}} <ion-text *ngIf="isRequiredField(emails.controls[i])">*</ion-text></ion-label>
                    <ion-input *ngIf="i < emails.controls.length - 1" [formControl]="emails.controls[i]" type="email" debounce="500" inputmode="email" maxlength="50" enterkeyhint="next"></ion-input>
                    <ion-input *ngIf="i == emails.controls.length - 1" [formControl]="emails.controls[i]" type="email" debounce="500" inputmode="email" maxlength="50" enterkeyhint="done"></ion-input>
                </ion-item>

                <div class="ion-padding-start ion-padding-end">
                    <ion-label class="validator-errors" color="danger"
                        *ngIf="emails.controls[i].invalid && (emails.controls[i].dirty || emails.controls[i].touched)">
                        <ng-container *ngFor="let validation of validationMessages.email">
                            <p *ngIf="emails.controls[i].hasError(validation.type) && (emails.controls[i].dirty || emails.controls[i].touched)"
                                [innerHTML]="validation.message">
                            </p>
                        </ng-container>
                    </ion-label>
                </div>
            </div>

        </ion-list>

        <div class="ion-padding">
            <ion-button size="small" color="light" (click)="addEmailField(false)">
                <ion-icon slot="start" name="add"></ion-icon> {{'btn_further_address' | translate}}
            </ion-button>
        </div>

        <div class="ion-padding">
            <ion-button expand="block" type="submit" class="ion-no-margin"
                [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'"
                [disabled]="submitButtonDisabled()">
                {{'btn_invite_now' | translate}}
            </ion-button>
        </div>
    </form>
    <!--</ion-grid>-->
</ion-content>
