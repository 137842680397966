import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NavController, AlertController } from '@ionic/angular';
import { AppData } from 'src/app/services/app-data.service';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { TranslationCorrectionsApi } from 'src/app/services/api/translationcorrections-api.service';
import { SavedWord } from 'src/app/models/saved-word.model';
import { IWordCatalog } from 'src/app/interfaces/IWordCatalog';
import { IGroup } from 'src/app/interfaces/IGroup';
import { ETranslationCorrectionSource } from '../../interfaces/ITranslationCorrection';

@Component({
    selector: 'translation-correction',
    templateUrl: 'translation-correction-page.html',
    styleUrls: ['translation-correction-page.scss'],
})
export class TranslationCorrectionPage {
    existingRule: any;
    translation: SavedWord|IWordCatalog;
    correctionForm: UntypedFormGroup;
    submitAttempt = false;
    class: IGroup;

    constructor(private _router: Router,
        public appData: AppData,
        public navCtrl: NavController,
        public alertCtrl: AlertController,
        public translate: TranslateService,
        public formBuilder: UntypedFormBuilder,
        public translationCorrectionApi: TranslationCorrectionsApi,
        public uiUtils: UiUtils) {

        const navState = this._router.getCurrentNavigation().extras.state;
        this.translation = navState.word;
        this.class = navState.group;

        console.log('Got translation: ', this.translation);

        const nounPOS = ['NOUN', 'PROPN'];
        if (this.translation.fromToken?.text && this.translation.fromToken?.pos) {
            if (!nounPOS.includes(this.translation.fromToken.pos)) {
                this.translation.fromWord = this.translation.fromWord.toLowerCase();
                this.translation.fromToken.text = this.translation.fromToken.text.toLowerCase();
            }
        }

        // Check if rule exists
        this.translationCorrectionApi.getTranslationSuggestions(this.translation.from, this.translation.to, 'pending', this.translation.fromWord)
            .subscribe(res => {
                if (res.success) {
                    this.existingRule = res.data;
                }
            });

        this.correctionForm = this.formBuilder.group({
            suggestion: ['', [Validators.required, Validators.maxLength(50)]],
            caseSensitive: {
                value: false,
                disabled: this.translation?.fromToken?.pos, // if it has a POS, then case-sensitive is always false
            },
            comment: ['', [Validators.maxLength(200)]]
        });


    }

    ionViewWillEnter() {
        if (!this.translation) {
            this.navCtrl.navigateRoot('catalog');
        }
    }

    /**
     * Navigates back to word catalog cards page
     */
    navigateBack() {
        if (this.class) {
            this.navCtrl.navigateBack(`word-catalog-cards/${this.class._id}`);
        } else {
            this.navCtrl.navigateBack('word-catalog-cards');
        }
    }

    /**
     * Calls helper to shows confirmation message
     */
    submitTranslation() {
        this.submitAttempt = true;

        if (!this.correctionForm.valid) {
            console.log('Form is not valid');
            return;
        }

        this.showConfirmation();
    }

    /**
     * Saves the translation correction on the server.
     */
    saveTranslationCorrection() {
        const requestBody = {
            video_id: this.translation.video_id,
            translation_id: this.translation.translation_id,
            subtitles_index: this.translation.subtitles_index,
            toWords_index: this.translation.fromWords_index,
            from: this.translation.from,
            to: this.translation.to,
            fromWord: this.translation.fromWord,
            toWord: this.translation.toWord,
            toWordFix: this.correctionForm.value.suggestion,
            rule: 'override',
            rule_file: 'Postwords',
            case_sensitive: this.correctionForm.value.caseSensitive,
            comment: this.correctionForm.value.comment,
            pos: this.translation.fromToken?.pos || undefined,
            lem: this.translation.fromToken?.lemma || undefined,
            src: ETranslationCorrectionSource.USER,
        };

        this.translationCorrectionApi.sendTranslationCorrection(requestBody).subscribe(res => {
            this.uiUtils.displayToast(this.translate.instant('suggestion_sent'));

            this.navCtrl.navigateBack('word-catalog-cards');
        }, err => {
            this.uiUtils.showServerRequestErrorAlert(err);
        });
    }

    /**
     * Shows confirmation alert and sends translation correction request on confirmation
     */
    async showConfirmation() {
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('translation_correction_title'),
            subHeader: this.translate.instant('translation_correction_subtitle', { originalWord: this.translation.fromWord }),
            buttons: [
                {
                    text: this.translate.instant('btn_cancel'),
                    role: 'cancel'
                },
                {
                    text: this.translate.instant('btn_submit'),
                    handler: () => {
                        this.saveTranslationCorrection();
                    }
                }
            ]
        });
        await alert.present();
    }
}
