// angular
import { Injectable, ElementRef } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';

// libraries
import 'rxjs/add/operator/map';
import { Observable, of, from } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

// services
import { AppData } from './app-data.service';
import { UsersApi } from './api/users-api.service';
import { GroupsApi } from './api/groups-api.service';
import { TranslateService } from '@ngx-translate/core';

import { CryptoUtils } from './crypto-utils.service';
import { mergeMap, map } from 'rxjs/operators';
import { AzureBlobUploadResponse, GroupsResponse } from '../models/dtos';
import { environment } from '../../environments/environment';
import { Constants } from '../app.constants';
import jwt_decode from 'jwt-decode';
import { TutorialPage } from '../pages/tutorial/tutorial-page';
import { IUser } from '../interfaces/IUser';
import { IActivityEvent, ActivityAction, ActivityCategory } from '../interfaces/IActivity';
import { ActivityService } from './activity.service';
import { UiUtils } from './ui-utils.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { WatchedVideoApi } from './api/watched-videos-api.service';
import { WatchedVideo } from '../models/watched-video';
import { TermsOfUsePage } from '../pages/about/terms-of-use-page';
import { ICustomer } from '../interfaces/ICustomer';

declare const AzureStorage: any;

/*
* Central app manager class.
*/
@Injectable({
    providedIn: 'root'
})
export class AppManager {
    constructor(public constants: Constants,
        public translate: TranslateService,
        public appData: AppData,
        public uiUtils: UiUtils,
        private usersApi: UsersApi,
        private groupsApi: GroupsApi,
        private watchedVideoApi: WatchedVideoApi,
        private alertCtrl: AlertController,
        private cryptoUtils: CryptoUtils,
        private modalCtrl: ModalController,
        private activityService: ActivityService,
        private analytics: AnalyticsService,
        private navCtrl: NavController,
        private logger: NGXLogger) {
        this.logger.debug('Created new AppManager instance');
    }

    /**
     * Get expiration date of the jwt Token by decoding it
     *
     * @returns The expiration date of jwt Token
     */
    getTokenExpirationDate(token: string): Date {
        const decoded = jwt_decode(token);

        if (decoded.exp === undefined) {
            return null;
        }

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    /**
     * Checks if jwt Token is expired
     *
     * @returns True if expired, otherwise false
     */
    isTokenExpired(token: string): boolean {
        const date = this.getTokenExpirationDate(token);
        if (date === undefined) {
            return false;
        }
        return !(date.valueOf() > new Date().valueOf());
    }

    /**
     * Upon app initialization, check if there is a stored auth token for the user.
     * Returns an Observable (Rx) that will emit a boolean value whether the user was logged in or not.
     * If the user was logged in, and the user hasn't accepted the Terms of Use yet, a dialog will be shown.
     */
    public checkAuthToken(token: string): Observable<boolean> {
        let authToken = token;
        if (!authToken) {
            authToken = this.appData.getPreferenceString(this.constants.pref.AUTH_TOKEN);
            if (authToken) {
                this.logger.debug('checkAuthToken got token from storage'/*, authToken*/);
            } else {
                this.logger.debug('checkAuthToken got no token from storage');
            }
        } else {
            this.logger.debug('checkAuthToken got token passed in to checkAuthToken'/*, authToken*/);
        }

        if (authToken) {
            const tokenExpired = this.isTokenExpired(authToken);

            // If token exists and not expired
            if (tokenExpired) {
                this.logger.debug('Auth token is expired, showing alert and romoving token');
                return this.translate.get(['expired_token_header', 'expired_token_message', 'btn_ok', 'menu_login']).pipe(
                    map(i18n => {
                        this.alertCtrl.create({
                            header: i18n['expired_token_header'],
                            subHeader: i18n['expired_token_message'],
                            buttons: [
                                {
                                    text: i18n['btn_ok'],
                                    role: 'cancel'
                                },
                                {
                                    text: i18n['menu_login'],
                                    handler: () => {
                                        this.logger.debug('Login button clicked');
                                        this.navCtrl.navigateRoot('login_educator', {
                                            queryParams: { segment: 'email' },
                                        });
                                    }
                                }
                            ]
                        }).then(alert => alert.present());
                        this.deleteUserInfo();
                        return false;
                    })
                );

            } else {
                // If user already authenticated
                if (this.appData.authenticatedUser) {
                    return of(true);
                }

                // Get saved user and license
                const user = JSON.parse(this.appData.getPreferenceString(this.constants.pref.LOGGED_USER));
                const license = JSON.parse(this.appData.getPreferenceString(this.constants.pref.LICENSE));
                this.appData.authenticatedUser = user;
                this.appData.userLicense = license;

                this.logger.debug('User is logged in');

                this.checkSavedPreferences();
                return of(true);
            }
        } else {
            this.checkSavedPreferences();
            return of(false);
        }
    }

    /**
     * Called at login/logout to check if current translation language and websource are valid for the user
     */
    checkSavedPreferences() {
        const lang = this.appData.getPreferenceString(this.constants.pref.TRANSLATION_LANG);
        const source = this.appData.getPreferenceString(this.constants.pref.VIDEO_SOURCE);
        if (this.appData.isLoggedIn()) {
            const allowedLangs: string[] = this.appData.userLicense.translation_languages;
            const allowedSources: string[] = this.appData.userLicense.websources;

            const defaultLang =
                window.localStorage[this.constants.pref.TRANSLATION_LANG] ||
                this.constants.DefaultTranslationLang;
            const defaultSource =
                window.localStorage[this.constants.pref.VIDEO_SOURCE] ||
                this.constants.DefaultVideoSource;

            // Check selected translation language
            if (allowedLangs && !allowedLangs.includes(lang)) {
                // Fallback for logged-in users that don't have it stored in the server based profile
                const langNew = allowedLangs.includes(defaultLang) ? defaultLang : allowedLangs[0];
                this.appData.savePreferenceString(this.constants.pref.TRANSLATION_LANG, langNew);
            }

            // Check selected web source
            if (this.appData.isEducator()) {
                if (allowedSources && !allowedSources.includes(source)) {
                    const sourceNew = allowedSources.includes(defaultSource)
                        ? defaultSource
                        : allowedSources[0];
                    this.appData.savePreferenceString(this.constants.pref.VIDEO_SOURCE, sourceNew);
                }
            } else {
                if (allowedSources && this.appData.videoSources) {
                    const sourceInfo = this.appData.videoSources.find((ws) => ws.id === source);

                    if (!allowedSources.includes(source) || sourceInfo.isScooling) {
                        const sourceNew = allowedSources.includes(defaultSource)
                            ? defaultSource
                            : allowedSources[0];
                        this.appData.savePreferenceString(
                            this.constants.pref.VIDEO_SOURCE,
                            sourceNew
                        );
                    }
                }
            }
        } else {
            // Only orftvthek allowed
            const {
                isAllowed,
                allowedWebsourceIds,
                allowedWebsourceIdDefault,
            } = this.isWebsourceIdAllowedForAnonymousUser(source);
            if ((!source || !isAllowed) && allowedWebsourceIds) {
                this.appData.savePreferenceString(
                    this.constants.pref.VIDEO_SOURCE,
                    allowedWebsourceIdDefault
                );
                this.appData.forceReloadVideos();
            }
            if (this.appData.getPreferenceString(this.constants.pref.LOGGED_USER_PREFERENCES)) {
                this.appData.removePreference(this.constants.pref.LOGGED_USER_PREFERENCES);
            }
        }
    }

    /**
     * Returns {isAllowed: true} if the websource ID is allowed for not-logged-in users.
     * @param websourceId for example "orftvthek" or "ndr"
     */
    isWebsourceIdAllowedForAnonymousUser(websourceId): { isAllowed: boolean; allowedWebsourceIds: Array<string>; allowedWebsourceIdDefault: string; } {
        if (!this.appData.videoSources) {
            // Web sources are not loaded yet - synchronization issue with loading the initial data
            return {
                isAllowed: false,
                allowedWebsourceIds: null,
                allowedWebsourceIdDefault: null
            };
        }
        const tvWebsourceIds = this.appData.videoSources.filter(ws => !ws.isScooling).map(ws => ws.id);
        return {
            isAllowed: tvWebsourceIds.includes(websourceId),
            allowedWebsourceIds: tvWebsourceIds,
            allowedWebsourceIdDefault: tvWebsourceIds.find(id => id === this.constants.DefaultVideoSource) || tvWebsourceIds[0]
        };
    }

    /**
     * Initialize preferences for authenticated user with values form local storage.
     * Because we assume that the same user has been using the app before.
     */
    initializePrefsForAuthenticatedUser(): any {
        const user_preferences = {
            video_quality: window.localStorage[this.constants.pref.VIDEO_QUALITY],
            language: window.localStorage[this.constants.pref.LANGUAGE],
            translation_lang: window.localStorage[this.constants.pref.TRANSLATION_LANG],
            video_source: window.localStorage[this.constants.pref.VIDEO_SOURCE],
            player_hide_subtitles: window.localStorage[this.constants.pref.PLAYER_HIDE_SUBTITLES],
            playback_rate_index: window.localStorage[this.constants.pref.PLAYBACK_RATE_INDEX],
        };
        return user_preferences;
    }

    /**
     * Saves user information in local storage after login
     *
     * @param user The logged in user
     * @param token The jwtToken
     * @param license The user license
     * @param prefs The user preferences
     * @param customer
     * @param isUnderAge
     */
    saveUserInfo(user?: IUser, token?: string, license?: any, prefs?: any, customer?: ICustomer, isUnderAge?: boolean) {
        this.logger.debug('saveUserInfo');
        // The following properties will only be saved locally
        if (token) {
            this.appData.savePreferenceString(this.constants.pref.AUTH_TOKEN, token);
        }
        if (user) {
            this.appData.savePreferenceString(this.constants.pref.LOGGED_USER, JSON.stringify(user));
            this.appData.authenticatedUser = user;
        }
        if (license) {
            this.appData.savePreferenceString(this.constants.pref.LICENSE, JSON.stringify(license));
            this.appData.userLicense = license;
        }

        if (customer) {
            this.appData.savePreferenceString(this.constants.pref.LOGGED_USER_CUSTOMER, JSON.stringify(customer));
            this.appData.userCustomer = customer;
        }

        if (isUnderAge) {
            this.appData.savePreferenceString(this.constants.pref.ONLY_KIDS_CONTENT, JSON.stringify(true));
            this.appData.userOnlyKidsContent = true;
        }

        if (prefs) {
            this.appData.savePreferenceString(this.constants.pref.LOGGED_USER_PREFERENCES, JSON.stringify(prefs));
            // We need to set the language in case it changed
            const prefLanguage = this.appData.getLanguage();
            this.translate.use(prefLanguage);

        } else if (user && token && license) {
            this.logger.debug('saveUserInfo - initialize preferences for a logged user');
            const newPrefs = this.initializePrefsForAuthenticatedUser();
            this.appData.savePreferenceString(this.constants.pref.LOGGED_USER_PREFERENCES, JSON.stringify(newPrefs));
            this.usersApi.savePreferencesAll(newPrefs)
                .subscribe(resp => {
                    this.logger.debug('saveUserInfo - Save preferences response:', resp);
                }, err => {
                    this.logger.error('saveUserInfo - Error saving preferences:', err.error);
                });
        }

        this.checkSavedPreferences();

        // Set the analytics user ID to the user ID
        if (user) {
            this.analytics.setAnalyticsUserProperties(user._id, null, null, {
                role: user.role,
                customer_id: user.customer_id,
                profile_city: user.city,
                school_name: user.schoolName,
            });
        }
    }

    /**
     * Removes user information from local storage after logout
     */
    deleteUserInfo() {
        this.appData.groupsForUser = null;
        this.appData.groupTemplatesForUser = null;
        this.appData.authenticatedUser = null;
        this.appData.userLicense = null;
        this.appData.watchedVideosServer = null;
        this.appData.removePreference(this.constants.pref.AUTH_TOKEN);
        this.appData.removePreference(this.constants.pref.LOGGED_USER);
        this.appData.removePreference(this.constants.pref.LOGGED_USER_PREFERENCES);
        this.appData.removePreference(this.constants.pref.LICENSE);
        this.appData.removePreference(this.constants.pref.OPENED_CLASSES);
        this.appData.removePreference(this.constants.pref.ONLY_KIDS_CONTENT);
        this.checkSavedPreferences();

        // We need to set the language in case it changed
        const prefLanguage = this.appData.getLanguage();
        this.translate.use(prefLanguage);

        // this.activityService.eventsServiceUnsubscribe();

        // Reset the analytics user ID back to installation ID
        // this.analytics.setAnalyticsUserProperties(this.appData.getInstallationId());
        this.analytics.resetAnalyticsUserProperties();
    }

    /**
     * Loads user classes from the server after login
     */
    getUserClasses(): Observable<GroupsResponse> {
        const user = this.appData.authenticatedUser;
        if (user.role === 'educator') {
            return this.groupsApi.getByUser(user._id);
        } else if (user.role === 'student') {
            // return this.groupsApi.getStudentClasses();
            // Don't get all classes, but only those that the user added locally:
            return this.groupsApi.listStudentClasses(this.appData.getClassIds());
        }
    }

    /**
     * Loads user classes from the server after login
     */
    getUserClassTemplates(): Observable<GroupsResponse|null> {
        const user = this.appData.authenticatedUser;
        if (user.role === 'educator') {
            return this.groupsApi.getTemplatesByUser(user._id);
        } else if (user.role === 'student') {
            return of(null);
        }
    }

    /**
     * Displays the tutorial as a modal
     */
    async showTutorialPage() {
        const tutorialModal = await this.modalCtrl.create({
            component: TutorialPage,
            showBackdrop: true
        });
        await tutorialModal.present();

        const { data } = await tutorialModal.onDidDismiss();

        // Save event if tutorial done
        if (this.appData.isStudent() && data && data.done) {
            const event: IActivityEvent = {
                installationID: this.appData.getInstallationId(),
                category: ActivityCategory.tutorial,
                action: ActivityAction.tutorial_finished,
                label: 'onboarding',
                generated_at: new Date()
            };

            this.activityService.saveEventToStorage(event);
        }
    }

    /**
     * Show a non-cancelable dialog with the Terms of Use (AGB)
     * @param userId the user ID, needed for storing the accepted state in local storage for the one user.
     * @param allowBackdropDismiss if true, the user will need to accept the terms
     */
    async showTermsOfUseDialog(userId: string, allowBackdropDismiss = false) {
        const i18n = await this.translate.get(['terms_of_use_title', 'terms_of_use_subtitle', 'btn_accept']).toPromise();
        const title = i18n['terms_of_use_title'];
        const alert = await this.alertCtrl.create({
            header: title,
            subHeader: i18n['terms_of_use_subtitle'],
            message: this.constants.TermsTextDe, // this.translate.instant('terms_of_use_text'),
            buttons: [
                // {
                //     text: 'Datenschutz',
                //     cssClass: 'secondary',
                //     handler: () => {
                //         window.open('https://www.uugot.it/datenschutz.html', '_blank');
                //         return false;
                //     }
                // },
                {
                    text: i18n['btn_accept'],
                    handler: () => {
                        // ### Persist state
                        this.appData.savePreferenceString(this.constants.pref.TERMS_ACCEPTED + '_' + userId, '1');

                        const tutorialDone = this.appData.getPreferenceString(`${this.constants.pref.TUTORIAL_DONE}_0`);
                        if (!tutorialDone) {
                            this.showTutorialPage();
                        }
                        return true;
                    }
                }
            ],
            // User cannot dismiss dialog:
            backdropDismiss: allowBackdropDismiss
        });
        await alert.present();
    }

    /**
     * Show a non-cancelable modal page with the Terms of Use (AGB)
     * @param userId the user ID, needed for storing the accepted state in local storage for the one user.
     * @param isAcceptRequired if true, the user will need to accept the terms
     */
    async showTermsOfUseDialogV2(userId: string, isAcceptRequired = true) {
        this.logger.debug('showTermsOfUseDialogV2 isAcceptRequired', isAcceptRequired);
        const modal = await this.modalCtrl.create({
            component: TermsOfUsePage,
            showBackdrop: true,
            // swipeToClose: false,
            keyboardClose: false,
            backdropDismiss: !isAcceptRequired,
            componentProps: {
                isAcceptRequired,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        this.logger.debug('showTermsOfUseDialogV2 data', data);
        if (data?.acceptedTerms) {
            this.logger.debug('showTermsOfUseDialogV2 user accepted the terms-of-use');
            // ### Persist state
            this.appData.savePreferenceString(
                this.constants.pref.TERMS_ACCEPTED + '_' + userId,
                '1'
            );

            const tutorialDone = this.appData.getPreferenceString(
                `${this.constants.pref.TUTORIAL_DONE}_0`
            );
            if (!tutorialDone) {
                this.showTutorialPage();
            }
            return true;
        }
    }

    /**
     * Gets a SAS token and uploads the two files
     *
     * @param fileInput1 a file "input" element
     * @param fileInput2 a file "input" element
     * @param typePrefix "assessment" or "solution"
     * @param videoId the ID of the video
     * @param level the level of the video, for example A2
     */
    getTokenAndUploadFilesToAzureStorage(fileInput1: ElementRef, fileInput2: ElementRef, typePrefix: string, videoId: string, level: string): Observable<AzureBlobUploadResponse[]> {
        this.logger.debug('File input 1:', fileInput1);
        this.logger.debug('File input 2:', fileInput2);
        const token = this.appData.getPreferenceString(this.constants.pref.AUTH_TOKEN);

        return this.usersApi.blobToken(token).pipe(
            mergeMap(blobToken => {
                this.logger.debug('Got token object:', blobToken);
                return from(
                    Promise.all([
                        this.uploadFileToAzureStorage(fileInput1, blobToken.token, typePrefix, videoId, level),
                        this.uploadFileToAzureStorage(fileInput2, blobToken.token, typePrefix, videoId, level)
                    ])
                );
                // ).pipe(
                //   //catch and gracefully handle rejections
                //   catchError(error => of(`Error: ${error}`))
                // );
            })
        );

    }

    // var sasKey = 'sv=2017-07-29&ss=bfqt&srt=sco&sp=rwdlacup&se=2018-03-27T22:07:31Z&st=2018-03-27T14:07:31Z&spr=https&sig=us6u8h1x4tMjuQSJUlwKYqO%2Bp%2BKN1NfsLQ14zCzWel4%3D';

    /**
     * Uploads a file to Azure blob storage, white spaces will be removed off the file name.
     * @param uploadElement Upload element
     * @param sasKey sas Key
     * @param typePrefix Type Prefix
     * @param videoId Id of the video
     * @param level Assessment level
     */
    uploadFileToAzureStorage(uploadElement: ElementRef, sasKey: string, typePrefix: string, videoId: string, level: string): Promise<any> {
        // https://dmrelease.blob.core.windows.net/azurestoragejssample/samples/sample-blob.html#step1

        this.logger.debug('uploadFileToAzureStorage with sasKey', sasKey);

        // If one file has been selected in the HTML file input element
        const file = uploadElement.nativeElement.files[0];
        this.logger.debug('file:', file);

        if (!file) {
            return Promise.resolve(null);
        }

        const container = 'appassessments';
        const hashKey = this.cryptoUtils.generateId(8);
        let originalFileName = '';
        if (file) {
            const regEx = /\s/g; // find white spaces
            originalFileName = file.name.toString().replace(regEx, '_'); // replace them with _
        }
        const fileName = `${typePrefix}_${videoId}_${level}_${hashKey}_${originalFileName}`;

        this.logger.debug('fileName:', fileName);

        // your account and SAS information
        // var sasKey = '6usk2YYCutt0';
        // var sasKey = 'sv=2017-07-29&ss=bfqt&srt=sco&sp=rwdlacup&se=2018-03-27T22:07:31Z&st=2018-03-27T14:07:31Z&spr=https&sig=us6u8h1x4tMjuQSJUlwKYqO%2Bp%2BKN1NfsLQ14zCzWel4%3D';
        const blobUri = 'https://' + environment.azureStorageAccount + '.blob.core.windows.net';
        const blobService = AzureStorage.Blob.createBlobServiceWithSas(blobUri, sasKey).withFilter(new AzureStorage.Blob.ExponentialRetryPolicyFilter());

        const customBlockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
        blobService.singleBlobPutThresholdInBytes = customBlockSize;

        return new Promise((resolve, reject) => {
            return blobService.createBlockBlobFromBrowserFile(container, fileName, file, { blockSize: customBlockSize }, function(error, result) {
                if (error) {
                    // Upload blob failed
                    // alert('Upload failed, open browser console for more detailed info.');
                    // this.logger.debug('Upload error:', error);
                    reject(error);
                } else {
                    // Uploaded successfully
                    // alert('Uploaded successfully!');
                    // this.logger.debug('Upload result:', result);

                    // Function returns blocks that make up the blob if blob size exceeds block size instead of the blob object
                    if (result.name) {
                        resolve(result);
                    } else {
                        resolve({ name: fileName });
                    }
                }
            });
        });
    }

    isAustrianTeacherEmailAddress(email: string): boolean {
        if (!email) {
            return false;
        }
        // const allowedEmailDomains = [
        //     'bildung.at',
        //     'bildung.gv.at',
        //     'bildung-bgld.gv.at',
        //     'bildung-ktn.gv.at',
        //     'bildung-noe.gv.at',
        //     'bildung-ooe.gv.at',
        //     'bildung-wien.gv.at',
        //     'bildung-sbg.gv.at',
        //     'bildung-stmk.gv.at',
        //     'bildung-tirol.gv.at',
        //     'bildung-vbg.gv.at',
        //     'bildungsserver.com',
        //     'bildungsserver.wien',
        //     'bmbwf.gv.at',
        //     'eeducation.at',
        //     'schule.at',
        //     'tibs.at',
        //     'vobs.at',
        //     'wien.gv.at',

        //     // For testing:
        //     'creativeworkline.at',
        //     'uugot.it',
        //     // 'gmail.com',
        // ];
        const allowedEmailDomains = this.appData.remoteConfig.autoRegisterAllowedDomains;
        return !!allowedEmailDomains.find(domain => email.toLowerCase().endsWith(`@${domain}`));
    }

    getAustrianTeacherEmailAddressesForDisplay(): Array<string> {
        // Don't show certain email domains
        return this.appData.remoteConfig.autoRegisterAllowedDomains
            .filter(e => !['creativeworkline.at', 'uugot.it', 'magwien.gv.at', 'wien.gv.at'].includes(e))
            .sort();
    }

    /**
     * Executes updates periodically in the background while the app is running.
     */
    executeBackgroundUpdates() {
        if (this.appData.isLoggedIn()) {
            this.loadWatchedVideos();
        }
    }

    async loadWatchedVideos() {
        try {
            const res = await this.watchedVideoApi.list(500, 0, '-updated_at').toPromise();
            this.logger.debug('Loaded watched videos in the background, success:', res.success, res.msg);
            this.appData.watchedVideosServer = res.data?.map(wv => {
                const w = new WatchedVideo(wv.video_id, wv.watchedUntil, wv.expiryDate, wv.dur, wv.fin);
                w.createdAt = wv.created_at;
                w.updatedAt = wv.updated_at;
                return w;
            }) || [];
            return this.appData.watchedVideosServer;
        } catch (err) {
            this.logger.warn(`Error loading watched videos in the background: ${err.message}`, err);
        }
    }

    get appBaseUrl() {
        return window.location.hostname?.endsWith('.app-preview.uugot.it')
            ? `https://${window.location.hostname}`
            : environment.appBaseUrl;
    }

}
