<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-title>{{ "edit_profile" | translate }}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="dismiss()" *ngIf="dismissPageEnabled">
                <ion-icon slot="icon-only" name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <!--<ion-grid fixed>-->
    <p class="ion-padding-start ion-padding-end">
        <span *ngIf="!user || !user.first_name || !user.last_name">
            {{'edit_profile_text_initial' | translate}}
        </span>
        <span *ngIf="user && user.first_name && user.last_name">
            {{'edit_profile_text_update' | translate}}
        </span>
    </p>
    <form [formGroup]="profileForm" (ngSubmit)="onSubmitForm()">
        <ion-list lines="full" class="ion-no-margin ion-no-padding" *ngIf="user && user.role === 'educator'">
            <ion-item>
                <ion-label position="floating">{{'email' | translate}} <ion-text>*</ion-text>
                </ion-label>
                <ion-input formControlName="email" type="email" readonly #email (keyup.enter)="firstName.setFocus()"></ion-input>
            </ion-item>

            <ion-item>
                <ion-label position="floating">{{'first_name' | translate}} <ion-text>*</ion-text>
                </ion-label>
                <ion-input formControlName="firstName" type="text" enterkeyhint="next" inputmode="text" autocapitalize="sentences" maxlength="50" #firstName (keyup.enter)="lastName.setFocus()"></ion-input>
            </ion-item>

            <ion-item>
                <ion-label position="floating">{{'last_name' | translate}} <ion-text>*</ion-text>
                </ion-label>
                <ion-input formControlName="lastName" type="text" enterkeyhint="next" inputmode="text" autocapitalize="sentences" maxlength="50" #lastName (keyup.enter)="schoolName.setFocus()"></ion-input>
            </ion-item>

            <ion-item>
                <ion-label position="floating">{{'school' | translate}} <ion-text *ngIf="isSchoolRequired">*</ion-text>
                </ion-label>
                <ion-input formControlName="schoolName" type="text" enterkeyhint="next" inputmode="text" autocapitalize="sentences" maxlength="50" #schoolName (keyup.enter)="city.setFocus()"></ion-input>
            </ion-item>

            <ion-item>
                <ion-label position="floating">{{'city' | translate}} <ion-text *ngIf="isSchoolRequired">*</ion-text>
                </ion-label>
                <ion-input formControlName="city" type="text" enterkeyhint="done" inputmode="text" autocapitalize="sentences" maxlength="50" #city (keyup.enter)="hideKeyboard()"></ion-input>
            </ion-item>
        </ion-list>

        <ion-list lines="full" class="ion-no-margin ion-no-padding" *ngIf="user && user.role === 'student'">
            <ion-item>
                <ion-label position="floating">{{'first_name' | translate}} <ion-text>*</ion-text>
                </ion-label>
                <ion-input formControlName="firstName" type="text" enterkeyhint="next" inputmode="text" autocapitalize="sentences" maxlength="50" #firstName (keyup.enter)="lastName.setFocus()"></ion-input>
            </ion-item>

            <ion-item>
                <ion-label position="floating">{{'last_name' | translate}} <ion-text>*</ion-text>
                </ion-label>
                <ion-input formControlName="lastName" type="text" enterkeyhint="next" inputmode="text" autocapitalize="sentences" maxlength="50" #lastName (keyup.enter)="hideKeyboard()"></ion-input>
            </ion-item>
        </ion-list>

        <div class="ion-padding">
            <ion-button expand="block" type="submit" class="ion-no-margin"
                [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'"
                [disabled]="profileForm.pristine || !profileForm.valid">
                {{'btn_save' | translate}}
            </ion-button>
        </div>
    </form>
    <!--</ion-grid>-->
</ion-content>
