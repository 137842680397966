<ion-content class="ion-padding" *ngIf="failedServerResponse">
    <p>{{failedServerResponse.msg}}</p>
    <p>{{'redirect_to_video_catalog' | translate}}</p>
    <ion-button routerLink="/" routerDirection="root">{{'all_videos' | translate}}</ion-button>
</ion-content>
<ion-content  [class.mobile-landscape-videoplayer]="true" >
    <ion-progress-bar type="indeterminate" *ngIf="showLoadingProgress" class="video-player-progress-bar"></ion-progress-bar>

    <div id="video-container" #videoContainer *ngIf='!loadVideoPlayer'>
        <div  class="video-overlay-center-middle" [class.overlay-active]="overlayActive">
            <div class="overlay" [style.background-color]="'rgba(0, 0, 0, ' + overlayOpacity + ')'"></div>
            <ion-img id="imageInIframe" class="video-js vjs-fluid vjs-default-skin vjs-big-play-centered" *ngIf='imageURL' [src]="imageURL"
                     (click)="loadIframe()"
                     (ionImgDidLoad)="onImageLoad()"
                     (mouseover)="onImageMouseOver()"
                     (mouseout)="onImageMouseOut()">
            </ion-img>
            <ion-img src="assets/img/video-player/play-circle-outline.svg" *ngIf='imageURL' class="video-overlay-center-button" (click)="toggleOverlay()"></ion-img>
        </div>
        <div id="video-overlay-intro" *ngIf='!loadVideoPlayer'>
            <div class="video-overlay-intro-container">
                <div class="video-overlay-video-title">
                    <a href="" target="_blank">{{videoTitle}}</a>
                </div>
                <div class="video-overlay-intro-info">
                    <!-- https://ga-dev-tools.appspot.com/campaign-url-builder/ -->
                    <ion-icon name="information-circle"></ion-icon>
                    <span [innerHTML]="'video_intro_info' | translate"></span>
                </div>

            </div>
<!--            <div id="video-subtitle-intro" class="video-subtitle" style="display: block;">-->
<!--                <span id="video-subtitle-inner-intro" class="video-subtitle-inner" [innerHTML]="introAnimation.currentSubtitle"></span>-->
<!--            </div>-->
<!--            <img id="click-cursor" src="assets/img/video-player/hand-pointer-icon.png" />-->
        </div>
    </div>

    <iframe
        *ngIf='loadVideoPlayer'
        class=""
        [src]="safeUrl"
        (load)="onIframeLoad()"
        frameborder='0'
        allowfullscreen></iframe>

</ion-content>
