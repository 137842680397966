import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    NavigationExtras,
    Router
} from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { AppData } from '../services/app-data.service';
import { AppManager } from '../services/app-manager.service';
import { NavController, MenuController, Platform } from '@ionic/angular';
import { catchError, map } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { AnalyticsService } from '../services/analytics.service';
import { ActivityService } from '../services/activity.service';
import { ActivityCategory, ActivityAction } from '../interfaces/IActivity';
import { UsersApi } from '../services/api/users-api.service';
import { GroupsApi } from '../services/api/groups-api.service';
import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics';
import { Constants } from '../app.constants';

@Injectable({
    providedIn: 'root'
})
export class RouteGuard implements CanActivate {

    private readonly CLASSES_RELOAD_INTERVAL_MINUTES = 1;
    private lastClassesUpdate: Date;

    constructor(private _appData: AppData,
        private appManager: AppManager,
        private navCtrl: NavController,
        private menuCtrl: MenuController,
        private logger: NGXLogger,
        private analytics: AnalyticsService,
        private constants: Constants,
        private router:Router,
        private appData: AppData,
        private activityService: ActivityService,
        private usersApi: UsersApi,
        private groupsApi: GroupsApi,
        private platform: Platform) {
        // Do nothing.
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        // if (state.url.includes('account') &&  this.appData.authenticatedUser && this.appData.authenticatedUser.role !== 'student') {
        //     const isOnboardingComplete = this.appData.getPreferenceString(this.constants.pref.ONBOARDING_EDUCATOR_DONE);
        //     if (this.appData.groupsForUser.length > 0) {
        //         return true;
        //     }
        //     if (!isOnboardingComplete) {
        //         const onboardingRedirectUrl = this.appData.getPreferenceString(this.constants.pref.ONBOARDING_REDIRECT_URL, 'onboarding');
        //         this.router.navigateByUrl(`/${onboardingRedirectUrl}`);
        //         return false;
        //     }
        // }
        if (!this._appData.geoIp || !this._appData.videoSources || !this._appData.subtitleLanguages) {
            return forkJoin(
                this._appData.loadInitialData(),
                this.routeGuardHelper(route, true)
            ).pipe(
                map(([initialDataRes, canAccess]) => {
                    this.logger.debug('Loaded initial data in RouteGuard');
                    // We are using the installation ID as the user ID
                    this.initializeAnalytics();
                    console.log('state url: ', state.url)
                    if (state.url.includes('/educator-class/new')) {
                        // Add your specific conditional check here
                    }

                    if (state.url.includes('/onboarding') &&  this.appData.authenticatedUser && this.appData.authenticatedUser.role !== 'student') {
                        const groups = this.appData.groupsForUser;
                        if (groups.length > 0) {
                            return false;
                        }
                    }
                        let isOnlyKidsContent = false;
                    if  (this.appData.getPreferenceString(this.constants.pref.ONLY_KIDS_CONTENT)) {
                        isOnlyKidsContent = JSON.parse(this.appData.getPreferenceString(this.constants.pref.ONLY_KIDS_CONTENT))
                    }
                    if (state.url === '/catalog' && this.appData.authenticatedUser && this.appData.authenticatedUser.role === 'student' && isOnlyKidsContent) {
                        location.href = 'catalog/t/kids'
                        return false;
                    }


                    // Save event on app start for students
                    if (this.appData.isStudent()) {
                        this.activityService.saveEventToStorage({
                            installationID: this.appData.getInstallationId(),
                            category: ActivityCategory.app,
                            action: ActivityAction.app_started,
                            generated_at: new Date()
                        });
                    }
                    return canAccess;
                }),
                map(canAccess => {
                    if (!canAccess) {
                        this.forwardNoAccess();
                    }
                    return canAccess;
                }),
                catchError((err) => {
                    this.logger.warn('Error loading inital data', state.url, err);

                    // Passing state doesn't seem to work yet
                    const navigationExtras: NavigationExtras = {
                        state: {
                            errorMessage: 'Error loading inital data.'
                        }
                    };
                    this.navCtrl.navigateRoot('error', navigationExtras);
                    this.menuCtrl.close();
                    this.reportErrorCrashlytics(state, err);
                    return of(false);
                })
            );
        } else {
            return this.routeGuardHelper(route, false).map(canAccess => {
                if (!canAccess) {
                    this.forwardNoAccess();
                }
                return canAccess;
            });
        }
    }

    forwardNoAccess() {
        this.navCtrl.navigateRoot('catalog');
        this.menuCtrl.close();
    }

    routeGuardHelper(route: ActivatedRouteSnapshot, isInitial: boolean): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.logger.debug('routeGuardHelper checkAuthToken');
            this.appManager.checkAuthToken(null).subscribe((isLoggedIn) => {
                if (isLoggedIn) {
                    return forkJoin([
                        isInitial ? this.loadUserDetailsAndPrefs() : of(false),
                        this.loadUserClassesAndTemplates()
                    ]).subscribe(([loadedUserDetails, reLoadedClassesAndTemplates]) => {
                        // Show menu only after groups loaded because menu color flickers otherwise
                        // this._appData.showMenu = true;

                        const role = this._appData.authenticatedUser.role;

                        observer.next(route.data.expectedRoles.includes(role));
                        observer.complete();
                    });
                } else {
                    // this._appData.showMenu = true;
                    observer.next(route.data.expectedRoles.includes('public'));
                    observer.complete();
                }
            });
        });
    }

    /**
     * Load the user's classes and template classes (with a cache period of CLASSES_RELOAD_INTERVAL_MINUTES)
     */
    loadUserClassesAndTemplates(): Observable<boolean> {
        const minutes = this.CLASSES_RELOAD_INTERVAL_MINUTES;
        const now = new Date();
        if (!this.lastClassesUpdate || this.lastClassesUpdate.getTime() < now.getTime() - minutes * 60 * 1000) {
            this.lastClassesUpdate = new Date();
            return forkJoin(
                this.appManager.getUserClasses(),
                this.appManager.getUserClassTemplates()
            ).map(resArray => {
                // this._logger.debug('Groups and templates:', resArray);
                const oldGroups = this._appData.groupsForUser;
                this._appData.groupsForUser = resArray[0].data;
                this._appData.groupTemplatesForUser = resArray[1] ? resArray[1].data : null;

                // Set videos from previously loaded groups to avoid flickering of UI ("unwatched" videos indicator in menu)
                if (oldGroups) {
                    this._appData.groupsForUser.forEach(g => {
                        const oldGroup = oldGroups.find(og => og._id === g._id);
                        if (oldGroup) {
                            g.videos = oldGroup.videos;
                        }
                    });
                }

                // Load the videos of all groups (needed for showing he "unwatched" videos indicators in the menu)
                // This is done asynchronously, so it doesn't block the UI
                Promise.all(this._appData.groupsForUser.map(g => {
                    return this.groupsApi.getVideos(g._id, true, false).toPromise().then(videosResponse => {
                        g.videos = videosResponse.data;
                        return videosResponse.count;
                    });
                })).then(loadVideosRes => this.logger.debug('loaded all class videos', loadVideosRes)); // for example [2, 5]

                return true;
            });
        } else {
            return of(false);
        }
    }

    loadUserDetailsAndPrefs(): Observable<boolean> {
        return forkJoin([this.usersApi.getMyInfo(), this.usersApi.getPreferences()]).map(
            ([userInfo, userPrefs]) => {
                if (userInfo) {
                    this.logger.debug('Got user info and prefs', userInfo, userPrefs);
                    this.appManager.saveUserInfo(userInfo.loggedUser, null, null, userPrefs);
                    return true;
                }
                return false;
            }
        );
    }

    initializeAnalytics() {
        this.analytics.init();

        let userId: string;
        if (this.appData.isLoggedIn()) {
            // console.log('initializeAnalytics user is logged in');
            userId = this.appData.authenticatedUser._id;
        } else {
            // console.log('initializeAnalytics user is not logged in');
            // userId = this.appData.getInstallationId();
            userId = null;
        }
        if (userId) {
            this.analytics.setAnalyticsUserProperties(userId);
        }

        /*
        const userId = this.appData.getPreferenceString(this.constants.pref.USER_EMAIL_ENCRYPTED);
        if (userId) {
            this.analytics.setAnalyticsUserProperties(
                userId,
                this.appData.getPreferenceString(this.constants.pref.USER_LANGUAGE_LEVEL_DE),
                this.appData.getPreferenceString(this.constants.pref.USER_LANGUAGE_LEVEL_EN)
            );
        }
        */
    }

    /**
     * Report error with https://github.com/capacitor-community/firebase-crashlytics
     * @param state router state snapshot
     * @param err the error object
     */
    private reportErrorCrashlytics(state: RouterStateSnapshot, err: any) {
        if (this.platform.is('capacitor')) {
            FirebaseCrashlytics.recordException({
                message: `Error loading initial data (URL: ${state.url}): ${err?.message}`,
            })
                .then(() => this.logger.debug('Recorded error with Crashlytics'))
                .catch((crashlyticsErr) => {
                    this.logger.debug('Error reporting error with Crashlytics', crashlyticsErr);
                });
        }
    }
}
