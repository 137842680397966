import * as _ from 'lodash';

interface IWatchedVideoJSON {
    vid: string; // videoId
    wu: number; // watchedUntil
    c: Date; // createdAt
    u?: Date; // updatedAat
    e?: Date; // expiryDate
    d?: number; // duration (sec.)
    f?: boolean; // finished
    cid?: string; // clipId
}

export class WatchedVideo {
    videoId: string;
    watchedUntil: number; // progress in seconds (decimal), will be 0 if user hasn't started playing

    createdAt: Date;
    updatedAt?: Date;

    expiryDate?: Date; // Used for clearing out watched video information once videos have expired

    dur?: number; // total duration of the video in seconds
    fin?: boolean; // true if the user finished watching the video
    clipId?: string; // the ID of the clip

    constructor(videoId: string, watchedUntil?: number, expiryDate?: Date, dur?: number, fin?: boolean, clipId?: string) {
        this.videoId = videoId;
        this.watchedUntil = watchedUntil;
        this.expiryDate = expiryDate;
        this.createdAt = new Date();
        this.dur = dur;
        this.fin = fin;
        this.clipId = clipId;
    }

    isContainedInArray(arr: Array<WatchedVideo>): WatchedVideo {
        for (let i = 0; i < arr.length; i++) {
            if (
                arr[i].videoId === this.videoId &&
                (arr[i].clipId === this.clipId || _.isNil(arr[i].clipId) && _.isNil(this.clipId))
            ) {
                return arr[i];
            }
        }
        return null;
    }

    static fromJSONObject(json: IWatchedVideoJSON): WatchedVideo {
        const watchedVideo = new WatchedVideo(
            json.vid || json['videoId'], // 'videoId' is for backwards compatibility
            json.wu || json['watchedUntilTime'] // 'watchedUntilTime' is for backwards compatibility
        );
        watchedVideo.createdAt = json.c;
        watchedVideo.updatedAt = json.u;
        watchedVideo.expiryDate = json.e;
        watchedVideo.dur = json.d;
        watchedVideo.fin = json.f;
        watchedVideo.clipId = json.cid;
        return watchedVideo;
    }

    static toJSONObject(w: WatchedVideo): IWatchedVideoJSON {
        return {
            vid: w.videoId,
            wu: w.watchedUntil,
            c: w.createdAt,
            u: w.updatedAt,
            e: w.expiryDate,
            d: w.dur,
            f: w.fin,
            cid: w.clipId
        };
    }
}
