<ion-header>
    <ion-toolbar color="blue">
        <ion-buttons slot="start">
            <ion-button (click)="navigateBack()">
                <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title *ngIf="statistics?.clip">Tasks statistics for '{{statistics?.clip?.title || statistics?.video?.title}}'</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <ion-grid fixed>

        <table class="statistics-table" *ngIf="statistics?.progressions">
            <tr>
                <th>
                    Name
                </th>
                <th>
                    Result
                </th>
                <th>
                    Time req.
                </th>
                <th>
                    Finished at
                </th>
            </tr>
            <tr *ngFor="let progression of statistics.progressions">
                <td>
                    {{progression.user_id.first_name}} {{progression.user_id.last_name}}
                </td>
                <td>
                    <ng-container *ngIf="progression.result; else noResult">
                        {{progression.result.score}} ({{progression.result.correct}} of {{progression.result.total}})
                    </ng-container>
                    <ng-template #noResult>
                        -
                    </ng-template>
                </td>
                <td>
                    <ng-container *ngIf="progression.isFinished">
                        {{uiUtils.secondsToTimeString(progression.msSinceStart / 1000, true)}}
                    </ng-container>
                </td>
                <td>
                    <ng-container *ngIf="progression.endAt; else noEndAt">
                        {{progression.endAt | date:'medium'}}
                    </ng-container>
                    <ng-template #noEndAt>
                        {{'not_finished' | translate}}
                    </ng-template>
                </td>
            </tr>

        </table>
    </ion-grid>

</ion-content>
