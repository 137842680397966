/* eslint-disable @typescript-eslint/camelcase */
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

import { Api } from './api.service';
import { IAssessment } from '../../interfaces/IAssessment';
import { VideoAssessmentsResponse, AssessmentResponse, GroupVideoResponse } from 'src/app/models/dtos';

/**
 * API for /assessments/
 * See http://spyder3-stage.uugot.it/api-docs/#/assessments
 */
@Injectable({
    providedIn: 'root'
})
export class AssessmentsApi {
    constructor(public api: Api) {
        // Do nothing;
    }

    /**
     * Returns all current available assessments from a video
     *
     * @param videoId required video id to search for
     * @param sort for example 'level name' or '-name', defaults to 'name'
     */
    getAssessmentsForVideo(videoId: string, sort = 'name'): Observable<VideoAssessmentsResponse> {
        const params = {
            video_id: videoId,
            sort
        };
        return this.api.get('assessments', params);
    }

    /**
     * Add an assessment to a video in a group
     *
     * @param groupId groupId the ID of the group
     * @param assessment the assessment you want to add
     */
    addAssessmentToVideoInGroup(groupId: string, assessment_id: string, video_id: string): Observable<GroupVideoResponse> {
        const body = {
            group_id: groupId,
            assessment_id,
            video_id
        };
        return this.api.post('assessments/assign', body);
    }

    /**
     * Gets an assessment object by ID
     *
     * @param assessment_id required assessment ID to search for
     */
    getAssessmentByID(assessment_id: string): Observable<AssessmentResponse> {
        return this.api.get(`assessments/${assessment_id}`);
    }

    /**
     * Adds assessment to assessments collection
     *
     * @param assessment The assessment to be added
     */
    addAssessment(assessment: IAssessment): Observable<AssessmentResponse> {
        return this.api.post('assessments', assessment);
    }
}
