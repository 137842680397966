import { Component, ElementRef, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Constants } from '../../app.constants';
import { AppData } from '../../services/app-data.service';
import { UsersApi } from '../../services/api/users-api.service';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'app-tutorial',
    templateUrl: 'tutorial-page.html',
    styleUrls: ['tutorial-page.scss']
})

export class TutorialPage {
    index = 0;
    showArrow = false;
    done = false;

    slideOptions = {
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets', // 'progressbar'
        }
    };

    @ViewChild('swiper')
        swiperRef: ElementRef | undefined;

    // @ViewChild(IonSlides, { static: false }) slides: IonSlides;

    constructor(private modalCtrl: ModalController,
                private appData: AppData,
                private logger: NGXLogger,
                private usersApi: UsersApi,
                private constants: Constants,
        public plt: Platform,
        // eslint-disable-next-line no-empty-function
    ) {

    }

    /**
     * Closes tutorial
     */
    closeModal() {
        this.modalCtrl.dismiss({
            done: this.done
        });
    }

    async prev() {
        this.swiperRef?.nativeElement.swiper.slidePrev();
        this.index -= 1;
    }

    async next() {
        this.swiperRef?.nativeElement.swiper.slideNext();
        this.index += 1;
    }

    /**
     * Marks the tutorial as done so that it isn't opened again when user revisits app
     */
    tutorialDone() {
        const key = this.constants.pref.TUTORIAL_DONE + '_0';
        if (this.appData.authenticatedUser) {
            this.usersApi.savePreferences(key, 1).subscribe(result => {
                this.logger.debug('Saved preference on server', result);
            });
        }
        this.done = true;
    }
}
