import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from './api.service';
import { WordCatalogListResponse, WordCatalogSaveResponse, WordCatalogDeleteResponse, WordCatalogCardDetailsResponse } from 'src/app/models/dtos';
import { IWordCatalog } from 'src/app/interfaces/IWordCatalog';

/**
 * API for /wordcatalog/
 * See https://spyder3-dev.uugot.it/api-docs/#/wordcatalog
 */
@Injectable({
    providedIn: 'root'
})
export class WordCatalogApi {
    constructor(public api: Api) {
        // Do nothing.
    }

    /**
     * Add word to general word catalog
     *
     * @param word The word to add
     */
    addWordToCatalog(word: IWordCatalog): Observable<WordCatalogSaveResponse> {
        return this.api.post('wordcatalog', word);
    }

    /**
     * Returns all words stored in the general word catalog
     *
     * @param offset Offset for paging
     * @param limit Number of words to retrieve per call
     * @param lang
     */
    getCatalog(limit: number, offset: number, lang = 'de'): Observable<WordCatalogListResponse> {
        const params = {
            limit,
            offset,
            sort: '-created_at',
            lang
        };
        return this.api.get('wordcatalog', params);
    }

    /**
     * Removes all words from the general word catalog
     */
    deleteCatalog(): Observable<WordCatalogDeleteResponse> {
        return this.api.delete('wordcatalog');
    }

    /**
     * Adds a word to a specific class word catalog
     *
     * @param classId The class ID
     * @param word The word to be added
     */
    addWordToClassCatalog(classId: string, word: IWordCatalog): Observable<WordCatalogSaveResponse> {
        return this.api.post(`wordcatalog/group/${classId}`, word);
    }

    /**
     * Returns all words stored in a specific class word catalog
     *
     * @param classId The class ID
     * @param offset Offset for paging
     * @param limit Number of words to retrieve per call
     */
    getClassCatalog(classId: string, limit: number, offset: number): Observable<WordCatalogListResponse> {
        const params = {
            limit,
            offset,
            sort: '-created_at'
        };
        return this.api.get(`wordcatalog/group/${classId}`, params);
    }

    /**
     * Removes all words from a specific class word catalog
     *
     * @param classId The class ID
     */
    deleteClassCatalog(classId: string): Observable<WordCatalogDeleteResponse> {
        return this.api.delete(`wordcatalog/group/${classId}`);
    }

    /**
     * Deletes array of words by ID from either general or class word catalog
     *
     * @param wordId The array of word IDs
     */
    deleteWordsById(wordIds: string[]): Observable<WordCatalogDeleteResponse> {
        return this.api.delete('wordcatalog/selected', null, wordIds);
    }

    // PUBLIC ENDPOINTS

    /**
     * Add word to the word catalog for a anonymous user (installation_id).
     *
     * @param word The word to add
     */
    addWordToCatalog4Public(word: IWordCatalog): Observable<WordCatalogSaveResponse> {
        return this.api.post(`wordcatalog/4public/${word.installationID}`, word);
    }

    /**
     * Returns all words stored in the word catalog for a anonymous user (installation_id).
     *
     * @param offset Offset for paging
     * @param limit Number of words to retrieve per call
     */
    getCatalog4Public(installationId: string, limit: number, offset: number, sort = '-created_at'): Observable<WordCatalogListResponse> {
        const params = {
            limit,
            offset,
            sort
        };
        return this.api.get(`wordcatalog/4public/${installationId}`, params);
    }

    /**
     * Removes all words from the word catalog for a anonymous user (installation_id).
     */
    deleteCatalog4Public(installationId: string): Observable<WordCatalogDeleteResponse> {
        return this.api.delete(`wordcatalog/4public/${installationId}`);
    }

    getCardDetails(wordCatalogId: string): Observable<WordCatalogCardDetailsResponse> {
        return this.api.get(`wordcatalog/${wordCatalogId}/card-details`);
    }
}
