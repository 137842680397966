import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.log(err));

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
    const isSafari = window['safari'] !== undefined;
    console.log(`App module loaded. Production: ${environment.production} Safari: ${isSafari}`);
    // There seems to be an issue with Safari and the Angular PWA service worker
    // https://github.com/angular/angular/issues/22362
    // https://github.com/angular/angular/issues/23012
    // Update: Could be fixed by adding 'If-None-Match' to 'Access-Control-Allow-Headers' on the server
    if ('serviceWorker' in navigator && environment.production/* && !isSafari*/) {
        console.log('Registering service worker');
        navigator.serviceWorker.register('/ngsw-worker.js');
    }
}).catch(err => console.log(err));
