// angular
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

// services
import { AppData } from 'src/app/services/app-data.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AnimationController, NavController } from '@ionic/angular';

// constants
import { Constants } from '../../app.constants';

// libraries
import { TranslateService } from '@ngx-translate/core';

import { AbstractRootMenuPage } from 'src/app/utils/abstract-root-menu-page';
import { Platform } from '@ionic/angular';
import {
    FormBuilder,
    UntypedFormBuilder,
} from '@angular/forms';
import { GroupsApi } from '../../services/api/groups-api.service';
import { UiUtils } from '../../services/ui-utils.service';
import { UsersApi } from '../../services/api/users-api.service';
import { ActivatedRoute } from '@angular/router';
import { Utils } from 'src/app/utils/utils';

@Component({
    selector: 'app-onboarding',
    templateUrl: 'onboarding-unverified-page.html',
    styleUrls: ['onboarding-unverified-page.scss']
})
export class OnboardingUnverifiedPage extends AbstractRootMenuPage implements OnInit {
    public systemLanguageName: string;
    @ViewChild('swiper')
    swiperRef: ElementRef | undefined;
    pagination = {
        clickable: true,
        type: 'progressbar'
    };
    // emailsInvite = []
    public showCustomDatePicker = true;
    allSetUp: boolean;
    submitting: boolean = false;
    public disableNext = false;


    constructor(
        private route: ActivatedRoute,
        private groupsApi: GroupsApi,
        private animationCtrl: AnimationController,
        public appData: AppData,
        private userService: UsersApi,
        public navCtrl: NavController,
        private uiUtils: UiUtils,
        public constants: Constants,
        public translate: TranslateService,
        public analytics: AnalyticsService,
        private titleService: Title,
        protected plt: Platform) {
        super(plt);
    }



    ionViewWillEnter() {
        super.ionViewWillEnter();
        this.appData.activePage = 'onboarding';
    }

    ionViewWillLeave() {
        super.ionViewWillLeave();
    }



    onKeyPressSwiper($event) {
        $event.preventDefault();
        console.log('recibe ', $event);
        this.previous();
    }



    async ngOnInit() {
        this.titleService.setTitle('Onboarding | uugot.it');
        const swiperEl = document.querySelector('swiper-container');
        this.systemLanguageName = this.appData.getLanguage();
    }


    async next() {
        if (this.swiperRef?.nativeElement.swiper.activeIndex === 1) {
            try {
                this.submitting = true;
                const groupId = this.route.snapshot.queryParamMap.get('group_id');
                this.appData.savePreferenceString(this.constants.pref.ONBOARDING_EDUCATOR_DONE, '1');
                await this.userService.updateOnboardingCompletePreference().toPromise(); // set onboarding_complete to true
                if (groupId) {
                    this.navCtrl.navigateForward(`/educator-class/details/${groupId}`);
                } else {
                    console.error('Group ID is missing in the query parameters.');
                }
            } catch (error) {
                this.submitting = false;
                console.error('Error updating onboarding complete:', error);
                // Manejar el error de actualización de onboarding_complete
                // Aquí puedes mostrar un mensaje de error al usuario o realizar cualquier otra acción necesaria.
            }
        } else {
            this.swiperRef?.nativeElement.swiper.slideNext();
        }
    }

    previous() {
        this.swiperRef?.nativeElement.swiper.slidePrev();
    }


    disablePreviousButton() {
        return this.swiperRef?.nativeElement.swiper.activeIndex <= 0;
    }

    getTextForNextButton() {
        if (this.swiperRef?.nativeElement.swiper.activeIndex === 1) {
            return this.translate.instant('go_to_class');
        }
        return this.translate.instant('next');
    }

}
