<ion-header>
    <ion-toolbar color="secondary">
        <ion-buttons slot="start">
            <ion-button (click)="navigateBack()">
                <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>{{ "join_class" | translate }}<span *ngIf="group">: {{group.name}}</span></ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding ion-text-center">
    <ion-grid fixed *ngIf="this.group">
        <div *ngIf="maxLimitStudentsReached" class="ion-padding ion-text-center">
            <div class="alert alert-danger" >
                {{ 'max_students_in_class_reached' | translate:{maxStudents:group.maxStudents} }}
            </div>
        </div>
        <ion-radio-group [(ngModel)]="selectedUser">
            <ion-item *ngFor="let student of group.students">
                <ion-radio [value]="student" slot="start" ></ion-radio>
                <ion-label>
                    {{ student.first_name + ' ' + student.last_name }}
                </ion-label>
            </ion-item>
            <ion-item>
                <ion-radio value="new" slot="start" [disabled]="maxLimitStudentsReached"></ion-radio>
                <ion-label>
                    {{ "create_new_user" | translate }}
                </ion-label>
            </ion-item>
        </ion-radio-group>
        <form [formGroup]="userInfoForm">
            <ion-grid fixed>
                <ion-list>
                    <ion-item class="ion-no-padding" [disabled]="selectedUser !== 'new' || maxLimitStudentsReached">
                        <ion-label position="stacked"
                            [class.red-text]="!userInfoForm.controls.firstName.valid && submitAttempt && selectedUser === 'new'">
                            {{ "first_name" | translate }} <ion-text color="danger">*</ion-text>
                        </ion-label>
                        <ion-input type="text" formControlName="firstName"></ion-input>
                    </ion-item>
                    <ion-item class="ion-no-padding" [disabled]="selectedUser !== 'new' || maxLimitStudentsReached">
                        <ion-label position="stacked"
                            [class.red-text]="!userInfoForm.controls.lastName.valid && submitAttempt && selectedUser === 'new'">
                            {{ "last_name" | translate }} <ion-text color="danger">*</ion-text>
                        </ion-label>
                        <ion-input type="text" formControlName="lastName"></ion-input>
                    </ion-item>

                    <ion-button class="ion-margin-top" color="green" (click)="joinClass()" [disabled]="!selectedUser">
                        {{ "join_class" | translate }}
                    </ion-button>
                </ion-list>
            </ion-grid>
        </form>
    </ion-grid>
</ion-content>
