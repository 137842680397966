// angular
import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';

// ionic
import { AlertController, ToastController, LoadingController, Platform } from '@ionic/angular';

// libraries
import 'rxjs/add/operator/map';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';

// models
import { ILanguages } from '../interfaces/ILanguages';
import { ILanguage } from '../interfaces/ILanguage';
import { Constants } from '../app.constants';
import { IVideo } from '../interfaces/IVideo';

/*
  UI utils class.
*/
@Injectable({
    providedIn: 'root'
})
export class UiUtils {
    constructor(public http: HttpClient,
        public alertCtrl: AlertController,
        public translate: TranslateService,
        public toastCtrl: ToastController,
        public loadingCtrl: LoadingController,
        private logger: NGXLogger,
        private plt: Platform,
        private constants: Constants) {
        logger.debug('Hello UiUtils Provider');
    }

    /**
     * Shows an info alert (AlertController)
     *
     * @param subTitle Alert body text
     * @param title Alert title, defaults to i18n 'default_info_alert_title' if null
     * @param okHandler Optional handler to be called when the user taps the OK button.
     */
    async showInfoAlert(title?: string, subHeader?: string, message?: string, okHandler?: () => void, cssClass?: string) {
        let header = title;
        if (header === null) {
            header = this.translate.instant('default_info_alert_title');
        }
        const alert = await this.alertCtrl.create({
            header,
            subHeader,
            message,
            buttons: [
                {
                    text: this.translate.instant('btn_ok'),
                    handler: okHandler
                }
            ],
            cssClass
        });
        await alert.present();
    }

    /**
     * Shows an error alert (AlertController)
     *
     * @param subTitle Alert body text
     * @param title Alert title, defaults to i18n 'error'
     * @param reloadHandler Optional handler to be called when the user taps the 'reload' button.
     */
    async showErrorAlert(subTitle: string, title?: string, reloadHandler?: () => void) {
        let alert;
        let header = title;
        if (header === null) {
            header = this.translate.instant('default_info_alert_title');
        }
        if (reloadHandler) {
            alert = await this.alertCtrl.create({
                header,
                subHeader: subTitle,
                buttons: [
                    {
                        text: this.translate.instant('btn_cancel'),
                        role: 'cancel',
                        handler: () => {
                            console.log('Cancel clicked');
                        }
                    },
                    {
                        text: this.translate.instant('btn_reload'),
                        handler: reloadHandler
                    }
                ]
            });
        } else {
            alert = await this.alertCtrl.create({
                header: title,
                subHeader: subTitle,
                buttons: [this.translate.instant('btn_ok')]
            });
        }
        await alert.present();
    }

    /**
     * Shows a generic error dialog with the message returned from the server API endpoint.
     * @param err The error object
     */
    showServerRequestErrorAlert(err) {
        if (err.status === this.constants.HTTP_STATUS_CODE.UNAUTHORIZED) {
            // This is handled by AuthErrorInterceptor
            this.logger.debug(`showRequestErrorAlert not showing because error status is ${err.status}`);
            return;
        }
        let message = err.error ? err.error.message : err.message;
        if (!message) {
            message = 'Server request returned an error.';
        }
        if (message == 'email_not_verified') {
            this.showErrorAlert(this.translate.instant('email_not_verified'));
        } else {
            this.showErrorAlert(message);
        }
    }

    /**
     * Shows a toast with message (ToastController) for 3 seconds
     *
     * @param {string} message The message to be displayed
     * @param {string} position The position of the toast on the screen. Default: 'bottom'.
     * @param {number} duration How many milliseconds to wait before hiding the toast. Default: 3000.
     * @param color
     */
    async displayToast(message: string, position?: 'top' | 'bottom' | 'middle', duration = 3000, color = 'primary') {
        const posToUse = position || (this.plt.is('mobile') ? 'bottom' : 'top');
        const toast = await this.toastCtrl.create({
            message,
            duration,
            position: posToUse,
            color
        });

        await toast.present();
    }

    /**
     * Converts translations array to string, for example "Englisch, Spanisch, Arabisch (OV Deutsch)"
     *
     * @param {ILanguages[]} translations The translations
     * @param {string} [originalLang] Original language of video
     * @returns Translations as a string
     */
    translationsToText(translations: ILanguages[], originalLang?: string) {

        /*
        if (!translations) {
            return '';
        }
        let langText = translations.map((key) => {
            return this.translate.instant('lang_' + key);
        }).join(', ');
        if (originalLang) {
            langText = langText + ' (OV ' + this.translate.instant('lang_' + originalLang) + ')';
        }
        return langText;
        */

        // Don't show translation languages, return only "OV Deutsch";
        return originalLang ? `OV ${this.translate.instant('lang_' + originalLang)}` : '';
    }

    /**
     * Converts seconds to HH:mm:ss format.
     * If the seconds are negative (< 0) then a minus will be added (for example -5 becomes -00:05).
     *
     * @param seconds The number of seconds
     * @param alwaysShowHour if true then the format HH:mm:ss will always be used, even if the time is less than one hour
     * @return Time string in HH:mm:ss format or mm:ss if hours = 0
     */
    secondsToTimeString(seconds: number, alwaysShowHour = false): string {
        const isNegative = seconds < 0;
        const secondsPositive = isNegative ? -seconds : seconds;
        const format = secondsPositive >= 3600 || alwaysShowHour ? 'HH:mm:ss' : 'mm:ss';
        let formattedTime = formatDate(new Date(secondsPositive * 1000), format, 'en-US', '+0000');
        if (isNegative) {
            formattedTime = `-${formattedTime}`;
        }
        return formattedTime;
    }

    /**
     * Adds a 'displayName' property to the language object (depending on current i18n).
     * @param lang the language
     * @return returns the same language object again
     */
    setDisplayNameOfLanguage(lang: ILanguage): ILanguage {
        const key = 'lang_' + lang.code;
        let displayName = this.translate.instant(key);
        if (displayName === key) {
            displayName = lang.name_native || lang.name;
        }
        const append = (lang.status === 'Beta') ? '(Beta)' : '';
        lang['displayName'] = `${displayName} ${append}`;
        return lang;
    }

    /**
     * Creates a unique set of assessment levels (from assessment_docs) for a video
     *
     * @param video The video object
     */
    getWorksheetLevels(video: IVideo): string {
        if (!video || !video.assessment_docs) {
            return '';
        }
        const levels = new Set(video.assessment_docs.map(assessment => assessment.level));
        return Array.from(levels).sort().join(', ');
    }
}
