import { Component, OnInit, Input, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { IVideo } from '../../interfaces/IVideo';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from '../../services/app-data.service';
import { Constants } from 'src/app/app.constants';
import * as moment from 'moment';
import { interval, Subscription, timer } from 'rxjs';
import { IPopover } from 'src/app/pages/video-player/video-player-page';
import {
    ISettingsChangeListener,
    ISettingsMainItem,
} from '../video-player-settings/video-player-settings.interfaces';
import { AnimationController } from '@ionic/angular';
import { ILanguages } from '../../interfaces/ILanguages';
declare const Popper: any;

type WordTranslations = { [key in ILanguages]?: string };

/**
 * Component that shows the video player settings (overlay).
 */
@Component({
    selector: 'video-player-settings-preview-animation',
    templateUrl: './video-player-settings-preview-animation.component.html',
    styleUrls: ['./video-player-settings-preview-animation.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerSettingsPreviewAnimation implements OnInit, ISettingsChangeListener {
    private readonly exampleWord: WordTranslations = {
        ar: 'لغة',
        bs: 'jezik',
        de: 'Sprache',
        en: 'language',
        es: 'idioma',
        fa: 'زبان',
        fr: 'langue',
        hr: 'jezik',
        it: 'linguaggio',
        ro: 'limba',
        ru: 'язык',
        so: 'luqadda',
        sr: 'језик',
        pt: 'língua',
        sk: 'jazyk',
        uk: 'Мова',
        hu: 'Nyelv',
        tr: 'dil',
    };

    isShown = false;
    animationLoopSubscription: Subscription;
    currentSubtitle: string;
    currentWordOriginal: string;
    currentWortTranslation: string;

    private closeTimeout;

    private popover: IPopover;

    @ViewChild('clickCursor', { read: ElementRef }) public clickCursor: ElementRef;

    constructor(
        private logger: NGXLogger,
        public translate: TranslateService,
        public uiUtils: UiUtils,
        public appData: AppData,
        public constants: Constants,
        private animationCtrl: AnimationController
    ) {
        // empty
        this.logger.debug('VideoPlayerSettingsPreviewAnimation constructor');
    }

    ngOnInit() {
        this.logger.debug('VideoPlayerSettingsPreviewAnimation.ngOnInit()');

        this.setNewWord(
            this.exampleWord['de'],
            this.exampleWord[this.selectedLanguage] || this.exampleWord['en']
        );
    }

    show(startAnimation = false) {
        this.isShown = true;
        if (startAnimation && !this.animationLoopSubscription) {
            this.startAnimation();
        }
    }

    hide() {
        if (this.animationLoopSubscription) {
            this.stopAnimation();
        }
        this.isShown = false;
    }

    setNewWord(wordOriginal: string, wordTranslation: string) {
        this.currentWordOriginal = wordOriginal;
        this.currentWortTranslation = wordTranslation;
        this.currentSubtitle = `&nbsp;<span class="drop-target drop-target-1"><span class="drop-target-inner">${wordOriginal}</span></span>&nbsp;`;
    }

    openWordPopover() {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const element = document.querySelector(
            `#video-overlay-settings-preview-animation .drop-target-1`
        ) as HTMLElement;

        // This is the div that will be shown as the popup:
        const popperDiv = document.createElement('div');
        popperDiv.classList.add('popper');
        popperDiv.classList.add(`video-subtitle-fontsize-multiplier-${this.subtitleFontSize}`);
        const popperInnerDiv = document.createElement('div');
        popperInnerDiv.classList.add('popper-inner');
        popperDiv.appendChild(popperInnerDiv);
        popperInnerDiv.innerHTML = this.currentWortTranslation;

        // popperDiv.innerHTML = translatedWord;
        const appendToElement = document.getElementById('video-overlay-settings-preview-animation');
        if (!appendToElement) {
            return;
        }

        appendToElement.appendChild(popperDiv);

        const showForMs = 2500;
        // const clickDelay = this.videoClickDelay;
        // if (clickDelay > 0 && clickDelay < 10 && showForMs < clickDelay * 1000) {
        //     showForMs = clickDelay * 1000;
        // }

        const popoverObject: IPopover = {
            wordIndex: 0,
            subtitlesIndex: 0,
            popper: undefined,
        };

        // Create the Popper instance:
        const popperInstance = new Popper(element, popperDiv, {
            placement: 'top',
            removeOnDestroy: true,
            // offset: 0,
            // ### This works for popper.js 1.14.4:
            onCreate: (data) => {
                // this.logger.debug('Popper was created', data);
                element.classList.add('drop-target-selected');
                // this.logger.debug('POPPER CREATE', data.instance);
                popoverObject.closeTimeout = setTimeout(() => {
                    data.instance.close();
                }, showForMs);
            },
        });

        popoverObject.popper = popperInstance;

        // Add a close() method to the popper instance
        popperInstance.close = () => {
            this.logger.debug('popperInstance.close');
            element.classList.remove('drop-target-selected');
            try {
                clearTimeout(popoverObject.closeTimeout);
                popoverObject.closeTimeout = null;
                popperInstance.destroy();
            } catch (e) {
                console.error('Error destroying Popper instance', e);
            }
        };

        popperDiv.onclick = () => {
            this.logger.debug('Closing popper instance...');
            // clearTimeout(popoverObject.closeTimeout);
            // popoverObject.closeTimeout = null;
            popperInstance.close();
        };

        this.popover = popoverObject;
    }

    get subtitleFontSize(): string {
        return this.appData.getPreferenceString(
            this.constants.pref.SUBTITLE_FONT_SIZE,
            this.constants.DefaultSubtitleFontSize
        );
    }

    get videoClickDelay(): number {
        return Number.parseInt(
            this.appData.getPreferenceString(
                this.constants.pref.VIDEO_CLICK_DELAY,
                this.constants.DefaultVideoClickDelay
            ),
            10
        );
    }

    get selectedLanguage(): ILanguages {
        return this.appData.getPreferenceString(
            this.constants.pref.TRANSLATION_LANG,
            this.constants.DefaultTranslationLang
        ) as ILanguages;
    }

    onSettingChanged(id: ISettingsMainItem['id'], value: string) {
        this.logger.debug(`Preview animation - settingChanged ${id}: ${value}`);
        switch (id) {
            case 'language':
                this.setNewWord(
                    this.exampleWord['de'],
                    this.exampleWord[value] || this.exampleWord['en']
                );
                break;
            case 'video_quality':
                break;
            default:
                break;
        }

        // if (this.popover?.closeTimeout) {
        //     // clearTimeout(this.popover.closeTimeout);
        //     this.popover.popper.close();
        // }

        if (this.animationLoopSubscription) {
            this.stopAnimation();
        }
        this.startAnimation();
    }

    startAnimation() {
        this.animationLoopSubscription = timer(800, 4000).subscribe((value) => {
            this.logger.debug(`Animation loop ${value}`);

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            const element = document.querySelector(
                `#video-overlay-settings-preview-animation .drop-target-1`
            ) as HTMLElement;
            if (element) {
                this.logger.debug('Clicking on element', element);
                // element.onmousedown(null);
                // element.onmouseup(null);
                this.clickClickIntroCursor(element);
            } else {
                this.logger.debug('Clicking on element not possible, is null');
            }
        });
    }

    public stopAnimation() {
        this.animationLoopSubscription?.unsubscribe();
        this.animationLoopSubscription = null;
        if (this.popover?.closeTimeout) {
            // clearTimeout(this.popover.closeTimeout);
            this.popover.popper.close();
        }
    }

    /**
     * Move the "fake cursor" element to a certain element in the DOM and click it.
     * @param element The HTML element which you want to move the cursor to and click on (onmousedown, onmouseup)
     */
    clickClickIntroCursor(element: HTMLElement) {
        if (this.clickCursor) {
            const squareA = this.animationCtrl
                .create()
                .addElement(this.clickCursor.nativeElement)
                .keyframes([
                    // { offset: 0, transform: 'scale(1)' },
                    { offset: 0.5, transform: 'scale(0.75)', bottom: '-38px' },
                    { offset: 1, transform: 'scale(1)', bottom: '-43px' },
                ]);

            const parent = this.animationCtrl
                .create()
                .duration(400)
                .iterations(1)
                .addAnimation([squareA]);

            parent.play().then(() => {
                this.logger.debug('Animation finished');
            });
        }

        setTimeout(() => {
            this.openWordPopover();
        }, 300);
    }
}
