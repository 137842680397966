import { OnInit, Component } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { NavParams, PopoverController } from '@ionic/angular';
import { ClipboardService } from 'ngx-clipboard';
import { UiUtils } from 'src/app/services/ui-utils.service';

/**
 * A page (popover) showing the "embed code" for a video.
 */
@Component({
    template: `
    <div class="ion-padding-horizontal" style="padding-bottom: 5px;">
        <ion-button (click)="dismiss()" fill="clear" color="dark" style="position: absolute; right: 3px; top: 3px;">
            <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
        <h3>
            Embed+
        </h3>
        <p>Share this video with full uugot.it functionality.</p>
        <p>HTML code:</p>
        <p class="embed-code-block">{{embedCode}}</p>
        <p style="margin-bottom: 5px;">
            <small><a href="https://sites.google.com/view/uugot-it-embed/single-video" target="_blank">
                Developer Sample <!-- DE: Entwicklerbeispiel -->
            </a></small>
        </p>
        <div style="text-align: right;">
            <ion-button (click)="copyCodeToClipboard()" fill="clear">
                <!-- <ion-icon slot="start" name="copy"></ion-icon> -->
                Copy <!-- DE: Kopieren -->
            </ion-button>
        </div>
    </div>
    `,
    styles: [
        `
        .embed-code-block {
            padding: 10px;
            background-color: #eee;
            font-family: monospace;
            font-size: 1.1em;
            -webkit-user-select: all; /* Safari */
            -ms-user-select: all; /* IE 10+ and Edge */
            user-select: all; /* Standard syntax */
        }
        `
    ]
})
export class VideoPlayerEmbedPopoverPage implements OnInit {

    embedUrl = null;
    embedCode = null;

    constructor(private navParams: NavParams,
        private analytics: AnalyticsService,
        private logger: NGXLogger,
        private popoverCtrl: PopoverController,
        private clipboardService: ClipboardService,
        public uiUtils: UiUtils) {
        // do nothing.
    }

    ngOnInit() {
        this.embedUrl = this.navParams.get('embedUrl');
        this.embedCode = `<iframe width="640" height="360" src="${this.embedUrl}" frameborder="0" allow="autoplay" allowfullscreen></iframe>`;
    }

    dismiss() {
        this.popoverCtrl.dismiss({
            dismissed: true,
        });
    }

    copyCodeToClipboard() {
        if (this.clipboardService.copyFromContent(this.embedCode)) {
            this.uiUtils.displayToast('Code copied to clipboard'); // DE: Code in Zwischenablage kopiert
        } else {
            this.uiUtils.displayToast('Code could not be copied to clipboard');
        }
    }
}
