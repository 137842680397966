import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from './api.service';
import { VideosListAvailableResponse, VideosOfTheDayResponse, VideosResponse } from 'src/app/models/dtos';
import { IVideo } from 'src/app/interfaces/IVideo';
import { AppData } from '../app-data.service';

/**
 * API for /videos/
 * See https://spyder3-dev.uugot.it/api-docs/#/videos
 */
@Injectable({
    providedIn: 'root'
})
export class VideosApi {
    constructor(public api: Api, public appData: AppData) {
        // Do nothing.
    }

    /**
     * Calls API to load the video catalog depending on user role
     *
     * @param sourceId - for example 'orftvthek'
     * @param offset - offset for paging
     * @param limit - Number of videos to retrieve per call.
     * @param translation - Translations language code: de, en, hr, ar, ru, ... Multiple languages separate with space (translation=hr%20en%20ru).
     * @param country - Country code (AT, DE, JP, ...) used to return only videos that are available (not blocked) in this country.
     * @param includeWithoutSubtitle - if this is true, then also videos that don't have a subtitle will be returned
     * @param withTasks - if set to 1, then only videos will be returned that have at least one task (created by anyone)
     * @param withMyTasks - if set to 1, then only videos for which the user created at least one tasks
     */
    getUserSpecificCatalog(
        sourceId: string,
        offset = 0,
        limit = 20,
        searchText?: string,
        translation?: string,
        country?: string,
        tag?: string,
        subjectId?: string | 'none',
        categoryId?: string,
        includeWithoutSubtitle?: boolean,
        withTasks?: 0 | 1,
        withMyTasks?: 0 | 1,
        sortByFreeVideos = false
    ): Observable<VideosResponse> {
        const role = this.appData.authenticatedUser ? this.appData.authenticatedUser.role : 'public';
        let sort;
        if (sortByFreeVideos) {
            sort = '-isAvailableForFreeUser -publishDate -groupId sequence -created_at';
        } else {
            sort = '-publishDate -groupId sequence -created_at';
        }

        // if (this.appData.authenticatedUser && this.appData.userLicense.type === 'free') {
        //     sort = '-isAvailableForFreeUser -publishDate -groupId sequence -created_at';
        // } else {
        // sort = '-publishDate -groupId sequence -created_at';
        // }

        const params = {
            offset,
            limit,
            sort
        };

        if (translation !== undefined && translation !== '') {
            params['translation'] = translation;
        }
        if (country) {
            params['country'] = country;
        }
        if (tag) {
            params['tag'] = tag;
        }
        if (subjectId) {
            params['subject'] = subjectId;
        }
        if (categoryId) {
            params['category'] = categoryId;
        }

        if (includeWithoutSubtitle) {
            params['includeWithoutSubtitle'] = '1';
        }

        if (withTasks === 0 || withTasks === 1) {
            params['withTasks'] = withTasks;
        }

        if (withMyTasks === 0 || withMyTasks === 1) {
            params['withMyTasks'] = withMyTasks;
        }

        if (searchText) {
            params['search'] = searchText;
        }

        return this.api.get(`videos/list4${role}/${sourceId || 'all'}`, params);
    }

    /**
     * Calls API to load the videos by ID.
     *
     * @param videoIds - array of video IDs
     * @param offset - offset for paging
     * @param limit - Number of videos to retrieve per call
     */
    getVideos(videoIds: string[], offset = 0, limit = 20): Observable<VideosResponse> {
        const role = this.appData.authenticatedUser ? this.appData.authenticatedUser.role : 'public';

        const params = {
            offset,
            limit,
            sort: '-publishDate -groupId sequence -created_at'
        };

        const body = {
            _id: { $in: videoIds },
        };

        return this.api.post(`videos/list4${role}`, body, params);
    }

    /**
     * Calls API to get video details depending on user role
     *
     * @param videoId The ID of the video
     */
    getUserSpecificVideoDetails(videoId: string): Observable<IVideo | {success: boolean; code: string; msg: string;}> {
        const role = this.appData.authenticatedUser ? this.appData.authenticatedUser.role : 'public';

        return this.api.get(`videos/details4${role}/${videoId}`);
    }

    /**
     * List videos of day. Get video of day for today or backward certain number of videos of day.
     *
     * @param offset - offset for paging
     * @param limit - Number of videos to retrieve per call.
     */
    getVideosOfTheDay(offset = 0, limit = 10): Observable<VideosOfTheDayResponse> {
        const params = {
            offset,
            limit,
            sort: '-ofdayDate',
            select: ''
        };

        return this.api.get(`videos/ofday`, params);
    }

    /**
     * Loads the video catalog from the API.
     *
     * @param sourceId - for example 'orftvthek'
     * @param offset - offset for paging
     * @param limit - Number of videos to retrieve per call.
     * @param translation - language code. For example 'en'
     * @deprecated
     */
    getVideoCatalog(sourceId: string, offset = 0, limit = 20, translation: string): Observable<VideosListAvailableResponse> {
        const params = {
            offset,
            limit,
            sort: '-publishDate -groupId sequence -created_at'
        };

        if (translation !== undefined && translation !== '') {
            params['translation'] = translation;
        }

        return this.api.get(`videos/list/available/${sourceId}`, params);
    }

    /**
     * Loads a certain video
     *
     * @param videoId the videoId to be loaded
     * @deprecated
     */
    getVideoById(videoId: string): Observable<IVideo> {
        return this.api.get(`videos/details/${videoId}`);
    }

    /**
     * Calls API to get the next video to be played after the video given by video _id.
     *
     * @param videoId The ID of the video
     * @param includeWithoutSubtitle - if this is true, then also videos that don't have a subtitle will be returned
     */
    getPlayNext(
        videoId: string,
        includeWithoutSubtitle?: boolean
    ): Observable<{ success: boolean; nextVideo: IVideo; }> {
        const params: any = {};
        if (includeWithoutSubtitle) {
            params.includeWithoutSubtitle = '1';
        }
        return this.api.get(`videos/play-next/${videoId}`, params);
    }

    getVideoBySearchParam(id: string) {
        const allVideos = this.appData.videos;
        const videito = [];
        for (const video of allVideos) {
            videito.push(video);
            return videito;
        }
    }
}
