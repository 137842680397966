import { Component, ViewChild, Input } from '@angular/core';
import { IonSlides, ModalController, Platform } from '@ionic/angular';
import { AppData } from 'src/app/services/app-data.service';
import { Constants } from 'src/app/app.constants';
import { UsersApi } from 'src/app/services/api/users-api.service';
import { NGXLogger } from 'ngx-logger';

export interface ISlideConfig {
    image: string;
    header: string;
    text: string;
}

export interface ITutorialConfig {
    id: string;
    title: string;
    slides: ISlideConfig[];

    /**
     * Can be text or an i18n key.
     */
    doneButtonText?: string;

    /**
     * Show a semi transparent "done" button on the last slide.
     */
    showDoneArrow?: boolean;
}

@Component({
    selector: 'app-tutorial-generic',
    templateUrl: 'tutorial-generic-page.html',
    styleUrls: ['tutorial-generic-page.scss']
})
export class TutorialGenericPage {
    index = 0;
    showArrow = false;
    done = false;

    slideOptions = {
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets', // 'progressbar'
        }
    };

    @Input() public config: ITutorialConfig;
    @Input() public callOnDismiss?: () => void;

    @ViewChild(IonSlides, { static: false }) slides: IonSlides;

    constructor(public modalCtrl: ModalController,
        public plt: Platform,
        public appData: AppData,
        public constants: Constants,
        private usersApi: UsersApi,
        private logger: NGXLogger) {
        // Show right arrow on phone after 3 seconds
        setTimeout(() => {
            this.showArrow = true;
            setTimeout(() => {
                this.showArrow = false;
                setTimeout(() => {
                    this.showArrow = true;
                    setTimeout(() => {
                        this.showArrow = false;
                        setTimeout(() => {
                            this.showArrow = true;
                            setTimeout(() => {
                                this.showArrow = false;
                            }, 600);
                        }, 600);
                    }, 600);
                }, 600);
            }, 600);
        }, 2000);
    }

    /**
     * Closes tutorial
     */
    closeModal() {
        this.modalCtrl.dismiss({
            done: this.done
        }).then(() => {
            if (this.callOnDismiss) {
                // console.log('callOnDismiss', this.callOnDismiss);
                this.callOnDismiss();
            }
        });
    }

    /**
     * Marks the tutorial as done so that it isn't opened again when user revisits app
     */
    tutorialDone() {
        const key = this.constants.pref.TUTORIAL_DONE + '_' + this.config.id;
        // this.appData.savePreferenceString(key, '1');
        if (this.appData.authenticatedUser) {
            this.usersApi.savePreferences(key, 1).subscribe(result => {
                this.logger.debug('Saved preference on server', result);
            });
        }
        this.done = true;
    }

    /**
     * Slides to next slide
     */
    slideNext() {
        this.slides.slideNext(500);
    }

    /**
     * Slides to previous slide
     */
    slidePrev() {
        this.slides.slidePrev(500);
    }

    /**
     * Called when a slide is changed
     */
    slideChanged() {
        this.slides.getActiveIndex().then(i => {
            this.index = i;
        });
    }
}
