<div id="video-overlay-intro2-outer" *ngIf="isShown">
    <div class="video-overlay-intro2-container">
        <div class="video-overlay-intro2-top-container">
            <div class="video-overlay-intro2-top-title">
                <img src="assets/img/video-player/intro/uugot.it_player_essentials_uu.png"
                    alt="uugot.it player essentials" />
            </div>
            <div class="video-overlay-intro2-top-closebutton">
                <!-- <ion-icon name="close"></ion-icon> -->
                <ion-button color="secondary" [size]="(plt.is('mobile') && plt.isPortrait() && !plt.is('ipad') && !plt.is('phablet')) ? 'small' : 'default'" (click)="close()">
                    {{ 'btn_got_it' | translate }}
                </ion-button>
            </div>
        </div>
        <div class="video-overlay-intro2-content">
            <ion-grid>
                <ion-row>
                    <ion-col size="4">
                        <img src="assets/img/video-player/intro/player_tutorial_col_01_click.png"
                            alt="click to trnaslate" />
                    </ion-col>
                    <ion-col size="4">
                        <img src="assets/img/video-player/intro/player_tutorial_col_02_speed.png"
                            alt="speed up and down" />
                    </ion-col>
                    <ion-col size="4">
                        <img src="assets/img/video-player/intro/player_tutorial_col_03_language.png"
                            alt="switch language" />
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
        <div class="video-overlay-intro2-bottom-container">
            <div class="video-overlay-intro2-bottom-left">
                <img src="assets/img/video-player/intro/orientation_rotation_screen_icon.svg" />
                <span>{{ 'best_experience_flip_landscape' | translate }}</span>
            </div>
            <!-- <div class="video-overlay-intro2-bottom-center">
                <ion-button color="secondary" (click)="close()">Got it</ion-button>
            </div> -->
            <div class="video-overlay-intro2-bottom-right">
                <ion-checkbox color="secondary" #dontShowAgain [(ngModel)]="configuration.isDontShowAgain"></ion-checkbox>
                <span>{{ 'dont_show_again' | translate }}</span>
            </div>
        </div>
    </div>
</div>
