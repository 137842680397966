import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from './api.service';
import { IListResponse, ISingleObjectResponse } from 'src/app/models/dtos';
import { ITask } from 'src/app/interfaces/ITask';
import { IClip } from 'src/app/interfaces/IClip';

/**
 * API for /clips/
 * See http://localhost:3005/api-docs/#/clips
 */
@Injectable({
    providedIn: 'root',
})
export class ClipsApi {
    constructor(public api: Api) {
        // Do nothing.
    }

    /**
     * Returns a specific clip by id, or an error if not found
     *
     * @param id required id to search for
     */
    getById(id: string): Observable<ISingleObjectResponse<IClip>> {
        return this.api.get(`clips/${id}`);
    }

    /**
     * Creates a new clip
     * @param clip the Clip object
     */
    addClip(clip: IClip): Observable<ISingleObjectResponse<IClip>> {
        return this.api.post(`clips/`, clip);
    }

    /**
     * Updates a clip
     * @param id the Clip ID
     * @param clip the Clip object
     */
    updateClip(
        id: string,
        clip:
        | IClip
        | {
            task_ids?: string[];
            start?: number;
            end?: number;
            duration?: number;
            cropStart?: number;
            cropEnd?: number;
            title?: string;
            isFull?: boolean;
        }
    ): Observable<ISingleObjectResponse<IClip>> {
        return this.api.put(`clips/${id}`, clip);
    }

    /**
     */
    getTasksByClipId(
        clipId: string,
        offset?: number,
        limit?: number,
        sort = 'position _id',
        onlyVisible = false
    ): Observable<IListResponse<ITask>> {
        const params: any = {};
        if (offset !== null && offset !== undefined) {
            params.offset = offset;
        }
        if (limit !== null && limit !== undefined) {
            params.limit = limit;
        }
        if (sort !== null && sort !== undefined) {
            params.sort = sort;
        }
        if (onlyVisible !== null && onlyVisible !== undefined) {
            params.onlyVisible = onlyVisible;
        }
        return this.api.get(`clips/${clipId}/list-tasks`, params);
    }

    /**
     */
    getTasksByClipIdForAnonymousUser(
        clipId: string,
        offset?: number,
        limit?: number,
        sort = 'position _id',
        onlyVisible = false
    ): Observable<IListResponse<ITask>> {
        const params: any = {};
        if (offset !== null && offset !== undefined) {
            params.offset = offset;
        }
        if (limit !== null && limit !== undefined) {
            params.limit = limit;
        }
        if (sort !== null && sort !== undefined) {
            params.sort = sort;
        }
        if (onlyVisible !== null && onlyVisible !== undefined) {
            params.onlyVisible = onlyVisible;
        }
        return this.api.get(`clips/${clipId}/list-tasks-for-anonymous-user`, params);
    }

    /**
     * Generates the leaderboard for a clip.
     * @param clipId the ID of the clip
     */
    generateLeaderboard(clipId: string): Observable<ISingleObjectResponse<IClip>> {
        return this.api.post(`clips/${clipId}/generate-leaderboard`, {});
    }
}
