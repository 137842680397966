import { Component, OnInit, ViewEncapsulation, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from '../../services/app-data.service';
import { Constants } from 'src/app/app.constants';
import { Platform, AnimationController } from '@ionic/angular';
import { IVideoOverlayIntroConfiguration } from './video-overlay-intro2.interfaces';
import Future from 'src/app/utils/future';

/**
 * Component that shows the video player settings (overlay).
 */
@Component({
    selector: 'video-overlay-intro2',
    templateUrl: './video-overlay-intro2.component.html',
    styleUrls: ['./video-overlay-intro2.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VideoOverlayIntro2 implements OnInit {
    isShown = false;

    @Output() onClose: EventEmitter<IVideoOverlayIntroConfiguration> = new EventEmitter();

    configuration: IVideoOverlayIntroConfiguration;

    future: Future<void>;

    constructor(
        private logger: NGXLogger,
        public translate: TranslateService,
        public uiUtils: UiUtils,
        public appData: AppData,
        public constants: Constants,
        public plt: Platform
    ) {
        // empty
        this.logger.debug('VideoOverlayIntro2 constructor');
    }

    ngOnInit() {
        this.logger.debug('VideoOverlayIntro2.ngOnInit()');
    }

    show(configuration: IVideoOverlayIntroConfiguration = {}): Promise<void> {
        this.configuration = configuration;
        this.isShown = true;
        if (this.configuration.isDontShowAgain === null || this.configuration.isDontShowAgain === undefined) {
            this.configuration.isDontShowAgain =
                this.appData.getPreferenceString(this.constants.pref.HIDE_VIDEO_INTRO_OVERLAY) === '1';
        }
        this.future = new Future();
        return this.future.asPromise();
    }

    close() {
        this.logger.debug(`VideoOverlayIntro2.hide() configuration=${JSON.stringify(this.configuration)}`);
        this.isShown = false;
        this.appData.videoPlayerIntroShown = true;
        if (this.configuration.isDontShowAgain) {
            this.appData.savePreferenceString(this.constants.pref.HIDE_VIDEO_INTRO_OVERLAY, '1');
        } else {
            this.appData.removePreference(this.constants.pref.HIDE_VIDEO_INTRO_OVERLAY);
        }
        this.onClose.emit(this.configuration);
        this.future?.resolve();
    }
}
