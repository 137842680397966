<ion-header class="video-player-header" *ngIf="!isMobileLandscape()">
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-button (click)="navigateBack()">
                <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>
            <ng-container *ngIf="clip && clip.title; else noClipTitle">
                {{clip.title}}
            </ng-container>
            <ng-template #noClipTitle>
                {{videoInfo?.title}}
            </ng-template>
            <span *ngIf="maxLoops > 1 && clippingStart > -1 && clippingEnd > -1">[{{secondsAsDate(clippingStart) | date:'mm:ss'}} - {{secondsAsDate(clippingEnd) | date:'mm:ss'}}]</span>
        </ion-title>
        <!--
        <ion-buttons slot="end" *ngIf="showActionMenu">
            <ion-button slot="icon-only" (click)="presentPopover($event)">
                <ion-icon name="more"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
            <ion-button *ngIf="isShareEnabled" (click)="openShareDialog()">
                <ion-icon slot="icon-only" name="share-social"></ion-icon>
            </ion-button>
        </ion-buttons>
        -->
        <ion-buttons slot="end">
            <!-- Show the favor/unfavor button only if it's not in the app and if it's not within a class -->
            <ng-container *ngIf="!plt.is('cordova') && !plt.is('capacitor') && !class_id && videoInfo?.hasSubtitle">
                <ion-button
                    *ngIf="this.favoredVideo !== undefined && this.favoredVideo === null"
                    (click)="favorVideo()" title="Video zu Favoriten hinzufügen">
                    <ion-icon slot="icon-only" name="heart-outline"></ion-icon>
                </ion-button>
                <ion-button
                    *ngIf="this.favoredVideo !== undefined && this.favoredVideo !== null"
                    (click)="unfavorVideo()" title="Video aus Favoriten entfernen">
                    <ion-icon slot="icon-only" name="heart"></ion-icon>
                </ion-button>
            </ng-container>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding"*ngIf="failedServerResponse">
    <p>{{failedServerResponse.msg}}</p>
    <p>{{'redirect_to_video_catalog' | translate}}</p>
    <ion-button routerLink="/" routerDirection="root">{{'all_videos' | translate}}</ion-button>
</ion-content>

<ion-content [scrollY]="!isShowPlayerOnly()" *ngIf="!failedServerResponse" [class.mobile-landscape-videoplayer]="isMobileLandscape()" [class.click-delay-timeout]="clickDelay.timeout">
    <ion-progress-bar type="indeterminate" *ngIf="showLoadingProgress" class="video-player-progress-bar"></ion-progress-bar>
    <ion-grid class="ion-no-padding">
        <ion-row>
            <ion-col size="12" [attr.size-xl]="columnSizeXl[0]" class="ion-no-padding">
                <!-- START video-container -->
                <div id="video-container" #videoContainer [hidden]="!videoInfo"
                    [class.video-player-locked]="isLocked"
                    [class.video-player-unlocked]="!isLocked"
                    [class.video-player-delay-paused-infinite]="clickDelay.isPausedInfinite">

                    <!-- START main_video -->
                    <video id="main_video" class="video-js vjs-fluid vjs-default-skin vjs-big-play-centered"
                        webkit-playsinline playsinline>
                        <!--<source src="{{videoInfo.url.medium}}" type="video/mp4">-->
                        <!--<source src="movie.ogg" type="video/ogg">-->
                        <!--Your browser does not support the video tag.-->
                        <p class="vjs-no-js">
                            To view this video please enable JavaScript, and consider upgrading to a web browser that
                            <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                        </p>
                    </video>
                    <!-- END main_video -->

                    <!-- An overlay that hides burned-in subtitles -->
                    <div id="video-overlay-hide-burned-subtitles"
                        [style.visibility]="videoInfo && videoInfo.websource_id === 'testtv' && videoInfo.tags?.includes('einfache-sprache') && currentTime && currentTime > 0 ? 'visible' : 'hidden'">
                    </div>

                    <div id="video-postroll" class="vjs-poster" [ngClass]="videoInfo?.websource_id"></div>
                    <div id="video-subtitle" class="video-subtitle video-subtitle-fontsize-multiplier-{{subtitleFontSize}}">
                        <span id="video-subtitle-inner" class="video-subtitle-inner" [innerHTML]="currentSubtitle" [style.display]="hideSubtitles ? 'none' : 'inline'">
                        </span> <!-- Note: Using ngIf here doesn't work because we need the element in the DOM -->
                    </div>

                    <ion-button slot="icon-only" fill="outline" color="light" (click)="presentPopover($event)"
                        class="video-overlay-button button-more" *ngIf="showActionMenu">
                        <ion-icon name="more"></ion-icon>
                    </ion-button>

                    <div id="video-overlay-topleft-container">
                        <div class="video-overlay-button-no-border video-overlay-button-icon" id="video-back-overlay-button" (click)="navigateBack()">
                            <!-- <ion-icon name="arrow-back"></ion-icon> -->
                            <ion-icon name="close"></ion-icon>
                        </div>
                        <!-- uugot.it TV logo that is shown top left of the video -->
                        <img class="uugotit-tv-logo" src="assets/img/video-player/uugotit_tv_logo_v2_600px.png" alt="uugot.it TV"/>
                    </div>

                    <div id="video-overlay-buttons-container">
                        <!-- <ion-button slot="icon-only" fill="outline" color="light" (click)="presentPopover($event)" class="video-overlay-button button-more-fullscreen"
                            *ngIf="isVideoFullscreen">
                            <ion-icon name="more"></ion-icon>
                        </ion-button> -->

                        <div slot="icon-only" fill="clear" color="light" (click)="toggleSubtitlesOnOff(); showTopOverlayButtonValue(videoTopButtonValueSubOnOff);"
                            title="{{ 'player_switch_subtitles_button_title' | translate }}"
                            *ngIf="videoInfo?.hasSubtitle"
                            class="video-overlay-button-no-border button-subtitles-onoff"
                            [style.text-decoration]="hideSubtitles ? 'line-through' : 'none'">
                            <!-- Text:
                                {{ "button_subtitles_short" | translate }}
                            -->
                            <span class="video-overlay-button-value" #videoTopButtonValueSubOnOff>{{(hideSubtitles ? 'subtitles_hidden' : 'subtitles_shown') | translate}}</span>
                            <!-- Image with ON / OFF: -->
                            <img [src]="hideSubtitles ? 'assets/img/video-player/subtitles_off.png' : 'assets/img/video-player/subtitles_on.png'" />
                        </div>
                        <!-- <div slot="icon-only" fill="clear" color="light" (click)="switchPlaybackRateCallback(+1)" title="{{ 'player_playback_rate_button_title' | translate }}"
                            class="video-overlay-button-no-border button-change-rate">
                            {{playbackRate}}X
                        </div> -->
                        <div (click)="switchPlaybackRateCallback(+1); showTopOverlayButtonValue(videoTopButtonValuePlaybackRate);"
                            class="video-overlay-button-icon button-settings video-overlay-button-speed"
                            title="{{ 'player_playback_rate_button_title' | translate }}">
                            <!--
                            <ion-icon name="speedometer" ioniconRemoveTitle iconTitle="{{ 'player_playback_rate_button_title' | translate }}" class="background"
                                [style.clip-path]="'inset(0px ' + (3 - playbackRateIndex) / 4 * 100 + '% 0px 0px)'"></ion-icon>
                            <ion-icon name="speedometer-outline" ioniconRemoveTitle iconTitle="{{ 'player_playback_rate_button_title' | translate }}"></ion-icon>
                            -->
                            <span class="video-overlay-button-value" #videoTopButtonValuePlaybackRate>
                                <ng-container *ngIf="playbackRate !== undefined">
                                    {{playbackRate * 100}} %
                                </ng-container>
                            </span>
                            <ng-container *ngIf="playbackRateIndex !== undefined">
                                <img class="video-overlay-button-icon-svg" src="assets/img/video-player/speedometer-background-dirtyyellow.svg" class="speed-background speed-background-{{(playbackRateIndex + 1)}}"/>
                                <!-- [style.clip-path]="'inset(0px ' + (3 - playbackRateIndex) / 4 * 100 + '% 0px 0px)'" -->
                                <img class="video-overlay-button-icon-svg" [src]="'assets/img/video-player/speedometer-outline-' + (playbackRateIndex + 1) + '.svg'"/>
                            </ng-container>
                        </div>

                        <!-- <div slot="icon-only" fill="clear" color="light" (click)="skipVideo(-10, 'button')" title="{{ 'player_skip_video_back_button_title' | translate }}"
                            class="video-overlay-button-no-border button-skip button-skip-rewind">
                            <img src="assets/img/video-player/icon-back.png" />
                        </div>
                        <div slot="icon-only" fill="clear" color="light" (click)="skipVideo(+10, 'button')" title="{{ 'player_skip_video_fwd_button_title' | translate }}"
                            class="video-overlay-button-no-border button-skip button-skip-forward">
                            <img src="assets/img/video-player/icon-forward.png" />
                        </div> -->
                        <div (click)="showSettingsOverlay()" class="video-overlay-button-icon button-settings">
                            <ion-icon name="settings-outline" ioniconRemoveTitle iconTitle="{{ 'settings' | translate }}"></ion-icon>
                        </div>

                        <div (click)="openShareDialog()" class="video-overlay-button-icon button-settings">
                            <ion-icon name="share-social" ioniconRemoveTitle iconTitle="{{ 'btn_share_video' | translate }}"></ion-icon>
                        </div>
                    </div>

                    <div id="video-overlay-center-container" *ngIf="!appData.isEducator() || ['clipSelectStart', 'clipSelectEnd'].includes(videoPlayerEducatorOverlay?.uiState)">
                        <div class="video-overlay-center-middle">
                            <div (click)="skipVideo(-10, 'button', true)" title="{{ 'player_skip_video_back_button_title' | translate }}"
                                class="video-overlay-center-button video-overlay-center-leftButton">
                                <img src="assets/img/video-player/skip-back-10sec.svg" alt="skip back 10 seconds" />
                            </div>
                            <div class="video-overlay-center-button video-overlay-center-centerButton" [style.display]="videoJSplayer && videoJSplayer.paused() ? 'inline' : 'none'" (click)="playVideo()"
                                title="{{ 'player_play_button' | translate }}">
                                <!-- <ion-icon name="play-circle-outline" ioniconRemoveTitle iconTitle="{{ 'player_play_button' | translate }}"></ion-icon> -->
                                <img src="assets/img/video-player/play-circle-outline.svg" alt="play video" />
                            </div>
                            <div class="video-overlay-center-button video-overlay-center-centerButton" [style.display]="videoJSplayer && !videoJSplayer.paused() ? 'inline' : 'none'"  (click)="pauseVideo()"
                                title="{{ 'player_pause_button' | translate }}">
                                <!-- <ion-icon name="pause-circle-outline" ioniconRemoveTitle iconTitle="{{ 'player_pause_button' | translate }}"></ion-icon> -->
                                <img src="assets/img/video-player/pause-circle-outline.svg" alt="pause video" />
                            </div>
                            <div (click)="skipVideo(+10, 'button', true)" title="{{ 'player_skip_video_fwd_button_title' | translate }}"
                                class="video-overlay-center-button video-overlay-center-rightButton">
                                <!-- <img src="assets/img/video-player/icon-forward.png" /> -->
                                <img src="assets/img/video-player/skip-ahead-10sec.svg" alt="skip ahead 10 seconds" />
                            </div>

                            <!-- <ion-button class="video-overlay-buttons-center-leftButton">left</ion-button>
                            <ion-button class="video-overlay-buttons-center-rightButton">right</ion-button> -->
                        </div>
                    </div>

                    <video-player-educator-overlay id="video-player-educator-overlay-component" [hidden]="!appData.isEducator()"
                        [videoJSplayer]="videoJSplayer" [video]="videoInfo" [(clip)]="clip" [class_id]="class_id" [listener]="this"></video-player-educator-overlay>

                    <video-player-task-overlay id="video-player-task-overlay-component" [clip]="clip" [class_id]="class_id"
                        [listener]="this" [videoJSplayer]="videoJSplayer"></video-player-task-overlay> <!-- [hidden]="appData.isEducator()" -->

                    <!-- Button on the right side (lock/unlock) -->
                    <div id="video-overlay-middleright-container" [hidden]="!isMobileLandscape()">
                        <video-player-screen-lock [progressListener]="this"></video-player-screen-lock>
                    </div>

                    <!-- START video-postroll3-playnext -->
                    <div id="video-postroll3-playnext" class="video-postroll3-playnext" [style.visibility]="videoPlayNextOverlay && videoPlayNextOverlay.isShown ? 'visible' : 'hidden'">

                        <div class="video-postroll3-container" *ngIf="videoPlayNextOverlay && videoPlayNextOverlay.isShown">
                            <div class="video-postroll3-top" [style.visibility]="videoPlayNextOverlay.isCountdownVisible() ? 'visible' : 'hidden'">
                                {{ 'next_video_in' | translate }} <strong>{{ videoPlayNextOverlay.countdownMaxSec - videoPlayNextOverlay.countdownCurrentSec }}</strong>
                            </div>
                            <div class="video-postroll3-middle">
                                <div class="video-postroll3-left">
                                    <div class="video-postroll3-left-image-container">
                                        <img src="{{ videoPlayNextOverlay.nextVideo.imageURL }}" />
                                        <div class="video-postroll3-nextvideo-duration" *ngIf="videoPlayNextOverlay.nextVideo; let nv">
                                            <span *ngIf="nv.offset && nv.offset.length === 2">
                                                {{uiUtils.secondsToTimeString(nv.offset[1] - nv.offset[0])}}
                                            </span>
                                            <span *ngIf="(!nv.offset || nv.offset.length != 2) && nv.duration">
                                                {{uiUtils.secondsToTimeString(nv.duration)}}
                                            </span>
                                            <span *ngIf="(!nv.offset || nv.offset.length != 2) && !nv.duration && nv.durMinutes">
                                               ~ {{nv.durMinutes}} Min.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="video-postroll3-right">

                                    <div class="video-postroll3-header text-shadow">
                                        {{ videoPlayNextOverlay.nextVideo.title }}
                                    </div>

                                    <div class="video-postroll3-main-button">
                                        <ion-button *ngIf="plt.is('desktop') || plt.is('tablet'); else elseNotDesktop" fill="solid" color="light" size="medium"
                                            (click)="videoPlayNextOverlay.hide('goToNext')">
                                            <ion-icon slot="start" name="play"></ion-icon>
                                            {{'video_postroll_playnext_now' | translate}}
                                        </ion-button>
                                        <ng-template #elseNotDesktop>
                                            <ion-button fill="outline" color="light" (click)="videoPlayNextOverlay.hide('goToNext')">
                                                <ion-icon slot="icon-only" name="play"></ion-icon>
                                            </ion-button>
                                        </ng-template>
                                    </div>

                                    <div class="video-postroll3-secondary-button" *ngIf="isShareEnabled && plt.is('desktop') || plt.is('tablet'); else notDesktopRepeatShare">
                                        <ion-button fill="solid" color="light" (click)="videoPlayNextOverlay.hide('replay')"
                                            size="small" style="margin-bottom: 10px;">
                                            <ion-icon slot="start" name="repeat"></ion-icon>
                                            {{'video_postroll_btn_repeat' | translate}}
                                        </ion-button>
                                        <ion-button fill="solid" color="secondary" (click)="videoPlayNextOverlay.stopCountdown(); openShareDialog('next_video');" size="small">
                                            <ion-icon slot="start" name="share-social"></ion-icon>
                                            {{'btn_share_video' | translate}}
                                        </ion-button>
                                    </div>
                                    <ng-template #notDesktopRepeatShare>
                                        <div class="video-postroll3-secondary-button" style="flex-direction: row;">
                                            <ion-button fill="outline" color="light" (click)="videoPlayNextOverlay.hide('replay')"
                                                size="small" style="margin-right: 8px;" title="{{'video_postroll_btn_repeat' | translate}}">
                                                <ion-icon slot="icon-only" name="repeat"></ion-icon>
                                            </ion-button>
                                            <ion-button fill="solid" color="secondary" (click)="videoPlayNextOverlay.stopCountdown(); openShareDialog('next_video');"
                                                size="small" title="{{'btn_share_video' | translate}}">
                                            <ion-icon slot="icon-only" name="share-social"></ion-icon>
                                        </ion-button>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="video-postroll3-bottom" *ngIf="videoPlayNextOverlay.showReviewWordsPrompt">
                                <div class="video-postroll3-bottom-left ion-text-center" *ngIf="plt.is('desktop'); else elseNotDesktopBottomLeft">
                                    {{ 'learn_clicked_words_long' | translate }}
                                </div>
                                <ng-template #elseNotDesktopBottomLeft>
                                    <div class="video-postroll3-bottom-left ion-text-center">
                                        {{ 'learn_clicked_words_short' | translate }}
                                    </div>
                                </ng-template>
                                <div class="video-postroll3-bottom-right ion-text-center">
                                    <ion-button fill="solid" color="light" [size]="plt.is('desktop') || plt.is('tablet') ? 'medium' : 'small'"
                                        (click)="videoPlayNextOverlay.hide('reviewWords')">
                                        <ion-icon slot="start" name="list"></ion-icon>
                                        {{'word_catalog' | translate}}
                                    </ion-button>
                                </div>
                            </div>
                        </div>

                        <div class="video-postroll3-close" fill="clear" color="light"
                            (click)="videoPlayNextOverlay.hide('cancel')" title="{{'btn_cancel' | translate}}">
                            <ion-icon slot="icon-only" name="close"></ion-icon>
                        </div>
                    </div>
                    <!-- END video-postroll3-playnext -->

                    <!-- START video-overlay-bottom-insert: Show info about asynchronous subtitles as a bottom overlay -->
                    <div id="video-overlay-bottom-insert"
                        *ngIf="videoInfo && videoInfo.websource_id === 'orftvthek' &&
                            !appData.playedVideoOfSource.get(videoInfo.websource_id) &&
                            !settingsOverlayShown &&
                            !videoPlayerEducatorOverlay?.isOverlayShown &&
                            !(clip && appData.isEducator())">
                        {{ 'infotext_async_subtitles' | translate }}
                    </div>
                    <!-- END video-overlay-bottom-insert -->

                    <!-- START video-overlay-settings -->
                    <video-player-settings *ngIf='videoInfo' [video]='videoInfo' [hidden]="!settingsOverlayShown" [listener]="this" id="video-player-settings"></video-player-settings>
                    <!-- END video-overlay-settings -->

                    <!-- START video-player-leaderboard-overlay -->
                    <video-player-leaderboard-overlay [hidden]="!leaderboardOverlayShown" [video]="videoInfo" [clip]="clip"
                        [listener]="this" [(leaderboardOverlayShown)]="leaderboardOverlayShown" id="video-player-leaderboard-overlay">
                    </video-player-leaderboard-overlay>
                    <!-- END video-player-leaderboard-overlay -->

                    <!-- START video-overlay-intro2 -->
                    <video-overlay-intro2 id="video-overlay-intro2" (onClose)="onCloseVideoIntroOverlay($event)"></video-overlay-intro2>
                    <!-- END video-overlay-intro2 -->

                    <ion-progress-bar [value]="clickDelay.countdownPercent" id="video-player-click-delay-progress"
                        style="position: absolute; top: 0px;" color="light" [hidden]="!clickDelay.showProgressBar"></ion-progress-bar>
                </div>
                <!-- END video-container -->

                <!-- <p style="display: none">Current time: {{currentTime}}</p> -->
            </ion-col>

            <ion-col size="12" [attr.size-xl]="columnSizeXl[1]" class="ion-no-padding" id="video-info-container" *ngIf="!isShowPlayerOnly()">
                <div class="infotext ion-padding-start ion-padding-end" *ngIf="videoInfo">
                    <h1>
                        <ng-container *ngIf="clip && clip.title; else noClipTitle">
                            {{clip.title}} [Clip]
                        </ng-container>
                        <ng-template #noClipTitle>
                            {{videoInfo?.title}}
                        </ng-template>
                    </h1>
                    <p class="infotext-share" *ngIf="videoInfo.hasSubtitle">

                        <!-- Share (Cordova / Capacitor): -->
                        <ng-container *ngIf="plt.is('cordova') || plt.is('capacitor'); else isWeb">
                            <ion-button size="small" color="light" (click)="openShareDialog()"
                                *ngIf="isShareEnabled"
                                class="ion-margin-end">
                                <ion-icon slot="start" name="share-social"></ion-icon>{{'btn_share_video' | translate}}
                            </ion-button>

                            <ng-container *ngIf="!class_id">
                                <ion-button size="small" color="light"
                                    *ngIf="this.favoredVideo !== undefined && this.favoredVideo === null"
                                    (click)="favorVideo()" title="Video zu Favoriten hinzufügen">
                                    <ion-icon slot="start" name="heart-outline"></ion-icon>
                                    {{'btn_add_to_favored' | translate}}
                                </ion-button>
                                <ion-button size="small" color="light"
                                    *ngIf="this.favoredVideo !== undefined && this.favoredVideo !== null"
                                    (click)="unfavorVideo()" title="Video aus Favoriten entfernen">
                                    <ion-icon slot="start" name="heart"></ion-icon>
                                    {{'btn_remove_from_favored' | translate}}
                                </ion-button>
                            </ng-container>
                        </ng-container>


                        <ng-template #isWeb>
                            <!-- Share (Web / Desktop): -->
                            <span [innerHTML]="getShareTextHtml() | async" *ngIf="isShareEnabled"></span>

                            <!-- Embed -->
                            <ion-button size="small" color="light" (click)="openEmbedDialog($event)"
                                *ngIf="isShareEnabled" style="display: none;">
                                <ion-icon slot="start" name="code-slash"></ion-icon>{{'btn_embed_video' | translate}}
                            </ion-button>
                        </ng-template>

                    </p>
                    <p class="infotext-lang">
                        <span *ngIf="videoInfo.cat_id">
                            <span class="video-category-chip">{{'video_cat_' + videoInfo.cat_id | translate}}</span> |
                        </span>
                        {{uiUtils.translationsToText(videoInfo?.translations, videoInfo?.originalLang)}}
                        <span *ngIf="videoInfo">| <ion-icon name="time"></ion-icon>
                            <span *ngIf="videoInfo.offset && videoInfo.offset.length === 2">
                                {{uiUtils.secondsToTimeString(videoInfo.offset[1] - videoInfo.offset[0])}}
                            </span>
                            <span *ngIf="(!videoInfo.offset || videoInfo.offset.length != 2) && videoInfo.duration">
                                {{uiUtils.secondsToTimeString(videoInfo.duration)}}
                            </span>
                            <span *ngIf="(!videoInfo.offset || videoInfo.offset.length != 2) && !videoInfo.duration && videoInfo.durMinutes">
                               ~ {{videoInfo.durMinutes}} Min.
                            </span>
                            <span *ngIf="maxLoops > 1 && clippingStart > -1 && clippingEnd > -1">[{{secondsAsDate(clippingStart) | date:'mm:ss'}} - {{secondsAsDate(clippingEnd) | date:'mm:ss'}}]</span>
                        </span>
                    </p>
                    <p class="infotext-date">
                        {{ "published" | translate }}: {{videoInfo?.publishDate | date:'mediumDate'}}
                    </p>
                    <p class="infotext-description">{{videoInfo?.description}}</p>
                    <p class="infotext-expiryDate" *ngIf="showExpiryDate(videoInfo)">
                        {{ "expiry_date" | translate }}: {{videoInfo?.expiryDate | date:'mediumDate'}}
                    </p>
                </div>
                <div class="ion-padding-start" *ngIf="videoInfo?.websource_id && appData.getLogoPathForSource(videoInfo?.websource_id, 's')">
                    <img class="video-source-logo"
                        [src]="appData.getLogoPathForSource(videoInfo.websource_id, 's')"
                        [alt]="appData.getNameForSource(videoInfo.websource_id)"
                        [title]="appData.getNameForSource(videoInfo.websource_id)"/>
                </div>
                <div class="ion-padding-start" *ngIf="videoInfo?.websource_id && !appData.getLogoPathForSource(videoInfo?.websource_id, 's')">
                    <span class="websource-logo-name">{{appData.getNameForSource(videoInfo.websource_id)}}</span>
                </div>
                <div class="ion-padding-start" *ngIf="videoInfo && appData.isEducator() && videoInfo.hasSubtitle">
                    <p>
                        <ion-button (click)="downloadTranscript()" size="small" fill="solid" color="light" target="_blank">
                            <ion-icon name="download" slot="start"></ion-icon> {{'download_transcript' |  translate}}
                        </ion-button>
                    </p>
                    <p *ngIf="videoInfo.teachResURL">
                        <ion-button href="{{videoInfo.teachResURL}}" size="small" fill="solid" color="light" target="_blank">
                            <ion-icon name="document-outline" slot="start"></ion-icon> {{'teaching_resources' |  translate}}
                        </ion-button>
                    </p>
                </div>
                <div class="ion-padding-start" *ngIf="videoInfo && appData.isEducator() && clip && clip.createdBy == appData.authenticatedUser._id && groupVideo?.numUsersFinishedTasks > 0">
                    <p>
                        <ion-button *ngIf="!clip.leaderboard" (click)="generateLeaderboardForClip()" size="small" fill="solid" color="light" target="_blank">
                            <ion-icon name="trophy" slot="start"></ion-icon> {{'generate_leaderboard' |  translate}}
                        </ion-button>
                        <ion-button *ngIf="clip.leaderboard && !leaderboardOverlayShown" (click)="showLeaderboardOverlay()" size="small" fill="solid" color="light" target="_blank">
                            <ion-icon name="trophy" slot="start"></ion-icon> {{'show_leaderboard' |  translate}}
                        </ion-button>
                        <ion-button *ngIf="clip.leaderboard && leaderboardOverlayShown" (click)="onCloseLeaderboardOverlay()" size="small" fill="solid" color="light" target="_blank">
                            <ion-icon name="trophy-outline" slot="start"></ion-icon> {{'hide_leaderboard' |  translate}}
                        </ion-button>
                        <ion-button *ngIf="clip.leaderboard" (click)="generateLeaderboardForClip()" size="small" fill="solid" color="light" target="_blank">
                            <ion-icon name="refresh" slot="start"></ion-icon> {{'generate_leaderboard' |  translate}}
                        </ion-button>
                    </p>
                </div>

                <div class="ion-padding-start" *ngIf="videoInfo && videoInfo.assessment_docs && videoInfo.assessment_docs.length > 0 && videoInfo.hasSubtitle">
                    <p>
                        <ion-button size="small" fill="solid" color="light" target="_blank"
                            (click)="openWorksheetSelectPage()">
                            <ion-icon name="document" slot="start"></ion-icon> {{'worksheets' |  translate}} ({{ uiUtils.getWorksheetLevels(videoInfo) }})
                        </ion-button>
                    </p>
                </div>

                <!-- Show the small blue (+) button, only of the user is an educator and it's not already a clip -->
                <div class="add-circle-btn ion-padding-start" *ngIf="videoInfo && appData.isEducator() && videoInfo.hasSubtitle && !videoPlayerEducatorOverlay?.clip?._id">
                    <ion-icon color="blue" name="add-circle" class="icon-add-circle"
                        (click)="addVideoToClass(videoInfo?._id);$event.stopPropagation();" ioniconRemoveTitle
                        iconTitle="{{ 'tooltip_add_video_to_group' | translate }}"></ion-icon>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>

    <!-- <div slot="fixed">
        <ion-button slot="icon-only" fill="outline" color="light" id="video-back-overlay-button"
            class="video-overlay-button" (click)="navigateBack()">
            <ion-icon name="arrow-back"></ion-icon>
        </ion-button>
    </div> -->
</ion-content>
