import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';

import { Api } from './api.service';
import {
    BlobTokenResponse,
    GeoipResponse,
    LoginResponse,
    PreAuthResponse,
    ISingleObjectResponse,
    IOnboardingUpdateResponse
} from '../../models/dtos';
import { IForgotPassword, IUpdatePassword } from '../../interfaces/IUpdatePassword';

/**
 * API for /users/
 * See http://spyder3-stage.uugot.it/api-docs/#/users
 */
@Injectable({
    providedIn: 'root'
})
export class UsersApi {
    constructor(public http: HttpClient, public api: Api) {
        // Do nothing.
    }

    /**
     * Checks if the given access code is valid and returns a message accordingly
     *
     * @param token The available token for the user
     */
    loginCode(token: string): Observable<LoginResponse> {
        const params = {
            access_code: token
        };
        return this.api.post('users/logincode', params);
    }

    /**
     * Checks if given username and password are valid and returns message accordingly
     *
     * @param username The username entered by user
     * @param password The password entered by user
     */
    login(username: string, password: string): Observable<LoginResponse> {
        const body = {
            username,
            password
        };

        return this.api.post('users/login', body);
    }

    /**
     * Sends an app login link with token to the requested email or an error if email is not found
     *
     * @param email The email of the user registered in the system
     */
    preAuth(email: string): Observable<PreAuthResponse> {
        // Issue with + in email: https://github.com/angular/angular/issues/18261
        const params = {
            email
        };

        return this.api.post('users/preauth', params);
    }

    /**
     * Checks the authorization and returns a valid blob token to write files to the blob storage. The token is valid for one hour
     *
     * @param token the login token to use for authentication.
     */
    blobToken(token: string): Observable<BlobTokenResponse> {
        return this.api.post('assessments/blobtoken', {});
    }

    /**
     * Get a user country code based on IP address.
     */
    geoip(): Observable<GeoipResponse> {
        return this.api.get('users/geoip');
    }

    /**
     * Checks if the given access code is valid and returns a message accordingly
     *
     * @param token The available token for the user
     */
    getMyInfo(): Observable<LoginResponse> {
        return this.api.get('users/my-info');
    }

    /**
     * Get the saved preferences of a user (key-values).
     */
    getPreferences(): Observable<any> {
        return this.api.get('users/preferences');
    }

    /**
     * Save a preference of the user on the server by key:value.
     *
     * @param key the key
     * @param value the value to save
     */
    savePreferences(key: string, value: any): Observable<ISingleObjectResponse<any>> {
        const prefs = {
            [key]: value
        };
        // console.log('PUT users/preferences', prefs);
        return this.api.put('users/preferences', prefs);
    }

    /**
     * Removes a preference of the user on the server.
     *
     * @param key the key
     */
    removePreferences(key: string): Observable<ISingleObjectResponse<any>> {

        // The PATCH operation doesn't seem to work with 'remove' op, see https://gitlab.com/uugotitTeam/uugot.it/-/issues/275
        // const body = {
        //     op: 'remove',
        //     path: `/${key}`
        // };
        // console.log('PATCH users/preferences', body);
        // return this.api.patch('users/preferences', body);

        // Setting key to null instead:
        const prefs = {
            [key]: null
        };
        // console.log('PUT users/preferences', prefs);
        return this.api.put('users/preferences', prefs);
    }

    /**
     * Save a preference of the user on the server by whole object.
     *
     * @param obj
     */
    savePreferencesAll(obj: any): Observable<ISingleObjectResponse<any>> {
        // console.log('PUT users/preferences', obj);
        return this.api.put('users/preferences', obj);
    }

    /**
     * Confirm email
     */
    confirmEmail(ac: string, e: string, invite: string = null) {
        const params = {
            ac,
            e,
            invite
        }
        return this.api.get('students/register/verify', params);
    }

    /**
     * Request forgot password email
     */
    requestForgotPasswordEmail(email: string) {
        return this.api.post('users/password', { email });
    }

    /**
     * Check forgot password token
     */
    checkForgotPasswordToken(t: string) {
        return this.api.get('users/password', { t });
    }

    /**
     * update password. Requires old_password and new_password and repeat_password
     */
    updatePassword(params: IUpdatePassword) {
        return this.api.put('users/password/change', params);
    }

    /**
     * update password. Requires old_password and new_password and repeat_password
     */
    forgotPassword(params: IForgotPassword) {
        return this.api.put('users/password', params);
    }


    /**
     * get invites to group of a user
     */
    getUserInvites() {
        return this.api.get('users/my-invites');
    }

    /**
     * Updates the onboarding complete preference for the user
     */
    updateOnboardingCompletePreference(): Observable<IOnboardingUpdateResponse> {
        return this.api.put('users/onboarding', {});
    }

}
