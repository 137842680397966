import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
// import 'rxjs/add/operator/map';

import { SavedWord } from '../models/saved-word.model';
import { WatchedVideo } from '../models/watched-video';
import { Utils } from '../utils/utils';
import { ILanguage } from '../interfaces/ILanguage';
import { IVideo } from '../interfaces/IVideo';
import { IWebsource } from '../interfaces/IWebsource';
import { IUser } from '../interfaces/IUser';
import { IGroup } from '../interfaces/IGroup';
import { environment } from '../../environments/environment';
import { Constants } from '../app.constants';
import { GeoipResponse, IListResponse } from '../models/dtos';
import { UsersApi } from './api/users-api.service';
import { WebsourcesApi } from './api/websources-api.service';
import { LanguagesApi } from './api/languages-api.service';
import { AppConfigApi } from './api/appconfig-api';
import * as moment from 'moment';
import { WatchedVideoApi } from './api/watched-videos-api.service';
import { FavoredVideosApi } from './api/favored-videos-api.service';
import { IFavoredVideo } from '../interfaces/IFavoredVideo';
import { FavoredVideo } from '../models/favored-video';
import { VideoCategoriesApi } from './api/video-categories-api';
import { IVideoCategory } from '../interfaces/IVideoCategory';
import { IEducatorAdminGroup } from '../interfaces/IEducatorAdminGroup';
import { IInvitesToGroups } from '../interfaces/IInvitesToGroups';
import { ICustomer } from '../interfaces/ICustomer';
import { IWatchedVideo } from '../interfaces/IWatchedVideo';

@Injectable({
    providedIn: 'root'
})
export class AppData {

    /**
     * The currently authenticated user
     */
    private _authenticatedUser: IUser = null;
    private _groupsForUser: IGroup[];
    private _educatorAdminGroups: IEducatorAdminGroup[];
    private _educatorInvitesToGroups: IInvitesToGroups[];
    private _groupsTemplatesForUser: IGroup[];
    private _videos: IVideo[];
    private _lastReload: Date;
    private _license: any;
    private _customer: ICustomer;
    private _onlyKidsContent: boolean;
    private _videoPlayerVideo = new BehaviorSubject<IVideo>(null);
    private readonly _localStorage_only = [
        this.constants.pref.INSTALLATION_ID,
        this.constants.pref.FIRST_LAUNCH_TIMESTAMP,
        this.constants.pref.LOGGED_USER,
        this.constants.pref.LOGGED_USER_PREFERENCES,
        this.constants.pref.AUTH_TOKEN,
        `${this.constants.pref.TERMS_ACCEPTED}_0`,
        `${this.constants.pref.TUTORIAL_DONE}_0`,
        this.constants.pref.OPENED_CLASSES,
        this.constants.pref.LICENSE,
        this.constants.pref.EVENTS,
        this.constants.pref.LICENSE,
        this.constants.pref.REACHALL_INTRO_HIDE,
        this.constants.pref.WORD_CATALOG,
        this.constants.pref.WORD_CATALOG_CLASSES,
        this.constants.pref.USER_EMAIL, // not used any more
        this.constants.pref.USER_LANGUAGE_LEVEL_DE, // not used any more
        this.constants.pref.USER_LANGUAGE_LEVEL_EN, // not used any more
        this.constants.pref.WATCHED_VIDEOS,
        this.constants.pref.FAVORED_VIDEOS,
        this.constants.pref.PLAYER_VOLUME,
        this.constants.pref.PLAYER_MUTED,
        this.constants.pref.FEEDBACK_BUTTON_CLICKED_BKA2021,
        this.constants.pref.SUBTITLE_FONT_SIZE,
        this.constants.pref.ASK_APP_INSTALLATION_TIMESTAMP,
        this.constants.pref.ONLY_KIDS_CONTENT
    ];
    public currentVideo = this._videoPlayerVideo.asObservable();
    public videoOfTheDay: IVideo;
    public subtitleLanguages: ILanguage[];
    public geoIp: GeoipResponse;
    public videoSources: IWebsource[];
    public videoCategories: IVideoCategory[];
    public allVideosLoaded = false;
    public newClassVideoId: string;
    public playBackRate: number;
    public switchPlaybackRateCallback: any;
    public activePage: string;
    public activeGroupId: string;
    public tutorialShown = false;
    public showMenu = true; // Show menu only have user is logged
    public videoPlayerIntroShown = false;
    public lastCatalogSearch: string

    /**
     * Mapping of wesource ID (eg. 'orftvthek') and boolean (true = video from this source has been played during the session)
     */
    public playedVideoOfSource = new Map<string, boolean>();

    /**
     * This will be loaded remotely from the server
     */
    public remoteConfig = {
        autoRegisterAllowedDomains: [] as string[],
        lastUpdate: null as Date,
        enableHotTopic: true
    }
    public remoteConfigLastFetch?: Date = null;

    /**
     * The watched videos that were loaded for the currently logged in user
     */
    public watchedVideosServer: WatchedVideo[];

    constructor(public constants: Constants,
        private _usersApi: UsersApi,
        private _websourcesApi: WebsourcesApi,
        private _languagesApi: LanguagesApi,
        private _watchedVideosApi: WatchedVideoApi,
        private _favoredVideosApi: FavoredVideosApi,
        private _appConfigApi: AppConfigApi,
        private _videoCategoriesApi: VideoCategoriesApi) {
        console.log('Created new AppData instance');
    }

    /**
     * Passes video to video player page
     *
     * @param video The video
     */
    changeVideo(video: IVideo) {
        this._videoPlayerVideo.next(video);
    }

    /**
     * Loads geoIp, video sources and subtitle languages - this is done in parallel
     */
    loadInitialData() {
        const geoIp = this._usersApi.geoip().map(
            data => {
                // console.log('Loaded geoip', data);
                this.geoIp = data;
                return data;
            }
        );
        const videoSources = this._websourcesApi.getVideoSources().map(
            data => {
                // console.log('Loaded video sources', data);
                this.videoSources = data;
                return data;
            }
        );
        const authToken = this.getPreferenceString(this.constants.pref.AUTH_TOKEN, null);
        const languages = this._languagesApi.getSubtitleLanguages(true, !!authToken).map(
            data => {
                // console.log('Loaded subtitle languages', data);
                this.subtitleLanguages = data;
                return data;
            }
        );
        const remoteConfig = this._appConfigApi.getRemoteConfig().map(
            data => {
                // console.log('Loaded remote config', data);
                this.remoteConfig.autoRegisterAllowedDomains = data.autoRegisterAllowedDomains;
                this.remoteConfig.lastUpdate = new Date(data.lastUpdate);
                this.remoteConfig.enableHotTopic = data.enableHotTopic;
                this.remoteConfigLastFetch = new Date();
                return data;
            }
        );
        const videoCategories = this._videoCategoriesApi.getVideoCategories().map(
            data => {
                // console.log('Loaded video sources', data);
                this.videoCategories = data;
                return data;
            }
        );
        return forkJoin(geoIp, videoSources, languages, remoteConfig, videoCategories);
    }

    /**
     * Saves preference key and value
     *
     * @param key Preference key
     * @param value Preference value
     */
    savePreferenceString(key: string, value: string, forceLocalStorage = false) {
        // console.log('savePreference ' + key + ' ' + (value && value.length > 100 ? value.substr(0, 100) + '...' : value));

        if (this._localStorage_only.includes(key) || forceLocalStorage) {
            window.localStorage[key] = value;
        } else {

            /* - logged (authenticated) user save preferences into the mongoDB.user_preferences and localStorage.logged_user_preferences.
               - not logged (anonymous) user save preferences into the localStorage */
            if (this.isLoggedIn()) { // logged user
                /* 1. save in localStorage.logged_user_preferences */
                // copy from previous logged_user or anonymous user or default
                const ls1 = JSON.parse(window.localStorage[this.constants.pref.LOGGED_USER_PREFERENCES] || '{}');
                // const ls2 = window.localStorage;
                // const user_preferences = {
                //     installation_id: ls1.installation_id || ls2.installation_id,
                //     video_quality: ls1.video_quality || ls2.video_quality || 'low',
                //     language: ls1.language || ls2.language || 'en',
                //     translation_lang: ls1.translation_lang || ls2.translation_lang || 'en',
                //     video_source: ls1.video_source || ls2.video_source || 'orftvthek',
                //     player_hide_subtitles: ls1.player_hide_subtitles || ls2.player_hide_subtitles || 0,
                //     playback_rate_index: ls1.playback_rate_index || ls2.playback_rate_index || 3,
                //     invited_educators: ls1.invited_educators || ls2.invited_educators,
                //     tutorial_done_educators_scooling_welcome: ls1.tutorial_done_educators_scooling_welcome || ls2.tutorial_done_educators_scooling_welcome
                // };
                // user_preferences[key] = value; // override existing pr add new key
                ls1[key] = value;
                window.localStorage[this.constants.pref.LOGGED_USER_PREFERENCES] = JSON.stringify(ls1);
                // this.authenticatedUserPrefs[key] = value;

                /* 2. save in the mongoDB */
                this._usersApi.savePreferences(key, value)
                    .subscribe(resp => {
                        // console.log('Save preferences response:', resp);
                    }, err => {
                        // console.error('Error ', err.error);
                    });
            } else { // anonymous user
                window.localStorage[key] = value;
            }
        }

    }

    /**
     * Gets a preference value given the key
     *
     * @param key The key of the preference
     * @param [fallback=null] Fallback value in case it is not found
     * @returns Returns the value of the key or fallback if not found
     */
    getPreferenceString(key: string, fallback: string = null, forceLocalStorage = false): string {
        let value;
        if (this.isLoggedIn() && !this._localStorage_only.includes(key) && !forceLocalStorage) {
            const preferences = JSON.parse(window.localStorage[this.constants.pref.LOGGED_USER_PREFERENCES] || '{}');
            value = preferences[key];
        } else {
            value = window.localStorage[key];
        }
        // console.log(`getPreference ${key} ${value && value.length > 100 ? `${value.substr(0, 100)}...` : value}`);
        if (value !== null && value !== undefined) {
            return value;
        } else {
            return fallback;
        }
    }

    /**
     * Removes a preference from local storage.
     *
     * @param key Preference key
     */
    removePreference(key: string, forceLocalStorage = false) {
        console.log('removePreference ' + key);
        if (this.isLoggedIn() && !this._localStorage_only.includes(key) && !forceLocalStorage) {
            const preferences = JSON.parse(window.localStorage[this.constants.pref.LOGGED_USER_PREFERENCES] || '{}');
            delete preferences[key];
            window.localStorage[this.constants.pref.LOGGED_USER_PREFERENCES] = JSON.stringify(preferences);
            this._usersApi.removePreferences(key).toPromise().then().catch(err => console.log('Error patching preference', key, err));
        } else {
            window.localStorage.removeItem(key);
        }
    }

    /**
     * Checks if user should reload
     *
     * @returns True if user should reload, false otherwise
     */
    shouldReload(): boolean {
        if (this._lastReload === null || this._lastReload === undefined) {
            console.log('shouldReload _lastReload is null');
            return true;
        }
        const diffSeconds = (new Date().getTime() - this._lastReload.getTime()) / 1000;
        console.log(`shouldReload diff seconds ${diffSeconds}`);
        const maxDataAgeSeconds = 3600 * 24; // 1 day
        return diffSeconds > maxDataAgeSeconds;
    }

    /**
     * Force reload videos
     */
    forceReloadVideos() {
        this._lastReload = null;
    }

    /**
     * Getter method for _videos
     */
    get getVideos(): IVideo[] {
        return this._videos;
    }

    /**
     * Setter method for _videos
     */
    set setVideos(newVideos: IVideo[]) {
        this._videos = newVideos;
    }

    /**
     * Getter method for videos
     */
    get videos(): IVideo[] {
        if (this.videoOfTheDay) {
            return [this.videoOfTheDay].concat(this._videos);
        } else {
            return this._videos;
        }
    }

    /**
     * Setter method for videos
     *
     * @param newVideos The videos to be set
     */
    set videos(newVideos: IVideo[]) {
        this._videos = newVideos;
        this._lastReload = new Date();
        this.allVideosLoaded = false;
    }

    /**
     * Append videos to the videos array
     *
     * @param newVideos Array of videos to be added
     */
    addVideos(newVideos: IVideo[]) {
        if (!this._videos || this._videos.length === 0) {
            console.log(`Setting ${newVideos.length} new videos`);
            this._videos = newVideos;
            this._lastReload = new Date();
        } else {
            console.log(`Adding ${newVideos.length} new videos`);
            this._videos = this._videos.concat(newVideos);
        }
    }

    /**
     * Repkace videos in the videos array
     * @param videosAvailable Array of videos to replace
     */
    replaceVideos(videosAvailable: IVideo[]) {
        console.log(`Replacing with ${videosAvailable.length} new videos`);
        this._videos = videosAvailable;
    }

    /**
     * Returns the preferred language of the user ('en', 'de') etc.
     */
    getLanguage(): string {
        const language = this.getPreferenceString(this.constants.pref.LANGUAGE, this.constants.DefaultLanguage);
        if (language === 'system') {
            // the lang to use, if the lang isn't available, it will use the current loader to get them
            return Utils.getSystemLanguage();
        } else {
            return language;
        }
    }

    /**
     * A URL like http://service.uugot.it:8082/logo/orftvthek/small for the logo of a video source (for example ORF)
     *
     * @param sourceId for example 'daserste' or 'orftvthek'
     * @param size s, m or l
     */
    getLogoPathForSource(sourceId: string, size: string): string {

        /**
         Example of source:
         {
           "id": "daserste",
           "name": "Das Erste",
           "description": "Das Erste Programm online mit Videos, Informationen und Service zu Politik, Unterhaltung, Filmen und Serien.",
           "lang": "de",
           "logo": {
             "s": "/logo/daserste/small",
             "m": "/logo/daserste/medium",
             "l": "/logo/daserste/large"
           }
         },
         */
        if (!this.videoSources || !sourceId) {
            return null;
        }
        const source = this.getWebsourceById(sourceId);
        if (!source) {
            return null;
        }
        let logoPath = source.logo[size] as string;
        if (logoPath && !logoPath.startsWith('http')) {
            logoPath = environment.spyderBaseUrl + logoPath;
        }
        return logoPath;
    }

    /**
     * Returns the name for a websource ID.
     * @param sourceId for example "orftvthek"
     */
    getNameForSource(sourceId: string): string {
        if (!this.videoSources || !sourceId) {
            return null;
        }
        const source = this.getWebsourceById(sourceId);
        return source ? source.name : '';
    }

    /**
     * Get a web video source by ID.
     * @param sourceId For example "orftvthek"
     */
    getWebsourceById(sourceId: string): IWebsource {
        return this.videoSources.find(ws => ws.id === sourceId);
    }

    // ============= Video categoris:

    /**
     * Get a video category by slug.
     * @param sourceId For example "news"
     */
    getVideoCategoryBySlug(slug: string): IVideoCategory {
        return this.videoCategories.find(cat => cat.slug === slug);
    }

    getVideoCategoryById(id: string): IVideoCategory {
        return this.videoCategories.find(cat => cat._id === id);
    }

    // ============= Word catalog:

    /**
     * Gets the word catalog
     *
     * @returns Returns word catalog
     */
    getWordCatalog(): SavedWord[] {
        const jsonString = this.getPreferenceString(this.constants.pref.WORD_CATALOG, '[]');
        return JSON.parse(jsonString);
    }

    /**
     * Add word to word catalog
     *
     * @param Word to be added
     */
    addNewWordToCatalog(word: SavedWord) {
        const wordCatalog = this.getWordCatalog();
        let isFoundWord = false;
        for (const found of wordCatalog) {
            if (found.oriWord === word.oriWord && found.toWord === word.toWord &&
                found.video_id === word.video_id && found.start === word.start) {
                isFoundWord = true;
                break;
            }
        }

        // Only add word if it doesn't exist
        if (!isFoundWord) {
            // Insert new word at start of array to appear first
            wordCatalog.unshift(word);
            this.savePreferenceString(this.constants.pref.WORD_CATALOG, JSON.stringify(wordCatalog));
        }
    }

    /**
     * Save word catalog
     *
     * @param wordCatalog The new word catalog to be saved
     */
    saveWordCatalog(wordCatalog: SavedWord[]) {
        this.savePreferenceString(this.constants.pref.WORD_CATALOG, JSON.stringify(wordCatalog));
    }

    // ============= Watched videos:

    /**
     * Gets watched videos
     *
     * @returns Returns an array of watched videos
     */
    getWatchedVideos(): WatchedVideo[] {
        if (this.isLoggedIn()) {
            return this.watchedVideosServer;
        } else {
            const jsonString = this.getPreferenceString(this.constants.pref.WATCHED_VIDEOS, '[]');
            const parsed: Array<any> = JSON.parse(jsonString);
            return parsed.map(WatchedVideo.fromJSONObject);
        }
    }

    getWatchedVideo(videoId: string): WatchedVideo {
        return this.getWatchedVideos().find(w => w.videoId === videoId);
    }

    /**
     * Adds a new WatchedVideo to the localStorage, or updates it if the video IDs match
     *
     * @param videoId the video ID
     * @param watchedUntil the time progress in seconds
     * @param dur duration of the video in seconds
     * @param fin true if the user finished watching the video
     */
    async addOrUpdateWatchedVideo(videoId: string, watchedUntil?: number, expiryDate?: Date, dur?: number, fin?: boolean, clipId?: string): Promise<any> {
        if (this.isLoggedIn()) {
            return this._watchedVideosApi.update(videoId, watchedUntil, dur, fin, clipId).toPromise();
        } else {
            const watchedVideo = new WatchedVideo(videoId, watchedUntil, expiryDate, dur, fin, clipId);
            // console.log('addOrUpdateWatchedVideo', watchedVideo);
            const watched = this.getWatchedVideos();
            const watchedVideoExists = watchedVideo.isContainedInArray(watched);
            if (watchedVideoExists) {
                if (
                    // --- This will update if time was null/undefined or of new time is greater than previous time:
                    // watchedVideoExists.watchedUntil === null ||
                    // watchedVideoExists.watchedUntil === undefined ||
                    // watchedVideo.watchedUntil > watchedVideoExists.watchedUntil
                    // --- This will update if time has changed:
                    watchedVideo.watchedUntil !== watchedVideoExists.watchedUntil
                ) {
                    watchedVideoExists.watchedUntil = watchedVideo.watchedUntil;
                    watchedVideoExists.updatedAt = new Date();
                }
                if (watchedVideo.expiryDate !== undefined) {
                    watchedVideoExists.expiryDate = watchedVideo.expiryDate;
                }
                if (watchedVideo.dur && watchedVideo.dur !== watchedVideoExists.dur) {
                    watchedVideoExists.dur = watchedVideo.dur;
                }
                if (!watchedVideoExists.fin && watchedVideo.fin) {
                    watchedVideoExists.fin = fin;
                }
            } else {
                watched.push(watchedVideo);
            }
            const json = JSON.stringify(watched.map(WatchedVideo.toJSONObject));
            this.savePreferenceString(this.constants.pref.WATCHED_VIDEOS, json);
            return Promise.resolve();
        }
    }


    // ============= Favored videos:

    /**
     * Gets favored videos
     *
     * @returns Returns an array of favored videos
     */
    async getFavoredVideos(): Promise<IFavoredVideo[]> {
        if (this.isLoggedIn()) {
            const res = await this._favoredVideosApi.list(1000, 0, '-created_at').toPromise();
            return res.data || [];
        } else {
            const jsonString = this.getPreferenceString(this.constants.pref.FAVORED_VIDEOS, '[]');
            const parsed: Array<any> = JSON.parse(jsonString);
            return parsed.map(FavoredVideo.fromJSONObject);
            // return new Promise(resolve => {
            //     setTimeout(() => {
            //         resolve(parsed.map(FavoredVideo.fromJSONObject));
            //     }, 2000);
            // });
        }
    }

    /**
     * Returns an IFavoredVideo if the given video is favored by the user, or `null` otherwise;
     * @param videoId the video ID
     * @returns IFavoredVideo or null
     */
    async getFavoredVideo(videoId: string): Promise<IFavoredVideo> {
        if (this.isLoggedIn()) {
            const res = await this._favoredVideosApi.listByVideos([videoId]).toPromise();
            return res.data?.length ? res.data[0] : null;
        } else {
            const favoredVideos = await this.getFavoredVideos();
            return favoredVideos?.find((w) => w.video_id === videoId) || null;
        }
    }

    /**
     * Adds a new favored video to the localStorage, or updates it if the video IDs match
     *
     * @param videoId the video ID
     * @returns the favored video object
     */
    async addOrUpdateFavoredVideo(videoId: string, expiryDate?: Date): Promise<IFavoredVideo> {
        if (this.isLoggedIn()) {
            const res = await this._favoredVideosApi.add(videoId).toPromise();
            return res.data;
        } else {
            const favoredVideo = new FavoredVideo(videoId, expiryDate);
            // console.log('addOrUpdateWatchedVideo', watchedVideo);
            const favored = await this.getFavoredVideos();
            const favoredVideoExists = favoredVideo.isContainedInArray(favored);
            if (favoredVideoExists) {
                if (favoredVideo.expiryDate !== undefined) {
                    favoredVideoExists.expiryDate = favoredVideo.expiryDate;
                }
            } else {
                favored.push(favoredVideo);
            }
            const json = JSON.stringify(favored.map(FavoredVideo.toJSONObject));
            this.savePreferenceString(this.constants.pref.FAVORED_VIDEOS, json);
            return Promise.resolve(favoredVideoExists || favoredVideo);
        }
    }

    /**
     * Deletes a favored video from the localStorage or server.
     *
     * @param videoId the video ID
     */
    async deleteFavoredVideo(videoId: string): Promise<{ success: boolean; msg: string; }> {
        if (this.isLoggedIn()) {
            return this._favoredVideosApi.delete(videoId).toPromise();
        } else {
            let favored = await this.getFavoredVideos();
            favored = favored.filter((fv) => fv.video_id !== videoId);
            // console.log('addOrUpdateWatchedVideo', watchedVideo);
            const json = JSON.stringify(favored.map(FavoredVideo.toJSONObject));
            this.savePreferenceString(this.constants.pref.FAVORED_VIDEOS, json);
            return {
                success: true,
                msg: 'Favored video was deleted.',
            };
        }
    }

    /**
     * Clears out expired WatchedVideos (local storage only).
     */
    pruneExpiredWatchedVideos() {
        this.pruneExpiredWatchedOrFavoredVideos(
            this.constants.pref.WATCHED_VIDEOS,
            WatchedVideo.fromJSONObject,
            WatchedVideo.toJSONObject,
            2
        );
    }

    /**
     * Clears out expired FavoredVideos (local storage only).
     */
    pruneExpiredFavoredVideos() {
        this.pruneExpiredWatchedOrFavoredVideos(
            this.constants.pref.FAVORED_VIDEOS,
            FavoredVideo.fromJSONObject,
            FavoredVideo.toJSONObject,
            12
        );
    }

    /**
     * Clears out expired WatchedVideos or FavoredVideos (local storage only).
     * @param prefKey constants.pref.FAVORED_VIDEOS or constants.pref.WATCHED_VIDEOS
     * @param fromJSONObject mapper function from "minified" JSON object to object
     * @param fromJSONObject mapper function object to "minified" JSON
     * @param months clear out videos that have an expired date more than x months ago
     */
    pruneExpiredWatchedOrFavoredVideos(
        prefKey: string,
        fromJSONObject: (json: any) => { expiryDate?: Date; },
        toJSONObject: (obj: any) => any,
        months: number
    ) {
        const jsonString = this.getPreferenceString(prefKey, '[]');
        const parsed: Array<any> = JSON.parse(jsonString);
        let watched = parsed.map(fromJSONObject);

        // console.log('clearExpiredWatchedVideos before', watched.length);
        watched = watched.filter((w) => {
            if (!w.expiryDate) {
                // console.log('clearExpiredWatchedVideos filter: no expiry date', w.videoId);
                return true;
            } else {
                const expiryMoment = moment(w.expiryDate);
                const keep = expiryMoment.add(months, 'months').isAfter(moment());
                // console.log('clearExpiredWatchedVideos filter: ', w.expiryDate, keep);
                return keep;
            }
        });
        // console.log('clearExpiredWatchedVideos after', watched.length);
        const json = JSON.stringify(watched.map(toJSONObject));
        this.savePreferenceString(prefKey, json);
    }

    // ============= Classes

    /**
     * Gets the classId of the classes stored locally
     *
     * @return An array of the class Ids
     */
    getClassIds(): Array<string> {
        const jsonString = this.getPreferenceString(this.constants.pref.OPENED_CLASSES, '[]');
        return JSON.parse(jsonString);
    }

    /**
     * Adds a classId to locally stored classes array if it doesn't already exist
     *
     * @param classId The classId to add
     */
    addClassId(classId: string) {
        const classes = this.getClassIds();
        if (classes.indexOf(classId) === -1) {
            classes.push(classId);
        }
        this.savePreferenceString(this.constants.pref.OPENED_CLASSES, JSON.stringify(classes));
    }

    /**
     * Removes a classId from locally stored classes
     *
     * @param classId The classId to remove
     */
    removeClassId(classId: string) {
        const classes = this.getClassIds(), index = classes.indexOf(classId);
        if (index !== -1) {
            classes.splice(index, 1);
        }
        this.savePreferenceString(this.constants.pref.OPENED_CLASSES, JSON.stringify(classes));
    }

    // ============= User / Auth

    /**
     * Getter method for authenticated user
     *
     * @returns The authenticated user
     */
    get authenticatedUser(): IUser {
        return this._authenticatedUser;
    }

    /**
     * Setter method for authenticated user
     *
     * @param user The new authenticated user
     */
    set authenticatedUser(user: IUser) {
        this._authenticatedUser = user;
    }

    /**
     * Getter method for groups for user
     *
     * @returns Returns groups for user
     */
    get groupsForUser(): IGroup[] {
        return this._groupsForUser;
    }

    /**
     * Setter method for groups for educator
     *
     * @param groups The new groups to be set for the user
     */
    set groupsForUser(groups: IGroup[]) {
        this._groupsForUser = groups;
    }

    /**
     * Getter method for groups for educator user admin
     *
     * @returns Returns groups for user
     */
    get educatorAdminGroups(): IEducatorAdminGroup[] {
        return this._educatorAdminGroups;
    }

    /**
     * Setter method for groups for educator user admin
     *
     * @param groups The new groups to be set for the user
     */
    set educatorAdminGroups(groups: IEducatorAdminGroup[]) {
        this._educatorAdminGroups = groups;
    }

    /**
     * Getter method for educator's group invites
     *
     * @returns Returns invites of logged in educator
     */
    get educatorInvitesToGroups(): IInvitesToGroups[] {
        return this._educatorInvitesToGroups;
    }

    /**
     * Setter method for educator's group invites     *
     * @param invites
     */
    set educatorInvitesToGroups(invites: IInvitesToGroups[]) {
        this._educatorInvitesToGroups = invites;
    }

    /**
     * Getter method for template groups for user
     *
     * @returns Returns template groups for user
     */
    get groupTemplatesForUser(): IGroup[] {
        return this._groupsTemplatesForUser;
    }

    /**
     * Setter method for template groups for educator
     *
     * @param groups The new template groups to be set for the user
     */
    set groupTemplatesForUser(groups: IGroup[]) {
        this._groupsTemplatesForUser = groups;
    }


    /**
     * Getter method for current user license
     *
     * @returns Returns the licese of current user
     */
    get userLicense(): any {
        return this._license;
    }

    /**
     * Setter method for current user license
     *
     * @param license The new license of the user
     */
    set userLicense(license: any) {
        this._license = license;
    }

    /**
     * Getter method for current user customer
     *
     * @returns Returns the customer of current user
     */
    get userCustomer(): ICustomer {
        return this._customer;
    }

    /**
     * Setter method for current user customer
     *
     * @param customer
     */
    set userCustomer(customer: ICustomer) {
        this._customer = customer;
    }

    /**
     * Getter method for current user only kids content
     *
     * @returns Returns the only kids content prop of current user
     */
    get userOnlyKidsContent(): boolean {
        return this._onlyKidsContent;
    }

    /**
     * Setter method for current user only kids content
     *
     * @param customer
     */
    set userOnlyKidsContent(onlyKidsContent) {
        this._onlyKidsContent = onlyKidsContent;
    }

    /**
     * Returns true if the current user is authenticated and has the role 'educator'
     */
    isEducator(): boolean {
        return this.authenticatedUser && this.authenticatedUser.role === 'educator';
    }

    /**
     * Returns true if the current user is authenticated and has any role
     */
    isLoggedIn(): boolean {
        return this.authenticatedUser !== null && this.authenticatedUser !== undefined;
    }

    /**
     * Returns true if the current user is authenticated and has the role 'student'
     */
    isStudent(): boolean {
        return this.authenticatedUser && this.authenticatedUser.role === 'student';
    }

    /**
     * Returns true if the current user is authenticated and has the role 'educator' and is a corrector
     */
    isCorrector(): boolean {
        const user = this.authenticatedUser;
        return user && user.role === 'educator' && user.corrector;
    }

    /**
     * Returns the device installation ID that is stored in local storage (see constants.pref.INSTALLATION_ID)
     */
    getInstallationId(fallback: string = null): string {
        return this.getPreferenceString(this.constants.pref.INSTALLATION_ID, fallback);
    }

    getSubtitleLanguagesForLoggedInUser(): ILanguage[] {
        if (!this.subtitleLanguages) {
            console.log('getSubtitleLanguagesForLoggedInUser subtitleLanguages is null');
            return [];
        }
        if (this.isLoggedIn()) {
            return this.subtitleLanguages;
        }
        console.log('VA A FILTRAR', this.subtitleLanguages)
        const languages = this.subtitleLanguages.filter(lang => {
            if (!this.isLoggedIn()) {
                // Public users
                return lang.is_public;
            } else {
                // For logged in users, check the license
                return this.userLicense.translation_languages.includes(lang.code); //this needs to be removed. Now it comes from the api directly.
            }
        });
        if (languages.length === 0) {
            console.log('No languages after filtering');
        }
        return languages;
    }

    setCatalogSearch(search: string) {
        this.lastCatalogSearch = search;
    }
}
