import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import {
    AlertController,
    LoadingController,
    ModalController,
    NavController,
    Platform
} from '@ionic/angular';
import { IVideo } from 'src/app/interfaces/IVideo';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from 'src/app/services/app-data.service';
import { Constants } from 'src/app/app.constants';

import { IVideoPlayerEducatorOverlayListener } from './video-player-educator-overlay.interfaces';
import {
    VideoPlayerEditQuestionPage,
    IVideoPlayerEditQuestionPageResult,
} from 'src/app/pages/video-player-educator/video-player-edit-question/video-player-edit-question.page';
import { TasksApi } from 'src/app/services/api/tasks.service';
import { ITask, ETaskType } from 'src/app/interfaces/ITask';
import { IClip } from 'src/app/interfaces/IClip';
import {
    VideoPlayerAddQuestionPage,
    IVideoPlayerAddQuestionPageResult,
} from 'src/app/pages/video-player-educator/video-player-add-question/video-player-add-question.page';
import { ClipsApi } from 'src/app/services/api/clips.service';
import { ISingleObjectResponse, GroupVideoResponse } from 'src/app/models/dtos';
import { GroupsApi } from 'src/app/services/api/groups-api.service';

import * as moment from 'moment';
import { IGroup } from 'src/app/interfaces/IGroup';
import { concat, isNil, pull, remove, sortBy } from 'lodash-es';

export enum UiState {
    video = 'video',
    addingToClass = 'addingToClass',
    tasksHome = 'tasksHome',
    clipHome = 'clipHome',
    clipSelectStart = 'clipSelectStart',
    clipSelectEnd = 'clipSelectEnd',
}

/**
 * Component that shows clips and tasks functionality as a video player overlay for educators.
 */
@Component({
    selector: 'video-player-educator-overlay',
    templateUrl: './video-player-educator-overlay.component.html',
    styleUrls: ['./video-player-educator-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerEducatorOverlayComponent implements OnInit {
    @Input() video: IVideo; // the video

    @Input() isEmbed = false;

    @Input() listener: IVideoPlayerEducatorOverlayListener;

    @Input() videoJSplayer: any;

    uiState: UiState = UiState.video;

    assignedTasks: ITask[] = [];

    /**
     * Set to true if the assigned tasks have been loaded.
     */
    isAssignedTasksLoaded = false;
    availableTasks: ITask[] = null;

    uiStateEnum: typeof UiState = UiState;
    taskTypeEnum: typeof ETaskType = ETaskType;
    @Input() clip: IClip;
    @Output() clipChange = new EventEmitter<IClip>(); // Two way data binding
    @Input() class_id: string; // ID of the class = group

    public isTimeInputFieldFocused = false;
    public timeInputColorName: null | 'warning' | 'danger' = null;

    constructor(
        private logger: NGXLogger,
        public translate: TranslateService,
        public uiUtils: UiUtils,
        public appData: AppData,
        public loadingCtrl: LoadingController,
        public constants: Constants,
        private modalCtrl: ModalController,
        private alertCtrl: AlertController,
        private navCtrl: NavController,
        public tasksApi: TasksApi,
        public clipsApi: ClipsApi,
        private groupApi: GroupsApi,
        public plt: Platform
    ) {
        // empty
        this.logger.debug('VideoPlayerEducatorOverlayComponent constructor');
    }

    ngOnInit() {
        this.logger.debug('VideoPlayerEducatorOverlayComponent.ngOnInit()');
    }

    initClip() {
        if (this.clip) {
            this.logger.debug(
                `VideoPlayerEducatorOverlayComponent.initClip() with clip - start = ${this.clip.start}, end = ${this.clip.end}, duration = ${this.clip.duration}`
            );
        } else if (this.videoJSplayer) {
            this.clip = {
                video_id: this.video._id,
                start: 0,
                end: this.videoJSplayer.duration(),
                duration: this.videoJSplayer.duration(),
                createdBy: this.appData?.authenticatedUser?._id,
                isFull: true,
                task_ids: [],
                title: null,
            };
            this.clipChange.emit(this.clip);
            this.logger.debug(
                `VideoPlayerEducatorOverlayComponent.initClip() with videojs start = ${this.clip.start}, end = ${this.clip.end}, duration = ${this.clip.duration}`
            );
        } else {
            this.logger.warn(
                'VideoPlayerEducatorOverlayComponent.initClip() videoJSplayer is null'
            );
        }
    }

    changeUiState(newState: UiState, doPauseVideo?: boolean) {
        this.uiState = newState;
        switch (newState) {
            case UiState.tasksHome:
                this.listener.lockToLandscapeOrientation();
                this.setPlayerHasStartedClass();
                if (this.clip?._id) {
                    this.loadTasksForClip().then(() => {
                        if (this.availableTasks === null) {
                            this.loadAvailableTasksInVideo().then();
                        }
                    });
                } else if (this.availableTasks === null) {
                    this.loadAvailableTasksInVideo().then();
                }
                break;
            case UiState.clipHome:
                this.listener.lockToLandscapeOrientation();
                this.setPlayerHasStartedClass();
                if (!this.clip) {
                    this.initClip();
                }
                break;
            default:
                break;
        }
        if (doPauseVideo) {
            this.videoJSplayer.pause();
        }
    }

    /**
     * Add the `vjs-has-started` class to the video-js element to show the control bar.
     * See https://github.com/videojs/video.js/issues/5143
     */
    setPlayerHasStartedClass() {
        const elements = document.getElementsByClassName('video-js');
        if (elements.length > 0) {
            elements[0].classList.add('vjs-has-started');
        }
    }

    get isOverlayShown(): boolean {
        return this.uiState != UiState.video;
    }

    exitFullscreen() {
        if (this.videoJSplayer.isFullscreen()) {
            this.videoJSplayer.exitFullscreen();
        }
    }

    async showCreateQuestionPage() {
        this.exitFullscreen();
        const position = this.videoJSplayer.currentTime();

        const showModalPage = async () => {
            const modal = await this.modalCtrl.create({
                component: VideoPlayerEditQuestionPage,
                showBackdrop: true,
                componentProps: {
                    position,
                    video: this.video,
                    clip: this.clip,
                },
            });
            await modal.present();
            const { data } = await modal.onDidDismiss();
            this.logger.debug('showCreateQuestionPage result data', data);
            const resultData = data as IVideoPlayerEditQuestionPageResult;
            if (resultData?.isSaved) {
                this.assignedTasks.push(resultData.savedTask);
                if (!this.clip) {
                    this.initClip();
                }
                if (!this.clip.task_ids) {
                    this.clip.task_ids = [];
                }
                this.clip.task_ids.push(resultData.savedTask._id);
                await this.updateClip({ task_ids: this.clip.task_ids });
                if (this.clip._id) {
                    await this.loadTasksForClip();
                }
                this.updateClipMarkers();
            }
        };

        // ### Do some checks and show "warning" dialogs before opening the real modal
        const tasksAtCurrentPosition = this.getTaskInClipAtCurrentPosition();
        if (position < 1.0) {
            this.logger.debug('showCreateQuestionPage position is < 1.0 sec');
            const alert = await this.alertCtrl.create({
                header: this.translate.instant('create_new_task'),
                message: this.translate.instant('create_new_task_beginning_of_video'),
                buttons: [
                    {
                        text: this.translate.instant('btn_cancel'),
                        role: 'cancel',
                    },
                    {
                        text: this.translate.instant('btn_yes'),
                        handler: showModalPage,
                    },
                ],
            });
            await alert.present();
        } else if (tasksAtCurrentPosition.length > 0) {
            this.logger.debug(
                'showCreateQuestionPage got tasks at current position',
                tasksAtCurrentPosition.length
            );
            const alert = await this.alertCtrl.create({
                header: this.translate.instant('create_new_task'),
                message: this.translate.instant('create_new_task_already_exists_at_time'),
                buttons: [
                    {
                        text: this.translate.instant('btn_cancel'),
                        role: 'cancel',
                    },
                    {
                        text: this.translate.instant('btn_yes'),
                        handler: showModalPage,
                    },
                ],
            });
            await alert.present();
        } else {
            this.logger.debug('showCreateQuestionPage checks OK - show modal directly');
            await showModalPage();
        }
    }

    async showAddQuestionPage() {
        this.exitFullscreen();
        const showModalPage = async () => {
            // We already pass in the resultData as a reference
            const resultData: IVideoPlayerAddQuestionPageResult = {
                addedTasks: [],
            };
            const modal = await this.modalCtrl.create({
                component: VideoPlayerAddQuestionPage,
                backdropDismiss: true,
                showBackdrop: true,
                componentProps: {
                    video: this.video,
                    excludeTaskIds: this.assignedTasks?.map((task) => task._id) || [],
                    clip: this.clip,
                    resultData,
                },
            });
            await modal.present();
            const dismissEvent = await modal.onDidDismiss();
            this.logger.debug('showAddQuestionPage event and resultData', dismissEvent, resultData);
            if (resultData?.addedTasks.length > 0) {
                const addedTasksIds = resultData.addedTasks.map((t) => t._id);
                resultData.addedTasks.forEach((task) => {
                    if (!this.assignedTasks.map((t) => t._id).includes(task._id)) {
                        this.assignedTasks.push(task);
                    }
                });
                this.assignedTasks = sortBy(this.assignedTasks, ['position', '_id']);
                if (!this.clip) {
                    this.initClip();
                }

                if (!this.clip.task_ids) {
                    this.clip.task_ids = [];
                }
                this.clip.task_ids = concat(this.clip.task_ids, addedTasksIds);
                await this.updateClip({ task_ids: this.clip.task_ids });
                if (this.clip._id) {
                    await this.loadTasksForClip();
                }

                // Remove the newly added ones from available tasks
                const assignedTaskId = this.assignedTasks?.map((t) => t._id);
                this.availableTasks = this.availableTasks.filter(
                    (t) => !assignedTaskId.includes(t._id)
                );
                this.updateClipMarkers();
            }
        };
        await showModalPage();
    }

    async loadTasksForClip() {
        const tasksResponse = await this.clipsApi.getTasksByClipId(this.clip._id).toPromise();
        this.assignedTasks = tasksResponse.data;
        this.isAssignedTasksLoaded = true;
    }

    async loadAvailableTasksInVideo() {
        const availableTasksResponse = await this.tasksApi
            .getTasksByVideo(this.video._id, 0, 200)
            .toPromise();
        const assignedTaskId = this.assignedTasks?.map((t) => t._id);
        this.logger.debug('assignedTaskId', assignedTaskId);
        this.availableTasks = availableTasksResponse.data;
        this.availableTasks = this.availableTasks.filter((t) => {
            if (assignedTaskId?.includes(t._id)) {
                return false;
            }
            // Check if task is within cropped video
            if (this.clip) {
                if (!isNil(this.clip.cropStart) && t.position < this.clip.cropStart) {
                    return false;
                }
                if (!isNil(this.clip.cropEnd) && t.position > this.clip.cropEnd) {
                    return false;
                }
            }
            return true;
        });
        this.logger.debug('loadAvailableTasksInVideo', this.availableTasks);
    }

    async editTask(task: ITask) {
        this.exitFullscreen();
        const showModalPage = async () => {
            const modal = await this.modalCtrl.create({
                component: VideoPlayerEditQuestionPage,
                showBackdrop: true,
                componentProps: {
                    position: task.position,
                    video: this.video,
                    clip: this.clip,
                    task,
                },
            });
            await modal.present();
            const { data } = await modal.onDidDismiss();
            this.logger.debug('editTask result data', data);
            const resultData = data as IVideoPlayerEditQuestionPageResult;
            if (resultData?.isSaved) {
                if (this.clip?._id) {
                    // The clip is already saved on the server
                    this.loadTasksForClip();
                } else {
                    // The clip is not saved yet on the server
                    const taskIndex = this.assignedTasks.findIndex(
                        (t) => t._id === resultData.savedTask?._id
                    );
                    if (taskIndex > -1) {
                        // Replace tasks in array
                        this.assignedTasks.splice(taskIndex, 1, resultData.savedTask);
                    }
                }
            }
        };
        await showModalPage();
    }

    /**
     * Reemoves a task from this.assignedTasks and also updates the clip using the server API.
     * @param task the task to remove
     */
    async removeTask(task: ITask) {
        this.logger.debug('removeTask assignedTasks before', this.assignedTasks);
        remove(this.assignedTasks, (t) => t._id === task._id);
        this.logger.debug('removeTask assignedTasks after', this.assignedTasks);
        if (this.clip) {
            if (!this.clip.task_ids) {
                this.clip.task_ids = [];
            }
            this.logger.debug('removeTask clip.task_ids before', this.clip.task_ids);
            pull(this.clip.task_ids, task._id);
            this.logger.debug('removeTask clip.task_ids after', this.clip.task_ids);
            // We got a clip _id, so update the clip on the server with the new tasks_ids:
            if (this.clip._id) {
                await this.clipsApi
                    .updateClip(this.clip._id, { task_ids: this.clip.task_ids })
                    .toPromise();
                await this.loadTasksForClip();
            }
            // Load available tasks for this video:
            await this.loadAvailableTasksInVideo();
        }
        this.updateClipMarkers();
    }

    getTaskInClipAtCurrentPosition(): ITask[] {
        if (!this.assignedTasks) {
            return [];
        }
        const currentPlayerTime = this.videoJSplayer.currentTime() as number;
        const tasks = this.assignedTasks.filter((t) => {
            return t.position > currentPlayerTime - 1 && t.position < currentPlayerTime + 1;
        });
        this.logger.debug(`Tasks at position ${currentPlayerTime}`, tasks);
        return tasks;
    }

    onTimeInputFocus(event: any) {
        this.logger.debug('onTimeInputFocus', event);
        this.isTimeInputFieldFocused = true;
    }

    onTimeInputBlur(event: any) {
        this.logger.debug('onTimeInputBlur', event);
        this.isTimeInputFieldFocused = false;
    }

    onTimeInputChange(event: any) {
        // this.logger.debug('onTimeInputChange', event);
        let timeString: string = event.detail.value;
        const timeStringNoColons = timeString.replace(/:/, '');
        this.logger.debug('timeStringNoColons', timeStringNoColons);
        if (timeStringNoColons.length < 3 || timeStringNoColons.length > 6) {
            this.timeInputColorName = 'danger';
            return;
        }
        const timeStringSplit = timeString.split(':');
        if (timeStringSplit.length === 2 && timeStringSplit[1].length === 1) {
            // For example 01:2 - wait for another digit
            this.timeInputColorName = 'danger';
            return;
        }
        if (timeStringNoColons.length === 3) {
            timeString = `${timeStringNoColons[0]}:${timeStringNoColons[1]}${timeStringNoColons[2]}`;
        } else if (timeStringNoColons.length === 4) {
            timeString = `${timeStringNoColons[0]}${timeStringNoColons[1]}:${timeStringNoColons[2]}${timeStringNoColons[3]}`;
        } else if (timeStringNoColons.length === 5) {
            timeString = `${timeStringNoColons[0]}:${timeStringNoColons[1]}${timeStringNoColons[2]}:${timeStringNoColons[3]}${timeStringNoColons[4]}`;
        } else if (timeStringNoColons.length === 6) {
            timeString = `${timeStringNoColons[0]}${timeStringNoColons[1]}:${timeStringNoColons[2]}${timeStringNoColons[3]}:${timeStringNoColons[4]}${timeStringNoColons[5]}`;
        }
        this.logger.debug('timeString', timeString);
        let format = 'mm:ss';
        if (timeString?.length === 7 || timeString?.length === 8) {
            format = 'hh:mm:ss';
        }
        const time = moment(timeString, format);
        if (!time.isValid || Number.isNaN(time.get('seconds'))) {
            this.logger.debug('onTimeInputChange got invalid time', time, format);
            this.timeInputColorName = 'danger';
            return;
        }
        const seconds = time.get('hours') * 3600 + time.get('minutes') * 60 + time.get('seconds');
        if (seconds > this.videoJSplayer.duration()) {
            this.logger.debug('onTimeInputChange time is greater than duration', seconds);
            this.timeInputColorName = 'warning';
            return;
        }
        this.logger.debug('onTimeInputChange got valid time', seconds, time);
        this.videoJSplayer.currentTime(seconds);
        this.timeInputColorName = null;
    }

    updateClipMarkers() {
        this.listener.removeAllMarkersFromProgressBar();
        if (this.assignedTasks) {
            const cropOffset = 0; //this.clip?.cropStart ? this.clip.cropStart : 0;
            const markers = this.assignedTasks.map((t) => {
                return { time: t.position - cropOffset, label: t.question, id: t._id };
            });
            this.listener.addMarkersToProgressBar(markers);
        }
        this.listener.setClipMarkers(
            this.clip.start,
            this.clip.end,
            this.clip.cropStart,
            this.clip.cropEnd
        );
    }

    async updateClip(newData: {
        task_ids?: string[];
        start?: number;
        end?: number;
        duration?: number;
        cropStart?: number;
        cropEnd?: number;
        title?: string;
        isFull?: boolean;
    }): Promise<ISingleObjectResponse<IClip>> {
        let response: ISingleObjectResponse<IClip>;
        if (!this.clip) {
            this.initClip();
        }
        if (this.clip._id) {
            // This clip is already saved in the database on the server
            response = await this.clipsApi.updateClip(this.clip._id, newData).toPromise();
        } else if (this.class_id) {
            // This clip is not yet saved in the database, but we have a class_id (group ID), so let's save it
            const resClip = await this.clipsApi.addClip(this.clip).toPromise();
            if (resClip.success) {
                this.clip = resClip.data;
                response = await this.clipsApi.updateClip(this.clip._id, newData).toPromise();
                const groupVideosResponse = await this.groupApi
                    .getVideos(this.class_id)
                    .toPromise();
                const groupVideo = groupVideosResponse?.data?.find(
                    (gv) => isNil(gv.clip_id) && (gv.video_id._id || gv.video_id) === this.video._id
                );
                if (groupVideo) {
                    const updateVideoResponse = await this.groupApi
                        .updateVideo(groupVideo._id, {
                            clip_id: this.clip._id,
                        })
                        .toPromise();
                    this.logger.debug('Updated group video with new clip_id:', updateVideoResponse);
                } else {
                    this.logger.debug(
                        `Found no group video for video ID ${this.video._id} and group ID ${this.class_id}`
                    );
                }
            }
        }
        return response;
    }

    async addClipToClass() {
        this.exitFullscreen();
        this.initClip();
        this.changeUiState(UiState.addingToClass);
        const resultHandler = (
            result: 'cancel' | 'new_class' | 'added' | 'already_in_class' | 'error',
            addResponse?: GroupVideoResponse,
            selectedGroup?: IGroup
        ) => {
            if (result === 'added' || result === 'already_in_class') {
                const alert2 = this.alertCtrl.create({
                    // cssClass: 'my-custom-class',
                    header: this.translate.instant('go_to_class_now'),
                    subHeader: this.translate.instant(
                        result === 'added' ? 'added_video_to_class' : 'video_already_in_class',
                        {
                            className: selectedGroup.name,
                        }
                    ),
                    buttons: [
                        {
                            text: this.translate.instant('btn_cancel'),
                            role: 'cancel',
                            handler: () => {
                                this.logger.debug('Confirm Cancel');
                                this.changeUiState(UiState.video);
                            },
                        },
                        {
                            text: this.translate.instant('btn_yes'),
                            handler: () => {
                                this.logger.debug('Confirm Yes');
                                this.appData.activePage = '';
                                this.appData.activeGroupId = addResponse.data.group_id;
                                this.navCtrl.navigateRoot(
                                    `educator-class/details/${addResponse.data.group_id}`
                                );
                            },
                        },
                    ],
                });

                alert2.then((alertEllement) => alertEllement.present().then());
            }
        };

        if (this.clip.isFull) {
            this.showAddClipToClassDialog(this.clip.video_id, null, resultHandler);
            return;
        }

        const name = this.getDefaultTitleForClip();
        const alert = await this.alertCtrl.create({
            // cssClass: 'my-custom-class',
            header: this.translate.instant('add_clip_to_group'),
            subHeader: 'Please enter a name for this clip:',
            inputs: [
                {
                    name: 'title',
                    type: 'textarea',
                    placeholder: this.translate.instant('rename_clip_subheader'),
                    value: name,
                },
            ],
            buttons: [
                {
                    text: this.translate.instant('btn_cancel'),
                    role: 'cancel',
                    handler: () => {
                        console.log('Confirm Cancel');
                        this.changeUiState(UiState.video);
                    },
                },
                {
                    text: this.translate.instant('button_continue'),
                    handler: (value) => {
                        console.log('Confirm Ok', value.title);
                        this.showAddClipToClassDialog(this.video._id, value.title, resultHandler);
                    },
                },
            ],
        });

        await alert.present();
    }


    async saveClip(title: string, selectedGroup: IGroup, videoId: string, resultHandler, selectedSubject?: string) {
        // original
        this.clip.title = title;
        this.clip.task_ids = this.assignedTasks?.map((t) => t._id);
        try {
            let newClip: IClip;
            if (
                !this.clip.isFull ||
                this.clip.task_ids && this.clip.task_ids.length > 0
            ) {
                const resClip = await this.clipsApi
                    .addClip(this.clip)
                    .toPromise();
                newClip = resClip.data;
            }
            const res = await this.groupApi
                .addVideo(selectedGroup._id, videoId, newClip?._id, null, selectedSubject)
                .toPromise();

            let message: string;
            if (res.success) {
                message = this.translate.instant('added_video_to_class', {
                    className: selectedGroup.name,
                });
                if (newClip) {
                    this.clip = newClip;
                }
                if (resultHandler) {
                    resultHandler('added', res, selectedGroup);
                }
            } else if (
                !res.code ||
                res.code === 'GROUPVIDEO_VIDEO_ALREADY_IN_GROUP' ||
                res.code === 'GROUPVIDEO_CLIP_ALREADY_IN_GROUP'
            ) {
                message = this.translate.instant('video_already_in_class', {
                    className: selectedGroup.name,
                });
                if (resultHandler) {
                    resultHandler('already_in_class', res, selectedGroup);
                }
            } else {
                message = res.msg || 'Unknown error';
                if (resultHandler) {
                    resultHandler('error', res, selectedGroup);
                }
            }
            this.uiUtils.displayToast(message);
        } catch (err) {
            this.logger.error('Error - saving clip', err);
            if (resultHandler) {
                resultHandler('error');
            }
            switch (err.status) {
                case 400:
                    this.uiUtils.showErrorAlert(
                        this.translate.instant('invalid_input')
                    );
                    break;
                case 401:
                    this.uiUtils.showErrorAlert(
                        this.translate.instant('not_same_class_video_language')
                    );
                    break;
                case 404:
                    this.uiUtils.showErrorAlert(
                        this.translate.instant('class_or_user_not_found')
                    );
                    break;
            }
        }
    }

    /**
     * Adds video to group selected by user in the alert
     *
     * @param videoId The video ID of the video to add
     */
    async showAddClipToClassDialog(
        videoId: string,
        title: string,
        resultHandler?: (
            result: 'cancel' | 'new_class' | 'added' | 'already_in_class' | 'error',
            addResponse?: GroupVideoResponse,
            selectedGroup?: IGroup
        ) => void
    ) {
        this.exitFullscreen();
        const alertInputs = [];

        // Add create new class option
        alertInputs.push({
            type: 'radio',
            label: this.translate.instant('new_class'),
            value: 'add-new-class',
            checked: true,
        });

        // Add classes to alertInputs
        for (const group of this.appData.groupsForUser) {
            alertInputs.push({
                type: 'radio',
                label: group.name,
                value: group,
            });
        }

        const alert = await this.alertCtrl.create({
            header: this.translate.instant('add_clip_to_group'),
            inputs: alertInputs,
            buttons: [
                {
                    text: this.translate.instant('btn_cancel'),
                    role: 'cancel',
                    handler: () => {
                        if (resultHandler) {
                            resultHandler('cancel');
                        }
                    },
                },
                {
                    text: this.translate.instant('btn_ok'),
                    handler: async (selectedGroup) => {
                        if (selectedGroup === 'add-new-class') {
                            if (resultHandler) {
                                resultHandler('new_class');
                            }
                            // Save video_id
                            this.appData.newClassVideoId = videoId;
                            this.navCtrl.navigateForward('educator-class/new');
                        } else {
                            this.clip.title = title;
                            this.clip.task_ids = this.assignedTasks?.map((t) => t._id);


                            const loader = await this.loadingCtrl.create({
                                message: 'Fetching your subjects'
                            });
                            await loader.present();
                            const subjectsObservable = this.groupApi.getSubjectsForEducator(selectedGroup._id);
                            const alertInputsSubjects = [];
                            subjectsObservable.subscribe(async response => {
                                await loader.dismiss();
                                const language = this.appData.getLanguage();
                                const subjectsInputs = response.subjects.regular_subjects;
                                subjectsInputs.sort((subject1, subject2) => {
                                    const name1 = subject1.name[language] || subject1.name['en'];
                                    const name2 = subject2.name[language] || subject2.name['en'];

                                    // Use localeCompare for case-insensitive alphabetical sorting
                                    return name1.localeCompare(name2, language, { sensitivity: 'base' });
                                });


                                const alertInputsSubjects: {}[] = subjectsInputs.map(subject => ({
                                    type: 'radio',
                                    label: subject.name[language] || subject.name['en'],
                                    value: subject._id,
                                }));

                                if (subjectsInputs.length === 1) {
                                    await this.saveClip(title, selectedGroup, videoId, resultHandler, subjectsInputs[0]?._id);
                                } else {
                                    const alertSubjects = await this.alertCtrl.create({
                                        header: this.translate.instant('tooltip_select_subject'),
                                        inputs: alertInputsSubjects,
                                        buttons: [
                                            {
                                                text: this.translate.instant('btn_cancel'),
                                                role: 'cancel',
                                                handler: () => {
                                                    if (resultHandler) {
                                                        resultHandler('cancel');
                                                    }
                                                },
                                            },
                                            {
                                                text: this.translate.instant('btn_ok'),
                                                handler: async (selectedSubject) => {
                                                    if (!selectedSubject) {
                                                        return false;
                                                    }
                                                    await this.saveClip(title, selectedGroup, videoId, resultHandler, selectedSubject);
                                                }
                                            }
                                        ]
                                    });
                                    await  alertSubjects.present();
                                }
                            });
                        }
                    },
                },
            ],
        });

        await alert.present();
    }

    async selectClipBorderAtCurrentPosition(isStart: boolean) {
        let currentTime: number = this.videoJSplayer.currentTime();
        if (!isNil(this.clip.cropStart)) {
            currentTime += this.clip.cropStart;
        }
        if (isStart) {
            if (currentTime > this.clip.end) {
                this.uiUtils.showErrorAlert(
                    'The start of the clip cannot be after the end of the clip.'
                );
                return;
            }
            this.clip.start = currentTime;
            this.logger.debug(`Selecting clip start at ${this.clip.start}`);
        } else {
            if (currentTime < this.clip.start) {
                this.uiUtils.showErrorAlert(
                    'The end of the clip cannot be before the start of the clip.'
                );
                return;
            }
            this.clip.end = currentTime;
            this.logger.debug(`Selecting clip start at ${this.clip.end}`);
        }
        this.clip.duration = this.clip.end - this.clip.start;
        if (this.clip) {
            this.clip.isFull =
                this.clip.start === 0 && this.clip.end === this.videoJSplayer.duration();

            await this.updateClip({
                start: this.clip.start,
                end: this.clip.end,
                duration: this.clip.duration,
                isFull: this.clip.isFull,
            });
        }
        this.changeUiState(UiState.clipHome);
        this.listener.setClipMarkers(
            this.clip.start,
            this.clip.end,
            this.clip.cropStart,
            this.clip.cropEnd
        );
    }

    async cropClip(): Promise<void> {
        const doCrop = async () => {
            this.clip.cropStart = this.clip.start;
            this.clip.cropEnd = this.clip.end;

            // Remove tasks that are outside of the cropped clip
            this.assignedTasks = this.assignedTasks.filter((t) => {
                if (!isNil(this.clip.cropStart) && t.position < this.clip.cropStart) {
                    return false;
                }
                if (!isNil(this.clip.cropEnd) && t.position > this.clip.cropEnd) {
                    return false;
                }
                return true;
            });

            await this.updateClip({
                cropStart: this.clip.cropStart,
                cropEnd: this.clip.cropEnd,
                task_ids: this.assignedTasks?.map((t) => t._id),
            });
            this.changeUiState(UiState.video);
            this.listener.updateVideoOffset(); // this.clip
            this.updateClipMarkers();
            this.uiUtils.displayToast('Clip was cropped');
        };

        this.exitFullscreen();
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('crop_confirmation_header'),
            subHeader: this.translate.instant('crop_confirmation_infotext'),
            buttons: [
                {
                    text: this.translate.instant('btn_cancel'),
                    role: 'cancel',
                },
                {
                    text: this.translate.instant('btn_yes'),
                    handler: doCrop,
                },
            ],
        });

        await alert.present();
    }

    isTaskInsideClipBounds(task: ITask): boolean {
        if (!this.clip) {
            return true;
        }
        return task.position >= this.clip.start && task.position <= this.clip.end;
    }

    get isClipCreatedByCurrentUser() {
        if (!this.clip._id) {
            return false;
        }
        return this.clip?.createdBy === this.appData.authenticatedUser?._id;
    }

    getDefaultTitleForClip() {
        const start = this.clip?.start || 0;
        const end = this.clip?.end || this.videoJSplayer.duration();
        const name = `${this.video.title} ${this.uiUtils.secondsToTimeString(
            start
        )} - ${this.uiUtils.secondsToTimeString(end)}`;
        return name;
    }

    async renameClip() {
        this.exitFullscreen();
        this.changeUiState(UiState.addingToClass);
        const name = this.clip.title || this.getDefaultTitleForClip();
        const alert = await this.alertCtrl.create({
            // cssClass: 'my-custom-class',
            header: this.translate.instant('rename_clip'),
            subHeader: this.translate.instant('rename_clip_subheader'),
            inputs: [
                {
                    name: 'title',
                    type: 'textarea',
                    placeholder: this.translate.instant('rename_clip_subheader'),
                    value: name,
                },
            ],
            buttons: [
                {
                    text: this.translate.instant('btn_cancel'),
                    role: 'cancel',
                    handler: () => {
                        console.log('Confirm Cancel');
                        this.changeUiState(UiState.video);
                    },
                },
                {
                    text: this.translate.instant('btn_save'),
                    handler: async (value) => {
                        console.log('Confirm Ok', value.title);
                        const newTitle = value.title;
                        const updateResponse = await this.updateClip({ title: newTitle });
                        if (updateResponse?.success) {
                            this.clip.title = newTitle;
                            this.uiUtils.displayToast(
                                this.translate.instant('rename_clip_success')
                            );
                        } else {
                            this.logger.warn('No success updating clip', updateResponse);
                        }
                    },
                },
            ],
        });

        await alert.present();
    }

    /**
     * Generates a string for all the answers of a task.
     * @param task the task
     * @param cssClassForCorrect a css class that will be set for correct answers (optional)
     * @returns the answers text, for example "(1/2) Antwort A / Antwort B"
     */
    taskAnswersToString(task: ITask, cssClassForCorrect?: string): string {
        return task.answers
            ?.map((t) => {
                if (cssClassForCorrect && t.isCorrect) {
                    return `<span class="${cssClassForCorrect}">${t.text}</span>`;
                } else {
                    return `${t.text}`;
                }
            })
            .join(' / ');
    }

    numberOfCorrectAnswers(task: ITask): number {
        return task.answers?.filter((t) => t.isCorrect).length;
    }

    playVideo(resetShownTasks = false) {
        if (resetShownTasks) {
            this.listener.resetShownTasks();
        }
        this.uiState = this.uiStateEnum.video;
        this.videoJSplayer.play();
    }
}
