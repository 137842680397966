/**
 * User activity used to measure engagement and use of the app.
 * See https://gitlab.com/uugotitTeam/uugotit-spyder3/wikis/User-Event-Tracking
 */
export interface IActivityEvent {
    _id?: string;
    user_id?: string; // student id from users._id
    installationID?: string; // device ID when student is not logged e.g. for anonymous student
    group_id?: string; // groups._id
    video_id?: string; // videos._id
    category: ActivityCategory; // app, video, word, ...
    action: ActivityAction; // video_started, video_ended, video_watched_full, word_clicked, ...
    label?: string;
    metadata?: any;
    generated_at: Date; // date/time when actionEvent is generated in frontend and stored in localStorage
    created_at?: Date; // date/time when spyder3 endpoint is called (every ten minutes) and activityEvent doc is inserted into mongo
}

/**
 * Available activity actions.
 */
export enum ActivityAction {
    app_started = 'app_started',
    video_started = 'video_started',
    video_ended = 'video_ended',
    word_added = 'word_added',
    word_learned = 'word_learned',
    tutorial_finished = 'tutorial_finished',
    streak_7_days = 'streak_7_days',
    group_joined = 'group_joined',
    group_left = 'group_left',
    group_removed_from = 'group_removed_from',
}

/**
 * Available activity categories.
 */
export enum ActivityCategory {
    app = 'app',
    video = 'video',
    word = 'word',
    tutorial = 'tutorial',
    streak = 'streak',
    group = 'group'
}
