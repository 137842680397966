<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-menu-toggle>
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
        <ion-title>
            {{ 'word_catalog' | translate }}
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>

    <ion-grid>
        <ion-row>
            <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="4" class="ion-no-padding">
                <ion-card [routerLink]="['/word-catalog/' + constants.GeneralClassId]">
                    <div class="group-card-background-overlay">
                        <div class="group-card-title">
                            {{ 'word_catalog_general_group' | translate }}
                        </div>
                    </div>
                </ion-card>

            </ion-col>
            <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="4" *ngFor="let group of groups"
                class="ion-no-padding">
                <ion-card [routerLink]="['/word-catalog/' + group._id]">
                    <div class="group-card-background-overlay">
                        <div class="group-card-title">
                            <span *ngIf="group.name">
                                {{group.name}}
                            </span>
                        </div>
                    </div>
                </ion-card>

            </ion-col>
        </ion-row>
    </ion-grid>

</ion-content>
