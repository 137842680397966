import { interval, Subscription } from 'rxjs';
import { IVideo } from '../../interfaces/IVideo';
import { NGXLogger } from 'ngx-logger';

export type PlayNextAction = 'goToNext' | 'goToNextTimeout' | 'cancel' | 'reviewWords' | 'replay';

export type PlayNextActionCallback = (result: {
    nextVideo: IVideo;
    action: PlayNextAction;
}) => void;

/**
 * An overlay that shows the next video that the user can watch and a countdown.
 */
export class VideoPlayNextOverlay {
    countdownCurrentSec = 0;
    countdownMaxSec = -1;
    private countdownSubscription: Subscription;
    public isShown = false;

    public nextVideo: IVideo;
    public showReviewWordsPrompt = false;
    private actionCallback: PlayNextActionCallback;

    // Timeout in seconds (if user clicked on words):
    private readonly countdownMaxSecWithReviewWords = 9;
    // Timeout in seconds (if user clicked didn't click on words):
    private readonly countdownMaxSecWithoutReviewWords = 6;

    constructor(private logger: NGXLogger, private isCountdownEnabled = true) {
        // Do nothing
    }

    public init(
        nextVideo: IVideo,
        actionCallback: PlayNextActionCallback,
        showReviewWordsPrompt = false,
        isShown = true
    ) {
        this.isShown = isShown;
        this.nextVideo = nextVideo;
        this.actionCallback = actionCallback;
        this.showReviewWordsPrompt = showReviewWordsPrompt;
        this.countdownMaxSec = this.showReviewWordsPrompt
            ? this.countdownMaxSecWithReviewWords
            : this.countdownMaxSecWithoutReviewWords;

        // If a subscription still exists, unsubscribe it
        this.unsubscribeInterval();

        this.logger.debug(`VideoPlayNextOverlay isCountdownEnabled=${this.isCountdownEnabled}`);
        if (this.isCountdownEnabled) {
            this.startCountdown();
        } else {
            this.countdownCurrentSec = -1;
        }
    }

    public hide(action: PlayNextAction) {
        this.isShown = false;
        this.unsubscribeInterval();
        if (this.actionCallback) {
            this.actionCallback({ nextVideo: this.nextVideo, action });
        }
    }

    private startCountdown() {
        const intervalFunction = (value: number) => {
            // this.logger.debug('countdown', value);
            this.countdownCurrentSec = value;
            if (this.countdownMaxSec - this.countdownCurrentSec <= 0) {
                this.hide('goToNextTimeout');
            }
        };
        intervalFunction(0);
        // Now create a new interval subscription
        this.countdownSubscription = interval(1000).subscribe(intervalFunction);
    }

    public stopCountdown() {
        this.unsubscribeInterval();
        this.countdownCurrentSec = -1;
    }

    public unsubscribeInterval() {
        this.countdownSubscription?.unsubscribe();
    }

    public isCountdownVisible(): boolean {
        return this.countdownCurrentSec >= 0;
    }
}
