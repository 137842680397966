import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

/**
 * Api is a generic REST Api handler. Set your API url first.
 */
@Injectable({
    providedIn: 'root'
})
export class Api {
    url: string = environment.serviceApiBaseUrl;

    constructor(public http: HttpClient) {
        // Do nothing.
    }

    get(endpoint: string, params?: any): Observable<any> {
        const p: HttpParams = new HttpParams();

        // Support easy query params for GET requests
        if (params) {
            for (const k of Object.keys(params)) {
                p.append(k, params[k]);
            }
        }

        return this.http.get(`${this.url}/${endpoint}`, { params, responseType: 'json' });
    }

    getAsArrayBuffer(endpoint: string, params?: any, headers?: { [header: string]: string | string[]; }): Observable<ArrayBuffer> {
        const p: HttpParams = new HttpParams();

        // Support easy query params for GET requests
        if (params) {
            for (const k of Object.keys(params)) {
                p.append(k, params[k]);
            }
        }

        return this.http.get(`${this.url}/${endpoint}`, { params, responseType: 'arraybuffer', headers });
    }

    post(endpoint: string, body: any, params?: any): Observable<any> {
        const p: HttpParams = new HttpParams();

        // Support easy query params
        if (params) {
            for (const k of Object.keys(params)) {
                p.append(k, params[k]);
            }
        }

        return this.http.post(`${this.url}/${endpoint}`, body, { params });
    }

    put(endpoint: string, body: any): Observable<any> {
        return this.http.put(`${this.url}/${endpoint}`, body);
    }

    delete(endpoint: string, params?: any, body?: any): Observable<any> {
        const p: HttpParams = new HttpParams();

        // Support easy query params
        if (params) {
            for (const k of Object.keys(params)) {
                p.append(k, params[k]);
            }
        }

        const options = body ? { params, body } : { params };

        return this.http.delete(`${this.url}/${endpoint}`, options);
    }

    patch(endpoint: string, body: any, params?: any): Observable<any> {
        const p: HttpParams = new HttpParams();

        // Support easy query params
        if (params) {
            for (const k of Object.keys(params)) {
                p.append(k, params[k]);
            }
        }

        return this.http.patch(`${this.url}/${endpoint}`, body, { params });
    }
}
