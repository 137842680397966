import { VideoPlayerPage } from './pages/video-player/video-player-page';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VideoCatalogPage } from './pages/video-catalog/video-catalog-page';
import { EducatorLoginPage } from './pages/educator-login/educator-login-page';
import { ClassCreatePage } from './pages/class-create/class-create-page';
import { ClassDetailsEducatorPage } from './pages/class-details-educator/class-details-educator-page';
import { WorksheetSelectPage } from './pages/worksheet-select/worksheet-select-page';
import { WorksheetUploadPage } from './pages/worksheet-upload/worksheet-upload-page';
import { SettingsPage } from './pages/settings/settings-page';
import { WordCatalogPage } from './pages/word-catalog/word-catalog-page';
import { WordCatalogCardsPage } from './pages/word-catalog-cards/word-catalog-cards-page';
import { FlashCardsPage } from './pages/flash-cards/flash-cards-page';
import { StudentLoginPage } from './pages/student-login/student-login-page';
import { ClassDetailsStudentPage } from './pages/class-details-student/class-details-student-page';
import { AboutPage } from './pages/about/about-page';
import { TranslationCorrectionPage } from './pages/translation-correction/translation-correction-page';
import { ClassSelectUserPage } from './pages/class-select-user/class-select-user-page';
import { RouteGuard } from './utils/route.guard';
import { VideoPlayerResolver } from './pages/video-player/video-player-resolver.service';
import { EducatorGroupResolver } from './pages/class-details-educator/class-details-educator-resolver.service';
import { StudentGroupResolver } from './pages/class-details-student/class-details-student-resolver.service';
import { WordCatalogResolver } from './pages/word-catalog/word-catalog-resolver.service';
import { FlashCardsResolver } from './pages/flash-cards/flash-cards-resolver.service';
import { VideoCatalogTagPage } from './pages/video-catalog/video-catalog-tag-page';
import { VideoPlayerEmbedPage } from './pages/video-player-embed/video-player-embed-page';
import { SubjectsCatalogPage } from './pages/subjects-catalog/subjects-catalog-page';
import { HelpPage } from './pages/help/help-page';
import { WordCatalogGroupsPage } from './pages/word-catalog-groups/word-catalog-groups-page';
import { FlashCardsGroupsPage } from './pages/flash-cards-groups/flash-cards-groups-page';
import { ErrorPage } from './pages/error/error-page';
import { VideoCatalogMyVideosPage } from './pages/video-catalog/video-catalog-my-videos-page';
import { ClipTasksStatisticsPage } from './pages/clip-tasks-statistics/clip-tasks-statistics.page';
import { ClipTasksStatisticsResolver } from './pages/clip-tasks-statistics/clip-tasks-statistics-resolver.service';
import { VideoPlayerAddQuestionPage } from './pages/video-player-educator/video-player-add-question/video-player-add-question.page';
import { VideoPlayerEditQuestionPage } from './pages/video-player-educator/video-player-edit-question/video-player-edit-question.page';
import { ClassLeaderboardPage } from './pages/class-leaderboard/class-leaderboard.page';
import { ClassLeaderboardResolver } from './pages/class-leaderboard/class-leaderboard-resolver.service';
import { OnboardingPage } from './pages/onboarding/onboarding-page';
import { EducatorLoginV2Page } from './pages/educator-login-v2/educator-login-v2-page';
import { ConfirmUserPage } from './pages/confirm-user/confirm-user-page';
import { ForgotPasswordPage } from './pages/forgot-password/forgot-password-page';
import { OnboardingUnverifiedPage } from './pages/onboarding-unverified/onboarding-unverified-page';
import { CreateClassGuard } from './utils/createClass.guard';
import {
    ClassDetailsListVideosStudentPage
} from './pages/class-details-list-videos-student/class-details-list-videos-student-page';
import {
    StudentListVideoGroupResolver
} from './pages/class-details-list-videos-student/class-details-list-videos-resolver.service';
import { OnboardingStudentPage } from './pages/onboarding-student/onboarding-student-page';
import { ReachAll } from './pages/reach-all/reach-all';

const routes: Routes = [
    {
        path: 'catalog',
        component: VideoCatalogPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
        },
    },
    {
        path: 'catalog/:websource_id',
        component: VideoCatalogPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
        },
    },
    {
        path: 'verify',
        component: ConfirmUserPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public'],
        },
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public'],
        },
    },
    {
        path: 'catalog/t/:tag',
        component: VideoCatalogTagPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
        },
    },
    {
        path: 'catalog/s/:subject',
        component: VideoCatalogTagPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
        },
    },
    {
        path: 'catalog/s/:subject/f/:filter',
        component: VideoCatalogTagPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
        },
    },
    {
        path: 'catalog/sp/:special',
        component: VideoCatalogTagPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['educator'],
        },
    },
    {
        path: 'category/:category',
        component: VideoCatalogTagPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
        },
    },
    {
        path: 'my-videos',
        component: VideoCatalogMyVideosPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
        },
    },
    {
        path: 'settings',
        component: SettingsPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
        },
    },
    {
        path: 'onboarding',
        component: OnboardingPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['educator'],
        },
    },
    {
        path: 'onboarding-student',
        component: OnboardingStudentPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['student'],
        },
    },
    {
        path: 'onboarding-unverified',
        component: OnboardingUnverifiedPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['educator'],
        },
    },
    {
        path: 'login',
        component: EducatorLoginV2Page,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
        },
    },
    {
        path: 'login_educator',
        component: EducatorLoginV2Page,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
        },
    },
    {
        path: 'account',
        component: EducatorLoginV2Page,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
        },
    },
    {
        path: 'login/:code',
        component: EducatorLoginPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
        },
    },
    {
        path: 'student',
        component: StudentLoginPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'student'],
        },
    },
    {
        path: 'educator-class/new',
        component: ClassCreatePage,
        canActivate: [RouteGuard, CreateClassGuard],
        data: {
            expectedRoles: ['educator'],
        },
    },
    {
        path: 'educator-class/details/:class_id',
        component: ClassDetailsEducatorPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['educator'],
        },
        resolve: {
            resData: EducatorGroupResolver,
        },
    },
    {
        path: 'educator-class/details/:class_id/videos/available',
        component: VideoCatalogMyVideosPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],

        },
    },
    {
        path: 'student-class/details/:class_id',
        component: ClassDetailsStudentPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['student'],
        },
        resolve: {
            resData: StudentGroupResolver,
        },
    },
    {
        path: 'student-class/details/:class_id/:subject_id',
        component: ClassDetailsListVideosStudentPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['student'],
        },
        resolve: {
            resData: StudentListVideoGroupResolver,
        },
    },
    {
        path: 'student-class/select-user',
        component: ClassSelectUserPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public'],
        },
    },
    {
        path: 'video/:websource_id/:video_id',
        component: VideoPlayerPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
        },
        resolve: {
            resData: VideoPlayerResolver,
        },
    },
    {
        path: 'video/:websource_id/:video_id/:class_id',
        component: VideoPlayerPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['educator', 'student'],
        },
        resolve: {
            resData: VideoPlayerResolver,
        },
    },
    {
        path: 'video/:websource_id/:video_id/:class_id/clip/:clip_id',
        component: VideoPlayerPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['educator', 'student'],
        },
        resolve: {
            resData: VideoPlayerResolver,
        },
    },
    {
        path: 'embed/:video_id',
        component: VideoPlayerEmbedPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
        },
        resolve: {
            resData: VideoPlayerResolver,
        },
    },
    {
        path: 'reachall/:video_id',
        component: ReachAll,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student', 'tvuser'],
        }
    },
    {
        path: 'embed/:video_id/:class_id/clip/:clip_id',
        component: VideoPlayerEmbedPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
            isEmbed: true
        },
        resolve: {
            resData: VideoPlayerResolver,
        },
    },
    {
        path: 'reachall/:video_id/:class_id/clip/:clip_id',
        component: ReachAll,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
            isEmbed: true
        }
    },
    {
        path: 'worksheet-select',
        component: WorksheetSelectPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['educator'],
        },
    },
    {
        path: 'worksheet-upload',
        component: WorksheetUploadPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['educator'],
        },
    },
    {
        path: 'word-catalog',
        component: WordCatalogPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
        },
        resolve: {
            resData: WordCatalogResolver,
        },
    },
    {
        path: 'word-catalog-groups',
        component: WordCatalogGroupsPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['educator', 'student'],
        },
    },
    {
        path: 'word-catalog/:class_id',
        component: WordCatalogPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['educator', 'student'],
        },
        resolve: {
            resData: WordCatalogResolver,
        },
    },
    {
        path: 'word-catalog-cards',
        component: WordCatalogCardsPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
        },
        resolve: {
            resData: WordCatalogResolver,
        },
    },
    {
        path: 'word-catalog-cards/:class_id',
        component: WordCatalogCardsPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['educator', 'student'],
        },
        resolve: {
            resData: WordCatalogResolver,
        },
    },
    {
        path: 'flash-cards',
        component: FlashCardsPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
        },
        resolve: {
            resData: FlashCardsResolver,
        },
    },
    {
        path: 'flash-cards-groups',
        component: FlashCardsGroupsPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['educator', 'student'],
        },
    },
    {
        path: 'flash-cards/:class_id',
        component: FlashCardsPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['educator', 'student'],
        },
        resolve: {
            resData: FlashCardsResolver,
        },
    },
    {
        path: 'about',
        component: AboutPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
        },
    },
    {
        path: 'translation-correction',
        component: TranslationCorrectionPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['educator'],
        },
    },
    {
        path: 'subjects',
        component: SubjectsCatalogPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['public', 'educator', 'student'],
        },
    },
    {
        path: 'help',
        component: HelpPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['educator'],
        },
    },
    {
        path: '',
        redirectTo: 'catalog',
        pathMatch: 'full',
    },
    {
        path: 'error',
        component: ErrorPage,
    },
    // That leads to an error "The pipe 'translate' could not be found!" in the pages' templates, not using the submodule solution
    // {
    //     path: 'video-player-edit-question',
    //     loadChildren: () => import('./pages/video-player-educator/video-player-edit-question/video-player-edit-question.module').then(m => m.VideoPlayerEditQuestionPageModule),
    // },
    // {
    //     path: 'video-player-add-question',
    //     loadChildren: () => import('./pages/video-player-educator/video-player-add-question/video-player-add-question.module').then(m => m.VideoPlayerAddQuestionPageModule)
    // },
    {
        path: 'video-player-edit-question',
        component: VideoPlayerAddQuestionPage,
        data: {
            expectedRoles: ['educator'],
        },
    },
    {
        path: 'video-player-add-question',
        component: VideoPlayerEditQuestionPage,
        data: {
            expectedRoles: ['educator'],
        },
    },
    {
        path: 'educator-class/:class_id/clip/:clip_id/tasks-statistics',
        component: ClipTasksStatisticsPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['educator'],
        },
        resolve: {
            resData: ClipTasksStatisticsResolver,
        },
    },
    {
        path: 'class/:class_id/leaderboard',
        component: ClassLeaderboardPage,
        canActivate: [RouteGuard],
        data: {
            expectedRoles: ['educator', 'student'],
        },
        resolve: {
            resData: ClassLeaderboardResolver,
        },
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
