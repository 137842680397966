<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-menu-toggle *ngIf="isRootPage">
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
            <ion-button *ngIf="!isRootPage" (click)="navigateBack()">
                <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-buttons slot="secondary" *ngIf='appData.isEducator() && !showSearch'>
            <ion-button (click)='activateSearch()'>
                <ion-icon slot="icon-only" name="search"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-searchbar #search
                       *ngIf="!plt.is('mobile') && showSearch"
                       [(ngModel)]="searchText"
                       showCancelButton="always"
                       (ionBlur)='clickOutsideSearchBar()'
                       (ionCancel)='closeSearchBar()'
                       (ionChange)='onSearchChange()'
                       debounce="600"
                       placeholder="{{ 'search_in_video_library' | translate }}"></ion-searchbar>
        <ion-title *ngIf="(!plt.is('mobile') && !showSearch)">
            {{ headerTitle }}
        </ion-title>
        <ion-title *ngIf="plt.is('mobile')">
            {{ headerTitle }}
        </ion-title>

    </ion-toolbar>
    <ion-searchbar #search
                   inputmode='search'
                   *ngIf="plt.is('mobile') && showSearch"
                   [(ngModel)]="searchText"
                   showCancelButton="always"
                   (ionBlur)='clickOutsideSearchBar()'
                   (ionCancel)='closeSearchBar()'
                   (ionChange)='onSearchChange()'
                   debounce="600"
                   placeholder="{{ 'search_in_video_library' | translate }}">
    </ion-searchbar>
    <ion-toolbar *ngIf='!showLoadingIndicator && searchText'>
        <ion-title>{{ 'search_result' | translate }}: {{searchText}}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>

    <div slot="fixed" class="progressBarFixedTop">
        <ion-progress-bar type="indeterminate" *ngIf="showLoadingIndicator"></ion-progress-bar>
    </div>

    <ion-refresher slot="fixed" #refresher (ionRefresh)="doRefresh($event)" (ionPull)="onRefresherPull($event)">
        <ion-refresher-content pullingText="{{ 'pull_to_refresh' | translate }}"
            refreshingText="{{ 'reloading_videos' | translate }}"></ion-refresher-content>
    </ion-refresher>

    <p class="ion-padding ion-text-center" *ngIf="getAppDataVideos() && getAppDataVideos().length === 0 && (tag || categorySlug)">
        <ng-container *ngIf="tag">
            {{ "video_catalog_tag_empty_info" | translate:{tag: tag} }}
        </ng-container>
        <ng-container *ngIf="categorySlug">
            {{ "video_catalog_category_empty_info" | translate }}
        </ng-container>
    </p>

    <ion-grid fixed="true">
        <!-- <ion-searchbar *ngIf="appData.isEducator()" padding-left padding-right placeholder="{{ 'search' | translate }}"></ion-searchbar> -->
        <ion-row>
            <!-- START: hot topic card -->
            <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="4" *ngIf="isShowHotTopic">
                <ion-card [routerLink]="['/catalog/t/COVID-19']" class="isHighlighted">
                    <div class="card-image-container">
                        <img src="https://uugotitlive.blob.core.windows.net/hot-topics/hot_topic_COVID-19.jpg" alt="Alle Meldungen zum Corona Virus"/>
                    </div>
                    <ion-item lines="none">
                        <ion-label class="ion-text-wrap">
                            <ion-card-title>COVID-19: Tagesaktuelle Informationen</ion-card-title>
                            <ion-label>
                                <span class="video-category-chip video-category-chip-inverse"><ion-icon name="flame"></ion-icon> {{'hot_topic' | translate}}</span>
                                <span class="text-content"></span>
                            </ion-label>
                        </ion-label>

                    </ion-item>
                </ion-card>
            </ion-col>
            <!-- END: hot topic card -->

            <!-- START: videos with tasks -->
            <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="4" *ngIf="subjectSlug && !withTasks">
                <ion-card [routerLink]="['/catalog/s', subjectSlug, 'f', 'with-tasks']" class="isHighlighted">
                    <div class="card-image-container">
                        <img *ngIf="subject && !subject.isGeneric" [src]="'/assets/img/school-subjects/subjectcover_' + subjectSlug + '.jpg'" />
                        <div class="video-title video-title-not-yet-watched video-title-large">
                            <span>
                                {{'tasked_videos' | translate}}
                            </span>
                        </div>
                    </div>
                    <ion-item lines="none">
                        <ion-label class="ion-text-wrap">
                            <ion-label>{{'tasked_description' | translate}}</ion-label>
                            <!-- <ion-card-title>Tasked Videos</ion-card-title> -->
                            <!-- <ion-label>
                                <span class="video-category-chip video-category-chip-inverse"><ion-icon name="list-circle"></ion-icon> {{'tasks' | translate}}</span>
                                <span class="text-content"></span>
                            </ion-label> -->
                        </ion-label>

                    </ion-item>
                </ion-card>
            </ion-col>
            <!-- END: videos with tasks -->
                <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="4" *ngFor="let video of getAppDataVideos()">
                    <app-video-catalog-card [showPublishDate]="showPublishDate" [video]="video" [watchedVideos]="watchedVideos" [tag]="tag" [subjectSlug]="subjectSlug">
                    </app-video-catalog-card>
                </ion-col>
        </ion-row>
    </ion-grid>

    <ion-infinite-scroll (ionInfinite)="doInfinite($event)">
        <ion-infinite-scroll-content loadingSpinner="circles" loadingText="{{ 'loading_more_broadcasts' | translate }}">
        </ion-infinite-scroll-content>
    </ion-infinite-scroll>
</ion-content>
