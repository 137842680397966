<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-menu-toggle>
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
        <ion-title>{{'error' | translate }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding ion-text-center">
    <ion-grid fixed>
        <img class="yuyu" src="assets/img/yuyu_yellow_exclamation_sign.png" alt="disappointed yuyu (uugot.it logo)" />
        <p>{{ 'error_page_message' | translate }} </p> <!-- class="alert alert-danger"> -->
        <p *ngIf="errorMessage">
            <small>{{errorMessage}}</small>
        </p>
        <p *ngIf="!plt.is('cordova') && !plt.is('capacitor')">
            <ion-button color="light" (click)="reloadPage()">{{ 'error_page_button_reload' | translate }}</ion-button>
        </p>
    </ion-grid>
</ion-content>
