import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from './api.service';
import { IWatchedVideo } from 'src/app/interfaces/IWatchedVideo';
import { ISingleObjectResponse, IListResponse } from '../../models/dtos';
import { IWatchedVideoByCustomer } from '../../interfaces/IWatchedVideoByCustomer';

/**
 * API for /watched-video/
 * See https://spyder3-dev.uugot.it/api-docs/#/watched-video
 */
@Injectable({
    providedIn: 'root',
})
export class WatchedVideoApi {
    constructor(public api: Api) {
        // Do nothing.
    }

    /**
     * Save a watched video in the “watched_videos” collection or update existing watched video. This endpoint can be called periodically when a user is watching video.
     * (student, educator)
     *
     * @param video_id the video ID
     * @param watchedUntil the time progress in seconds
     * @param dur duration of the video in seconds
     * @param fin true if the user finished watching the video
     * @param clip_id the clip ID
     */
    update(
        video_id: string,
        watchedUntil: number,
        dur?: number,
        fin?: boolean,
        clip_id?: string
    ): Observable<ISingleObjectResponse<IWatchedVideo>> {
        const params: any = { video_id, watchedUntil, dur, fin };
        if (clip_id) {
            params.clip_id = clip_id;
        }
        // console.log('Watched videos update params', params);
        return this.api.put('watched-videos', params);
    }

    /**
     * Get watched videos for a specific user.
     * (student, educator)
     *
     * @param offset Limit number of listed watched videos. Use it for pagination.
     * @param limit Skip certain number of watched videos. Use it for pagination.
     * @param sort Sort watched videos by field. Add - for descending sort. Default value : -expiryDate
     */
    list(limit?: number, offset?: number, sort?: string): Observable<IListResponse<IWatchedVideo>> {
        const params: any = {};
        if (limit) {
            params.limit = limit;
        }
        if (offset) {
            params.offset = offset;
        }
        if (sort) {
            params.sort = sort;
        }
        return this.api.get('watched-videos/list', params);
    }

    /**
     * Get watched videos for a specific customer.
     * (educator)
     *
     * @param offset Limit number of listed watched videos. Use it for pagination.
     * @param limit Skip certain number of watched videos. Use it for pagination.
     * @param sort Sort watched videos by field. Add - for descending sort. Default value : -expiryDate
     */
    listByCustomer(limit?: number, offset?: number, sort?: string): Observable<IWatchedVideoByCustomer[]> {
        const params: any = {};
        if (limit) {
            params.limit = limit;
        }
        if (offset) {
            params.offset = offset;
        }
        if (sort) {
            params.sort = sort;
        }
        return this.api.get('watched-videos/listbyfreecustomer', params);
    }

    /**
     * Get watched videos for a specific user by array of defined video ids.
     * (student, educator)
     *
     * @param videoIds the IDs of the videos for which you want the watched videos
     * @param limit Skip certain number of watched videos. Use it for pagination.
     * @param sort Sort watched videos by field. Add - for descending sort. Default value : -expiryDate
     */
    listByVideos(videoIds: string[]): Observable<IListResponse<IWatchedVideo>> {
        return this.api.post('watched-videos/listbyvideos', videoIds);
    }

}
