<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'dark' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-button (click)="dismiss()">
                <ion-icon slot="icon-only" name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title *ngIf="!task">{{'create_new_task' | translate}} ({{uiUtils.secondsToTimeString(position, true)}})
        </ion-title>
        <ion-title *ngIf="task">{{'edit_task' | translate}} ({{uiUtils.secondsToTimeString(position, true)}})
        </ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="onSaveClicked()" [disabled]="!isSaveEnabled">
                <ion-icon slot="start" name="save"></ion-icon> {{'btn_save' | translate}}
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <form [formGroup]="form" (ngSubmit)="onSubmitForm()">
        <ion-item>
            <ion-label>{{'time_in_clip' | translate}} *</ion-label>
            <ion-input formControlName="time" placeholder="hh:mm:ss" maxlength="8" style="text-align: right;">
            </ion-input>
        </ion-item>
        <ion-item>
            <ion-label>{{'task_type' | translate}} *</ion-label>
            <ion-select formControlName="type" placeholder="{{'task_type' | translate}}">
                <ion-select-option [value]="1">{{'task_type_1' | translate}}</ion-select-option>
                <!-- <ion-select-option [value]="2">{{'task_type_2_long' | translate}}</ion-select-option> -->
            </ion-select>
        </ion-item>
        <ion-item>
            <ion-label position="stacked">{{'question' | translate}} *</ion-label>
            <ion-textarea placeholder="{{'task_question_placeholder' | translate}}" autocapitalize="sentences"
                formControlName="question">
            </ion-textarea>
        </ion-item>
        <!-- Show current characters and limit -->
        <ion-label class="remaining-chars ion-margin-start ion-margin-end"
            [class.remaining-chars-error]="form.controls['question'].value.length > maxLengthQuestionField"
            [color]="form.controls['question'].value.length > maxLengthQuestionField ? 'danger' : ''"
            *ngIf="form.controls['question'].value.length >= 20">{{
            form.controls['question'].value.length }} / {{maxLengthQuestionField}}</ion-label>
        <ion-list-header>
            {{'answer_options' | translate}} *
        </ion-list-header>
        <ng-container *ngFor="let control of answerOptions.controls; index as i">
            <ion-item>
                <ion-checkbox slot="start" [formControl]="control.controls.isCorrect"
                    *ngIf="form.value.type === 1"></ion-checkbox>
                <!-- <ion-icon slot="end" name="trash"></ion-icon> -->
                <!-- <ion-label position="stacked">Answer 1</ion-label> -->
                <ion-input placeholder="{{'task_answer_option_placeholder' | translate}}" clearInput inputmode="text"
                    maxlength="60" autocapitalize="sentences" autocomplete="off"
                    [formControl]="control.controls.text">
                </ion-input>
                <ion-button slot="end" size="small" fill="clear" (click)="removeAnswerOptionField(i)" *ngIf="i > 1">
                    <ion-icon slot="icon-only" name="trash"></ion-icon>
                </ion-button>
            </ion-item>
            <!-- Show current characters and limit -->
            <ion-label class="remaining-chars ion-margin-start ion-margin-end"
                [class.remaining-chars-error]="control.controls.text.value.length > maxLengthAnswerField"
                [color]="control.controls.text.value.length > maxLengthAnswerField ? 'danger' : ''"
                *ngIf="control.controls.text.value.length >= 20">{{control.controls.text.value.length}} /
                {{maxLengthAnswerField}}</ion-label>
        </ng-container>
        <div class="ion-padding" *ngIf="answerOptions.controls.length < 6">
            <ion-button (click)="addAnswerOptionField()" size="small">
                <ion-icon slot="start" name="add"></ion-icon>
                {{'add_option' | translate}}
            </ion-button>
        </div>
        <ion-item>
            <ion-label>{{'language_level' | translate}}</ion-label>
            <ion-select formControlName="level" multiple="true" placeholder="{{'language_level' | translate}}">
                <ion-select-option value="A">A</ion-select-option>
                <ion-select-option value="B">B</ion-select-option>
                <ion-select-option value="C">C</ion-select-option>
                <ion-select-option value="X">{{'language_level_independent' | translate}}</ion-select-option>
            </ion-select>
        </ion-item>
        <ion-item>
            <ion-label>{{'age_group' | translate}}</ion-label>
            <ion-select formControlName="age" multiple="true" placeholder="{{'age_group' | translate}}">
                <ion-select-option [value]="taskAgeRangeEnum.range_up_to_10">
                    {{'age_group_1' | translate}}
                </ion-select-option>
                <ion-select-option [value]="taskAgeRangeEnum.range_10_to_15">
                    {{'age_group_2' | translate}}
                </ion-select-option>
                <ion-select-option [value]="taskAgeRangeEnum.range_15_and_higher">
                    {{'age_group_3' | translate}}
                </ion-select-option>
            </ion-select>
        </ion-item>
        <ion-item>
            <ion-label>{{'educational_level' | translate}}</ion-label>
            <ion-select formControlName="eduLevel" multiple="true" placeholder="{{'educational_level' | translate}}">
                <ion-select-option [value]="taskEduLevelEnum.primary">
                    {{'educational_level_1' | translate}}
                </ion-select-option>
                <ion-select-option [value]="taskEduLevelEnum.secondary_1">
                    {{'educational_level_2' | translate}}
                </ion-select-option>
                <ion-select-option [value]="taskEduLevelEnum.secondary_2">
                    {{'educational_level_3' | translate}}
                </ion-select-option>
                <ion-select-option [value]="taskEduLevelEnum.higher">
                    {{'educational_level_4' | translate}}
                </ion-select-option>
            </ion-select>
        </ion-item>
    </form>
</ion-content>
