import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { AppComponent } from 'src/app/app.component';
import { IGroup } from 'src/app/interfaces/IGroup';
import { ITasksStatisticsResponse } from 'src/app/services/api/tasks.service';
import { UiUtils } from 'src/app/services/ui-utils.service';

@Component({
    selector: 'app-clip-tasks-statistics',
    templateUrl: './clip-tasks-statistics.page.html',
    styleUrls: ['./clip-tasks-statistics.page.scss'],
})
export class ClipTasksStatisticsPage implements OnInit {
    public group: IGroup;
    public statistics: ITasksStatisticsResponse;

    constructor(
        private _route: ActivatedRoute,
        private appComponent: AppComponent,
        private navCtrl: NavController,
        public uiUtils: UiUtils,
        public translate: TranslateService,
        private logger: NGXLogger
    ) {
        // Doing nothing.
    }

    ngOnInit() {
        // Doing nothing.
    }

    ionViewWillEnter() {
        const class_id = this._route.snapshot.params.class_id;

        this._route.snapshot.data.resData.subscribe((data) => {
            this.statistics = data.statistics;
            this.group = data.group;
        });
    }

    /**
     * Navigates back to the class details page
     */
    navigateBack() {
        const canGoBack = this.appComponent.routerOutlet.canGoBack();

        if (canGoBack) {
            // this.appComponent.routerOutlet.pop();
            this.navCtrl.pop();
        } else if (this.group) {
            this.navCtrl.navigateBack(`educator-class/details/${this.group._id}`);
        } else {
            this.navCtrl.navigateRoot('/catalog/all');
        }
    }
}
