<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-menu-toggle>
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
        <ion-title>{{ "about" | translate }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding ion-text-center">
    <ion-grid fixed>
        <div class="about-page-content">
            <a href="http://uugot.it" target="_blank"><img src="assets/img/logo_uugotit_app_SUBUU_840x293.png" class="about-logo-image"
                alt="uugot.it - enable users to understand TV everywhere and improve their foreign language skills in a noninvasive way" />
            </a>

            <div class="ion-margin-top">
                <small>{{'recommended_by' | translate}}:</small>
            </div>
            <div class="ion-margin-top">
                <a href="https://www.oedaf.at" target="_blank" rel="nofollow">
                    <img class="about-partner-logo" src="assets/img/about/oedaf_logo_gray.png"
                    alt="ÖDaF - Österreichischer Fachverband für Deutsch als Fremdsprache/Zweitsprache"
                    title="ÖDaF - Österreichischer Fachverband für Deutsch als Fremdsprache/Zweitsprache" />
                </a>
                <a href="https://www.bmbwf.gv.at" target="_blank" rel="nofollow">
                    <img class="about-partner-logo" src="assets/img/about/BMBWF_Logo_srgb_440x147_gray.png"
                    alt="Bundesministerium für Bildung, Wissenschaft und Forschung"
                    title="Bundesministerium für Bildung, Wissenschaft und Forschung" />
                </a>
            </div>

            <h2>{{ "about_uugotit" | translate }}</h2>
        </div>

        <p>
            {{ "about_infotext" | translate }}
        </p>
        <h3>{{ "contact" | translate }}</h3>
        <p>
            E-mail: <a href="mailto:ask_uu@uugot.it">ask_uu@uugot.it</a><br><br>
            Facebook: <a href="https://www.facebook.com/uugot.it" target="_blank">facebook.com/uugot.it</a><br><br>
            Twitter: <a href="https://twitter.com/uugot_it" target="_blank">twitter.com/uugot_it</a><br><br>
            uugot.it GmbH<br>
            Putzendoplergasse 16/32/10<br>
            A-1230 Wien<br>
        </p>

        <!--<h3 style="margin-top: 32px;">{{ "partners_header" | translate }}</h3>
        <p>

        </p>-->

        <p style="margin-top: 32px;">
            <ion-button color="light" (click)="showTermsOfUse()">
                {{'btn_terms_of_use' | translate}}
            </ion-button>
        </p>
        <p style="margin-top: 32px;">
            <ion-button color="light" (click)="showTutorial()">
                {{'btn_tutorial' | translate}}
            </ion-button>
        </p>

        <p style="margin-top: 32px;">
            <ion-button color="light" (click)="showLicenses()">
                {{'btn_licenses' | translate}}
            </ion-button>
        </p>

        <p class="about-small-info" *ngIf="versionNumber || geoIpCountry">
            <ng-container *ngIf="versionNumber">
                {{'app_version' | translate:{number: versionNumber, code: versionCode} }}
            </ng-container>
            <ng-container *ngIf="packageName != 'it.uugot' && packageName != 'it.uugot.app'">
                {{packageName}}
            </ng-container>
            <ng-container *ngIf="geoIpCountry">
                {{geoIpCountry}}
            </ng-container>
        </p>
        <p class="about-small-info" *ngIf="!isProdution">
            {{environmentName}} {{plt.platforms()}}
        </p>
    </ion-grid>
</ion-content>
