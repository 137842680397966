// angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

// libraries
import * as sha256 from 'fast-sha256';

declare const TextEncoder: any;
declare const TextDecoder: any;
import { Md5 } from 'ts-md5/dist/md5';

@Injectable({
    providedIn: 'root'
})
export class CryptoUtils {

    constructor(public http: HttpClient) {
        // console.log('Hello CryptoUtils Provider');
    }

    encodeEmailAdressForAnalytics(email: string): string {
        // https://www.npmjs.com/package/text-encoding-utf-8
        // https://www.npmjs.com/package/fast-sha256

        const uint8arrayEmail = new TextEncoder('UTF-8').encode(email);
        // console.dir(uint8arrayEmail);
        const uint8arraySalt = new TextEncoder('UTF-8').encode('uugot.salt.2017');
        const userIdKey = sha256.pbkdf2(uint8arrayEmail, uint8arraySalt, 5, 30); // returns derived key
        // console.dir(userIdKey);
        let userIdString = new TextDecoder('UTF-8').decode(userIdKey);
        // console.log('Encoded userId: ' + userIdString);

        userIdString = Md5.hashStr(userIdString);
        // console.log('Encoded and md5-hashed userId: ' + userIdString); // 842a96c75c32663e984cd7f2f491c637
        return userIdString;
    }

    /**
     * Generates a string made up of random lower case characters and numbers.
     * @param length for example 4 to get 'c6wm'
     */
    public generateId(length: number): string {
        let text = '';
        const possible = 'abcdefghijkmnopqrstuvwxyz0123456789';

        for (let i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }
}
