// angular
import { Component, OnInit } from '@angular/core';

// ionic
import {
    NavController,
    Platform,
} from '@ionic/angular';

// services
import { Constants } from '../../app.constants';
import { AppData } from 'src/app/services/app-data.service';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { AppManager } from 'src/app/services/app-manager.service';

// models
import { IGroup } from '../../interfaces/IGroup';

// libraries
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { AbstractRootMenuPage } from 'src/app/utils/abstract-root-menu-page';

@Component({
    selector: 'flash-cards-groups-page',
    templateUrl: 'flash-cards-groups-page.html',
    styleUrls: ['flash-cards-groups-page.scss'],
})
export class FlashCardsGroupsPage extends AbstractRootMenuPage implements OnInit {

    constructor(
        public navCtrl: NavController,
        public appData: AppData,
        public constants: Constants,
        public uiUtils: UiUtils,
        public analytics: AnalyticsService,
        public translate: TranslateService,
        public platform: Platform,
        protected appManager: AppManager,
        protected logger: NGXLogger
    ) {
        super(platform);
    }

    ionViewWillLoad() {
        this.logger.debug('ionViewWillLoad');
    }

    ngOnInit() {
        this.logger.debug('ngOnInit');
        this.analytics.trackPageView('flash-cards-group');
    }

    ionViewWillEnter() {
        this.logger.debug('ionViewWillEnter');
        this.appData.activePage = 'flash-cards-groups';
    }

    // Workaround for https://gitlab.com/uugotitTeam/webapp/issues/172
    ionViewDidEnter() {
        super.ionViewWillEnter();
    }

    ionViewWillLeave() {
        super.ionViewWillLeave();
    }

    get groups(): IGroup[] {
        return this.appData.groupsForUser;
    }

}
