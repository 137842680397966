<div id="video-player-task-overlay" *ngIf="isShown">
    <div class="video-overlay-task">
        <div class="video-overlay-task-container">
            <ng-container *ngIf="state === stateEnum.not_answered || state === stateEnum.answered">
                <div class="video-overlay-settings-top-container">
                    <!-- <div class="video-overlay-settings-top-closebutton" (click)="continueWatching()">
                        <ion-icon name="close"></ion-icon>
                    </div> -->
                    <div class="video-overlay-settings-top-title">{{task.question}}</div>
                </div>
                <div class="video-overlay-settings-content">
                    <div class="tasks-answers-container">
                        <div class="tasks-answer-option-container" *ngFor="let answerObj of shuffledAnswers">
                            <div class="tasks-answer-option-box"
                                [class.tasks-answer-option-box-disabled]="state === stateEnum.answered"
                                [class.tasks-answer-option-box-correct]="state === stateEnum.answered && isSelectedAnswer(answerObj) && answer?.score == 100"
                                (click)="selectAnswer(answerObj)">
                                <ion-icon name="square-outline" *ngIf="!isSelectedAnswer(answerObj)"></ion-icon>
                                <ion-icon name="checkbox-outline" *ngIf="isSelectedAnswer(answerObj)">
                                </ion-icon>
                                <div class="tasks-answer-option-text">
                                    {{answerObj.text}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="state === stateEnum.show_result">
                <div class="video-overlay-settings-top-container">
                    <div class="video-overlay-settings-top-title">{{ (isOnlySurveyQuestions ? 'survey_finished_header' : 'tasks_finished_header') | translate }}
                    </div>
                </div>
                <div class="video-overlay-settings-content" *ngIf="tasksProgressionResult">
                    <div class="task-result-center" *ngIf="!isOnlySurveyQuestions">
                        <div class="task-result-center-main">
                            <span class="task-result-resultText1">{{ 'tasks_result' | translate }}:</span>
                            <span class="task-result-number">{{tasksProgressionResult.progression.result.score}}</span>
                            <span class="task-result-resultText2">{{ 'tasks_out_of' | translate }}</span>
                            <span class="task-result-numberMax">{{tasksProgressionResult.progression.result.max}}</span>
                        </div>
                        <div class="task-result-center-sub">
                            <span class="task-result-center-sub-correct">{{ 'tasks_correct' | translate }}:
                                <strong>{{tasksProgressionResult.progression.result.correct}}</strong>
                                <!-- <ion-icon name="thumbs-up-outline"></ion-icon> -->
                            </span>

                            <span class="task-result-center-sub-wrong">
                                {{ 'tasks_wrong' | translate }}: <strong>{{tasksProgressionResult.progression.result.wrong}}</strong>
                                <!-- <ion-icon name="thumbs-down-outline"></ion-icon> -->
                            </span>
                        </div>
                        <!-- <div class="task-result-center-sub task-result-center-sub-wrong">
                        </div> -->
                        <div class="task-result-center-image">
                            <img *ngIf="tasksProgressionResult.progression.result.score <= 33"
                                src="assets/icon/yuyu_yellow_sad2.png" />
                            <img *ngIf="tasksProgressionResult.progression.result.score > 33 && tasksProgressionResult.progression.result.score <= 66"
                                src="assets/img/yuyu_yellow_exclamation_sign.png" />
                            <img *ngIf="tasksProgressionResult.progression.result.score > 66"
                                src="assets/icon/launcher-icon-3x.png" />

                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="video-overlay-task-bottom-container">
                <div class="task-answer-bottom-left">
                    <ion-button color="light" [size]="bottomRightButtonSize" (click)="rewatchAfterResult()"
                        *ngIf="state === stateEnum.show_result">
                        <ion-icon name="repeat" slot="start"></ion-icon>
                        {{ 'watch_again' | translate }}</ion-button>
                </div>
                <ng-container *ngIf="answer?.type === taskTypeEnum.multiple_choice">
                    <div class="task-answer-result task-answer-result-correct"
                        *ngIf="state == stateEnum.answered && answer?.score == 100">
                        <span class="task-answer-result-text" [innerHTML]="'tasks_answer_was_correct' | translate"></span>
                        <ion-icon name="thumbs-up-outline"></ion-icon>
                    </div>
                    <div class="task-answer-result task-answer-result-wrong"
                        *ngIf="state == stateEnum.answered && answer?.score != null && answer?.score < 100">
                        <span class="task-answer-result-text" [innerHTML]="'tasks_answer_was_wrong' | translate"></span>
                        <ion-icon name="thumbs-down-outline"></ion-icon>
                    </div>
                </ng-container>
                <div class="task-answer-bottom-right">
                    <ion-button color="light" [size]="bottomRightButtonSize" (click)="submitAnswer()"
                        *ngIf="(state === stateEnum.not_answered && !appData.isEducator()) || (state === stateEnum.not_answered && isEmbed)" [disabled]="isSubmitDisabled()">
                        {{ 'tasks_btn_submit' | translate }}</ion-button>
                    <ion-button color="light" [size]="bottomRightButtonSize" (click)="continueWithoutSaving()"
                        *ngIf="state === stateEnum.not_answered && appData.isEducator() && !isEmbed" [disabled]="isSubmitDisabled()">
                        {{ 'close' | translate }}</ion-button>
                    <ion-button color="light" [size]="bottomRightButtonSize" (click)="continueWatching()"
                        *ngIf="state === stateEnum.answered">
                        {{ 'button_continue' | translate }}</ion-button>
                    <ion-button color="light" [size]="bottomRightButtonSize" (click)="continueAfterResult()"
                        *ngIf="state === stateEnum.show_result">
                        <ion-icon name="play" slot="start"></ion-icon>
                        {{ 'continue_fortsetzen' | translate }}</ion-button>
                </div>
            </div>
        </div>
    </div>
</div>
