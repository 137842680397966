import { Component, Input } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

@Component({
    selector: 'app-create-class-paid-feature-popup',
    templateUrl: './create-class-paid-feature-popup.component.html',
    styleUrls: ['./create-class-paid-feature-popup.component.scss'],
})
export class CreateClassPaidFeaturePopupComponent {
    loadingImage: boolean = true;
    @Input() isModalOpen: boolean;

    constructor(private modalCtrl: ModalController, public plt: Platform) {}

    subscribe() {
        window.open('https://www.uugot.it/sba/', '_blank');
        // return this.modalCtrl.dismiss(null, 'subscribe');
    }

    product() {
        window.open('https://www.uugot.it/product-scooling-de.html', '_blank');
        // return this.modalCtrl.dismiss(null, 'productPage');
    }

    showSpinner() {
        this.loadingImage = true;
    }

    hideSpinner() {
        this.loadingImage = false;
    }

    get isIOsDevice() {
        return this.plt.is('ios');
    }
}
