import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NavController, AlertController } from '@ionic/angular';
import { AppData } from 'src/app/services/app-data.service';
import { IGroup } from 'src/app/interfaces/IGroup';
import { GroupsApi } from 'src/app/services/api/groups-api.service';
import { UsersApi } from 'src/app/services/api/users-api.service';
import { AppManager } from 'src/app/services/app-manager.service';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivityService } from 'src/app/services/activity.service';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { isNil } from 'lodash';
import { Constants } from '../../app.constants';

@Component({
    selector: 'app-class-select-user',
    templateUrl: 'class-select-user-page.html',
    styleUrls: ['class-select-user-page.scss']
})
export class ClassSelectUserPage {
    group: IGroup;
    classCode: string; // class access code
    users: any[];
    selectedUser: any = '';
    userInfoForm: UntypedFormGroup;
    submitAttempt = false;

    constructor(private formBuilder: UntypedFormBuilder,
        public appData: AppData,
        public navCtrl: NavController,
        public groupsApi: GroupsApi,
        public usersApi: UsersApi,
        public appManager: AppManager,
        public uiUtils: UiUtils,
        public alertCtrl: AlertController,
        private constants: Constants,
        public translate: TranslateService,
        private activityService: ActivityService,
        private router: Router,
        private logger: NGXLogger) {
        this.userInfoForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required]
        });

        const nav = this.router.getCurrentNavigation();
        this.logger.debug('ClassSelectUserPage getCurrentNavigation.extras', nav ? nav.extras : '-');
        if (nav && nav.extras && nav.extras.state) {
            this.group = nav.extras.state.group;
            this.classCode = nav.extras.state.classCode;
        }
    }

    ionViewWillEnter() {
        // if (this.appData.classAccessCode) {
        //     this.group = this.appData.studentClass;
        // } else {
        //     this.navCtrl.navigateRoot('catalog');
        // }
    }

    ionViewDidLeave() {
        // this.appData.classAccessCode = this.appData.studentClass = null;
    }

    /**
     * Logs the student into the class and opens the class details page
     */
    joinClass() {
        this.submitAttempt = true;

        if (this.selectedUser === 'new' && !this.userInfoForm.valid) {
            console.log('Form is not valid');
            return;
        }

        if (this.selectedUser === 'new') {
            this.groupsApi
                .registerStudent(
                    this.userInfoForm.value.firstName,
                    this.userInfoForm.value.lastName,
                    this.classCode
                )
                .subscribe(
                    (response) => {
                        this.appManager.saveUserInfo(
                            response.loggedUser,
                            response.jwtToken,
                            response.license.license,
                            null,
                            null,
                            this.group.under14_students
                        );
                        this.appData.groupsForUser = [response.group];
                        this.appData.addClassId(response.group._id);

                        // Redirect to class
                        this.openClassDetails(this.group);
                    },
                    (err) => {
                        console.log('joinClass error', err);
                        this.uiUtils.showServerRequestErrorAlert(err);
                    }
                );
        } else {
            this.confirmLogin();
        }
    }

    /**
     * Opens class details page for student
     *
     * @param group The class to be opened
     */
    openClassDetails(group: IGroup) {
        this.appData.activePage = '';
        this.appData.activeGroupId = group._id;
        // this.navCtrl.navigateForward(`student-class/details/${group._id}`);
        const isOnboardingDone = this.appData.getPreferenceString(this.constants.pref.ONBOARDING_STUDENT_DONE);
        if (isOnboardingDone) {
            location.href = `student-class/details/${group._id}`;
            return;
        }
        location.href = `/onboarding-student`;
    }

    /**
     * Display an alert to confirm user login
     */
    async confirmLogin() {
        const alert = await this.alertCtrl.create({
            header: 'Login',
            subHeader: this.translate.instant('class_login_confirmation', { firstName: this.selectedUser.first_name, lastName: this.selectedUser.last_name }),
            buttons: [
                {
                    text: this.translate.instant('btn_cancel'),
                    role: 'cancel'
                },
                {
                    text: this.translate.instant('btn_yes'),
                    handler: () => {
                        this.usersApi.loginCode(this.selectedUser.access_code).subscribe(async response => {
                            console.log('Success - login with code'/*, response*/);
                            let onlyKidsContent = false;
                            if (this.group.under14_students) {
                                onlyKidsContent = true;
                            }
                            this.appManager.saveUserInfo(response.loggedUser, response.jwtToken, response.license, response.preferences, null, onlyKidsContent);

                            // // Load student's classes
                            // this.groupsApi.getStudentClasses().subscribe(res => {
                            //     this.appData.groupsForUser = res.data;

                            //     // Show welcome toast
                            //     this.uiUtils.displayToast(this.translate.instant('student_welcome', { firstName: this.selectedUser.first_name, lastName: this.selectedUser.last_name }));

                            //     // Redirect to class
                            //     this.openClassDetails(this.group);
                            // });

                            this.appData.groupsForUser = [this.group];
                            this.appData.addClassId(this.group._id);


                            // Show welcome toast
                            this.uiUtils.displayToast(this.translate.instant('student_welcome', { firstName: this.selectedUser.first_name, lastName: this.selectedUser.last_name }));

                            // Redirect to class
                            this.openClassDetails(this.group);
                        }, err => {
                            console.error('Error - login with code', err.error);
                            this.uiUtils.showServerRequestErrorAlert(err);
                        });
                    }
                }
            ]
        });
        await alert.present();
    }

    /**
     * Navigates back to add class page
     */
    navigateBack() {
        this.navCtrl.navigateBack('student');
    }

    get maxLimitStudentsReached(): boolean {
        if (!this.group || isNil(this.group.maxStudents)) {
            return false;
        } else {
            return this.group.students?.length >= this.group.maxStudents;
        }
    }
}
