import { Component, Input, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard'
import { ModalController, ToastController } from '@ionic/angular';

@Component({
    selector: 'app-embed-modal',
    template: `
        <ion-header>
            <ion-toolbar>
                <ion-title>{{ 'Embed Code' | translate }}</ion-title>
                <ion-buttons slot="end">
                    <ion-button (click)="dismissModal()">
                        <ion-icon name="close"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <div class="ion-padding">
                <textarea readonly [(ngModel)]="embedCode"></textarea>
                <ion-button expand="full" (click)="copyToClipboard()">{{currentCopyText}}</ion-button>
            </div>
        </ion-content>
    `,
    styles: [
        `
            textarea {
                width: 100%;
                height: 150px; /* Adjust the height as needed */
            }
        `,
    ],
})
export class EmbedShareModalComponent  implements OnInit{
    @Input() videoLink: string;
    embedCode: string;
    originalCopyText = 'Copy to Clipboard'
    currentCopyText = this.originalCopyText;
    copiedText = 'Copied!';
    constructor(private modalController: ModalController,
                private toastController: ToastController,
                private _clipboardService: ClipboardService) {
    }

    ngOnInit() {
        this.embedCode = this.generateEmbedCode();
    }
    generateEmbedCode(): string {
        return `<iframe width="640" height="360" src="${this.videoLink}" frameborder="0" allowfullscreen></iframe>`;
    }

    dismissModal() {
        this.modalController.dismiss();
    }

    async copyToClipboard() {
        await navigator.clipboard.writeText(this.embedCode);
        this.currentCopyText = this.copiedText;
        setTimeout(() => {
            this.currentCopyText = this.originalCopyText;
        }, 2000)
        const toast = await this.toastController.create({
            message: 'Embed code copied to clipboard',
            duration: 2000,
            position: "top"
        });
        await toast.present();
    }
}
