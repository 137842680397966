import { OnInit, Component, Input } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { NavParams, PopoverController } from '@ionic/angular';
import { UiUtils } from 'src/app/services/ui-utils.service';

export interface IPopoverActionItem {

    /**
     * Title of the action item.
     */
    title: string;

    /**
     * Key of the action item.
     */
    key: string;
}

/**
 * A generic popover page that can be used for showing an action menu from the toolbar.
 * The selected option will be passed back to the onDidDismiss() handler as `data`.
 */
@Component({
    template: `
    <ion-list>
        <ion-list-header>{{title}}</ion-list-header>
        <ion-item button *ngFor="let o of options; let i = index"
            [lines]="i === options.length-1 ? 'none' : ''"
            (click)="optionClicked(o)"
            detail="false">
            {{o.title}}
        </ion-item>
        <!--<ion-item lines="none" detail="false" button onClick="dismiss()">{{'close' | translate}}</ion-item>-->
    </ion-list>
    `,
    styles: [
        `
        `
    ]
})
export class ActionMenuPopoverPage implements OnInit {

    @Input() title: string;
    @Input() options: IPopoverActionItem[];

    constructor(private popoverCtrl: PopoverController,
        private analytics: AnalyticsService,
        private logger: NGXLogger,
        public uiUtils: UiUtils) {
        // do nothing.
    }

    ngOnInit() {
        this.logger.debug('GenericMenuPopoverPage init', this.title, this.options);
    }

    dismiss() {
        this.popoverCtrl.dismiss();
    }

    optionClicked(option: IPopoverActionItem) {
        this.popoverCtrl.dismiss(option);
    }
}
