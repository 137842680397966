import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable, EMPTY, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { GroupsApi } from 'src/app/services/api/groups-api.service';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { NonBlockingResolver } from 'src/app/utils/non-blocking.resolver';
import { NGXLogger } from 'ngx-logger';
import { TasksApi } from 'src/app/services/api/tasks.service';

@Injectable({
    providedIn: 'root'
})
export class ClipTasksStatisticsResolver extends NonBlockingResolver {
    constructor(private _groupsApi: GroupsApi,
        private _tasksApi: TasksApi,
        private _uiUtils: UiUtils,
        private _navCtrl: NavController,
        private _logger: NGXLogger) {
        super();
    }

    getData(route: ActivatedRouteSnapshot): Observable<any> {
        const class_id = route.params.class_id;
        const clip_id = route.params.clip_id;
        return this._groupsApi.getById(class_id).pipe(catchError(err => {
            return EMPTY;
        }), mergeMap(res => {
            if (res.success) {
                return this._tasksApi.getStatisticsByClip(clip_id).pipe(catchError(err => {
                    return EMPTY;
                }), mergeMap(response => {
                    this._logger.debug('Got groups videos response', response);
                    return of({ group: res.data, statistics: response });
                }));
            } else {
                this._uiUtils.displayToast(res.msg);
                this._navCtrl.navigateRoot('catalog');
                return EMPTY;
            }
        }));
    }
}
