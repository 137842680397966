import {
    Component,
    OnInit,
    Input,
    ViewEncapsulation,
    ViewChild,
    ChangeDetectorRef,
} from '@angular/core';
import { IVideo } from '../../interfaces/IVideo';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from '../../services/app-data.service';
import { Constants } from 'src/app/app.constants';
import { ILeaderboardOverlayListener } from './video-player-leaderboard-overlay.interfaces';
import { IClip, ILeaderboardEntry } from 'src/app/interfaces/IClip';
import * as moment from 'moment';

/**
 * Component that shows the video player settings (overlay).
 */
@Component({
    selector: 'video-player-leaderboard-overlay',
    templateUrl: './video-player-leaderboard-overlay.component.html',
    styleUrls: ['./video-player-leaderboard-overlay.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerLeaderboardOverlay implements OnInit {
    @Input() video: IVideo; // the video
    @Input() clip: IClip;
    @Input() listener: ILeaderboardOverlayListener;

    momentShow: moment.Moment;

    @Input() leaderboardOverlayShown: boolean;

    detectChangesInterval: ReturnType<typeof setInterval>;

    /**
     * Show only the first 10 entries.
     */
    public readonly showFirstXEntries = 10;

    /**
     * How amny entries are being animated (max.)
     */
    public readonly animateFirstXEntries = 10;

    private readonly intervalMs = 350;

    constructor(
        private logger: NGXLogger,
        public translate: TranslateService,
        public uiUtils: UiUtils,
        public appData: AppData,
        public constants: Constants,
        private cdref: ChangeDetectorRef
    ) {
        // empty
        this.logger.debug('VideoPlayerLeaderboardOverlay constructor');
    }

    ngOnInit() {
        this.logger.debug('VideoPlayerLeaderboardOverlay.ngOnInit()');
    }

    onShow() {
        this.startAnimation();
    }

    startAnimation() {
        this.momentShow = moment();
        this.detectChangesInterval = setInterval(() => {
            // this.logger.debug('***** INTERVAL');
            this.clip?.leaderboard?.entries?.forEach((entry) => {
                // We just set a property on the entry
                const isEntryShown = this.isEntryShown(entry);
                if (!entry['isEntryShown'] && isEntryShown) {
                    this.logger.debug(`*** Show entry with position ${entry.pos}`);
                }
                entry['isEntryShown'] = isEntryShown;
            });
            this.cdref.detectChanges();
            if (
                moment().diff(this.momentShow, 'milliseconds') >
                this.intervalMs * this.maxShownEntries
            ) {
                this.logger.debug('***** CLEAR INTERVAL');
                clearInterval(this.detectChangesInterval);
            }
        }, 100);
    }

    close() {
        this.onClose();
        this.listener.onCloseLeaderboardOverlay();
    }

    onClose() {
        clearInterval(this.detectChangesInterval);
        this.clip?.leaderboard?.entries?.forEach((entry) => {
            entry['isEntryShown'] = false;
        });
        this.cdref.detectChanges();
    }

    /**
     * Either maximum of shown entries (this.showFirstXEntries) or number of entries, if less.
     */
    get maxShownEntries(): number {
        let max = this.showFirstXEntries;
        if (this.appData.isEducator()) {
            max = 200;
        }
        return Math.min(max, this.clip?.leaderboard?.entries?.length || 0);
    }

    /**
     * @param entry the leaderboard entry
     * @returns true if it's the authenticated users's own rank
     */
    isMyOwnRank(entry: ILeaderboardEntry): boolean {
        return entry.user_id === this.appData.authenticatedUser?._id;
    }

    /**
     * Needed for the animation
     * @param entry
     * @returns
     */
    isEntryShown(entry: ILeaderboardEntry): boolean {
        const delta = moment().diff(this.momentShow, 'milliseconds');
        const maxAnimateEntries = Math.min(this.animateFirstXEntries, this.maxShownEntries);
        const pos = this.clip?.leaderboard?.entries?.indexOf(entry) + 1;
        const showAt = (maxAnimateEntries - pos) * this.intervalMs;
        const show = showAt < delta;
        // this.logger.debug('showAt', showAt, delta, show);
        return show;
    }

    isTableHeaderShown() {
        if (!this.clip?.leaderboard) {
            return false;
        }
        const delta = moment().diff(this.momentShow, 'milliseconds');
        const maxAnimateEntries = Math.min(this.animateFirstXEntries, this.maxShownEntries);
        const showAt = maxAnimateEntries * this.intervalMs;
        const show = showAt < delta;
        // this.logger.debug('showAt', showAt, delta, show);
        return show;
    }

    topCountAsString(asText = false) {
        const num = this.maxShownEntries;
        let text = `${num}`;
        if (asText) {
            switch (num) {
                case 1:
                    text = 'One';
                    break;
                case 2:
                    text = 'Two';
                    break;
                case 3:
                    text = 'Three';
                    break;
                case 4:
                    text = 'Four';
                    break;
                case 5:
                    text = 'Five';
                    break;
                case 6:
                    text = 'Six';
                    break;
                case 7:
                    text = 'Seven';
                    break;
                case 8:
                    text = 'Eight';
                    break;
                case 9:
                    text = 'Nine';
                    break;
                case 10:
                    text = 'Ten';
                    break;
                default:
                    break;
            }
        }
        return text;
    }
}
