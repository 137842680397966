<ion-header>
    <ion-toolbar [color]="appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-menu-toggle *ngIf="isRootPage">
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
            <ion-button *ngIf="!isRootPage" (click)="navigateBack()">
                <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
            </ion-button>
        </ion-buttons>

        <ion-title *ngIf='getSubjectName()' >
            {{ group ? group.name : '' }} - {{getSubjectName()}}
        </ion-title>

        <ion-title *ngIf='!getSubjectName()' >
            {{ group ? group.name : '' }}
        </ion-title>

        <ion-buttons slot="end">
            <ion-button (click)="refreshContent()" title="{{ 'btn_reload_class' | translate }}">
                <ion-icon slot="icon-only" name="refresh"></ion-icon>
            </ion-button>
            <ion-button (click)="deleteClass()" title="{{ 'btn_remove_class' | translate }}">
                <ion-icon slot="icon-only" name="trash"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-progress-bar *ngIf="!group" type="indeterminate"></ion-progress-bar>
    <ion-grid fixed *ngIf="group">
        <ion-text *ngIf="available" color="green">
            <h4 class="ion-padding-start">
                {{ 'language' | translate }}:
                <span class="selectable-text">{{ 'lang_' + group.base_language | translate }}</span>
            </h4>
        </ion-text>
        <div class="alert alert-info ion-margin-top ion-margin-start ion-margin-end" *ngIf="available && classVideos.length === 0">
            {{ 'hint_no_video_visible_students' | translate }}
        </div>
<!--        <div class="ion-margin-start ion-margin-end ion-text-right" *ngIf="available">-->
<!--            <ion-button fill="solid" size="small" color="light" (click)="openLeaderboardPage()">-->
<!--                <ion-icon name="trophy" slot="start"></ion-icon>{{ "season_leaderboard" | translate }}-->
<!--            </ion-button>-->
<!--        </div>-->
        <ion-row *ngIf="available">
            <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="6"
                *ngFor="let video of classVideosInfo; let i = index;">
                <ion-card *ngIf="classVideos[i]; let groupVideo" (click)="openVideoPlayerPage(video, groupVideo.clip_id, groupVideo)" [class.isDisabled]="isVideoExpired(video) || video.isEnabled === false">
                    <div class="card-image-container">
                        <img [src]="video.imageURL" [alt]="video.title"/>
                        <div class="watched-progress-bar" *ngIf="getProgressForWatchedVideo(video, groupVideo.clip_id) as percent;"
                            [title]="'your_watching_progress' | translate:{percent:percent}">
                            <span [style.width]="percent + '%'">&nbsp;</span>
                        </div>
                        <div class="card-top-image-overlay" *ngIf="groupVideo.clip_id && groupVideo.clip_id.task_ids && groupVideo.clip_id.task_ids.length && !groupVideo.userTaskScore?.forLeaderboard">
                            <!--<span *ngIf="!groupVideo.clip_id.isFull">
                                {{'clip_of_video' | translate}}
                                "{{video.title}}" ({{uiUtils.secondsToTimeString(groupVideo.clip_id.start)}} - {{uiUtils.secondsToTimeString(groupVideo.clip_id.end)}})
                            </span>-->

                            <span class="card-top-image-overlay-tasks" *ngIf="groupVideo.clip_id.task_ids && groupVideo.clip_id.task_ids.length"
                                title="{{ 'tasks' | translate }}">
                                <ion-icon name="list-circle" class="icon-document" ioniconRemoveTitle iconTitle="{{ 'tasks' | translate }}"></ion-icon>
                                {{ groupVideo.clip_id.task_ids.length }} {{ groupVideo.clip_id.task_ids.length <= 1 ? 'task' : 'tasks' | translate }}
                            </span>

                            <!-- <ion-button size="small" style="float: right;" fill="outline" color="light" (click)="openStatisticsPage(groupVideo.clip_id)" >
                                <ion-icon name="stats-chart" slot="icon-only"></ion-icon>
                            </ion-button> -->

                            <ion-button *ngIf="groupVideo.clip_id.leaderboard && groupVideo.userTaskScore?.forLeaderboard" size="small" style="float: right;" fill="solid" color="light"
                                (click)="openVideoPlayerPage(video, groupVideo.clip_id, groupVideo, true); $event.stopPropagation();" title="{{'show_leaderboard' | translate}}">
                                <ion-icon name="stats-chart" slot="icon-only"></ion-icon>
                            </ion-button>

                        </div>

                        <div class="card-image-overlay-student-completed" *ngIf="groupVideo.clip_id && !isClipTaskResultDetailsShown(groupVideo) && groupVideo.userTaskScore?.forLeaderboard as entry">

                            <span class="card-top-image-overlay-tasks" *ngIf="groupVideo.clip_id.task_ids && groupVideo.clip_id.task_ids.length"
                                title="{{ 'tasks' | translate }}">
                                <ion-icon name="list-circle" class="icon-document" ioniconRemoveTitle iconTitle="{{ 'tasks' | translate }}"></ion-icon>
                                {{ groupVideo.clip_id.task_ids.length }} {{ groupVideo.clip_id.task_ids.length <= 1 ? 'task' : 'tasks' | translate }}
                            </span>

                            <div class="card-image-overlay-student-completed-top-right" *ngIf="groupVideo.clip_id.leaderboard">
                                <div class="leaderboard-abzeichen-tasks-solved"
                                    *ngIf="groupVideo?.userTaskScore?.forLeaderboard?.result?.total as total">
                                    {{total}}x
                                </div>
                                <img class="leaderboard-abzeichen-image" *ngIf="groupVideo?.userTaskScore?.forLeaderboard?.result?.total as total"
                                    src="assets/img/leaderboard/abzeichen.png" title="{{'tasks_completed' | translate}}"
                                    alt="{{'tasks_completed' | translate}}">

                                <ng-container *ngIf="getLeaderboardEntryForUser(groupVideo.clip_id) as entry">
                                    <ng-container *ngIf="entry.award1">
                                        <img class="leaderboard-medal-image" src="assets/img/leaderboard/medal_1_gold.png"
                                            alt="gold medal" />
                                    </ng-container>
                                    <ng-container *ngIf="entry.award2">
                                        <img class="leaderboard-medal-image" src="assets/img/leaderboard/medal_2_silver.png"
                                            alt="silver medal" />
                                    </ng-container>
                                    <ng-container *ngIf="entry.award3">
                                        <img class="leaderboard-medal-image" src="assets/img/leaderboard/medal_3_bronze.png"
                                            alt="bronze medal" />
                                    </ng-container>
                                </ng-container>

                                <div>
                                    <ion-button *ngIf="groupVideo.clip_id.leaderboard" size="small" style="float: right;" fill="solid" color="light"
                                        (click)="openVideoPlayerPage(video, groupVideo.clip_id, groupVideo, true); $event.stopPropagation();" title="{{'show_leaderboard' | translate}}">
                                        <ion-icon name="stats-chart" slot="icon-only"></ion-icon>
                                    </ion-button>
                                </div>
                            </div>

                            <ion-icon name="checkmark" class="card-overlay-checkmark"></ion-icon>


                            <div class="card-image-overlay-bottom-right">
                                <div class="card-image-overlay-text-yourscore">
                                   {{'your_score' | translate}}
                                </div>
                                <div >
                                    <span class="card-image-overlay-text-leaderboard">{{'leaderboard' | translate}}</span>
                                    <span class="card-image-overlay-score leaderboard-score-{{getLeaderboardScoreColor(entry.result.score)}}">
                                        {{entry.result.score}}%</span>
                                </div>
                                <div *ngIf="groupVideo.clip_id.leaderboard && isUserInLeaderboard(groupVideo.clip_id.leaderboard)">
                                    <ion-button size="small" fill="solid" color="secondary" (click)="showClipTaskResultDetails(groupVideo); $event.stopPropagation();" >
                                        {{'btn_detail' | translate}}
                                    </ion-button>
                                </div>
                            </div>
                        </div>

                        <div class="card-image-overlay-student-completed-details" *ngIf="groupVideo.clip_id && isClipTaskResultDetailsShown(groupVideo) && groupVideo.userTaskScore?.forLeaderboard as entry">

                            <div class="card-image-overlay-student-completed-closebutton" (click)="hideClipTaskResultDetails(groupVideo); $event.stopPropagation();">
                                <ion-icon name="close"></ion-icon>
                            </div>

                            <div class="card-image-overlay-student-completed-top-right">

                                <div class="leaderboard-abzeichen-tasks-solved"
                                    *ngIf="groupVideo?.userTaskScore?.forLeaderboard?.result?.total as total">
                                    {{total}}x
                                </div>
                                <img class="leaderboard-abzeichen-image" *ngIf="groupVideo?.userTaskScore?.forLeaderboard?.result?.total as total"
                                    src="assets/img/leaderboard/abzeichen.png" title="{{'tasks_completed' | translate}}"
                                    alt="{{'tasks_completed' | translate}}">

                                <div class="leaderboard-medal-container" *ngIf="getLeaderboardEntryForUser(groupVideo.clip_id) as entry">
                                    <ng-container *ngIf="entry.award1">
                                        <img class="leaderboard-medal-image" src="assets/img/leaderboard/medal_1_gold.png"
                                            alt="gold medal" />
                                    </ng-container>
                                    <ng-container *ngIf="entry.award2">
                                        <img class="leaderboard-medal-image" src="assets/img/leaderboard/medal_2_silver.png"
                                            alt="silver medal" />
                                    </ng-container>
                                    <ng-container *ngIf="entry.award3">
                                        <img class="leaderboard-medal-image" src="assets/img/leaderboard/medal_3_bronze.png"
                                            alt="bronze medal" />
                                    </ng-container>
                                </div>

                                <div>
                                    <ion-button *ngIf="groupVideo.clip_id.leaderboard" size="small" style="float: right;" fill="solid" color="light"
                                        (click)="openVideoPlayerPage(video, groupVideo.clip_id, groupVideo, true); $event.stopPropagation();" title="{{'show_leaderboard' | translate}}">
                                        <ion-icon name="stats-chart" slot="icon-only"></ion-icon>
                                    </ion-button>
                                </div>
                            </div>


                            <div class="card-image-overlay-student-completed-center">
                                <table>
                                    <tr>
                                        <td colspan="4" class="header-in-table">
                                            {{'your_score' | translate}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="4">
                                            <ng-container *ngIf="groupVideo.userTaskScore?.forLeaderboard as progression">
                                                {{progression.user_id?.first_name}}
                                                {{progression.user_id?.last_name?.toUpperCase()}}
                                            </ng-container>
                                        </td>
                                    </tr>
                                    <!-- <tr>
                                        <td>
                                            Points
                                        </td>
                                        <td class="score-in-table">
                                           400
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr> -->
                                    <tr>
                                        <td>
                                            {{'leaderboard' | translate}}
                                        </td>
                                        <td class="score-in-table">
                                            <ng-container *ngIf="groupVideo.userTaskScore?.forLeaderboard as progression">
                                                <span class="leaderboard-score-{{getLeaderboardScoreColor(progression.result.score)}}">
                                                    {{progression.result.score}}%
                                                </span>
                                            </ng-container>
                                        </td>
                                        <td  class="header-in-table">
                                            {{'best_in_class' | translate}}
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{'top_score' | translate}}
                                        </td>
                                        <td class="score-in-table">
                                            <ng-container *ngIf="groupVideo.userTaskScore?.bestByUser as progression">
                                                <span class="leaderboard-score-{{getLeaderboardScoreColor(progression.result.score)}}">
                                                    {{progression.result.score}}%
                                                </span>
                                            </ng-container>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="groupVideo.userTaskScore?.bestInLeaderboard as progression">
                                                {{getNameEllipsized(progression.user_id?.first_name, progression.user_id?.last_name)}}
                                            </ng-container>
                                        </td>
                                        <td class="score-in-table">
                                            <ng-container *ngIf="groupVideo.userTaskScore?.bestInLeaderboard as progression">
                                                <span class="leaderboard-score-{{getLeaderboardScoreColor(progression.result.score)}}">
                                                    {{progression.result.score}}%
                                                </span>
                                            </ng-container>
                                        </td>
                                    </tr>
                                </table>
                                <div class="card-image-overlay-student-completed-bottom" *ngIf="getClipTaskResultText(groupVideo) as taskResult">
                                    <img class="card-image-overlay-student-completed-uu" [src]="taskResult.image" />
                                    <div>
                                        <div class="card-image-overlay-student-completed-headline">{{taskResult.headline}}</div>
                                        <div class="card-image-overlay-student-completed-text">{{taskResult.text}}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <ion-item class="ion-no-padding">
                        <ion-label class="ion-padding-start ion-text-wrap">
                            <ion-card-title>{{ '#' + (i+1) + ': ' }}
                                <ng-container *ngIf="groupVideo.clip_id && groupVideo.clip_id.title; else noClipTitle">
                                    {{groupVideo.clip_id.title}}
                                </ng-container>
                                <ng-template #noClipTitle>
                                    {{video.title}}
                                </ng-template>
                            </ion-card-title>
                            <ion-label>{{ getTranslations(video) }}</ion-label>
                        </ion-label>
                        <ion-note slot="end">
                            <div class="video-time">
                                <div class="video-time">
                                    <ion-icon name="time"></ion-icon>
                                    <span *ngIf="groupVideo.clip_id && !groupVideo.clip_id.isFull; else noClipDuration">
                                        {{uiUtils.secondsToTimeString(groupVideo.clip_id.duration)}}
                                    </span>
                                    <ng-template #noClipDuration>
                                        <span *ngIf="video.offset && video.offset.length === 2">
                                            {{uiUtils.secondsToTimeString(video.offset[1] - video.offset[0])}}
                                        </span>
                                        <span *ngIf="(!video.offset || video.offset.length != 2) && video.duration">
                                            {{uiUtils.secondsToTimeString(video.duration)}}
                                        </span>
                                        <span *ngIf="(!video.offset || video.offset.length != 2) && !video.duration && video.durMinutes">
                                           ~ {{video.durMinutes}} Min.
                                        </span>
                                    </ng-template>
                                </div>
                            </div>
                            <div *ngIf="video.websource_id && appData.getLogoPathForSource(video.websource_id, 's')">
                                <img class="video-source-logo"
                                    [src]="appData.getLogoPathForSource(video.websource_id, 's')" />
                            </div>
                            <div *ngIf="video.websource_id && !appData.getLogoPathForSource(video.websource_id, 's')"
                                class="websource-logo-name">
                                <span>{{appData.getNameForSource(video.websource_id)}}</span>
                            </div>
                        </ion-note>
                    </ion-item>

                    <ion-button *ngIf="groupVideo.assessment_id" color="green" fill="clear" class="download-btn" (click)="$event.stopPropagation()">
                        <a [href]="groupVideo.assessment_id.testFileUrl" target="_blank" title="{{'download_the_worksheet' | translate}}">
                            <ion-icon name="download" class="download-center"></ion-icon>
                            <span class="download-center">
                                &nbsp;&nbsp;{{ groupVideo.assessment_id.name }}
                            </span>
                        </a>
                    </ion-button>
                    <p class="no-worksheet-available" *ngIf="!groupVideo.assessment_id" >
                        {{ 'no_worksheet_available' | translate}}
                    </p>
                </ion-card>

                <!-- Show the "2 days still available" flap: -->
                <app-video-catalog-expires-flap [video]="video"></app-video-catalog-expires-flap>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
