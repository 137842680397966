<ion-header>
    <ion-toolbar color="secondary">
        <ion-title>{{config.title}}</ion-title>
        <ion-buttons slot="end">
            <ion-button *ngIf="index > 0 && index < 3" (click)="slidePrev()">
                <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
            </ion-button>
            <ion-button *ngIf="index < (config.slides.length - 1)" (click)="slideNext()">
                <ion-icon slot="icon-only" name="arrow-forward"></ion-icon>
            </ion-button>
            <ion-button *ngIf="index == (config.slides.length - 1)" (click)="closeModal()">
                <ion-icon name="close" slot="start"></ion-icon>
                {{config.doneButtonText || 'button_done' | translate}}
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-slides pager="true" scrollbar="false" (ionSlideDidChange)="slideChanged()" (ionSlideReachEnd)="tutorialDone()"
        [options]="slideOptions">
        <ion-slide *ngFor="let slide of config.slides">
            <img src="{{slide.image}}">
            <h1>{{slide.header}}</h1>
            <p>
                {{slide.text}}
            </p>
        </ion-slide>
    </ion-slides>

    <div *ngIf="!plt.is('mobile')" class="slide-arrows-container">
        <ion-icon *ngIf="index > 0" name="arrow-back-circle" class="slide-back-arrow" (click)="slidePrev()"></ion-icon>
        <ion-icon *ngIf="index < (config.slides.length - 1)" name="arrow-forward-circle" class="slide-forward-arrow"
            (click)="slideNext()"></ion-icon>
        <ion-icon *ngIf="config.showDoneArrow && index == (config.slides.length - 1)" name="close-circle" class="slide-done-arrow"
            (click)="closeModal()" title="{{config.doneButtonText || 'button_done' | translate}}"></ion-icon>
    </div>
    <div *ngIf="plt.is('mobile') && showArrow" class="slide-arrows-container">
        <ion-icon *ngIf="index === 0" name="arrow-forward-circle" class="slide-forward-arrow"></ion-icon>
    </div>
</ion-content>
