import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../app.constants';
import { AppData } from 'src/app/services/app-data.service';
import { AppManager } from '../services/app-manager.service';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
    constructor(private alertCtrl: AlertController,
        public translate: TranslateService,
        public appData: AppData,
        private appManager: AppManager,
        public constants: Constants,
        private navCtrl: NavController,
        private logger: NGXLogger) {
        //Doing nothing.
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            // Doing nothing.
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                // If server returns authentication error
                if (err.status === this.constants.HTTP_STATUS_CODE.UNAUTHORIZED) {
                    this.logger.info('Authentication error: ', err.status);
                    // this.appData.groupsForUser = [];
                    // this.appData.removePreference(this.constants.pref.AUTH_TOKEN);
                    // this.appData.removePreference(this.constants.pref.LOGGED_USER);
                    // this.appData.removePreference(this.constants.pref.LICENSE);
                    // this.appData.authenticatedUser = null;
                    // this.appData.userLicense = null;
                    this.appManager.deleteUserInfo();
                    this.showAlert();
                }
            }
        }));
    }

    async showAlert() {
        const alert = await this.alertCtrl.create({
            header: this.translate.instant('authentication_error_header'),
            subHeader: this.translate.instant('authentication_error_message'),
            buttons: [
                {
                    text: this.translate.instant('btn_ok'),
                    handler: () => {
                        // location.href = 'login';
                        // return true;
                        this.navCtrl.navigateRoot('login');
                        return true;
                    }
                }
            ],
            // User cannot dismiss dialog
            backdropDismiss: false
        });
        await alert.present();
    }
}
