import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras } from '@angular/router';

import { ToastController, NavController, ModalController, Platform, AlertController, IonInput } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UsersApi } from 'src/app/services/api/users-api.service';
import { GroupsApi } from 'src/app/services/api/groups-api.service';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from 'src/app/services/app-data.service';
import { AppManager } from 'src/app/services/app-manager.service';
import { ActivityService } from 'src/app/services/activity.service';
import { NGXLogger } from 'ngx-logger';
import { forkJoin } from 'rxjs';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { Constants } from '../../app.constants';
import { EducatorsApi } from '../../services/api/educators-api';
import { SharedUiService } from '../../services/shared-ui.service';
import { AbstractRootMenuPage } from 'src/app/utils/abstract-root-menu-page';

enum State {
    home,
    login_error,
    legal_error,
    logged_in
}

@Component({
    selector: 'app-educator-login',
    templateUrl: 'educator-login-page.html',
    styleUrls: ['educator-login-page.scss']
})
export class EducatorLoginPage extends AbstractRootMenuPage implements OnInit {
    public requestLinkForm: UntypedFormGroup;
    public loginCodeForm: UntypedFormGroup;
    public loginPasswordForm: UntypedFormGroup;
    public codeSubmitAttempt = false;
    public preauthSubmitAttempt = false;
    public passwordSubmitAttempt = false;
    public segment = 'code';

    public currentState = State.home;
    public mainText = 'hello_uu_scooling';
    public subHeaderText = 'login_subheader_infotext';
    public askEmailText = 'ask_email';

    public showLoginWithPassword = false;

    State = State; // Needed, so it can be accessed from the template

    private emailInput: IonInput;
    private codeInput: IonInput;

    constructor(public navCtrl: NavController,
        private _route: ActivatedRoute,
        private usersApi: UsersApi,
        private groupsApi: GroupsApi,
        private educatorsApi: EducatorsApi,
        public uiUtils: UiUtils,
        private formBuilder: UntypedFormBuilder,
        public appData: AppData,
        public toastCtrl: ToastController,
        private appManager: AppManager,
        public translate: TranslateService,
        private activityService: ActivityService,
        private analytics: AnalyticsService,
        private logger: NGXLogger,
        private modalCtrl: ModalController,
        private constants: Constants,
        private sharedUiService: SharedUiService,
        protected plt: Platform,
        private alertCtrl: AlertController) {

        super(plt);

        // https://ionicframework.com/docs/developer-resources/forms/
        // https://www.joshmorony.com/advanced-forms-validation-in-ionic-2/
        this.requestLinkForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.email])],
        });

        this.loginCodeForm = this.formBuilder.group({
            code: ['', Validators.compose([Validators.required])]
        });

        this.loginPasswordForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.email])],
            password: ['', Validators.compose([Validators.required])],
        });

        // Update login page if user refreshes page and is auto-logged in
        this.appManager.checkAuthToken(null).subscribe(isLoggedIn => {
            if (isLoggedIn) {
                this.updateUI(State.logged_in);
            }
        });

        // Get code from the URL and do an automatic login
        const accessCode = this._route.snapshot.params.code;

        // Update login page is user is logged in
        if (accessCode) {
            if (this.appData.isLoggedIn) {
                this.logger.debug('Automatic login: user is still logged in - need to log out');
                this.logout();
            }
            this.loginWithCode(accessCode);
        } else if (this.appData.isEducator()) {
            this.updateUI(State.logged_in);
        }
    }

    ngOnInit() {
        this._route.queryParams.subscribe(params => {
            this.logger.debug('Query params', params);
            if (this.currentState !== State.logged_in) {
                if (params['segment']) {
                    this.segment = params['segment'];
                }
            }
        });
    }

    ionViewWillEnter() {
        super.ionViewWillEnter();
        this.appData.activePage = 'login';
        super.ionViewWillEnter();
    }

    ionViewWillLeave() {
        super.ionViewWillLeave();
    }

    /**
     * Updates text depending on page state
     */
    updateUI(state: State) {
        this.currentState = state;
        switch (state) {
            case State.home:
                this.mainText = 'hello_uu_scooling';
                this.subHeaderText = 'login_subheader_infotext';
                this.askEmailText = 'ask_email';
                break;
            case State.login_error:
                this.mainText = 'outdated_link';
                this.subHeaderText = 'login_subheader_infotext';
                this.askEmailText = 'ask_email_after_error';
                break;
            case State.logged_in:
                this.mainText = 'hello_uu';
                this.subHeaderText = '';
                this.askEmailText = 'logged_in_as';
                break;
            default:
                this.mainText = 'not_accepted_terms';
                this.subHeaderText = 'login_subheader_infotext';
                this.askEmailText = 'have_to_accept_terms';
                break;
        }
    }

    /**
     * Calls userApi.preAuth() to request a login link.
     */
    requestLoginLink() {
        this.preauthSubmitAttempt = true;

        if (!this.requestLinkForm.valid) {
            this.logger.debug('Form is not valid');
            return;
        }

        const email = this.requestLinkForm.value.email;

        // Callback in case of preauth error
        const onPreauthError = (result?: any) => {
            if (result.code === 'USERS_PREAUTH_EMAIL_NOTFOUND_AUTO_REGISTER') {
                this.logger.debug('Could not authorize user, attempt to register', result);
                this.educatorsApi.register(email).subscribe(registerResult => {
                    if (registerResult.success) {
                        this.uiUtils.displayToast(registerResult.msg, undefined, 4000);
                    } else if (registerResult.code === 'EDUCATOR_EMAIL_NOT_WHITELISTED') {
                        this.showNoRegistrationPossibleDialog();
                    } else {
                        this.uiUtils.displayToast(registerResult.msg, undefined, 4000);
                    }
                });
            } else {
                this.logger.debug('Could not authorize user, automatic registration not possible', result);
                // this.uiUtils.displayToast(this.translate.instant('user_not_found'));
                this.showNoRegistrationPossibleDialog();
            }
        };


        this.usersApi.preAuth(email).subscribe(result => {
            if (result.success) {
                this.logger.debug('Success - preAuth response', result);
                this.uiUtils.displayToast(this.translate.instant('auth_link_sent'), undefined, 4000);
                this.loginCodeForm.reset();
                this.codeSubmitAttempt = false;
                this.segment = 'code';
                setTimeout(() => {
                    if (this.codeInput) {
                        this.codeInput.setFocus();
                    }
                }, 500);
            } else {
                this.uiUtils.displayToast(result.msg, undefined, 4000);
            }
        }, err => {
            this.logger.info('Error - preAuth response', err.error);
            if (err.error && (err.error.code === 'USERS_PREAUTH_EMAIL_NOTFOUND' || err.error.code === 'USERS_PREAUTH_EMAIL_NOTFOUND_AUTO_REGISTER')) {
                onPreauthError(err.error);
            } else {
                this.uiUtils.showErrorAlert(this.translate.instant('email_error'));
            }
        });
    }

    showNoRegistrationPossibleDialog() {
        const list = this.appManager.getAustrianTeacherEmailAddressesForDisplay().map(e => `<li>${e}</li>`).join('');
        this.uiUtils.showInfoAlert(
            '',
            'Code nicht versendet!',
            `Bitte gib deine Dienstmailadresse für die automatische Registrierung ein. (folgende Domains)<br>
            <ul>
            ${list}
            </ul>
            <div class="footer">Keine Dienstmailadresse? Wir haben dir eine E-Mail gesendet. Bitte überprüfe auch deinen Spam-Ordner!</div>
        `, null, 'dialog-registration-not-possible'
        );
    }

    /**
     * Logs the user out
     */
    async logout() {

        const doLogout = () => {
            const userRole = this.appData.authenticatedUser.role;
            this.updateUI(State.home); // Assignment needed for UI to update
            this.appManager.deleteUserInfo();
            this.logger.debug('User logged out');

            if (userRole === 'student') {
                this.navCtrl.navigateRoot('student');
            }
        };

        // Save events for student before logout
        if (this.appData.isStudent()) {
            // Show confirmation message
            const alert = await this.alertCtrl.create({
                header: this.translate.instant('log_out'),
                subHeader: this.translate.instant('confirm_student_logout_text'),
                buttons: [
                    {
                        text: this.translate.instant('btn_cancel'),
                        role: 'cancel'
                    },
                    {
                        text: this.translate.instant('btn_ok'),
                        handler: async () => {
                            await this.activityService.uploadLocallyStoredEvents();
                            doLogout();
                        }
                    }
                ]
            });
            await alert.present();

        } else {
            doLogout();
        }

    }

    /**
     * Opens student login page
     */
    openStudentLoginPage() {
        this.navCtrl.navigateRoot('student');
    }

    /**
     * Checks if form is valid and calls login function if it is
     */
    onSubmitLoginWithCode() {
        this.codeSubmitAttempt = true;

        if (!this.loginCodeForm.valid) {
            this.logger.debug('Form is not valid');
            return;
        }

        this.loginWithCode(this.loginCodeForm.value.code);
    }

    /**
     * Log user in with code
     */
    loginWithCode(accessCode: string) {
        const code = accessCode.trim();
        this.usersApi.loginCode(code).subscribe(async response => {
            this.logger.debug('Success - login with code', response);

            this.appManager.saveUserInfo(response.loggedUser, response.jwtToken, response.license, response.preferences);
            return forkJoin([
                this.appManager.getUserClasses(),
                this.appManager.getUserClassTemplates()
            ]).map(([groupsResponse, templatesResponse]) => {
                // this._logger.debug('Groups and templates:', resArray);
                this.appData.groupsForUser = groupsResponse?.data;
                this.appData.groupTemplatesForUser = templatesResponse?.data;
                this.appData.forceReloadVideos();
                this.updateUI(State.logged_in);
                this.sharedUiService.checkShowProfilePageRequired(response.loggedUser);
                if (this.appData.isEducator()) {
                    this.navCtrl.navigateRoot('login');
                }
                return true;
            }).subscribe(() => {
                this.logger.debug('Loaded educator groups and templates');
            });
        }, err => {
            this.logger.warn('Error - login with code. Trying class access code', err.error);
            this.groupsApi.getByCode(code).subscribe(response => {
                if (response.success) {
                    // this.appData.studentClass = response.data;
                    // this.appData.classAccessCode = code;
                    // this.analytics.trackAnalyticsEvent(AnalyticsCategory.Class, 'join', this.appData.studentClass._id);
                    // this.navCtrl.navigateForward('student-class/select-user');
                    const navigationExtras: NavigationExtras = {
                        state: {
                            group: response.data,
                            classCode: code
                        }
                    };
                    this.navCtrl.navigateForward('student', navigationExtras);
                } else {
                    // Show original error
                    this.logger.debug('Error code is ', err.error?.code);
                    if (['USERS_ACEXPIRED', 'USERS_ACNOTEXISTS'].includes(err.error?.code)) {
                        this.alertCtrl.create({
                            // header: this.translate.instant('token_expired_header'),
                            subHeader: err.error.message,
                            buttons: [
                                {
                                    text: this.translate.instant('btn_cancel'),
                                    role: 'cancel',
                                    handler: () => {
                                        console.log('Cancel clicked');
                                    }
                                },
                                {
                                    text: this.translate.instant('login_request_code'),
                                    handler: () => {
                                        this.segment = 'email';
                                        setTimeout(() => {
                                            if (this.emailInput) {
                                                this.emailInput.setFocus();
                                            }
                                        }, 500);
                                        return true;
                                    }
                                }
                            ]
                        }).then(alert => {
                            return alert.present();
                        });
                    } else {
                        this.uiUtils.showServerRequestErrorAlert(err);
                    }

                }
            }, err2 => {
                console.warn('Error getting group by code', err2);
                this.uiUtils.showServerRequestErrorAlert(err);
            });
        });
    }

    /**
     * NOT USED!!!! DEPRECATED
     * Checks if username and password are valid and logs user in if they are
     */
    loginWithPassword() {
        this.passwordSubmitAttempt = false;

        if (!this.loginPasswordForm.valid) {
            this.logger.debug('Form is not valid');
            return;
        }

        this.usersApi.login(this.loginPasswordForm.value.email, this.loginPasswordForm.value.password).subscribe(async response => {
            this.logger.debug('Success - login with password', response);

            this.appManager.saveUserInfo(response.loggedUser, response.jwtToken, response.license, response.preferences);
            const sources =[
                this.appManager.getUserClasses(),
                this.appManager.getUserClassTemplates()
            ];
            if (response.loggedUser.customer_role === 'admin'){
                console.log('tenemos que traer los grupos de amdin');
            }
            return forkJoin(sources).map(([groupsResponse, templatesResponse]) => {
                // this._logger.debug('Groups and templates:', resArray);
                this.appData.groupsForUser = groupsResponse?.data;
                this.appData.groupTemplatesForUser = templatesResponse?.data;
                this.appData.forceReloadVideos();
                this.updateUI(State.logged_in);
                this.sharedUiService.checkShowProfilePageRequired(response.loggedUser);
                return true;
            }).subscribe(() => {
                this.logger.debug('Loaded educator groups and templates');
            });

            // const resClasses = await this.appManager.getUserClasses().toPromise();
            // this.appData.groupsForUser = resClasses?.data;
            // this.appManager.getUserClassTemplates().subscribe()
            // this.appData.forceReloadVideos();
            // this.updateUI(this.currentState = State.logged_in);

        }, err => {
            this.logger.error('Error - login with password', err.error);
            this.uiUtils.showServerRequestErrorAlert(err);
        });
    }

    async buttonEditProfilePageClicked() {
        return this.sharedUiService.showEditProfilePage();
    }

    async buttonInviteEducatorsClicked() {
        return this.sharedUiService.showInviteEducatorsPage(true, true);
    }

    async buttonEducatorTutorialClicked() {
        return this.sharedUiService.showEducatorTutorialPage(true);
    }

    @ViewChild('email', { static: false }) set emailInputPlaceholder(email: IonInput) {
        this.logger.debug('EMAIL', email);
        if (email) { // initially setter gets called with undefined
            this.emailInput = email;
        }
    }

    @ViewChild('code', { static: false }) set codeInputPlaceholder(code: IonInput) {
        this.logger.debug('CODE', code);
        if (code) { // initially setter gets called with undefined
            this.codeInput = code;
        }
    }

}
