<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-menu-toggle>
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
        <ion-title>{{ "Welcome to uugot.it sCOOLing" | translate }}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center" >
    <swiper-container
        [pagination]='pagination'
        space-between="30"
        class=''
        #swiper >
        <!--        step  0-->
        <swiper-slide class="ion-padding">
            <ion-row class='centered-row ion-justify-content-center'>
                <ion-col>
                    <div class='slide-desktop-container'>
                        <ion-img class='ion-padding' src="assets/img/tutorials/A03_tutorial_subtitle.gif"></ion-img>
                        <ion-text class='ion-padding ion-margin-bottom'>
                                {{ 'student_onboarding_slide_2_description' | translate }}
                        </ion-text>
                    </div>
                </ion-col>
            </ion-row>
        </swiper-slide>
        <swiper-slide class="">
            <ion-row class='centered-row ion-justify-content-center'>
                <ion-col>
                    <div class='slide-desktop-container'>
                        <ion-img class='ion-padding ion-margin-top superhero' src="assets/img/onboarding_student_superhero.jpeg"></ion-img>
                        <ion-text class='ion-padding ion-margin-bottom'>
                            {{ 'student_onboarding_slide_4_description' | translate }}
                        </ion-text>
                    </div>
                </ion-col>
            </ion-row>
        </swiper-slide>
        <swiper-slide class="ion-padding">
            <ion-row class='centered-row ion-justify-content-center'>
                <ion-col>
                    <div class='slide-desktop-container'>
                        <ion-img src="assets/img/yuyu_yellow_exclamation_sign.png"></ion-img>
                        <ion-text class='ion-padding ion-margin-bottom'>
                            {{ getTextForGiftSlide() }}
                        </ion-text>
                    </div>
                </ion-col>
            </ion-row>
        </swiper-slide>
    </swiper-container>
</ion-content>
<ion-footer class='footer'>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button [disabled]='disablePreviousButton()' (click)='previous()'>Previous</ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
            <ion-button (click)='next()' [disabled]='disableNext || submitting'>
                <ion-spinner *ngIf="submitting"></ion-spinner>
                <span *ngIf="!submitting">{{ getTextForNextButton() }}</span>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
