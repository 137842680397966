import { Component, OnInit, ViewChild } from '@angular/core';
import { Platform, NavController, IonRouterOutlet, AnimationController } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import { TranslateService } from '@ngx-translate/core';
import { UUID } from 'angular2-uuid';
import { NGXLogger } from 'ngx-logger';
import * as moment from 'moment';
import * as _ from 'lodash';
import { register } from 'swiper/element/bundle';
import { VideoCatalogPage } from './pages/video-catalog/video-catalog-page';
import { Constants } from './app.constants';
import { Utils } from './utils/utils';
import { IGroup } from './interfaces/IGroup';
import { AppData } from './services/app-data.service';
import { AnalyticsCategory, AnalyticsService } from './services/analytics.service';
import { ActivityService } from './services/activity.service';
import { environment } from 'src/environments/environment';
import { interval, Observable, Subscription } from 'rxjs';
import { NavigationStart, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { AppManager } from './services/app-manager.service';
import { SharedUiService } from './services/shared-ui.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { Browser } from '@capacitor/browser';
import { ILanguages } from './interfaces/ILanguages';
import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics';
import { IVideoCategory } from './interfaces/IVideoCategory';
import { NgZone } from '@angular/core';

import { App, URLOpenListenerEvent } from '@capacitor/app';

/**
 * A menu item in the main app menu.
 */
interface IMenuItem {
    title: string;
    segment: string;
    isMain: boolean;
    isDisabled?: boolean;

    /**
     * User roles for which the item will be shown, for example "educator", "student".
     * Leave undefined for all roles.
     * Set to "public" to show only to unauthorized users (= public or anonymous users).
     */
    roles?: Array<string>;
    subItems?: Array<IMenuItem>;
    isSubItemsOpen?: boolean;
    titleI18n?: string;
    badge?: string;

    /**
     * If true, then this item will be shown at the bottom of the page
     */
    isBottom?: boolean;
}

enum BottomFloatingButtonType {
    educator_help = 'educator_help',
    educator_invite_colleagues = 'educator_invite_colleagues',
    feedback_form_bka_2021 = 'feedback_form_bka_2021'
}
register();
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
    rootPage: any = VideoCatalogPage;
    pages: Array<IMenuItem>;
    systemLanguage: string;
    isFirstPageNavigation = false;
    currentUrl: string;
    webSources = ['Das erste', 'zdfmediathek', 'ORF', 'NDR', 'Planet Schule', 'Tirol TV', 'W24', 'sCOOLing', 'Health TV', 'LT1', 'Academy', 'ZDF', 'all_videos_badge'];

    private backgroundUpdateSubscription: Subscription;

    unwatchedVideosCount: Observable<number>;

    // See also https://www.damirscorner.com/blog/posts/20191220-PopMultiplePagesInIonic4.html
    @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;

    /**
     * If true, the floating action button (bottom right) will be shown.
     */
    bottomFloatingButtonHelpEnabled = true;
    showStartScoolingButton = true;


    constructor(private platform: Platform,
                private navCtrl: NavController,
                private translate: TranslateService,
                public appData: AppData,
                private constants: Constants,
                private analytics: AnalyticsService,
                private activityService: ActivityService,
                private appManager: AppManager,
                private router: Router,
                private titleService: Title,
                private metaService: Meta,
                private logger: NGXLogger,
                private sharedUiService: SharedUiService,
                private route: ActivatedRoute,
                private zone: NgZone,
                private animationCtrl: AnimationController) {

        this.initializeApp();
        this.initializeTranslateServiceConfig();
        this.initializeHtmlMetadata();

        // title will be translated on the page
        const onboardingIncomplete = false;
        const userPrefs = JSON.parse(this.appData.getPreferenceString(this.constants.pref.LOGGED_USER_PREFERENCES));
        const onlyKidsContent = JSON.parse(this.appData.getPreferenceString(this.constants.pref.ONLY_KIDS_CONTENT));
        const basePages = [
            { title: 'subjects_catalog', segment: 'subjects', isMain: true, roles: ['educator'] },
            { title: 'my_videos', segment: 'my-videos', isMain: true, isDisabled: onboardingIncomplete },
            {
                title: 'learning_section',
                segment: null,
                isMain: true,
                isSubItemsOpen: false,
                subItems: [
                    { title: 'word_catalog_groups', segment: 'word-catalog-groups', isMain: false, roles: ['educator', 'student'] },
                    { title: 'word_catalog', segment: 'word-catalog', isMain: false, roles: ['public'] },
                    { title: 'flash_cards_groups', segment: 'flash-cards-groups', isMain: false, roles: ['educator', 'student'] },
                    { title: 'flash_cards', segment: 'flash-cards', isMain: false, roles: ['public'] }
                ]
            },
            { title: 'settings', segment: 'settings', isMain: true },
            { title: 'help_section', segment: 'help', isMain: true, roles: ['educator'] },
            {
                title: 'menu_uugotit_schooling',
                segment: null,
                isMain: true,
                isSubItemsOpen: false,
                roles: ['public'],
                subItems: [
                    { title: 'menu_login_scooling', segment: 'login_educator', isMain: false, roles: ['public'] },
                    { title: 'menu_login_student', segment: 'student', isMain: false, roles: ['public'] }
                ],
                isBottom: true
            }
        ];
        if (userPrefs && onlyKidsContent) {
            this.pages = [
                { title: 'video_catalog_kids', segment: 'catalog/t/kids', isMain: true },
                { title: 'about', segment: 'about', isMain: true },
                {
                    title: 'menu_account',
                    segment: 'account',
                    isMain: true,
                    roles: ['educator', 'student'],
                    isBottom: true
                },
                ...basePages,
            ];
        } else {
            this.pages = [
                { title: 'all_videos', segment: null, isMain: true, isSubItemsOpen: false, roles: ['public', 'student'], badge: 'all_videos_badge',
                    subItems: [
                        { title: 'ORF', segment: 'catalog/orftvthek', isMain: false, roles: ['public', 'student'] },
                        { title: 'NDR', segment: 'catalog/ndr', isMain: false, roles: ['student'] },
                        { title: 'ZDF', segment: 'catalog/zdfmediathek', isMain: false, roles: ['student'] },
                    ]
                },
                { title: 'video_library', segment: null, isMain: true, isSubItemsOpen: false, roles: ['educator'], badge: 'all_videos_badge',
                    subItems: [
                        { title: 'all_videos_badge', segment: 'catalog/all', isMain: false, roles: ['educator'] },
                        { title: 'ORF', segment: 'catalog/orftvthek', isMain: false, roles: ['educator'] },
                        { title: 'Health TV', segment: 'catalog/testtv', isMain: false, roles: ['educator'] },
                        { title: 'LT1', segment: 'catalog/lt1', isMain: false, roles: ['educator'] },
                        { title: 'NDR', segment: 'catalog/ndr', isMain: false, roles: ['educator'] },
                        { title: 'Planet Schule', segment: 'catalog/planetschule', isMain: false, roles: ['educator'] },
                        { title: 'Tirol TV', segment: 'catalog/tiroltv', isMain: false, roles: ['educator'] },
                        { title: 'W24', segment: 'catalog/w24', isMain: false, roles: ['educator'] },
                        { title: 'ZDF', segment: 'catalog/zdfmediathek', isMain: false, roles: ['educator'] },
                    ]
                },
                { title: 'categories', segment: null, isMain: true, isSubItemsOpen: false, badge: 'new' },
                ...basePages,
                // { title: 'kids', segment: 'catalog/t/kids', isMain: true },
                { title: 'about', segment: 'about', isMain: true },
                {
                    title: 'menu_account',
                    segment: 'account',
                    isMain: true,
                    roles: ['educator', 'student'],
                    isBottom: true
                },
            ];
        }
    }

    ngOnInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.logger.debug('NavigationEnd', event);
                if (!this.isFirstPageNavigation) {
                    this.isFirstPageNavigation = true;
                    this.doOnFirstPageLoad(event.url);
                    // SplashScreen.hide();
                }
                this.currentUrl = event.url;
                if (event.url.startsWith('/onboarding') || event.url.startsWith('/onboarding-unverified')) {
                    this.showStartScoolingButton = false;
                } else {
                    this.showStartScoolingButton = true;
                }
                this.logger.debug('currentUrl', this.currentUrl);
                // this.updatePagesDisable();
                setTimeout(() => {
                    this.checkMenuItemOpen(this.currentUrl);
                }, 300);
            }
        });

    }
    /**
     * Get a filtered list of menu items (pages).
     * @param isBottom if true only items where isBottom === true will be returned.
     * @returns the list of filtered menu items.
     */
    getPages(isBottom?: boolean): IMenuItem[] {
        return this.pages?.filter((p) => {
            if (isBottom === true) {
                return !!p.isBottom;
            } else if (isBottom === false) {
                return !p.isBottom;
            } else {
                return true;
            }
        });
    }

    /**
     * Initializes config of translation service
     */
    initializeTranslateServiceConfig() {
        this.systemLanguage = Utils.getSystemLanguage();

        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('en');

        let prefLanguage = this.appData.getLanguage();

        // Load the language of the logged-in user
        // We need to do this here, because if a page doesn't have the RouteGuard, then it won't overwrite the language later
        const authToken = this.appData.getPreferenceString(this.constants.pref.AUTH_TOKEN, null);
        if (authToken && !this.appManager.isTokenExpired(authToken)) {
            const prefsLoggedUser = JSON.parse(
                this.appData.getPreferenceString(this.constants.pref.LOGGED_USER_PREFERENCES, '{}')
            );
            const languageLoggedUser = prefsLoggedUser[this.constants.pref.LANGUAGE];
            if (languageLoggedUser) {
                if (languageLoggedUser === 'system') {
                    // the lang to use, if the lang isn't available, it will use the current loader to get them
                    prefLanguage = Utils.getSystemLanguage();
                } else {
                    prefLanguage = languageLoggedUser;
                }
            }
        }

        this.translate.use(prefLanguage);

        this.logger.info('Navigator language: ' + navigator.language + ', system language: ' + this.systemLanguage + ', pref language: ' + prefLanguage);
    }

    /**
     * Initializes application
     */
    async initializeApp() {
        await SplashScreen.show({});
        this.logger.info(`Running in environment ${environment.environmentName}`);

        let installId = this.appData.getPreferenceString(this.constants.pref.INSTALLATION_ID);
        if (!installId) {
            // Create a UUID that will be saved into local storage as a permanent INSTALLATION_ID
            installId = UUID.UUID();
            this.appData.savePreferenceString(this.constants.pref.INSTALLATION_ID, installId);
        }
        if (!this.appData.getPreferenceString(this.constants.pref.FIRST_LAUNCH_TIMESTAMP)) {
            this.appData.savePreferenceString(this.constants.pref.FIRST_LAUNCH_TIMESTAMP, new Date().toISOString());
        }
        // console.log('installId=' + installId);

        await this.platform.ready();

        this.logger.info('Ionic platform is ready');



        // Okay, so the platform is ready and our plugins are available.
        // Here you can do any higher level native things you might need.

        // StatusBar - see https://capacitorjs.com/docs/v3/apis/status-bar
        if (this.platform.is('capacitor')) {
            if (this.platform.is('android')) {
                StatusBar.setOverlaysWebView({ overlay: false });
            }
            this.setStatusBarStyleLight();
        }

        // console.log('window.location.hostname=' + window.location.hostname);

        if (this.platform.is('cordova')) {
            this.logger.debug(`Platform is cordova (${this.platform.platforms()})`);
            this.setPlatformListener();
        } else {
            this.logger.debug(`Platform is not cordova (${this.platform.platforms()})`);
        }
        // Initialize our events tracking
        // We have to do this once here even if platform is cordova:
        this.activityService.initializeEventsService();



        // Decide if we need to hide the menu or not
        this.appData.showMenu = !window.location.pathname.includes('/embed/');
        this.logger.info(`Showing menu: ${this.appData.showMenu}`);

        this.appData.pruneExpiredWatchedVideos();
        this.appData.pruneExpiredFavoredVideos();

        this.initializeBackgroundUpdateService();

        if (this.platform.is('capacitor')) {
            await FirebaseCrashlytics.setEnabled({ enabled: true });
        }
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            this.zone.run(() => {
                // Example url: https://app.uugot.it/verify
                const slug = event.url.split('.it').pop();
                if (slug) {
                    // Navigate to the route using only the slug
                    this.router.navigateByUrl(slug);
                } else {

                    // If no match, let regular routing logic take over
                    // You might want to handle this case differently based on your requirements
                }
            });
        });

    }


    async setStatusBarStyleLight() {
        if (this.platform.is('capacitor')) {
            await StatusBar.setStyle({ style: Style.Light });
            if (this.platform.is('android')) {
                await StatusBar.setBackgroundColor({ color: '#c8b900' }); // Dark yellow color from https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=ffeb3b
            }
        }
    }

    async setStatusBarStyleDark() {
        if (this.platform.is('capacitor')) {
            await StatusBar.setStyle({ style: Style.Dark });
            if (this.platform.is('android')) {
                await StatusBar.setBackgroundColor({ color: '#000000' });
            }
        }
    }

    async hideStatusBar() {
        if (this.platform.is('capacitor')) {
            await StatusBar.hide();
        }
    }

    async showStatusBar() {
        if (this.platform.is('capacitor')) {
            await StatusBar.show();
        }
    }

    setPlatformListener() {
        this.platform.pause.subscribe(() => { // background
            this.logger.debug('App in Background');
            this.activityService.eventsServiceUnsubscribe();
            this.backgroundUpdateSubscription.unsubscribe();
        });
        this.platform.resume.subscribe(() => { // foreground
            this.logger.debug('App in Foreground');
            this.activityService.initializeEventsService();
            this.initializeBackgroundUpdateService();
            this.checkRemoteConfigUpdate();
        });
    }

    initializeBackgroundUpdateService() {
        // Run it once after 1 second
        setTimeout(() => {
            this.appManager.executeBackgroundUpdates();
        }, 1_000);
        const intervalMs = environment.production ? 5_000 : 20_000;
        // Create a timer that runs ever 10 seconds
        this.backgroundUpdateSubscription = interval(intervalMs).subscribe((_) => {
            this.appManager.executeBackgroundUpdates();
        });
    }

    /**
     * Checks whether an update of the remote config is necessary.
     * This can happen if the app stays in memory for a longer period.
     */
    checkRemoteConfigUpdate() {
        const now = new Date();
        if (this.appData.remoteConfigLastFetch) {
            const deltaMinutes = (now.getTime() - this.appData.remoteConfigLastFetch.getTime()) / 1000 / 60;

            const maxHours = 6;
            const maxMinutes =  maxHours * 60;
            if (deltaMinutes >= maxMinutes) {
                this.logger.debug(`Remote config delta minutes: ${deltaMinutes}. Reloading (max: ${maxMinutes}).`);
                this.appData.loadInitialData().subscribe(_ => {
                    this.logger.info('Fetched initial data on resume');
                }, err => {
                    this.logger.warn('Error fetching initial data on resume', err);
                });
            } else {
                this.logger.debug(`Remote config delta minutes: ${deltaMinutes}. Not reloading (max: ${maxMinutes}).`);
            }
        }
    }

    initializeHtmlMetadata() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                const isProd = environment.environmentName === 'PROD';
                this.logger.debug('Resetting page title, production?', isProd ? 'yes' : 'no');
                this.titleService.setTitle('uugot.it');
                this.metaService.updateTag({ name: 'description', content: 'Learn languages with interactive subtitles while watching TV: uugot.it' });
                this.metaService.updateTag({ property: 'og:title', content: 'uugot.it' });
                this.metaService.updateTag({ property: 'og:description', content: 'Learn languages with interactive subtitles while watching TV: uugot.it' });
                this.metaService.updateTag({ property: 'og:image', content: this.appManager.appBaseUrl + '/assets/img/uugotit_default_share_image.png' });
                this.metaService.updateTag({ property: 'og:type', content: 'website' });
                this.metaService.removeTag('property="og:url"');

                // Disallow indexing of non-production pages
                this.metaService.updateTag({ name: 'robots', content: isProd ? 'index' : 'noindex' });
            }
        });
    }

    updatePagesDisable() {
        const user = this.appData.authenticatedUser;
        // @TODO verify well here
        // if (user && user.disable_all_menus) {
        if (user) {
            this.pages = this.pages.map(page => {
                const availableWhenOnboarding = ['settings', 'help_section', 'debug', 'menu_account', 'about', 'menu_uugotit_schooling'];
                if (availableWhenOnboarding.includes(page.title)) {
                    return {...page}
                }
                return {
                    ...page,
                    isDisabled: true
                }
            });
        }
    }
    doOnFirstPageLoad(url: string) {
        this.logger.debug(`doOnFirstPageLoad ${url} - user:`, this.appData.authenticatedUser);
        if (url && url.startsWith('/embed/')) {
            // Don't shop popup on embedded page (ReachAll)
            return;
        }
        this.sharedUiService.checkShowProfilePageRequired();
        this.setVideoCategoriesInMenu(this.appData.videoCategories);
        this.logger.debug(`doOnFirstPageLoad ${url} - videoCategories:`, this.appData.videoCategories);
        this.sharedUiService.checkShowAppStoreRedirect();
        // this.updatePagesDisable();
    }

    /**
     * Opens a page from the side menu
     * @param page The page to open
     */
    openPage(page: IMenuItem, $event?: any) {
        if (page.subItems) {
            // this.logger.debug('openPage()', page.title);
            this.toggleSubMenu(page.title, $event);
            $event?.stopPropagation();
            return;
        }

        if (this.webSources.includes(page.title)) {
            // Find all 'video_library' items and add a 'badge' property to each of them
            this.pages.forEach(item => {
                if (item.title === 'video_library' || item.title === 'all_videos') {
                    item.badge = page.title;
                }
            });
        }

        if (!page.segment) {
            return;
        }
        if (page.title !== 'categories' && !page.segment.startsWith('category/')) {
            // if (this.isCatogriesShown) {
            //     this.toggleCategoriesInMenu($event);
            // }
        }

        this.appData.activeGroupId = '';
        this.navCtrl.navigateRoot(page.segment);
    }

    /**
     * Opens a group (aka class) details page for educators (from the side menu)
     *
     * @param group A class
     */
    openEducatorGroup(group: IGroup) {
        this.appData.activePage = '';
        this.appData.activeGroupId = group._id;
        this.navCtrl.navigateRoot(`educator-class/details/${group._id}`);
    }

    /**
     * Opens a group (aka class) details page for students (from the side menu)
     *
     * @param group A class
     */
    openStudentGroup(group: IGroup) {
        this.appData.activePage = '';
        this.appData.activeGroupId = group._id;
        this.navCtrl.navigateRoot(`student-class/details/${group._id}`);
    }

    /**
     * Opens word catalog for the given class
     *
     * @param group The class
     */
    openClassWordCatalog(group: IGroup) {
        this.appData.activePage = `word-catalog/${group._id}`;
        this.navCtrl.navigateRoot(`word-catalog/${group._id}`);
    }

    /**
     * Opens flash cards for the given class
     *
     * @param group The class
     */
    openClassFlashCards(group: IGroup) {
        this.appData.activePage = `flash-cards/${group._id}`;
        this.navCtrl.navigateRoot(`flash-cards/${group._id}`);
    }

    /**
     * Returns true if the current user is authenticated and has the role 'Educator'
     */
    isEducator(): boolean {
        return this.appData.isEducator();
    }

    /**
     * If roles is null or undefined, then this will return true.
     * @param roles return true of the current user has a role that is in the given roles array.
     */
    isRoleAllowed(roles: Array<string>): boolean {
        if (!roles) {
            return true;
        }
        if (!this.appData.isLoggedIn()) {
            return roles.includes('public');
        }
        return roles.includes(this.appData.authenticatedUser.role);
    }

    /**
     * Returns true if the current user is authenticated
     */
    isLoggedIn(): boolean {
        return this.appData.isLoggedIn();
    }

    /**
     * Returns true if the current user is not logged in as an 'Educator' and has opened classes
     */
    isStudent(): boolean {
        return this.appData.isStudent();
    }

    /**
     * Navigates user to class create page
     */
    openClassCreatePage() {
        this.navCtrl.navigateRoot('educator-class/new');
    }

    /**
     * Navigates to student login page
     */
    openStudentLoginPage() {
        this.appData.activeGroupId = '';
        this.navCtrl.navigateRoot('student');
    }

    /**
     * Opens educator login page
     */
    openEducatorLoginPage() {
        this.appData.activeGroupId = '';
        this.navCtrl.navigateRoot('login');
    }

    async bottomFloatingButtonClicked() {
        const type = this.isBottomFloatingButtonShown();
        switch (type) {
            case BottomFloatingButtonType.educator_help:
                this.navCtrl.navigateRoot('help');
                this.bottomFloatingButtonHelpEnabled = false;
                break;
            case BottomFloatingButtonType.educator_invite_colleagues:
                this.sharedUiService.showInviteEducatorsPage(true, true);
                break;
            case BottomFloatingButtonType.feedback_form_bka_2021: {
                const language = this.appData.getPreferenceString(
                    this.constants.pref.TRANSLATION_LANG,
                    this.appData.constants.DefaultTranslationLang
                ) as ILanguages;
                const urls = {
                    ar:
                        'https://docs.google.com/forms/d/e/1FAIpQLScmt4XQ0J25VVep5Gya2E3X1dMZSLwDtQTLtzDrxstSVRsl9A/viewform?usp=sf_link',
                    de:
                        'https://docs.google.com/forms/d/e/1FAIpQLSfb3gz5XiMvUdzK51AA4OfhjtngJxrFIhh1l3AFAucHzH3goA/viewform?usp=sf_link',
                    en:
                        'https://docs.google.com/forms/d/e/1FAIpQLScrL16iXkxJ0iSZlzNlNjKl7lIuPdjlzCuSAcgGBtI_WS02Hw/viewform?usp=sf_link',
                    es:
                        'https://docs.google.com/forms/d/e/1FAIpQLSdzAQ3Yn8Ctn8cbco-9XuOUTC-i5cf5L5d0NgX9L02QyJm7Mw/viewform?usp=sf_link',
                    bs:
                        'https://docs.google.com/forms/d/e/1FAIpQLSfGz3KCOVPBT5Px4HksnAkTnpYenNjWrFoAews8yHceu-1dTA/viewform?usp=sf_link',
                    fa:
                        'https://docs.google.com/forms/d/e/1FAIpQLSc8KDJ7ilQu-U-RlgDam1_yx0h2ubPk6tBxSgtC9GW32O8P_g/viewform?usp=sf_link',
                    fr:
                        'https://docs.google.com/forms/d/e/1FAIpQLScMYg_C7c29QDI8eQj7qn-dB7epfqTDX5kiPk2Vb6w0o8768Q/viewform?usp=sf_link',
                    it:
                        'https://docs.google.com/forms/d/e/1FAIpQLSc_9Oq-y6ae7yAnbBtYmmjvLbDJDHlQEPpJzhid34xk2lsm0g/viewform?usp=sf_link',
                    hr:
                        'https://docs.google.com/forms/d/e/1FAIpQLSflWD3s0qbZywnBuKvsICE5f6YqMsHF0JQYijCec51O4zYHlg/viewform?usp=sf_link',
                    pt:
                        'https://docs.google.com/forms/d/e/1FAIpQLSeEm6BZvQVJ4GafXDUu_YNEiJ8oovN1nG8AXWBPAaYcBqoacg/viewform?usp=sf_link',
                    ro:
                        'https://docs.google.com/forms/d/e/1FAIpQLSfb3gz5XiMvUdzK51AA4OfhjtngJxrFIhh1l3AFAucHzH3goA/viewform?usp=sf_link', // <-- this is the German form
                    ru:
                        'https://docs.google.com/forms/d/e/1FAIpQLSeBOZZX5OZK7YKbA3eVCbOxs3i4y_mnHMVHL7KC3sjgQEG1VQ/viewform?usp=sf_link',
                    sr:
                        'https://docs.google.com/forms/d/e/1FAIpQLSfoUd5JK3X4_ydRgSEBxAwMStaaTU65R1jSRhnraVYSQ56fJQ/viewform?usp=sf_link',
                    so:
                        'https://docs.google.com/forms/d/e/1FAIpQLSe0oESJNlwjJhzwWt8hPE5rC2LeKXghTuiuUWDFBHAXEH2aLw/viewform?usp=sf_link',
                };
                const formUrl = urls[language] || urls.en;
                await Browser.open({ url: formUrl, toolbarColor: '#ffeb3b' });
                this.appData.savePreferenceString(
                    this.constants.pref.FEEDBACK_BUTTON_CLICKED_BKA2021,
                    new Date().toISOString()
                );
                this.logger.debug('Feedback form URL:', language, formUrl);
                this.analytics.trackAnalyticsEvent(
                    AnalyticsCategory.Misc,
                    'fbf_btn_click',
                    language
                );
                break;
            }
            default:
                break;
        }
    }

    async bottomFloatingButtonCloseClicked() {
        const buttonShown = this.isBottomFloatingButtonShown();
        switch (buttonShown) {
            case BottomFloatingButtonType.feedback_form_bka_2021:
                this.appData.savePreferenceString(
                    this.constants.pref.FEEDBACK_BUTTON_CLICKED_BKA2021,
                    new Date().toISOString()
                );
                break;
            default:
                break;
        }
    }

    isBottomFloatingButtonShown(): null | BottomFloatingButtonType {
        if (this.currentUrl && this.currentUrl.startsWith('/reachall/')) {
            return null;
        }
        if (!this.isEducator()) {
            if (!this.isStudent()) {
                const feedbackButtonClicked = this.appData.getPreferenceString(
                    this.constants.pref.FEEDBACK_BUTTON_CLICKED_BKA2021
                );
                const firstLaunchTimestamp = this.appData.getPreferenceString(
                    this.constants.pref.FIRST_LAUNCH_TIMESTAMP
                );
                const watchedVideos = this.appData.getWatchedVideos() || [];

                const minUsageInDays = 3; // How many days the app needs to be used
                const minWatchedVideos = 3; // How many videos need to be
                let daysSinceFirstLaunch = moment().diff(firstLaunchTimestamp, 'days');

                if (daysSinceFirstLaunch < minUsageInDays) {
                    const firstWatchedVideo = _(watchedVideos)
                        .filter((w) => w.createdAt != undefined)
                        .orderBy('createdAt', 'asc')
                        .first();

                    if (firstWatchedVideo && firstWatchedVideo.createdAt) {
                        const momentFirstWatchedVideo = moment(firstWatchedVideo.createdAt);
                        if (momentFirstWatchedVideo.isBefore(firstLaunchTimestamp)) {
                            daysSinceFirstLaunch = moment().diff(momentFirstWatchedVideo, 'days');
                            // this.logger.debug(
                            //     `isBottomFloatingButtonShown using first watched video`
                            // );
                        }
                    }
                }

                // this.logger.debug(
                //     `feedbackButtonClicked ${feedbackButtonClicked}, daysSinceFirstLaunch ${daysSinceFirstLaunch}, watchedVideos.length ${watchedVideos.length}, currentUrl ${this.currentUrl}`
                // );

                // this.logger.debug('oldestWatchedVideo', oldestWatchedVideo);

                const isCountryAT = ['AT', 'ZZ'].includes(this.appData.geoIp?.country);

                // this.logger.debug('IS COUNTRY AUSTRIA?', isCountryAT, this.appData.geoIp?.country);

                const showFeedbackButton =
                    !feedbackButtonClicked &&
                    ['/', '/catalog', '/word-catalog'].includes(this.currentUrl) &&
                    daysSinceFirstLaunch >= minUsageInDays &&
                    watchedVideos.length >= minWatchedVideos &&
                    isCountryAT;

                if (showFeedbackButton) {
                    return BottomFloatingButtonType.feedback_form_bka_2021;
                }
            }
            return null;
        }
        const momentFirstLogin = moment(this.appData.authenticatedUser.first_login);
        const daysSinceFirstLogin = moment().diff(momentFirstLogin, 'days');
        // this.logger.debug(`daysSinceFirstLogin ${daysSinceFirstLogin}`);
        if (daysSinceFirstLogin > 30) {
            // Show the "invite colleagues" button
            if (!this.currentUrl || this.currentUrl.startsWith('/embed/') || this.currentUrl.startsWith('/video/')) {
                return null;
            }
            return BottomFloatingButtonType.educator_invite_colleagues;
        } else {
            if (!this.currentUrl || this.currentUrl === '/help' || this.currentUrl.startsWith('/embed/') || this.currentUrl.startsWith('/video/')) {
                return null;
            }
            return this.bottomFloatingButtonHelpEnabled ? BottomFloatingButtonType.educator_help : null;
        }
    }

    isBottomFloatingButtonCloseShown(): boolean {
        return this.isBottomFloatingButtonShown() === BottomFloatingButtonType.feedback_form_bka_2021;
    }

    bottomFloatingButtonText() {
        const type = this.isBottomFloatingButtonShown();
        switch (type) {
            case BottomFloatingButtonType.educator_help:
                return this.translate.instant('btn_start_with_schooling');
            case BottomFloatingButtonType.educator_invite_colleagues:
                return this.translate.instant('btn_invite_colleagues');
            case BottomFloatingButtonType.feedback_form_bka_2021:
                return this.translate.instant('btn_your_feedback');
            default:
                return '';
        }
    }


    /**
     * Returns the number of unwatched videos in a group.
     * The groups must have an array of IGroupVideos at the `videos` property.
     * @param group the class
     */
    getUnwatchedVideosCount(group: IGroup): number {
        // this.logger.debug('getUnwatchedVideosCount group', group._id);
        if (group.videos) {
            const watchedVideos = this.appData.getWatchedVideos();
            if (!watchedVideos) {
                return 0;
            }
            const count = group.videos.filter(video => {
                // Return true if video from group is not contained in watched videos
                return !watchedVideos.find(w => {
                    return w.videoId === video.video_id._id && w.watchedUntil > 10;
                }) && video.is_visible && video.subjects && video.subjects.length > 0
            }).length;
            // this.logger.debug('getUnwatchedVideosCount count and videos', count, watchedVideos);
            return count;
        } else {
            return 0;
        }
    }

    /**
     * Returns true if the given page (segment) is the current page.
     * If there is no exact match for activePage, then we look if the first part of the activePage matches the segement.
     * @param segment for example 'catalog'
     */
    isActivePage(segment: string): boolean {
        // this.logger.debug('isActivePage', this.appData.activePage, segment);
        if (this.appData.activePage === segment) {
            return true;
        }
        if (this.appData.activePage && !this.pages.find(p => p.segment === this.appData.activePage)) {
            const pathSplit = this.appData.activePage.split('/');
            return pathSplit[0] === segment;
        }
        return false;
    }

    setVideoCategoriesInMenu(categories: IVideoCategory[]) {
        const categoriesMenuItem = this.pages.find((p) => p.title === 'categories');
        if (!categoriesMenuItem) {
            return;
        }
        const subItems: IMenuItem[] = _(categories)
            .filter((category) => {
                return !['other'].includes(category.slug);
            })
            .map((category) => {
                return {
                    title: `video_cat_${category._id}`,
                    roles: categoriesMenuItem.roles,
                    segment: `category/${category.slug}`,
                    isMain: false,
                    titleI18n: this.translate.instant(`video_cat_${category._id}`),
                } as IMenuItem;
            })
            .sortBy(['titleI18n'])
            .value();
        categoriesMenuItem.subItems = subItems;
        this.logger.debug('addVideoCategoriesToMenu() added sub menu items', subItems);
    }

    isSubMenuShown(menuItemTitle: string): boolean {
        return this.pages.find(p => p.title === menuItemTitle)?.isSubItemsOpen;
    }

    checkMenuItemOpen(currentUrl: string) {
        this.pages.forEach(page => {
            if (page.subItems) {
                page.subItems.forEach(subItem => {
                    if (currentUrl === subItem.segment || currentUrl === `/${subItem.segment}`) {
                        if (!this.isSubMenuShown(page.title)) {
                            this.toggleSubMenu(page.title);
                        }
                    }
                });
            }
        });
    }

    toggleSubMenu(menuItemTitle: string, event?: any) {
        const menuItem = this.pages.find(p => p.title === menuItemTitle);
        if (!menuItem) {
            this.logger.debug('toggleSubMenu() unknwon menu item:', menuItemTitle);
            return;
        }
        this.logger.debug('toggleSubMenu()', menuItem.isSubItemsOpen);
        const containerEl = document.querySelector(`.submenuContainer_${menuItem.title}`);
        const chevronButtonEl = document.querySelector(`.submenuOpenCloseIndicator_${menuItem.title}`);
        if (!containerEl) {
            this.logger.debug('toggleSubMenu() container not available');
            return;
        }
        // this.logger.debug('toggleSubMenu() container', containerEl.scrollHeight, chevronButtonEl);

        let animation;
        if (menuItem.isSubItemsOpen) {
            // Hide
            const animation1 = this.animationCtrl.create('menu-categories-container')
                .addElement(containerEl)
                .fromTo('opacity', '100%', '30%')
                .fromTo('height', `${containerEl.scrollHeight}px`, '0px')
                .easing('ease-in-out');
            const animation2 = this.animationCtrl.create('categories-open-close')
                .addElement(chevronButtonEl)
                .fromTo('transform', 'rotate(90deg)', 'rotate(0deg)');
            animation = this.animationCtrl.create()
                .duration(400)
                .iterations(1)
                .addAnimation([animation1, animation2])
                .onFinish(() => {
                    menuItem.isSubItemsOpen = !menuItem.isSubItemsOpen;
                    // this.logger.debug('toggleSubMenu() animation finished (hide)');
                });
        } else {
            // Show
            const animation1 = this.animationCtrl.create('menu-categories-container')
                .addElement(containerEl)
                .fromTo('opacity', '30%', '100%')
                .fromTo('height', '0px', `${containerEl.scrollHeight}px`)
                .easing('ease-in-out');
            const animation2 = this.animationCtrl.create('categories-open-close')
                .addElement(chevronButtonEl)
                .fromTo('transform', 'rotate(0deg)', 'rotate(90deg)');
            animation = this.animationCtrl.create()
                .duration(400)
                .iterations(1)
                .addAnimation([animation1, animation2])
                .onFinish(() => {
                    menuItem.isSubItemsOpen = !menuItem.isSubItemsOpen;
                    // this.logger.debug('toggleSubMenu() animation finished (show)');
                });
        }

        animation.play();
    }
}
