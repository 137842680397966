<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'dark' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-button (click)="dismiss()">
                <ion-icon slot="icon-only" name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>{{'add_existing_tasks' | translate}}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="add-task-modal-content">
    <div *ngIf="tasks && tasks.length === 0" class="ion-margin ion-text-center alert alert-info">
        {{'no_further_tasks_for_this_video' | translate}}
    </div>
    <ion-list *ngIf="tasks">
        <ion-item *ngFor="let task of tasks; index as i">
            <ion-label>
                <h2 [title]="task.question">{{task.question}}</h2>
                <div [title]="taskAnswersToString(task)"
                    [innerHTML]="'(' + numberOfCorrectAnswers(task) + '/' + task.answers.length + ') ' + taskAnswersToString(task, 'correct-answer')">
                </div>
                <p class="add-task-list-bottom">
                    <ng-container [ngSwitch]="task.type">
                        <ion-icon name="checkmark-circle"></ion-icon>
                        <span *ngSwitchCase="taskTypeEnum.multiple_choice">
                            {{'task_type_1' | translate}}
                        </span>
                        <span *ngSwitchCase="taskTypeEnum.survey">
                            {{'task_type_2' | translate}}
                        </span>&nbsp;|
                    </ng-container>
                    <ion-icon name="pin"></ion-icon>
                    {{uiUtils.secondsToTimeString(task.position - (clip?.cropStart || 0), true)}}
                    &nbsp;|&nbsp;
                    <span *ngIf="task.createdBy">
                        <ion-icon name="person"></ion-icon>&nbsp;
                        <ng-container
                            *ngIf="task.createdBy === appData.authenticatedUser._id || task.createdBy._id === appData.authenticatedUser._id; else notMyUser">
                            {{'you' | translate}}
                        </ng-container>
                        <ng-template #notMyUser>
                            <ng-container *ngIf="task.createdBy.first_name">
                                {{task.createdBy.first_name}}
                            </ng-container>
                            <ng-container *ngIf="task.createdBy.last_name">
                                {{task.createdBy.last_name.substring(0, 1)}}.
                            </ng-container>
                            <ng-container *ngIf="!task.createdBy.first_name && !task.createdBy.last_name">
                                {{task.createdBy.username}}
                            </ng-container>
                        </ng-template>
                    </span>
                </p>
            </ion-label>
            <ion-button slot="end" size="medium" fill="solid" (click)="onAddAnswerClicked(task)"
                *ngIf="!task || !excludeTaskIds.includes(task._id)">
                <ion-icon slot="start" name="add"></ion-icon>
                {{'add' | translate}}
            </ion-button>
            <ng-container *ngIf="task && excludeTaskIds.includes(task._id)">
                <ion-icon slot="end" name="checkmark" ioniconRemoveTitle iconTitle="{{ 'task_already_added_to_clip' | translate }}"></ion-icon>
            </ng-container>
        </ion-item>
    </ion-list>
</ion-content>
