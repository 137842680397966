import { Component, Input, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AppData } from 'src/app/services/app-data.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ModalController } from '@ionic/angular';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { TasksApi } from 'src/app/services/api/tasks.service';
import { ITask, ETaskType } from 'src/app/interfaces/ITask';
import { IVideo } from 'src/app/interfaces/IVideo';
import * as _ from 'lodash';
import { ISingleObjectResponse } from '../../../models/dtos';
import { ETaskAgeRange, ETaskEduLevel } from '../../../interfaces/ITask';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { IClip } from 'src/app/interfaces/IClip';

export interface IVideoPlayerEditQuestionPageResult {
    isSaved: boolean;
    savedTask?: ITask;
}

/**
 * Component to create or edit a task.
 */
@Component({
    selector: 'app-video-player-edit-question',
    templateUrl: './video-player-edit-question.page.html',
    styleUrls: ['./video-player-edit-question.page.scss'],
})
export class VideoPlayerEditQuestionPage implements OnInit {
    public form: UntypedFormGroup;
    public answerOptions: UntypedFormArray;

    @Input() public position: number;
    @Input() public video: IVideo;
    @Input() public task: ITask;
    @Input() public clip: IClip;

    taskTypeEnum: typeof ETaskType = ETaskType;
    taskAgeRangeEnum: typeof ETaskAgeRange = ETaskAgeRange;
    taskEduLevelEnum: typeof ETaskEduLevel = ETaskEduLevel;

    public readonly maxLengthQuestionField = 140;
    public readonly maxLengthAnswerField = 60;

    constructor(
        private analytics: AnalyticsService,
        public appData: AppData,
        private logger: NGXLogger,
        private modalCtrl: ModalController,
        private formBuilder: UntypedFormBuilder,
        public uiUtils: UiUtils,
        public tasksApi: TasksApi,
        public translate: TranslateService,
    ) {
        // Do nothing.
    }

    ngOnInit() {
        document.body.classList.toggle('dark', true);
        this.logger.debug(`VideoPlayerEditQuestionPage.ngOnInit() position=${this.position}, clip crop start=${this.clip?.cropStart}`);
        this.initForm();
    }

    initForm() {
        this.answerOptions = new UntypedFormArray([]);

        if (this.task) {
            this.position = this.task.position;
            if (!_.isNil(this.clip?.cropStart)) {
                // Add crop start seconds
                this.position -= this.clip.cropStart;
            }
        }
        const time = moment.utc(this.position * 1000).format('HH:mm:ss');

        this.form = this.formBuilder.group({
            _id: [this.task?._id],
            question: [
                this.task?.question || '',
                Validators.compose([Validators.required, Validators.maxLength(this.maxLengthQuestionField)]),
            ],
            answers: this.answerOptions,
            // isSurvey: [false],
            video_id: [this.task?.video_id || this.video._id],
            type: [this.task?.type || ETaskType.multiple_choice, Validators.required],
            position: [this.position],
            showFeedback: [true],
            title: ['', Validators.maxLength(100)],
            level: [this.task?.level],
            age: [this.task?.age],
            eduLevel: [this.task?.eduLevel],
            time: [time, Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/)],
            visib: 0, // only in class/clip
        });

        if (this.task?.answers) {
            this.task.answers.forEach((task, i) => {
                this.addAnswerOptionField(i < 2, task.text, task.isCorrect, task._id);
            });
        } else {
            this.addAnswerOptionField(true);
            this.addAnswerOptionField(true);
        }
    }

    addAnswerOptionField(isRequired = false, text = '', isCorrect = false, _id?: string) {
        const validators = [Validators.minLength(1), Validators.maxLength(this.maxLengthAnswerField)];
        if (isRequired) {
            validators.push(Validators.required);
        }
        this.answerOptions.push(
            new UntypedFormGroup(
                {
                    text: new UntypedFormControl(text, validators),
                    isCorrect: new UntypedFormControl(isCorrect),
                    order: new UntypedFormControl(this.answerOptions.length + 1),
                    _id: new UntypedFormControl(_id),
                },
                validators
            )
        );
    }

    removeAnswerOptionField(index: number) {
        this.answerOptions.removeAt(index);
    }

    dismiss(isSaved = false, savedTask?: ITask) {
        document.body.classList.toggle('dark', false);
        const resultData: IVideoPlayerEditQuestionPageResult = {
            isSaved,
            savedTask,
        };
        this.modalCtrl.dismiss(resultData);
    }

    onSubmitForm() {
        // TODO
    }

    async onSaveClicked() {
        try {
            this.logger.debug('Form value:', this.form.value);
            // this.dismiss(true);
            let response: ISingleObjectResponse<ITask>;
            if (this.task) {
                // Save task
                const time = this.form.value.time;
                const task = this.form.value as ITask;
                this.prepareTaskBeforeSave(task, time);
                response = await this.tasksApi.updateTask(task).toPromise();
                this.logger.debug('Update task response:', response);
                this.uiUtils.displayToast(response.msg);
            } else {
                // Create task
                const time = this.form.value.time;
                const task = this.form.value as ITask;
                this.prepareTaskBeforeSave(task, time);
                response = await this.tasksApi.addTask(task).toPromise();
                this.logger.debug('Add task response:', response);
            }
            this.uiUtils.displayToast(response.msg);
            this.dismiss(true, response.data);
        } catch (err) {
            console.dir(err);
            this.logger.error('Error saving task', err.error?.message || err.message || err);
            this.uiUtils.showErrorAlert(
                `The task could not be saved [${err.error?.message || err.message || err}]`
            );
        }
    }

    /**
     * Prepares the task before creating or updating
     * @param task the Task object
     */
    private prepareTaskBeforeSave(task: ITask, time?: string) {
        task.title = task.question;
        task.answers.forEach((answer) => {
            if (!answer._id) {
                delete answer._id;
            }
        });
        if (!task._id) {
            delete task._id;
        }
        if (time) {
            const timeMoment = moment(time, 'HH:mm:ss');
            const postionChanged = this.form.get('time').dirty;

            // Only update position if user changed it manually
            if (!task._id || postionChanged) {
                task.position =
                    timeMoment.get('hours') * 3600 +
                    timeMoment.get('minutes') * 60 +
                    timeMoment.get('seconds');
            }

            if (!_.isNil(this.clip?.cropStart)) {
                // Add crop start seconds
                task.position += this.clip.cropStart;
            }
        }
    }

    /**
     * Returns true if the save button should be enabled.
     * If the type of task is multiple choice and there is no answer selected, then saving is not enabled.
     * See https://gitlab.com/uugotitTeam/uugot.it/-/issues/347
     */
    get isSaveEnabled() {
        if (!this.form.valid) {
            return false;
        }
        if (this.form.get('type').value == ETaskType.multiple_choice) {
            const selectedAnswers = this.answerOptions.controls.filter((control) => {
                const isCorrect = control.get('isCorrect').value;
                return isCorrect;
            }).length;
            if (selectedAnswers === 0) {
                return false;
            }
        }
        return true;
    }
}
