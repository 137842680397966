<div id="video-overlay-leaderboard">
    <div class="video-overlay-leaderboard-container" *ngIf="clip?.leaderboard && leaderboardOverlayShown">
        <div class="video-overlay-leaderboard-top-container" (click)="close()">
            <div class="video-overlay-leaderboard-top-closebutton" (click)="close()">
                <ion-icon name="close"></ion-icon>
            </div>
            <div class="video-overlay-leaderboard-top-title">
                <img class="leaderboard-title-flag" src="assets/img/leaderboard/finish_flag_grey_stick.png"
                    alt="flag" />
                <span>Top-{{topCountAsString()}} {{ "leaderboard" | translate }}</span>
                <img class="leaderboard-title-flag" src="assets/img/leaderboard/finish_flag_grey_stick.png"
                    alt="flag" />
            </div>
        </div>
        <div class="video-overlay-leaderboard-content">

            <div class="scrollable-container">

                <table>
                    <ng-container *ngFor="let entry of clip.leaderboard.entries; let i = index">
                        <tr *ngIf="i < maxShownEntries" [class.leaderboard-own-rank-row]="isMyOwnRank(entry)"
                            [class.leaderboard-entry-hidden]="!entry.isEntryShown"
                            [class.leaderboard-entry-all-shown]="isTableHeaderShown()">
                            <!-- Only show first 10 entries -->
                            <td class="leaderboard-pos-cell">{{entry.pos}}.</td>
                            <td class="leaderboard-name-cell">{{entry.first_name}} {{entry.last_name.toUpperCase()}}</td>
                            <td class="leaderboard-score-cell" [class.leaderboard-score-green]="entry.score >= 85"
                                [class.leaderboard-score-yellow]="entry.score >= 65 && entry.score < 85"
                                [class.leaderboard-score-orange]="entry.score >= 50 && entry.score < 65"
                                [class.leaderboard-score-red]="entry.score < 50">
                                {{entry.score}}%</td>
                            <td class="leaderboard-medal-cell">
                                <ng-container *ngIf="entry.award1">
                                    <img class="leaderboard-medal-image" src="assets/img/leaderboard/medal_1_gold.png"
                                        alt="gold medal" />
                                </ng-container>
                                <ng-container *ngIf="entry.award2">
                                    <img class="leaderboard-medal-image" src="assets/img/leaderboard/medal_2_silver.png"
                                        alt="silver medal" />
                                </ng-container>
                                <ng-container *ngIf="entry.award3">
                                    <img class="leaderboard-medal-image" src="assets/img/leaderboard/medal_3_bronze.png"
                                        alt="bronze medal" />
                                </ng-container>
                            </td>
                        </tr>
                    </ng-container>
                </table>
            </div>

        </div>
    </div>
</div>
