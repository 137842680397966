<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-menu-toggle>
                <ion-button>
                    <ion-icon slot="icon-only" name="menu"></ion-icon>
                </ion-button>
            </ion-menu-toggle>
        </ion-buttons>
        <ion-title>
            {{ headerTitle }}
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>

    <ion-progress-bar type="indeterminate" *ngIf="showLoadingIndicator"></ion-progress-bar>

    <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
        <ion-refresher-content pullingText="{{ 'pull_to_refresh' | translate }}"
            refreshingText="{{ 'reloading_videos' | translate }}"></ion-refresher-content>
    </ion-refresher>

    <ion-grid>
        <!-- <ion-searchbar *ngIf="appData.isEducator()" padding-left padding-right placeholder="{{ 'search' | translate }}"></ion-searchbar> -->
        <ion-row>
            <!-- Spacial card: videos with my tasks: -->
            <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="4" class="ion-no-padding">
                <ion-card [routerLink]="['/catalog/sp/my-tasks']">
                    <div class="subject-card-background-overlay">
                        <div class="subject-card-title">
                            <span>
                                {{'videos_special_my-tasks' | translate }}
                            </span>
                        </div>
                    </div>
                </ion-card>
            </ion-col>

            <!-- List all subjects: -->
            <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="4" *ngFor="let subject of subjects" class="ion-no-padding">
                <ion-card [routerLink]="['/catalog/s/' + subject.slug]" [style.background-image]="subject.isGeneric ? '' : 'url(/assets/img/school-subjects/subjectcover_' + subject.slug + '.jpg)'">
                    <!--<img src="https://uugotitlive.blob.core.windows.net/hot-topics/hot_topic_COVID-19.jpg" alt="Alle Meldungen zum Corona Virus"/>
                    <ion-item lines="none">
                        <ion-label class="ion-text-wrap">
                            <ion-card-title>{{subject.slug}}</ion-card-title>
                            <ion-label>
                                <span class="video-category-chip video-category-chip-inverse"><ion-icon name="flame"></ion-icon> {{'hot_topic' | translate}}</span>
                                <span class="text-content">
                                    {{subject._id}}
                                </span>
                            </ion-label>
                        </ion-label>

                    </ion-item>-->
                    <div class="subject-card-background-overlay">
                        <div class="subject-card-title">
                            <span *ngIf="subject.name">
                                {{subject.name[language] || subject.name['en']}}
                            </span>
                        </div>
                    </div>
                </ion-card>
            </ion-col>

            <!-- Spacial card: videos with my tasks: -->
            <ion-col size="12" size-sm="12" size-md="6" size-lg="6" size-xl="4" class="ion-no-padding">
                <ion-card [routerLink]="['/catalog/sp/no-subject-with-tasks']">
                    <div class="subject-card-background-overlay">
                        <div class="subject-card-title">
                            <span>
                                {{'videos_special_no-subject-with-tasks' | translate }}
                            </span>
                        </div>
                    </div>
                </ion-card>
            </ion-col>

        </ion-row>
    </ion-grid>

</ion-content>
