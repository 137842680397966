// angular
import { Component, ViewChild, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';

// ionic
import {
    LoadingController,
    AlertController,
    ModalController,
    ToastController,
    NavController,
    Platform,
} from '@ionic/angular';

// services
import { Constants } from '../../app.constants';
import { AppData } from 'src/app/services/app-data.service';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AnalyticsService, AnalyticsCategory } from 'src/app/services/analytics.service';
import { AppManager } from 'src/app/services/app-manager.service';

// models
import { zip, Subscription } from 'rxjs';

// libraries
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';

import { SubjectsApi } from '../../services/api/subjects.service';
import { ISubject } from 'src/app/interfaces/ISubject';

@Component({
    selector: 'subjects-catalog-catalog',
    templateUrl: 'subjects-catalog-page.html',
    styleUrls: ['subjects-catalog-page.scss'],
})
export class SubjectsCatalogPage implements OnInit {
    protected offset = 0;
    protected pageSize = 50;

    private lastReload: Date;

    private subscription: Subscription;
    public headerTitle: string;

    public subjects: ISubject[];

    public showLoadingIndicator = true;

    public language: string;

    constructor(
        public navCtrl: NavController,
        public appData: AppData,
        public subjectsApi: SubjectsApi,
        public constants: Constants,
        public loadingCtrl: LoadingController,
        public alertCtrl: AlertController,
        public toastCtrl: ToastController,
        public uiUtils: UiUtils,
        public analytics: AnalyticsService,
        public translate: TranslateService,
        public modalCtrl: ModalController,
        public platform: Platform,
        protected appManager: AppManager,
        protected titleService: Title,
        protected logger: NGXLogger,
        protected _route: ActivatedRoute,
        protected router: Router
    ) {
        // Do nothing.
    }

    ionViewWillLoad() {
        this.logger.debug('ionViewWillLoad');
    }

    ngOnInit() {
        this.logger.debug('ngOnInit');
        this.titleService.setTitle('Subjects | uugot.it');
        this.analytics.trackPageView('subjects-catalog');
        this.language = this.appData.getLanguage();
        this.logger.debug('language', this.language);
    }

    ionViewWillEnter() {
        this.logger.debug('ionViewWillEnter');
        this.appData.activePage = 'subjects';

        this.headerTitle = this.translate.instant('subjects_catalog');

        if (this.shouldReload()) {
            this.offset = 0;
            this.subjects = null;
            this.showLoadingIndicator = true;
            this.loadSubjects();
        }
    }

    // Workaround for https://gitlab.com/uugotitTeam/webapp/issues/172
    ionViewDidEnter() {
        this.subscription = this.platform.backButton.subscribe(() => {
            navigator['app'].exitApp();
        });
    }

    ionViewWillLeave() {
        this.subscription.unsubscribe();
    }

    /**
     * Loads next videos in the video catalog
     */
    async loadSubjects() {
        let country = this.appData.geoIp?.country;
        if (country === 'ZZ') {
            // when running on localhost for example
            country = 'AT';
        }

        // Combine the two requests
        const apiRequests = zip(
            // Load subjects of the day:
            this.subjectsApi.getSubjects(this.offset, this.pageSize, `isGeneric name.${this.language} name.en`, country, 1)
        );
        try {
            const data = await apiRequests.toPromise();
            // Destructure array
            const [subjectsResponse] = data;

            this.logger.debug(`Reload data got ${subjectsResponse.data.length} videos`);

            if (this.offset === 0) {
                // Clear videos, in case of pull-to-refresh
                this.subjects = [];
            }
            this.addSubjects(subjectsResponse.data);
            this.offset += this.pageSize;
            this.analytics.trackAnalyticsEvent(AnalyticsCategory.SubjectsCatalog, 'loaded');
        } catch (err) {
            this.logger.error('Error reloading data: %s', err.message, err);
            if (err.status !== this.constants.HTTP_STATUS_CODE.UNAUTHORIZED) {
                const reloadHandler = async () => {
                    this.logger.debug('user wants to reload');
                    await this.loadSubjects();
                };
                this.uiUtils.showErrorAlert(err.error.message, null, reloadHandler);
            }
        } finally {
            this.showLoadingIndicator = false;
        }
    }

    /**
     * Append subjects to the subjects array
     *
     * @param newSubjects Array of subjects to be added
     */
    addSubjects(newSubjects: ISubject[]) {
        if (!this.subjects || this.subjects.length === 0) {
            this.logger.debug('Setting %d new subjects', newSubjects ? newSubjects.length : 0);
            this.subjects = newSubjects;
            this.lastReload = new Date();
        } else {
            this.logger.debug('Adding %d new subjects', newSubjects.length);
            this.subjects = this.subjects.concat(newSubjects);
        }
    }

    /**
     * Checks if user should reload
     *
     * @returns True if user should reload, false otherwise
     */
    shouldReload(): boolean {
        if (!this.lastReload) {
            this.logger.debug('shouldReload _lastReload is null');
            return true;
        }
        const diffSeconds = (new Date().getTime() - this.lastReload.getTime()) / 1000;
        this.logger.debug('shouldReload diff seconds %s', diffSeconds);
        const maxDataAgeSeconds = 3600 * 24; // 1 day
        return diffSeconds > maxDataAgeSeconds;
    }

    /**
     * Refreshes video catalog
     *
     * @param event Ion refresher event
     */
    async doRefresh(event: any) {
        this.logger.debug('Begin refresh operation', event);

        this.offset = 0;

        this.subjects = null;

        await this.loadSubjects();

        this.logger.debug('Refresh operation has ended');
        event.target.complete();
    }
}
