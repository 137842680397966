import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable, EMPTY, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { GroupsApi } from 'src/app/services/api/groups-api.service';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { NonBlockingResolver } from 'src/app/utils/non-blocking.resolver';

@Injectable({
    providedIn: 'root',
})
export class EducatorGroupResolver extends NonBlockingResolver {
    constructor(
        private _groupsApi: GroupsApi,
        private _uiUtils: UiUtils,
        private _navCtrl: NavController
    ) {
        super();
    }

    getData(route: ActivatedRouteSnapshot): Observable<any> {
        // eslint-disable-next-line @typescript-eslint/camelcase
        const class_id = route.params.class_id;
        return this._groupsApi.getByIdForEducator(class_id).pipe(
            catchError((err) => {
                return EMPTY;
            }),
            mergeMap((res) => {
                if (res.success) {
                    return this._groupsApi.getVideos(class_id).pipe(
                        catchError((err) => {
                            return EMPTY;
                        }),
                        mergeMap((response) => {
                            return of({ groupResponse: res, videos: response.data });
                        })
                    );
                } else if (
                    res.code === 'GROUPS_AFTER_END_DATE' ||
                    res.code === 'GROUPS_BEFORE_START_DATE' ||
                    res.code === 'GROUPS_DEACTIVATED'
                ) {
                    return of({ groupResponse: res });
                } else {
                    this._uiUtils.displayToast(res.msg);
                    this._navCtrl.navigateRoot('catalog');
                    return EMPTY;
                }
            })
        );
    }
}
