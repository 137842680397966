import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin, Observable, from, of } from 'rxjs';
import { GroupsApi } from 'src/app/services/api/groups-api.service';
import { VideosApi } from 'src/app/services/api/videos-api.service';
import { FavoredVideosApi } from 'src/app/services/api/favored-videos-api.service';
import { AppData } from 'src/app/services/app-data.service';
import { NonBlockingResolver } from 'src/app/utils/non-blocking.resolver';
import { ClipsApi } from 'src/app/services/api/clips.service';
import { IClip } from 'src/app/interfaces/IClip';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class VideoPlayerResolver extends NonBlockingResolver {
    constructor(private _groupsApi: GroupsApi,
        private _videosApi: VideosApi,
        private _favoredVideosApi: FavoredVideosApi,
        private _clipsApi: ClipsApi,
        private _appData: AppData) {
        super();
    }

    getData(route: ActivatedRouteSnapshot): Observable<any> {
        const { video_id, class_id, clip_id } = route.params;
        const isEmbed = route.data.isEmbed;

        let loadVideoFunction: Observable<any>;

        if (class_id && isEmbed) {
            loadVideoFunction = this._groupsApi.getPublicSpecificClassVideoDetails(class_id, video_id);
        } else if (class_id) {
            loadVideoFunction = this._groupsApi.getUserSpecificClassVideoDetails(class_id, video_id);
        } else {
            loadVideoFunction = this._videosApi.getUserSpecificVideoDetails(video_id);
        }
        const loadFavoredVideoFunction = from(this._appData.getFavoredVideo(video_id));

        // Load clip of clip_id is given in the URL
        const loadClipFunktion = clip_id ? this._clipsApi.getById(clip_id).map(response => response.data) : of(null);

        // let loadClipAndTasksFunction = of({ clip: null, tasks: null });
        // if (clip_id) {
        //     loadClipAndTasksFunction = this._clipsApi.getById(clip_id).pipe(
        //         switchMap(response => {
        //             const clip: IClip = response.data;
        //             if (clip) {
        //                 const isClipByCurrentUser = this._appData.isEducator() && clip.createdBy === this._appData.authenticatedUser?._id;
        //                 const onlyVisible = !isClipByCurrentUser;
        //                 return this._clipsApi.getTasksByClipId(clip._id, 0, 200, null, onlyVisible).map(res => {
        //                     return {
        //                         clip,
        //                         tasks: res.data
        //                     };
        //                 });
        //             } else {
        //                 return of({ clip: null, tasks: null });
        //             }
        //         })
        //     );
        // }

        return forkJoin({
            video: loadVideoFunction,
            favored: loadFavoredVideoFunction,
            clip: loadClipFunktion,
        });
        // return loadVideoFunction;
    }
}
