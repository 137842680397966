import { ILanguages } from './ILanguages';

export interface ITranslationCorrection {
    _id?: string;

    educator_id: string; // users._id  -- educator who suggested translation
    admin_id?: string; // users._id   -- admin who approved translation

    video_id: string; // videos._id
    translation_id: string; // translations._id
    subtitles_index: number; // key of 'translations.subtitles' array to get 'translations.subtitles.fromText'
    toWords_index: number; // key of 'translations.subtitles[3].toWords' array to get word which needs to be corrected

    from: ILanguages;
    to: ILanguages;
    fromWord: string; // word in original language
    toWord: string; // API translation
    toWordFix: string; // translated correction
    case_sensitive: boolean;
    comment: string; // comment by the user submitting the correction
    feedback: string; // feedback by the user approving or rejecting the correction

    rule: string; // translation rule
    rule_file: string; // a file where translation rule is

    version: number; // translation version
    status: TTranslationCorrectionStatus; // only admin can change this field

    src?: ETranslationCorrectionSource; // USER: 1, FREQUENT_WORDS: 2 (Automatically created by the system) - see ETranslationCorrectionSource
}

export type TTranslationCorrectionStatus = 'pending' | 'approved' | 'rejected' | 'on_hold';

export enum ETranslationCorrectionSource {
    USER = 1,
    FREQUENT_WORDS = 2, // Automatically created by the system
}
