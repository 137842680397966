<ion-header>
    <ion-toolbar [color]="appData.isEducator() ? 'blue' : appData.isStudent() ? 'green' : 'secondary'">
        <ion-buttons slot="start">
            <ion-button (click)="navigateBack()">
                <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>{{ ("vocabulary_cards" | translate) + (class ? ' (' + class.name + ')' : '') }}</ion-title>
        <ion-buttons *ngIf="appData.isCorrector()" slot="end">
            <ion-button (click)="openTranslationCorrection()">
                <ion-icon slot="icon-only" name="flag"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-progress-bar *ngIf='loading'  type="indeterminate"></ion-progress-bar>
    <swiper-container
        [pagination]='slideOptions.pagination'
        space-between="30"
        #swiper>
        <swiper-slide
            *ngFor="let word of wordCatalog; let i = index"
            class='swiper-zoom-container'
        >
            <ion-card class="word-catalog-card word-card">
                <ion-card-header class="card-original-word">
                    {{getFromWord(word)}}
                </ion-card-header>
                <ion-card-header class="card-translated-word">
                    <span [className]="'to-word to-word-' + splitWordIntoLines(word).length" [innerHTML]="splitWordIntoLinesJoin(word)"></span>
                </ion-card-header>
                <ion-card-content>
                    <p [innerHTML]="getSentence(word)"></p>

                    <div class="card-image-container"
                        (click)="openVideoPlayerPage(word)">
                        <div class="preview-image" [style.background-image]="safeBackgroundUrls[word.imageURL]"></div>
                        <p>
                            <ion-icon name="play"></ion-icon>
                        </p>
                    </div>
                </ion-card-content>
            </ion-card>
        </swiper-slide>
    </swiper-container>

    <div *ngIf="!plt.is('mobile') && wordCatalog" class="slide-arrows-container">
        <ion-icon name="arrow-back-circle" class="slide-back-arrow" *ngIf="index > 0" (click)="slidePrev()"></ion-icon>
        <ion-icon name="arrow-forward-circle" class="slide-forward-arrow"
            *ngIf="index < wordCatalog.length - 1" (click)="slideNext()"></ion-icon>
    </div>
</ion-content>
