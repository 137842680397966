import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { NavController, Platform, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/app.constants';
import { UiUtils } from 'src/app/services/ui-utils.service';
import { AppData } from 'src/app/services/app-data.service';
import { AppManager } from 'src/app/services/app-manager.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { environment } from '../../../environments/environment';
import { AbstractRootMenuPage } from 'src/app/utils/abstract-root-menu-page';
import { App } from '@capacitor/app';


@Component({
    template: `
        <ion-header>
            <ion-toolbar [color]="'secondary'">
                <ion-title>{{ 'btn_licenses' | translate }}</ion-title>
                <ion-buttons slot="end">
                    <ion-button (click)="dismiss()">
                        <ion-icon slot="icon-only" name="close"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content class="ion-padding">
            <div [innerHTML]="licensesText" style="font-size: 0.85em;"></div>
        </ion-content>
    `,
})
export class LicensesPage implements OnInit {

    public licensesText;
    constructor(
        private analytics: AnalyticsService,
        public appData: AppData,
        private modalCtrl: ModalController,
        private constants: Constants
    ) {
        // do nothing.
        this.licensesText = this.constants.LicensesText.replace(/\n/g, '<br />');
    }

    ngOnInit() {
        // do nothing.
    }

    dismiss() {
        this.modalCtrl.dismiss({
            dismissed: true,
        });
    }
}

@Component({
    selector: 'app-about',
    templateUrl: 'about-page.html',
    styleUrls: ['about-page.scss']
})
export class AboutPage extends AbstractRootMenuPage implements OnInit {

    public appName: string;
    public packageName: string;
    public versionCode: string | number;
    public versionNumber: string;
    public isProdution = environment.production;
    public environmentName = environment.environmentName;
    public geoIpCountry: string;

    constructor(public uiUtils: UiUtils,
        public translate: TranslateService,
        public appData: AppData,
        public appManager: AppManager,
        public constants: Constants,
        public navCtrl: NavController,
        public plt: Platform,
        private analytics: AnalyticsService,
        private titleService: Title,
        private modalCtrl: ModalController) {
        super(plt);
    }


    ngOnInit() {
        this.titleService.setTitle('About | uugot.it');
        this.analytics.trackPageView('about');
    }

    ionViewWillEnter() {
        this.appData.activePage = 'about';

        this.geoIpCountry = this.appData.geoIp?.country;

        if (this.plt.is('capacitor')) {
            this.loadAppVersion().then(() => console.log('App version loaded'));
        } else {
            console.log('Platform is not cordova - app version not available');
            // this.versionCode = '20';
            // this.versionNumber = '1.1';
            // this.packageName = 'it.uugot.webapp';
        }
        super.ionViewWillEnter();
    }

    ionViewWillLeave() {
        super.ionViewWillLeave();
    }
    async loadAppVersion() {
        const info = await App.getInfo();
        this.appName = info.name;
        this.packageName = info.id;
        this.versionCode = info.version;
        this.versionNumber = info.build;
    }

    /**
     * Presents terms of use modal
     */
    showTermsOfUse() {
        const termsAccepted = this.appData.getPreferenceString(`${this.constants.pref.TERMS_ACCEPTED}_0`);
        const isAcceptRequired = !termsAccepted;
        this.appManager.showTermsOfUseDialogV2('0', isAcceptRequired);
    }

    /**
     * Presents tutorial modal
     */
    showTutorial() {
        this.appManager.showTutorialPage();
    }

    async showLicenses() {
        const modal = await this.modalCtrl.create({
            component: LicensesPage,
            showBackdrop: true,
            // swipeToClose: true,
        });
        await modal.present();
    }
}
