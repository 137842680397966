// angular
import { Component, OnInit, ViewChild } from '@angular/core';

// models

import { IVideo } from '../../interfaces/IVideo';
import { zip, of } from 'rxjs';

// libraries
import { VideoCatalogPage } from './video-catalog-page';
import { ISubject } from 'src/app/interfaces/ISubject';
import { AnalyticsCategory } from 'src/app/services/analytics.service';

// @ts-ignore
@Component({
    selector: 'app-video-catalog-tag',
    templateUrl: 'video-catalog-page.html',
    styleUrls: [
        'video-catalog-page.scss',
        '../../components/video-catalog-card/video-catalog-card.component.scss' // Needed for "Hot Topic" card]
    ]
})
export class VideoCatalogTagPage extends VideoCatalogPage implements OnInit {

    protected videos: IVideo[];
    private lastReload: Date;
    private allVideosLoaded = false;

    ionViewWillEnter() {
        this.logger.debug('ionViewWillEnter');

        const previousTag = this.tag;
        this.tag = this._route.snapshot.params.tag;
        this.logger.debug('Got tag: %s, previous: %s', this.tag, previousTag);

        this.subjectSlug = this._route.snapshot.params.subject;
        this.categorySlug = this._route.snapshot.params.category;
        this.special = this._route.snapshot.params.special;

        this.withTasks = this._route.snapshot.params.filter === 'with-tasks' || this.special === 'no-subject-with-tasks';
        this.logger.debug('### withTasks', this.withTasks, this._route.snapshot);

        this.appData.activePage = 'catalog';
        if (this.subjectSlug) {
            this.appData.activePage = 'subjects';
        } else if (this.tag) {
            this.appData.activePage = `catalog/t/${this.tag}`;
        } else if (this.categorySlug) {
            this.appData.activePage = `category/${this.categorySlug}`;
        } else if (this.special) {
            this.appData.activePage = `catalog/sp/${this.special}`;
        }

        if (this.tag) {
            // ### We got a TAG
            const key = `tag_${this.tag}`;
            this.translate.get(key).subscribe(i18n => {
                if (i18n !== key) {
                    // We have a translation
                    this.headerTitle = i18n;
                } else {
                    // We don't have a translation, use the plain tag
                    this.headerTitle = this.tag;
                }
            });
        } else if (this.categorySlug) {
            // ### We got a CATEGORY
            const videoCategory = this.appData.getVideoCategoryBySlug(this.categorySlug);
            const key = `video_cat_${videoCategory?._id}`;
            this.translate.get(key).subscribe(i18n => {
                if (i18n !== key) {
                    // We have a translation
                    this.headerTitle = i18n;
                } else {
                    // We don't have a translation, use the plain tag
                    this.headerTitle = this.categorySlug;
                }
            });
        } else if (this.special) {
            // ### We got a CATEGORY
            const key = `videos_special_${this.special}`;
            this.translate.get(key).subscribe(i18n => {
                if (i18n !== key) {
                    // We have a translation
                    this.headerTitle = i18n;
                } else {
                    // We don't have a translation, use the plain tag
                    this.headerTitle = this.special;
                }
            });
        }

        if (this.shouldReload()) {
            this.offset = 0;
            this.videos = null;
            if (this.subjectSlug) {
                this.loadSubject();
            }
            this.loadVideoCatalog(true, () => {
                // Do nothing.
            });
        } else {
            this.loadWatchedVideos();
        }
        // console.log('infiniteScroll:', this.infiniteScroll);
        this.infiniteScroll.disabled = this.appData.allVideosLoaded;

        if (this.isRootPage) {
            // Exit app subscription
            super.creatBackButtonSubscription();
        }
    }

    // Workaround for https://gitlab.com/uugotitTeam/webapp/issues/172
    ionViewDidEnter() {
        // Do nothing.
    }

    /**
     * Loads next videos in the video catalog
     *
     * @param showLoadingAnimation Show loading animation if true
     * @param onComplete Function to run when loading complete
     */
    async loadVideoCatalog(showLoadingAnimation: boolean, onComplete: () => void) {
        this.loadingAnimation(showLoadingAnimation);

        // Combine the two requests
        const apiRequests = zip(
            // Load video of the day:
            this.subjectSlug || this.categorySlug
                ? of(null)
                : this.videosApi.getVideosOfTheDay(0, 14),
            // Load video catalog:
            this.videosApi.getUserSpecificCatalog(
                null,
                this.offset,
                this.pageSize,
                this.searchText,
                '',
                this.setCountry(),
                this.tag,
                this.special === 'no-subject-with-tasks' ? 'none' : this.subjectSlug,
                this.categorySlug,
                null,
                this.withTasks ? 1 : null,
                this.special === 'my-tasks' ? 1 : null,
                true
            )
        );
        apiRequests.subscribe(data => {
            // Destructure array
            const [responseOfDay, responseCatalogue] = data;

            // this.logger.debug('Got videos of the day: ', data);
            if (responseOfDay && responseOfDay.data.length > 0) {
                responseOfDay.data.forEach(votd => {
                    responseCatalogue.videosAvailable.forEach(vid => {
                        if (vid._id === votd._id) {
                            this.logger.debug('Setting video of the day', vid._id);
                            vid['isVideoOfTheDay'] = true;
                        }
                    });
                });
            }

            // Solves 'cannot read property length of undefined'
            if (!responseCatalogue.videosAvailable) {
                responseCatalogue.videosAvailable = [];
            }

            this.logger.debug(`Reload data got ${responseCatalogue.videosAvailable.length} videos`);

            const onSuccess = () => {
                if (this.offset === 0) {
                    // Clear videos, in case of pull-to-refresh
                    this.videos = [];
                }
                this.addOrReplaceVideos(responseCatalogue.videosAvailable);
                this.offset += this.pageSize;
                this.analytics.trackAnalyticsEvent(AnalyticsCategory.VideoCatalogTag, 'loaded', this.tag);
                this.allVideosLoaded = this.offset >= responseCatalogue.videosAvailableCount;
                this.logger.debug(`Loaded all available videos (${this.offset} / ${responseCatalogue.videosAvailableCount})?`, this.allVideosLoaded ? 'yes' : 'no');
                this.infiniteScroll.disabled = this.allVideosLoaded;

                this.loadWatchedVideos();
            };

            if (showLoadingAnimation) {
                // loader.dismiss().then(onSuccess);
                this.showLoadingIndicator = false;
                onSuccess();
            } else {
                onSuccess();
            }
        }, err => {
            this.loaderErrorHandler(err, showLoadingAnimation);
        },
        () => {
            this.logger.debug('Reload data complete');
            onComplete();
        });
    }
    async loadVideoCatalogFromSearch(showLoadingAnimation: boolean, onComplete: () => void) {
        this.loadingAnimation(showLoadingAnimation);

        this.videosApi.getUserSpecificCatalog(
            null,
            this.offset,
            this.pageSize,
            this.searchText,
            '',
            this.setCountry(),
            this.tag,
            this.special === 'no-subject-with-tasks' ? 'none' : this.subjectSlug,
            this.categorySlug,
            null,
            this.withTasks ? 1 : null,
            this.special === 'my-tasks' ? 1 : null
        ).subscribe((responseCatalogue) => {
            // Solves 'cannot read property length of undefined'
            if (!responseCatalogue.videosAvailable) {
                responseCatalogue.videosAvailable = [];
            }
            this.logger.debug(`Reload data got ${responseCatalogue.videosAvailable.length} videos`);

            const onSuccess = () => {
                if (this.offset === 0) {
                    // Clear videos, in case of pull-to-refresh
                    this.videos = [];
                }
                this.addOrReplaceVideos(responseCatalogue.videosAvailable);
                this.offset = this.pageSize;
                this.analytics.trackAnalyticsEvent(AnalyticsCategory.VideoCatalogTag, 'loaded', this.tag);
                this.allVideosLoaded = this.offset >= responseCatalogue.videosAvailableCount;
                this.logger.debug(`Loaded all available videos (${this.offset} / ${responseCatalogue.videosAvailableCount})?`, this.allVideosLoaded ? 'yes' : 'no');
                this.infiniteScroll.disabled = this.allVideosLoaded;

                this.loadWatchedVideos();
            };

            if (showLoadingAnimation) {
                // loader.dismiss().then(onSuccess);
                this.showLoadingIndicator = false;
                onSuccess();
            } else {
                onSuccess();
            }
        }, err => {
            this.loaderErrorHandler(err, showLoadingAnimation);
        },
        () => {
            this.logger.debug('Reload data complete');
            onComplete();
        });
    }

    /**
     * Loads the school subject object from the server.
     */
    async loadSubject() {
        const response = await this.subjectsApi.getById(this.subjectSlug).toPromise();
        if (response && response.success && response.data) {
            this.subject = response.data;
            this.headerTitle = this.subject.name[this.appData.getLanguage()] || this.subject.name['en'];
            const suffix = this.withTasks ? ' - Tasked Videos' : '';
            this.headerTitle = `${this.headerTitle}${suffix}`;
        }
    }

    /**
     * Refreshes video catalog
     *
     * @param event Ion refresher event
     */
    doRefresh(event: any) {
        this.logger.debug('Begin refresh operation', event);

        this.offset = 0;
        this.infiniteScroll.disabled = false;

        this.loadVideoCatalog(false, () => {
            this.logger.debug('Refresh operation has ended');
            event.target.complete();
        });
    }

    /**
     * Checks if user should reload
     *
     * @returns True if user should reload, false otherwise
     */
    shouldReload(): boolean {
        if (!this.lastReload) {
            this.logger.debug('shouldReload _lastReload is null');
            return true;
        }
        const diffSeconds = (new Date().getTime() - this.lastReload.getTime()) / 1000;
        this.logger.debug('shouldReload diff seconds %d', diffSeconds);
        const maxDataAgeSeconds = 3600 * 24; // 1 day
        return diffSeconds > maxDataAgeSeconds;
    }

    /**
     * Force reload videos
     */
    forceReloadVideos() {
        this.lastReload = null;
    }

    /**
     * If search text is present and is the page (offset) 0 then will replace videos to the videos array
     * Otherwise will replace it.
     * @param newVideos Array of videos to be added
     */
    addOrReplaceVideos(newVideos: IVideo[]) {
        if (!this.videos || (this.searchText && this.searchText.length > 0) && this.offset === 0) {
            this.logger.debug('Setting %d new videos', newVideos ? newVideos.length : 0);
            this.videos = newVideos;
            this.lastReload = new Date();
        } else {
            this.logger.debug('Adding %d new videos', newVideos.length);
            this.videos = this.videos.concat(newVideos);
        }
    }

    /**
     * Override function from super class
     */
    getAppDataVideos() {
        return this.videos;
    }

    /**
     * Override function from super class
     */
    navigateBack() {
        const canGoBack = this.routerOutlet.canGoBack();
        if (canGoBack) {
            this.logger.debug('navigateBack() pop');
            this.navCtrl.pop();
        } else {
            this.logger.debug('navigateBack() navigateBack with path');
            let path = '';
            if (this.subjectSlug && this.withTasks) {
                path = `catalog/s/${this.subjectSlug}`;
            } else if (this.subjectSlug) {
                path = 'subjects';
            } else if (this.special === 'my-tasks' || this.special === 'no-subject-with-tasks') {
                path = 'subjects';
            } else {
                path = 'catalog';
            }
            this.navCtrl.navigateBack(path);
        }
    }

    get isShowHotTopic() {
        return false;
    }
}
